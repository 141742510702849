import React from 'react'

const FieldFormat = ({ element, icon, show}) => {
    return (
        <div className="position-relative d-flex justify-content-center align-items-center">
            {element}
            <div className="position-absolute" style={{zIndex:1,right:'10px'}}>{icon}</div>
        </div>
    )
}

export default FieldFormat
