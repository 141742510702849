import { Button } from "antd";
import { MdModeEditOutline } from "react-icons/md";
import { GoogleTranslateAPIKey } from "../../../../config";

const CookingInstructionListItem = ({ number, item, onEditClicked, id, isLink }) => {
  const handleLinkClick = () => {
    window.open(item, "_blank").focus();
  };
let fromLang = 'en';
let toLang = 'ar'; // translate to arabic
let text = item;

const API_KEY = GoogleTranslateAPIKey;

let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
url += '&q=' + encodeURI(text);
url += `&source=${fromLang}`;
url += `&target=${toLang}`;

fetch(url, { 
  method: 'GET',
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
})
.then(res => res.json())
.then((response) => {
  console.log("response from google: ", response);
})
.catch(error => {
  console.log("There was an error with the translation request: ", error);
});
  return (
    <div className={`cooking-instruction-list-item ${isLink ? "link" : ""}`}>
      <div className="number">{number}</div>
      <div onClick={isLink ? handleLinkClick : null} className="text">
        {item}
      </div>
      <div>
        <Button onClick={() => onEditClicked(id)} type="link">
          <MdModeEditOutline />
        </Button>
      </div>
    </div>
  );
};

export default CookingInstructionListItem;


