import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { cliniccalendarRoute, dashboardAppointmentsRoute } from "../routes";
import { userRoleClinic } from "../utility/constants";

const ViewAppointment = ({ data }) => {
  const role = useSelector((state) => {
    if (state.auth.userData) {
      return state.auth.userData.role;
    }
    return null;
  });
  const history = useHistory();

  const onClick = () => {
    // console.log({ data });
    // debugger;
    if (role === userRoleClinic) {
      history.push(cliniccalendarRoute.path);
    } else if (data.translation.name === "appointmentProcessedSuccessfully") {
      history.push(dashboardAppointmentsRoute.path, {
        pending: "Upcoming",
      });
    } else if (data.translation.name === "appointmentDeclinedWithPatient") {
      history.push(dashboardAppointmentsRoute.path, {
        pending: "Cancelled",
      });
    } else {
      history.push(dashboardAppointmentsRoute.path, {
        pending: "Pending",
      });
    }
  };

  const language = useSelector((state) => state.language);

  return (
    <div className="d-flex justify-content-end align-items-center w-100">
      <button
        onClick={onClick}
        className="btn"
        style={{
          backgroundColor: "#1B68DF",
          borderRadius: "50px",
          color: "#fff",
          padding: "3px 15px",
        }}
      >
        {language.lang.view}
      </button>
    </div>
  );
};
export default ViewAppointment;
