import { Component, Fragment } from "react";
import Avatar from "react-avatar";

class ImageLoader extends Component {
  state = {
    src: this.props.url,
    name: this.props.name,
    loaded: false,
    error: !this.props.url ? true : false,
  };

  onImageLoaded = () => {
    this.setState({ loaded: true });
  };
  onImageError = () => {
    this.setState({ error: true });
  };

  render() {
    const { src, loaded, fallbackSrc, error, name } = this.state;
    return (
      <>
        {error ? (
          <Avatar name={name} size="40" round={true} color="#1B68DF" />
        ) : (
          <img
            src={src}
            onLoad={this.onImageLoaded}
            onError={this.onImageError}
            className={this.props.className}
            alt={this.props.alt}
          />
        )}
      </>
    );
  }
}

export default ImageLoader;
