import React, { useState } from "react";
import { Link } from "react-router-dom";
import config from "../config";
import { useHistory } from "react-router";
import {
  dashboardRoute,
  dashboardContentManagerRoute,
  dashboardVoucherRoute,
  dashboardPractitionerRoute,
  dashboardDisputeCenterRoute,
  dashboardCorporatesRoute,
  dashboardPermissionsRoute,
} from "../routes";
import { useSelector, useDispatch } from "react-redux";
import { clientSignInRoute } from "../routes";
import { logOutAction } from "../redux/authenitcation/actionCreator";
import SideMenuLink from "./SideMenuLink";
import { MdCenterFocusWeak, MdSecurity } from "react-icons/md";
import { BsPeopleFill } from "react-icons/bs";

const SideMenuAdmin = () => {
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 2000);
  const history = useHistory();
  const isAuth = useSelector((state) => {
    return state.auth.login;
  });
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const logoutPressed = () => {
    dispatch(logOutAction());
    history.push(dashboardRoute.path);
  };

  return (
    <nav className={`mm-nav ${isCollapsed && "nav-collapsed"}`}>
      <Link to={dashboardRoute.path} className="logo d-flex justify-content-center">
        <img className="m" src={config.logoLink} alt="IZI" />
      </Link>
      <SideMenuLink
        icon={<i className="far fa-home"></i>}
        label={language.lang.home}
        to={dashboardRoute.path}
        exact={true}
      />
      <SideMenuLink
        icon={<MdCenterFocusWeak />}
        label="Dispute Center"
        to={dashboardDisputeCenterRoute.path}
      />
      <SideMenuLink
        icon={<i className="fas fa-heart"></i>}
        label="Practitioners"
        to={dashboardPractitionerRoute.path}
      />
      <SideMenuLink icon={<BsPeopleFill />} label="Customers" />
      <SideMenuLink
        icon={<i className="fas fa-user-tie"></i>}
        label="Corporates"
        to={dashboardCorporatesRoute.path}
      />
      <SideMenuLink
        icon={<i className="fal fa-salad"></i>}
        label="Content Manager"
        to={dashboardContentManagerRoute.path}
      />
      <SideMenuLink icon={<i className="fas fa-heart"></i>} label="Templates" />
      <SideMenuLink
        icon={<i className="far fa-newspaper"></i>}
        label="Good Reads"
      />
      <SideMenuLink
        icon={<MdSecurity />}
        label="Permissions"
        to={dashboardPermissionsRoute.path}
      />
      <SideMenuLink
        icon={<i className="fas fa-tags"></i>}
        label="Vouchers"
        to={dashboardVoucherRoute.path}
      />

      <div className="divider my-3"></div>
      {!isAuth && (
        <SideMenuLink
          icon={<i className="far fa-sign-in-alt"></i>}
          label="Login"
          to={clientSignInRoute.path}
        />
      )}
      {isAuth && (
        <SideMenuLink
          icon={<i className="far fa-sign-out-alt"></i>}
          label="Settings"
        />
      )}
      {isAuth && (
        <SideMenuLink
          onClick={logoutPressed}
          icon={<i className="far fa-sign-out-alt"></i>}
          label={language.lang.logout}
        />
      )}
      <SideMenuLink
        className="mt-auto"
        icon={<i className="fal fa-bars"></i>}
        label="Toggle Menu"
        toggleCollapse={toggleCollapse}
      />
    </nav>
  );
};

export default SideMenuAdmin;
