import { useState, useEffect } from "react";
import axios from "../services/Axios";
import moment from "moment";
import SessionDetails from "./SessionDetails";
import Loading from "../components/Loading";
import { Input } from "antd";
import { defaultErrorMsg } from "../utility/constants";
import { Link } from "react-router-dom";
import "../assets/styles/_Practitioner_live_session_list.scss";
import {
  practiitionercalendarRoute,
  practitionerLiveSessionTabRoute,
} from "../routes";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import TopMenu from "./CommonControls/TopMenu";

const PractitionerLiveSessionList = () => {
  const history = useHistory();
  const language = useSelector((state) => state.language.lang);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [sessions, setSessions] = useState(null);
  const [searchWord, setSearchWord] = useState("");
  const [searched, setSearched] = useState(false);
  const [foundData, setFoundData] = useState([]);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const today = moment().format("YYYY-MM-DD");
      const nextMonth = moment(today, "YYYY-MM-DD")
        .add(30, "days")
        .format("YYYY-MM-DD");
      const getSessions = await axios.get(
        `/getHostAllLiveSessions?startDate=${today}&endDate=${nextMonth}`
      );
      const now = moment();
      setSessions(
        getSessions.data.response.hostAllLiveSessions.filter((item) => {
          const sessionEndDateTime = moment.utc(item.end_date_time_utc);
          return sessionEndDateTime.isAfter(now);
        })
      );
    } catch (e) {
      console.log(e);
      setError(defaultErrorMsg);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setSearchWord("");
    setSearched(false);
  };

  const searchHandler = (e) => {
    setSearched(true);
    setSearchWord(e.target.value);
    const found = sessions.filter((obj) =>
      JSON.stringify(obj).toLowerCase().includes(searchWord.toLowerCase())
    );
    setFoundData(found);
  };

  const onViewSessionClicked = ({ session }) => {
    history.push(practiitionercalendarRoute.path);
  };

  return (
    <section className="practitioner-sessions-list">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.events}
      />
      <div className="mm-card mx-3 mb-5">
        <div className="row mx-0 align-items-end">
          <div className="col-12 col-lg-6 px-0">
            <h5 className="text-header-size">{language.allActiveEvents}</h5>
            <Input
              className="search-box text-header-size"
              value={searchWord}
              placeholder={language.searchEvents}
              prefix={<i className="fas fa-search blue-icon mr-2"></i>}
              suffix={
                <i
                  onClick={handleCancel}
                  className="far fa-times-circle blue-icon"
                ></i>
              }
              onChange={searchHandler}
            />
          </div>
          <div className="col-12 col-md-3 col-lg-2 mt-3 mt-lg-0 ml-auto px-0">
            <Link to={practitionerLiveSessionTabRoute.path}>
              <button className="createNewButton w-100 text-center">
                {language.createNew} +
              </button>
            </Link>
          </div>
        </div>
        {searched ? null : (
          <div className="row mx-0 flex-column mt-3">
            {loading ? (
              <Loading />
            ) : (
              (sessions || []).map((session) => (
                <div className="col-12 col-lg-8 px-0" key={session.id}>
                  <SessionDetails
                    key={session.id}
                    list={true}
                    title={session.title}
                    duration={session.duration}
                    paid={session.is_paid}
                    max_attendees={session.max_attendees}
                    start_date={session.start_date_time_utc}
                    onViewClicked={() => onViewSessionClicked({ session })}
                  />
                </div>
              ))
            )}
          </div>
        )}
        {searched ? (
          <div className="d-flex flex-column mt-3">
            {loading ? (
              <Loading />
            ) : (
              (foundData || []).map((session) => (
                <div className="col-12 col-lg-8 px-0" key={session.id}>
                  <SessionDetails
                    key={session.id}
                    list={true}
                    title={session.title}
                    duration={session.duration}
                    paid={session.is_paid}
                    max_attendees={session.max_attendees}
                    start_date={session.start_date_time_utc}
                    onViewClicked={() => onViewSessionClicked({ session })}
                  />
                </div>
              ))
            )}
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default PractitionerLiveSessionList;
