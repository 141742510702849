import moment from "moment";
import axios from "./Axios";

const getPractitionerSlots = async ({ practitionerID }) => {
  try {
    const response = await axios.get("/getSlots", {
      params: { practitionerId: practitionerID },
    });
    const slots = response.data.response;
    // console.log("--- slots", slots);
    const now = moment();
    return slots.filter((slot) => {
      return (
        slot.is_available && moment.utc(slot.start_time).local().isAfter(now)
      );
    });
  } catch (e) {
    console.log("--- getPractitionerSlots error");
    console.log(e);
    return null;
  }
};

const getPractitionerDetails = async ({ practitionerID, urlName }) => {
  try {
    const response = await axios.get("/getPractitionerDetails", {
      params: { practitionerId: practitionerID, urlName },
    });
    return response.data.response[0];
  } catch (e) {
    console.log("--- getPractitionerDetails error");
    console.log(e);
    return null;
  }
};

export default { getPractitionerDetails, getPractitionerSlots };
