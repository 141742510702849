import Dashboard from "../pages/Dashboard";
import SignUp from "../pages/SignUp";
import CoSignUp from "../pages/CoSignup";
import DashboardZenZone from "../components/DashboardMeditationYoga";
import DashboardMostPopular from "../components/DashboardMostPopular";
import DashboardWellness from "../components/DashboardWellness";
import DashboardFitness from "../components/DashboardFitness";
import DashboardPrescription from "../components/DashboardPrescription";
import CreatePrescription from "../components/CreatePrescription";
import BookConsultation from "../pages/BookConsultation";
import DoctorJoinForConsultation from "../pages/DoctorJoinForConsultation";
import SlotManagement from "../components/SlotManagement";
import WalkinForm from "../pages/WalkinForm";
import CustomerConfirmConsultation from "../pages/CustomerConfirmConsultation";
import ProvideConsultationInfo from "../pages/ProvideConsultationInfo";
import WalkinConfirmation from "../pages/WalkinConfirmation";
import DashboardConsultation from "../components/DashboardConsultation";
import VideoCallScreen from "../pages/VideoCallScreen";
import ClientJoinForConsultation from "../pages/ClientJoinForConsultation";
import ContentManager from "../components/ContentManager";
import Voucher from "../components/Voucher";
import Practitioner from "../components/Practitioner";
import Corporates from "../components/Corporates";
import Permissions from "../components/Permissions";
import DisputeCenter from "../components/DisputeCenter";
import CorporateSignIn from "../pages/CorporateSignIn";
import LiveSession from "../pages/LiveSession";
import DashboardAppointments from "../components/DashboardAppointments";
import Payment from "../pages/Payment";
import PaymentError from "../pages/PaymentError";
import MostPopularNew from "../components/MostPopularNew";
import MostPopularCardDash from "../components/MostPopularCardDash";
import CardRecipe from "../components/CardRecipe";
import LiveSessionDetailsCard from "../components/LiveSessions/LiveSessionDetailsCard";
import PaymentInfo from "../pages/PaymentInfo";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import Settings from "../components/Settings";
import PrivacyPolicy from "../components/PrivacyPolicy";
import TermsAndConditions from "../components/TermsAndConditions";
import ContactUs from "../components/ContactUs";
import AboutUs from "../components/AboutUs";
import JoinUs from "../components/JoinUs";
import Feedback from "../components/Feedback";
import Clinic from "../components/Clinic";
import ClinicCalendar from "../components/ClinicCalendar";
import VerifyPatientDetails from "../components/VerifyPatientDetails";
import ViewScheduledPatient from "../components/ViewScheduledPatient";
import CheckoutForm from "../pages/PaymentInfo/CheckoutForm";
import PractitionerCalendar from "../components/PractitionerCalendar";
import CustomerCalendar from "../components/CustomerCalendar";
import PractitionerLiveSessionTab from "../components/PractitionerLiveSessionTab";
import PractitionerLiveSessionList from "../components/PractitionerLiveSessionList";
import DashboardLiveSession from "../components/DashboardLiveSession";
import FitnessBuddy from "../pages/FitnessBuddy/FitnessBuddy";
import ViewMatches from "../pages/FitnessBuddy/ViewMatches/ViewMatches";
import MatchedProfile from "../pages/FitnessBuddy/ViewMatches/MatchedProfile";
import BuddysLiveChat from "../pages/FitnessBuddy/BuddysLiveChat/BuddyLiveChat";
import StripePaymentCheckout from "../pages/StripePaymentCheckout";
import VerifyPatientDetailsBasedOnRegion from "../components/VerifyPatientDetailsBasedOnRegion";
import ViewScheduledPatientBasedOnRegion from "../components/ViewScheduledPatientBasedOnRegion";
import MealPlanDashboard from "../components/MealPlan/MealPlanDashboard/MealPlanDashboard";
import CreateRecipe from "../components/MealPlan/Recipe/CreateRecipe";
import TeleHealthLanding from "../pages/LandingPages/TeleHealthLanding";
import FitnessLanding from "../pages/LandingPages/FitnessLanding";
import EventsLanding from "../pages/LandingPages/EventsLanding";
import MealPlanLanding from "../pages/LandingPages/MealPlanLanding";
import Support from "../components/Support";
import PasswordlessSignin from "../pages/PasswordlessSignin";
import { PhoneSignin } from "../pages/PhoneSignin";
import PhoneSigninVerifyOTP from "../pages/PhoneSigninVerifyOTP";
import SignupUserName from "../pages/SignupUsername";
import EmailSignin from "../pages/EmailSignin";
import CompleteEmailLinkSignin from "../pages/CompleteEmailLinkSignin";
import ViewRecipeDetails from "../components/MealPlan/ViewRecipeDetails";
import MealPlanOnboarding from "../components/MealPlan/MealPlanOnboarding";
import SubscriptionPlanDetails from "../components/MealPlan/SubscriptionPlanDetails";
import SelectSubscriptionPlan from "../components/MealPlan/SelectSubscriptionPlan";
import BookDieticianAppointment from "../components/MealPlan/BookDieticianAppointment";

const dashboardRoutePath = "/";

export const stripePaymentCheckoutRoute = {
  component: StripePaymentCheckout,
  path: `${dashboardRoutePath}payment-checkout`,
};

export const buddysLiveChatRoute = {
  path: "/live-chat",
  component: BuddysLiveChat,
};
export const matchedProfileRoute = {
  path: "/matched-profile",
  component: MatchedProfile,
};
export const fitnessBuddyRoute = {
  path: "/fitness-buddy",
  component: FitnessBuddy,
};
export const viewMatchesRoute = {
  path: "/view-matches",
  component: ViewMatches,
};

export const clinicVerifyPatientDetailsRoute = {
  path: `${dashboardRoutePath}clinic-patient-details`,
  component: VerifyPatientDetails,
  protected: true,
};

export const resetPasswordRoute = {
  component: ResetPassword,
  path: "/reset-password",
};

export const forgotpasswordRoute = {
  component: ForgotPassword,
  path: "/forgot-password",
};

export const paymentInfoRoute = {
  component: PaymentInfo,
  protected: true,
  path: "/payment-info",
};

export const paymentPortalFormRoute = {
  component: CheckoutForm, // PaymentPortalForm,
  path: "/payment-portal",
};

export const paymentErrorRoute = {
  path: "/payment-error",
  component: PaymentError,
};

export const paymentRoute = {
  path: "/payment",
  component: Payment,
};

export const liveSessionRoute = {
  path: "/live-session",
  protected: true,
  component: LiveSession,
};

export const corporateSignInRoute = {
  path: "/corporate-login",
  component: CorporateSignIn,
};

export const walkinFormRoute = {
  path: "/meet-doctor",
  component: WalkinForm,
  protected: true,
};

export const doctorJoinConsultationRoute = {
  component: DoctorJoinForConsultation,
  path: `${dashboardRoutePath}doctor-join-consultation`,
  protected: true,
};

export const joinForConsultaitonRoute = {
  path: `${dashboardRoutePath}join-for-consultation`,
  component: ClientJoinForConsultation,
  protected: true,
};

export const walkinConfirmationRoute = {
  component: WalkinConfirmation,
  path: "/walkin-confirmation",
  protected: true,
};

export const clientSignInRoute = {
  // component: ClientSignIn,
  component: PasswordlessSignin,
  exact: true,
  path: "/login",
  protected: false,
};

export const signUpRoute = { component: SignUp, exact: false, path: "/signup" };
export const coSignUpRoute = {
  component: CoSignUp,
  exact: false,
  path: "/cosignup",
};

export const telehealthlanding = {
  component: TeleHealthLanding,
  exact: false,
  path: "/izitelehealth",
};
export const fitnesslanding = {
  component: FitnessLanding,
  exact: false,
  path: "/izifitness",
};
export const eventslanding = {
  component: EventsLanding,
  exact: false,
  path: "/izievents",
};
export const mealplanslanding = {
  component: MealPlanLanding,
  exact: false,
  path: "/izimealplans",
};

export const dashboardPrescriptionRoute = {
  component: DashboardPrescription,
  path: `${dashboardRoutePath}prescription`,
  protected: true,
};

export const provideConsultationInfoRoute = {
  component: ProvideConsultationInfo,
  path: `/provide-consultation-info`,
  protected: true,
  exact: true,
};

export const dashboardBookConsultationRoute = {
  component: BookConsultation,
  path: `${dashboardRoutePath}book`,
  protected: true,
  exact: true,
};

export const customerConfirmConsultationRoute = {
  path: `${dashboardBookConsultationRoute.path}/:urlName`,
  component: CustomerConfirmConsultation, // PractitionerBookConsultation
  exact: false,
};

export const dashboardLiveSessionRoute = {
  component: DashboardLiveSession,
  path: `${dashboardRoutePath}customer-live-sessions-list`,
};

export const dashboardWellnessRoute = {
  component: DashboardWellness,
  path: `${dashboardRoutePath}wellness`,
};

export const dashboardMostPopularNewRoute = {
  component: MostPopularNew,
  path: `${dashboardRoutePath}mostpopulars`,
};

export const MostPopularCardDashRoute = {
  component: MostPopularCardDash,
  path: `${dashboardRoutePath}mostpopularcard`,
};

export const dashboardMostPopularRoute = {
  component: DashboardMostPopular,
  path: `${dashboardRoutePath}mostpopular`,
};

// export const dashboardMealPlanRoute = {
//   component: MealPlanLanding,
//   path: `${dashboardRoutePath}mealplan`,
// };

export const dashboardMealPlanRoute = {
  component: MealPlanDashboard,
  path: `${dashboardRoutePath}mealplan`,
  exact: true,
};

export const mealPlanOnboardingRoute = {
  component: MealPlanOnboarding,
  path: `${dashboardMealPlanRoute.path}/onboarding`,
  exact: false,
};

export const viewRecipeDetailsRoute = {
  path: `${dashboardMealPlanRoute.path}/recipe/:recipeURLName`,
  component: ViewRecipeDetails,
  exact: true,
};

export const editRecipeRoute = {
  path: `${dashboardMealPlanRoute.path}/recipe/:recipeURLName/edit`,
  component: CreateRecipe,
  exact: true,
};

export const createRecipeRoute = {
  component: CreateRecipe,
  path: `${dashboardRoutePath}createRecipe`,
};

export const dashboardSessionDetailRoute = {
  component: LiveSessionDetailsCard,
  path: `${dashboardRoutePath}sessionDetails`,
};
export const dashboardCardRecipeRoute = {
  component: CardRecipe,
  path: `${dashboardRoutePath}cardrecipe`,
};

export const dashboardMeditationYogaRoute = {
  component: DashboardZenZone,
  path: `${dashboardRoutePath}meditationyoga`,
};

export const dashboardFitnessRoute = {
  component: DashboardFitness,
  path: `${dashboardRoutePath}fitness`,
};

export const dashboardAppointmentsRoute = {
  component: DashboardAppointments,
  path: `${dashboardRoutePath}Appointments`,
};

export const practitionerLiveSessionRoute = {
  component: PractitionerLiveSessionList,
  path: `${dashboardRoutePath}practitioner-events-list`,
};

export const clinicRoute = {
  component: Clinic,
  path: `${dashboardRoutePath}clinic`,
};

export const ViewScheduledPatientRoute = {
  component: ViewScheduledPatient,
  path: `${dashboardRoutePath}viewpatientdetails`,
};
export const ViewScheduledPatientBasedOnRegionRoute = {
  component: ViewScheduledPatientBasedOnRegion,
  path: `${dashboardRoutePath}viewpatientdetailsBasedOnRegion`,
};

export const VerifyPatientDetailsRoute = {
  component: VerifyPatientDetails,
  path: `${dashboardRoutePath}verifypatientdetails`,
};
export const VerifyPatientDetailsBasedOnRegionRoute = {
  component: VerifyPatientDetailsBasedOnRegion,
  path: `${dashboardRoutePath}verifypatientdetailsBasedOnRegion`,
};

export const cliniccalendarRoute = {
  component: ClinicCalendar,
  path: `${dashboardRoutePath}clinicCalendar`,
};

export const customerCalendarRoute = {
  component: CustomerCalendar,
  path: `${dashboardRoutePath}customerCalendar`,
};

export const practiitionercalendarRoute = {
  component: PractitionerCalendar,
  path: `${dashboardRoutePath}practitionercalendar`,
};

export const settingsRoute = {
  component: Settings,
  path: `${dashboardRoutePath}settings`,
};

export const dashboardConsultationRoute = {
  component: DashboardConsultation,
  path: `${dashboardRoutePath}consultation`,
};

export const dashboardCreatePrescriptionRoute = {
  component: CreatePrescription,
  path: `${dashboardRoutePath}create-prescription`,
};

export const dashboardSlotManagementRoute = {
  component: SlotManagement,
  path: `${dashboardRoutePath}slot-management`,
};

export const dashboardContentManagerRoute = {
  component: ContentManager,
  path: `${dashboardRoutePath}content-manager`,
};

export const dashboardVoucherRoute = {
  component: Voucher,
  path: `${dashboardRoutePath}voucher`,
};

export const dashboardCorporatesRoute = {
  component: Corporates,
  path: `${dashboardRoutePath}corporates`,
};

export const dashboardPermissionsRoute = {
  component: Permissions,
  path: `${dashboardRoutePath}permissions`,
};

export const dashboardDisputeCenterRoute = {
  component: DisputeCenter,
  path: `${dashboardRoutePath}dispute-center`,
};

export const dashboardPractitionerRoute = {
  component: Practitioner,
  path: `${dashboardRoutePath}practitioner`,
};

export const dashboardRoute = {
  component: Dashboard,
  exact: false,
  path: dashboardRoutePath,
};

export const practitionerLiveSessionTabRoute = {
  component: PractitionerLiveSessionTab,
  path: `${dashboardRoutePath}create-live-event`,
  protected: true,
};

export const dashboardPrivacyPolicy = {
  component: PrivacyPolicy,
  path: `${dashboardRoutePath}privacy-policy`,
};
export const dashboardTermsAndConditions = {
  component: TermsAndConditions,
  path: `${dashboardRoutePath}termsandconditions`,
};
export const dashboardAboutUs = {
  component: AboutUs,
  path: `${dashboardRoutePath}aboutus`,
};
export const dashboardContactUs = {
  component: ContactUs,
  path: `${dashboardRoutePath}contactUs`,
};
export const dashboardCancelationPolicy = {
  component: ContactUs,
  path: `${dashboardRoutePath}cancelation-policy`,
};
export const dashboardJoinUs = {
  component: JoinUs,
  path: `${dashboardRoutePath}join-us`,
};
export const dashboardFeedback = {
  component: Feedback,
  path: `${dashboardRoutePath}feedback`,
};
export const dashboardSupport = {
  component: Support,
  path: `${dashboardRoutePath}support`,
};

export const agoraRoute = {
  component: VideoCallScreen,
  exact: false,
  path: "/video-call",
  protected: true,
};

export const phoneSigninRoute = {
  component: PhoneSignin,
  path: "/phone-signin",
  exact: true,
};

export const signupUserNameRoute = {
  component: SignupUserName,
  path: "/signup-username",
  exact: true,
};

export const phoneSigninVerifyOTPRoute = {
  component: PhoneSigninVerifyOTP,
  path: "/verify-otp",
  exact: true,
};

export const emailSigninRoute = {
  component: EmailSignin,
  path: "/email-signin",
  exact: true,
};

export const completeEmailLinkSigninRoute = {
  path: "/complete-email-link-signin",
  component: CompleteEmailLinkSignin,
  exact: true,
};

export const subscriptionPlanDetailsRoute = {
  path: "/subscription-plan-details",
  component: SubscriptionPlanDetails,
  exact: true,
};

export const selectSubscriptionPlanRoute = {
  path: "/select-subscription-plan",
  component: SelectSubscriptionPlan,
  exact: true,
};

export const bookDieticianAppointmentRoute = {
  path: `${dashboardMealPlanRoute.path}/book-dietician-appointment`,
  component: BookDieticianAppointment,
  exact: true,
};

const routes = [
  paymentPortalFormRoute,
  paymentErrorRoute,
  paymentRoute,
  liveSessionRoute,
  provideConsultationInfoRoute,
  walkinFormRoute,
  dashboardRoute,
];

export default routes;
