import { useEffect, useState } from "react";
import { getDietGoals, getDietTypes } from "../services/MealPlanNetworkService";

const useMealPlanOnboarding = () => {
  const [loading, setLoading] = useState(true);
  const [dietGoals, setDietGoals] = useState(null);
  const [dietTypes, setDietTypes] = useState(null);

  useEffect(() => {
    (async () => {
      const promiseResults = await Promise.all([getDietGoals(), getDietTypes()]);
      setDietGoals(promiseResults[0]);
      setDietTypes(promiseResults[1]);
      setLoading(false);
    })();
  }, []);

  return [loading, dietGoals, dietTypes];
};

export default useMealPlanOnboarding;
