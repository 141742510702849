import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { clientSignInRoute } from "../routes";

const AuthOnlyRoute = ({ component, path, exact }) => {
  const userData = useSelector((state) => state.auth.userData);
  const isAuth = userData && Object.keys(userData).length > 0;

  if (isAuth) {
    return <Route component={component} path={path} exact={exact} />;
  }
  return <Redirect to={clientSignInRoute.path} />;
};

export default AuthOnlyRoute;
