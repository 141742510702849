import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopMenu from "../../CommonControls/TopMenu";
import InputFieldFormat from "../../InputFieldFormat";
import axios from "../../../services/Axios";
import { settings } from "../../../config";
import { showWarningNotification } from "../../../utility/Helpers";
import { clientSignInRoute, createRecipeRoute, mealPlanOnboardingRoute } from "../../../routes";
import { useHistory } from "react-router-dom";
import MealPlanFilterSideMenu from "../Shared/MealPlanFilterSideMenu";
import { RiCloseCircleLine } from "react-icons/ri";
import RecipesTiles from "../Recipe/RecipesTiles";
import { resetBasicFoodsAction, setBasicFoodsAction } from "../../../redux/mealPlan/actionCreators";
import { resetRecipesAction, setRecipesAction } from "../../../redux/mealPlan/actionCreators";
import { showErrorNotification } from "../../../utility/Helpers";
import { Input, Spin } from "antd";
import { unapprovedFoodItemStatusID } from "../../../utility/constants";
import Loading from "../../Loading";
import { createMealPlan, getUserValidMealPlans } from "../../../services/MealPlanNetworkService";
import moment from "moment";
import AppStoreLinkButton from "../../AppStoreLinkButton";
import PlayStoreLinkButton from "../../PlayStoreLinkButton";

const MealPlanDashboard = () => {
  const language = useSelector((state) => state.language);
  const login = useSelector((state) => state.auth.login);
  const history = useHistory();

  //Loading evreything here
  const BasicFoods = useSelector((state) => state.mealPlan.basic_foods);
  const Recipes = useSelector((state) => state.mealPlan.recipes);
  const customRecipes = useSelector((state) => state.mealPlan.customRecipes);

  const [basicFoodsFilter, setBasicFoodsFilter] = useState([]);
  const [recipesFilter, setRecipesFilter] = useState([]);
  const [customRecipesFilter, setCustomRecipesFilter] = useState([]);
  const [filtersMenuOpened, setFiltersMenuOpened] = useState(false);
  const [combo, setCombo] = useState({});
  const [comboInputs, setComboInputs] = useState([]);
  const [filterCounter, setfilterCounter] = useState(0);
  const [loading, setloading] = useState(false);
  const [dropdownLoading, setDropdownLoading] = useState(false);
  //FoodTypes
  const [getFoodTypeProvider, setgetFoodTypeProvider] = useState([]);
  const [FoodTypeProviderSelected, setFoodTypeProviderSelected] = useState({});
  const [FoodTypeProvider, setFoodTypeProvider] = useState([]);
  const [FoodTypeCounter, setFoodTypeCounter] = useState([]);
  //FoodCategories
  const [getFoodRecipeCategoryProvider, setgetFoodRecipeCategoryProvider] = useState([]);
  const [FoodRecipeCategoryProviderSelected, setFoodRecipeCategoryProviderSelected] = useState({});
  const [FoodRecipeCategoryProvider, setFoodRecipeCategoryProvider] = useState([]);

  //const [selectedFoodRecipeCategory, setSelectedFoodRecipeCategory] = useState([]);

  ///MP Starts Here
  const dispatch = useDispatch();
  const regionID = language.regionID;
  const currentUser = useSelector((state) => state.auth.userData);
  const [hit, sethit] = useState(false);
  const [createMealPlanLoading, setCreateMealPlanLoading] = useState(false);
  const [loadMoreOffset, setsLoadMoreOffset] = useState(0);
  const [loadMoreCustomRecipesOffset, setsLoadMoreCustomRecipesOffset] = useState(0);
  const [pageLoading, setpageLoading] = useState(false);
  const [pageLoadingRecipes, setPageLoadingRecipes] = useState(false);
  const [pageLoadingCustomRecipes, setPageLoadingCustomRecipes] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const [loadMoreVisible, setLoadMoreVisible] = useState(false);
  const [loadMoreRecipesVisible, setLoadMoreRecipesVisible] = useState(false);
  const [loadMoreCustomRecipesVisible, setLoadMoreCustomRecipesVisible] = useState(false);
  const { Search } = Input;
  //Meal Plan Search
  const [mealPlanSearch, setMealPlanSearch] = useState({});
  //FoodCategory
  const [selectedFoodCategory, setSelectedFoodCategory] = useState([]);
  //FoodType
  const [selectedFoodType, setSelectedFoodType] = useState([]);
  const [selectedFoodTypeCounter, setSelectedFoodTypeCounter] = useState({});
  //showHideTiles
  const [showBrandedFoods, setShowBrandedFoods] = useState(true);
  const [showCommonFoods, setShowCommonFoods] = useState(true);
  const [showMyFoods, setShowMyFoods] = useState(true);
  const [showMyRecipes, setShowMyRecipes] = useState(true);
  const [showRecipes, setShowRecipes] = useState(true);
  const [foodTypeDisplay, setFoodTypeDisplay] = useState({});
  const [foodCategoryDisplay, setFoodCategoryDisplay] = useState({});
  const [finalSelectedFoodCategory, setFinalSelectedFoodCategory] = useState([]);
  const [showLoadMoreRecipes, setShowLoadMoreRecipes] = useState(true);
  const [showLoadMoreCustomRecipes, setShowLoadMoreCustomRecipes] = useState(true);
  ///MP Stops Here

  const languageType = useSelector((state) =>
    state.language.general?.preferredlanguage === undefined ? "en" : state.language.general.preferredlanguage
  );
  const [formData, setFormData] = useState({
    offset: 0,
    limit: 6,
    ingredientName: "",
    ingredientCategory: [],
  });

  const openFiltersSideMenu = () => {
    setFiltersMenuOpened(true);
  };

  const closeFiltersSideMenu = () => {
    setFiltersMenuOpened(false);
  };

  const [counter, setcounter] = useState({
    //All Counter Id's
    FoodRecipeCategoryProviderId: false,
  });

  const fetchFoodRecipeCategories = async () => {
    try {
      setDropdownLoading(true);
      let FoodRecipeCategory = await getFoodRecipeCategoryFetcher();
      let FoodTypeProvider = await getFoodTypeFetcher();
      let groups = [
        {
          name: "FoodRecipeCategory",
          type: "group",
          items: FoodRecipeCategory,
        },
        {
          name: "FoodType",
          type: "group",
          items: FoodTypeProvider,
        },
      ];
      //setComboInputs(groups);
      setDropdownLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (history?.location?.state && history?.location?.state?.basicfoods && history?.location?.state?.basicfoods.length > 0) {
      setBasicFoodsFilter(history?.location?.state?.basicfoods);
    }
  }, [BasicFoods]);

  useEffect(() => {
    if (history?.location?.state && history?.location?.state?.recipes && history?.location?.state?.recipes.length > 0) {
      setRecipesFilter(history?.location?.state?.recipes);
    }
  }, [Recipes, history]);

  useEffect(() => {
    if (
      history?.location?.state &&
      history?.location?.state?.customRecipes &&
      history?.location?.state?.customRecipes.length > 0
    ) {
      setCustomRecipesFilter(history?.location?.state?.customRecipes);
    }
  }, [customRecipes, history]);

  const clearFilterResult = async () => {
    sethit(true);
    setloading(true);
    const response = await axios.get("/getIngredients", {
      params: {
        offset: 0,
        limit: 6,
      },
    });
    setBasicFoodsFilter(response.data.response);
    setloading(false);
    setpageLoading(false);
    setShowLoadMore(true);
    sethit(false);
    // setMealPlanSearchTerm({name:"", value:""});
    setsLoadMoreOffset(0);
    setsLoadMoreCustomRecipesOffset(0);
  };

  const getFilterResult = async () => {
    sethit(true);
    setloading(true);
    var params = { offset: 0, limit: 6 };
    const response = await axios.get("/getIngredients", {
      params: formData,
    });
    setBasicFoodsFilter(response.data.response);
    //getRecipeFilterResult(params);
    setloading(false);
    setpageLoading(false);
    setShowLoadMore(true);
    setShowLoadMoreCustomRecipes(true);
    sethit(false);
    // setMealPlanSearchTerm({name:"", value:""});
    setsLoadMoreOffset(0);
    setsLoadMoreCustomRecipesOffset(0);
  };

  const getRecipesFilterResults = async ({ searchText }) => {
    sethit(true);
    setloading(true);
    const response = await axios.get("/getRecipes", {
      params: { ...formData, comboInput: searchText?.trim(), isCustomFilter: searchText?.trim() ? true : false },
    });
    setRecipesFilter(response.data.response);
    //getRecipeFilterResult(params);
    setPageLoadingRecipes(false);
    setShowLoadMore(true);
    sethit(false);
    // setMealPlanSearchTerm({name:"", value:""});
    setsLoadMoreOffset(0);
  };

  const getCustomRecipesFilterResults = async ({ searchText }) => {
    sethit(true);
    setloading(true);
    const response = await axios.get("/getRecipes", {
      params: {
        ...formData,
        statusId: unapprovedFoodItemStatusID,
        userId: (typeof currentUser === 'object' && currentUser !== null) ? currentUser.id: '', // Critical River, Varun Mishra, MealPlan Dashboard error due to currentUser is string
        comboInput: searchText?.trim(),
        isCustomFilter: searchText?.trim() ? true : false,
      },
    });
    setCustomRecipesFilter(response.data.response);
    setPageLoadingCustomRecipes(false);
    setShowLoadMoreCustomRecipes(true);
    sethit(false);
    setsLoadMoreCustomRecipesOffset(0);
  };

  useEffect(() => {
    //setMealPlanSearchTerm({name:"", value:""});
    getFilterResult();
    getRecipesFilterResults({});
    getCustomRecipesFilterResults({});
  }, [formData]);

  const fetchRecipes = useCallback(async () => {
    dispatch(resetRecipesAction());
    setShowLoading(true);
    try {
      const response = await axios.get("/getRecipes", {
        params: { offset: 0, limit: 6 },
      });
      console.log(response.data);
      dispatch(setRecipesAction({ recipes: response.data.response }));
    } catch (e) {
      console.log("--- getIngredients error");
      console.log(e);
      showErrorNotification();
    } finally {
      setShowLoading(false);
    }
  }, [dispatch, currentUser, regionID]);

  useEffect(() => {
    fetchRecipes();
  }, [fetchRecipes]);

  const fetchBasicFoods = useCallback(async () => {
    dispatch(resetBasicFoodsAction());
    //setMealPlanSearchTerm({name:"", value:""});
    setShowLoading(true);
    try {
      const response = await axios.get("/getIngredients", {
        params: { offset: 0, limit: 6 },
      });
      console.log("Ingree", response.data);
      dispatch(setBasicFoodsAction({ basicfoods: response.data.response }));
    } catch (e) {
      console.log("--- getIngredients error");
      console.log(e);
      showErrorNotification();
    } finally {
      setShowLoading(false);
    }
  }, [dispatch, currentUser, regionID]);

  useEffect(() => {
    fetchBasicFoods();
  }, [fetchBasicFoods]);

  const showMoreBasicFoods = () => {
    setpageLoading(true);
    getBasicFoodFilterResult({
      ...formData,
      offset: loadMoreOffset + formData.limit,
    });
    setLoadMoreVisible(true);
    setsLoadMoreOffset(loadMoreOffset + formData.limit);
  };

  const showLessBasicFoods = () => {
    // var params = {
    //   offset: 0,
    //   limit: 6,
    // };
    setpageLoading(true);
    getFilterResult();
    setLoadMoreVisible(false);
    setsLoadMoreOffset(0);
  };

  const getBasicFoodFilterResult = async (data) => {
    sethit(true);
    const response = await axios.get("/getIngredients", {
      params: data,
    });
    if (response.data.response.length === 0) {
      //showErrorNotification("End of results");
      setShowLoadMore(false);
    } else {
      let filterOut = [];
      setShowLoadMore(true);
      response.data.response.map((item) => {
        if (basicFoodsFilter.filter((a) => a.id === item.id).length === 0) {
          filterOut.push(item);
        }
      });
      setBasicFoodsFilter((currentArray) => [...currentArray, ...filterOut]);
    }
    setpageLoading(false);
    sethit(false);
  };

  const showMoreRecipes = () => {
    setPageLoadingRecipes(true);
    getRecipeFilterResult({
      ...formData,
      offset: loadMoreOffset + formData.limit,
    });
    setLoadMoreRecipesVisible(true);
    setsLoadMoreOffset(loadMoreOffset + formData.limit);
  };

  const showMoreCustomRecipes = () => {
    setPageLoadingCustomRecipes(true);
    getCustomRecipeFilterResult({
      ...formData,
      offset: loadMoreOffset + formData.limit,
    });
    setLoadMoreCustomRecipesVisible(true);
    setsLoadMoreCustomRecipesOffset(loadMoreOffset + formData.limit);
  };

  const showLessRecipes = () => {
    // var params = {
    //   offset: 0,
    //   limit: 6,
    // };
    setPageLoadingRecipes(true);
    getRecipesFilterResults({});
    setLoadMoreRecipesVisible(false);
    setsLoadMoreOffset(0);
  };

  const showLessCustomRecipes = () => {
    setPageLoadingCustomRecipes(true);
    getCustomRecipesFilterResults({});
    setLoadMoreCustomRecipesVisible(false);
    setsLoadMoreOffset(0);
  };

  const getRecipeFilterResult = async (data) => {
    sethit(true);
    const response = await axios.get("/getRecipes", {
      params: data,
    });
    if (response.data.response.length === 0) {
      //showErrorNotification("End of results");
      setShowLoadMoreRecipes(false);
    } else {
      let filterOut = [];
      setShowLoadMoreRecipes(true);
      response.data.response.map((item) => {
        if (recipesFilter.filter((a) => a.id === item.id).length === 0) {
          filterOut.push(item);
        }
      });
      setRecipesFilter((currentArray) => [...currentArray, ...filterOut]);
    }
    setPageLoadingRecipes(false);
    sethit(false);
  };

  const getCustomRecipeFilterResult = async (data) => {
    sethit(true);
    const response = await axios.get("/getRecipes", {
      params: { ...data, statusId: unapprovedFoodItemStatusID, userId: currentUser.id },
    });
    if (response.data.response.length === 0) {
      //showErrorNotification("End of results");
      setShowLoadMoreCustomRecipes(false);
    } else {
      let filterOut = [];
      setShowLoadMoreCustomRecipes(true);
      response.data.response.forEach((item) => {
        if (customRecipesFilter.filter((a) => a.id === item.id).length === 0) {
          filterOut.push(item);
        }
      });
      setCustomRecipesFilter((currentArray) => [...currentArray, ...filterOut]);
    }
    setPageLoadingCustomRecipes(false);
    sethit(false);
  };

  const getFoodRecipeCategoryFetcher = async () => {
    let newFormat = [];
    await axios.get(`${settings.apiBaseURL}/getFoodAndRecipeCategories`).then((response) => {
      const data = response.data.response;
      console.log(data);
      setFoodRecipeCategoryProvider(response.data.response);
      data.map((item) => {
        if (languageType === "en") newFormat.push({ name: item.group_name, value: [item.group_name, item.id, item.group_name] });
        if (languageType === "ar")
          newFormat.push({ name: item.group_name, value: [item.group_name, item.id, item.group_name_ar] });
        if (languageType === "ku")
          newFormat.push({ name: item.group_name, value: [item.group_name, item.id, item.group_name_ku] });
      });
      console.log(",", data);
      setgetFoodRecipeCategoryProvider(newFormat);
    });
    return newFormat;
  };
  const getFoodTypeFetcher = async () => {
    let newFormat = [];
    await axios.get(`${settings.apiBaseURL}/getFoodTypes`).then((response) => {
      const data = response.data.response;
      console.log(data);
      setFoodTypeProvider(response.data.response);
      data.map((item) => {
        if (languageType === "en") newFormat.push({ name: item.name, value: [item.name, item.id] });
        if (languageType === "ar") newFormat.push({ name: item.name, value: [item.name, item.id] });
        if (languageType === "ku") newFormat.push({ name: item.name, value: [item.name, item.id] });
      });
      setgetFoodTypeProvider(newFormat);
    });
    return newFormat;
  };

  const FoodRecipeCategoryHandler = (value, e) => {
    console.log(value);
    //setCombo({ name: e.name, value: e.value });
    //setFoodRecipeCategoryProviderSelected({ name: e.group_name, value: e.value });
    setFormData((prevState) => {
      return {
        ...prevState,
        // comboInput: e.name,
        FoodRecipeCategoryProviderId: value,
        offset: 0,
      };
    });
    if (!counter.FoodRecipeCategoryProviderId) {
      setfilterCounter((prevState) => prevState + 1);
      setcounter((prevState) => {
        return {
          ...prevState,
          FoodRecipeCategoryProviderId: true,
        };
      });
    }
  };

  const FoodTypeProviderHandler = (value, e) => {
    setCombo({ name: e.name, value: e.value });
    setFoodTypeProviderSelected({ name: e.name, value: value });
    setFormData((prevState) => {
      return {
        ...prevState,
        // comboInput: e.name,
        FoodRecipeCategoryProviderId: value,
        offset: 0,
      };
    });
    if (!counter.FoodRecipeCategoryProviderId) {
      setfilterCounter((prevState) => prevState + 1);
      setcounter((prevState) => {
        return {
          ...prevState,
          FoodTypeProviderId: true,
        };
      });
    }
  };

  useEffect(() => {
    //getLanguage.length === 0 && getLanguages();
    //getRegions.length === 0 && regions();
    fetchFoodRecipeCategories();
  }, []);

  const closeFoodRecipeCategoryProviderSelected = () => {
    setFoodRecipeCategoryProviderSelected({});
    setCombo({});
    setFormData((prevState) => {
      return {
        ...prevState,
        FoodRecipeCategoryProviderId: "",
      };
    });
    // if (Object.keys(speciality).length !== 0) {
    //   setFormData((prevState) => {
    //     return {
    //       ...prevState,
    //       specialityId: speciality.value,
    //       offset: 0,
    //     };
    //   });
    // }
    // if (
    //   Object.keys(speciality).length === 0 &&
    //   Object.keys(doctor).length === 0
    // ) {
    //   setFormData((prevState) => {
    //     return {
    //       ...prevState,
    //       comboInput: "",
    //       practitionerFirstName: "",
    //       practitionerLastName: "",
    //       FoodRecipeCategoryProviderId: "",
    //       specialityId: "",
    //       offset: 0,
    //     };
    //   });
  };
  const clearFoodTypeFilter = () => {
    setShowBrandedFoods(true);
    setShowCommonFoods(true);
    setShowMyFoods(true);
    setShowMyRecipes(true);
    setShowRecipes(true);
    setFoodTypeDisplay({});
    setSelectedFoodType([]);
    clearFilterResult();
  };

  const clearFoodCategoryFilter = () => {
    setFoodCategoryDisplay({});
    setSelectedFoodCategory([]);
    clearFilterResult();
  };

  const clearMealplanSearch = async (value) => {
    formData.ingredientName = "";
    const response = await axios.get("/getIngredients", {
      params: formData,
    });
    //setMealPlanSearchTerm({name:"", value:""});
    setMealPlanSearch({});
  };

  const onSearch = (value) => {
    performSearch(value);
  };

  const onSearchEnter = async (e) => {
    performSearch(e.target.value);
  };

  const performSearch = async (searchTerm) => {
    // console.log("Enter Press" + searchTerm);
    getRecipesFilterResults({ searchText: searchTerm });
    getCustomRecipesFilterResults({ searchText: searchTerm });
    sethit(true);
    setloading(true);
    setMealPlanSearch({ name: searchTerm, value: searchTerm });
    formData.ingredientName = !!searchTerm ? searchTerm : "";
    const response = await axios.get("/getIngredients", {
      params: formData,
    });
    setBasicFoodsFilter(response.data.response);
    setloading(false);
    setpageLoading(false);
    setShowLoadMore(true);
    sethit(false);
    setsLoadMoreOffset(0);
  };

  const closeFoodTypeProviderSelected = () => {
    setFoodTypeProviderSelected({});
    setCombo({});
    setFormData((prevState) => {
      return {
        ...prevState,
        FoodRecipeCategoryProviderId: "",
      };
    });
    // if (Object.keys(speciality).length !== 0) {
    //   setFormData((prevState) => {
    //     return {
    //       ...prevState,
    //       specialityId: speciality.value,
    //       offset: 0,
    //     };
    //   });
    // }
    // if (
    //   Object.keys(speciality).length === 0 &&
    //   Object.keys(doctor).length === 0
    // ) {
    //   setFormData((prevState) => {
    //     return {
    //       ...prevState,
    //       comboInput: "",
    //       practitionerFirstName: "",
    //       practitionerLastName: "",
    //       FoodRecipeCategoryProviderId: "",
    //       specialityId: "",
    //       offset: 0,
    //     };
    //   });
  };

  const ComboHandler = (e, target) => {
    setCombo({ name: e.target.name, value: e.target.value });
    //setDoctor({ name: target.name, value: target.value });
    setFormData((prevState) => {
      return {
        ...prevState,
        comboInput: e.target.value,
        ingredientName: e.target.value,
        offset: 0,
      };
    });
    getFilterResult();
    //if (target.groupName === "Specialities") specialityHandler(e, target);
    //if (target.groupName === "Insurance") insuranceHandler(e, target);
  };

  const [prev, setprev] = useState("");

  const handleFilter = (items) => {
    return (searchValue) => {
      if (searchValue.length === 0) {
        return comboInputs;
      }
      const updatedItems = items.map((list) => {
        const newItems = list.items.filter((item) => {
          return item.name.toLowerCase().includes(searchValue.toLowerCase());
        });
        return { ...list, items: newItems };
      });
      if (searchValue.length > 2) {
        setprev(searchValue);
        if (prev !== searchValue) {
          setFormData((prevState) => {
            return {
              ...prevState,
              comboInput: searchValue,
              ingredientName: searchValue,
              offset: 0,
            };
          });
          setCombo({ name: searchValue, value: searchValue });
        }
      }
      return updatedItems;
    };
  };

  const passFoodCategoryFilterValues = (e) => {
    console.log(finalSelectedFoodCategory);
    setFormData((prevState) => {
      return {
        ...prevState,
        ingredientCategory: finalSelectedFoodCategory,
        offset: 0,
      };
    });
  };

  const passFoodTypeFilterValues = (e) => {
    let typeIds = [];
    let typeNames = [];
    debugger;
    console.log(selectedFoodType);
    console.log(FoodTypeCounter);
    if (selectedFoodType.length > 1) {
      setSelectedFoodTypeCounter({ value: selectedFoodType.length });
      selectedFoodType.forEach((item) => {
        // displayCategory.push(item[0]);
        typeNames.push(item[0]);
        typeIds.push(item[1]);
      });
    }
    setFormData((prevState) => {
      return {
        ...prevState,
        ingredientType: "",
        offset: 0,
      };
    });
  };

  const truncate = (str) => {
    return (!!str && str.length) > 10 ? str.substring(0, 9) + ".." : str;
  };

  const onCreateRecipeTap = () => {
    if (!login) {
      showWarningNotification("Please login to IZI Health to create recipe");
      history.push(clientSignInRoute.path, {
        nextRoute: createRecipeRoute.path,
      });
    } else {
      history.push(createRecipeRoute.path, { recipe: null });
    }
  };

  const handleCreateMealPlanClicked = async () => {
    setCreateMealPlanLoading(true);
    history.push(mealPlanOnboardingRoute.path);
    setCreateMealPlanLoading(false);

    // Commented by mahi
    // setCreateMealPlanLoading(true);
    // const userNonExpiredMealPlans = await getUserValidMealPlans();
    // console.log("All user id meal",{ userNonExpiredMealPlans });
    // setCreateMealPlanLoading(false);
    // if (userNonExpiredMealPlans && userNonExpiredMealPlans.length > 0) {
    //   history.push(mealPlanOnboardingRoute.path);
    // } else {
    //   const now = new Date();
    //   const currentMoment = moment();
    //   await createMealPlan({
    //     name: "My Meal Plan",
    //     description: "My Meal Plan",
    //     caloriesInTake: 0,
    //     totalMealsAdded: 0,
    //     isAutoCreated: true,
    //     isAutoGenerated: false,
    //     planLenghInDays: 30,
    //     startDateMoment: currentMoment,
    //     endDateMoment: currentMoment.add(1, "month"),
    //     imageURL: null,
    //     plannedMeals: [],
    //     plannedMealsIngredients: [],
    //     timeZoneDifferenceInMinutes: now.getTimezoneOffset(),
    //     nutritionInfo: {
    //       mp_calories: 0,
    //       mp_carbs: 0,
    //       mp_fats: 0,
    //       mp_protien: 0,
    //     },
    //   });
    //   history.push(mealPlanOnboardingRoute.path);
    // }
  };

  let content = (
    <section className="mealplan-container">
      {/* <MealPlanHelpAlert variant="info" /> */}
      <div className="d-flex flex-sm-row flex-column w-100 justify-content-start">
        <div className="d-flex">
          <p className="mobile-redirect">{language.lang["mobile-redirect-web"] ?? "Create your meal plan using our app"}</p>
        </div>
        <div className="d-flex p-2">
          <AppStoreLinkButton className="FooterLink" />
          <PlayStoreLinkButton className="FooterLink ml-3" />
        </div>
      </div>
      <section className="row pt-1">
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex flex-row justify-content-start w-100 mp-px-4">
            <div className="p2">
              <div className="d-flex justify-content-center w-100 align-items-center downArrow py-2">
                <div onClick={handleCreateMealPlanClicked} className="mp-top-links btn">
                  <i className="fas fa-plus mr-1"></i>
                  <label className="showlabels">{language.lang["create-meal-plan"] ?? "Create Meal Plan"}</label>
                </div>
              </div>
            </div>
            {/* <div className="p2 mp-top-links-ml">
          <div className="d-flex justify-content-center w-100 align-items-center downArrow py-2">
            <div className="mp-top-links btn">
              <i className="fas fa-plus mr-1"></i>
              <label className="showlabels">View meal plans</label>
            </div>
          </div>
        </div> */}
          </div>
          <div className="d-flex flex-row justify-content-start w-100 mp-px-4">
            <div className="p2">
              <div className="d-flex justify-content-center w-100 align-items-center downArrow py-2">
                <button className="mp-top-links btn" onClick={onCreateRecipeTap}>
                  <i className="fas fa-plus mr-1"></i>
                  <label className="showlabels">{language.lang["createRecipe"] ?? "Create Recipe"}</label>
                </button>
              </div>
            </div>
            {/* <div className="p2 mp-top-links-ml">
          <div className="d-flex justify-content-center w-100 align-items-center downArrow py-2">
            <div className="mp-top-links btn">
              <i className="fas fa-plus mr-1"></i>
              <label className="showlabels">Create custom food</label>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </section>
      <section className="search-section row pt-1">
        <div className="row">
          <div className="d-flex flex-sm-row flex-row w-100">
            <div className="order1">
              <InputFieldFormat
                show={Object.keys(combo).length > 0}
                element={
                  <Search
                    placeholder="Search for recipies,ingredients and food items"
                    allowClear={clearMealplanSearch}
                    enterButton
                    size="large"
                    onPressEnter={onSearchEnter}
                    onSearch={onSearch}
                    className="mp-search-field"
                  />
                }
              />
            </div>
            {/* <div className="order2 ml-2">
          <button className="btn btn-white-filter" onClick={openFiltersSideMenu}>
            <i className="fas fa-sliders-v fa-lg text-logo-color"></i>
          </button>
        </div> */}
          </div>
          <div className="row">
            <div
              className="d-flex d-sm-flex d-md-flex d-lg-flex justify-content-start mp-counter-align flex-wrap"
              style={{ gap: "4px" }}
            >
              {/* <div className="p2 mp-counter">
        {Object.keys(mealPlanSearch).length !== 0 && (
          <div className="filter-selected">
            <div>{mealPlanSearch.name}</div>
            <button disabled={loading} onClick={clearMealplanSearch} className="btn p-2" style={{ zIndex: 1, color: "#3f83ec"}}>
              <RiCloseCircleLine size={25} />
            </button>
          </div>
        )}
        </div> */}
              <div className="p2 mp-counter">
                {Object.keys(foodTypeDisplay).length !== 0 && (
                  <div className="filter-selected">
                    <div>
                      {selectedFoodType.length > 1
                        ? truncate(foodTypeDisplay) + " and " + (selectedFoodType.length - 1) + " more"
                        : foodTypeDisplay}
                    </div>
                    <button
                      disabled={loading}
                      onClick={clearFoodTypeFilter}
                      className="btn p-2"
                      style={{ zIndex: 1, color: "#3f83ec" }}
                    >
                      <RiCloseCircleLine size={25} />
                    </button>
                  </div>
                )}
              </div>
              <div className="p2 mp-counter">
                {Object.keys(foodCategoryDisplay).length !== 0 && (
                  <div className="filter-selected">
                    <div>
                      {selectedFoodCategory.length > 1
                        ? truncate(foodCategoryDisplay) + " and " + (selectedFoodCategory.length - 1) + " more"
                        : foodCategoryDisplay}
                    </div>
                    <button
                      disabled={loading}
                      onClick={clearFoodCategoryFilter}
                      className="btn p-2"
                      style={{ zIndex: 1, color: "#3f83ec" }}
                    >
                      <RiCloseCircleLine size={25} />
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* <div className={"d-flex d-sm-flex d-md-flex d-lg-flex justify-content-end bd-highlight mb-1 ml-4 mt-1 filter-container flex-column" +(filterCounter > 0 && "")}>
      <div className="d-flex d-sm-flex d-md-flex d-lg-flex justify-content-end bd-highlight mb-1 ml-3  align-items-center justify-content-around mm-card more-filters mt-md-2 position-relative">
        <span className="more">{language.lang.moreFilters}</span>
      </div>
    </div> */}
          </div>
          <div className="row">
            {showRecipes ? (
              <RecipesTiles
                recipes={recipesFilter ?? []}
                showLoading={showLoading}
                showLoadMoreRecipes={showLoadMoreRecipes}
                pageLoading={pageLoadingRecipes}
                showMoreRecipes={showMoreRecipes}
                showLessRecipes={showLessRecipes}
                loadMoreRecipesVisible={loadMoreRecipesVisible}
              />
            ) : (
              ""
            )}
            {/* {showCommonFoods ? (
          <BasicFoodTiles
            basicfoods={basicFoodsFilter}
            formData={history?.location?.state}
            searchData={formData}
            showLoading={showLoading}
            showLoadMore={showLoadMore}
            pageLoading={pageLoading}
            showMoreBasicFoods={showMoreBasicFoods}
            showLessBasicFoods={showLessBasicFoods}
            loadMoreVisible={loadMoreVisible}
          />
        ) : (
          ""
        )} */}
            {/* {showBrandedFoods ? <BrandedFoodTiles /> : ""} */}
            {currentUser?.id && (
              <RecipesTiles
                title={language.lang["custom-recipes"] ?? "My Recipes"}
                recipes={customRecipesFilter ?? []}
                showLoadMoreRecipes={showLoadMoreCustomRecipes}
                pageLoading={pageLoadingCustomRecipes}
                showMoreRecipes={showMoreCustomRecipes}
                showLessRecipes={showLessCustomRecipes}
                loadMoreRecipesVisible={loadMoreRecipesVisible}
              />
            )}
          </div>
        </div>
      </section>
    </section>
  );

  if (createMealPlanLoading) {
    content = (
      <Spin
        indicator={
          <div className="d-flex justify-content-center mt-5">
            <Loading />
          </div>
        }
      >
        {content}
      </Spin>
    );
  }

  return (
    <div className="mealplan-spec">
      <MealPlanFilterSideMenu
        isOpened={filtersMenuOpened}
        onClosePressed={closeFiltersSideMenu}
        formData={formData}
        setFormData={setFormData}
        setfilterCounter={setfilterCounter}
        setcounter={setcounter}
        setCombo={setCombo}
        FoodTypeProviderSelected={FoodTypeProviderSelected}
        getFoodTypeProvider={getFoodTypeProvider}
        FoodTypeProviderHandler={FoodTypeProviderHandler}
        setFoodTypeProviderSelected={setFoodTypeProviderSelected}
        closeFoodTypeProviderSelected={closeFoodTypeProviderSelected}
        FoodRecipeCategoryProviderSelected={FoodRecipeCategoryProviderSelected}
        getFoodRecipeCategoryProvider={getFoodRecipeCategoryProvider}
        FoodRecipeCategoryHandler={FoodRecipeCategoryHandler}
        setFoodRecipeCategoryProviderSelected={setFoodRecipeCategoryProviderSelected}
        closeFoodRecipeCategoryProviderSelected={closeFoodRecipeCategoryProviderSelected}
        //Food Types
        passFoodTypeFilterValues={passFoodTypeFilterValues}
        selectedFoodType={selectedFoodType}
        setSelectedFoodType={setSelectedFoodType}
        setFoodTypeDisplay={setFoodTypeDisplay}
        //Food Categories
        passFoodCategoryFilterValues={passFoodCategoryFilterValues}
        selectedFoodCategory={selectedFoodCategory}
        setSelectedFoodCategory={setSelectedFoodCategory}
        setFoodCategoryDisplay={setFoodCategoryDisplay}
        setFinalSelectedFoodCategory={setFinalSelectedFoodCategory}
        //show hide tiles
        setShowBrandedFoods={setShowBrandedFoods}
        setShowCommonFoods={setShowCommonFoods}
        setShowMyFoods={setShowMyFoods}
        setShowMyRecipes={setShowMyRecipes}
        setShowRecipes={setShowRecipes}
      />
      <section className="row d-flex justify-content-between w-100 top-menu-pad">
        <TopMenu
          shouldHideBackButton={false}
          shouldHideProfileButton={false}
          shouldHideNotificationButton={false}
          shouldHideLogoButton={false}
          pageTitle={language.lang["meal-plan"]}
          hideCustomclass={true}
        />
      </section>
      {content}
    </div>
  );
};

export default MealPlanDashboard;
