import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClinicPatientAppointment from "./ClinicPatientAppointment";

const ClinicPendingCard = () => {
  // const { isOnline } = useSelector((state) => state.practitioner);
  const language = useSelector((state) => state.language);
  const notifArr = useSelector((state) => state.clinic.pending_appointments);
  // const walkinAppointments = nextPatientsArr.filter((appointment) => appointment.appointment_type == "Walk In");

  return (
    <div className="next-patient mm-card mt-5 mt-md-0 min-card-width pending-card">
      <div className="patients-next-arr px-2">
        <div className="d-flex justify-content-between">
          <p className="title">{language.lang.pending_requests}</p>
        </div>
        <div className="d-flex">
          <p className="col1 sub-title">{language.lang["patient-name"]}</p>
          <p className="col1 sub-title">{language.lang.time}</p>
          <p className="col1 sub-title">{language.lang.doctor}</p>
          <p className="col1 sub-title">{language.lang.date}</p>
        </div>
        <div className="ind-patient flex-column">
          {notifArr.length > 0 ? (
            notifArr.map((el, i) => {
              return (
                <ClinicPatientAppointment
                  key={el.appointmentId}
                  el={el}
                  i={i}
                />
              );
            })
          ) : (
            <div className="d-flex justify-content-center align-items-center w-100">
              {language.lang.no_pending_appointments}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClinicPendingCard;
