import { useSelector } from "react-redux";
import videoImg from "../assets/images/MealPlan2.png";
const MostPopularTopPractitioner = () =>{
  const language = useSelector((state) => state.language);
    return (
             <div>
                <div className="d-flex justify-content-around align-items-center flex-row p-2" style={{gap:'20px',borderRadius:'15px'}}>
                  <div className="most-upcoming-img" style={{width:'60px',height:'60px'}}>
                    <img src={videoImg} alt="img-thumb" />
                  </div>
                  <div>
                    <div style={{fontSize:'18px',fontWeight:'600'}}>Shihab Aiavi</div>
                    <div className="d-flex" style={{fontSize:'18px',gap:'5px'}}>
                      <div style={{fontWeight:'600'}}>{language.lang.specialization}:</div>
                      <div>Psychologist</div>
                    </div>
                  </div>
                  <div className="ml-2" style={{backgroundColor:'#ffb946',width:'30px',height:'30px',borderRadius:'5px'}}></div>
                </div>
            </div>
    );
;}
export default MostPopularTopPractitioner;