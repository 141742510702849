import ReactLoading from "react-loading";

import config from "../config";

const Loading = ({ height, width, className }) => {
  return (
    <div className={`loading d-flex justify-content-center align-items-center ${className ? className : ""}`}>
      <ReactLoading height={height} width={width} type="spin" color={config.logoColor} />
    </div>
  );
};

export default Loading;
