const SideMenuField = ({ name, field, isExpanded }) => {
  return (
    <div className={ "d-flex justify-content-between align-items-center nav-fields" } >
      {isExpanded && <div className="nav-label">{name}</div>}
      {field}
    </div>
  );
};

export default SideMenuField;
