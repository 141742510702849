import axios from "../../../services/Axios";

const actions = {
  SET_AGORA_CHANNEL: "SET_AGORA_CHANNEL",
  SET_AGORA_CLIENT: "SET_AGORA_CLIENT",
  SEND_MESSAGE: "SEND_MESSAGE",
  LEAVE_CHANNEL: "LEAVE_CHANNEL",
  ADD_MESSAGE: "ADD_MESSAGE",
  SAVE_MESSAGE: "SAVE_MESSAGE",
  SET_CHAT_MESSAGES: "SET_CHAT_MESSAGES",
  SET_MESSAGE_SAVED: "SET_MESSAGE_SAVED",
};
export const setChatMessagesAction = (messages, userID) => {
  return {
    type: actions.SET_CHAT_MESSAGES,
    payload: (messages || []).map((message) => {
      message.isSent = userID === message.user_id;
      return message;
    }),
  };
};

export const fetchChatMessages = async (channelID) => {
  try {
    const res = await axios.get("/getMessages", {
      params: {
        channelId: channelID,
      },
    });
    console.log("fetchChat", res);

    return res.data.response;
  } catch (e) {
    console.log("--- fetchChatMessages error");
    console.log(e);
    return null;
  }
};
export const setMessageSaved = (data) => {
  return {
    type: actions.SET_MESSAGE_SAVED,
    payload: { isMessageSaved: data },
  };
};

export const saveMessage = async (data) => {
  const res = await axios.post("/saveMessage", data);
  console.log("--- save msg", res.data);
  return res.data.response.data !== null;
};

export const addMessageAction = (message) => {
  return {
    type: actions.ADD_MESSAGE,
    payload: message,
  };
};

export const leaveChannelAction = () => {
  return {
    type: actions.LEAVE_CHANNEL,
  };
};

export const setAgoraChannelAction = (channel) => {
  return {
    type: actions.SET_AGORA_CHANNEL,
    payload: channel,
  };
};

export const setAgoraClientAction = (client) => {
  return {
    type: actions.SET_AGORA_CLIENT,
    payload: client,
  };
};
export default actions;