import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import useAllergiesAndExclusions from "../../../customHooks/useAllergiesAndExclusions";
import { mealPlanOnboardingRoute } from "../../../routes";
import Loading from "../../Loading";
import CustomExclusionsModal from "./CustomExclusionsModal";
import ExclusionListItem from "./ExclusionListItem";
import MajorExclusionListItem from "./MajorExclusionListItem";
import { SelectedCustomExclusionItem } from "./SelectedCustomExclusionItem";

const AllergiesAndExclusions = ({
  onSelectedMajorExclusionsChanged,
  onSelectedAllergiesChanged,
  onSelectedSubExclusionsChanged,
  onSelectedCustomExclusionsChanged,
}) => {
  const location = useLocation();
  const language = useSelector((state) => state.language.lang);
  const [showCustomExclusionsModal, setShowCustomExclusionsModal] = useState(false);
  const [selectedAllergies, setSelectedAllergies] = useState(null);
  const [selectedMajorExclusions, setSelectedMajorExclusions] = useState(null);
  const [selectedSubExclusions, setSelectedSubExclusions] = useState(null);
  const [selectedCustomExclusions, setSelectedCustomExclusions] = useState([]);
  const [loading, allergiesAndExclusions] = useAllergiesAndExclusions({ dietType: location.state?.dietType?.id });

  useEffect(() => {
    if (!selectedCustomExclusions) return;
    if (!onSelectedCustomExclusionsChanged) return;
    onSelectedCustomExclusionsChanged(selectedCustomExclusions);
  }, [selectedCustomExclusions, onSelectedCustomExclusionsChanged]);

  useEffect(() => {
    if (!selectedAllergies) return;
    if (!onSelectedAllergiesChanged) return;
    onSelectedAllergiesChanged(selectedAllergies);
  }, [selectedAllergies, onSelectedAllergiesChanged]);

  useEffect(() => {
    if (!selectedMajorExclusions) return;
    if (!onSelectedMajorExclusionsChanged) return;
    onSelectedMajorExclusionsChanged(selectedMajorExclusions);
  }, [selectedMajorExclusions, onSelectedMajorExclusionsChanged]);

  useEffect(() => {
    if (!selectedSubExclusions) return;
    if (!onSelectedSubExclusionsChanged) return;
    onSelectedSubExclusionsChanged(selectedSubExclusions);
  }, [selectedSubExclusions, onSelectedSubExclusionsChanged]);

  useEffect(() => {
    if (!allergiesAndExclusions) return;
    if (selectedAllergies) return;
    if (selectedMajorExclusions) return;
    if (selectedSubExclusions) return;
    const selectedAllergiesMap = new Map();
    for (const allergie of allergiesAndExclusions.allergies) {
      if (allergie.is_exclusion) {
        selectedAllergiesMap.set(allergie.exclusionMajor.id ?? allergie.exclusionSub.id ?? allergie.id, allergie);
      }
    }
    setSelectedAllergies(selectedAllergiesMap);
    const majorExclusionsMap = new Map();
    const subExclusionsMap = new Map();
    for (const exclusion of allergiesAndExclusions.majorExclusions) {
      let addedSubExclusions = false;
      if (exclusion.isExcluded || selectedAllergiesMap.has(exclusion.id)) {
        majorExclusionsMap.set(exclusion.id, exclusion);
        for (const subExclusion of exclusion.subExclusions ?? []) {
          addedSubExclusions = true;
          subExclusionsMap.set(subExclusion.id, subExclusion);
        }
      }
      if (!addedSubExclusions) {
        for (const subExclusion of exclusion.subExclusions ?? []) {
          if (subExclusion.isExcluded) {
            subExclusionsMap.set(subExclusion.id, subExclusion);
          }
        }
        addedSubExclusions = true;
      }
    }
    setSelectedMajorExclusions(majorExclusionsMap);
    setSelectedSubExclusions(subExclusionsMap);
  }, [allergiesAndExclusions, selectedSubExclusions, selectedMajorExclusions, selectedAllergies]);

  const handleAllergieClicked = (allergie) => {
    const allergieKey = allergie.exclusionMajor.id ?? allergie.exclusionSub.id ?? allergie.id;
    const copy = new Map();
    for (const [key, value] of selectedAllergies.entries()) {
      copy.set(key, value);
    }
    if (selectedAllergies.has(allergieKey)) {
      copy.delete(allergieKey);
    } else {
      copy.set(allergieKey, allergie);
    }
    setSelectedAllergies(copy);
  };

  const handleMajorExclusionClicked = (exclusion) => {
    // console.log("--- handleMajorExclusionClicked", exclusion);
    const copy = new Map();
    for (const [key, value] of selectedMajorExclusions.entries()) {
      copy.set(key, value);
    }
    setSelectedMajorExclusions(copy);
    const subExclusionsCopy = new Map();
    for (const [key, value] of selectedSubExclusions.entries()) {
      subExclusionsCopy.set(key, value);
    }
    if (selectedMajorExclusions.has(exclusion.id)) {
      copy.delete(exclusion.id);
      for (const subExclusion of exclusion?.subExclusions ?? []) {
        subExclusionsCopy.delete(subExclusion.id);
      }
      setSelectedSubExclusions(subExclusionsCopy);
    } else {
      copy.set(exclusion.id, exclusion);
      for (const subExclusion of exclusion?.subExclusions ?? []) {
        subExclusionsCopy.set(subExclusion.id, subExclusion);
      }
      setSelectedSubExclusions(subExclusionsCopy);
    }
  };

  const handleSubExclusionClicked = (majorExclusion, subExclusion) => {
    // console.log("--- handleSubExclusionClicked", majorExclusion, subExclusion);
    const majorExclusionsCopy = new Map();
    for (const [key, value] of selectedMajorExclusions.entries()) {
      majorExclusionsCopy.set(key, value);
    }
    const subExclusionsCopy = new Map();
    for (const [key, value] of selectedSubExclusions.entries()) {
      subExclusionsCopy.set(key, value);
    }
    if (selectedSubExclusions.has(subExclusion.id)) {
      subExclusionsCopy.delete(subExclusion.id);
      if (selectedMajorExclusions.has(majorExclusion.id)) {
        majorExclusionsCopy.delete(majorExclusion.id);
      }
    } else {
      subExclusionsCopy.set(subExclusion.id, subExclusion);
      let selectMajorExclusion = true;
      for (const subExclusion of majorExclusion?.subExclusions ?? []) {
        if (!subExclusionsCopy.has(subExclusion.id)) {
          selectMajorExclusion = false;
        }
      }
      if (selectMajorExclusion) {
        majorExclusionsCopy.set(majorExclusion.id, majorExclusion);
      }
    }
    setSelectedSubExclusions(subExclusionsCopy);
    setSelectedMajorExclusions(majorExclusionsCopy);
  };

  const handleSearchFieldClicked = () => {
    setShowCustomExclusionsModal(true);
  };

  const handleCustomExclusionSelected = (customExclusion) => {
    console.log({ customExclusion });
    if (!selectedCustomExclusions.some((item) => item === customExclusion.ing_name)) {
      setSelectedCustomExclusions((prevState) => {
        return [...(prevState ?? []), customExclusion.ing_name];
      });
    }
    setShowCustomExclusionsModal(false);
  };

  const handleRemoveCustomExclusion = (name) => {
    setSelectedCustomExclusions((prevState) => {
      return prevState.filter((item) => {
        return item !== name;
      });
    });
  };

  if (!location.state?.dietType || !location.state?.dietGoal) {
    return <Redirect to={mealPlanOnboardingRoute.path} />;
  }

  // console.log(location.state);
  // console.log({ selectedCustomExclusions });

  let content;
  if (loading) {
    content = (
      <div className="d-flex justify-content-center mt-5">
        <Loading />
      </div>
    );
  } else if (!allergiesAndExclusions) {
    content = (
      <div className="d-flex justify-content-center mt-5">
        <h4>{language["error"] ?? "error"}</h4>
      </div>
    );
  } else {
    content = (
      <>
        <div
          onClick={handleSearchFieldClicked}
          className="search-exclusions d-flex justify-content-between align-items-center mb-3"
        >
          <div className="text-secondary">{language["add-your-own-exclusions"] ?? "add-your-own-exclusions"}</div>
          <AiOutlineSearch />
        </div>
        <div className="selected-custom-exclusions mb-3">
          {selectedCustomExclusions?.map((item) => {
            return <SelectedCustomExclusionItem key={item} onRemoveClicked={handleRemoveCustomExclusion} name={item} />;
          })}
        </div>
        <div className="mb-2">{language["common-allergies"] ?? "common-allergies"}</div>
        <div className="allergies mb-3">
          {allergiesAndExclusions.allergies.map((item) => {
            return (
              <ExclusionListItem
                key={item.id}
                selected={selectedAllergies.has(item.exclusionMajor.id ?? item.exclusionSub.id ?? item.id)}
                enabled={!item.is_exclusion}
                name={item?.exclusionMajor?.name}
                onClick={() => handleAllergieClicked(item)}
              />
            );
          })}
        </div>
        <div className="mb-2">{language["other-exclusions"] ?? "other-exclusions"}</div>
        <div className="exclusions">
          {allergiesAndExclusions.majorExclusions.map((item) => {
            return (
              <MajorExclusionListItem
                key={item.id}
                majorExclusion={item}
                selectedMajorExclusions={selectedMajorExclusions}
                selectedSubExclusions={selectedSubExclusions}
                onMajorExclusionClicked={handleMajorExclusionClicked}
                onSubExclusionClicked={handleSubExclusionClicked}
              />
            );
          })}
        </div>
      </>
    );
  }

  return (
    <div className="allergies-and-exclusions">
      {content}
      <CustomExclusionsModal
        show={showCustomExclusionsModal}
        onItemSelected={handleCustomExclusionSelected}
        onClose={() => setShowCustomExclusionsModal(false)}
      />
    </div>
  );
};

export default AllergiesAndExclusions;
