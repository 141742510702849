import React, { useEffect, useState } from "react";
import ProfileCircleButton from "../components/ProfileCircleButton";
import NotificationsButton from "../components/NotificationsButton";
import BackButton from "../components/BackButton";
import { Row, Container, Col, Form } from "react-bootstrap";
import axios from "../services/Axios";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../utility/Helpers";
import Switch from "react-switch";
import VoucherCard from "./VoucherCard";
import moment from "moment";
import { useSelector } from "react-redux";

const VoucherModal = () => {

    const [avail, setAvail] = useState(false);
    const [avail2, setAvail2] = useState(false);
    const [active, setActive] = useState("0");
    const [featuresMap, setFeaturesMap] = useState(new Map());
    const language = useSelector((state) => state.language);
    const [Input, setInput]= useState({
        name: "",
        value: "",
        retailPrice: "",
        validTill: "",
        title: "",
        message: "",
        isOnlineSale: "",
        color: "black",
        features: []
    })

    const [stateClass, setStateClass] = useState("voucher-card black-card");

    const onChangeClass = (e)=> {
        let cls = e.target.className;
        let id = e.target.id;
        let ans = "voucher-card " + cls.split(" ")[1];
        let c = cls.split(" ")[1].split("-")[0];
        setStateClass(ans);
        setActive(id);
        setInput((prevState)=>{
            return {
                ...prevState,
                color: c
            }
        })
    }

    const onChangeHandler = (event)=> {
        let nam = event.target.name;
        let val = event.target.value;
        console.log(nam,val);
        setInput((prevState)=>{
            return {
                ...prevState,
                [nam]: val
            }
        })
    }

    const getDate = (days) =>{
        var addDate = moment().add('days', days);
        return addDate.format('YYYY-MM-DD');
    }
    const onSelectHandler = (e)=> {
        if(e.target.value != ""){
            var finalDate = getDate(e.target.value);
            setInput((prevState)=>{
                return {
                    ...prevState,
                    "validTill": finalDate
                }
            });
        }
    }

    const featuresHandle = (e) => {
        console.log("e", e.target.value);
        let element = e.target.value;
        setFeaturesMap((prevMap)=>{
            if (prevMap.has(element)) {
                prevMap.delete(element);
            }    
            else{
                prevMap.set(element, true);
            }
            return prevMap;
        });        
    }


    const onCreateVoucher = async()=> {
        let arr=[];
        featuresMap.forEach((value, key) => {
            arr.push(key);
        });
        console.log("arr", arr);
        console.log("Input", Input);
        setInput((prevState)=>{
            return {
                ...prevState,
                features: arr
            }
        })
       // console.log("Input", Input);

        try {
            const response = await axios.post(`/createVoucher`, Input);
            console.log(response);
          } catch (e) {
            // console.log("error->",{e});
            showErrorNotification(e.response.data.error);
          }

    }

    useEffect(() => {
        var finalDate = getDate(8);
            setInput((prevState)=>{
                return {
                    ...prevState,
                    "validTill": finalDate
                }
            });
    }, [])
    return (
        <div className="voucher">
           
            <div className="voucher--rows">

                    <Row>
                        <h5 className="voucher--rows-all">All Vouchers</h5>
                        <Col xs={12} lg={6} className="voucher--column-1">
                            <p className="voucher-info-heading">Voucher info</p>
                            <p className="voucher-info-desc">Add the voucher name, value and duration of the voucher. If the voucher value is higher than the retail price it will encourage more sails.</p>
                            
                            <div className="voucher-fields input-field">
                                <label htmlFor="name">
                                    Voucher Name
                                </label>
                                <input
                                    // value={state.formData.name}
                                    // onChange={handleInputChange}
                                    name="name"
                                    className="voucher-input voucher-name-input"
                                    value={Input.name}
                                    onChange={onChangeHandler}
                                />
                            </div>


                            <div className="voucher-fields input-field justify-content-between">
                                <div style={{width: '48%'}}>
                                    <label htmlFor="val">
                                        Value
                                    </label>
                                    <input
                                        name="value"
                                        className="voucher-input"
                                        value={Input.value}
                                        onChange={onChangeHandler}
                                    />
                                </div>

                                <div className="retail-price-input" style={{width: '48%'}}>
                                    <label htmlFor="retailPrice">
                                        Retail Price
                                    </label>
                                    <input
                                        name="retailPrice"
                                        className="voucher-input"
                                        value={Input.retailPrice}
                                        onChange={onChangeHandler}
                                    />
                                </div>
                            </div>

                            <div className="voucher-fields input-field" style={{flexDirection: 'column', alignItems: 'inherit'}}>
                                    <label htmlFor="val">
                                        Valid Till
                                    </label>
                                    <select className="voucher-input" name="validTill" id="days" onChange={onSelectHandler}>
                                        <option value="8" default>8 days</option>
                                        <option value="10">10 days</option>
                                    </select>
                            </div>

                            <div>
                                 <p className="voucher-text--para">Text</p>
                                 <p className="voucher-text--para">Add a title and message on the voucher</p>
                            </div>
                            <div className="voucher-fields input-field" style={{flexDirection: 'column', alignItems: 'inherit'}}>
                                 <input
                                        name="title"
                                        className="voucher-input mb-4"
                                        value={Input.title}
                                        onChange={onChangeHandler}
                                />
                                <input
                                        name="message"
                                        className="voucher-input"
                                        style={{
                                            height: '120px'
                                        }}
                                        value={Input.message}
                                        onChange={onChangeHandler}
                                />
                            </div>

                            <div className="d-flex ml-3 align-items-center">
                                <div className="custom-color--picker black-card" onClick={onChangeClass} id="0" style={{ border: active==="0"? '2px solid #a9bed2': '', borderBox: active==="0"? 'initial': 'border-box'}}></div>
                                <div className="custom-color--picker light-green-card" onClick={onChangeClass} id="1" style={{ border: active==="1"? '2px solid #a9bed2': '', borderBox: active==="1"? 'initial': 'border-box'}}></div>
                                <div className="custom-color--picker dark-green-card" onClick={onChangeClass} id="2" style={{ border: active==="2"? '2px solid #a9bed2': '', borderBox: active==="2"? 'initial': 'border-box'}}></div>
                                <div className="custom-color--picker dark-blue-card" onClick={onChangeClass} id="3" style={{ border: active==="3"? '2px solid #a9bed2': '', borderBox: active==="3"? 'initial': 'border-box'}}></div>
                                <div className="custom-color--picker orange-card" onClick={onChangeClass} id="4" style={{ border: active==="4"? '2px solid #a9bed2': '', borderBox: active==="4"? 'initial': 'border-box'}}></div>
                                <div className="custom-color--picker yellow-card" onClick={onChangeClass} id="5" style={{ border: active==="5"? '2px solid #a9bed2': '', borderBox: active==="5"? 'initial': 'border-box'}}></div>
                            </div>


                        </Col>
                        
                        <Col xs={12} lg={6} className="voucher--column-2">

                            <div className="voucher-fields input-field" style={{flexDirection: 'column', alignItems: 'inherit'}}>
                                <label>
                                    Features included
                                </label>
                            </div>

                            <div>
                                <div className="voucher-checkbox">
                                        <label for="mental-health">Mental Health</label><br />
                                        <input type="checkbox" id="mental-health" name="mental-health" value={1}  onClick={featuresHandle}/>
                                </div>

                                 <div className="voucher-checkbox">
                                        <label for="sleep">Sleep</label><br />
                                        <input type="checkbox" id="sleep" name="sleep" value={2} onClick={featuresHandle}/>
                                </div>

                                  <div className="voucher-checkbox">
                                        <label for="fitness">{language.lang.fitness}</label><br />
                                        <input type="checkbox" id="fitness" name="fitness" value={3} onClick={featuresHandle}/>
                                </div>

                                  <div className="voucher-checkbox">
                                        <label for="diet">Diet</label><br />
                                        <input type="checkbox" id="diet" name="diet" value={4} onClick={featuresHandle}/>
                                </div>

                                 <div className="voucher-checkbox">
                                        <label for="therapist">Therapist</label><br />
                                        <input type="checkbox" id="therapist" name="therapist" value={5} onClick={featuresHandle}/>
                                </div>

                                 <div className="voucher-checkbox">
                                        <label for="well-care">Well care</label><br />
                                        <input type="checkbox" id="well-care" name="well-care" value={6} onClick={featuresHandle}/>
                                </div>
                            </div>

                           <div>
                                
                                <Form.Group className="my-3" controlId="formBasicEmail">

                                <div className="d-flex">
                                    <Form.Label className="mb-4 mr-2 ml-3">{language.lang.online} sales</Form.Label>
                                    <div className="d-flex">
                                        <Switch
                                            checked={avail}
                                            onChange={(val)=>{
                                                if(val===true){
                                                    setAvail2(false);
                                                }
                                                setAvail(val);
                                            }}
                                            onColor="#bff5de"
                                            onHandleColor="#56c596"
                                            handleDiameter={15}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={20}
                                            width={48}
                                            className="react-switch"
                                            id="material-switch"
                                        />
                                        <p style={{marginLeft: '5px', marginTop: '-2px', fontWeight: '700', marginRight: '10px'}}>{language.lang.yes}</p>
                                    </div>

                                    <div className="d-flex">
                                        <Switch
                                            checked={avail2}
                                            onChange={(val)=>{
                                                if(val===true){
                                                    setAvail(false);
                                                }
                                                setAvail2(val);
                                            }}
                                            onColor="#bff5de"
                                            onHandleColor="#56c596"
                                            handleDiameter={15}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={20}
                                            width={48}
                                            className="react-switch"
                                            id="material-switch"
                                        />
                                        <p style={{marginLeft: '5px', marginTop: '-2px', fontWeight: '700'}}>{language.lang.no}</p>
                                    </div>
                                </div>
                            </Form.Group>
                        </div>


                        <div className="voucher-preview">
                            <p className="voucher-preview--heading">Voucher Preview</p>
                            <VoucherCard className={stateClass}  />

                        </div>


                        </Col>

                        <button className="save--btn" onClick={onCreateVoucher}>{language.lang.save}</button>
                    </Row>

                    

            </div>

        </div>
    )
}

export default VoucherModal;
