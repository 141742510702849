import moment from "moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useWindowDimension } from "./WindowsSize";
import { useState } from "react";

const WorkDaytabs = ({
  selected,
  date,
  onClickWorkDayItem,
  count,
  is_available,
  selectedMonth,
}) => {
  // console.log("date",date);
  // console.log("selected", selected);
  const [width, height] = useWindowDimension();
  const [isMobile, setIsMobile] = useState(width < 2000);
  let day = "";
  let daten = "";
  let month = "";
  let diff = 0;
  const week = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  if (date) {
    day = moment(date).format("dddd");
    month = moment(date).format("MMM");
    daten = moment(date).format("DD");
    diff = moment(date).format("DD") - moment().format("DD");
    //diff = 0;//language.lang.today
  }
  const language = useSelector((state) => state.language);
  {
    /*<div className="d-flex justify-content-start align-items-start flex-column">
          <div className="card-link">Pick a Date</div>
          <div className="d-flex justify-content-between w-100 mt-1 flex-wrap" style={{gap:'10px'}}>
            <div className={"day-card"+ selected ? " active" : ""} onClick={() => onClickWorkDayItem(count)}>{language.lang.today} {moment(date).format("DD")}</div>
            <div className={"day-card"+ selected ? " active" : ""} onClick={() => onClickWorkDayItem(count)}>{language.lang.tomorrow} {moment(date).add(1, 'days').format("DD")}</div>
            <div className="day-card" onClick={() => onClickWorkDayItem(count)}>{moment(date).add(2, 'days').format("dddd")} {moment(date).add(2, 'days').format("DD")}</div>
          </div>
  </div> */
  }
  return (
    <>
      {isMobile ? (
        <div
          className={"day-card" + (selected ? " active" : "")}
          onClick={() => onClickWorkDayItem(count)}
        >
          {diff === 0 ? (
            <>{language.lang.today + " " + month + " " + daten}</>
          ) : diff === 1 ? (
            <>{ daten + " " + month }</>
          ) : (
            <>{ daten + " " + month }</>
          )}
        </div>
      ) : (
        <div
          className={"day-card" + (selected ? " active" : "")}
          onClick={() => onClickWorkDayItem(count)}
        >
          {diff === 0 ? (
            <>{language.lang.today + " " + month + " " + daten}</>
          ) : diff === 1 ? (
            <>{language.lang.tomorrow + " " + month + " " + daten}</>
          ) : (
            <>{day + " " + month + " " + daten}</>
          )}
        </div>
      )}
    </>
  );
};

export default WorkDaytabs;
