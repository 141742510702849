import {useSelector } from "react-redux";
import TopMenu from "./CommonControls/TopMenu";
const Feedback = () => {
    const language = useSelector((state) => state.language);
    return (
    <section className="container-fluid mb-4 px-4">
      <div className="row">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.lang.PrivacyPolicy}
      />
      </div>
      <div className="d-flex justify-content-between px-4 m-0 align-items-center mobile-px-none">
                <div className="d-flex">
                    <div className="footer-container mb-4 glossy-style screen-min-height">
                        <div className="row mt-2 mb-2">
                            <h4 className="mt-2 mb-2">{language.lang.feedbackPara1}</h4>
                            <p>{language.lang.feedbackPara2}</p>
                            <p>{language.lang.feedbackPara3}</p>
                            <p>{language.lang.feedbackPara4}</p>
                        </div>
                        <div className="row mt-2 mb-2">
                            <h4 className="mt-2 mb-2">{language.lang.feedback}</h4>
                            <p>{language.lang.feedbackPara5}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
};

export default Feedback;