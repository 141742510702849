import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { dashboardRoute } from "../routes";

const HasRoleOrUnauthRoute = ({ component, path, roles, exact }) => {
  // const location = useLocation();
  const userData = useSelector((state) => state.auth.userData);
  const isAuth = userData && Object.keys(userData).length > 0;
  // const hasNextRoute = location.state && location.state.nextRoute;

  const route = <Route component={component} path={path} exact={exact} />;

  if (!isAuth) {
    return route;
  }
  if (roles.includes(userData.role)) {
    return route;
  }
  return <Redirect to={dashboardRoute.path} />;
};

export default HasRoleOrUnauthRoute;
