const actions = {
  SET_LANGUAGE: "SET_LANGUAGE",
  SET_LANGUAGE_PREFER: "SET_LANGUAGE_PREFER",
  SET_REGIONS: "SET_REGIONS",
  UPDATE_GENERAL_SETTINGS: "UPDATE_GENERAL_SETTINGS",
  UPDATE_PROFILE_SETTINGS: "UPDATE_PROFILE_SETTINGS",
  GET_PROFILE_DETAILS: "GET_PROFILE_DETAILS",
  SET_SELECTED_REGION: "SET_SELECTED_REGION",
  SET_PREFERRED_LANGUAGE: "SET_PREFERRED_LANGUAGE",
};

export default actions;
