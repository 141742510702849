import { useSelector } from "react-redux";
import useIngredientName, { useIngredientDescription } from "../../../customHooks/useIngredientName";
import Config from "../../../config/index";
import MealPlanBrokenImage from "../Shared/MealPlanBrokenImage";

const ViewRecipeIngredientListItem = ({ ingredient, quantity, weightInGrams }) => {
  const getIngredientName = useIngredientName();
  // Critical River, Varun Mishra desription widget of ingredient
  const getIngredientDescription = useIngredientDescription();
  const language = useSelector((state) => state.language.lang);
  const name = getIngredientName(ingredient?.ingredientDetails);
  const description = getIngredientDescription(ingredient?.ingredientDetails);

  return (
    <div className="ingredient-list-item">
      <MealPlanBrokenImage url={ingredient?.ingredientDetails?.image_url} fallbackUrl={Config.ingredientFallbackURL} name={name} className="rounded-circle chat-img" alt={name}/>
      <div className="ingredient-list-item-content d-flex justify-content-between">
        <div className="d-flex flex-column">
          <div className="name">{name}</div>
          {/* Critical River, Varun Mishra desription widget of ingredient */}
          <div className="description">{description}</div>
        </div>
        <div className="d-flex flex-column">
          <div className="quantity">
            {quantity?.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 }) ?? "0"} {ingredient?.unit_name ?? ""}
          </div>
          <div className="grams">
            {weightInGrams?.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 }) ?? "0"}{" "}
            {language[ingredient?.plain_unit_name] ?? ingredient?.plain_unit_name}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRecipeIngredientListItem;
