import React from "react";

const TimeSlotItem = ({
  selected,
  start_time,
  end_time,
  slotId,
  onSelectSlot,
  length,
  index,
  pracID,
  slot,
  is_available,
  type
}) => {
  return (
    <div
      className={`day-slots ${selected ? "selected" : ""} ${is_available?"" : "not_avail_class"}`}
      onClick={() => {
        onSelectSlot(slotId, length, index, pracID, slot, type);
      }}
    >
      {start_time} {/*end_time*/}
    </div>
  );
};

export default TimeSlotItem;
