import actions from "./actions";

export const initialState = {
  isRecording: false,
  recordChunks: [],
  audioMediaRecorder: null,
  recordBlob: null,
  audioMediaStream: null,
  needToCallOnChange: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case actions.NEED_TO_CALL_ON_CHANGE_TRUE:
      return Object.assign({}, state, {
        needToCallOnChange: true,
      });
    case actions.NEED_TO_CALL_ON_CHANGE_FALSE:
      return Object.assign({}, state, {
        needToCallOnChange: false,
      });
    case actions.DELETE_RECORD:
      return Object.assign({}, state, {
        recordChunks: [],
        recordBlob: null,
      });
    case actions.STOP_RECORDING:
      newState = Object.assign({}, state, {
        isRecording: false,
      });
      return newState;
    case actions.ADD_RECORD_CHUNKS:
      let blob = null;
      const chunks = [...state.recordChunks, payload];
      if (!state.isRecording) {
        blob = new Blob(chunks);
        // console.log("--- onRecordFileChange", blob);
        state.onRecordFileChange(blob);
      }
      newState = Object.assign({}, state, {
        recordChunks: chunks,
        recordBlob: blob,
        needToCallOnChange: !state.isRecording,
      });
      return newState;
    case actions.START_RECORDING:
      newState = Object.assign({}, state, payload, { isRecording: true });
      return newState;
    default:
      return state;
  }
};

export default reducer;
