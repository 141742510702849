import { useRef, useState, useEffect } from "react";
import BackButton from "../components/BackButton";
import DoctorCard from "../components/DoctorCard";
import QueueCard from "../components/QueueCard";
import { agoraRoute } from "../routes";
import {
  clientWalkinConfirmationAppointmentKey,
  defaultErrorMsg,
} from "../utility/constants";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { updateConsultationStatusAction } from "./DoctorJoinForConsultation/actions";
import Loading from "../components/Loading";
import {
  getAppointmentDetails,
  isMobileOrTabletBrowser,
  showErrorNotification,
  showSuccessNotification,
} from "../utility/Helpers";
import { currentAppointmentDetailsKey } from "../utility/constants";
import ProfileCircleButton from "../components/ProfileCircleButton";
import NotificationsButton from "../components/NotificationsButton";
import { Prompt } from "react-router-dom";
import { setAppointmentDetailsAction } from "../redux/client/actionsCreators";
import axios from "../services/Axios";
import {
  setDoctorStartedAppointmentDetails,
  setDoctorStartedAppointmentIDAction,
} from "../redux/client/actionsCreators";
import MobileAppPropmtModal from "../components/MobileAppPropmtModal";

const WalkinConfirmation = (props) => {
  const [showMobilePromptModal, setShowMobilePromptModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [callStatus, setCallStatus] = useState(false);
  const { history } = props;
  const { appointmentDetails, doctorStartedAppointmentID, status } =
    useSelector((state) => state.client);
  const language = useSelector((state) => state.language);
  const practitionerInfoRef = useRef(null);
  const canLeavePage = useRef(false);
  const dispatch = useDispatch();
  canLeavePage.current = false;

  useEffect(() => {
    // const startedStatus = status === 14;
    // setCallStatus(doctorStartedAppointmentID === appointmentDetails.appointmentId && startedStatus);
    const doctorStartedCall =
      doctorStartedAppointmentID === appointmentDetails.appointmentId;
    if (doctorStartedCall) {
      showSuccessNotification(
        null,
        <p>
          {language.lang["doctor-started-call"]}. Click on{" "}
          <span className="meet-doctor-notificaiton-text">
            {language.lang.meet_doctor}
          </span>{" "}
          button to start the call
        </p>
      );
    }
  }, [appointmentDetails, doctorStartedAppointmentID]);

  useEffect(() => {
    const startedStatus = localStorage.hasOwnProperty(
      clientWalkinConfirmationAppointmentKey
    );
    setCallStatus(
      doctorStartedAppointmentID === appointmentDetails.appointmentId &&
        startedStatus
    );
  }, [status]);

  useEffect(() => {
    dispatch(setAppointmentDetailsAction(appointmentDetails));
    localStorage.setItem(
      clientWalkinConfirmationAppointmentKey,
      JSON.stringify(appointmentDetails)
    );
    setCallStatus(
      doctorStartedAppointmentID === appointmentDetails.appointmentId &&
        status === 14
    );
    return () => {
      //localStorage.removeItem(clientWalkinConfirmationAppointmentKey);
      console.log("--- remove confirm consultaiton");
      if (!canLeavePage.current) {
        console.log("--- leave queue", appointmentDetails.appointmentId);
        updateConsultationStatusAction(
          appointmentDetails.appointmentId,
          "8",
          true
        );
      }
    };
  }, []);

  // const checkIfDoctorStartedAppointment = async () => {
  //   try {
  //     const res = await axios.get("/RTStatusUpdateConsultationStatus", {
  //       params: { appointmentId: appointmentDetails.appointmentId },
  //     });
  //     const data = res.data.response;
  //     dispatch(
  //       setDoctorStartedAppointmentIDAction(appointmentDetails.appointmentId)
  //     );
  //     dispatch(setDoctorStartedAppointmentDetails(data.statusId));
  //   } catch (e) {
  //     console.log("--- checkIfDoctorStartedAppointment error");
  //     console.log(e);
  //   }
  // };

  const meetDoctorPressed = async () => {
    if (doctorStartedAppointmentID === appointmentDetails.appointmentId) {
      setLoading(true);
      const values = await Promise.all([
        updateConsultationStatusAction(doctorStartedAppointmentID, "15"),
        getAppointmentDetails(appointmentDetails.appointmentId),
      ]);
      const data = values[0];
      if (data && data.id) {
        canLeavePage.current = true;
        localStorage.setItem(
          currentAppointmentDetailsKey,
          JSON.stringify(values[1])
        );
        joinAppointmentCall();
      } else {
        setLoading(false);
        showErrorNotification();
      }
    }
  };

  if (!appointmentDetails || Object.values(appointmentDetails).length === 0) {
    return <div>{defaultErrorMsg}</div>;
  }

  if (appointmentDetails.appointment && appointmentDetails.appointment.data) {
    practitionerInfoRef.current =
      appointmentDetails.appointment.data.update_Appointment.returning[0];
  }

  const startTime = new Date(appointmentDetails.startDateTime);
  const endTime = new Date(appointmentDetails.endDateTime);

  const joinAppointmentCall = () => {
    if (isMobileOrTabletBrowser()) {
      setShowMobilePromptModal(true);
    } else {
      history.replace(agoraRoute.path);
    }
  };

  const onMobileModalPromptClose = () => {
    setShowMobilePromptModal(false);
    history.replace(agoraRoute.path);
  };

  return (
    <div className="walkin-confirmation">
      <MobileAppPropmtModal
        show={showMobilePromptModal}
        onCloseClicked={onMobileModalPromptClose}
      />
      <Prompt
        when={!loading}
        message="Are you sure you want to leave the call queue ?"
      />
      <section
        className={"row px-4 justify-content-between" + `${language.direction}`}
      >
        <div className="col-10 col-md-5 d-flex align-items-center">
          <BackButton />
          <h5 className="ml-3">{language.lang["walkin-confirmation"]}</h5>
        </div>
        <div className="col-6 notifications-and-profile mt-3 mt-md-0">
          <NotificationsButton />
          <ProfileCircleButton />
        </div>
      </section>
      <section className="row px-4 mt-4">
        <h3 className="walkin-consultation-title">
          {language.lang["consultation-confirmed-with"]}
        </h3>
      </section>
      <section className="row px-4">
        <div className="col-12">
          {practitionerInfoRef.current && (
            <DoctorCard
              name={`${practitionerInfoRef.current.practitionerInfo.name} ${
                practitionerInfoRef.current.practitionerInfo.surname
                  ? practitionerInfoRef.current.practitionerInfo.surname
                  : ""
              }`}
              imgSrc={
                practitionerInfoRef.current.practitionerInfo.profile_photo_url
              }
              rate={
                practitionerInfoRef.current.practitionerInfo.rating.aggregate
                  .avg.rating
              }
              millde={language.lang["video-call"]}
              bottom={`${moment(startTime).format("h:mm a")} - ${moment(
                endTime
              ).format("h:mm a")}`}
            />
          )}
        </div>
      </section>
      <section className="row px-4 mt-4">
        <div className="col-12 col-lg-8">
          <QueueCard
            startTime={startTime}
            endTime={endTime}
            appointmentDetails={appointmentDetails}
          />
        </div>
        <div className="col-12 col-lg-4">
          <div className="mm-card mt-4 mt-lg-0">
            <p className="appointment-text">
              {language.lang["estimated-arrival-time"]}
            </p>
            <h4 className="appointment-time mt-3">
              {moment(startTime).format("h:mm a")}
            </h4>
            <p className="appointment-text mt-3">
              {moment(startTime).endOf("minute").fromNow()}
            </p>
          </div>
          {/* <PieChart>
            <p className="appointment-text">Estimated Arrival Time</p>
            <h4 className="appointment-time mt-3">
              {moment(startTime).format("h:mm a")}
            </h4>
            <p className="appointment-text mt-3">
              {moment(startTime).endOf("minute").fromNow()}
            </p>
          </PieChart> */}
          <div className="d-flex justify-content-center mt-3">
            {loading ? (
              <div className="d-flex justify-content-center">
                <Loading />
              </div>
            ) : (
              <button
                onClick={meetDoctorPressed}
                className={`btn mt-4 mt-lg-0 ${
                  callStatus ? "btn-primary" : "btn-secondary"
                } meet-doctor-btn`}
                type="button"
                disabled={!callStatus}
              >
                {language.lang.meet_doctor}
              </button>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default WalkinConfirmation;
