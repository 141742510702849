import React, { useState } from 'react';
import { Col, Dropdown, Modal, Button } from 'react-bootstrap';
import Switch from "react-switch";
import { Table } from 'antd';
import { TimePicker } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import SlotDetails from './SlotDetails';
import axios from "../services/Axios";
import { settings } from "../config";
import {
    showErrorNotification,
    showSuccessNotification
} from "../utility/Helpers";
import { useSelector } from 'react-redux';

const SlotArrangement = () => {
    const language = useSelector((state) => state.language);
    const month = new Date().getMonth();
    const week  = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const monthArr = ["January", "February","March","April","May","June","July","August", "September", "October", "November", "December"];
    const monthName = monthArr[month];
    const currentDate = new Date().toLocaleString('en-US',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });                         //current date
    const fortnight = new Date(Date.now() + 12096e5).toLocaleString('en-US',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });       //date after 14 days
    const [modalData, setModalData] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (index) => {
        let currDate = new Date(Date.now() + index * 24*60*60*1000).getDate();
        let currMonth = new Date(Date.now() + index * 24*60*60*1000).getMonth();
        let monthName = monthArr[month];
        let currDayNum = new Date(Date.now() + index * 24*60*60*1000).getDay();
        let currDay = week[currDayNum];
        setModalData({
            date: currDate,
            month: monthName,
            day: currDay
        });
        setShow(true);
    }



    let stateObj = {
        date: '',
        workingHours: {
            startTime: '',
            endTime: ''
        },
        breakHours: {
            startTime: '',
            endTime: ''
        },
        available: false
    }
    
    let arr= [];
    for (let i = 0; i < 14; i++) {
        let currDate = new Date(Date.now() + i * 24*60*60*1000).toISOString();
        let newObj = {
            ...stateObj,
            // index: i,
            date: currDate
        }
        arr.push(newObj);
    }
    // console.log("The arr",arr);
    const [array, setArray] = useState(arr);
    

    const saveChanges = async ()=> {
        
        let obj = JSON.parse(localStorage.getItem('userData'));
        // console.log("save changes clicked");
        // console.log("The obj", obj);
        // console.log("The state array", array);

        let slots = array.map((el)=>{
            return {
                ...el,
                date: el.date.substring(0, 10),
                available: !el.available,
            }
        });

        let slotData = {
            practitionerId: obj.id,
            interval: "30",
            slotsArrangement: slots
        };
        console.log({slotData});

        try {
            const response = await axios.post(`${settings.apiBaseURL}/managePractitionerSlots`, slotData);
            console.log("The response is", response);
            showSuccessNotification("Slots Updated");
            // setArray(arr);
        }
        catch (e) {
            // console.log("error->",{e});
            showErrorNotification(e.response.data.error);
        }

    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'Date',
            key: 'Date',
            width: 25,
        },
        {
            title: 'Slots',
            dataIndex: 'Slots',
            key: 'Slots',
            width: 25,
        },
        {
            title: 'Working Hours',
            dataIndex: 'WorkingHours',
            key: 'WorkingHours',
            width: 80,
        },
        {
            title: 'Break Hours',
            dataIndex: 'BreakHours',
            key: 'BreakHours',
            width: 80,
        },
        {
            title: '',
            dataIndex: 'ViewSlots',
            key: 'ViewSlots',
            width: 25,
        },
        {
            title: '',
            dataIndex: 'switchAvail',
            key: 'switchAvail',
            width: 50,
        },
    ];

    function onChangeWorkStartHours(time, timeString, i) {
        // console.log("For i", i);
        setArray((prevState)=>{
            prevState[i] = {
                ...prevState[i],
                workingHours: {
                    ...prevState[i].workingHours,
                    startTime: timeString,
                },
            }
            return prevState;
        });
    }

    function onChangeWorkEndHours(time, timeString, i){
        // console.log("For i", i);
        setArray((prevState)=>{
            prevState[i] = {
                ...prevState[i],
                workingHours: {
                    ...prevState[i].workingHours,
                    endTime: timeString,
                },
            }
            return prevState;
        });
    }

    function onChangeBreakStartHours(time, timeString, i){
        // console.log("For i", i);
        setArray((prevState)=>{
            prevState[i] = {
                ...prevState[i],
                breakHours: {
                    ...prevState[i].breakHours,
                    startTime: timeString,
                },
            }
            return prevState;
        });
    }

    function onChangeBreakEndHours(time, timeString, i){
        // console.log("For i", i);
        setArray((prevState)=>{
            prevState[i] = {
                ...prevState[i],
                breakHours: {
                    ...prevState[i].breakHours,
                    endTime: timeString,
                },
            }
            return prevState;
        });
    }

    const data = [];
    for (let i = 0; i < 14; i++) {
    let days = i;
    let currDate = new Date(Date.now() + days * 24*60*60*1000).getDate();
    let currDayNum = new Date(Date.now() + days * 24*60*60*1000).getDay();
    let currDay = week[currDayNum].slice(0,3).toUpperCase();
    data.push({
        key: i,
        Date: <div className="custom-calendar">
                {currDay} <br/>
                {currDate<10? '0': ''}{currDate}
            </div>,
        Slots: '12',
        WorkingHours: <><span> {language.lang.start} Time </span>
                            <TimePicker className="start-btn" format="h:mm:ss" onChange={(time, timeString)=>onChangeWorkStartHours(time,timeString,i)} />
                            <span>  End Time  </span>
                            <TimePicker className="start-btn" format="h:mm:ss" onChange={(time, timeString)=>onChangeWorkEndHours(time,timeString,i)} />
                    </>,
        BreakHours:   <><span> {language.lang.start} Time </span>
                            <TimePicker className="start-btn" format="h:mm:ss" onChange={(time, timeString)=>onChangeBreakStartHours(time, timeString,i)} />
                            <span>  End Time  </span>
                            <TimePicker className="start-btn" format="h:mm:ss" onChange={(time, timeString)=>onChangeBreakEndHours(time, timeString,i)} />
                    </>,
        ViewSlots: <button className="view-slots-btn" onClick={()=>{handleShow(i)}}>{language.lang.view} Slots</button>,
        switchAvail: <div className="d-flex">
                        <Switch
                            checked={array[i].available}
                            onChange={(val)=>{
                                setArray((prevState)=>{
                                    let newArr = [...prevState];
                                    newArr[i].available = val;
                                    return newArr;
                                });
                            }}
                            onColor="#bff5de"
                            onHandleColor="#56c596"
                            handleDiameter={15}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="material-switch"
                        />
                        <div style={{marginLeft: '5px'}}>Mark Leave</div>
                    </div>
    });
    }

    return (
        <Col xs={12} className="slot-arrangement">
               <div>
                   <h2 className="slot-heading">Slot Arrangement from &nbsp; <span className="day-duration">{currentDate} - {fortnight}</span></h2>
                   <div className="slot-paragraphs">
                        <p className="slot-para">Please select the slots to Manage</p>
                        <p className="slot-para">Default Slot Time {language.lang.duration}: &nbsp; 15 mins</p>
                    </div>
               </div>
                <div>
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                        {monthName}
                    </Dropdown.Toggle>
                    </Dropdown>
                </div>

                <Table columns={columns} dataSource={data} scroll={{ x: 1500, y: 430 }} pagination={false} />


                <div style={{textAlign: 'right'}}>
                    <button className="shadow-none save-changes-btn" onClick={saveChanges}>
                        {language.lang.save} Changes
                    </button>
                </div>

                <Modal show={show} onHide={handleClose}>
                    <SlotDetails modalData={modalData} />
                </Modal>
        </Col>
    )
}

export default SlotArrangement;
