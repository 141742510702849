import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useEffect, useState } from "react";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { useDispatch, useSelector } from "react-redux";
import { BsChevronDown } from "react-icons/bs";
import FieldFormat from "./FieldFormat";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  customerCalendarStartLoading,
  setPatientCalendarEvents,
  setPatientViewType,
  setSelectedSlotAndViewSlotAction,
} from "../redux/patient/actionCreators";
import Loading from "./Loading";
import { getScheduleTypes } from "../services/CustomerNetworkService";
import { Facebook, Instagram } from "react-content-loader";

const localizer = momentLocalizer(moment);

const MyCalendarCustomer = () => {
  const [bcView, setBCView] = useState("day");
  const ViewType = useSelector((state) => state.patient.calendar.calendarView);
  const Events = useSelector((state) => state.patient.calendar.events);
  const loading = useSelector((state) => state.patient.loading);
  const dispatch = useDispatch();
  const today = new Date();
  // console.log("--- Events", Events);

  const handleSelectedEvent = (event) => {
    // console.log("--- event", event);
    dispatch(
      setSelectedSlotAndViewSlotAction({ viewSlot: true, selectedSlot: event })
    );
    // dispatch({
    //   type: patientActions.SET_VIEW_SLOT,
    //   payload: true,
    // });
    // dispatch({
    //   type: patientActions.SET_SELECTED_SLOT,
    //   payload: event,
    // });
  };

  // let formats = {
  //   timeGutterFormat: (date, culture, localizer) =>
  //     localizer.format(date, "HH:mm", culture),
  // };
  // const handleonEventDrop = (month, view) => {
  //   console.log(month, view);
  // };

  return (
    <div className="mt-2 d-flex justify-content-center w-100">
    <div className="mm-card doctor-dashboard-card min-card-width p-0 w-100 py-4" style={{"border-radius":"10px"}}>
      {loading && <Instagram className="calendar-skeleton"/>}
      
      <Calendar
        className={`${loading ? "d-none" : ""}`}
        localizer={localizer}
        events={Events}
        startAccessor="start"
        endAccessor="end"
        components={{
          toolbar: CustomToolbar,
          event: CustomerCalendarCustomEvent,
        }}
        defaultDate={moment().toDate()}
        popup={true}
        style={{ minHeight: 500 }}
        view={ViewType}
        onView={setBCView}
        onSelectEvent={(e) => handleSelectedEvent(e)}
        dateFormat="h t"
        timeslots={1}
        step={15}
        min={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9)
        }
        max={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 20)
        }
      />
    </div>
   </div>
   
  );
};

const CustomToolbar = (toolbar) => {
  const calendarEvents = useSelector((state) => state.patient.calendar.events);
  const lastCalendarUpdateDate = useSelector(
    (state) => state.patient.calendar.lastUpdateDate
  );
  const ViewType = useSelector((state) => state.patient.calendar.calendarView);
  const userData = useSelector((state) => state.auth.userData);
  const loadingCalendar = useSelector((state) => state.patient.loading);
  const dispatch = useDispatch();
  const [startDate, setstartDate] = useState(toolbar.date);
  const [endDate, setendDate] = useState(toolbar.date);
  const [viewDate, setviewDate] = useState(moment().format("DD MMM YYYY"));
  const [scheduleTypes, setScheduleTypes] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(0);
  const [ViewTypeOptions, setViewTypeOptions] = useState([
    { name: "Day", value: "day" },
    { name: "Week", value: "week" },
  ]);
  const now = toolbar.date;

  const addMonths = (date, months) => {
    const d = date.getDate();
    date.setMonth(date.getMonth() + months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    console.log("--- addMonths", date);
    return date;
  };

  // useEffect(() => {
  //   getScheduleTypes().then((value) => {
  //     setScheduleTypes(value);
  //   });
  // }, []);

  useEffect(() => {
    setDocEvent({});
  }, [lastCalendarUpdateDate]);

  useEffect(() => {
    if (!selectedSchedule || selectedSchedule === 0) return;
    setDocEvent({ showLoading: true });
  }, [selectedSchedule]);

  // useEffect(() => {
  //   if (needToCall) {
  //     setDocEvent({});
  //     dispatch({
  //       type: patientActions.SET_NEED_TO_CALL_CALENDAR,
  //       payload: false,
  //     });
  //   }
  // }, [needToCall]);

  useEffect(() => {
    if (ViewType === "week") {
      setstartDate(getStartDate(now));
      setendDate(getEndDate(now));
    } else {
      setstartDate(now);
      setendDate(now);
    }
  }, [toolbar]);

  // useEffect(() => {
  //   setDocEvent({});
  // }, [PractitionerDetails]);

  useEffect(() => {
    if (ViewType === "day") {
      setviewDate(moment(toolbar.date).format("DD MMM YYYY"));
    } else {
      setviewDate(
        `${moment(getStartDate(now)).format("DD")}-${moment(
          getEndDate(now)
        ).format("DD MMM YYYY")}`
      );
    }
  }, [ViewType]);

  const addWeeks = (date, weeks) => {
    date.setDate(date.getDate() + 7 * weeks);
    return date;
  };

  const addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    console.log("--- addDays", date);
    return date;
  };

  const goToBack = () => {
    if (ViewType === "month") {
      toolbar.onNavigate("prev", addMonths(toolbar.date, -1));
    } else if (ViewType === "week") {
      toolbar.onNavigate("prev", addWeeks(toolbar.date, -1));
    } else {
      toolbar.onNavigate("prev", addDays(toolbar.date, -1));
    }
    if (ViewType === "day") {
      setviewDate(moment(toolbar.date).format("DD MMM YYYY"));
    }
    setDocEvent({});
  };

  const goToNext = () => {
    if (ViewType === "month") {
      toolbar.onNavigate("next", addMonths(toolbar.date, +1));
    } else if (ViewType === "week") {
      toolbar.onNavigate("next", addWeeks(toolbar.date, +1));
    } else {
      toolbar.onNavigate("next", addDays(toolbar.date, +1));
    }
    if (ViewType === "day") {
      setviewDate(moment(toolbar.date).format("DD MMM YYYY"));
    } else {
      setviewDate(
        `${moment(getStartDate(now)).format("DD")}-${moment(
          getEndDate(now)
        ).format("DD MMM YYYY")}`
      );
    }
    setDocEvent({});
  };

  const goToToday = () => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.date.setDate(now.getDate());
    toolbar.onNavigate("current");
    setviewDate(moment(toolbar.date).format("DD MMM YYYY"));
    setDocEvent({});
  };

  const getStartDate = (now) => {
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - now.getDay()
    );
  };

  const getEndDate = (now) => {
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      getStartDate(now).getDate() + 6
    );
  };

  const setViewType = (e) => {
    dispatch(setPatientViewType(e));
  };

  const getAndSetScheduleTypes = async () => {
    const types = await getScheduleTypes();
    setScheduleTypes(types);
    if (types.length > 0) {
      setSelectedSchedule(types[0].id);
    }
    return types[0];
  };

  const setDocEvent = async ({ showLoading = false }) => {
    if (loadingCalendar) return;
    const id = userData?.id;
    let firstScheduleType;
    if (!scheduleTypes || scheduleTypes.length === 0) {
      dispatch(customerCalendarStartLoading());
      firstScheduleType = await getAndSetScheduleTypes();
    } else {
      firstScheduleType = {};
    }
    const dispatchShowLoadingAction =
      calendarEvents.length === 0 || showLoading;
    dispatch(
      setPatientCalendarEvents(
        moment(getStartDate(now)).format("YYYY-MM-DD"),
        moment(getEndDate(now)).format("YYYY-MM-DD"),
        id,
        dispatchShowLoadingAction,
        firstScheduleType.id || selectedSchedule
      )
    );
  };

  const handleScheduleChanged = (value) => {
    setSelectedSchedule(value);
  };

  return (
    <div
      className="w-100 d-flex custom-toolbar justify-content-center align-items-center flex-wrap visiblility-none pt-0 pt-sm-3 "
      style={{ gap: 20 }}
    >
      <div
        className="d-flex w-100 align-items-center justify-content-center flex-wrap flex-lg-nowrap maxContentWidth mb-3"
        style={{ gap: "20px" }}
      >
        {scheduleTypes && scheduleTypes.length > 0 && (
          <FieldFormat
            element={
              <SelectSearch
                value={selectedSchedule}
                placeholder="Select Schedule Type"
                options={scheduleTypes.map((item) => ({
                  name: item.name,
                  value: item.id,
                }))}
                filterOptions={fuzzySearch}
                search={true}
                onChange={handleScheduleChanged}
              />
            }
            icon={<BsChevronDown color={"#18a0fb"} size={25} />}
          />
        )}
        <FieldFormat
          element={
            <SelectSearch
              value={{
                name: ViewType.toUpperCase(),
                value: ViewType.toLowerCase(),
              }}
              placeholder="select view type"
              options={ViewTypeOptions}
              filterOptions={fuzzySearch}
              search={true}
              onChange={setViewType}
            />
          }
          icon={<BsChevronDown color={"#18a0fb"} size={25} />}
        />
        {/* <FieldFormat element={ <SelectSearch value={selectedDoc} placeholder="select practitioner" options={top_list}  filterOptions={fuzzySearch} search={true}  onChange={setDocEvent}/>} icon={<BsChevronDown color={"#18a0fb"} size={25} />} show={Object.keys(selectedDoc).length > 0}/> */}
        <div className="d-flex controls col-4">
          <div className="arrow" onClick={goToBack}>
            <i className="fas fa-chevron-left"></i>
          </div>
          <div onClick={goToToday}>Today</div>
          <div>{viewDate}</div>
          <div className="arrow" onClick={goToNext}>
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>
      </div>
      <div
        className="w-100 justify-content-center align-items-center mt-2 status pb-2 flex-wrap"
        style={{ gap: "20px" }}
      >
        <div className="status-card pending">Pending</div>
        <div className="status-card ongoing">Ongoing</div>
        <div className="status-card completed">Completed</div>
        <div className="status-card upcoming">Upcoming</div>
        <div className="status-card cancelled">Cancelled</div>
        <div className="status-card failed">Failed</div>
        <div className="status-card skiped">Skipped/Missed</div>
      </div>
    </div>
  );
};

const CustomerCalendarCustomEvent = (events) => {
  const { event } = events;
  return (
    <div
      className="customer-calendar-custom-event calendar-event-border d-flex justify-content-center align-items-center h-100"
      style={{ backgroundColor: event.color }}
    >
      <div className="doc-name">{event.PractitionerName}</div>
    </div>
  );
};

export default MyCalendarCustomer;
