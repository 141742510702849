import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { showErrorNotification } from "../utility/Helpers";

const ShowEmiratesID = ({ onChange, url, type }) => {
  const [selectedImg, setSelectedImg] = useState(false);
  const imgRef = useRef(null);

  const handleChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const img = e.target.files[0];
    if (!img.type.startsWith("image")) {
      showErrorNotification();
      return;
    }
    try {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setSelectedImg(true);
        imgRef.current.src = fileReader.result;
      };
      fileReader.readAsDataURL(img);
      onChange(img);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setSelectedImg(url&&true)
    if(selectedImg && url) {
      imgRef.current.src=url;
    }
  },[url]);
  const language = useSelector((state) => state.language);
  return (
    <div className="emirates-id-image-field mt-3">
      <div className="img-container"></div>
      <label htmlFor={"emiratesID"+type} className="emirates-id-image-label">
        {selectedImg && (
          <img className="selected-img" ref={imgRef} src={url} alt="emirates-id" />
        )}
        <i className="far fa-paperclip"></i>
      </label>
    </div>
  );
};

export default ShowEmiratesID;
