import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MMRadioButton from "./MMRadioButton";

const GenderSelectField = (props) => {
  const { onChange, value, showLabel = true } = props;
  // console.log("--- gender props", props);
  const [selectedGender, setSelectedGender] = useState();
  const selectGender = (gender) => {
    setSelectedGender(gender);
    onChange(gender);
  };
  const language = useSelector((state) => state.language);

  useEffect(() => {
    // console.log("--- gender effect", value);
    setSelectedGender(value);
  }, [value]);

  return (
    <div className="gender-select-field w-100">
      {showLabel && (
        <span className="field-label mr-2">
          {language.lang.gender} <span className="required-field-star"> *</span>
        </span>
      )}
      <div className="check-box-container">
        <MMRadioButton
          onClick={() => selectGender(1)}
          label={language.lang.male}
          selected={selectedGender === 1}
          classes="mr-3"
        />
        <MMRadioButton
          onClick={() => selectGender(2)}
          label={language.lang.female}
          selected={selectedGender === 2}
        />
      </div>
    </div>
  );
};

export default GenderSelectField;
