import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { Rate } from "antd";
import "antd/dist/antd.css";
import DocProfile from "../components/DoctorConsultationProfile";
import { VscFilePdf } from "react-icons/vsc";
import axios from "axios";
import { showErrorNotification } from "../utility/Helpers";
import { settings } from "../config";
import Loading from "./Loading";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";

const PrescriptionSection2 = ({prescriptionInfo}) => {
  const [Input, setInput] = useState({
    appointmentId:prescriptionInfo.appointment_id,
    practitionerRating:0,
    comment:"",
    willRecommend:""
  });
  const [details, setDetails] = useState({});
  const getDetails = async (id) => {
    await axios.get(`${settings.apiBaseURL}/getPractitionerDetails?practitionerId=${id}`).then((response) => {
        setDetails(response.data.response[0]);
      });
  }
  useEffect(() => {
    if(prescriptionInfo && Object.keys(details).length === 0) getDetails(prescriptionInfo.practitioner_id);
  },[Input])
  const [loading, setloading] = useState(false);
  const handleinput = (e) =>{
    if( typeof(e) === "number" ){
      setinput("practitionerRating",e);
      return;
    }
    let name = e.target.name;
    let value = e.target.value;
    if(name === "willRecommend") value = value === "yes" ? true : false;
    setinput(name,value);
  }
  const setinput = (name,value) =>{
    setInput((prevState)=>{
      return{
        ...prevState,
        [name]: value,
      }
    })
  }
  const submitReview = async () =>{
    setloading(true);
    for (const [key, value] of Object.entries(Input)) {
      if(!value && key !== "willRecommend"){
        setloading(false);
        showErrorNotification("Please fill the review form");
        return;
      }
      if(key === "willRecommend" && value === ""){
        setloading(false);
        showErrorNotification("Please fill all the fields.");
        return;
      }
    }
  await axios.post(`${settings.apiBaseURL}/createAppointmentReview`, Input)
  .then((response)=>{
    setloading(false);
    showErrorNotification("Review submitted successfully");
  })
   .catch((e) => {
    setloading(false);
    showErrorNotification(e.response.data.error);
   })
  }
  const language = useSelector((state) => state.language);
  return (
    <Col xs={12} md={6} className="consultation-section-2">
      <div className="dashboard-book-consultation" style={{ height: "100px" }}>
        {details?.profile_photo_url ? <div className="col-12 notifications-and-profile mt-3 justify-content-center mx-2">
          <div>
            <DocProfile src={details?.profile_photo_url} />
          </div>
        </div> : <div className="d-flex justify-content-center align-items-center w-100  mt-3"><Avatar name={details?.name} size={80} textSizeRatio={1.75} round={true}/></div>}
      </div>
      <div>
        <div className="doctor-details">
          <h4 className="doctor-details--heading">
            {language.lang["how-was-your-experience-with"]} <br />
            <span className="dark-blue">{prescriptionInfo.practitionerInfo.name || ""}</span>
          </h4>
          <div className="mt-3 mb-5">
            <p className="text-center">
            <Rate name="practitionerRating" value={/* Input.practitionerRating || prescriptionInfo.practitionerInfo.rating && prescriptionInfo.practitionerInfo.rating.aggregate && prescriptionInfo.practitionerInfo.rating.aggregate.avg && prescriptionInfo.practitionerInfo.rating.aggregate.avg.rating*/Input.practitionerRating}  onChange={handleinput}/>
            </p>
          </div>
          <div className="">
            <div className="doctor-details-text">
              <div className="doctor-details-text-1">{language.lang["write-comment"]}</div>
              <div className="doctor-details-text-2">{language.lang["max-600-word"]}</div>
            </div>
            <Form className="text-center shadow-none">
              <textarea
                className="doctor-form--input"
                onChange={handleinput}
                name="comment"
                placeholder={(prescriptionInfo.practitionerInfo.name || "Tanisha" )+ " is a good doctor. She know how to treatment a patients. Very Good Service. Well Professional."}
              ></textarea>
            </Form>
          </div>
          <div className="doctor-details-question">
            {language.lang["would-you-recomed"]} {prescriptionInfo.practitionerInfo.doctorName} {language.lang["to-a-friend"]} ?
            <div className="doctor-form-radio d-flex justify-content-start w-100 mt-3" style={{gap:'40px'}}>
            <div className="d-flex justify-content-between align-items-center" style={{gap:'20px'}}>
              <div className="d-flex justify-content-between align-items-center" ><input
                className="form-check-input mr-3"
                type="radio"
                name="willRecommend"
                id="flexRadioDefault1"
                value="yes"
                onChange={handleinput}
              />
              </div>
              <div><label
                className="form-check-label mr-3 ml-1"
                htmlFor="flexRadioDefault1"
              >
                {language.lang.yes}
              </label>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center" style={{gap:'10px'}}> 
              <div className="d-flex justify-content-center align-items-center" style={{gap:'10px'}}><input
                className="form-check-input mr-2"
                type="radio"
                name="willRecommend"
                id="flexRadioDefault2"
                value="no"
                onChange={handleinput}
              /></div>

              <div><label
                className="form-check-label mr-3"
                htmlFor="flexRadioDefault2"
              >
                {language.lang.no}
              </label>
              
              </div>
            </div>
            </div>
          </div>
     
          <div className="text-center">
            {!loading ? (<button className="shadow-none btn btn-primary custom-btn" onClick={submitReview}>
              {language.lang["give-review"]}
            </button>): (<Loading />)}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default PrescriptionSection2;
