import actions from "./actions";
import store from "../../redux/store";

let userData = store.getState().auth.userData;
let name = "";
let email = "";
let phoneNum = "";
let age = "";
let emiratesId = "";
let insuranceNo = "";
let emiratesIdExpiryDate = "";
if (userData) {
  name = userData.name;
  email = userData.email;
}

// console.log(localStorage.getItem('patient-data'));
/*if(localStorage.getItem('patient-data')){
  let d = JSON.parse(localStorage.getItem('patient-data'));
  name = d.name;
  email = d.email;
  phoneNum = d.phone;
  age = d.age;
  emiratesId = d.emiratesId;
  insuranceNo = d.insuranceNo;
  emiratesIdExpiryDate = d.emiratesIdExpiryDate;
}*/

export const initialState = {
  recordFile: null,
  medicalDocFile: null,
  loading: false,
  showModal: false,
  filterSideMenuOpen: false,
  formData: {
    name: "",
    email: "",
    phone: "",
    dob: "",
    gender_id: 1,
    gender: 1,
    reason: "",
    regionId: "1",
    isAllowUploadDocument: false,
    // isAllowUploadDocument: "true",
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.CLOSE_FILTER_MENU:
      return Object.assign({}, state, { filterSideMenuOpen: false });
    case actions.OPEN_FILTER_MENU:
      return Object.assign({}, state, { filterSideMenuOpen: true });
    case actions.HIDE_MODAL:
      return Object.assign({}, state, { showModal: false });
    case actions.SHOW_MODAL:
      return Object.assign({}, state, { showModal: true });
    case actions.STOP_LOADING:
      return Object.assign({}, state, { loading: false });
    case actions.LOADING:
      return Object.assign({}, state, { loading: true });
    case actions.SET_RECORD_FILE:
      return Object.assign({}, state, { recordFile: payload });
    case actions.SET_MEDICAL_DOC_FILE:
      return Object.assign({}, state, { medicalDocFile: payload });
    case actions.INPUT_CHANGE:
      const { name, value } = payload;
      return Object.assign({}, state, {
        formData: Object.assign({}, state.formData, {
          [name]: value,
        }),
      });
    case actions.INIT_FORM_DATA:
      const newState = Object.assign({}, state, {
        formData: Object.assign({}, state.formData, payload),
      });
      return newState;
    default:
      return state;
  }
};

export default reducer;
