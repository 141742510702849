import { useSelector } from "react-redux";

const NotificationItem = ({ type, text, component }) => {
  const language = useSelector((state) => state.language);
  return (
    <div className={"notification-item" + `${language.direction}`}>
      <button className="btn close-btn">
        <i className="far fa-times"></i>
      </button>
      {text && <span className="">{text}</span>} {component}
    </div>
  );
};

export default NotificationItem;
