/* 
//Usage 
//For showing the hamburger menu in Dashboard for Doctor,Patient and Clinic
//This will show the Mobile Mode.
*/
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setsidebar } from "../../../redux/user/actionsCreators";
import { useWindowDimension } from "../../WindowsSize";
import ShareHamburgerButton from "../Shared/ShareHamburgerButton";
import Config from '../../../config/index';
const TopDashboardMenuHamburger = () => {
  const dispatch = useDispatch();
  const [width, height] = useWindowDimension();
  const Side_Bar = useSelector((state) => state.user.side_bar);
  const toggleCollapse = () => {
    dispatch(setsidebar(!Side_Bar));
  };
  useEffect(() => {
    setIsCollapsed(width < 2000);
  }, [width]);
  const [isCollapsed, setIsCollapsed] = useState(width < 2000);
  return (
    <>
      {isCollapsed && (
        <div
          className="d-flex justify-content-start flex-column"
          onClick={toggleCollapse}
        >
          <img src={Config.dashhamburger} alt="IZILogoCircle" className="dash-circle-button"/>
        </div>
      )}
    </>
  );
};

export default TopDashboardMenuHamburger;