import { useCallback, useState } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import useFireAuth from "./useFireAuth";
import {
  setCorporateID,
  setPhoneNumber,
  setPhoneAuthConfirmationResult,
} from "../redux/firebase/acitonCreators";
import { showErrorNotification } from "../utility/Helpers";

const useSendPhoneVerificationCode = () => {
  const fireAuth = useFireAuth();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.lang);
  const [loading, setLoading] = useState(false);

  const sendPhoneVerificationCode = useCallback(
    async ({ phoneNumber, phoneCountryCode, corporateID }) => {
      setLoading(true);
      try {
        const verifier = new RecaptchaVerifier(
          "phone-sign-in-captcha",
          { size: "invisible" },
          fireAuth
        );
        const confirmationResult = await signInWithPhoneNumber(
          fireAuth,
          `${phoneCountryCode}${phoneNumber}`,
          verifier
        );
        if (corporateID) {
          dispatch(setCorporateID({ corporateID }));
        }
        dispatch(
          setPhoneNumber({
            phoneCountryCode: phoneCountryCode,
            phoneNumber: phoneNumber,
          })
        );
        dispatch(setPhoneAuthConfirmationResult({ confirmationResult }));
        return true;
      } catch (e) {
        console.log("--- PhoneSignin error");
        console.log(e);
        if (e.code === "auth/invalid-phone-number") {
          showErrorNotification(
            language["invalid-phone-number"] ?? "Invalid phone number"
          );
        } else if (e.code === "auth/too-many-requests") {
          showErrorNotification(
            language["too-many-requests-from-this-number"] ??
              "too-many-requests-from-this-number"
          );
        } else {
          showErrorNotification();
        }
        // verifier.clear();
        // console.log("--- grecaptcha", verifierWidgetID);
        // debugger;
        // grecaptcha.reset(verifierWidgetID);
        return false;
      } finally {
        setLoading(false);
      }
    },
    [dispatch, fireAuth, language]
  );

  return [loading, sendPhoneVerificationCode];
};

export default useSendPhoneVerificationCode;
