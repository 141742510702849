const actions = {
  SET_DOCTOR_STARTED_APPOINTMENT_ID: "SET_DOCTOR_STARTED_APPOINTMENT_ID",
  SET_APPOINTMENT_DETAILS: "SET_APPOINTMENT_DETAILS",
  SET_IS_WAITING_DOCTOR_APPROVAL: "SET_IS_WAITING_DOCTOR_APPROVAL",
  SET_IS_NOT_WAITING_DOCTOR_APPROVAL: "SET_IS_NOT_WAITING_DOCTOR_APPROVAL",
  SET_DOCTOR_STARTED_APPOINTMENT_DETAILS:
    "SET_DOCTOR_STARTED_APPOINTMENT_DETAILS",
  SET_INITIATED_TRANSACTION_ID: "SET_INITIATED_TRANSACTION_ID",
  SET_COMPLETED_TRASNACTION: "SET_COMPLETED_TRASNACTION",
  SET_INITIATED_AND_COMPLETED_TRANSACTION:
    "SET_INITIATED_AND_COMPLETED_TRANSACTION",
    SET_CALL_TYPE : "SET_CALL_TYPE",
};

export default actions;
