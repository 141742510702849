import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form, Modal } from "react-bootstrap";
import Switch from "react-switch";
import { Select } from "antd";
// import axios from "../services/Axios";
import {
  showErrorNotification,
  showSuccessNotification,
  arr,
  uploadFile,
} from "../utility/Helpers";

import {
  storageTypeImage,
  userTypeUser,
  fileTypeImage,
  fileTypeVideo,
  storageTypeVideo,
} from "../utility/constants";
import UploadComponentHelper from "./UploadComponentHelper";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "../services/Axios";
import { settings } from "../config";
import ImageCropper from "./ImageCropper";
import Loading from "./Loading";

const { Option } = Select;

const UploadImage = ({ type, show, onHide }) => {
  const [files, setFiles] = useState([]);
  const [edit, setedit] = useState(false);
  const [loading, setLoading] = useState(false);
  const language = useSelector((state) => state.language);
  const [avail, setAvail] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [ispaid, setIsPaid] = useState(false);
  const { userData } = useSelector((state) => state.auth);
  const [crop, setcrop] = useState(false);
  const [Input, setInput] = useState({
    videoName: "",
    videoDescription: "",
  });
  const [tag, settag] = useState([]);
  const onChangeHandler = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
    setInput((prevState) => {
      return {
        ...prevState,
        [nam]: val,
      };
    });
  };
  const handleKeyDown = (e) => {
    if (e.code === "Enter") {
      settag((oldArray) => [...oldArray, Input.videoName]);
      setInput((prevState) => {
        return {
          ...prevState,
          videoName: "",
        };
      });
    }
  };

  const emiratesIDImageRef = useRef(null);

  const onSelectHandler = (e) => {
    setInput((prevState) => {
      return {
        ...prevState,
        corporateId: e,
      };
    });
  };

  const handleEmiratesIDImgChange = (file) => {
    emiratesIDImageRef.current = file;
  };

  const uploadEmiratesID = async () => {
    setLoading(true);
    if (
      Input["videoDescription"] &&
      ((avail == true && Input["corporateId"] != null) ||
        (avail == false && Input["corporateId"] == null)) &&
      tag.length > 0
    ) {
      const data = {
        storageType: storageTypeImage,
        fileType: fileTypeImage,
        userType: userTypeUser,
        userId: userData.id,
        description: Input["videoDescription"],
        category: "MentalHealth",
        isGalleryItem: true,
        isCorporate: avail,
        corporateId: Input["corporateId"],
        isPaidContent: ispaid,
        tags: tag,
      };
      //if (!emiratesIDImageRef.current) return null;
      for (let i = 0; i < stateFiles.length; i++) {
        data["fileType"] = stateFiles[i].type;
       // console.log(data["fileType"]);
        data["multi-files"] = stateFiles[i];
        if (await uploadFile(data)) {
          onHide(!show);
          showSuccessNotification("File Uploaded");
        }
      }
    } else {
      showErrorNotification("Please fill the form fields");
    }
    setLoading(false);
  };

  let setting = {
    noClick: true,
    noKeyboard: true,
    onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
      }
  };
  if (type === "Image") {
    setting["accept"] = "image/jpeg, image/png";
  }
  const { getRootProps, getInputProps, open, acceptedFiles } =
    useDropzone(setting);

  const [stateFiles, setStateFiles] = useState(acceptedFiles);
  useEffect(() => {
    files.length > 0 &&  setImageToCrop(files[0].preview);
    !edit && setStateFiles(acceptedFiles);
  }, [stateFiles, acceptedFiles]);

  const getDropDownDetails = async () => {
    const response = await axios.get(`${settings.apiBaseURL}/getTenants`);
    console.log("resp -> ", response);
  };

  useEffect(() => {
    getDropDownDetails();
  }, []);

  const removeFile = (index) => {
    if(index-1 >= 0) acceptedFiles.splice(index,index-1);
    const newFiles = stateFiles.filter((a,i) => i !== index);
    setStateFiles(newFiles);
    setFiles(newFiles);
    setedit(true);
  }
  const removeTag = (i) => {
    const newTag = tag.filter((a,index) => index !== i );
    settag(newTag);
  }
  return (
    <div className="single-feature-modal">
      <Modal.Header closeButton>
        <h3 className="single-feature-heading">Upload {type}</h3>
      </Modal.Header>
      <Row>
        <Col xs={12} lg={6}>
          <div className="tagarea">
            <Form.Label className="mb-2">
              {type} Tags <sup>*</sup>{" "}
            </Form.Label>
            <Form.Group controlId="formBasicEmail">
              <div className="tags d-flex">
                {tag &&
                  tag.map((item, i) => <span className="tag" key={i} >#{item}<i className="fas fa-times m-1" onClick={() => removeTag(i)}></i></span>)}
              </div>
              <Form.Control
                type="text"
                className="shadow-none"
                name="videoName"
                value={Input.videoName}
                onChange={onChangeHandler}
                onKeyDown={handleKeyDown}
                required
              />
            </Form.Group>
          </div>

          <div>
            <Form.Label>
              {type} Description <sup>*</sup>{" "}
            </Form.Label>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                className="shadow-none"
                name="videoDescription"
                value={Input.videoDescription}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>
          </div>

          <div className="cont">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <FaCloudUploadAlt className="upload--icon" />
              <p>Drag and Drop</p>
              <div className="or-block">
                <div className="or-line"></div>
                <div className="or-text">or</div>
                <div className="or-line"></div>
              </div>
              <button type="button" onClick={() => {open(); setedit(false);}} className="browse-btn">
                Browse
              </button>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={6} className="feature-col-2">
          <div>
            <Form.Label>Preview </Form.Label>
            { stateFiles.length === 0 && <div className="dropzone d-flex justify-content-center align-items-center" style={{fontSize:'20px'}}>Please select an image</div>}
            { stateFiles.length > 0 && 
                <div>
                    <div className="dropzone w-100 h-100 p-0">
                    {!crop && <div>
                        {<img className="w-100 h-100" style={{objectFit:'contain'}} src={imageToCrop} alt="image" />}
                    </div>}
                    {crop && <ImageCropper
                            imageToCrop={imageToCrop}
                            onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
                        />}
                    
                    </div>
            
                    <div className="mt-3 text-right d-flex justify-content-end" style={{gap:'20px'}}>
                    {!crop&& <div className="crop-btn" onClick={()=> setcrop(true)}>Crop</div>}
                    {crop && <div className="crop-btn" onClick={() => {croppedImage !== undefined && setImageToCrop(croppedImage);setcrop(false)}}>Apply</div>}
                    {crop && <div className="crop-btn" onClick={()=> setcrop(false)}>{language.lang.cancel}</div>}
                    </div>
                            </div>
                    }
            
          </div>
          <div className="dropbox-files">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">FileName</th>
                  </tr>
                </thead>
                <tbody>
                  {stateFiles.map((file, i) => {
                     // console.log(file,"files")
                    //console.log("hi", i);
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td
                          className="d-flex justify-content-evenly align-items-center py-1"
                          style={{ width: "375px" }}
                        >
                          <span onClick={() => {setImageToCrop(stateFiles[i].preview);setcrop(false);}}>{file.path}</span>
                          <RiDeleteBin6Line
                            className="delete--icon"
                            onClick={() => removeFile(i)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          
        </Col>
        <div className="my-3 text-right d-flex justify-content-end align-items-center w-100">
         {loading ? <div className="d-flex justify-content-end align-items-center"><Loading width={50} height={50}/></div>: <button
            className="shadow-none btn btn-primary create-btn"
            onClick={uploadEmiratesID}
          >
            Upload
          </button>}
        </div>
      </Row>
    </div>
  );
};

export default UploadImage;
