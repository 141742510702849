import FitnessCardList from "./FitnessCardList";
import NotificationsButton from "./NotificationsButton";
import ProfileCircleButton from "./ProfileCircleButton";
import VideoCarousel from "./VideoCarousel";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import BackButton from "./BackButton";
import { useSelector } from "react-redux";

const MostPopularCardDash = () => {
  // history.location.state.practitioner.id
  const history = useHistory();
  const [list, setlist] = useState([]);
  const [id, setid] = useState(-1);
  const [pagetitle, setpagetitle] = useState("");
  const language = useSelector((state) => state.language);
  useEffect(() => {
    history.location.state.list && setlist(history.location.state.list);
    history.location.state.listid && setid(history.location.state.listid);
    history.location.state.pagetitle &&
      setpagetitle(history.location.state.pagetitle);
  }, [
    history.location.state.list,
    history.location.state.listid,
    history.location.state.pagetitle,
  ]);
  return (
    <div>
      <div className="d-flex">
        <section className="row p-0 px-4 flex-fill">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <BackButton />
              <h3 className="Page-title">{pagetitle}</h3>
            </div>
            <div className="col-6 notifications-and-profile mt-md-0">
              <NotificationsButton className="ml-auto" />
              <ProfileCircleButton />
            </div>
          </div>
          {list && <VideoCarousel data={list} />}
          <div className="card d-flex flex-column justify-content-start pl-5">
            <div
              className="d-flex flex-column justify-content-start w-100"
              style={{
                fontSize: "28px",
                fontWeight: "500",
                marginTop: "10px",
                "margin-bottom": "10px",
              }}
            >
              {list && list.description_main_header}
            </div>
            <div
              className="d-flex flex-column justify-content-start w-100"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                marginTop: "10px",
                "margin-bottom": "10px",
              }}
            >
              {list && list.description_sub_header}
            </div>
            <div
              className="d-flex flex-column justify-content-start w-100"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                marginTop: "10px",
                "margin-bottom": "10px",
              }}
            >
              {list && list.description_content}
            </div>
          </div>
          <div className="pt-4 pb-5">
            <p style={{ fontSize: "20px", fontWeight: "600" }}>
              {language.lang.next}
            </p>
            <div className="px-4">
              <div className="divider mb-3"></div>
            </div>
            {id != -1 && <FitnessCardList id={id} />}
          </div>
        </section>
      </div>
    </div>
  );
};

export default MostPopularCardDash;
