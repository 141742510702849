import Config from "../config/index";

const PlayStoreLinkButton = ({ className }) => {
  return (
    <div
      className={`play-store-link-btn ${className ? className : ""}`}
      href="https://play.google.com/store/apps/details?id=com.doctors.mindmina"
    >
      <a
        rel="noreferrer"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.doctors.mindmina"
      >
        <img alt="Play Store" className="FooterStoreButtons" src={Config.playstoreIcon} />
      </a>
    </div>
  );
};

export default PlayStoreLinkButton;
