import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Facebook } from "react-content-loader";
import { dashboardMealPlanRoute } from "../../../routes";
import { MdEdit } from "react-icons/md";
import { unapprovedFoodItemStatusID } from "../../../utility/constants";

const RecipesTiles = ({
  recipes,
  formData,
  searchData,
  showLoading,
  showLoadMoreRecipes,
  pageLoading,
  showLessRecipes,
  showMoreRecipes,
  loadMoreRecipesVisible,
  title,
}) => {
  const history = useHistory();
  const language = useSelector((state) => state.language.lang);
  const preferredlanguage = useSelector(
    (state) => state.language.general.preferredlanguage
  );
  console.log("Lang :" ,preferredlanguage);
  const truncate = (str) => {
    return str.length > 15 ? str.substring(0, 14) + "..." : str;
  };

  const handleRecipeClick = (recipe) => {
    history.push(`${dashboardMealPlanRoute.path}/recipe/${recipe.rcp_url_name}`, { recipe });
  };

  const onEditRecipeClicked = (recipe) => {
    history.push(`${dashboardMealPlanRoute.path}/recipe/${recipe.rcp_url_name}/edit`, { recipe });
  };

  return (
    <div className="basic-food-tiles row py-2">
      <div className="w-100">
        <div className="col-12">
          <div className="row justify-content-between align-text-bottom py-1">
            <span className="section_header_text col-auto ml-3">
              <span className="h5 font-weight-light">{language["recipes"] ?? "Recipes"}</span>
            </span>
          </div>
        </div>
        <div className="section_results col-12">
          <div className="food_browser_results_list row" tabIndex="0">
            <ul className="card-group">
              {recipes.length > 0 ? (
                recipes.map((item) => {
                  return (
                    <li
                      key={item.id}
                      onClick={() => handleRecipeClick(item)}
                      className="browser_result col-6 col-lg-3 py-2 mp-card set-tile-width recipe-card-tile"
                    >
                      <div className="card h-100 holo-card-spec">
                        {item.status.id === unapprovedFoodItemStatusID && (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onEditRecipeClicked(item);
                            }}
                            className="edit-recipe-btn"
                          >
                            <MdEdit />
                          </button>
                        )}
                        <div className="card-img-top">
                          <img src={item.rcp_image_url} className="food-image" alt={item.rcp_title} />
                        </div>
                        <div className="card-body">
                          <p className="card-title food_name">
                          {
                          ((preferredlanguage=='ar') ? (!!item.rcp_title_ar ? item.rcp_title_ar : item.rcp_title)  : 
                           (preferredlanguage=='ku') ? (!!item.rcp_title_ku ? item.rcp_title_ku : item.rcp_title) 
                           :truncate(item.rcp_title))
                          }
                          </p>
                          <p className="small text-muted">{item.rcp_cooking_time} {language["minutes"] ?? "minutes to cook"}</p>
                          <p className="small text-muted">{item.rcp_serving_size} {language["serving"] ?? "serving"}</p>
                        </div>
                        <div className="card-footer">
                          {/* {(item.recipeCalculatedTotals.calories!=null || item.recipeCalculatedTotals.calories!=undefined) ? 'hello' : 'Nothing'} */}
                          {/* <small className="text-muted">{Math.round(!!item.recipeActualTotals.calories ? item.recipeActualTotals.calories : 0)} KCAL</small> */}
                          {/* <small className="text-muted">{item.recipeActualTotals.calories} KCAL</small> */}
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <div className="d-flex justify-content-center align-items-center w-100">No Recipes Found</div>
              )}
            </ul>
          </div>
          {pageLoading && <Facebook />}
          <div className="d-flex justify-content-center align-items-center">
            <div className="d-flex flex-row justify-content-center align-items-center w-100">
              {
                //!pageLoading &&
                loadMoreRecipesVisible && recipes.length > 0 && recipes.length >= 5 && (
                  <div className="p-2">
                    <div className="d-flex justify-content-center w-100 align-items-center downArrow py-2">
                      <div className="show-more btn" onClick={showLessRecipes}>
                        <label className="showlabels">Show Less</label>
                        <i className="fas fa-chevron-up ml-1"></i>
                      </div>
                    </div>
                  </div>
                )
              }
              {!pageLoading && showLoadMoreRecipes && recipes.length > 0 && recipes.length >= 5 && (
                <div className="p-2">
                  <div className="d-flex justify-content-center w-100 align-items-center downArrow py-2">
                    <div className="show-less btn" onClick={showMoreRecipes}>
                      <label className="showlabels">Show More</label>
                      <i className="fas fa-chevron-down ml-1"></i>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipesTiles;
