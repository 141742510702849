import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  getAppointmentDetails,
  isMobileOrTabletBrowser,
  showErrorNotification,
} from "../utility/Helpers";
import Loading from "./Loading";
import { useHistory } from "react-router-dom";
import {
  agoraRoute,
  dashboardAppointmentsRoute,
  dashboardPrescriptionRoute,
  stripePaymentCheckoutRoute,
} from "../routes";
import { useSelector } from "react-redux";
import {
  clientWalkinConfirmationAppointmentKey,
  currentAppointmentDetailsKey,
  paymentModuleTypeScheduledAppointment,
} from "../utility/constants";
import { updateConsultationStatusAction } from "../pages/DoctorJoinForConsultation/actions";
import MobileAppPropmtModal from "./MobileAppPropmtModal";

const AppointmentCards = ({ title, data, loading }) => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const history = useHistory();
  // const { userData } = useSelector((state) => state.auth);
  const language = useSelector((state) => state.language);
  const canLeavePage = useRef(false);
  canLeavePage.current = false;
  const [callstatus, setstatus] = useState(false);
  const [showMobilePromptModal, setShowMobilePromptModal] = useState(false);
  const { appointmentDetails, doctorStartedAppointmentID, status } =
    useSelector((state) => state.client);

  const meetDoctorPressed = async (appointmentid) => {
    let appointmentDetails = await getAppointmentDetails(appointmentid);
    localStorage.setItem(
      currentAppointmentDetailsKey,
      JSON.stringify(appointmentDetails)
    );
    let endedCall = appointmentDetails.practitioner_ended_call;
    setstatus(!endedCall);
    let endTime = appointmentDetails.end_date_time;
    if (endedCall) {
      showErrorNotification("Doctor has ended the call");
      return;
    } else if (moment().isAfter(endTime))
      showErrorNotification(language.lang["appointment-time"] + " Ended");
    else if (!moment().isAfter(endTime) && !endedCall) {
      joinAppointmentCall();
    }
  };

  const joinAppointmentCall = () => {
    if (isMobileOrTabletBrowser()) {
      setShowMobilePromptModal(true);
    } else {
      history.replace(agoraRoute.path);
    }
  };

  const [showLoader, setShowLoader] = useState(false);
  const joinDoctorPressed = async (appointmentid) => {
    setShowLoader(true);
    let appointmentDetails = await getAppointmentDetails(appointmentid);
    localStorage.setItem(
      currentAppointmentDetailsKey,
      JSON.stringify(appointmentDetails)
    );
    let endedCall = appointmentDetails.practitioner_ended_call;
    setstatus(!endedCall);
    let endTime = appointmentDetails.end_date_time;
    if (endedCall) {
      showErrorNotification("Doctor has ended the call");
      return;
    } else if (moment().isAfter(endTime))
      showErrorNotification(language.lang["appointment-time"] + " Ended");
    else if (!moment().isAfter(endTime) && !endedCall) {
      const res = await updateConsultationStatusAction(
        appointmentid,
        "15",
        false
      );
      joinAppointmentCall();
    }
  };

  const onClick = (id) => {
    history.push(dashboardPrescriptionRoute.path, {
      presId: id,
    });
  };

  const Paypending = (item) => {
    // console.log("--- pay", item);
    const viewButtonText = "viewAppointment"; // language.lang. || "View Appointment";
    const viewButtonRedirectLink = dashboardAppointmentsRoute.path;
    const viewButtonRedirectParams = JSON.stringify({
      pending: "Upcoming",
    });
    const paymentRedirectURL = `${window.location.protocol}//${window.location.host}?amount=${item.amount_to_pay}&currency=${item.amount_to_pay_currency_code}&startDateTime=${item.start_date_time}&endDateTime=${item.end_date_time}&buttonText=${viewButtonText}&viewButtonRedirectLink=${viewButtonRedirectLink}&viewButtonRedirectParams=${viewButtonRedirectParams}`;
    history.push(stripePaymentCheckoutRoute.path, {
      moduleID: item.id,
      currency: item.amount_to_pay_currency_code,
      amount: item.amount_to_pay,
      moduleType: paymentModuleTypeScheduledAppointment,
      redirectURL: paymentRedirectURL,
    });
  };

  let appointkey = JSON.parse(
    localStorage.getItem(clientWalkinConfirmationAppointmentKey)
  );

  useEffect(() => {
    const startedStatus = localStorage.hasOwnProperty(
      clientWalkinConfirmationAppointmentKey
    );
    setstatus(
      doctorStartedAppointmentID === appointmentDetails.appointmentId &&
        startedStatus
    );
  }, [status, appointkey]);

  const onMobileModalPromptClose = () => {
    setShowMobilePromptModal(false);
    history.replace(agoraRoute.path);
  };

  //console.log(data, title,appointkey,data[0].id);
  return (
    // <Scrollbars classname="w-100" style={{ minHeight: 500, height: 300 }}>
    <>
      {/* <div
      className="w-100 scroll-snap"
      style={{ minHeight: "400px","overflow-x":"scroll"}}
    >
       */}

      <MobileAppPropmtModal
        show={showMobilePromptModal}
        onCloseClicked={onMobileModalPromptClose}
      />
      {!loading ? (
        <section
          className="w-100 h-100 text-center d-flex flex-column"
          style={{ gap: "10px" }}
        >
          {data && data.length > 0 ? (
            data.map((item, i) => {
              // console.log(item.id === appointkey.id, item, appointkey)
              return (
                <div
                  key={item.id}
                  className="d-flex justify-content-around align-items-center appointment-item"
                >
                  <div className="appointment-bold">{i + 1}</div>
                  <div
                    className="appointment-bold"
                    style={{ "min-width": "7rem", width: "7rem" }}
                  >
                    {item.practitionerInfo && item.practitionerInfo.name
                      ? item.practitionerInfo.name
                      : "Dr Static"}
                  </div>
                  {item.start_date_time && (
                    <div
                      className="appointment-bold"
                      style={{
                        "min-width": "5rem",
                        "text-align": "left",
                        width: "5rem",
                      }}
                    >
                      <div className="header"></div>
                      <div
                        className="appointment-content"
                        style={{ "min-width": "5rem", "text-align": "left" }}
                      >
                        <div>
                          {item.start_date_time &&
                            days[moment(item.start_date_time).day()]}
                        </div>
                        <div>
                          {item.start_date_time &&
                            moment(item.start_date_time).format("DD/MM/YY")}
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className="appointment-bold"
                    style={{
                      "min-width": "5rem",
                      width: "5rem",
                      "text-align": "left",
                    }}
                  >
                    {item.start_date_time &&
                      moment(item.start_date_time).format("HH:mm a")}
                  </div>
                  {title === "Previous" && item.prescription ? (
                    <div
                      className="download-btn-appointment"
                      onClick={() => onClick(item.prescription.id)}
                      data-tooltip="Download prescription"
                      data-tooltip-conf="left success"
                    >
                      {" "}
                      <i className="fa fa-cloud-download"></i>
                    </div>
                  ) : (
                    title === "Previous" && (
                      <div
                        className="download-btn-appointment"
                        data-tooltip="No prescription"
                        data-tooltip-conf="left danger"
                      >
                        <i className="fa fa-exclamation-circle"></i>
                      </div>
                    )
                  )}
                  {title === "Ongoing" &&
                  (status !== 14 || status !== 8 || status !== 15) ? (
                    <button
                      onClick={() => meetDoctorPressed(item.id)}
                      className={`btn mt-4 mt-lg-0 ${
                        true ? "btn-primary" : "btn-secondary"
                      } custom-appointment-btn`}
                      type="button"
                      disabled={
                        !(item.id === (appointkey && appointkey.appointmentId))
                      }
                    >
                      {" "}
                      {language.lang.rejoin}{" "}
                    </button>
                  ) : (
                    title === "Ongoing" && <Loading />
                  )}

                  {title === "Pending" &&
                    (item.status_id === 23 ? (
                      <button
                        onClick={() => Paypending(item)}
                        className={`btn mt-4 mt-lg-0 custom-appointment-btn ${
                          true ? "btn-primary" : "btn-secondary"
                        } meet-doctor-btn`}
                        type="button"
                        disabled={false}
                      >
                        {" "}
                        {language.lang.pay +
                          " " +
                          item.amount_to_pay_currency_code +
                          " " +
                          item.amount_to_pay}{" "}
                      </button>
                    ) : (
                      <button
                        className={`btn mt-4 mt-lg-0 custom-appointment-btn ${
                          true ? "btn-primary" : "btn-secondary"
                        } meet-doctor-btn`}
                        type="button"
                        disabled={true}
                        style={{ width: "200px" }}
                      >
                        {" "}
                        {language.lang.verificationPending}{" "}
                      </button>
                    ))}
                  {title === "Upcoming" ? (
                    showLoader ? (
                      <div>
                        <Loading width={50} height={50} />
                      </div>
                    ) : (
                      <button
                        onClick={() => {
                          joinDoctorPressed(item.id);
                        }}
                        className={`btn mt-4 mt-lg-0 custom-appointment-btn ${
                          true ? "btn-primary" : "btn-secondary"
                        } meet-doctor-btn`}
                        type="button"
                        disabled={
                          !(
                            item.id === (appointkey && appointkey.appointmentId)
                          )
                        }
                      >
                        {" "}
                        {language.lang.join}{" "}
                      </button>
                    )
                  ) : (
                    title === "Upcoming" && <Loading />
                  )}
                </div>
              );
            })
          ) : (
            <div className="warning-appointment">
              {title === "Ongoing"
                ? language.lang.no_ongoing_appointments
                : title === "Previous"
                ? language.lang.no_previous_appointments
                : title === "Upcoming"
                ? language.lang.no_upcoming_appointments
                : title === "Cancelled"
                ? language.lang.no_cancelled_appointments
                : language.lang.no_pending_appointments}{" "}
            </div>
          )}
        </section>
      ) : (
        <Loading />
      )}
    </>

    // </Scrollbars>
  );
};
export default AppointmentCards;
