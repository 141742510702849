import { useSelector } from "react-redux";
import TopMenu from "./CommonControls/TopMenu";
// import { Card } from 'primereact/card';
import Config from "../config/index";
// import Whatsapp from "./Whatsapp";
// import Tawk from "./Tawk";

const Support = () => {
  const language = useSelector((state) => state.language);
  //   const customerheader = <img alt="Customer" src={Config.customersupport} />;
  //   const practitionerheader = (
  //     <img alt="Practitioner" src={Config.practitionersupport} />
  //   );
  //   const corporateheader = (
  //     <img alt="Practitioner" src={Config.corporatesupport} />
  //   );
  const footer = (
    <span>
      <button className="btn btn-primary">
        <a href="mailto:info@izi.health"></a>Contact us
      </button>
    </span>
  );
  return (
    <section className="container-fluid mb-4 px-4">
      <div className="row">
        <TopMenu
          shouldHideBackButton={false}
          shouldHideProfileButton={false}
          shouldHideNotificationButton={false}
          shouldHideLogoButton={false}
          pageTitle={language.lang["support"]}
        />
      </div>
      <div className="d-flex justify-content-between px-4 m-0 align-items-center mobile-px-none w-100">
        <div className="d-flex w-100">
          <div
            class="card-deck mb-4 creen-min-height w-100"
            style={{ backgroundColor: "rgb(255 255 255 / 0%)" }}
          >
            <div class="card">
              <img
                class="card-img-top"
                src={Config.customersupport}
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title">For Customers</h5>
                <p class="card-text">
                  Our IZI support team is here to help with any questions
                  regarding consultation, meal planning and groups.
                </p>
              </div>
              <div
                class="card-footer d-flex w-100 justify-content-center mb-2 mt-2"
                style={{ backgroundColor: "rgb(0 0 0 / 0%)" }}
              >
                <small class="text-muted">{footer}</small>
              </div>
            </div>
            <div class="card">
              <img
                class="card-img-top"
                src={Config.practitionersupport}
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title">For Practitioners</h5>
                <p class="card-text">
                  Our IZI support team is here to help with any questions you
                  might have about your IZI account, the IZI app.
                </p>
              </div>
              <div
                class="card-footer d-flex w-100 justify-content-center mb-2 mt-2"
                style={{ backgroundColor: "rgb(0 0 0 / 0%)" }}
              >
                <small class="text-muted">{footer}</small>
              </div>
            </div>
            <div class="card">
              <img
                class="card-img-top"
                src={Config.corporatesupport}
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title">For Corporates</h5>
                <p class="card-text">
                  Our IZI support team is here to help with any questions for
                  corporates.
                </p>
              </div>
              <div
                class="card-footer d-flex w-100 justify-content-center mb-2 mt-2"
                style={{ backgroundColor: "rgb(0 0 0 / 0%)" }}
              >
                {footer}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="d-flex w-100">
                    <div className="card mb-4 glossy-style screen-min-height w-100"> */}
        {/* <h5 className="mt-2 mb-2">Our IZI support team is here to help with any questions regarding consultation, meal planning and groups.</h5> */}
        {/* <div class="d-flex d-flex flex-sm-row flex-column justify-content-start w-100">
                            <div class="d-flex px-4 mt-2 mb-2 align-items-stretch">
                                <Card footer={footer} header={customerheader} title="For Customers" subTitle="Support for doctors,dieticians and health coaches" style={{ fontFamily: "Montserrat", maxWidth: "20rem", maxHeight: "32rem" }}>
                                    <p class="small" style={{ height: "2rem" }}>Our IZI support team is here to help with any questions regarding consultation, meal planning and groups.</p>
                                </Card>
                            </div>
                            <div class="d-flex px-4 mt-2 mb-2 align-items-stretch">
                                <Card footer={footer} header={practitionerheader} title="For Practitioners" subTitle="Enhanced live support for customers" style={{ fontFamily: "Montserrat", maxWidth: "20rem", maxHeight: "32rem" }}>
                                    <p class="small" style={{ height: "2rem" }}>Our IZI support team is here to help with any questions you might have about your IZI account, the IZI app.</p>
                                </Card>
                            </div>
                            <div class="d-flex px-4 mt-2 mb-2 align-items-stretch">
                                <Card footer={footer} header={corporateheader} title="For Corporates" subTitle="Enhanced live support for customers" style={{ fontFamily: "Montserrat", maxWidth: "20rem", maxHeight: "32rem" }}>
                                    <p class="small" style={{ height: "2rem" }}>Our IZI support team is here to help with any questions for corporates</p>
                                </Card>
                            </div>
                        </div> */}
        {/* <div className="row mt-2 mb-2">
                            <p>{language.lang.support2}</p>
                            <p>{language.lang.support3}</p>
                        </div> */}
        {/* <div className="d-flex flex-column justify-content-start w-100 mt-2 mb-2">
                            <div class="d-flex"><h4 className="mt-2 mb-2">{language.lang.support6}</h4></div>
                            <div className="d-flex flex-row justify-content-start w-100 mt-2 mb-2">
                                <div class="d-flex">
                                    {language.lang.support4}

                                </div>
                                <div class="d-flex">
                                    {language.lang.support5}
                                </div>
                            </div>
                        </div> */}
        {/* </div>
                </div> */}
      </div>
    </section>
  );
};

export default Support;
