import { useEffect } from "react";
import ReactGA from "react-ga4";
import { isProductionEnv, GoogleAnalyticsMID } from "../config/index";

const GoogleAnalytics = () => {
  useEffect(() => {
    if (!isProductionEnv) return;
    ReactGA.initialize(GoogleAnalyticsMID);
  }, []);

  return <span className="google-analytics"></span>;
};

export default GoogleAnalytics;
