import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AuthBase from "../../components/AuthBase";
import SocialAuthButtons from "../../components/SocialAuthButtons";
import Config from "../../config/index";
import { emailSigninRoute, phoneSigninRoute } from "../../routes";

const PasswordlessSignin = (props) => {
// Critical River, Varun Mishra, Routing Issue, user is not redirected to previous page before login pop-up
  let nextRoute = '/';
  let practitioner = {};
  let selectedSlot = {};
  if (props.location && props.location.state) {
    nextRoute = props.location.state.nextRoute;
    practitioner = props.location.state.practitioner;
    selectedSlot = props.location.state.selectedSlot;
  }
  const language = useSelector((state) => state.language.lang);

  return (
    <AuthBase className="passwordless-signin">
      <div className="logo-container">
        <img src={Config.logoLink} alt="IZI Health" className="izi-logo" />
      </div>
      <p className="text-center mb-3">{language.whyLogin ?? "Why Login ?"}</p>
      <p className="text-center mb-3 text-secondary">
        {language.simpleAndFree ??
          "Simple and its free. it is how you can access all our resources"}
      </p>
      <p className="text-center mb-3">
        {language.signupOrLoginWith ?? "Signup or Login with"}
      </p>
      <div className="d-flex justify-content-center mb-3">
       {/* Critical River, Varun Mishra, Routing Issue, user is not redirected to previous page before login pop-up */}
       {/* Critical River, Varun Mishra, hide mobile login functionality */}
        {/* <Link
          to={{
            pathname: phoneSigninRoute.path,
            state: { nextRoute, practitioner, selectedSlot }}}
          className="btn btn-primary btn-mobile-signin"
        >
          {language.mobileNumber ?? "Mobile Number"}
        </Link> */}
      </div>
      {/* Critical River, Varun Mishra, hide mobile login functionality */}
      {/* <p className="text-center mb-3 text-secondary">{language.or ?? "Or"}</p> */}
      {/* Critical River, Varun Mishra, Routing Issue, user is not redirected to previous page before login pop-up */}
      <SocialAuthButtons nextRoute={nextRoute} practitioner={practitioner} selectedSlot={selectedSlot}/>
      <div className="d-flex justify-content-center align-items-center">
        <p>{language.orLoginOrSignup ?? "Or login or signup"}</p>
        <Link to={emailSigninRoute.path} className="btn text-primary">
          <strong>{language.viaEmail ?? "via email"}</strong>
        </Link>
      </div>
    </AuthBase>
  );
};

export default PasswordlessSignin;
