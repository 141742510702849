const actions = {
  FETCH_USER_NOTIFICATIONS: "FETCH_USER_NOTIFICATIONS",
  UPDATE_USER_NOTIFICATIONS: "UPDATE_USER_NOTIFICATIONS",
  RESET_NOTIFICATIONS: "RESET_NOTIFICATIONS",
  SHOW_OLD_NOTIFICATIONS: "SHOW_OLD_NOTIFICATIONS",
  FETCH_OLD_NOTIFICATIONS: "FETCH_OLD_NOTIFICATIONS",
  SET_SIDEBAR: "SET_SIDEBAR",
  RESET_MODAL_NOTIFICATIONS: "RESET_MODAL_NOTIFICATIONS",
  SET_PREVIOUS_PAGE_PATH: "",
  SET_LOADING_NOTIFICATIONS: "SET_LOADING_NOTIFICATIONS",
};

export default actions;
