import { useCallback } from "react";
import { useSelector } from "react-redux";

const useIngredientName = () => {
  const languagePrefs = useSelector((state) => state.language);
  const currentLang = languagePrefs.general.preferredlanguage;

  const getName = useCallback(
    (ingredient) => {
      let name;
      if (currentLang === "ar") {
        name = ingredient?.ing_name_ar ?? ingredient?.ing_name;
      } else if (currentLang === "ku") {
        name = ingredient?.ing_name_ku ?? ingredient?.ing_name;
      } else {
        name = ingredient?.ing_name;
      }
      return name;
    },
    [currentLang]
  );

  return getName;
};

// Critical River, Varun Mishra desription widget of ingredient

export const useIngredientDescription = () => {
  const languagePrefs = useSelector((state) => state.language);
  const currentLang = languagePrefs.general.preferredlanguage;

  const getDescription = useCallback(
    (ingredient) => {
      let description;
      if (currentLang === "ar") {
        description = ingredient?.ing_description_ar ?? ingredient?.ing_description;
      } else if (currentLang === "ku") {
        description = ingredient?.ing_description_ku ?? ingredient?.ing_description;
      } else {
        description = ingredient?.ing_description;
      }
      return description;
    },
    [currentLang]
  );

  return getDescription;
};

export default useIngredientName;
