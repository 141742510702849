import {
  calendarColorTokensMap,
  isOnlineStorageKey,
} from "../../utility/constants";
import actions from "./actions";
import authActions from "../authenitcation/actions";
import moment from "moment";

let prescriptionForm = JSON.parse(localStorage.getItem("prescriptionForm"));

const initState = {
  isOnline: localStorage.getItem(isOnlineStorageKey) === "true",
  loading: false,
  currentAppointment: {},
  patientsQueue: {
    practitionerInfo: [],
    nextPatient: [],
    upcomingPatient: [],
    nextPatientsArr: [],
  },
  notifArr: [],
  prescriptionForm: {
    fName: (prescriptionForm && prescriptionForm.fName) || "",
    lName: (prescriptionForm && prescriptionForm.lName) || "",
    age: (prescriptionForm && prescriptionForm.age) || "",
    gender: (prescriptionForm && prescriptionForm.gender) || "",
    visit: (prescriptionForm && prescriptionForm.visits) || "",
    diagnosis: (prescriptionForm && prescriptionForm.diagnosis) || "",
    allergies: (prescriptionForm && prescriptionForm.allergies) || "",
    medicineList: (prescriptionForm && prescriptionForm.medicineList) || [],
    labTestList: (prescriptionForm && prescriptionForm.labTestList) || [],
    medication: (prescriptionForm && prescriptionForm.medication) || [],
  },
  calendar: {
    lastUpdateDate: moment(),
    calendarView: "day",
    events: [],
    viewSlot: false,
    needToCall: false,
    selectedSlot: {},
    loading: false,
  },
  practitionerDetails: [],
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.SET_CALENDAR_LOADING:
      return Object.assign({}, state, {
        calendar: Object.assign({}, state.calendar, {
          loading: payload,
        }),
      });
    case actions.SET_PRACTITIONER_SELECTED_SLOT_AND_VIEW:
      return Object.assign({}, state, {
        calendar: {
          ...state.calendar,
          selectedSlot: payload.selectedSlot,
          viewSlot: payload.viewSlot,
        },
      });
    case actions.ADD_PENDING_WALKIN_REQUESTS:
      let existingRequests = [...(state.notifArr || [])];
      existingRequests = existingRequests.filter((patientRequest) => {
        return !payload.find(
          (request) => request.appointmentId === patientRequest.appointmentId
        );
      });
      existingRequests = [...existingRequests, ...payload];
      return {
        ...state,
        notifArr: existingRequests,
      };
    case actions.NEW_PATIENT_REQUEST:
      // console.log("The payload", payload);
      // console.log("The notif arr", state.patientsQueue.notifArr);
      //   return Object.assign({}, state, {
      //     notifArr: [ ...state.patientsQueue.notifArr, payload.details],
      // });
      let newArr = [...(state.notifArr || [])];
      newArr = newArr.filter(
        (patientRequest) =>
          patientRequest.userId !== payload.details.userId &&
          patientRequest.appointmentId !== payload.details.appointmentId
      );
      newArr = [...newArr, payload.details];
      return {
        ...state,
        notifArr: newArr,
      };
    case actions.REMOVE_PATIENT_FROM_NOTIF_ARR:
      // console.log("The payload", payload);
      let arr = state.notifArr;
      arr.splice(payload, 1);
      return {
        ...state,
        notifArr: arr,
      };
    case actions.REMOVE_PATIENT_FROM_NOTIF_ARR_BY_ID:
      let array = state.notifArr;
      let index = -1;
      for (let i = 0; i < array.length; i++) {
        if (array[i].appointmentId === payload) {
          index = i;
          break;
        }
      }
      if (index !== -1) array.splice(index, 1);
      return {
        ...state,
        notifArr: array,
      };
    case actions.SET_QUEUE_OF_PATIENTS:
      let nextPatient =
        payload.data.nextPatient && payload.data.nextPatient.length > 0
          ? payload.data.nextPatient[0]
          : [];
      let upcomingPatient = payload.data.upcomingPatient;
      let arrayOfUp = payload.data.upcomingPatient
        ? [...payload.data.upcomingPatient]
        : [];
      if (payload.data.nextPatient && payload.data.nextPatient.length > 0) {
        arrayOfUp = [payload.data.nextPatient[0], ...arrayOfUp];
      }
      // let nextPatientsArr = arr;
      // let notifArr = state.patientsQueue.notifArr;
      let newObj = {
        practitionerInfo: payload.practitionerInfo,
        nextPatient: nextPatient,
        upcomingPatient: upcomingPatient,
        nextPatientsArr: arrayOfUp,
        notifArr: state.notifArr,
      };
      // console.log("--- SET_QUEUE_OF_PATIENTS", newObj, payload);
      return Object.assign({}, state, {
        patientsQueue: newObj,
      });
    case actions.SET_CURRENT_APPOINTMENT:
      return Object.assign({}, state, { currentAppointment: payload || {} });
    case actions.UPDATE_PATIENT_PRESCRIPTION:
      const formData = {
        fName: payload.fName || state.prescriptionForm.fName,
        lName: payload.lName || state.prescriptionForm.lName,
        age: payload.age || state.prescriptionForm.age,
        gender: payload.gender || state.gender,
        visit: payload.visit || state.visit,
        diagnosis: payload.diagnosis || state.diagnosis,
        allergies: payload.allergies || state.allergies,
        medicineList: payload.medicineList || state.medicineList,
        labTestList: payload.labTestList || state.labTestList,
        medication: payload.medication || state.medication,
      };
      return Object.assign({}, state, { prescriptionForm: formData });
    case authActions.LOGOUT_SUCCESS:
      return initState;
    case actions.STOP_LOADING:
      return Object.assign({}, state, {
        loading: false,
        currentAppointment: payload || {},
      });
    case actions.LOADING:
      return Object.assign({}, state, { loading: true });
    case actions.SET_STATUS_OFFLINE:
      return Object.assign({}, state, { isOnline: false });
    case actions.SET_STATUS_ONLINE:
      return Object.assign({}, state, { isOnline: true });
    case actions.SET_ALL_APPOINTMENTS_OF_PRACTITIONER:
      const docEvent = [];
      if (payload && payload.practitionerPendingLiveSessions) {
        payload.practitionerPendingLiveSessions.forEach((item) => {
          const statusID = item.status_id || item.sessionDetails.status_id;
          const styleToken = payload?.styles.tokens.find(
            (token) => token.status_id === statusID
          );
          docEvent.push(
            mapSessionToCalendarItem({
              session: item,
              statusText:
                (item.status && item.status.name) ||
                (item.sessionDetails.status && item.sessionDetails.status.name),
              colorToken: styleToken && styleToken.token,
            })
          );
        });
      }
      if (payload && payload.practitionerUpcomingLiveSessions) {
        payload.practitionerUpcomingLiveSessions.forEach((item) => {
          const statusID = item.status_id || item.sessionDetails.status_id;
          const styleToken = payload?.styles.tokens.find(
            (token) => token.status_id === statusID
          );
          docEvent.push(
            mapSessionToCalendarItem({
              session: item,
              statusText:
                (item.status && item.status.name) ||
                (item.sessionDetails.status && item.sessionDetails.status.name),
              colorToken: styleToken && styleToken.token,
            })
          );
        });
      }
      if (payload && payload.practitionerCancelledLiveSessions) {
        payload.practitionerCancelledLiveSessions.forEach((item) => {
          const statusID = item.status_id || item.sessionDetails.status_id;
          const styleToken = payload?.styles.tokens.find(
            (token) => token.status_id === statusID
          );
          docEvent.push(
            mapSessionToCalendarItem({
              session: item,
              statusText:
                (item.status && item.status.name) ||
                (item.sessionDetails.status && item.sessionDetails.status.name),
              colorToken: styleToken && styleToken.token,
            })
          );
        });
      }
      if (payload && payload.practitionerUnattendedLiveSessions) {
        payload.practitionerUnattendedLiveSessions.forEach((item) => {
          const statusID = item.status_id || item.sessionDetails.status_id;
          const styleToken = payload?.styles.tokens.find(
            (token) => token.status_id === statusID
          );
          docEvent.push(
            mapSessionToCalendarItem({
              session: item,
              statusText:
                (item.status && item.status.name) ||
                (item.sessionDetails.status && item.sessionDetails.status.name),
              colorToken: styleToken && styleToken.token,
            })
          );
        });
      }
      if (payload && payload.practitionerCompletedLiveSessions) {
        payload.practitionerCompletedLiveSessions.forEach((item) => {
          const statusID = item.status_id || item.sessionDetails.status_id;
          const styleToken = payload?.styles.tokens.find(
            (token) => token.status_id === statusID
          );
          docEvent.push(
            mapSessionToCalendarItem({
              session: item,
              statusText:
                (item.status && item.status.name) ||
                (item.sessionDetails.status && item.sessionDetails.status.name),
              colorToken: styleToken && styleToken.token,
            })
          );
        });
      }
      if (payload && payload?.practitionerPendingAppointments?.length > 0) {
        payload?.practitionerPendingAppointments.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText:
                payload.practitionerPendingAppointments[0].status.name,
              color:
                payload?.styles?.practitionerPendingAppointments?.left_border,
              colorToken:
                payload?.styles?.practitionerPendingAppointments?.token,
            })
          );
        });
      }
      if (payload && payload?.practitionerOngoingAppointments?.length > 0) {
        payload?.practitionerOngoingAppointments.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText:
                payload.practitionerOngoingAppointments[0].status.name,
              color:
                payload?.styles?.practitionerOngoingAppointments?.left_border,
              colorToken:
                payload?.styles?.practitionerOngoingAppointments?.token,
            })
          );
        });
      }
      if (payload && payload?.practitionerUpcomingAppointments?.length > 0) {
        payload?.practitionerUpcomingAppointments.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText:
                payload.practitionerUpcomingAppointments[0].status.name,
              color:
                payload?.styles?.practitionerUpcomingAppointments?.left_border,
              colorToken:
                payload?.styles?.practitionerUpcomingAppointments?.token,
            })
          );
        });
      }
      if (payload && payload?.practitionerCancelledAppointments?.length > 0) {
        payload?.practitionerCancelledAppointments.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText:
                payload.practitionerCancelledAppointments[0].status.name,
              color:
                payload?.styles?.practitionerCancelledAppointments?.left_border,
              colorToken:
                payload?.styles?.practitionerCancelledAppointments?.token,
            })
          );
        });
      }
      if (payload && payload?.practitionerCompletedAppointments?.length > 0) {
        payload?.practitionerCompletedAppointments.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText:
                payload.practitionerCompletedAppointments[0].status.name,
              color:
                payload?.styles?.practitionerCompletedAppointments?.left_border,
              colorToken:
                payload?.styles?.practitionerCompletedAppointments?.token,
            })
          );
        });
      }
      if (
        payload &&
        payload?.practitionerUnattendedAppointmentsByDoctor?.length > 0
      ) {
        payload?.practitionerUnattendedAppointmentsByDoctor.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText:
                payload.practitionerUnattendedAppointmentsByDoctor[0].status
                  .name,
              color:
                payload?.styles?.practitionerUnattendedAppointmentsByDoctor
                  ?.left_border,
              colorToken:
                payload?.styles?.practitionerUnattendedAppointmentsByDoctor
                  ?.token,
            })
          );
        });
      }
      if (
        payload &&
        payload?.practitionerUnattendedAppointmentsByPatient?.length > 0
      ) {
        payload?.practitionerUnattendedAppointmentsByPatient.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText:
                payload.practitionerUnattendedAppointmentsByPatient[0].status
                  .name,
              color:
                payload?.styles?.practitionerUnattendedAppointmentsByPatient
                  ?.left_border,
              colorToken:
                payload?.styles?.practitionerUnattendedAppointmentsByPatient
                  ?.token,
            })
          );
        });
      }
      if (payload && payload?.practitionerFailedAppointments?.length > 0) {
        payload?.practitionerFailedAppointments.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText: payload.practitionerFailedAppointments[0].status.name,
              color:
                payload?.styles?.practitionerFailedAppointments?.left_border,
              colorToken:
                payload?.styles?.practitionerFailedAppointments?.token,
            })
          );
        });
      }
      return Object.assign({}, state, {
        ...state,
        calendar: {
          ...state.calendar,
          events: docEvent,
          loading: false,
        },
      });
    case actions.SET_PRACTITIONER_VIEW_TYPE:
      return Object.assign({}, state, {
        ...state,
        calendar: {
          ...state.calendar,
          calendarView: payload,
        },
      });
    case actions.SET_PRACTITIONER_VIEW_SLOT:
      return Object.assign({}, state, {
        ...state,
        calendar: {
          ...state.calendar,
          viewSlot: payload,
        },
      });
    case actions.SET_NEED__TO_CALL_PRACTITIONER_CALENDAR:
      return Object.assign({}, state, {
        ...state,
        calendar: {
          ...state.calendar,
          lastUpdateDate: moment(),
          needToCall: payload,
        },
      });
    case actions.SET_PRACTITIONER_SELECTED_SLOT:
      return Object.assign({}, state, {
        ...state,
        calendar: {
          ...state.calendar,
          selectedSlot: payload,
        },
      });
    case actions.SET_PRACTITIONER_DETAILS:
      if (!payload) return state;
      return Object.assign({}, state, {
        ...state,
        practitionerDetails: payload,
      });
    default:
      return state;
  }
};

const mapSessionToCalendarItem = ({
  session,
  color,
  colorToken,
  statusText,
}) => {
  return {
    PractitionerName: session.title,
    PatientName: "",
    start: moment.utc(session.start_date_time_utc).toDate(),
    end: moment.utc(session.end_date_time_utc).toDate(),
    color: calendarColorTokensMap[colorToken] || color,
    appointmentId: session.id,
    statusText: statusText,
    statusID: session.status_id,
    calendarItemType: "session",
    ...session,
  };
};

const mapAppointmentToCalendarItem = ({
  appointment,
  statusText,
  color,
  colorToken,
}) => {
  return {
    PractitionerName: `${appointment?.practitionerInfo?.name || ""} ${
      appointment?.practitionerInfo?.surname || ""
    }`.trim(),
    PatientName: `${appointment?.patientInfo?.name || ""} ${
      appointment?.patientInfo?.surname || ""
    }`.trim(),
    start: new Date(appointment.start_date_time),
    end: new Date(appointment.end_date_time),
    color: calendarColorTokensMap[colorToken] || color,
    appointmentId: appointment.id,
    statusText: statusText,
    statusID: appointment.status_id,
    calendarItemType: "appointment",
    ...appointment,
  };
};

export default reducer;
