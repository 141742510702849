import axios from "../../services/Axios";

export const hideModalAction = () => {
  return {
    type: actions.HIDE_MODAL,
  };
};

export const showModalAction = () => {
  return {
    type: actions.SHOW_MODAL,
  };
};


export const scheduleAppointmentByRegion = async (data) => {
    try {
      const response = await axios.post(`/scheduleAppointmentByRegion`, data);//scheduleAppointment
      // console.log(response.data.response.appointmentId);
      return response.data.response;
    } catch (e) {
      console.log("---- schedule appointment error");
      console.log(e);
      return null;
    }
  };
  


export const stopLoadingAction = () => {
  return { type: actions.STOP_LOADING };
};

export const loadingAction = () => {
  return { type: actions.LOADING };
};

export const setRecordFileAction = (payload) => {
  return { type: actions.SET_RECORD_FILE, payload };
};

export const setMedicalDocFileAction = (payload) => {
  return { type: actions.SET_MEDICAL_DOC_FILE, payload };
};

export const setEmiratesIDImageAction = (payload) => {
  return { type: actions.SET_EMIRATES_ID_IMG, payload };
};

export const inputChangeAction = (payload) => {
  return {
    type: actions.INPUT_CHANGE,
    payload,
  };
};

export const initFormDataAction = (formData) => {
  return {
    type: actions.INIT_FORM_DATA,
    payload: formData,
  };
};

export const openFilterMenuAction = () => {
  return {
    type: actions.OPEN_FILTER_MENU,
  };
};

export const closeFilterMenuAction = () => {
  return {
    type: actions.CLOSE_FILTER_MENU,
  };
};

const actions = {
  INIT_FORM_DATA: "INIT_FORM_DATA",
  INPUT_CHANGE: "INPUT_CHANGE",
  SET_EMIRATES_ID_IMG: "SET_EMIRATES_ID_IMG",
  SET_MEDICAL_DOC_FILE: "SET_MEDICAL_DOC_FILE",
  SET_RECORD_FILE: "SET_RECORD_FILE",
  LOADING: "LOADING",
  STOP_LOADING: "STOP_LOADING",
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
  OPEN_FILTER_MENU: "OPEN_FILTER_MENU",
  CLOSE_FILTER_MENU: "CLOSE_FILTER_MENU",
};

export default actions;
