import { Button, Input } from "antd";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { getIngredients } from "../../../services/CustomerNetworkService";
import { approvedFoodItemStatusID, unapprovedFoodItemStatusID } from "../../../utility/constants";

const CustomExclusionsModal = ({ show, onClose, onItemSelected }) => {
  const language = useSelector((state) => state.language.lang);
  const [searchText, setSearchText] = useState("");
  const [ingredients, setIngredients] = useState(null);

  useEffect(() => {
    setSearchText("");
  }, [show]);

  useEffect(() => {
    if (searchText.trim().length < 3) {
      setIngredients([]);
      return;
    }
    // console.log({ searchText });
    (async () => {
      const res = await getIngredients({
        searchText,
        isCustomFilter: false,
        statusIDs: [approvedFoodItemStatusID, unapprovedFoodItemStatusID],
      });
      //   console.log({ res });
      setIngredients([{ id: `${searchText}-${new Date().getTime()}`, ing_name: searchText }, ...(res.response ?? [])]);
    })();
  }, [searchText]);

  return (
    <Modal
      backdrop="static"
      className="custom-exclusions-modal"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center mb-2">
          {language["add-your-own-exclusions"] ?? "add-your-own-exclusions"}
          <Button type="text" onClick={onClose}>
            <AiOutlineClose />
          </Button>
        </div>
        <Input allowClear className="mb-2" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        <div className="ingredients-list">
          {ingredients?.map((item) => {
            return (
              <div onClick={() => onItemSelected && onItemSelected(item)} className="custom-exclusion-item" key={item.id}>
                {item.ing_name}
              </div>
            );
          })}
          {searchText && ingredients && ingredients.length === 0 && (
            <div className="text-center">{language["no-results-found"] ?? "no-results-found"}</div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CustomExclusionsModal;
