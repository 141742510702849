import React from "react";
import moment from "moment";
import ReactAudioPlayer from 'react-audio-player';
import { useSelector } from "react-redux";
const PatinetAppointmentDetailsCard = ({ appointment }) => {
  const language = useSelector((state) => state.language);
  if (!appointment) return <div></div>;
  const startTime = appointment.start_date_time
    ? new Date(appointment.start_date_time || "")
    : null;
  const endTime = appointment.end_date_time
    ? new Date(appointment.end_date_time || "")
    : null;

    console.log("the voice note", appointment);
  return (
    <div className="patient-appointment-details-card mm-card">
      <div className="d-flex align-items-start">
        <i className="fal fa-clock"></i>
        <div className="d-flex flex-column ml-3">
          <p className="mb-0 title-text">{language.lang["visit-time"]}</p>
          {startTime && (
            <p className="mb-0">
              {moment(startTime).format("a") == "am" ? language.lang.morning : language.lang.evening}
            </p>
          )}
          {startTime && <p className="mb-0">{moment(startTime).calendar()}</p>}
          {startTime && endTime && (
            <p className="mb-0 visit-time">
              {moment(startTime).format("hh:mm a")} -{" "}
              {moment(endTime).format("hh:mm a")}
            </p>
          )}
        </div>
      </div>
      {/* <div className="d-flex align-items-start mt-3">
        <i className="far fa-dollar-sign"></i>
        <div className="d-flex flex-column ml-3">
          <p className="mb-0 title-text">{language.lang["payment-info"]}</p>
          <p className="mb-0">NA</p>
          <p className="mb-0">5$</p>
        </div>
      </div> */}
      <h6 className="label title-text mt-3 mb-3">{language.lang["reason-of-visit"]}</h6>
      <textarea
        onChange={() => {}}
        value={appointment.reason || ""}
        className="neumorphic-input"
        name="reason"
      />
      {appointment.voice_note_url && appointment.voice_note_url !== "null" && (
        <div className="voice-record mt-3">
          <p className="text">{language.lang["listen-to-voice-note"]}</p>
          {/* <button className="btn">
            <i className="fas fa-play-circle"></i>
          </button> */}
          <ReactAudioPlayer
            src={appointment.voice_note_url}
            controls
          />
        </div>
      )}
      {appointment.medical_report_url &&
        appointment.medical_report_url !== "null" && (
          <div className="medical-report mt-3">
            <p className="text">{language.lang["view-medical-report"]}</p>
            <a className="btn" href={appointment?.medical_report_url} download="medical_report.pdf" target="_blank">
              <i className="far fa-file-pdf"></i>
            </a>
          </div>
        )}
    </div>
  );
};

export default PatinetAppointmentDetailsCard;
