import MostPopularCard from "./MostPopularCard";
import NotificationsButton from "./NotificationsButton";
import ProfileCircleButton from "./ProfileCircleButton";
import SearchInputField from "./SearchInputField";
import TopOnlineDoctors from "./TopOnlineDoctors";
import videoImg from "../assets/images/MealPlan2.png";
import MostPopularReminderCards from "./MostPopularReminderCards";
import MostPopulartTrendingCard from "./MostPopulartTrendingCard";
import MostPopularTopPractitioner from "./MostPopularTopPractitioner";
import MostPopularAppointment from "./MostPopularAppointment";
import BackButton from "./BackButton";
import { useSelector } from "react-redux";
const DashboardMostPopular = () => {
  const language = useSelector((state) => state.language);
  const data = [
    {
      time: "40 min",
      src: "https://i0.wp.com/images-prod.healthline.com/hlcmsresource/images/topic_centers/man-deadlift-1296x728-header.jpg?w=1155&h=1528",
    },
    {
      time: "40 min",
      src: "https://i0.wp.com/images-prod.healthline.com/hlcmsresource/images/topic_centers/man-deadlift-1296x728-header.jpg?w=1155&h=1528",
    },
  ];
  return (
    <section className="row px-4">
      <div className="d-flex justify-content-between p-0 m-0 align-items-center">
        <BackButton />
        <div className="d-flex">
          <NotificationsButton className="ml-auto" />
          <ProfileCircleButton />
        </div>
      </div>
      <div className="d-flex justify-content-center transform-tab ml-3">
        <section className="row pt-5 px-4" style={{ maxWidth: "900px" }}>
          <div className="d-flex justify-content-between">
            <div>
              <h3 className="Page-title">{language.lang["most-popular"]}</h3>
              <div className="title-border"></div>
            </div>
            <div className="d-flex" style={{ gap: "10px" }}>
              <div className="card" style={{ height: "0" }}>
                <i className="fas fa-chevron-left"></i>
              </div>
              <div className="card" style={{ height: "0" }}>
                <i className="fas fa-chevron-right"></i>
              </div>
            </div>
          </div>
          <div className="py-3 d-flex most-popular-scroll">
            {data &&
              data.map((item, i) => {
                return (
                  <div
                    className="position-relative scroll-snap"
                    style={{ height: "400px" }}
                    key={i}
                  >
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ gap: "20px" }}
                    >
                      <MostPopularCard />
                      <MostPopularCard />
                    </div>
                    <div
                      className="d-flex justify-content-between align-items-center pt-3"
                      style={{ gap: "20px" }}
                    >
                      <MostPopularCard />
                      <MostPopularCard />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="w-100">
            <div className="py-4" style={{ width: "max-content" }}>
              <p style={{ fontSize: "20px", fontWeight: "600" }}>
                Upcoming Reminders
              </p>
              <div className="title-border"></div>
            </div>
            <div
              className="d-flex justify-content-between flex-wrap"
              style={{ gap: "10px" }}
            >
              <MostPopularAppointment />
              <MostPopularReminderCards
                reminder={"Yoga Class"}
                start={"5pm"}
                end={"6pm"}
                when={"Tomorrow"}
              />
              <MostPopularReminderCards
                reminder={"Drink Water"}
                start={"5pm"}
                end={"6pm"}
                when={"Tomorrow"}
              />
            </div>
          </div>
        </section>
        <section
          className="row pt-5 px-5 w-100 flex-column flex-fill"
          style={{ maxWidth: "500px" }}
        >
          <div className="d-flex flex-column justify-content-start h-min w-min">
            <h3 style={{ whiteSpace: "nowrap" }}> Trending Now</h3>
            <div className="title-border"></div>
          </div>
          <div className="pt-5" style={{ height: "500px" }}>
            <div
              className="d-flex justify-content-between h-min w-100"
              style={{ gap: "10px" }}
            >
              <div className="most-popular-tags">health</div>
              <div className="most-popular-tags active">diet</div>
              <div className="most-popular-tags">wellbeing</div>
            </div>
            <div className="d-flex flex-column pt-5 ">
              <MostPopulartTrendingCard />
              <MostPopulartTrendingCard />
            </div>
          </div>
          <div className="w-100">
            <div className="py-4" style={{ width: "max-content" }}>
              <p style={{ fontSize: "20px", fontWeight: "600" }}>
              {language.lang["top-practitioners"]}
              </p>
              <div className="title-border"></div>
            </div>
            <MostPopularTopPractitioner />
            <MostPopularTopPractitioner />
          </div>
        </section>
      </div>
    </section>
  );
};

export default DashboardMostPopular;
