import axios from "./Axios";
import moment from "moment";

export const setSessionControls = async ({ sessionID, controlID }) => {
  try {
    const response = await axios.post("/controlSessionCall", {
      liveSessionId: sessionID,
      attendeeId: "",
      controlId: controlID,
    });
    return response.data.socket.callControlRequest;
  } catch (e) {
    console.log("--- setSessionControles error");
    console.log(e);
    return false;
  }
};

export const getSessionAttendeesList = async ({ sessionID }) => {
  try {
    const response = await axios.get("/getLiveSessionAttendees", {
      params: { liveSessionId: sessionID },
    });
    return response.data.response;
  } catch (e) {
    console.log("--- getSessionAttendeesList error");
    console.log(e);
    return null;
  }
};

export const updateSessionStatus = async ({
  sessionID,
  userSessionID,
  statusID,
}) => {
  try {
    const response = await axios.post("/updateSessionStatus", {
      liveSessionId: sessionID,
      userLiveSessionId: userSessionID,
      statusId: statusID,
    });
    const data = response.data;
    if (data.error) return null;
    return data.response[0].id;
  } catch (e) {
    console.log("--- updateSessionStatus error");
    console.log(e);
    return null;
  }
};

export const customerRegisterForSession = async ({ sessionID }) => {
  try {
    const response = await axios.post("/bookLiveSession", {
      liveSessionId: sessionID,
    });
    return response.data.response.userLiveSession.id;
  } catch (e) {
    console.log("--- customerRegisterForSession error");
    console.log(e);
    return null;
  }
};

export const checkIfRegisteredForLiveSession = async ({ sessionID }) => {
  try {
    const response = await axios.get("/getCustomerLiveSessions", {
      params: { liveSessionId: sessionID },
    });
    return response.data.response.length > 0;
  } catch (e) {
    console.log("--- checkIfRegisteredForSession error");
    console.log(e);
    return false;
  }
};

export const getCustomerLiveSessionsList = async ({
  startDate,
  endDate,
  sessionID,
  limit,
  cacheRead = true,
}) => {
  try {
    const now = moment();
    const params = {
      startDate: (startDate || now).format("YYYY-MM-DD"),
      endDate: (endDate || now.clone().add(1, "month")).format("YYYY-MM-DD"),
      statusId: 30,
      liveSessionId: sessionID,
      limit: limit || 100,
      cacheRead,
    };
    // console.log("--- getCustomerLiveSessionsList params", params);
    const res = await axios.get("/getLiveSessions", {
      params: params,
    });
    // console.log("--- res.data", res.data);
    return res.data.response.liveSessions.filter((item) => {
      return moment.utc(item.end_date_time_utc).isAfter(now);
    });
  } catch (e) {
    console.log("--- getCustomerLiveSessionsList error");
    console.log(e);
    // debugger;
    return null;
  }
};
