import { useEffect, useReducer } from "react";
import reducer, { initState } from "./reducer";
import { Elements } from "@stripe/react-stripe-js";
import { initStripePaymentForm } from "./actions";
import config from "../../config";
import { FaAddressCard } from "react-icons/fa";
import Loading from "../../components/Loading";
import StripeCheckoutForm from "./StripeCheckoutForm";
import TopMenu from "../../components/CommonControls/TopMenu";
import { useSelector } from "react-redux";

const StripePaymentCheckout = ({ history }) => {
  const paymentInfo = history.location.state;
  const language = useSelector((state) => state.language);
  // console.log("--- StripePaymentCheckout", paymentInfo);
  const [state, dispatch] = useReducer(reducer, initState);

  useEffect(() => {
    initStripePaymentForm({ dispatch, ...paymentInfo });
  }, [paymentInfo]);

  const stripeOptions = {
    clientSecret:
      state.paymentRegisterationData &&
      state.paymentRegisterationData.ClientSecret,
  };

  return (
    <section className="stripe-payment-checkout mb-4">
      <div className="container-fluid">
        <div className="row">
          <TopMenu
            shouldHideBackButton={false}
            shouldHideProfileButton={true}
            shouldHideNotificationButton={true}
            shouldHideLogoButton={true}
            pageTitle={language.lang["payment-info"]}
          />
        </div>
        <div className="payment-card" style={{ "min-height": "30rem" }}>
          {state.loading ? (
            <div className="row justify-content-center">
              <Loading />
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-6 description-content">
                <p className="font-weight-normal py-3">
                  Choose your convenient option to pay
                </p>
                <p className="pb-2">
                  The easy-to-use contactless digital payment option is
                  available from within the app to the millions of customers.
                </p>
                <p className="font-weight-bold">
                  You can pay using the following options
                </p>
                <div>
                  <img
                    src={config.mastercardLink}
                    alt="mastercard"
                    className="payment-service ml-md-2"
                  />
                  <img
                    src={config.visaLink}
                    alt="visa"
                    className="payment-service ml-md-4"
                  />
                </div>
              </div>
              <div className="col-lg-6 field-content d-flex flex-column align-items-center">
                <div className="w-100 d-flex">
                  <div className="card-card d-flex justify-content-center align-items-center my-3">
                    <FaAddressCard size={30} />
                    <div className="ml-2">Card</div>
                  </div>
                </div>
                <Elements
                  stripe={state.loadStripePromise}
                  options={stripeOptions}
                >
                  <StripeCheckoutForm
                    amount={paymentInfo.amount}
                    currency={
                      language.lang[paymentInfo.currency] ||
                      paymentInfo.currency
                    }
                    redirectURL={paymentInfo.redirectURL}
                  />
                </Elements>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default StripePaymentCheckout;
