import React, { useState, useRef, useEffect } from "react";
import DoctorCard from "./DoctorCard";
import axios from "../services/Axios";
import moment from "moment";
// import DoctorCard from "../components/DoctorCard";
import { agoraRoute } from "../routes";
// import moment from "moment";
import { useSelector } from "react-redux";
// import { updateConsultationStatusAction } from "./DoctorJoinForConsultation/actions";
import { updateConsultationStatusAction } from "../pages/DoctorJoinForConsultation/actions";
import { useHistory } from "react-router-dom";

import {
  getAppointmentDetails,
  isMobileOrTabletBrowser,
  showErrorNotification,
} from "../utility/Helpers";
import { currentAppointmentDetailsKey } from "../utility/constants";
import { settings } from "../config";
import DoctorAppointmentCard from "./DoctorAppointmentCard";
import MobileAppPropmtModal from "./MobileAppPropmtModal";

const PreviousAppointmentsSection = () => {
  const { userData } = useSelector((state) => state.auth);
  const [showMobilePromptModal, setShowMobilePromptModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [yesterdayAppointments, setyesterdayAppointments] = useState([]);
  const [todaysAppointments, setTodaysAppointments] = useState([]);
  const [tomorrowsAppointments, setTomorrowsAppointments] = useState([]);
  const history = useHistory();
  const language = useSelector((state) => state.language);
  const [doctorStartedCall, setDoctorStartedCall] = useState(null);
  const { appointmentDetails, doctorStartedAppointmentID, status } =
    useSelector((state) => {
      return state.client;
    });
  const [activeButton, setactiveButton] = useState(
    doctorStartedCall && doctorStartedAppointmentID === doctorStartedCall.id
  );
  const practitionerInfoRef = useRef(null);
  const canLeavePage = useRef(false);
  canLeavePage.current = false;
  useEffect(() => {
    fetchTopPractitioners();
  }, [loading]);
  useEffect(() => {
    console.log("todaysAppointments -->", todaysAppointments);
  }, [todaysAppointments]);
  useEffect(() => {
    console.log("tomorrowsAppointments -->", tomorrowsAppointments);
  }, [tomorrowsAppointments]);
  useEffect(() => {
    console.log("yesterdayAppointments -->", yesterdayAppointments);
  }, [yesterdayAppointments]);
  useEffect(() => {
    let setDoctorDetails = appointments.find(
      (x) => x.id === doctorStartedAppointmentID
    );
    console.log(setDoctorDetails, "setDoctorDetails");
    setDoctorStartedCall(setDoctorDetails);
    if (doctorStartedCall) {
      setactiveButton(
        doctorStartedCall && doctorStartedAppointmentID === doctorStartedCall.id
      );
      /* showSuccessNotification(
      null,
      <p>
        {language.lang["doctor-started-call"]}. Click on{" "}
        <span className="meet-doctor-notificaiton-text">Meet Doctor</span>{" "}
        button to start the call
      </p>
    );*/
    }
  }, [
    appointmentDetails,
    doctorStartedAppointmentID,
    appointments,
    appointments,
    doctorStartedCall,
  ]);

  var getWalkinAppointments,
    getScheduledAppointments,
    todaysAppointment,
    tomorrowsAppointment,
    prevAppointment;
  const fetchTopPractitioners = async () => {
    try {
      const response = await axios.get(
        `${settings.apiBaseURL}/getCustomerAppointments?customerId=${userData.id}`
      );
      const data = response.data.response;
      /*console.log("All appointments --> ",response.data.response);
      response.data.response.map(a => {
        console.log(moment(a.start_date_time).format("DD:MM:YY"),a.status_id)
      })*/
      response.data.response.map((appointment) => {
        console.log(
          moment(appointment.start_date_time).format("DD"),
          moment(appointment.start_date_time).format("DD") ===
            moment().subtract(1, "day").format("DD")
        );
      });
      setAppointments(data);
      const today = moment();
      const tomorrow = moment().add(1, "day");
      todaysAppointment =
        appointments &&
        appointments.filter((appointments) => {
          return today.isSame(
            moment(appointments.start_date_time)
              .local()
              .format("YYYY-MM-DD HH:mm:ss"),
            "day"
          );
        });
      prevAppointment =
        appointments &&
        appointments.filter((appointments) => {
          return !today.isSame(
            moment(appointments.start_date_time)
              .local()
              .format("YYYY-MM-DD HH:mm:ss"),
            "day"
          );
        });
      setyesterdayAppointments(
        appointments.filter(
          (appointment) =>
            moment(appointment.start_date_time).format("DD") ===
            moment().subtract(1, "day").format("DD")
        )
      );
      setTodaysAppointments(todaysAppointment);
      tomorrowsAppointment =
        appointments &&
        appointments.filter((appointments) =>
          tomorrow.isSame(
            moment(appointments.start_date_time)
              .local()
              .format("YYYY-MM-DD HH:mm:ss"),
            "day"
          )
        );
      // setTodaysAppointments(tomorrowsAppointment);
      tomorrowsAppointment =
        appointments &&
        appointments.filter((appointments) =>
          tomorrow.isSame(
            moment(appointments.start_date_time)
              .local()
              .format("YYYY-MM-DD HH:mm:ss"),
            "day"
          )
        );
      setTomorrowsAppointments(tomorrowsAppointment);
      console.log({ todaysAppointments, tomorrowsAppointments });
    } catch (e) {
      // setError(defaultErrorMsg);
      console.log(e);
    } finally {
      setLoading(true);
    }
  };

  const meetDoctorPressed = async () => {
    if (doctorStartedCall) {
      setLoading(true);
      const values = await Promise.all([
        updateConsultationStatusAction(doctorStartedAppointmentID, "15"),
        getAppointmentDetails(doctorStartedCall.id),
      ]);
      const data = values[0];
      if (data && data.id) {
        canLeavePage.current = true;
        localStorage.setItem(
          currentAppointmentDetailsKey,
          JSON.stringify(values[1])
        );
        joinAppointmentCall();
      } else {
        setLoading(false);
        showErrorNotification();
      }
    }
  };

  const joinAppointmentCall = () => {
    if (isMobileOrTabletBrowser()) {
      setShowMobilePromptModal(true);
    } else {
      history.replace(agoraRoute.path);
    }
  };

  const onMobileModalPromptClose = () => {
    setShowMobilePromptModal(false);
    history.replace(agoraRoute.path);
  };

  return (
    <div className="dashboard-book-consultation">
      <MobileAppPropmtModal
        show={showMobilePromptModal}
        onCloseClicked={onMobileModalPromptClose}
      />
      <div className="previous-appointments">
        <h5>{language.lang.appointments}</h5>
        <h6 className="date-header">{language.lang.todays}</h6>
        {todaysAppointments.length > 0 ? (
          todaysAppointments.map((appointment) => {
            //console.log(appointment,moment().isBefore(appointment.end_date_time) && moment().isAfter(appointment.start_date_time),appointment.id,doctorStartedAppointmentID)
            if (
              appointment.appointment_type === "scheduled" &&
              appointment.status_id === 1
            ) {
              console.log("appointment", appointment);
              return (
                <DoctorAppointmentCard
                  id={appointment?.practitioner_id}
                  name={appointment.practitionerInfo.name}
                  shownow={
                    activeButton &&
                    appointment.id === doctorStartedAppointmentID
                  }
                  meetDoctorPressed={meetDoctorPressed}
                  activeButton={activeButton}
                  imgSrc={appointment.practitionerInfo.profile_photo_url}
                  rate={
                    appointment.practitionerInfo.rating.aggregate.avg.rating
                  }
                  middle={language.lang["video-call"]}
                  bottom={`${moment(appointment.start_date_time)
                    .local()
                    .format("HH:mm")} - ${moment(appointment.end_date_time)
                    .local()
                    .format("HH:mm")}`}
                />
              );
            }
          })
        ) : (
          <div>{language.lang["no-appointments"]}</div>
        )}
        <h6 className="date-header">{language.lang.tomorrows}</h6>
        {tomorrowsAppointments.length > 0 ? (
          tomorrowsAppointments.map((appointment) => (
            <DoctorAppointmentCard
              id={appointment?.practitioner_id}
              name={appointment.practitionerInfo.name}
              imgSrc={appointment.practitionerInfo.profile_photo_url}
              rate={appointment.practitionerInfo.rating.aggregate.avg.rating}
              middle={language.lang["video-call"]}
              bottom={`${moment(appointment.start_date_time)
                .local()
                .format("HH:mm")} - ${moment(appointment.end_date_time)
                .local()
                .format("HH:mm")}`}
            />
          ))
        ) : (
          <div>{language.lang["no-appointments"]}</div>
        )}
        <h6 className="date-header">{language.lang.yesterday}</h6>
        {yesterdayAppointments.length > 0 ? (
          yesterdayAppointments.map((appointment) => {
            return (
              <DoctorAppointmentCard
                id={appointment?.practitioner_id}
                name={appointment.practitionerInfo.name}
                imgSrc={appointment.practitionerInfo.profile_photo_url}
                rate={appointment.practitionerInfo.rating.aggregate.avg.rating}
                middle={language.lang["video-call"]}
                bottom={`${moment(appointment.start_date_time)
                  .local()
                  .format("HH:mm")} - ${moment(appointment.end_date_time)
                  .local()
                  .format("HH:mm")}`}
              />
            );
          })
        ) : (
          <div>{language.lang["no-appointments"]}</div>
        )}
      </div>
    </div>
  );
};

export default PreviousAppointmentsSection;
