import actions from "./actions";

const initState = {
  defaultChatUser: {},
  selectedUserStatus: false,
  selectedUserID: {},
  matchedUsers: [],
  userConversations: [],
  allMatches: [],
  currentBuddyDetails: {},
  buddyDetails: {},
  interestList: [],
  rejectedUserDetails: [],
  isViewMatchesLoading: false,
  isSettingsLoading: false,
  isLiveChatPageLoading: false,
  isLiveChatWindowLoading: false,
  isFetchedUserDetails: false,
  isConversationRefreshed: false,
  newProfileImage: "",
  userDetails: {
    id: "",
    isAdult: true,
    name: "",
    surName: "",
    profilePhotoUrl: "",
    location: [24.4539, -54.3773],
    locationName: "",
    matchMeWith: "1",
    ageRangeForMatch: { min: 18, max: 30 },
    isSwipeWithFriends: false,
    aboutInfo: "",
    interests: [],
    allowedNotifications: [],
    distance: 50000,
    showOnlySameInterest: false,
    isVaccinated: false,
  },
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.GET_ALL_THE_MATCHES:
      return Object.assign({}, state, {
        ...state,
        allMatches: payload.allMatches,
      });
    case actions.UPDATE_MATCHED_USER:
      return Object.assign({}, state, {
        ...state,
        selectedUserID: payload.selectedUserID,
        selectedUserStatus: payload.selectedUserStatus,
      });
    case actions.GET_USER_CONVERSATION:
      return Object.assign({}, state, {
        ...state,
        userConversations: payload.userConversations,
      });
    case actions.GET_MATCHED_USERS:
      return Object.assign({}, state, {
        ...state,
        matchedUsers: payload.matchedUsers,
      });
    case actions.VIEW_BUDDY_DETAILS:
      return Object.assign({}, state, {
        ...state,
        currentBuddyDetails: payload.currentBuddyDetails,
        isViewMatchesLoading: false,
      });
    case actions.UPDATE_LIVE_CHAT_PAGE_LOADING:
      return {
        ...state,
        isLiveChatPageLoading: payload.isLiveChatPageLoading,
      };
    case actions.UPDATE_LIVE_CHAT_WINDOW_LOADING:
      return {
        ...state,
        isLiveChatWindowLoading: payload.isLiveChatWindowLoading,
      };
    case actions.UPDATE_VIEW_MATCHES_LOADING:
      return {
        ...state,
        isViewMatchesLoading: payload.isViewMatchesLoading,
      };
    case actions.UPDATE_SETTINGS_LOADING:
      return {
        ...state,
        isSettingsLoading: payload.isSettingsLoading,
      };
    case actions.GET_PATIENT_DETAILS:
      return Object.assign({}, state, {
        ...state,
        buddyDetails: payload.buddyDetails,
        userDetails: payload.userDetails,
        isFetchedUserDetails: payload.isFetchedUserDetails,
      });
    case actions.GET_INTEREST_DETAILS:
      return Object.assign({}, state, {
        ...state,
        interestList: payload,
      });
    case actions.REMOVE_USER_INTEREST:
      let tempInterests = payload.map((value) => {
        return value.interest.id;
      });
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          interests: tempInterests,
        },
        buddyDetails: {
          ...state.buddyDetails,
          userInterests: payload,
        },
      };
    case actions.UPDATE_USER_INTEREST:
      let interest = payload.map((load) => {
        return { interest: load };
      });
      let userInterestsList = payload.map((value) => {
        return value.id;
      });

      return {
        ...state,
        buddyDetails: {
          ...state.buddyDetails,
          userInterests: [...state.buddyDetails.userInterests, ...interest],
        },
        userDetails: {
          ...state.userDetails,
          interests: [...state.userDetails.interests, ...userInterestsList],
        },
      };
    case actions.UPDATE_ABOUT_INFO:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          aboutInfo: payload,
        },
      };
    case actions.UPDATE_PROFILE_IMAGE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          profilePhotoUrl: payload.profilePhotoUrl,
        },
      };
      case actions.UPDATE_NEW_PROFILE_IMAGE:
        console.log("inreducer",payload.newProfilePhotoUrl);
      return {
        ...state,      
          newProfilePhotoUrl: payload.newProfilePhotoUrl,
     
      };
    case actions.UPDATE_LOCATION:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          location: payload.location,
          locationName: payload.locationName,
        },
      };
    case actions.UPDATE_DISTANCE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          distance: payload.distance,
        },
      };
    case actions.UPDATE_MATCHMEWITH:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          matchMeWith: payload.matchMeWith,
        },
      };
    case actions.UPDATE_ADULT_STATUS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isAdult: payload.isAdult,
        },
      };
    case actions.UPDATE_MIN_AGE_RANGE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ageRangeForMatch: {
            ...state.userDetails.ageRangeForMatch,
            min: payload.min,
          },
        },
      };
    case actions.UPDATE_MAX_AGE_RANGE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ageRangeForMatch: {
            ...state.userDetails.ageRangeForMatch,
            max: payload.max,
          },
        },
      };
    case actions.UPDATE_NOTIFICATIONS_SETTINGS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          allowedNotifications: payload.notifications,
          isVaccinated: payload.vaccinated,
        },
      };
    case actions.UPDATE_REJECTED_USER_DETAILS:
      let updateRejectedList = [...state.rejectedUserDetails];
      updateRejectedList.unshift(payload);
      return {
        ...state,
        rejectedUserDetails: updateRejectedList,
      };
    case actions.REMOVE_REJECTED_USER_DETAILS:
      let removeRejectedList = [...state.rejectedUserDetails];
      removeRejectedList.splice(0, 1);
      return {
        ...state,
        rejectedUserDetails: removeRejectedList,
      };
    case actions.UPDATE_FETCHED_USER_DETAILS:
      return {
        ...state,
        isFetchedUserDetails: payload.isFetchedUserDetails,
      };
    case actions.UPDATE_IS_NEXT_CONVERSATION_REFRESHED:
      return {
        ...state,
        isConversationRefreshed: payload.isConversationRefreshed,
      };
    case actions.SET_DEFAULT_CHAT_USER:
      return {
        ...state,
        defaultChatUser: payload.defaultChatUser,
      };
    default:
      return state;
  }
};
export default reducer;
