import { useEffect, useState } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchregions,
  UpdateGeneralSettings,
} from "../redux/language/actionCreators";
import { settings } from "../config";
import axios from "axios";
import actions from "../redux/user/actions";
import Loading from "./Loading";
import {
  resetnewNotificationsAction,
  resetOldNotificationsAction,
} from "../redux/user/actionsCreators";
import { dashboardRoute } from "../routes";
import { useHistory } from "react-router-dom";
import Config from "../config/index";
import { useWindowDimension } from "./WindowsSize";
import RegionDropdown from "./RegionDropdown";

const { Option } = Select;

export const countryFlagsMap = new Map();
countryFlagsMap.set(1, Config.UAEFlagIcon);
countryFlagsMap.set(2, Config.IRAQFlagIcon);
countryFlagsMap.set(3, Config.EGYPTFlagIcon);

const GeneralSettings = () => {
  const dispatch = useDispatch();
  const windowHeight = window.innerHeight;
  console.log("ii",windowHeight);
  const history = useHistory();
  const language = useSelector((state) => state.language);
  const userData = useSelector((state) => state.auth.userData);
  const [width, height] = useWindowDimension();
  const [isExpanded, setIsExpanded] = useState(width > 2000);
  const [isMobile, setIsMobile] = useState(width < 2000);
  const [loading, setloading] = useState(false);
  const [Input, setInput] = useState({});
  useEffect(() => {
    setInput((prevState) => {
      return {
        ...prevState,
        preferredlanguage: language.general.preferredlanguage,
        regionId: language.regionID,
        theme: language.general.theme,
      };
    });
  }, [language.general]);
  const onSelectHandler = (value) => {
    var name = "";
    if (value === "light" || value === "dark") {
      name = "theme";
    } else {
      if (!isNaN(value)) {
        name = "regionId";
      } else {
        name = "preferredlanguage";
      }
    }
    setInput((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const submitGeneralProfile = () => {
    setloading(true);
    dispatch(UpdateGeneralSettings(Input));
    if (userData) {
      dispatch(
        resetOldNotificationsAction(userData?.id, Input.preferredlanguage)
      );
      dispatch(
        resetnewNotificationsAction(userData?.id, Input.preferredlanguage)
      );
    }
    setloading(false);
  };
  const [languages, setlangauges] = useState([]);
  const getLanguage = async () => {
    const res = await axios.get(`${settings.apiBaseURL}/getProfileLanguages`);
    setlangauges(res.data.response);
    console.log(res.data.response);
  };
  useEffect(() => {
    language.general.regions.length === 0 && dispatch(fetchregions());
    languages.length === 0 && getLanguage();
  }, []);
  const navigateTo = () => {
    history.push(dashboardRoute.path);
  };
  return (
    <div className="generalSettings mb-2">
      <div className={"custom-profile-setting-card" + `${language.direction}`} style={{minHeight: (windowHeight-300) +'px'}}>
        {/* <div className="custom-profile-setting-heading">
        <h4 className="Page-title-custom">{language.lang.general}</h4>
        <div className="title-border"></div>
      </div> */}
        <div className="my-3 mx-2 w-100 d-flex align-items-center">
          <div className="custom-profile-setting-label">
            {language.lang.region}
          </div>
          <RegionDropdown
            isExpanded={isExpanded}
            showLabel={false}
            isMobile={isMobile}
          />
        </div>

        <div className="my-3 mx-2 w-100 d-flex align-items-center">
          <div className="custom-profile-setting-label">
            {language.lang.language}
          </div>
          <div className="custom-profile-setting-select">
            <Select
              onChange={onSelectHandler}
              value={Input.preferredlanguage}
              name="preferredlanguage"
            >
              {languages.length > 0 &&
                languages.map((lang, i) => {
                  return (
                    <Option
                      value={
                        lang.id === 93 ? "ku" : lang.id === 43 ? "en" : "ar"
                      }
                    >
                      {lang.name}
                    </Option>
                  );
                })}
            </Select>
          </div>
        </div>
        {/* <div className="my-3 mx-2 w-100 d-flex align-items-center">
          <div className="custom-profile-setting-label">
            {language.lang.language}
          </div>
          <div className="custom-profile-setting-select">
            <Select
              onChange={onSelectHandler}
              value={Input.preferredlanguage}
              name="preferredlanguage"
            >
              {languages.length > 0 &&
                languages.map((lang, i) => {
                  return (
                    <Option
                      value={
                        lang.id === 93 ? "ku" : lang.id === 43 ? "en" : "ar"
                      }
                    >
                      {lang.name}
                    </Option>
                  );
                })}
            </Select>
          </div>
        </div> */}

        {/*<div className="my-3 mx-2 w-100 d-flex align-items-center">
            <div className="custom-profile-setting-label">{language.lang.theme}</div>
            <div className="custom-profile-setting-select">
              <Select name="theme" style={{minWidth:'100px'}} value={Input.theme} onChange={onSelectHandler}>
                  <Option value="light">{language.lang.light}</Option>
                  <Option value="dark">{language.lang.dark}</Option>
              </Select>
              </div>
                </div>*/}

        {!loading ? (
          <div className="d-flex flex-wrap profile-settings-button-gap">
            <div className="order1 p2">
              <button
                className="btn shadow-none custom-profile-setting-btn"
                onClick={() => submitGeneralProfile()}
              >
                {language.lang.apply}
              </button>
            </div>
            <div className="order2 p2">
              <button onClick={navigateTo} className="btn shadow-none custom-profile-setting-btn btn-gray">
                {language.lang.cancel}
              </button>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default GeneralSettings;
