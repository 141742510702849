import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  dashboardAppointmentsRoute,
  dashboardPrescriptionRoute,
} from "../routes";

const ToastViewPrescription = ({ presID }) => {
  const history = useHistory();
  const language = useSelector((state) => state.language);
  const onClick = () => {
    if (presID) {
      history.push(dashboardPrescriptionRoute.path, {
        presId: presID,
      });
    } else {
      history.push(dashboardAppointmentsRoute.path, {
        previous: "Previous",
      });
    }
  };

  return (
    <button
      onClick={onClick}
      className="btn view-prescription-button text-white"
    >
      {language.lang.view_prescription}
    </button>
  );
};

export default ToastViewPrescription;
