import { useEffect } from "react";
import propTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { clientSignInRoute, dashboardRoute } from "../routes";
import { showWarningNotification } from "../utility/Helpers";
import { loginRequiredMsg } from "../utility/constants";
import { userRoleSuperAdmin } from "../utility/constants";

const ProtectedRoute = ({ component, path, exact, redirectState, render }) => {
  const isLoggedIn = useSelector((state) => state.auth.login);
  const role = useSelector((state) => {
    if (state.auth.userData) {
      return state.auth.userData.role;
    }
    return null;
  });
  const routeComponent = <Route component={component} path={path} exact={exact} render={render} />;

  useEffect(() => {
    if (!isLoggedIn) {
      showWarningNotification("Login", loginRequiredMsg);
    }
  }, [isLoggedIn]);

  if (
    isLoggedIn &&
    (path === "/content-manager" ||
      path === "/voucher" ||
      path === "/practitioner" ||
      path === "/corporates" ||
      path === "/permissions" ||
      path === "/dispute-center")
  ) {
    if (role === userRoleSuperAdmin) {
      return routeComponent;
    } else {
      return <Redirect to={dashboardRoute.path} />;
    }
  }

  if (isLoggedIn) {
    return routeComponent;
  }

  return (
    <Redirect
      to={{
        pathname: clientSignInRoute.path,
        state: redirectState,
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  path: propTypes.string.isRequired,
};

export default ProtectedRoute;
