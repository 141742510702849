import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  scheduledAmountKey,
  scheduledAppointment,
} from "../../utility/constants";
import axios from "../../services/Axios";
import Loading from "../../components/Loading";
import { dashboardAppointmentsRoute } from "../../routes";
import {
  setInitiatedAndCompletedTransaciton,
  setInitiatedTransactionIDAction,
} from "../../redux/client/actionsCreators";
import { showErrorNotification } from "../../utility/Helpers";
import { useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { settings } from "../../config";

const CardPaymentTab = ({ onPaymentSuccess, formData, unpaid }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { paymentAmounts, userData } = useSelector((state) => state.auth);
  const { initiatedTransactionID, completedTransaciton } = useSelector(
    (state) => state.client
  );
  const completedTransactionID =
    completedTransaciton && completedTransaciton.transaction_id;
  const amountItem = paymentAmounts.filter(
    (item) => item.key === scheduledAmountKey
  )[0];
  const [TransactionStatus, setTransactionStatus] = useState({});

  useEffect(() => {
    if (!!completedTransaciton && Object.keys(TransactionStatus).length === 0)
      setTransactionStatus(completedTransaciton);
  }, [completedTransaciton]);

  useEffect(() => {
    if (!completedTransaciton) return;
    if (initiatedTransactionID !== completedTransaciton.transaction_id) return;
    // console.log("---- completedTransaciton effect", completedTransactionID);
    if (
      completedTransaciton.is_complete &&
      completedTransaciton.paymentHistory.status_id === 6
    ) {
      dispatch(
        setInitiatedAndCompletedTransaciton({
          initiatedTransactionID: "",
          completedTransaciton: null,
        })
      );
      // setshow(true);
      //onPaymentSuccess(paymentInfo.TransactionID);
    } else {
      showErrorNotification();
      // setshow(true);
    }
  }, [completedTransactionID]);

  useEffect(() => {
    // debugger;
    // if (!history?.location?.state?.formData) return;
    // debugger;
    registerPayment();
  }, [history]);

  const registerPayment = async () => {
    setLoading(true);
    let perData = {};
    if (unpaid) {
      perData = {
        amount: formData.amount,
        moduleType: scheduledAppointment,
        moduleId: formData.moduleId,
        userId: userData.id,
        //  returnURL: "https://mindminadevserver.azurewebsites.net/api/v1/stripeElementComplete"
      };
    } else {
      perData = {
        userId: userData.id,
        amount: amountItem.value,
        //   returnURL: "https://mindminadevserver.azurewebsites.net/api/v1/stripeElementComplete",
      };
    }
    try {
      // console.log("--- paymentRegistration ", perData);
      const res = await axios.post("/stripeElementPaymentRegistration", {
        ...perData,
      });
      const data = res.data.response;
      setClientSecret(data.ClientSecret);
      dispatch(setInitiatedTransactionIDAction(data.TransactionID));
    } catch (e) {
      console.log("--- registerPayment error");
      console.log(e);
    }
    setLoading(false);
  };

  const language = useSelector((state) => state.language);
  const modalOkPressed = () => {
    history.push(dashboardAppointmentsRoute.path, {
      pending: "Upcoming",
    });
  };

  const [clientSecret, setClientSecret] = useState("");

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  const [payStatus, setpaystatus] = useState(
    JSON.parse(localStorage.getItem("PublishKey") || "{}")
  );

  const paymentStatus = new URLSearchParams(window.location.search).get(
    "redirect_status"
  );

  const clientsecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );

  useEffect(() => {
    setpaystatus(paymentStatus);
  }, [paymentStatus]);

  const [PublishKey, setPublishKey] = useState("");
  const [stripePromise, setStripePromise] = useState(null);

  const getPublishKey = async () => {
    setLoading(true);
    const res = await axios.get(`${settings.apiBaseURL}/stripeConfig`);
    const key = res.data.response.apiPublishKey;
    setPublishKey(key);
    localStorage.setItem("PublishKey", JSON.stringify(key));
    // loadStripe(PublishKey);
    setStripePromise(loadStripe(key));
    setLoading(false);
  };

  // useEffect(() => {
  //   setClientSecret(clientsecret);
  // }, [clientsecret]);

  useEffect(() => {
    getPublishKey();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="card-payment-tab">
      {
        clientSecret ? (
          <div className="row h-100">
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                fee={formData?.amount}
                setClientSecret={setClientSecret}
                paramsclientSecret={clientsecret}
                payStatus={payStatus}
              />
            </Elements>
          </div>
        ) : (
          <Loading />
        )
        // : (
        //   <>
        //     <div className="d-flex flex-column justify-content-center">
        //       <h5 className="my-1 mt-0">Click the button to pay {formData && formData.amount && formData.amount} AED</h5>
        //       {loading ? (
        //         <Loading />
        //       ) : (
        //         <div className="d-flex justify-content-center">
        //           <button onClick={registerPayment} className="btn btn-primary">
        //             {language.lang["pay-using-card"]}
        //           </button>
        //         </div>
        //       )
        //       }
        //     </div>
        //   </>
        // )
      }
    </div>
  );
};

export default CardPaymentTab;
