import { loadStripe } from "@stripe/stripe-js";
import { getStripeConfig, registerPayment } from "../../services/PaymentNetworkService";

const actions = {
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",
  SET_STRIPE_KEY: "SET_STRIPE_KEY",
  SET_STRIPE_FORM_CONFIG: "SET_STRIPE_FORM_CONFIG",
};

export const initStripePaymentForm = async ({ dispatch, amount, moduleID, moduleType, currency = "aed" }) => {
  const promisesResults = await Promise.all([getStripeConfig(), registerPayment({ amount, moduleID, moduleType, currency })]);
  const paymentInfo = promisesResults[0];
  const paymentRegisterationData = promisesResults[1];
  localStorage.setItem("transactionID", paymentRegisterationData.TransactionID);
  // debugger;
  // console.log(
  //   "--- initStripePaymentForm",
  //   paymentInfo,
  //   paymentRegisterationData
  // );
  dispatch({
    type: actions.SET_STRIPE_FORM_CONFIG,
    payload: {
      registerationData: paymentRegisterationData,
      paymentInfo,
      loadStripePromise: loadStripe(paymentInfo.apiPublishKey),
    },
  });
};

export default actions;
