const CirclesBase = ({ children }) => {
  return (
    <div className="landing-base-layout">
      <div className="top-right-circle-ring" />
      <div className="top-right-circle-base" />
      <div className="bottom-left-circle-ring" />
      <div className="bottom-left-circle-base" />
      {children}
      {/* <Particles
            params={particles}
            className="particle"
          ></Particles> */}
    </div>
  );
};

export default CirclesBase;
