import { useState } from "react";
import BackButton from "./BackButton";
import NotificationsButton from "./NotificationsButton";
import ProfileCircleButton from "./ProfileCircleButton";
import ProfileSettings from "./ProfileSettings";
import GeneralSettings from "./GeneralSettings";
import { useSelector } from "react-redux";
import { userRoleClient } from "../utility/constants";
import LogoSideButton from "../components/LogoSideButton";
import TopMenu from "./CommonControls/TopMenu";
const Settings = () => {
  const language = useSelector((state) => state.language);
  const role = useSelector((state) => state.auth?.userData?.role);
  const [tab, settab] = useState(role !== userRoleClient);
  return (
    <section className="row px-2">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.lang.settings}
      />
      <div className="d-flex py-2 justify-content-start w-100 pl-4 settings-custom-gap">
        {role === userRoleClient && (
          <div className="cursor-pointer" onClick={() => settab(false)}>
            <h4 className="tab-title-custom">
              {language.lang.profile_settings}
            </h4>
            {!tab && <div className="title-border"></div>}
          </div>
        )}
        <div className="cursor-pointer" onClick={() => settab(true)}>
          <h4 className="tab-title-custom">{language.lang.general_settings}</h4>
          {(tab || role !== userRoleClient) && (
            <div className="title-border"></div>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-start">
        {/* <TopMenu isExpanded={isExpanded} showLabel={false} isMobile={isMobile} shouldHideBackButton={false}/> */}
        <div className="order1 p1">
          {" "}
          {!tab && role === userRoleClient && <ProfileSettings />}
        </div>
        <div className="order2 p1">
          {(tab || role !== userRoleClient) && <GeneralSettings />}
        </div>
      </div>
    </section>
  );
};

export default Settings;
