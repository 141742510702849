import { useSelector } from "react-redux";
import { Row, Col, InputNumber, Dropdown, Space, Menu, Button } from "antd";
import { FaWineBottle } from "react-icons/fa";
import { useEffect, useState } from "react";
import { AiFillClockCircle } from "react-icons/ai";
import { FiChevronDown, FiSmile } from "react-icons/fi";
import TopMenu from "../../CommonControls/TopMenu";
import useRecipeDetails from "../../../customHooks/useRecipeDetails";
import Loading from "../../Loading";
import ViewRecipeTotals from "./ViewRecipeTotals";
import { gramsUnit, servingUnit, unapprovedFoodItemStatusID } from "../../../utility/constants";
import ViewRecipeIngredients from "./ViewRecipeIngredients";
import ViewRecipeCookingInstructions from "./ViewRecipeCookingInstructions";
import RecipeNutritionsInfoDrawer from "./RecipeNutritionsInfoDrawer";
import { dashboardMealPlanRoute } from "../../../routes";

const ViewRecipeDetails = ({ history }) => {
  const [loading, recipe] = useRecipeDetails();
  const [selectedUnit, setSelectedUnit] = useState(servingUnit);
  const [selectedUnitQuantity, setSelectedUnitQuantity] = useState(1);
  const language = useSelector((state) => state.language.lang);
  const preferredlanguage = useSelector(
    (state) => state.language.general.preferredlanguage
  );
  const [showNutritionInfoDrawer, setShowNutritionInfoDrawer] = useState(false);

  useEffect(() => {
    if (!recipe) return;
    setSelectedUnitQuantity(recipe.rcp_serving_size);
  }, [recipe]);

  const handleUnitSelected = ({ key }) => {
    // console.log("--- handleUnitSelected");
    // console.log({ key });
    if (key === gramsUnit) {
      // debugger;
      let recipeTotalWeightInGramsForSelectedServing = 0;
      for (const ing of recipe?.recipeIngredients ?? []) {
        recipeTotalWeightInGramsForSelectedServing += ing.plain_unit_quantity;
      }
      setSelectedUnitQuantity(recipeTotalWeightInGramsForSelectedServing);
      // console.log({ recipeTotalWeightInGramsForSelectedServing });
    } else {
      setSelectedUnitQuantity(recipe?.rcp_serving_size ?? 1);
    }
    setSelectedUnit(key);
  };

  const onEditClick = () => {
    history.push(`${dashboardMealPlanRoute.path}/recipe/${recipe.rcp_url_name}/edit`, { recipe });
  };

  let content;
  if (loading) {
    content = (
      <div className="d-flex justify-content-center mt-5">
        <Loading />
      </div>
    );
  } else if (!recipe) {
    content = (
      <div className="d-flex justify-content-center mt-5">
        <h4>{language["error"]}</h4>
      </div>
    );
  } else {
    // console.log({ recipe });
    const menu = (
      <Menu
        onClick={handleUnitSelected}
        items={[
          {
            label: language["serving"] ?? "serving",
            key: servingUnit,
          },
          {
            label: language["grams"] ?? "grams",
            key: gramsUnit,
          },
        ]}
      />
    );
    const editCloseRow = (
      <div className="d-flex justify-content-end">
        {recipe?.status?.id === unapprovedFoodItemStatusID && (
          <Button onClick={onEditClick} type="link">
            {language["edit"] ?? "edit"}
          </Button>
        )}
        <Button onClick={() => history.goBack()} type="link">
          {language["close"] ?? "close"}
        </Button>
      </div>
    );
    content = (
      <div className="view-recipe-card">
        {editCloseRow}
        <Row>
          <Col className="p-3" span={24} md={12}>
            <div className="d-flex justify-content-center mb-3">
              <img className="rcp-image" src={recipe.rcp_image_url} alt={recipe.rcp_title} />
            </div>
            <h2 className="mb-3">
            {((preferredlanguage=='ar') ? (!!recipe.rcp_title_ar ? recipe.rcp_title_ar  : recipe.rcp_title)  : 
                           (preferredlanguage=='ku') ? (!!recipe.rcp_title_ku ? recipe.rcp_title_ku  : recipe.rcp_title) 
                           :recipe.rcp_title)
            }
            </h2>
            <div className="cooking-prep-time mb-3">
              <div className="prep-time">
                <div className="icon">
                  <FaWineBottle />
                </div>
                {recipe.rcp_preparation_time} {language["minutes-to-prepare"] ?? "minutes-to-prepare"}
              </div>
              <div className="cook-time">
                <div className="icon">
                  <AiFillClockCircle />
                </div>
                {recipe.rcp_cooking_time} {language["minutes-to-cook"] ?? "minutes-to-cook"}
              </div>
            </div>
            {recipe.is_keeps_well && (
              <div className="cooking-prep-time mb-3">
                <div className="prep-time">
                  <div className="icon">
                    <FiSmile />
                  </div>
                  {language["keeps-well"] ?? "keeps-well"}
                </div>
              </div>
            )}
            <div className="unit-quantity mb-3">
              <div>{language["quantity"] ?? "quantity"}</div>
              <div className="number-input">
                <InputNumber
                  value={selectedUnitQuantity?.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })}
                  onChange={(e) => {
                    setSelectedUnitQuantity(e);
                  }}
                  min={1}
                />
              </div>
              <div className="unit-dropdown">
                <Dropdown overlay={menu} trigger={["click"]}>
                  <Space>
                    {language[selectedUnit] ?? selectedUnit}
                    <FiChevronDown />
                  </Space>
                </Dropdown>
              </div>
            </div>
            <ViewRecipeTotals recipe={recipe} selectedUnit={selectedUnit} selectedUnitQuantity={selectedUnitQuantity} />
          </Col>
          <Col className="p-3" span={24} md={12}>
            <div className="mb-3">
              <Button
                onClick={() => setShowNutritionInfoDrawer(!showNutritionInfoDrawer)}
                size="large"
                className="detailed-nutritions-btn"
              >
                <div className="icon">
                  <FiChevronDown />
                </div>
                {language["get-detailed-nutritions"] ?? "get-detailed-nutritions"}
              </Button>
            </div>
            <div className="mb-3">
              <ViewRecipeIngredients recipe={recipe} selectedUnit={selectedUnit} selectedUnitQuantity={selectedUnitQuantity} />
            </div>
            <ViewRecipeCookingInstructions recipe={recipe} />
          </Col>
        </Row>
        {editCloseRow}
      </div>
    );
  }

  return (
    <div className="view-recipe-details">
      <section className="row d-flex justify-content-between w-100 top-menu-pad">
        <TopMenu
          shouldHideBackButton={false}
          shouldHideProfileButton={false}
          shouldHideNotificationButton={false}
          shouldHideLogoButton={false}
          pageTitle={((preferredlanguage=='ar') ? (!!recipe?.rcp_title_ar ? recipe?.rcp_title_ar  : recipe?.rcp_title)  : 
          (preferredlanguage=='ku') ? (!!recipe?.rcp_title_ku ? recipe?.rcp_title_ku  : recipe?.rcp_title) 
          :recipe?.rcp_title)}
          hideCustomclass={true}
        />
      </section>
      {content}
      <RecipeNutritionsInfoDrawer
        recipe={recipe}
        visible={showNutritionInfoDrawer}
        onClose={() => setShowNutritionInfoDrawer(false)}
        selectedUnit={selectedUnit}
        selectedUnitQuantity={selectedUnitQuantity}
      />
    </div>
  );
};

export default ViewRecipeDetails;
