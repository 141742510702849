import React, { useEffect } from "react";
import moment from "moment";
const WorkDayItem = ({ selected, date, onClickWorkDayItem, count }) => {
  // console.log("date",date);
  // console.log("selected", selected);
  let day = "";
  let month = "";
  let dateNum = "";
  const week = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  if (date) {
    const D = new Date(date);
    day = D.getDay();
    month = moment(date).format("MMM");
    day = week[day].slice(0, 3).toUpperCase();
    dateNum = D.getDate();
  }
  return (
    <div
      className={`work-day-item h-list-item ${selected ? "selected" : ""}`}
      onClick={() => onClickWorkDayItem(count)}
    >
      <div className="cap text-center text-white">{month}</div>
      <div className="new-content d-flex flex-column">
        <span className="day-name">{day}</span>
        <span className="day-date">{dateNum}</span>
      </div>
    </div>
  );
};

export default WorkDayItem;
