import { useEffect, useReducer, useRef, useState } from "react";
import AgoraRTC from "agora-rtc-sdk";
import {
  agoraAppId,
  CameraAndAudioPermissionNotGranted,
  PermissionsGranted,
  prescriptionAppointmentKey,
} from "../../utility/constants";
import { dashboardCreatePrescriptionRoute, dashboardRoute } from "../../routes";
import { useSelector, useDispatch } from "react-redux";
import {
  userRoleDoctor,
  currentAppointmentDetailsKey,
} from "../../utility/constants";
import reducer, { initState } from "./reducer";
import {
  addRemoteStreamID,
  setAgoraClientAction,
  removeRemoteStreamID,
  enableAudioAction,
  enableVideoAction,
} from "./actions";
import { useHistory } from "react-router-dom";
import {
  getUserMediaPermissions,
  showErrorNotification,
  showSuccessNotification,
} from "../../utility/Helpers";
import { updateConsultationStatusAction } from "../../pages/DoctorJoinForConsultation/actions";
import { setIsNotWatingForDoctorApprovalAction } from "../../redux/client/actionsCreators";
import axios from "../../services/Axios";
import {
  setCurrentAppointmentAction,
  setPractitionerAvailability,
} from "../../redux/practitioner/actionCreators";
import Timer from "../Timer";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import moment from "moment";
import clientActions from "../../redux/clinic/actions";
import { useUserMedia } from "../VideoAudioPermission";
import { useWindowDimension } from "../WindowsSize";
import { Modal, Button } from "react-bootstrap";

const VideoCallCard = ({ loading, onOk }) => {
  const [width, _] = useWindowDimension();
  const isMobile = width < 1024;
  const reduxDispatch = useDispatch();
  const reduxStore = useSelector((state) => state);
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initState);
  // const time = new Date();
  const [time, setTime] = useState(new Date());
  // 15 minutes timer
  const language = useSelector((state) => state.language);
  const { role, id } = reduxStore.auth.userData;
  const appointmentDetails = useRef(
    JSON.parse(localStorage.getItem(currentAppointmentDetailsKey))
  );
  const handle = useFullScreenHandle();
  const [modalShow, setModalShow] = useState(false);
  //const callType = useSelector((state) => state.client.callType);
  // useEffect(() => {
  //   console.log("callType -->",callType)
  //   if(!callType) {
  //     toggleVideo();
  //   }
  // },[callType])
  const CAPTURE_OPTIONS = {
    audio: true,
    video: { facingMode: "user" },
  };
  const [granted, setgranted] = useState(false);
  const mediaStream = useUserMedia(CAPTURE_OPTIONS);

  useEffect(() => {
    if (mediaStream?.active && !granted) {
      showSuccessNotification(PermissionsGranted);
      setgranted(true);
    } else {
      //!mediaStream?.active && showSuccessNotification(PermissionsDisabled);
    }
  }, [mediaStream]);

  useEffect(() => {
    const rtc = {
      client: null,
      localStream: null,
    };
    if (!appointmentDetails.current) return;
    const appointmentID =
      appointmentDetails.current.id || appointmentDetails.current.appointmentId;
    // console.log("--- appointment id", appointmentID);
    if (!appointmentID) {
      console.log("---- no appointment id");
      return showErrorNotification();
    }

    var startDate = new Date(appointmentDetails.current.start_date_time);
    var endDate = new Date(appointmentDetails.current.end_date_time);
    let newDate = new Date(appointmentDetails.current.end_date_time);
    //console.log(moment(endDate).diff(moment(),"minutes"),"startDate");
    setTime(time.setSeconds(moment(endDate).diff(moment(), "seconds")));
    if (moment().diff(moment(startDate), "minutes") > 15) endCall();

    //console.log({ time });
    // console.log({seconds})
    getAgoraToken(appointmentID)
      .then((token) => {
        if (!token) {
          showErrorNotification(
            "Something went wrong. Please refresh the page."
          );
          return;
        }
        // debugger;
        rtc.client = AgoraRTC.createClient({
          mode: "rtc",
          codec: "vp8",
        });
        rtc.client.init(
          agoraAppId,
          () => {},
          function (err) {
            debugger;
            console.log("--- client init failed ", err);
          }
        );
        rtc.client.join(
          token,
          appointmentID,
          id,
          (uid) => {
            console.log("--- join video success", uid);
            // debugger;
            const localStream = AgoraRTC.createStream({
              audio: state.enableAudio,
              video: state.enableVideo,
            });
            rtc.localStream = localStream;
            localStream.init(() => {
              localStream.play("local-video");
              rtc.client.publish(localStream, handleError);
            }, handleError);
          },
          (error) => {
            debugger;
            console.log("--- join error", error);
          }
        );
        // Subscribe to the remote stream when it is published
        rtc.client.on("stream-added", (evt) => {
          console.log("--- agora stream-added");
          rtc.client.subscribe(evt.stream, handleError);
        });
        // Play the remote stream when it is subscribed
        rtc.client.on("stream-subscribed", (evt) => {
          const stream = evt.stream;
          const streamId = String(stream.getId());
          console.log("--- agora stream-subscribed", streamId);
          dispatch(addRemoteStreamID(streamId));
          stream.play(streamId);
        });
        rtc.client.on("stream-removed", (evt) => {
          console.log("agora stream-removed", evt);
          const stream = evt.stream;
          const streamId = String(stream.getId());
          stream.close();
          dispatch(removeRemoteStreamID(streamId));
        });
        // Remove the corresponding view when a remote user leaves the channel.
        rtc.client.on("peer-leave", (evt) => {
          console.log("agora peer-leave", evt);
          const stream = evt.stream;
          const streamId = String(stream.getId());
          stream.close();
          dispatch(removeRemoteStreamID(streamId));
          /*if (state.remoteStreamIDs.length === 0) {
            let notificationText = "";
            if (role === userRoleDoctor) {
              notificationText = "The client has left the call.";
            } else {
              notificationText = "The doctor has left the call.";
            }
            showSuccessNotification(notificationText);
          }*/
        });

        dispatch(setAgoraClientAction(rtc));
      })
      .catch(() => {
        showErrorNotification("Something went wrong. Please refresh the page.");
      });

    return () => {
      // const leavePage = confirm("Are you sure you want to leave this page ?");
      // if (!leavePage) return;
      let appointmentID;
      let appointmentEndDateTime;
      if (role === userRoleDoctor) {
        const currentAppointment = reduxStore.practitioner.currentAppointment;
        appointmentEndDateTime = Date.parse(
          appointmentDetails.current.end_date_time
        );
        appointmentID =
          currentAppointment.id || currentAppointment.appointmentId;
        reduxDispatch(setCurrentAppointmentAction(null));
      } else {
        appointmentID = reduxStore.client.doctorStartedAppointmentID;
        // const appointmentDetails = reduxStore.client.appointmentDetails;
        appointmentEndDateTime = Date.parse(
          appointmentDetails.current.end_date_time
        );
        console.log("--- detauls ", appointmentDetails.current);
        reduxDispatch(setIsNotWatingForDoctorApprovalAction());
      }
      const now = Date.parse(new Date());
      const callEndedBeforeTime =
        now < (appointmentEndDateTime || Date.parse(new Date()));
      // debugger;
      if (appointmentID) {
        updateConsultationStatusAction(appointmentID, "8", callEndedBeforeTime);
      }
      // showSuccessNotification("Call ended");
      if (rtc.client) {
        rtc.client.stopLiveStreaming();
        rtc.client.leave(() => {
          console.log("--- leave success");
        });
      }
      if (rtc.localStream) rtc.localStream.close();
    };
  }, []);

  const getAgoraToken = async (appointmentId) => {
    try {
      const res = await axios.post("/rtcToken", {
        userId: id,
        channelName: appointmentId,
      });
      const gotPermission = await getUserMediaPermissions({
        video: true,
        audio: true,
      });
      if (!gotPermission) return null;
      return res.data.response;
    } catch (e) {
      console.log("--- getAgoraToken error", e);
      return null;
    }
  };

  const handleError = (error) => {
    console.log("--- handleError", error);
    let err = CameraAndAudioPermissionNotGranted;
    //  if(!VideoAudioPermission("camera")){
    //     err=CameraPermissionNotGranted;
    //  }
    showErrorNotification(err);
  };

  const toggleVideo = () => {
    if (!state.agoraClient.localStream) return;
    if (state.enableVideo /*&& !callType*/) {
      dispatch(enableVideoAction(false));
      state.agoraClient.localStream.disableVideo();
    } else {
      dispatch(enableVideoAction(true));
      state.agoraClient.localStream.enableVideo();
    }
  };

  const toggleAudio = () => {
    if (!state.agoraClient.localStream) return;
    if (state.enableAudio) {
      dispatch(enableAudioAction(false));
      state.agoraClient.localStream.disableAudio();
    } else {
      dispatch(enableAudioAction(true));
      state.agoraClient.localStream.enableAudio();
    }
  };
  const isPractitioner = role === userRoleDoctor;
  const endCall = () => {
    setModalShow(true);
  };
  const handleModalCloseOk = () => {
    // localStorage.removeItem(currentAppointmentDetailsKey);
    onOk();
    dispatch(setPractitionerAvailability(1));
    if (isPractitioner) {
      localStorage.setItem(
        prescriptionAppointmentKey,
        JSON.stringify(reduxStore.practitioner.currentAppointment)
      );
      history.replace(dashboardCreatePrescriptionRoute.path);
      dispatch(
        updateConsultationStatusAction(appointmentDetails.current.id, "8", true)
      );
    } else {
      history.replace(dashboardRoute.path);
    }
    dispatch({
      type: clientActions.SET_CALL_TYPE,
      payload: true,
    });
  };
  const handleModalCloseCancel = () => {
    setModalShow(false);
  };
  return (
    <div className="video-call-card ml-0 ml-lg-3">
      <div className="video">
        <FullScreen handle={handle} className="h-100">
          <div id="local-video"></div>
          <div className="remote-video" id="remote-videos">
            {state.remoteStreamIDs.map((remoteID) => (
              <div key={remoteID} id={remoteID}></div>
            ))}
          </div>
        </FullScreen>
      </div>

      <div className="bottom-bar row mx-0">
        <div className="timer-container row mx-0 flex-column justify-content-between col-12 px-0">
          <div className="d-flex flex-column col-7 px-0">
            {!isPractitioner && appointmentDetails.current && (
              <h4 className="name">
                {appointmentDetails.current.practitionerInfo.name}{" "}
                {appointmentDetails.current.practitionerInfo.surname || ""}
              </h4>
            )}
            {isPractitioner && appointmentDetails.current && (
              <h4 className="name">
                {appointmentDetails.current.patientInfo.name}{" "}
                {appointmentDetails.current.patientInfo.surname || ""}
              </h4>
            )}
            <p className="title">
              {isPractitioner ? language.lang.patient : language.lang.doctor}
            </p>
          </div>
          {isMobile && (
            <Timer
              className="d-flex justify-content-end col-5 px-0"
              expiryTimestamp={time}
              endCall={endCall}
            />
          )}
        </div>
        <div className="call-controles col-12 ml-auto px-0">
          {!isMobile && (
            <Timer
              className="mr-auto"
              expiryTimestamp={time}
              endCall={endCall}
            />
          )}
          <button
            onClick={toggleVideo}
            className="btn btn-primary" /*disabled={!callType}*/
          >
            {state.enableVideo ? (
              <i className="fas fa-video"></i>
            ) : (
              <i className="fas fa-video-slash"></i>
            )}
          </button>
          <button onClick={toggleAudio} className="btn btn-primary">
            {state.enableAudio ? (
              <i className="fas fa-microphone"></i>
            ) : (
              <i className="fas fa-microphone-slash"></i>
            )}
          </button>
          <button onClick={handle.enter} className="btn btn-primary">
            <i className="far fa-expand"></i>
          </button>
          <button
            onClick={endCall}
            className="btn btn-danger"
            disabled={!loading}
          >
            <i className="fas fa-phone-slash"></i>
          </button>
        </div>
      </div>
      <Modal show={modalShow} className="end-call" size="sm" backdrop="static">
        <Modal.Body>
          {language.lang["r-u-sure-u-want-end-call"] || ""}
        </Modal.Body>
        <Modal.Footer>
          <Button className="ok-btn" onClick={handleModalCloseOk}>
            {language.lang.ok}
          </Button>
          <Button className="ok-btn" onClick={handleModalCloseCancel}>
            {language.lang.cancel}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VideoCallCard;
