import actions from "./actions";
import authActions from "../authenitcation/actions";
import { calendarColorTokensMap } from "../../utility/constants";

const initState = {
  appointments: [],
  patientDetail: {},
  appointmentDetail: {},
  pending_appointments: [],
  scheduled_appointments: [],
  view_schedule: false,
  currencies: [],
  top_practitioner: [],
  top_practitioner_dropList: [],
  calendarView: "day",
  events: [],
  viewSlot: false,
  selectedSlot: {},
  selectedDocInfo: {},
  needToCall: false,
  calendar_settings: {
    startDate: "",
    endDate: "",
    pracID: "",
  },
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case authActions.LOGOUT_SUCCESS:
      return initState;
    case actions.SET_CLINIC_APPOINTMENT:
      if (!payload) return state;
      return Object.assign({}, state, {
        ...state,
        appointments: payload,
      });
    case actions.SET_PATIENT_DETAIL:
      return Object.assign({}, state, {
        ...state,
        patientDetail: payload,
      });
    case actions.SET_APPOINTMENT_DETAIL:
      return Object.assign({}, state, {
        ...state,
        appointmentDetail: payload,
      });
    case actions.UPDATE_PENDING_APPOINTMENTS:
      return Object.assign({}, state, {
        pending_appointments: state.pending_appointments.filter(
          (a) => a.id !== payload
        ),
      });
    case actions.UPDATE_SCHEDULED_APPOINTMENTS:
      return Object.assign({}, state, {
        ...state,
        scheduled_appointments: state.scheduled_appointments.concat(
          state.pending_appointments.filter((a) => a.id === payload)
        ),
      });
    case actions.SET_PENDING_APPOINTMENTS:
      return Object.assign({}, state, {
        ...state,
        pending_appointments: payload.pendingAppointments,
        scheduled_appointments: payload.scheduledAppointments,
      });
    case actions.SET_VIEW_SCHEDULED_APPOINTMENTS:
      return Object.assign({}, state, {
        ...state,
        view_schedule: payload,
      });
    case actions.SET_CURRENCIES:
      return Object.assign({}, state, {
        ...state,
        currencies: payload,
      });
    case actions.GET_TOP_PRACTITIONERS:
      if (!payload) return;
      let newFormat = [],
        newArr = [];
      payload.map((item) => {
        if (item?.practitioner?.length > 0) {
          newFormat.push({
            name:
              item?.practitioner[0].name +
              " " +
              (!!item.practitioner[0].surname
                ? item.practitioner[0].surname
                : ""),
            value: item.practitioner[0].id,
          });
        }
      });
      payload.map((item) => {
        item?.practitioner?.map((items) => {
          newArr.push(items);
        });
      });
      return Object.assign({}, state, {
        ...state,
        top_practitioner: [...state.top_practitioner, ...newArr],
        top_practitioner_dropList: newFormat,
      });
    case actions.SET_CALENDAR_VIEW_TYPE:
      return Object.assign({}, state, {
        ...state,
        calendarView: payload,
      });
    case actions.SET_PRACTITIONER_EVENTS:
      return Object.assign({}, state, {
        ...state,
        events: payload,
      });
    case actions.SET_VIEW_SLOT:
      return Object.assign({}, state, {
        ...state,
        viewSlot: payload,
      });
    case actions.SET_SELECTED_SLOT:
      return Object.assign({}, state, {
        ...state,
        selectedSlot: payload,
      });
    case actions.SET_SELECTED_DOCTOR_INFO:
      return Object.assign({}, state, {
        ...state,
        selectedDocInfo: payload,
      });
    case actions.SET_ALL_APPOINTMENTS_OF_PRACTITIONER:
      let docEvent = [];
      // color: calendarColorTokensMap[colorToken] || color,
      if (payload?.clinicPendingAppointments?.length > 0) {
        payload?.clinicPendingAppointments.forEach((item) => {
          docEvent.push({
            PractitionerName:
              item?.practitionerInfo?.name +
              " " +
              (!!item?.practitionerInfo?.surname
                ? item?.practitionerInfo?.surname
                : ""),
            PatientName:
              item?.patientInfo?.name +
              " " +
              (!!item?.patientInfo?.surname ? item?.patientInfo?.surname : ""),
            start: new Date(item.start_date_time),
            end: new Date(item.end_date_time),
            color:
              calendarColorTokensMap[
                payload?.styles?.clinicPendingAppointments?.token
              ],
            appointmentId: item.id,
            status: "Pending Appointment",
          });
        });
      }
      // if(payload?.clinicInProgressAppointments?.length > 0){
      //   payload?.clinicInProgressAppointments.map((item) => {
      //     docEvent.push({
      //     PractitionerName: item?.practitionerInfo?.name + " " + (!!item?.practitionerInfo?.surname? item?.practitionerInfo?.surname : ""),
      //     PatientName: item?.patientInfo?.name + " " + (!!item?.patientInfo?.surname? item?.patientInfo?.surname : ""),
      //     start: new Date(item.start_date_time),
      //     end:  new Date(item.end_date_time),
      //     color: payload?.styles?.clinicInProgressAppointments?.background,
      //     appointmentId: item.id,
      //     status: "Progress Appointment",
      //   });
      // });
      // }
      if (payload?.clinicInProgressAppointments?.length > 0) {
        payload?.clinicInProgressAppointments.forEach((item) => {
          docEvent.push({
            PractitionerName:
              item?.practitionerInfo?.name +
              " " +
              (!!item?.practitionerInfo?.surname
                ? item?.practitionerInfo?.surname
                : ""),
            PatientName:
              item?.patientInfo?.name +
              " " +
              (!!item?.patientInfo?.surname ? item?.patientInfo?.surname : ""),
            start: new Date(item.start_date_time),
            end: new Date(item.end_date_time),
            color:
              calendarColorTokensMap[
                payload?.styles?.clinicInProgressAppointments?.token
              ],
            appointmentId: item.id,
            status: item.status && item.status.name,
          });
        });
      }
      if (payload?.clinicOngoingAppointments?.length > 0) {
        payload?.clinicOngoingAppointments.forEach((item) => {
          docEvent.push({
            PractitionerName:
              item?.practitionerInfo?.name +
              " " +
              (!!item?.practitionerInfo?.surname
                ? item?.practitionerInfo?.surname
                : ""),
            PatientName:
              item?.patientInfo?.name +
              " " +
              (!!item?.patientInfo?.surname ? item?.patientInfo?.surname : ""),
            start: new Date(item.start_date_time),
            end: new Date(item.end_date_time),
            color:
              calendarColorTokensMap[
                payload?.styles?.clinicOngoingAppointments?.token
              ],
            appointmentId: item.id,
            status: "Ongoing Appointment",
          });
        });
      }
      if (payload?.clinicUpcomingAppointments?.length > 0) {
        payload?.clinicUpcomingAppointments.forEach((item) => {
          docEvent.push({
            PractitionerName:
              item?.practitionerInfo?.name +
              " " +
              (!!item?.practitionerInfo?.surname
                ? item?.practitionerInfo?.surname
                : ""),
            PatientName:
              item?.patientInfo?.name +
              " " +
              (!!item?.patientInfo?.surname ? item?.patientInfo?.surname : ""),
            start: new Date(item.start_date_time),
            end: new Date(item.end_date_time),
            color:
              calendarColorTokensMap[
                payload?.styles?.clinicUpcomingAppointments?.token
              ],
            appointmentId: item.id,
            status: "Upcoming Appointment",
          });
        });
      }
      if (payload?.clinicCancelledAppointments?.length > 0) {
        payload?.clinicCancelledAppointments.forEach((item) => {
          docEvent.push({
            PractitionerName:
              item?.practitionerInfo?.name +
              " " +
              (!!item?.practitionerInfo?.surname
                ? item?.practitionerInfo?.surname
                : ""),
            PatientName:
              item?.patientInfo?.name +
              " " +
              (!!item?.patientInfo?.surname ? item?.patientInfo?.surname : ""),
            start: new Date(item.start_date_time),
            end: new Date(item.end_date_time),
            color:
              calendarColorTokensMap[
                payload?.styles?.clinicCancelledAppointments?.token
              ],
            appointmentId: item.id,
            status: "Cancelled Appointment",
          });
        });
      }
      if (payload?.clinicCompletedAppointments?.length > 0) {
        payload?.clinicCompletedAppointments.forEach((item) => {
          docEvent.push({
            PractitionerName:
              item?.practitionerInfo?.name +
              " " +
              (!!item?.practitionerInfo?.surname
                ? item?.practitionerInfo?.surname
                : ""),
            PatientName:
              item?.patientInfo?.name +
              " " +
              (!!item?.patientInfo?.surname ? item?.patientInfo?.surname : ""),
            start: new Date(item.start_date_time),
            end: new Date(item.end_date_time),
            color:
              calendarColorTokensMap[
                payload?.styles?.clinicCompletedAppointments?.token
              ],
            appointmentId: item.id,
            status: "Completed Appointment",
          });
        });
      }
      if (payload?.clinicFailedAppointments?.length > 0) {
        payload?.clinicFailedAppointments.forEach((item) => {
          docEvent.push({
            PractitionerName:
              item?.practitionerInfo?.name +
              " " +
              (!!item?.practitionerInfo?.surname
                ? item?.practitionerInfo?.surname
                : ""),
            PatientName:
              item?.patientInfo?.name +
              " " +
              (!!item?.patientInfo?.surname ? item?.patientInfo?.surname : ""),
            start: new Date(item.start_date_time),
            end: new Date(item.end_date_time),
            color:
              calendarColorTokensMap[
                payload?.styles?.clinicFailedAppointments?.token
              ],
            appointmentId: item.id,
            status: "Failed Appointment",
          });
        });
      }
      return Object.assign({}, state, {
        ...state,
        events: docEvent, // add practitioner's event here
      });
    case actions.SET_CLINIC_CALENDAR_SETTINGS:
      return Object.assign({}, state, {
        ...state,
        calendar_settings: {
          ...state.calendar_settings,
          startDate: payload.startDate,
          endDate: payload.endDate,
          pracID: payload.pracID,
        },
      });
    case actions.SET_NEED_TO_CALL_CALENDAR:
      return Object.assign({}, state, {
        ...state,
        needToCall: payload,
      });
    default:
      return state;
  }
};

export default reducer;
