import actions from "./actions";

export const setLoadingHostMuteAllAction = ({ loading }) => {
  return {
    type: actions.SET_LOADING_HOST_MUTE_ALL,
    payload: loading,
  };
};

export const updateAttendeesMapAction = ({ attendeesMap }) => {
  return {
    type: actions.UPDATE_ATTENDEES_MAP,
    payload: attendeesMap,
  };
};

export const hostMuteAllAction = () => {
  return {
    type: actions.HOST_MUTE_ALL,
  };
};

export const hostUnmuteAllAction = () => {
  return {
    type: actions.HOST_UNMUTE_ALL,
  };
};

export const updateSessionAttendeesListAction = ({ attendeesList }) => {
  const attendeesMap = {};
  attendeesList.forEach((element) => {
    attendeesMap[element.id] = element;
  });
  return {
    type: actions.UPDATE_ATTENDEES_LIST,
    payload: { attendeesList, attendeesMap },
  };
};
