import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import GoogleMapView from "./GoogleMapView";
const DiscoverySettingsModal= (props) => {
  const language = useSelector((state) => state.language);

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="map-modal"
      >
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
           {language.lang.addLocation}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GoogleMapView  onHide={props.onHide}/>
        </Modal.Body>
        
      </Modal>
    );
  }
  export default DiscoverySettingsModal;
  