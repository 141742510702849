import { useState } from "react";
import { useSelector } from "react-redux";
import { showErrorNotification } from "../utility/Helpers";

const MedicalDocumentField = ({ onChange, setagree, setappear, appear }) => {
  const [pickedFile, setPickedFile] = useState(false);
  const language = useSelector((state) => state.language);
  const handleChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const img = e.target.files[0];
    if (!img.type.startsWith("application")) {
      showErrorNotification("Please select PDF files");
      return;
    }
    setPickedFile(true);
    setappear(!appear);
    setagree("0");
    if (onChange) {
      onChange(img);
    }
  };

  const remove = () => {
    onChange(null);
    setPickedFile(false);
    setappear(!appear);
    setagree("1");
  };
  return (
    <div className="medical-document-field mt-4">
      <label htmlFor="medical-doc" className="medical-doc-label">
        {pickedFile
          ? language.lang["upload-medical-doc"]
          : language.lang["upload-medical-doc"]}
        {pickedFile ? (
          <i className="far fa-check-circle"></i>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <i className="far fa-file-pdf"></i>
            <div className="click-to-upload mt-1">
              {language.lang.click_to_upload}
            </div>
          </div>
        )}
      </label>
      {appear && (
        <div
          style={{ width: "20px", cursor: "pointer", color: "red" }}
          onClick={remove}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="trash-alt"
            className="svg-inline--fa fa-trash-alt fa-w-14"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
            ></path>
          </svg>
        </div>
      )}
      <input
        onChange={handleChange}
        id="medical-doc"
        className="medical-doc-input"
        name="medicalDoc"
        type="file"
        accept="application/pdf"
      />
    </div>
  );
};

export default MedicalDocumentField;
