import moment from "moment";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useSelector } from "react-redux";
import { liveSessionRoute } from "../routes";
import { updateSessionStatus } from "../services/LiveSessionsNetworkService";
import { sessionJoinedByUserStatusID } from "../utility/constants";
import {
  isMobileOrTabletBrowser,
  showErrorNotification,
} from "../utility/Helpers";
import Loading from "./Loading";
import MobileAppPropmtModal from "./MobileAppPropmtModal";

const CustomerCalendarSessionCard = () => {
  const history = useHistory();
  const translations = useSelector((state) => state.language.lang);
  const [showMobilePromptModal, setShowMobilePromptModal] = useState(false);
  const selectedCalendarItem = useSelector(
    (state) => state.patient.calendar.selectedSlot
  );
  const [loading, setLoading] = useState(false);

  if (!selectedCalendarItem || !selectedCalendarItem.sessionDetails) {
    return <div></div>;
  }

  const startDateTime = moment(selectedCalendarItem.start);
  const endDateTime = moment(selectedCalendarItem.end);
  const now = moment();
  const isCalendarItemTimeNow =
    now.isSameOrAfter(startDateTime) && now.isBefore(endDateTime);
  const userJoinedSession = selectedCalendarItem.is_user_started_call;
  const userEndedSession = selectedCalendarItem.is_user_ended_call;
  const hostStartedSession =
    selectedCalendarItem.sessionDetails.is_host_started_call;
  const hostEndedSession =
    selectedCalendarItem.sessionDetails.is_host_ended_call;
  const canJoinSession =
    isCalendarItemTimeNow &&
    !userJoinedSession &&
    !userEndedSession &&
    hostStartedSession &&
    !hostEndedSession;
  const showRejoinSessionButton =
    isCalendarItemTimeNow &&
    userJoinedSession &&
    hostStartedSession &&
    !hostEndedSession;
  const showJoinCallButtom =
    now.isBefore(endDateTime) && !hostEndedSession && !userJoinedSession;

  // console.log("---- isCalendarItemTimeNow", selectedCalendarItem);
  //   debugger;

  const onMobileModalPromptClose = () => {
    setShowMobilePromptModal(false);
    history.replace(liveSessionRoute.path, { session: selectedCalendarItem });
  };

  const joinSessionClicked = async () => {
    setLoading(true);
    const sessionID = await updateSessionStatus({
      sessionID: selectedCalendarItem.sessionDetails.id,
      userSessionID: selectedCalendarItem.id,
      statusID: sessionJoinedByUserStatusID,
    });
    setLoading(false);
    if (sessionID) {
      joinSessionCall();
    } else {
      showErrorNotification();
    }
  };

  const joinSessionCall = () => {
    if (isMobileOrTabletBrowser()) {
      setShowMobilePromptModal(true);
    } else {
      history.push(liveSessionRoute.path, { session: selectedCalendarItem });
    }
  };

  const buttonClasses =
    "w-100 d-flex justify-content-center align-items-center";
  let button;
  if (loading) {
    button = <Loading className="mt-3" />;
  } else if (showJoinCallButtom) {
    button = (
      <button
        disabled={!canJoinSession}
        onClick={joinSessionClicked}
        className={`${buttonClasses} ${
          canJoinSession ? "pay-btn" : "btn btn-secondary"
        }`}
      >
        {translations.joinEvent}
      </button>
    );
  } else if (showRejoinSessionButton) {
    button = (
      <button
        onClick={joinSessionClicked}
        className={`pay-btn ${buttonClasses}`}
      >
        {translations.rejoinEvent}
      </button>
    );
  }

  return (
    <div className="d-flex w-100 flex-column">
      <MobileAppPropmtModal
        show={showMobilePromptModal}
        onCloseClicked={onMobileModalPromptClose}
      />
      <div className="d-flex w-100 mt-1">
        <div className="col-2 p-0 d-flex flex-column text-end">
          <span className="start-time">{startDateTime.format("hh:mm")}</span>
          <span className="end-time">{endDateTime.format("hh:mm")}</span>
        </div>
        <div className="col-9 p-0 mx-2">
          <div className="d-flex justify-content-center align-items-center w-100">
            <div className="side-show mr-2"></div>
            <div
              className="selected-appoint-card w-100"
              style={{ backgroundColor: selectedCalendarItem.color }}
            >
              <div className="d-flex justify-content-between w-100 align-items-center">
                <span>
                  {translations[`${selectedCalendarItem.statusText}-session`]}
                </span>
                <span>
                  <BsThreeDotsVertical color={"white"} />
                </span>
              </div>
              <div className="mt-1">
                {selectedCalendarItem.PractitionerName}
              </div>

              {/* <div className="mt-2">
                <HiOutlineLocationMarker size={20} />
                <span className="ml-2">
                  UAE{" "}
                  {Object.keys(DocInfo || {}).length > 0 &&
                    (!!DocInfo.city_name ? "- " + DocInfo.city_name : "")}
                </span>
              </div> */}
              <div className="d-flex justify-content-end w-100 align-items-center mt-auto">
                {button}
                {/* <button className="pay-btn w-100 d-flex justify-content-center align-items-center">
                  {getBtnIcon(selectedCalendarItem.status).icon}{" "}
                  {getBtnIcon(selectedCalendarItem.status).text}
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showJoinCallButtom && !canJoinSession && (
        <p className="px-3 pt-3">
          {translations.joinWillBeEnabledAfterStartCall}
        </p>
      )}
    </div>
  );
};

export default CustomerCalendarSessionCard;
