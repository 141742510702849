import React from 'react';
import { Form } from "react-bootstrap";
import { RiFileExcel2Line } from "react-icons/ri";
import { useSelector } from 'react-redux';

const PractitionerModal = () => {
    const language = useSelector((state) => state.language);
    return (
        <div className="practitioner-modal">
               <p className="practitioner-modal--heading">Onboard Practitioners</p>
               <div className="excel-icon text-center">
                        <RiFileExcel2Line size={35} color="#1f744b" />
                        <br />
                        <button className="download-temp--btn">Download template</button>
                </div>
               <Form className="practitioner-form">
                    
                    <div className="voucher-fields input-field">
                        <label htmlFor="name">
                            Client {language.lang.name}
                        </label>
                        <input
                            name="name"
                            className="prac-modal-input voucher-name-input"
                            placeholder="IZI HEALTH"
                        />
                    </div>

                    <div className="voucher-fields input-field">
                        <label htmlFor="email">
                            Client {language.lang.email} Address
                        </label>
                        <input
                            name="email"
                            className="prac-modal-input voucher-name-input"
                            placeholder="info@doctorsae.com"
                        />
                    </div>

                    <div className="voucher-fields input-field cls-3">
                        <label htmlFor="clientID">
                            Client ID
                        </label>
                        <input
                            name="clientID"
                            className="prac-modal-input voucher-name-input"
                            placeholder="MM1001"
                        />
                    </div>

                    <div className="voucher-fields input-field">
                        <label htmlFor="clientID" className="label-4">
                            Choose your template
                        </label>
                        <button className="browse-btn">Browse</button>
                        <input
                            name="clientID"
                            className="prac-modal-input voucher-name-input"
                            placeholder="MM1001"
                        />
                    </div>

                    <div className="voucher-fields input-field">
                        <button className="upload--btn">Upload</button>
                    </div>

                    {/* <div className="excel-pattern">

                    </div> */}

                    

               </Form>
        </div>
    )
}

export default PractitionerModal;