import { Button, Drawer } from "antd";
import { FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";
import useNutritionDescriptions from "../../../customHooks/useNutritionDescriptions";
import { servingUnit } from "../../../utility/constants";

const RecipeNutritionsInfoDrawer = ({ visible, onClose, recipe, selectedUnit, selectedUnitQuantity }) => {
  const [loading, nutritionDescriptions] = useNutritionDescriptions({ key: "rcp_column_name" });
  const languagePrefs = useSelector((state) => state.language);
  const currentLang = languagePrefs.general.preferredlanguage;
  const language = useSelector((state) => state.language.lang);

  const nutiritons = recipe?.recipeActualTotals;
  if (nutiritons) {
    delete nutiritons.id;
    delete nutiritons.recipe_id;
  }

  // console.log({ nutritionDescriptions, loading });

  let recipeTotalWeightInGramsForSelectedServing = 0;
  for (const ing of recipe?.recipeIngredients ?? []) {
    recipeTotalWeightInGramsForSelectedServing += ing.plain_unit_quantity;
  }

  return (
    <Drawer className="recipe-nutritions-info-drawer" width={550} onClose={onClose} visible={visible} closable={false}>
      <div className="d-flex justify-content-between align-items-center px-2">
        {language["detailed-nutrition-info"] ?? "detailed-nutrition-info"}
        <Button onClick={onClose} type="text">
          <FaTimes />
        </Button>
      </div>
      <div className="p-3">
        {Object.keys(nutiritons ?? {})
          .filter((key) => !key.startsWith("per_"))
          .map((key) => {
            const percentageKey = `per_${key}`;
            const percentage = nutiritons[percentageKey]?.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 });
            const nutritionDescription = nutritionDescriptions.get(key) ?? {};
            let weight;
            if (selectedUnit === servingUnit) {
              const wieghtForOneServe = nutiritons[key];
              weight = wieghtForOneServe * selectedUnitQuantity;
            } else {
              const totalWeightForSelectedServing = recipe?.recipeCalculatedTotals[key] ?? 0;
              weight = selectedUnitQuantity * (totalWeightForSelectedServing / recipeTotalWeightInGramsForSelectedServing);
            }
            let name;
            if (currentLang === "ar") {
              name = nutritionDescription.description_ar;
            } else if (currentLang === "ku") {
              name = nutritionDescription.description_ku;
            } else {
              name = nutritionDescription.description;
            }
            return (
              <div key={key} className="nutrition-item">
                <div className="nutrition-name">{name}</div>
                <div className="nutrition-weight">
                  {weight.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })}{" "}
                  {language[nutritionDescription.unit_symbol] ?? nutritionDescription.unit_symbol}
                </div>
                <div className="nutrition-percentage">{percentage ? nutiritons[percentageKey] : language["N/A"] ?? "N/A"}</div>
              </div>
            );
          })}
      </div>
    </Drawer>
  );
};

export default RecipeNutritionsInfoDrawer;
