import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUserNotificationsAction,
  ResetNotificationsAction,
  setLoadingNotificationsAction,
} from "../../../redux/user/actionsCreators";
import NotificationsSideMenu from "../../NotificationsSideMenu";
import ShareBackButton from "../Shared/ShareBackButton";
import Config from '../../../config/index';
const TopDashboardMenuNotification = ({ className }) => {
  const dispatch = useDispatch();
  const [openSideMenu, setOpenSideMenue] = useState(false);
  const currentUser = useSelector((state) => state.auth.userData);
  const userNotifications = useSelector((state) => state.user.notifications);
  const userNotificationCount = useSelector(
    (state) => state.user.notification_count
  );
  const language = useSelector((state) => state.language);
  const selectedLanguage = language.general.preferredlanguage;
  const isAuth = currentUser && Object.values(currentUser).length > 0;

  useEffect(() => {
    dispatch(setLoadingNotificationsAction({ loading: true }));
    FetchNotifications();
  }, [selectedLanguage]);

  const FetchNotifications = () => {
    dispatch(fetchUserNotificationsAction());
  };

  const closeSideMenu = () => {
    if (!openSideMenu) return;
    setOpenSideMenue(false);
  };

  const handleBtnPressed = () => {
    setOpenSideMenue(true);
    FetchNotifications();
    dispatch(ResetNotificationsAction());
  };

  // const notificationsList =
  //   (userNotifications && userNotifications.notificationDetails) || []; // .slice(0, 100);

  // notificationsList = notificationsList.filter((item) => item.details !== "");
  // const notificationsCount =
  //   (notificationsList &&
  //     notificationsList.filter((notification) => !notification.is_viewed)
  //       .length) ||
  //   0;

  // console.log("--- count ", notificationsCount, notificationsList);

  if (!isAuth) return <span></span>;

  return (
    <>
      <NotificationsSideMenu
        isOpened={openSideMenu}
        onClosePressed={closeSideMenu}
        userNotifications={userNotifications}
      />
      <button
        onClick={handleBtnPressed}
        className={`btn topMenuNotificationBtn ${className}`}
      >
         <img src={Config.dashbellbutton} alt="BellButton" className="dash-circle-button"/>
        {userNotificationCount && userNotificationCount > 0 ? (
          <div
            className="topMenuNotificationCount"
            style={
              language.direction === " direction-rtl"
                ? {
                    right: "-10px",
                  }
                : {}
            }
          >
            {userNotificationCount}
          </div>
        ) : null}
      </button>
    </>
  );
};

export default TopDashboardMenuNotification;
