//Components import
import FitnessBuddyDetails from "./FitnessBuddyDetails";
import FitnessBuddyGallery from "./FitnessBuddyGallery";
import FitnessBuddyNotification from "./FitnessBuddyNotification";
import FitnessBuddySettings from "./FitnessBuddySettings";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

//redux import
import {
  getInterestDetails,
  getPatientDetails,
} from "../../redux/fitnessBuddy/actionCreators";

// Modules import
import { useDispatch, useSelector } from "react-redux";
import { dashboardRoute, viewMatchesRoute } from "../../routes";
import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { settings } from "../../config";
import axios from "../../services/Axios";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../utility/Helpers";
import { useHistory } from "react-router-dom";
import actions from "../../redux/fitnessBuddy/actions";
import { notificationEnum } from "./constant";
import Loading from "../../components/Loading";
import TopMenu from "../../components/CommonControls/TopMenu";
const FitnessBuddy = () => {
  const language = useSelector((state) => state.language);
  const methods = useForm();
  const history = useHistory();
  const { register, handleSubmit } = methods;
  const { userData } = useSelector((state) => state.auth);
  const userDetails = useSelector((state) => state.patientDetails.userDetails);
  const buddyDetails = useSelector(
    (state) => state.patientDetails.buddyDetails
  );
  const [showOnBoardModal, setShowOnBoardModal] = useState(false);
  const isSettingsLoading = useSelector(
    (state) => state.patientDetails.isSettingsLoading
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInterestDetails());
    dispatch(getPatientDetails());
  }, []);
  const getKeysBasedOnValue = (object, value) => {
    return Number(Object.keys(object).find((key) => object[key] === value));
  };
  const updateAllowedNotification = (data) => {
    let allowedNotifications = [];
    if (data.inAppSounds)
      allowedNotifications.push(
        getKeysBasedOnValue(notificationEnum, "inAppSounds")
      );
    if (data.inAppVibrations)
      allowedNotifications.push(
        getKeysBasedOnValue(notificationEnum, "inAppVibrations")
      );
    if (data.messages)
      allowedNotifications.push(
        getKeysBasedOnValue(notificationEnum, "messages")
      );
    if (data.messagesLikes)
      allowedNotifications.push(
        getKeysBasedOnValue(notificationEnum, "messageLikes")
      );
    if (data.newMatches)
      allowedNotifications.push(
        getKeysBasedOnValue(notificationEnum, "newMatches")
      );
    if (data.superLikes)
      allowedNotifications.push(
        getKeysBasedOnValue(notificationEnum, "superLikes")
      );
    userDetails.allowedNotifications = allowedNotifications;
    userDetails.isVaccinated = data.isVaccinated;
    dispatch({
      type: actions.UPDATE_NOTIFICATIONS_SETTINGS,
      payload: {
        notifications: allowedNotifications,
        vaccinated: data.isVaccinated,
      },
    });
  };
  const onSubmit = async (data) => {
    if (userDetails.profilePhotoUrl === "") {
      showErrorNotification("Please add  the profile picture");
      return
    }
    if (userDetails.aboutInfo === "") {
      showErrorNotification("Please add the about information");
      return
    }
    if (userDetails.interests.length === 0) {
      showErrorNotification("Please add an interest");
      return
    }
    if (
      userDetails.location === ""
    ) {
      showErrorNotification("Please add the location");
      return;
    }
    updateAllowedNotification(data);
    dispatch({
      type: actions.UPDATE_SETTINGS_LOADING,
      payload: { isSettingsLoading: true },
    });
    try {
      const res = await axios.put(
        `${settings.apiBaseURL}/updateUserProfile`,
        userDetails
      );

      if (res.data.response.user[0].id === userData.id) {
        dispatch({
          type: actions.UPDATE_SETTINGS_LOADING,
          payload: { isSettingsLoading: false },
        });
        if (buddyDetails.is_enabled_for_fitness === false) {
          dispatch({
            type: actions.UPDATE_FETCHED_USER_DETAILS,
            payload: { isFetchedUserDetails: false },
          });
          setShowOnBoardModal(true);
        } else {
          showSuccessNotification("Settings applied Successfully");
          dispatch({
            type: actions.UPDATE_VIEW_MATCHES_LOADING,
            payload: { isViewMatchesLoading: true },
          });
          history.push(viewMatchesRoute.path);
        }
      }
    } catch (e) {
      showErrorNotification("Please fill all the details");
      dispatch({
        type: actions.UPDATE_SETTINGS_LOADING,
        payload: { isSettingsLoading: false },
      });
    }
  };
  const handleOnBoardModalClose = () => {
    setShowOnBoardModal(false);
    history.push(viewMatchesRoute.path);
  };

  return (
    <div className="onboard-fitness-buddy">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.lang.fitnessConnect}
      />
      {isSettingsLoading ? (
        <div className="d-flex justify-content-center">
          <Loading />
        </div>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <section className="buddy-details">
              <section className="fb-section-1 row">
                <FitnessBuddyDetails register={register} />
                <FitnessBuddyGallery register={register} />
              </section>
              <section className="fb-section-2 row">
                <FitnessBuddySettings register={register} />
                <FitnessBuddyNotification register={register} />
              </section>
              <div className="btn-group">
                <input
                  disabled={userDetails.isAdult === false ? true : false}
                  className="btn-apply"
                  type="submit"
                  value={language.lang.apply}
                />
                <button
                  type="button"
                  className="btn-cancel"
                  onClick={() => {
                    history.push(dashboardRoute.path);
                  }}
                >
                  {language.lang.cancel}
                </button>
              </div>
            </section>
            <Modal
              show={showOnBoardModal}
              onHide={handleOnBoardModalClose}
              backdrop="static"
              keyboard={false}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="onboard-popup"
            >
              <Modal.Header closeButton>
                <Modal.Title>{language.lang.fitnessConnect}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {language.lang.fitnessBuddySuccessOnboardingMessage}
              </Modal.Body>
              <Modal.Footer>
                <Button className="ok-btn" onClick={handleOnBoardModalClose}>
                  {language.lang.ok}
                </Button>
              </Modal.Footer>
            </Modal>
          </form>
        </FormProvider>
      )}
    </div>
  );
};
export default FitnessBuddy;
