import { useState } from "react";
import { useHistory } from "react-router-dom";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Loading from "../../components/Loading";
import { showErrorNotification } from "../../utility/Helpers";
import { useSelector } from "react-redux";

const StripeCheckoutForm = ({ amount, currency, redirectURL }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [paymentElementReady, setPaymentElementReady] = useState(false);
  const language = useSelector((state) => state.language);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    // console.log("--- handleSubmit", redirectURL);
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: redirectURL,
      },
    });
    setLoading(false);
    // console.log("--- stripe.confirmPayment", result);
    if (result.error) {
      showErrorNotification(result.error.message);
      console.log("--- stripe payment error");
      console.log(result.error.message);
    }
  };

  const cancelPressed = () => {
    history.goBack();
  };

  return (
    <form onSubmit={handleSubmit} className="w-100">
      {!paymentElementReady && <Loading className="my-auto" />}
      <PaymentElement
        onReady={() => {
          setPaymentElementReady(true);
        }}
      />
      {loading ? (
        <Loading className="py-3" />
      ) : (
        <div className="d-flex">
          <div className="p-2">
            <button
              onClick={cancelPressed}
              type="button"
              disabled={!stripe || !paymentElementReady}
              className="btn btn-secondary btn-secondary-circle-border margin-top-set"
            >
              {" "}
              Cancel{" "}
            </button>
          </div>
          <div className="p-2">
            <button type="submit" disabled={!stripe || !paymentElementReady} className="btn btn-primary margin-top-set">
              {language.lang.pay + " " + amount + " " + currency}{" "}
            </button>
          </div>
        </div>
      )}
    </form>
  );
};

export default StripeCheckoutForm;
