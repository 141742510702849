import actions from "./actions";

export const initState = {
  loading: false,
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.STOP_LOADING:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.LOADING:
      return Object.assign({}, state, {
        loading: true,
      });
    default:
      return state;
  }
};

export default reducer;
