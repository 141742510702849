import React, { useRef } from "react";
import { BiUser } from "react-icons/bi";
import { VscFilePdf } from "react-icons/vsc";
import { IconContext } from "react-icons";
import { AiFillPlayCircle } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import {
  currentAppointmentDetailsKey,
  userRoleDoctor,
} from "../utility/constants";
import { useTimer } from "react-timer-hook";
import { MdTimer } from "react-icons/md";
import { setPractitionerAvailability } from "../redux/practitioner/actionCreators";
import { prescriptionAppointmentKey } from "../utility/constants";
import { useHistory } from "react-router-dom";
import { dashboardCreatePrescriptionRoute, dashboardRoute } from "../routes";
import VoiceRecordField from "./VoiceRecordField";

const VideoCallUserInfoCard = () => {
  const { role } = useSelector((state) => state.auth.userData);
  const language = useSelector((state) => state.language);
  const appointmentDetails = useRef(
    JSON.parse(localStorage.getItem(currentAppointmentDetailsKey))
  );
  const isPractitioner = role === userRoleDoctor;
  const dispatch = useDispatch();
  const history = useHistory();
  const reduxStore = useSelector((state) => state);
  const endCall = () => {
    dispatch(setPractitionerAvailability(1));
    if (role === userRoleDoctor) {
      localStorage.setItem(
        prescriptionAppointmentKey,
        JSON.stringify(reduxStore.practitioner.currentAppointment)
      );
      history.replace(dashboardCreatePrescriptionRoute.path);
    } else {
      history.replace(dashboardRoute.path);
    }
  };

  const time = new Date();
  time.setSeconds(time.getSeconds() + 10); // 15 minutes timer

  function MyTimer({ expiryTimestamp }) {
    // const { seconds, minutes } = useTimer({
    //   expiryTimestamp,
    //   onExpire: () => endCall(),
    // });
    return (
      <div>
        {/* <button className="timer-sty" style={{ marginLeft: "0" }}>
          { <MdTimer style={{ margin: "-3px 6px 0 0" }} /> }
          {<span>{minutes}</span>:<span>{seconds}</span>}
        </button> */}
      </div>
    );
  }

  if (!appointmentDetails.current) return <div></div>;
  return (
    <>
      <div>
        <MyTimer expiryTimestamp={time} />
      </div>

      <div className="patient-info">
        <div className="d-flex">
          <BiUser size={20} style={{ color: "#57606a", marginRight: "10px" }} />
          <div style={{ paddingLeft: "5px" }}>
            <div className="patient-info--heading">
              {isPractitioner
                ? language.lang["patient-info"]
                : language.lang.doctor_information}
            </div>
            {isPractitioner && (
              <div className="patient-info--details">
                {reduxStore.language.lang.name} :{" "}
                {appointmentDetails.current.patientInfo.name}{" "}
                {appointmentDetails.current.patientInfo.surname || ""}
              </div>
            )}
            {!isPractitioner && appointmentDetails.current && (
              <div className="patient-info--details">
                {reduxStore.language.lang.name} :{" "}
                {appointmentDetails.current.practitionerInfo.name}{" "}
                {appointmentDetails.current.practitionerInfo.surname || ""}
              </div>
            )}
            {isPractitioner && appointmentDetails.current.age && (
              <div className="patient-info--details">
                {language.lang.age} : {appointmentDetails.current.age}
              </div>
            )}
            {isPractitioner && appointmentDetails.current.phone && (
              <div className="patient-info--details">
                {language.lang["phone-number"]} : +{" "}
                {appointmentDetails.current.phone}
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="patient-info--heading-reason">
            {isPractitioner
              ? language.lang["reason-for-visit"]
              : language.lang["reason-of-visit"]}
          </div>
          <p className="patient-info--reason">
            {appointmentDetails.current && appointmentDetails.current.reason}
          </p>
        </div>
        <div>
          {appointmentDetails.current.voice_note_url &&
            appointmentDetails.current.voice_note_url !== "null" && (
              <div className="d-flex my-2 justify-content-between align-items-center">
                <div className="patient-info-reports">
                  {language.lang["listen-to-voice-note"]}
                </div>
                <audio
                  src={appointmentDetails.current.voice_note_url}
                  controls
                />
              </div>
            )}
          {appointmentDetails.current.medical_report_url &&
            appointmentDetails.current.medical_report_url !== "null" && (
              <div className="d-flex my-2">
                <div className="patient-info-reports">
                  {language.lang["view-medical-report"]}
                </div>
                <a
                  className="btn"
                  href={appointmentDetails.current?.medical_report_url}
                  download="medical_report.pdf"
                  target="_blank"
                >
                  <IconContext.Provider value={{ color: "#ee2c34" }}>
                    <VscFilePdf size={30} className="patient-info--icons" />
                  </IconContext.Provider>
                </a>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default VideoCallUserInfoCard;
