import CookingInstructionListItem from "./CookingInstructionListItem";

const CookingInstructionsList = ({ cookingInstructions, onItemEditClicked }) => {
  return (
    <div className="cooking-instructions-list">
      {cookingInstructions.map((item, index) => {
        return (
          <CookingInstructionListItem
            key={item.id}
            id={item.id}
            item={item.value}
            number={index + 1}
            onEditClicked={onItemEditClicked}
            isLink={item.isLink}
          />
        );
      })}
    </div>
  );
};

export default CookingInstructionsList;
