
import NotificationsButton from "./NotificationsButton";
import ProfileCircleButton from "./ProfileCircleButton";
import AllRecipe from "./AllRecipe";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { settings } from "../config";
import { MostPopularCardDashRoute } from "../routes";
import Loading from "./Loading";
import MostPopularCard from "./MostPopularCard";
import BackButton from "./BackButton";
import VideoCard from "./VideoTiles";
import { useSelector } from "react-redux";
import LogoSideButton from "./LogoSideButton";



const DashboardFitness = () => {
const [allrecipe,setallrecipe] = useState([]);
const [recipe, setrecipe] = useState([]);
const [flag, setflag] = useState(false);
const [pageLoading, setpageLoading] = useState(false);
const history = useHistory();
const id=3;
const type="Fitness";
const language = useSelector((state) => state.language);
const [offset, setoffset] = useState(0);
const [limit, setlimit] = useState(10);
useEffect(() => {
    !flag && FetchRecipes(offset);
  },[])
  const FetchRecipes = async (off) =>{
    await axios.get(`${settings.apiBaseURL}/getSingleFeatureByCategory?categoryIds=${id}&offset=${off}&limit=${limit}`)
            .then((response) => {
              setallrecipe((prevState) => [...prevState, ...response.data.response]);
          })
          .catch((error) => { console.log(error.response.status) })
          .finally(()=>{setflag(true); })
          setpageLoading(false);
  }
  const routeRecipe = (recipe) =>{
    history.push(MostPopularCardDashRoute.path, {
      list: recipe,
      listid: id,
      pagetitle: type
    });
  }
  const handleScroll = (event) => {
    setpageLoading(true);
    console.log("fetch single cats --");
    const target = event.target;
    if (target.scrollWidth - target.scrollLeft === target.clientWidth) {
      console.log("fitness");
      FetchRecipes(offset + limit);
      setoffset(offset + limit);
      if(limit === 10) setlimit(5);
    }
  };
  return (
    <section className="row px-4">
    <div className="d-flex justify-content-between p-0 m-0 align-items-center">
      <div className="d-flex align-items-center">
        <div><LogoSideButton /></div>
      <div><BackButton /></div>
        <div>
            <h3 className="Page-title-custom">{language.lang.fitness}</h3>
            <div className="title-border"></div>
        </div>
        </div>
        <div className="d-flex">
          <NotificationsButton className="ml-auto" />
          <ProfileCircleButton />
        </div>
      </div>
    <div className="d-flex justify-content-center w-100">
        <section className="row pt-4 px-4 w-100">

          <div className="grid-layout justify-content-center" style={{gap:'20px'}} onScroll={handleScroll}> 
          {flag && allrecipe ?
                allrecipe.filter((a)=>{ return(recipe.id !=a.id)}).map((item, i) => {
                    return (
                    <div
                        className="d-flex justify-content-around scroll-snap-start"
                        style={{ maxHeight: "200px",cursor: "pointer", 'margin-bottom': "12rem"}}
                        key={i}
                        onClick={() => routeRecipe(item)}
                    >
                      <VideoCard data={item}/>
                        {/* <MostPopularCard recipe={item} /> */}
                    </div>
                    );
                }) : <Loading width={100} height={100} />} 
                 {pageLoading && (
            <div className="d-flex justify-content-center align-items-center">
              <Loading />
            </div>
          )}
         </div>
        
        </section>
        </div>
  </section>);
};

export default DashboardFitness;