import TopMenu from "../../../components/CommonControls/TopMenu";
import { HiChatAlt2 } from "react-icons/hi";
import { BsFillPersonFill } from "react-icons/bs";
import { BiSearchAlt2 } from "react-icons/bi";
import { useEffect, useReducer, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMatchedUsers,
  getUserConversations,
  refreshConversationList,
} from "../../../redux/fitnessBuddy/actionCreators";
import VerifyBrokenImage from "../../../components/VerifyBrokenImage";
import AgoraRTM from "agora-rtm-sdk";
import axios from "../../../services/Axios";
import uuid from "react-uuid";
import { Form } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { agoraAppId } from "../../../utility/constants";
import {
  setAgoraChannelAction,
  setAgoraClientAction,
  leaveChannelAction,
  addMessageAction,
  saveMessage,
  fetchChatMessages,
  setChatMessagesAction,
  setMessageSaved,
} from "./actions";
import { showErrorNotification } from "../../../utility/Helpers";
import reducer, { initState } from "./reducer";
import { settings } from "../../../config";
import SentMsgItem from "../../../components/SentMsgItem";
import ReceivedMsgItem from "../../../components/ReceivedMsgItem";
import { IoMdSend } from "react-icons/io";
import { fitnessBuddyRoute, viewMatchesRoute } from "../../../routes";
import { useHistory } from "react-router-dom";
import Loading from "../../../components/Loading";
import AvatarImg from "../../../assets/images/avatar.png";
import fitnessActions from "../../../redux/fitnessBuddy/actions";
import { IoSettingsSharp } from "react-icons/io5";
import ReactPaginate from "react-paginate";
import actions from "./actions";

const BuddysLiveChat = () => {
  const language = useSelector((state) => state.language);
  const fitnessDispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth);

  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initState);
  const [channelID, setChannelID] = useState("");
  const [selectedUserDetails, setSelectedUserDetails] = useState({});
  const [messageText, setMessageText] = useState("");
  const [inputText, setInputText] = useState("");
  const isLiveChatPageLoading = useSelector(
    (state) => state.patientDetails.isLiveChatPageLoading
  );
  const isLiveChatWindowLoading = useSelector(
    (state) => state.patientDetails.isLiveChatWindowLoading
  );
  const isConversationRefreshed = useSelector(
    (state) => state.patientDetails.isConversationRefreshed
  );
  const matchedUser = useSelector((state) => state.patientDetails.matchedUsers);
  const userID = useSelector(
    (state) => state.auth.userData && state.auth.userData.id
  );
  const messagesListRef = useRef(null);
  const messageFieldRef = useRef(null);
  const [messageOffset, setMessageoffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [hidePrevious, setHidePrevious] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const defaultChatUser = useSelector(
    (state) => state.patientDetails.defaultChatUser
  );
  console.log(userID);
  const itemsPerPage = 5;
  useEffect(() => {
    fitnessDispatch(getMatchedUsers());
    fitnessDispatch(getUserConversations(messageOffset, limit));
  }, []);
  useEffect(() => {
    if (
      Object.keys(defaultChatUser).length !== 0 &&
      !selectedUserStatus &&
      !isLiveChatPageLoading
    ) {
      const defaultSelectedChat = {};
      if (defaultChatUser.users[0].userDetail.id !== userID) {
        defaultSelectedChat.id = defaultChatUser.users[0].userDetail.id;
        defaultSelectedChat.name = defaultChatUser.users[0].userDetail.name;
        defaultSelectedChat.profile_photo_url =
          defaultChatUser.users[0].userDetail.profile_photo_url;
        defaultSelectedChat.is_online =
          defaultChatUser.users[0].userDetail.is_online;
      } else {
        defaultSelectedChat.id = defaultChatUser.users[1].userDetail.id;
        defaultSelectedChat.name = defaultChatUser.users[1].userDetail.name;
        defaultSelectedChat.profile_photo_url =
          defaultChatUser.users[1].userDetail.profile_photo_url;
        defaultSelectedChat.is_online =
          defaultChatUser.users[1].userDetail.is_online;
      }

      selectedUser({ userDetails: defaultSelectedChat });
    }
  }, [defaultChatUser, isLiveChatPageLoading]);
  const selectedUserID = useSelector(
    (state) => state.patientDetails.selectedUserID
  );
  const selectedUserStatus = useSelector(
    (state) => state.patientDetails.selectedUserStatus
  );
  const userConversations = useSelector(
    (state) => state.patientDetails.userConversations
  );

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  useEffect(() => {
    if (selectedUserStatus) {
      selectedUser({ userDetails: selectedUserID });
    }
  }, [selectedUserID]);
  useEffect(() => {
    if (isConversationRefreshed) {
      updateMessageConvoList();
      fitnessDispatch({
        type: fitnessActions.UPDATE_IS_NEXT_CONVERSATION_REFRESHED,
        payload: { isConversationRefreshed: false },
      });
    }
  }, [isConversationRefreshed]);
  const selectedUser = async ({ userDetails, convoID }) => {
    fitnessDispatch({
      type: fitnessActions.UPDATE_LIVE_CHAT_WINDOW_LOADING,
      payload: { isLiveChatWindowLoading: true },
    });
    setsenderDetails(userDetails);
    setSelectedUserDetails(userDetails);
    try {
      const response = await axios.post(
        `${settings.apiBaseURL}/joinConversation`,
        {
          matchUserId: userDetails.id,
        }
      );
      if (convoID) {
        const updatedResponse = await axios.put(
          `${settings.apiBaseURL}/updateConversation`,
          {
            conversationId: convoID,
            isSeen: true,
          }
        );
        if (updatedResponse.status === 200) {
          fitnessDispatch(refreshConversationList(messageOffset, limit));
          updateMessageConvoList();
        }
      }

      if (response.status === 200) {
        fitnessDispatch({
          type: fitnessActions.UPDATE_LIVE_CHAT_WINDOW_LOADING,
          payload: { isLiveChatWindowLoading: false },
        });
        scrollToBottom();
      }
      setChannelID(response.data.response);
    } catch (e) {
      showErrorNotification(e.response.data.error);
      fitnessDispatch({
        type: fitnessActions.UPDATE_LIVE_CHAT_WINDOW_LOADING,
        payload: { isLiveChatWindowLoading: false },
      });
      scrollToBottom();
    }
  };
  const scrollToBottom = () => {
    if (messagesListRef.current !== null) {
      messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [state.messagesList]);

  const getAgoraRTMToken = async () => {
    try {
      const res = await axios.post("/rtmToken", {
        userId: userID,
        channelName: channelID,
      });
      return res.data.response;
    } catch (e) {
      console.log("--- getAgoraRTMToken error");
      console.log(e);
      return null;
    }
  };

  const onChannelMessage = (message, memberId) => {
    message.isSent = false;
    dispatch(addMessageAction(message));
  };

  useEffect(() => {
    if (channelID === "") return;
    let channel;
    let client;
    getAgoraRTMToken()
      .then((token) => {
        console.log("--- chat token ", token);
        const options = {
          uid: userID,
          token: token,
        };

        const appID = agoraAppId;

        // Initialize client
        client = AgoraRTM.createInstance(appID);
        dispatch(setAgoraClientAction(client));

        // Display connection state changes
        client.on("ConnectionStateChanged", function (state, reason) {
          console.log("--- ConnectionStateChanged");
          console.log(state, reason);
        });

        channel = client.createChannel(channelID);
        dispatch(setAgoraChannelAction(channel));

        channel.on("ChannelMessage", onChannelMessage);
        // Display channel member stats
        channel.on("MemberJoined", function (memberId) {
          console.log("--- MemberJoined");
          console.log(memberId);
        });
        // Display channel member stats
        channel.on("MemberLeft", function (memberId) {
          console.log("--- MemberLeft");
          console.log(memberId);
        });

        client.login(options).then(() => {
          channel.join();
        });
      })
      .catch(() => {
        showErrorNotification();
      });

    return () => {
      dispatch(leaveChannelAction());
      if (channel) channel.leave();
      if (client) client.logout();
    };
  }, [channelID]);
  const [senderDetails, setsenderDetails] = useState({});
  const getSenderDetails = () => {
    setsenderDetails(selectedUserDetails);
  };

  useEffect(() => {
    if (senderDetails && Object.keys(senderDetails).length === 0) {
      getSenderDetails();
    }

    fitnessDispatch(refreshConversationList(messageOffset, limit));
    console.log("Sender Details", senderDetails);
  }, [state.messagesList]);

  useEffect(() => {
    console.log("senderDetails --> ", senderDetails);
  }, [senderDetails]);

  useEffect(() => {
    fitnessDispatch({
      type: fitnessActions.UPDATE_LIVE_CHAT_WINDOW_LOADING,
      payload: { isLiveChatWindowLoading: true },
    });
    fetchChatMessages(channelID).then((value) => {
      dispatch(setChatMessagesAction(value, userID));
      fitnessDispatch(refreshConversationList);

      fitnessDispatch({
        type: fitnessActions.UPDATE_LIVE_CHAT_WINDOW_LOADING,
        payload: { isLiveChatWindowLoading: false },
      });
      scrollToBottom();
    });
  }, [channelID]);

  useEffect(() => {
    if (state.isMessageSaved) {
      fitnessDispatch(refreshConversationList(messageOffset, limit));
    }
  }, [state.isMessageSaved]);

  const postMessage = async (type) => {
    if (!messageText) return;
    const message = { text: messageText, isSent: true, type: type };
    state.agoraChannel.sendMessage(message);
    dispatch(addMessageAction(message));
    setMessageText("");
    await saveMessage({
      channelId: channelID,
      userId: userID,
      message: messageText,
      type: type,
      module: "Fitness",
    });
    dispatch(setMessageSaved(true));
    dispatch(setMessageSaved(false));

    messageFieldRef.current.focus();
  };
  let filteredMatchedUser = matchedUser.filter((user) => {
    //if no input the return the original

    if (inputText === "") {
      return user;
    }
    //return the item which contains the user input
    else {
      return user.matchUser.name.toLowerCase().includes(inputText);
    }
  });
  const updateMessageConvoList = () => {
    const endOffset = itemOffset + itemsPerPage;
    let filteredMatchedUserConversations = userConversations.filter((convo) => {
      //if no input the return the original

      if (inputText === "") {
        return convo;
      }
      //return the item which contains the user input
      else {
        return convo.users[0].userDetail.id !== userID
          ? convo.users[0].userDetail.name.toLowerCase().includes(inputText)
          : convo.users[1].userDetail.name.toLowerCase().includes(inputText);
      }
    });
    setCurrentItems(
      filteredMatchedUserConversations.slice(itemOffset, endOffset)
    );
  };

  useEffect(() => {
    if (Object.keys(userConversations).length !== 0) {
      const endOffset = itemOffset + itemsPerPage;
      let filteredMatchedUserConversations = userConversations.filter(
        (convo) => {
          //if no input the return the original

          if (inputText === "") {
            return convo;
          }
          //return the item which contains the user input
          else {
            return convo.users[0].userDetail.id !== userID
              ? convo.users[0].userDetail.name.toLowerCase().includes(inputText)
              : convo.users[1].userDetail.name
                  .toLowerCase()
                  .includes(inputText);
          }
        }
      );
      setCurrentItems(
        filteredMatchedUserConversations.slice(itemOffset, endOffset)
      );
      setPageCount(Math.ceil(userConversations.length / itemsPerPage));
    }
  }, [userConversations, itemOffset, itemsPerPage, inputText]);

  const checkLastMessageByUserId = (conversation) => {
    if (conversation.lastMessage.length === 0) {
      return "last-message";
    } else if (conversation.lastMessage[0].user_id === userID) {
      return "last-message";
    } else if (conversation.is_seen) {
      return "last-message";
    } else {
      return "last-message message-highlight";
    }
  };
  const goToSettings = () => {
    history.push(fitnessBuddyRoute.path);
  };

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % userConversations.length;

    setItemOffset(newOffset);
  };
  return (
    <div>
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.lang.fitnessConnect}
      />
      {isLiveChatPageLoading ? (
        <div className="d-flex justify-content-center">
          <Loading />
        </div>
      ) : (
        <div className="live-chat-container">
          <div className="live-chat-layout row">
            <div className="headings col md-6">
              <div
                className="settings"
                onClick={() => {
                  history.push(viewMatchesRoute.path);
                }}
              >
                <BsFillPersonFill />
                <div className="title-lc">
                  {language.lang.keepSwipingMatches}
                </div>
              </div>

              <div onClick={goToSettings} className="settings">
                <IoSettingsSharp />
                <div className="title-lc">{language.lang.fitnessSettings}</div>
              </div>
            </div>
            <div className="split-screen row">
              <div className="left-window col md-6">
                <div className="search-box">
                  <BiSearchAlt2 />
                  <input
                    className="neumorphic-input"
                    type="text"
                    placeholder={language.lang.searchYourMatch}
                    onChange={inputHandler}
                  />
                </div>
                <label className="new-matches">
                  {language.lang.newMatches}
                </label>
                <div
                  className="d-flex items-list  custom-scroll pb-lg-2  nw-matches-grp"
                  style={{ gap: "2px", maxWidth: "100%" }}
                >
                  {filteredMatchedUser.map((user) => {
                    return (
                      <div className="profile-container">
                        <div className="nm-profile">
                          <button
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            id="prifle-dropdown"
                            className="btn p-0 profile-btn d-flex justify-content-center align-items-center"
                            onClick={() =>
                              selectedUser({ userDetails: user.matchUser })
                            }
                          >
                            <VerifyBrokenImage
                              url={user?.matchUser?.profile_photo_url}
                              name={
                                user?.matchUser?.name && user?.matchUser?.name
                              }
                            />
                          </button>
                          <div
                            style={{
                              background: user?.matchUser?.is_online
                                ? "#3fec84"
                                : "#fffb02",
                            }}
                            className="online-status"
                          ></div>
                        </div>
                        <label className="pc-name">
                          {user?.matchUser?.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <label className="left-messages">
                  {language.lang.messages}
                </label>
                <div className="grp-messages">
                  {currentItems.map((conversation) => {
                    return (
                      <div
                        key={conversation.id}
                        className="profile-details"
                        onClick={() =>
                          selectedUser(
                            userID !== conversation.users[0].userDetail.id
                              ? {
                                  userDetails: conversation.users[0].userDetail,
                                  convoID: conversation.id,
                                }
                              : {
                                  userDetails: conversation.users[1].userDetail,
                                  convoID: conversation.id,
                                }
                          )
                        }
                      >
                        <div className="profile-container">
                          <button
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            id="prifle-dropdown"
                            className="btn p-0 profile-btn d-flex justify-content-center align-items-center"
                          >
                            <VerifyBrokenImage
                              url={
                                userID !== conversation.users[0].userDetail.id
                                  ? conversation.users[0].userDetail
                                      ?.profile_photo_url
                                  : conversation.users[1].userDetail
                                      ?.profile_photo_url
                              }
                              name={
                                userID !== conversation.users[0].userDetail.id
                                  ? conversation.users[0].userDetail?.name
                                  : conversation.users[1].userDetail?.name
                              }
                            />
                          </button>
                          <div
                            style={{
                              background:
                                userID !== conversation.users[0].userDetail.id
                                  ? conversation.users[0].userDetail?.is_online
                                    ? "#3fec84"
                                    : "#FFFF00"
                                  : conversation.users[1].userDetail?.is_online
                                  ? "#3fec84"
                                  : "#FFFF00",
                            }}
                            className="online-status"
                          ></div>
                        </div>

                        <div className="user-details">
                          <label>
                            {userID !== conversation.users[0].userDetail.id
                              ? conversation.users[0].userDetail?.name
                              : conversation.users[1].userDetail?.name}
                          </label>
                          <label
                            className={checkLastMessageByUserId(conversation)}
                          >
                            {conversation.lastMessage.length === 0
                              ? ""
                              : conversation.lastMessage[0].message}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination hide-pagination"}
                  subContainerClassName={"pages pagination "}
                  activeClassName={"active"}
                />
              </div>
              {isLiveChatWindowLoading ? (
                <div className="d-flex justify-content-center is-loading col md-6">
                  <Loading />
                </div>
              ) : (
                <div className="right-window col md-6">
                  {Object.keys(selectedUserDetails).length === 0 ? (
                    <></>
                  ) : (
                    <>
                      <div className="chat-header">
                        <label className="right-messages">
                          {language.lang.message}
                        </label>
                        <div className="user-profile">
                          <div className="profile-container">
                            <button
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              id="prifle-dropdown"
                              className="btn p-0 profile-btn d-flex justify-content-center align-items-center"
                            >
                              <img
                                src={
                                  !!selectedUserDetails?.profile_photo_url
                                    ? selectedUserDetails.profile_photo_url
                                    : AvatarImg
                                }
                                alt="no-pic"
                              />
                            </button>
                            <div
                              style={{
                                background:
                                 selectedUserDetails?.is_online
                                    ? "#3fec84"
                                    : "#fffb02",
                              }}
                              className="online-status"
                            ></div>
                          </div>

                          {selectedUserDetails?.name}
                        </div>
                      </div>
                      <div ref={messagesListRef} className="chat-body">
                        {state &&
                          state.messagesList.map((item) => {
                            const key = uuid();
                            const messageText = item.text || item.message;
                            if (item.isSent) {
                              return (
                                <SentMsgItem key={key} text={messageText} />
                              );
                            }
                            return (
                              <div className="recived-message">
                                <ReceivedMsgItem
                                  key={key}
                                  data={item}
                                  text={messageText}
                                  userImg={senderDetails?.profile_photo_url}
                                  name={
                                    senderDetails?.name && senderDetails?.name
                                  }
                                />
                              </div>
                            );
                          })}
                      </div>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          postMessage("text");
                        }}
                        className="sent-msg--input"
                      >
                        <Form.Group className="form-grp">
                          <Form.Control
                            type="text"
                            className="shadow-none"
                            value={messageText}
                            placeholder={language.lang.typeAMessage}
                            onChange={(e) => setMessageText(e.target.value)}
                            ref={messageFieldRef}
                          />
                          <IoMdSend
                            className="post-msg"
                            size={20}
                            onClick={() => {
                              postMessage("text");
                            }}
                          />
                        </Form.Group>
                      </Form>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default BuddysLiveChat;
