import { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  TimePicker,
  Select,
  Switch,
  Space,
  InputNumber,
  Row,
  Col,
} from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "../../services/Axios";
import Loading from "../Loading";
import {
  showErrorNotification,
  showSuccessNotification,
  uploadFile,
} from "../../utility/Helpers";
import {
  categoryMentalHealth,
  fileTypeImage,
  storageTypeImage,
  userTypeUser,
} from "../../utility/constants";
import { Modal } from "react-bootstrap";
import EmiratesIDImageField from "../EmiratesIDImageField";
import { useHistory } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { practitionerLiveSessionRoute } from "../../routes";

const PractitionerNewLiveSessionFormTab = () => {
  const history = useHistory();
  const language = useSelector((state) => state.language.lang);
  const { userData } = useSelector((state) => state.auth);
  const [isPaid, setIsPaid] = useState(true);
  const [participantsMicOn, setParticipantsMicOn] = useState(false);
  // console.log("--- participantsMicOn", participantsMicOn);
  const [sessionCategories, setSessionCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [loadingFormSubmit, setLoadingFormSubmit] = useState(false);
  const [selectedThumbnailImage, setSelectedThumbnailImage] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [infoPoint, setInfoPoint] = useState(null);
  const [info, setInfo] = useState([]);
  const [infArr, setInfArr] = useState([]);
  const [point, setPoint] = useState([]);
  // const [infArr, setInfArr] = useState([]);
  const [foundIndex, setFoundIndex] = useState(null);
  const isProductionUser = userData.user_group == "production";
  const [imageUrl, setImageUrl] = useState();
  const dateFormat = "DD-MM-YYYY";

  // const handleChange = ({ fileList }) => setFileList({fileList});
  // const handleChange = ({ fileList }) => setFileList(fileList);
  const handleImgChange = (file) => {
    setSelectedThumbnailImage([file]);
  };
  // console.log('selected image',selectedThumbnailImage);

  // function getBase64(file) {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  // }

  // const handlePreview = async (file) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   setPreviewImage(file.url || file.preview);
  //   setPreviewVisible(true);
  // };

  useEffect(() => {
    getSessionCategories();
  }, []);

  const getSessionCategories = async () => {
    try {
      const res = await axios.get("/getSchedulerTypes");
      setSessionCategories(
        res.data.response.slice(1, res.data.response.length)
      );
    } catch (e) {
      console.log("--- getSessionCategories error");
      console.log(e);
      showErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const startDateValidator = (field, value) => {
    // console.log("--- startDateValidator", field, value);
    if (!value) {
      return Promise.reject(new Error(language.pleaseSelectStartDate));
    }
    const now = moment();
    // console.log("--- format", now.format(dateFormat));
    const today = moment(now.format(dateFormat), dateFormat);
    const after12Hours = now.clone().add(12, "hour").toDate().getTime();
    const selectedValue = value.toDate().getTime();
    // console.log(
    //   "--- date",
    //   today,
    //   now.clone().toDate().getTime(),
    //   selectedValue
    // );
    if (selectedValue < after12Hours && isProductionUser) {
      return Promise.reject(new Error(language.selectValue12HoursFromNow));
    } else if (selectedValue < today.clone().toDate().getTime()) {
      return Promise.reject(
        new Error(language.selectFutureDate || "invalid date")
      );
    }
    return Promise.resolve();
  };

  const startTimeValidator = (field, value) => {
    // console.log("--- startDateValidator", value.format("HH:mm"), value);
    if (!value) {
      return Promise.reject(new Error(language.pleaseSelectStartTime || ""));
    }
    // console.log({ language: language.pleaseSelectStartTime });
    const selectedDate = form.getFieldValue("startDate");
    if (!selectedDate) {
      return Promise.reject(new Error(language.pleaseSelectStartDate));
    }
    const timeFormat = "HH:mm";
    const now = moment();
    const tomorrow = now.clone().add(12, "hour").toDate().getTime();
    const selectedValue = moment(
      `${selectedDate.format(dateFormat)} ${value.format(timeFormat)}`,
      `${dateFormat} ${timeFormat}`
    );
    if (selectedValue < tomorrow && isProductionUser) {
      return Promise.reject(new Error(language.selectValue12HoursFromNow));
    }
    // else if (selectedValue < now.toDate().getTime()) {
    //   return Promise.reject(new Error("Please select a future date"));
    // }
    return Promise.resolve();
  };

  const onFormFinishedValidation = async (formData) => {
    // console.log({
    //   formData: formData.participantsMicEnabled,
    //   participantsMicOn,
    // });
    // console.log("--- image", selectedThumbnailImage);
    if (!selectedThumbnailImage || selectedThumbnailImage.length === 0) {
      return showErrorNotification(language.pleaseSelectPromotionImage);
    }
    const startDateStr = formData.startDate.format("YYYY-MM-DD");
    const startTimeStr = formData.startTime.format("HH:mm");
    const startDateTimeMoment = moment(
      `${startDateStr} ${startTimeStr}`,
      "YYYY-MM-DD HH:mm"
    );
    const endDateTimeMoment = startDateTimeMoment
      .clone()
      .add(formData.durationHours, "hours")
      .add(formData.durationMinutes, "minutes");
    const endTimeStr = endDateTimeMoment.format("HH:mm");
    const endDateStr = endDateTimeMoment.format("YYYY-MM-DD");
    // const duration = moment.duration({
    //   hours: formData.durationHours,
    //   minutes: formData.durationMinutes,
    // });
    // console.log(
    //   duration,
    //   duration.toISOString(),
    //   duration.toJSON(),
    //   duration.toString(),
    //   duration.format
    // );
    const duration = `${
      formData.durationHours > 9
        ? formData.durationHours
        : `0${formData.durationHours}`
    }:${
      formData.durationMinutes > 9
        ? formData.durationMinutes
        : `0${formData.durationMinutes}`
    }:00`;
    setLoadingFormSubmit(true);
    const thumbnailURL = await uploadThumbnail();
    // debugger;
    const requestData = {
      title: formData.topic,
      startDate: startDateStr,
      startTime: startTimeStr,
      endDate: endDateStr,
      endTime: endTimeStr,
      duration: duration,
      timeZone: formData.timeZone,
      isPaid: isPaid,
      amount: formData.pricePerHead || "0",
      amountCurrency: "AED",
      attendeesMic: participantsMicOn, // formData.participantsMicEnabled,
      maxAttendees: formData.attendeesNumber,
      sessionCategory: formData.sessionCategory,
      description: formData.description,
      sessionImageUrl: thumbnailURL,
      keyPoints: info,
    };

    // console.log("--- requestData", requestData);
    try {
      const res = await axios.post("/createLiveSession", requestData);
      // console.log("--- response", res.data.response[0]);
      if (res.data.response && res.data.response.id) {
        // form.resetFields();
        // setSelectedThumbnailImage([]);
        // setFileList([]);
        showSuccessNotification(language.eventScheduledSuccess);
        history.replace(practitionerLiveSessionRoute.path);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (e) {
      console.log("--- createLiveSession error");
      console.log(e);
      showErrorNotification();
    } finally {
      setLoadingFormSubmit(false);
    }
  };

  const uploadThumbnail = () => {
    if (selectedThumbnailImage.length === 0) return null;
    const data = {
      storageType: storageTypeImage,
      category: categoryMentalHealth,
      userType: userTypeUser,
      fileType: fileTypeImage,
      userId: userData.id,
    };
    data["multi-files"] = selectedThumbnailImage[0];
    // console.log("image", selectedThumbnailImage[0]);
    return uploadFile(data);
  };

  const addInfo = (e) => {
    setInfoPoint(e.target.value);
  };

  const addInfoArr = () => {
    // console.log(infoPoint, "asdfasdfasdf");
    setShowInfoModal(false);
    setInfo((prev) => [...prev, { keyPointEn: infoPoint }]);
    setInfArr((prev) => [...prev, infoPoint]);
  };

  const editInfoArr = () => {
    // console.log(infoPoint, "asdfasdfasdf");
    setShowEditModal(false);

    const newArr = infArr.map((item, i) => {
      if (i === foundIndex) {
        // console.log("item before", item);
        item = point.toString();
        // console.log("item after", item);
        return item;
      } else {
        return item;
      }
    });
    setInfArr((prev) => [...newArr]);
  };

  // let infArr =[]
  // info.forEach(value => {
  // infArr.push(value.keyPointEn)
  // // console.log('infarr',infArr);
  // });

  const editPoint = (index) => {
    setShowEditModal(true);
    setFoundIndex(index);
    const foundPoint = infArr.filter((item, i) => index === i);
    if (!foundPoint) return;
    setPoint(foundPoint);

    // point.map(item => item === )
  };

  // const uploadButton = (
  //   <div>
  //     <div style={{ marginTop: 8 }}>Upload</div>
  //   </div>
  // );

  return (
    <>
      <div className="d-flex justify-content-between w-100">
        <div className="practitioner-new-live-session-form-tab w-100">
          {loading ? (
            <Loading />
          ) : (
            <Form
              className="new-live-session-form d-flex w-100"
              size="large"
              form={form}
              requiredMark={false}
              name="basic"
              initialValues={{
                // startDate: moment().add(1, "day"),
                // startTime:null,
                // startDate: null,

                // durationHours: 0,
                // durationMinutes: 0,
                timeZone: "+04:00",
                participantsMicEnabled: participantsMicOn,
                isPaid: isPaid,
                thumbnail: selectedThumbnailImage,
              }}
              wrapperCol={{ span: 18 }}
              labelCol={{ span: 8 }}
              layout="vertical"
              onFinish={onFormFinishedValidation}
              // onFinishFailed={(e) => console.log("--- form onFinishFailed", e)}
              autoComplete="off"
            >
              <Row className="w-100">
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={language.eventTopic}
                    name="topic"
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: language.pleaseEnterEventTopic,
                      },
                    ]}
                  >
                    <Input className="live-session-input" />
                  </Form.Item>
                  <Form.Item
                    label={`${language.description}:`}
                    className="itemsmt-3"
                    name="description"
                    wrapperCol={{ span: 24 }}
                    rules={[
                      () => ({
                        validator(field, value) {
                          if (!value || value.length === 0) {
                            return Promise.reject(
                              language.pleaseEnterEventDescription
                            );
                          } else if (value.length < 150 && isProductionUser) {
                            return Promise.reject(
                              language.descriptionShouldBeMoreThan150
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                      // {
                      //   required: true,
                      //   message: "Please enter session description",
                      //   min: 150,
                      // },
                    ]}
                  >
                    <Input.TextArea rows={5} className="description" />
                  </Form.Item>
                  {/* <Form.Item
                label="Thumbnail Image"
                className="mt-3"
                name="thumbnail"
                rules={[
                  {
                    required: true,
                    message: "Please select your session thumbnail",
                  },
                  () => ({
                    validator(field, value) {
                      if (!value) {
                        return Promise.reject(
                          new Error("Please select thumbnail image")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Upload
                  onRemove={() => {
                    setSelectedThumbnailImage([]);
                  }}
                  beforeUpload={(file) => {
                    if (!file.type.startsWith("image/")) {
                      message.error(
                        `${file.name} is not an image. Please select an image`
                      );
                      return false;
                    }
                    setSelectedThumbnailImage([file]);
                    return false;
                  }}
                  maxCount={1}
                  fileList={selectedThumbnailImage}
                >
                  <Button>Select File</Button>
                </Upload>
              </Form.Item> */}
                  <Form.Item wrapperCol={{ span: 24 }} className="itemsmt-3">
                    <Form.Item
                      style={{ display: "inline-block", width: "100%" }}
                      label={`${language.start || ""}:`}
                    >
                      <Form.Item
                        name="startDate"
                        className="mr-2"
                        style={{ display: "inline-block", width: "65%" }}
                        // wrapperCol={{ span: 8 }}
                        rules={[
                          // {
                          //   type: "date",
                          //   required: true,
                          //   message: "Please select start date",
                          // },
                          () => ({
                            validator(field, value) {
                              return startDateValidator(field, value);
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          suffixIcon={<i className="fas fa-chevron-down "></i>}
                          format="dddd MMMM Do YYYY"
                          style={{ width: "100%" }}
                          placeholder=""
                          // onChange={(e) => {
                          //   console.log("--- start date change", e);
                          // }}
                          allowClear={true}
                        />
                      </Form.Item>
                      <Form.Item
                        name="startTime"
                        placeholder=""
                        style={{ display: "inline-block", width: "32%" }}
                        // wrapperCol={{ span: 8 }}
                        label=""
                        rules={[
                          // {
                          //   type: "date",
                          //   required: true,
                          //   message: "Please select start time",
                          // },
                          () => ({
                            validator(field, value) {
                              return startTimeValidator(field, value);
                            },
                          }),
                        ]}
                      >
                        <TimePicker
                          placeholder=""
                          style={{ width: "100%" }}
                          // onChange={(e) => {
                          //   // console.log("--- start date time change", e);
                          // }}
                          use12Hours={true}
                          format="h:mm a"
                          clearIcon={true}
                          minuteStep={5}
                          suffixIcon={<i className="fas fa-chevron-down"></i>}
                        />
                      </Form.Item>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    style={{ flexDirection: "row" }}
                    // wrapperCol={{ span: 16 }}
                    wrapperCol={{ span: 14 }}
                    labelCol={{ span: 8 }}
                    className="itemsmt-3"
                    label={`${language.isPaid}`}
                  >
                    <Space className="mr-3">
                      <Form.Item name="isPaid">
                        <Switch
                          onClick={() => setIsPaid(!isPaid)}
                          checked={isPaid}
                        />
                      </Form.Item>
                      <span className="is-paid-value-text">{language.yes}</span>
                    </Space>
                    <Space>
                      <Form.Item>
                        <Switch
                          checked={!isPaid}
                          onClick={() => setIsPaid(!isPaid)}
                        />
                      </Form.Item>
                      <span className="is-paid-value-text">{language.no}</span>
                    </Space>
                  </Form.Item>
                  {isPaid && (
                    <Form.Item
                      style={{ flexDirection: "row" }}
                      className="itemsmt-3"
                      name="pricePerHead"
                      label={`${language.perHead}:`}
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      rules={[
                        // {
                        //   type: "number",
                        //   message:
                        //      ||
                        //     ,
                        //   min: 0,
                        // },
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.reject(
                                new Error(language.pleaseEnterPricePerHead)
                              );
                            } else if (!Number.parseFloat(value)) {
                              return Promise.reject(
                                new Error(
                                  language.pleaseEnterValidAmount ||
                                    "Plesae enter a valid amount"
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                        // {
                        //   required: true,
                        //   message: ,
                        // },
                      ]}
                    >
                      <Input className="live-session-input" suffix="AED" />
                    </Form.Item>
                  )}
                  <Form.Item
                    style={{ flexDirection: "row" }}
                    wrapperCol={{ span: 14 }}
                    labelCol={{ span: 8 }}
                    className="itemsmt-3"
                    label={`${language.participantsMic}:`}
                  >
                    <Space className="mr-3">
                    <Form.Item>
                        <Switch
                          checked={participantsMicOn}
                          onClick={() =>
                            setParticipantsMicOn(!participantsMicOn)
                          }
                        />
                      </Form.Item>
                      <span className="is-paid-value-text">{language.on}</span>
                    </Space>
                    <Space>
                    <Form.Item name="participantsMicEnabled">
                        <Switch
                          checked={!participantsMicOn}
                          onClick={() =>
                            setParticipantsMicOn(!participantsMicOn)
                          }
                        />
                      </Form.Item>
                      <span className="is-paid-value-text">{language.off}</span>
                    </Space>
                  </Form.Item>
                  <Form.Item
                    label={`${language.timeZone}:`}
                    style={{ flexDirection: "row" }}
                    name="timeZone"
                    className="itemsmt-3"
                    // wrapperCol={{ span: 12 }}
                    wrapperCol={{ span: 16 }}
                    labelCol={{ span: 8 }}
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: language.pleaseSelectTimezone,
                      },
                    ]}
                  >
                    <Select>
                      <Select.Option value="+04:00">
                        UTC + 4 (Dubai)
                      </Select.Option>
                      <Select.Option value="+03:00">
                        UTC + 3 (IRAQ)
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ flexDirection: "row" }}
                    className="itemsmt-3"
                    name="attendeesNumber"
                    label={`${language.attendees}:`}
                    // wrapperCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    labelCol={{ span: 8 }}
                    rules={[
                      {
                        type: "number",
                      },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject(
                              new Error(language.pleaseEnterNumberOfAttendees)
                            );
                          } else if (value < 2) {
                            return Promise.reject(
                              new Error(language.attendeesMustBeMoreThan2)
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber className="live-session-input w-100" />
                  </Form.Item>
                  <Form.Item
                    label={`${language.eventCategory}:`}
                    style={{ flexDirection: "row" }}
                    name="sessionCategory"
                    className="itemsmt-3"
                    // wrapperCol={{ span: 12 }}
                    wrapperCol={{ span: 16 }}
                    labelCol={{ span: 8 }}
                    rules={[
                      {
                        type: "number",
                        required: true,
                        message: language.pleaseSelectEventCategory,
                      },
                    ]}
                  >
                    <Select>
                      {sessionCategories.map((item) => {
                        return (
                          <Select.Option key={item.id} value={item.id}>
                            {item.category}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  lg={{ span: 8, offset: 2 }}
                  className="mt-4 mt-lg-0"
                >
                  <div className="key-points">
                    {infArr.length <= 0 ? (
                      <p className="text-secondary">
                        {language.pleaseAddAdditionalInfo}
                      </p>
                    ) : (
                      infArr.map((point, i) => (
                        <div>
                          <span className="bullet-point">{i + 1}</span> {point}{" "}
                          <i
                            onClick={() => editPoint(i)}
                            className="fas fa-pen"
                          ></i>
                        </div>
                      ))
                    )}
                  </div>
                  <div className="d-flex align-items-center justify-start">
                    <div
                      className="add-info btn itemsmt-3 w-100"
                      onClick={() => setShowInfoModal(true)}
                    >
                      <i className="fas fa-plus-circle"></i> {language.addInfo}
                    </div>
                  </div>
                  <Form.Item
                    style={{ display: "inline-block", width: "100%" }}
                    label={`${language.duration}:`}
                    wrapperCol={{ span: 24 }}
                    className="itemsmt-3"
                  >
                    <Form.Item
                      name="durationHours"
                      // wrapperCol={{ span: 12 }}
                      style={{
                        display: "inline-block",
                        width: "45%",
                        marginRight: "0.7rem",
                      }}
                      rules={[
                        {
                          type: "number",
                          required: true,
                          message: language.pleaseSelectDuration,
                        },
                      ]}
                    >
                      <Select>
                        <Select.Option value={1}>
                          1 {language.hour}
                        </Select.Option>
                        <Select.Option value={2}>
                          2 {language.hours}
                        </Select.Option>
                        <Select.Option value={3}>
                          3 {language.hours}
                        </Select.Option>
                        <Select.Option value={4}>
                          4 {language.hours}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="durationMinutes"
                      // wrapperCol={{ span: 12 }}
                      style={{ display: "inline-block", width: "45%" }}
                      rules={[
                        {
                          type: "number",
                          required: true,
                          message: language.pleaseSelectDurationMinutes,
                        },
                      ]}
                    >
                      <Select>
                        {[...Array(12).keys()].map((value) => {
                          const minutes = value * 5;
                          return (
                            <Select.Option key={minutes} value={minutes}>
                              {minutes} {language.minutes}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label=""
                    wrapperCol={{ span: 24 }}
                    className="itemsmt-3 d-flex justify-content-center"
                    name="thumbnail"
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Please select your session thumbnail",
                      // },
                      () => ({
                        validator(field, value) {
                          if (!value) {
                            return Promise.reject(
                              new Error(language.pleaseSelectThumbImage)
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    {/* <Upload
                    onRemove={() => {
                      setSelectedThumbnailImage([]);
                    }}
                    beforeUpload={(file) => {
                      if (!file.type.startsWith("image/")) {
                        message.error(
                          `${file.name} is not an image. Please select an image`
                        );
                        return false;
                      }
                      setSelectedThumbnailImage([file]);
                      return false;
                    }}
                    
                    maxCount={1}
                    fileList={selectedThumbnailImage}
                  >
                    <Button>Select File</Button>
                  </Upload> */}
                    {/* <Upload
          // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          beforeUpload={(file) => {
                      if (!file.type.startsWith("image/")) {
                        message.error(
                          `${file.name} is not an image. Please select an image`
                        );
                        return false;
                      }
                      setSelectedThumbnailImage([file]);
                      return false;
                    }}
          listType="picture-  "
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload> */}
                    <p className="label">{language.sessionPromotionPhoto}</p>
                  <div className="live-session-image-holder">
                    <EmiratesIDImageField
                      url={imageUrl}
                      onChange={handleImgChange}
                      live={true}
                    />
                  </div>
                  </Form.Item>
                  <Form.Item wrapperCol={{ span: 16 }} className="itemsmt-3">
                    {loadingFormSubmit ? (
                      <Loading />
                    ) : (
                      <div className="d-flex  justify-content-end">
                        <Button
                          type="primary"
                          className="submit-btn btn-label"
                          htmlType="submit"
                        >
                          {language.submitRequest}
                        </Button>
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </div>
        <Modal
          className="add-event-info-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showInfoModal}
          onHide={() => setShowInfoModal(false)}
        >
          <Modal.Header>
            <Modal.Title className="d-flex">
              {language.addInformation}
              <button
                className="btn ml-auto"
                onClick={() => setShowInfoModal(false)}
              >
                <IoMdClose size={20} />
              </button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              name="info"
              id="info"
              rows="5"
              placeholder={language.addAdditionalInfo}
              onChange={(e) => addInfo(e)}
              style={{
                borderRadius: "1.2rem",
                resize: "none",
                padding: "10px",
                width: "100%",
              }}
            ></textarea>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-center gap-5">
            <Button
              className="button"
              variant="secondary"
              onClick={() => setShowInfoModal(false)}
            >
              {language.cancel}
            </Button>
            <Button
              className="button"
              variant="primary"
              onClick={() => addInfoArr()}
            >
              {language.add}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
        >
          <Modal.Header>
            <Modal.Title className="d-flex">
              {language.editInfo}{" "}
              <button
                className="btn ml-auto"
                onClick={() => setShowEditModal(false)}
              >
                <IoMdClose size={20} />
              </button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              value={point.join("\n")}
              name="edit info"
              id="info"
              rows="5"
              placeholder="Please edit information"
              onChange={(e) => setPoint([e.target.value])}
              style={{
                borderRadius: "1.2rem",
                resize: "none",
                padding: "10px",
                width: "100%",
              }}
            ></textarea>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-center gap-5">
            <Button
              className="button"
              variant="secondary"
              onClick={() => setShowEditModal(false)}
            >
              {language.cancel}
            </Button>
            <Button
              className="button"
              variant="primary"
              onClick={() => editInfoArr()}
            >
              {language.save}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default PractitionerNewLiveSessionFormTab;
