import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form, Modal } from "react-bootstrap";
import Switch from "react-switch";
import { Select } from "antd";
// import axios from "../services/Axios";
import { showErrorNotification, showSuccessNotification, arr, uploadFile } from "../utility/Helpers";

import { storageTypeImage, userTypeUser, fileTypeImage, fileTypeVideo, storageTypeVideo } from "../utility/constants";
import UploadComponentHelper from "./UploadComponentHelper";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "../services/Axios";
import { settings } from "../config";
import ImageCropper from "./ImageCropper";
import Loading from "./Loading";
const { Option } = Select;

const UploadBanner = ({ type, show, onHide }) => {
  const [files, setFiles] = useState([]);
  const language = useSelector((state) => state.language);
  const [crop, setcrop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [position, setposition] = useState("left");
  const [banner, setbanner] = useState({
    left: "",
    right: "",
    top: "",
    bottom: "",
  });
  const { userData } = useSelector((state) => state.auth);
  const [Input, setInput] = useState({
    bannerName: "",
    promotionHeader: "",
    promotionText: "",
    promotionDescription: "",
    bannerPosition: "",
    urlCode: "",
    bannerType: "",
  });
  const [maxwidth, setmaxwidth] = useState(0);
  const [maxheight, setmaxheight] = useState(0);
  const onChangeHandler = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
    setInput((prevState) => {
      return {
        ...prevState,
        [nam]: val,
      };
    });
  };

  const onSelectHandler = (e) => {
    setInput((prevState) => {
      return {
        ...prevState,
        bannerType: e,
      };
    });
  };

  const uploadEmiratesID = async () => {
    let flag = Object.keys(Input).length !== 0;
    for (const [key, value] of Object.entries(Input)) {
      if (!value) {
        flag = false;
        break;
      }
    }
    if (!flag) {
      showErrorNotification("Please fill the form fields");
      return;
    }
    if (files.length === 0) {
      showErrorNotification("Please select an image");
      return;
    }
    setLoading(true);
    const Mediadata = {
      storageType: storageTypeImage,
      fileType: stateFiles[0].type,
      userType: userTypeUser,
      userId: userData.id,
      description: Input.promotionDescription,
      category: "Banner",
      isGalleryItem: false,
      isCorporate: false,
      corporateId: "1234567890",
      isPaidContent: false,
      tags: "banner",
    };
    Mediadata["multi-files"] = stateFiles[0];
    let mediaUrl = await uploadFile(Mediadata);
    if (mediaUrl) {
      const Bannerdata = {
        ...Input,
        promotionAlign: position,
        bannerImageUrl: mediaUrl,
      };
      await axios
        .post(`${settings.apiBaseURL}/createBanner`, Bannerdata)
        .then((response) => {
          console.log("Banner response", response);
          setLoading(false);
        })
        .catch((error) => {
          showSuccessNotification("Error creating banner");
          setLoading(false);
        })
        .finally(() => {
          onHide(!show);
          showSuccessNotification("Banner Uploaded");
        });
    }
  };

  let setting = {
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: "image/jpeg, image/png",
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone(setting);

  const [stateFiles, setStateFiles] = useState(acceptedFiles);

  useEffect(() => {
    files.length > 0 && setImageToCrop(files[0].preview);
    setStateFiles(acceptedFiles);
  }, [stateFiles, acceptedFiles]);

  const removeFile = (index) => {
    setStateFiles((prevState) => {
      return prevState.splice(index, 1);
    });
  };

  useEffect(() => {
    if (
      ((position === "left" || position === "right") && Input.bannerPosition > maxwidth) ||
      ((position === "top" || position === "bottom") && Input.bannerPosition > maxheight)
    )
      return;
    setbanner((prevState) => {
      return { ...prevState, [position]: `${Input.bannerPosition}px` };
    });
  }, [Input.bannerPosition]);

  const positionBanner = (side) => {
    setposition(side);
    setbanner();
  };
  const resetData = () => {
    removeFile(0);
    setImageToCrop(undefined);
    setFiles([]);
    setInput({
      bannerName: "",
      promotionHeader: "",
      promotionText: "",
      promotionDescription: "",
      bannerPosition: "",
      urlCode: "",
      bannerType: "",
    });
    setbanner({
      left: "",
      right: "",
      top: "",
      bottom: "",
    });
    setposition("left");
  };
  return (
    <div className="single-feature-modal">
      <Modal.Header closeButton>
        <h3 className="single-feature-heading">Upload {type}</h3>
      </Modal.Header>
      <Row>
        <Col xs={12} lg={6}>
          <div className="tagarea">
            <Form.Label className="mb-2">
              {type} Name <sup>*</sup>{" "}
            </Form.Label>
            <Form.Group controlId="formBasicEmail">
              <Form.Control type="text" className="shadow-none" name="bannerName" value={Input.bannerName} onChange={onChangeHandler} required />
            </Form.Group>
          </div>
          <div className="tagarea">
            <Form.Label className="mb-2">
              Promotion Header <sup>*</sup>
            </Form.Label>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                type="text"
                className="shadow-none"
                name="promotionHeader"
                value={Input.promotionHeader}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>
          </div>
          <div className="tagarea">
            <Form.Label className="mb-2">
              Promotion Text <sup>*</sup>
            </Form.Label>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                type="text"
                className="shadow-none"
                name="promotionText"
                value={Input.promotionText}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>
          </div>
        </Col>

        <Col xs={12} lg={6} className="feature-col-2">
          <div>
            <Form.Label>
              Promotion Description <sup>*</sup>{" "}
            </Form.Label>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                className="shadow-none"
                name="promotionDescription"
                value={Input.promotionDescription}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="tagarea">
              <Form.Label className="mb-2">
                Promotion Align <sup>*</sup>
              </Form.Label>
              <div className="d-flex justify-content-start mb-3" style={{ gap: "10px" }}>
                <div className={`align-btn ${position === "left" ? "active" : ""}`} onClick={() => positionBanner("left")}>
                  <i className="fas fa-hand-point-left"></i>
                </div>
                <div className={`align-btn ${position === "right" ? "active" : ""}`} onClick={() => positionBanner("right")}>
                  <i className="fas fa-hand-point-right"></i>
                </div>
                <div className={`align-btn ${position === "top" ? "active" : ""}`} onClick={() => positionBanner("top")}>
                  <i className="fas fa-hand-point-up"></i>
                </div>
                <div className={`align-btn ${position === "bottom" ? "active" : ""}`} onClick={() => positionBanner("bottom")}>
                  <i className="fas fa-hand-point-down"></i>
                </div>
              </div>
            </div>
            <div>
              <Form.Label>
                Banner {language.lang.position} <sup>*</sup>
              </Form.Label>
              <div style={{ maxWidth: "100px" }}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="number"
                    className="shadow-none"
                    name="bannerPosition"
                    value={Input.bannerPosition}
                    onChange={onChangeHandler}
                    required
                    min="0"
                    max={((position === "left" || position === "right") && maxwidth) || ((position === "top" || position === "bottom") && maxheight)}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <Form.Label>
                URL Code <sup>*</sup>
              </Form.Label>
              <div style={{ maxWidth: "180px" }}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="text" className="shadow-none" name="urlCode" value={Input.urlCode} onChange={onChangeHandler} required />
                </Form.Group>
              </div>
            </div>
            <div>
              <Form.Label>
                Banner Type <sup>*</sup>
              </Form.Label>
              <div style={{ maxWidth: "300px" }}>
                <Select value={Input.bannerType} onChange={onSelectHandler} name="bannerType" style={{ width: "100%" }}>
                  <Option value="image">Image</Option>
                  <Option value="video">Video</Option>
                </Select>
              </div>
            </div>
          </div>
        </Col>
        {stateFiles.length === 0 && (
          <div className="cont">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <FaCloudUploadAlt className="upload--icon" />
              <p>Drag and Drop</p>
              <div className="or-block">
                <div className="or-line"></div>
                <div className="or-text">or</div>
                <div className="or-line"></div>
              </div>
              <button type="button" onClick={open} className="browse-btn">
                Browse
              </button>
            </div>
          </div>
        )}
        {stateFiles.length > 0 && (
          <div>
            <div className="position-relative d-flex justify-content-center">
              <div className={"banner " + position} style={banner}>
                <div className="banner-header">{Input.promotionHeader}</div>
                <div className="banner-text">{Input.promotionText}</div>
                <div className="banner-description">{Input.promotionDescription}</div>
                <div className="d-flex justify-content-end w-100 pr-2">
                  <button className="btn view-btn">{language.lang.view}</button>
                </div>
              </div>
              <div className="position-absolute" style={{ right: "20px", color: "#fff" }} onClick={resetData}>
                <i className="fas fa-times"></i>
              </div>
              {!crop && (
                <img
                  ref={(el) => {
                    if (!el) return;
                    setmaxwidth(el.getBoundingClientRect().width - 400);
                    setmaxheight(el.getBoundingClientRect().height - 148);
                  }}
                  className="w-100"
                  style={{ objectFit: "cover", height: "350px" }}
                  src={imageToCrop}
                  alt="banner"
                />
              )}
              {crop && <ImageCropper imageToCrop={imageToCrop} onImageCropped={(croppedImage) => setCroppedImage(croppedImage)} />}
            </div>
            <div className="pt-3"></div>
            <div className={"mt-3 text-right d-flex " + (crop ? "justify-content-between" : "justify-content-end")} style={{ gap: "20px" }}>
              {/*!crop && <div className="shadow-none btn btn-primary create-btn">Preview</div> */}
              {!crop && (
                <div className="shadow-none btn btn-primary create-btn align-self-end" onClick={() => setcrop(true)}>
                  Crop
                </div>
              )}
              {crop && (
                <div
                  className="shadow-none btn btn-primary create-btn"
                  onClick={() => {
                    setImageToCrop(croppedImage);
                    setcrop(false);
                  }}
                >
                  Apply
                </div>
              )}
              {crop && (
                <div className="shadow-none btn btn-primary create-btn" onClick={() => setcrop(false)}>
                  {language.lang.cancel}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="my-3 text-right d-flex justify-content-center align-items-center">
          {loading ? (
            <Loading />
          ) : (
            <button className="shadow-none btn btn-primary create-btn" onClick={uploadEmiratesID}>
              Upload
            </button>
          )}
        </div>
      </Row>
    </div>
  );
};

export default UploadBanner;
