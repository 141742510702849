import React from "react";
import videoImg from "../assets/images/fit.png";

const VideoCard = ({data}) => {
  let image = data &&  data.thumbnail.find(e => e.galleryItemInfo.type === "image/png");
  return (
    <div className="video-card-tiles d-flex flex-column justify-content-center align-items-center">
    {/* <div className="duration">30 min</div> */}
    <div className="video-card-thumbnail"><img className="thumb-img py-0 mb-3" src={image ? image.galleryItemInfo.url : videoImg} alt="img-thumb" /></div>
   <div className="video-card-headline my-2"><p className="title d-flex justify-content-start w-100 card-tiles-font" >{data && data.featureSection && data.name}</p></div>
   </div>
  );
};

export default VideoCard;