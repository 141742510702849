import { useEffect, useState } from "react";
import { getSubscriptionPlan } from "../services/CustomerNetworkService";

const useSubscriptionPlan = ({ categryID = 1, moduleID = 3 }) => {
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState(null);

  useEffect(() => {
    (async () => {
      const res = await getSubscriptionPlan({ categryID, moduleID });
      setLoading(false);
      setPlan(res);
    })();
  }, [categryID, moduleID]);

  return [loading, plan];
};

export default useSubscriptionPlan;
