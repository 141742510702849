import Switch from "../../ReusableComponents/Switch/Switch";

// Modules import
import { useEffect, useState } from "react";
import "./constant";
import { notificationEnum } from "./constant";
import { useSelector } from "react-redux";

const FitnessBuddyNotification = () => {
  const language = useSelector((state) => state.language);
  const userDetails = useSelector(
    (state) => state.patientDetails.userDetails || {}
  );
  const [vaccinatedValue, setVaccinatedValue] = useState(
    userDetails.isVaccinated
  );
  const [newMatchesValue, setNewMatchesValue] = useState(false);
  const [messages, setMessages] = useState(false);
  const [messageLikes, setMessageLikes] = useState(false);
  const [superLikes, setSuperLikes] = useState(false);
  const [inAppVibrations, setInAppVibrations] = useState(false);
  const [inAppSounds, setInAppSounds] = useState(false);

  const setDefaultNotification = (allowedNotifications) => {
    allowedNotifications.forEach((notificationName) => {
      if (notificationName === "newMatches") setNewMatchesValue(true);
      else if (notificationName === "messages") setMessages(true);
      else if (notificationName === "messageLikes") setMessageLikes(true);
      else if (notificationName === "superLikes") setSuperLikes(true);
      else if (notificationName === "inAppVibrations") setInAppVibrations(true);
      else if (notificationName === "inAppSounds") setInAppSounds(true);
    });
  };
  useEffect(() => {
    if (userDetails.allowedNotifications.length !== 0) {
      const allowedNotifications = userDetails.allowedNotifications.map(
        (value) => {
          return notificationEnum[value];
        }
      );
      setDefaultNotification(allowedNotifications);
    }
  }, []);
  return (
    <div className=" fb-notifi-container col md-6">
      <div className="vaccinated">
        <label>{language.lang.vaccinated}</label>
        <Switch
          isOn={vaccinatedValue}
          handleToggle={() => {
            setVaccinatedValue(!vaccinatedValue);
          }}
          name={"isVaccinated"}
          id={`react-switch-vaccinated`}
        />
      </div>
      <div className="notifi-text">{language.lang.notifications}</div>
      <div className="new-matches">
        <label>{language.lang.newMatches}</label>
        <Switch
          isOn={newMatchesValue}
          handleToggle={() => setNewMatchesValue(!newMatchesValue)}
          name={"newMatches"}
          id={`react-switch-newMatches`}
        />
      </div>
      <div className="messages">
        <label>{language.lang.messages}</label>
        <Switch
          isOn={messages}
          handleToggle={() => setMessages(!messages)}
          name={"messages"}
          id={`react-switch-messages`}
        />
      </div>
      <div hidden className="message-likes">
        <label>{language.lang.messageLikes}</label>
        <Switch
          isOn={messageLikes}
          handleToggle={() => setMessageLikes(!messageLikes)}
          name={"messagesLikes"}
          id={`react-switch-messageLikes`}
        />
      </div>
      <div hidden className="super-likes">
        <label>{language.lang.superLikes}</label>
        <Switch
          isOn={superLikes}
          handleToggle={() => setSuperLikes(!superLikes)}
          name={"superLikes"}
          id={`react-switch-superLikes`}
        />
      </div>
      <div hidden className="in-app-vibrations">
        <label>{language.lang.inAppVibrations}</label>
        <Switch
          isOn={inAppVibrations}
          handleToggle={() => setInAppVibrations(!inAppVibrations)}
          name={"inAppVibrations"}
          id={`react-switch-inAppVibrations`}
        />
      </div>
      <div hidden className="in-app-sounds">
        <label>{language.lang.inAppSounds}</label>
        <Switch
          isOn={inAppSounds}
          handleToggle={() => setInAppSounds(!inAppSounds)}
          name={"inAppSounds"}
          id={`react-switch-inAppSounds`}
        />
      </div>
    </div>
  );
};
export default FitnessBuddyNotification;
