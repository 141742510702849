import React, { useReducer, useEffect, useState } from "react";
import BackButton from "../../components/BackButton";
import NotificationsButton from "../../components/NotificationsButton";
import ProfileCircleButton from "../../components/ProfileCircleButton";
import DoctorCard from "../../components/DoctorCard";
import JoinConsultationPatientInfoCard from "../../components/JoinConsultationPatientInfoCard";
import PatinetAppointmentDetailsCard from "../../components/PatinetAppointmentDetailsCard";
import { useSelector } from "react-redux";
import reducer, { initState } from "./reducer";
import {
  updateConsultationStatusAction,
  startLoadingAction,
  stopLoadingAction,
} from "./actions";
import Loading from "../../components/Loading";
import { agoraRoute } from "../../routes";
import {
  getAppointmentDetails,
  isMobileOrTabletBrowser,
  showErrorNotification,
} from "../../utility/Helpers";
import {
  currentAppointmentDetailsKey,
  doctorJoinForConsultationAppointment,
} from "../../utility/constants";
import {
  setCurrentAppointmentAction,
  setPractitionerAvailability,
} from "../../redux/practitioner/actionCreators";
import MobileAppPropmtModal from "../../components/MobileAppPropmtModal";
import TopMenu from "../../components/CommonControls/TopMenu";

const DoctorJoinForConsultation = ({ history }) => {
  // const appointment = useSelector(
  //   (state) => state.practitioner.currentAppointment
  // );
  const routeNextPatient = history.location.state.nextPatient;
  const [showMobilePromptModal, setShowMobilePromptModal] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const [state, dispatch] = useReducer(reducer, initState);
  const appointment =
    useSelector((state) => state.practitioner.patientsQueue.nextPatient) ||
    routeNextPatient;
  const { userData } = useSelector((state) => state.auth);
  appointment.appointmentId = appointment.id;
  console.log(appointment);
  useEffect(() => {
    if (appointment.appointmentId) {
      localStorage.setItem(
        doctorJoinForConsultationAppointment,
        JSON.stringify(appointment)
      );
    }
    // if (!appointment.appointmentId) {
    //   history.replace(dashboardRoute.path);
    //   return;
    // }
    const storageAppointment =
      JSON.parse(localStorage.getItem(doctorJoinForConsultationAppointment)) ||
      {};
    const appointmentID =
      (appointment && appointment.appointmentId) ||
      storageAppointment.appointmentId;
    dispatch(startLoadingAction());
    getAppointmentDetails(appointmentID)
      .then((value) => {
        setAppointmentDetails(value);
      })
      .finally(() => dispatch(stopLoadingAction()));
    return () => {
      localStorage.removeItem(doctorJoinForConsultationAppointment);
    };
  }, []);

  const startCallPressed = async () => {
    if (!appointmentDetails || Object.values(appointmentDetails).length === 0)
      return;
    dispatch(setPractitionerAvailability(2));
    dispatch(startLoadingAction());
    dispatch(setCurrentAppointmentAction(appointmentDetails));
    const data = await updateConsultationStatusAction(
      appointment.appointmentId || routeNextPatient.id
    );
    dispatch(stopLoadingAction());
    if (data && data.id) {
      localStorage.setItem(
        currentAppointmentDetailsKey,
        JSON.stringify(appointmentDetails)
      );
      joinAppointmentCall();
    } else {
      showErrorNotification();
    }
  };

  const joinAppointmentCall = () => {
    if (isMobileOrTabletBrowser()) {
      setShowMobilePromptModal(true);
    } else {
      history.replace(agoraRoute.path);
    }
  };

  const onMobileModalPromptClose = () => {
    setShowMobilePromptModal(false);
    history.replace(agoraRoute.path);
  };

  const language = useSelector((state) => state.language);

  return (
    <div className="doctor-join-for-consultation mx-4 mb-4">
      <MobileAppPropmtModal
        show={showMobilePromptModal}
        onCloseClicked={onMobileModalPromptClose}
      />
      <section className="row">
        <TopMenu
            shouldHideBackButton={false}
            shouldHideProfileButton={false}
            shouldHideNotificationButton={false}
            shouldHideLogoButton={false}
            pageTitle={<p>{language.lang.join} {language.lang.for_consultation}</p>}
            hideCustomclass={true}
          />
        {/* <div className="col-1">
          <BackButton />
        </div>
        <div className="col-10 d-flex align-items-center">
          <h5 className="page-title">
            {language.lang.join} {language.lang.for_consultation}
          </h5>
          <NotificationsButton className="ml-auto" />
          <ProfileCircleButton />
        </div> */}
      </section>
      <section className="row">
        <div className="col-12">
          <DoctorCard
            imgSrc={userData.profile_photo_url}
            middle=""
            bottom=""
            name={`${userData.name} ${
              userData.surname ? userData.surname : ""
            }`}
          />
        </div>
      </section>
      <section className="row mt-4">
        <div className="col-12 col-lg-7">
          {appointmentDetails && (
            <JoinConsultationPatientInfoCard appointment={appointmentDetails} />
          )}
        </div>
        <div className="col-12 col-lg-5 mt-4 mt-lg-0">
          <PatinetAppointmentDetailsCard appointment={appointmentDetails} />
          {state.loading ? (
            <div className="d-flex justify-content-center mt-4">
              <Loading />
            </div>
          ) : (
            <button
              onClick={startCallPressed}
              className="btn btn-primary join-call-btn mt-4"
            >
              {language.lang.start_consultation_call}
            </button>
          )}
        </div>
      </section>
    </div>
  );
};

export default DoctorJoinForConsultation;
