import React from "react";
import { userRoleSuperAdmin } from "../../utility/constants";
import { useSelector } from "react-redux";

import SideMenu from "../../components/SideMenu";
import SideMenuAdmin from "../../components/SideMenuAdmin";

const SideBar = () => {
  const role = useSelector((state) => {
    if (state.auth.userData) {
      return state.auth.userData.role;
    }
    return null;
  });

  if (role === userRoleSuperAdmin) {
    return <SideMenuAdmin />;
  }
  return <SideMenu />;
};

export default SideBar;
