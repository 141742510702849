import { useState } from "react";
import AuthBase from "../../components/AuthBase";
import { clientSignInRoute, signUpRoute } from "../../routes";
import { isValidEmail, showErrorNotification } from "../../utility/Helpers";
import axios from "../../services/Axios";
import Loading from "../../components/Loading";
import VerifyOTPModal from "./VerifyOTPModal";
import { useSelector } from "react-redux";

const ForgotPassword = ({ history }) => {
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const createAccountPressed = () => {
    history.replace(signUpRoute.path);
  };

  const loginPressed = () => {
    history.push(clientSignInRoute.path);
  };

  const submitPressed = async () => {
    const emailError = validateEmail();
    if (emailError) {
      setErrorMessage(emailError);
      return;
    }
    setLoading(true);
    setErrorMessage("");
    try {
      const res = await axios.post("/requestForgotPassword", {
        email,
      });
      if (res.data.response === "OTP has been sent to your Email") {
        if (!showOTPModal) setShowOTPModal(true);
      } else if (res.data.error) {
        showErrorNotification(res.data.error);
      } else {
        showErrorNotification();
      }
    } catch (e) {
      console.log("--- forgotPassword submit error");
      console.log(e);
      showErrorNotification(e.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = () => {
    if (!email) {
      return "Please enter your email address";
    }
    return isValidEmail(email);
  };

  // const onModalClosePressed = () => {
  //   setShowOTPModal(false);
  // };

  const language = useSelector((state) => state.language);
  return (
    <AuthBase className="forgot-password">
      <h4 className="welcome-text d-flex justify-content-center mb-5">
        {language.lang.welcome}
      </h4>
      <p className="text-center text-secondary font-weight-bold mb-5">
        {language.lang["enter-registered-email-address"]}
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitPressed();
        }}
      >
        <input
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          name="email"
          className="neumorphic-input w-100"
          placeholder={language.lang.email + " Address"}
          type="email"
        />
      </form>
      {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}
      <div className="d-flex justify-content-center">
        {loading ? (
          <Loading className={"mt-3"} />
        ) : (
          <button
            onClick={submitPressed}
            className="btn btn-primary btn-submit mt-5"
          >
            {language.lang.submit}
          </button>
        )}
      </div>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <span className="m-0">{language.lang["new-here-?"]}</span>
        <button type="submit" className="btn" onClick={createAccountPressed}>
          <span className="text-logo-color">
            {language.lang.createAnAccount}
          </span>
        </button>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <span className="m-0">{language.lang.alreadyAMember}</span>
        <button type="submit" className="btn" onClick={loginPressed}>
          <span className="text-logo-color">{language.lang.login}</span>
        </button>
      </div>
      <VerifyOTPModal
        resendLoading={loading}
        onResendPressed={submitPressed}
        show={showOTPModal}
        onhide={setShowOTPModal}
        email={email}
      />
    </AuthBase>
  );
};

export default ForgotPassword;
