export const validEmailErrorMsg = "enterValidEmailAddress";
export const passwordLengthErrorMsg = "passwordMustbeMoreCharacter";
export const invalidUserMsg = "Invalid user credentials";
export const invalidCredentialsErrorMsg = "Invalid credentials";
export const invalidCorporateCredentialsErrorMsg = "You have entered an invalid Corporate ID. Please try again.";
export const isLoggedInStorageKey = "loggedIn";
export const platformType = "Web";
export const tokenStorageKey = "token";
export const userDataStorageKey = "userData";
export const refreshTokenStorageKey = "refreshToken";
export const tokenExpiryTimeStorageKey = "tokenExpiryTime";
export const CameraPermissionNotGranted = "Camera permission is not granted.";
export const PermissionsGranted = "Camera and Audio Permissions are granted";
export const PermissionsDisabled = "Camera and Audio Permissions are disabled";
export const CameraAndAudioPermissionNotGranted = "Camera and Audio permissions are not granted.";
export const defaultErrorMsg = "Something went wrong.";
export const localStorageErrorMsg = "Sorry, your browser does not support Web Storage...";
export const mediaNotSupportedErrorMsg = "User Media is not supported on your browser";
export const storageTypeImage = "image";
export const storageTypeVideo = "video";
export const storageTypeAudio = "audio";
export const storageTypeDocument = "document";
export const storageTypeAnimation = "animaiton";
export const categoryMentalHealth = "MentalHealth";
export const categoryMealPlan = "MealPlan";
export const categorySleep = "Sleep";
export const categoryMeditation = "Meditation";
export const categoryAir = "Air";
export const categoryTravel = "Travel";
export const categoryErgonomics = "Ergonomics";
export const categoryFitness = "Fitness";
export const categoryLifeStyle = "LifeStyle";
export const fileTypeImage = "images";
export const fileTypeBanner = "banners";
export const fileTypeProfile = "profiles";
export const fileTypeVideo = "videos";
export const fileTypeAnimation = "animations";
export const fileTypeMedicalReport = "medicalreports";
export const fileTypePrescription = "prescriptions";
export const fileTypeAudio = "audios";
export const userTypeTenant = "Tenant";
export const userTypeAdminLiveSupport = "Admin/LiveSupport";
export const userTypeCounsellor = "Counsellor";
export const userTypePatient = "Patient";
export const userTypeUser = "User";
export const userTypeNutritioner = "Nutritioner";
export const userTypeGymTrainer = "Gym Trainer";
export const userTypeEndUser = "End User";
export const userTypeNurse = "Nurse";
export const userRoleSuperAdmin = "aemm_admin";
export const userRoleDoctor = "aemm_counsellor";
export const userRoleClient = "aemm_client";
export const userRoleClinic = "aemm_clinic";
export const loginRequiredMsg = "Please login first";
export const loginToBookMsg = "Please Sign In to Book an appointment";
export const isOnlineStorageKey = "isOnline";
// export const agoraAppId = "53d0e611783b4886b144150e113d70c9";
export const agoraAppId = "656fd4b8d34440e1a3e72b58e86539be";//New One
export const acceptRejectNotificationType = "AcceptReject";
export const emailAddressTakenErrorMsg = "emailAddressAlreadyTaken";
export const passwordContainLowerCaseLetterErrorMsg = "passwordMustContainLowerCaseLetter";
export const passwordContainUpperCaseLetterErrorMsg = "passwordMustContainUpperCaseLetter";
export const passwordContainNumberErrorMsg = "passwordMustContainNumber";
export const passwordContainSpecialCharacterErrorMsg = "passwordMustContainSpecialCharacter";
export const doctorAcceptedCallNotificationType = "DoctorAcceptedCall";
export const doctorStartedCallNotificationType = "DoctorStartedCall";
export const prescriptionReadyNotificationType = "prescriptionReadyNotification";
export const prescriptionAppointmentKey = "prescriptionAppointment";
export const currentAppointmentDetailsKey = "currentAppointmentDetails";
export const clientWalkinConfirmationAppointmentKey = "clientWalkinConfirmation";
export const appointmentTypeWalkin = "Walk In";
export const appointmentTypeScheduled = "scheduled";
export const scheduledAppointment = "Scheduled Appointment";
export const paymentModuleTypeScheduledAppointment = "Scheduled Appointment";
export const notificationTypeAcceptReject = "AcceptReject";
export const doctorJoinForConsultationAppointment = "doctorJoinForConsultaitonAppointment";
export const cardPaymentType = "card";
export const membershipPaymentType = "membership";
export const paymentAmountsKey = "paymentAmounts";
export const walkinAmountKey = "amount_walkin_appointment";
export const scheduledAmountKey = "amount_scheduled_appointment";
export const userTypeTest = "test";
export const userTypeProduction = "production";
export const fitnessnBuddyLogin = "Please login to use fitness buddy";
export const registerSessionLogin = "Please login to use fitness buddy";
export const liveSessionPaymentModuleType = "Live Session";
export const calendarColorTokensMap = {
  TOK_YELLOW: "#fdd835",
  TOK_GREEN: "#4caf50",
  TOK_ORANGE: "#ff9800",
  TOK_INDIGO: "#3f51b5",
  TOK_GREY: "#9e9e9e",
  TOK_RED: "#f44336",
  TOK_BLUE: "#2196f3",
};
export const sessionJoinedByUserStatusID = 33;
export const sessionStartedByHostStatusID = 32;
export const tawkPropertyID = "62347cd8a34c2456412bb084";
export const tawkID = "1fuegfbl1";
export const whatsAppNumber = "+971503062536";
export const sessionHostMuteAllControlID = 1;
export const sessionHostUnmuteAllControlID = 2;
// Critical River, Varun Mishra, Currency Bug for Iraq
// IQD currency for selected country IRAQ
// export const regionCurrencyMap = { 1: "AED", 2: "USD", 3: "EGP" };
export const regionCurrencyMap = { 1: "AED", 2: "IQD", 3: "EGP" };
export const regionIDsMap = { 1: "UAE", 2: "IRAQ", 3: "EGYPT" };
export const sessionStartedNotificationType = "EventCallStarted";
export const sessionEndedNotificationType = "EventCallEnded";
export const signupUserId = "signupUserId";
export const dietitianRoleID = 7;
export const iOSBundleID = "com.doctorsGroup.mindmina";
export const androidPackageName = "com.doctors.mindmina";
export const firebaseDomain = "www.izi.health";
export const emailSigninStorageKey = "signinEmail";
export const emailSigninCorporateIDKey = "corporateID";
// export const dynamicLinkDomain = "izi.health";
export const approvedFoodItemStatusID = 1;
export const unapprovedFoodItemStatusID = 2;
export const customIngredientTypeID = -1;
export const servingUnit = "serving";
export const gramsUnit = "grams";
export const subscriptionPaymentModule = "Subscription";
export const subscriptionValidityUnitTwoLetterShort = {
  M: "mo",
  Y: "yr",
};
export const subscriptionValidityUnitText = {
  M: "monthly",
  Y: "yearly",
  D: "daily",
  W: "weekly",
};
export const subscriptionValidityUnitName = {
  M: "month",
  Y: "year",
  D: "day",
  W: "week",
};
