import React from 'react';
import ProfileCircleButton from "../components/ProfileCircleButton";
import NotificationsButton from "../components/NotificationsButton";
import BackButton from "../components/BackButton";
import SlotArrangement from './SlotArrangement';


import { Row, Container } from 'react-bootstrap';

const SlotManagement = () => {

    return (
        <div className="consultation create-prescription">
        <div className="dashboard-book-consultation">
                <div className="col-12 notifications-and-profile mt-3 mt-md-0 justify-content-between mx-2">
                <div>
                    <BackButton />
                    <span className="consultation-heading">Slot Management</span>
                </div>
                </div>
        </div>
        <Container fluid className="mt-1">
            <Row className="slot-management">
                <SlotArrangement />
            </Row>
            
        </Container>
        
        </div>
    )
}

export default SlotManagement;
