import actions from "./actions";
import axios from "../../services/Axios";
import store from "../store";
import {
  isOnlineStorageKey,
  userDataStorageKey,
} from "../../utility/constants";
import { showErrorNotification } from "../../utility/Helpers";
import { settings } from "../../config";

export const setCalendarLoading = ({ loading = true }) => {
  return {
    type: actions.SET_CALENDAR_LOADING,
    payload: loading,
  };
};

export const setPractitionerSelectedSlotAndViewSlotAction = ({
  viewSlot,
  selectedSlot,
}) => {
  return {
    type: actions.SET_PRACTITIONER_SELECTED_SLOT_AND_VIEW,
    payload: { viewSlot, selectedSlot },
  };
};

export const updatePatientPrescriptionAction = (formData) => {
  return {
    type: actions.UPDATE_PATIENT_PRESCRIPTION,
    payload: formData,
  };
};

export const addPatientRequestAction = (payload) => {
  return {
    type: actions.NEW_PATIENT_REQUEST,
    payload,
  };
};

export const addPendingWalkinRequestsAciton = (requests) => {
  return {
    type: actions.ADD_PENDING_WALKIN_REQUESTS,
    payload: requests,
  };
};

export const setCurrentAppointmentAction = (appointment) => {
  return {
    type: actions.SET_CURRENT_APPOINTMENT,
    payload: appointment,
  };
};

export const loadingAction = () => {
  return { type: actions.LOADING };
};

export const stopLoadingAction = (appointment) => {
  return { type: actions.STOP_LOADING, payload: appointment };
};

export const acceptWalkinRequestAction = (clientDetails, i) => {
  return async (dispatch) => {
    const { id } = store.getState().auth.userData;
    dispatch(loadingAction());
    let res;
    try {
      res = await axios.post("/acceptWalkinAppointment", {
        practitionerId: id,
        patientId: clientDetails.userId,
        appointmentId: clientDetails.appointmentId,
      });
      // console.log("res.data", res.data);
      dispatch({
        type: "REMOVE_PATIENT_FROM_NOTIF_ARR_BY_ID",
        payload: clientDetails.appointmentId,
      });
      return res.data;
    } catch (e) {
      if (
        e.response.data.error ===
        "You have already accepted the maximum number of requests"
      ) {
        showErrorNotification(e.response.data.error);
      }
      res = {};
    } finally {
      const appointmentDetails = res.data;
      if (appointmentDetails) {
        appointmentDetails.clientDetails = clientDetails;
        const nextPatient =
          store.getState().practitioner.patientsQueue.nextPatient;
        if (nextPatient && Object.values(nextPatient).length !== 0) {
          appointmentDetails.appointmentId = nextPatient.id;
        }
        dispatch(stopLoadingAction(appointmentDetails));
      } else {
        dispatch(stopLoadingAction({}));
      }
    }
  };
};

export const setPractitionerAvailability = (statusId) => {
  return async (dispatch) => {
    const { id } = store.getState().auth.userData;
    dispatch(loadingAction());
    let res;
    try {
      res = await axios.post("/updatePractitionerStatus", {
        practitionerId: id,
        statusId: statusId,
      });
      // console.log("The res", res);
      return res.data;
    } catch (e) {
      showErrorNotification(e.response.data.error);
      res = {};
    } finally {
      dispatch(stopLoadingAction({}));
    }
  };
};

const setStatusOnlineAction = () => {
  return async (dispatch) => {
    dispatch({
      type: actions.SET_STATUS_ONLINE,
    });
    const success = await setPractitionerStatus(true);
    if (success) {
      localStorage.setItem(isOnlineStorageKey, true);
      return;
    }
    dispatch({
      type: actions.SET_STATUS_OFFLINE,
    });
  };
};

export const updatePractitionerDashboardData = (appointmentDetails) => {
  return {
    type: actions.SET_QUEUE_OF_PATIENTS,
    payload: appointmentDetails,
  };
};

const setStatusOfflineAction = () => {
  return async (dispatch) => {
    dispatch({
      type: actions.SET_STATUS_OFFLINE,
    });
    const success = await setPractitionerStatus(false);
    if (success) {
      localStorage.setItem(isOnlineStorageKey, false);
      return;
    }
    dispatch({
      type: actions.SET_STATUS_ONLINE,
    });
  };
};

const setPractitionerStatus = async (isOnline) => {
  try {
    const userID = store.getState().auth.userData.id;
    const res = await axios.post("/toggleOnlineStatus", {
      practitionerId: userID,
      isOnline: `${isOnline}`,
    });
    return res.data.response[0].id != null;
  } catch (e) {
    console.log("--- setPractitionerStatus error");
    console.log(e);
    debugger;
    return false;
  }
};

export const updateConsultationStatusAction = async (
  appointmentID,
  statusId = "14",
  isCallEndedBeforeTime = false
) => {
  if (!appointmentID) return null;
  try {
    const userData = JSON.parse(localStorage.getItem(userDataStorageKey));
    const res = await axios.post("/updateConsultationStatus", {
      appointmentId: appointmentID,
      statusId: statusId,
      isCallEndedBeforeTime,
      userId: userData.id,
      userRole: userData.role,
    });
    return res.data.response;
  } catch (e) {
    debugger;
    console.log("--- update appointment error");
    console.log(e);
    return null;
  }
};

export const setPractitionerCalendarEvents = (
  pracID,
  startDate,
  endDate,
  scheduleID
) => {
  return async (dispatch) => {
    dispatch(setCalendarLoading({ loading: true }));
    const res = await axios.get(
      `${settings.apiBaseURL}/getPractitionerAllAppointments?startDate=${startDate}&endDate=${endDate}&practitionerId=${pracID}&schedulerType=${scheduleID}`
    );
    // console.log("events response: ", res);
    const data = res.data && res.data.response;
    if (!data) return;
    dispatch({
      type: actions.SET_ALL_APPOINTMENTS_OF_PRACTITIONER,
      payload: data,
    });
  };
};

export const setPractitionerDetails = (id) => {
  return async (dispatch) => {
    const res = await axios.get(
      `${settings.apiBaseURL}/getPractitionerDetails?practitionerId=${id}`
    );
    dispatch({
      type: actions.SET_PRACTITIONER_DETAILS,
      payload: res ? res.data?.response : null,
    });
    return res ? res.data?.response : null;
  };
};

export const setPractitionerViewType = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_PRACTITIONER_VIEW_TYPE,
      payload: payload,
    });
  };
};
export { setStatusOnlineAction, setStatusOfflineAction };
