import BackButton from "../components/BackButton";
import ProfileCircleButton from "../components/ProfileCircleButton";
import NotificationsButton from "../components/NotificationsButton";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

const DisputeCenter = () => {
  const language = useSelector((state) => state.language);
  return (
    <div className="dispute-center">
      <div className="dashboard-book-consultation">
        <div className="col-12 notifications-and-profile mt-3 mt-md-0 justify-content-between mx-2">
          <div className="book-back-btn">
            <BackButton />
            <span className="consultation-heading">Settings</span>
          </div>
          <div style={{ paddingRight: "15px", display: "flex" }}>
            <NotificationsButton />
            <ProfileCircleButton />
          </div>
        </div>
      </div>

      <h5>Dispute Center</h5>

      <Row className="disput-center-row">
        <Col xs={12} md={8} className="dispute-center-col1">
          <h3 className="support-tickets-heading">Support Tickets</h3>

          <div className="status-div">
            Status <button className="open-btn">Open</button>
          </div>

          <p className="ticket-id">Ticket ID: #1487</p>

          <div className="line-div">
            <p>
              I'm having problems with my video class with Doctor on 07/09/21
            </p>
            <p>
              Mar 06
              <input type="checkbox" style={{ marginLeft: "5px" }} />
            </p>
          </div>

          <div className="complaint-1">
            <div className="complaint-1-div">
              <div className="rounded-circle--profile">FS</div>
              <div>
                <h3 className="complaint-1--heading">Fletch Skinner</h3>
                <p className="complaint-1--para">
                  Hi, Could you help me with a thing?
                </p>
              </div>
            </div>
            <div className="complaint-1--description">
              <p className="para1">
                Even the all-powerful Pointing has no control about the blind
              </p>
              <p className="para2">
                A small river named Duden flows by their place and supplies it
                with the necessary regelilia.
              </p>
              <p className="para3">
                It is a paradisematic country in which roasted parts of
                sentences fly into your mouth.
              </p>
              <p className="para4">
                What happened with the four missing items ?
              </p>
            </div>
          </div>

          <div className="border-thick-line">
            <div className="line-div-2">
              <div className="rounded-circle--profile">DL</div>
              <div>
                <h3 className="complaint-1--heading">Dominic L. Ement</h3>
              </div>
            </div>

            <p className="para5">
              Hi, Sorry for the delay we was in helidays, please send me your
              listed question.
            </p>
          </div>

          <div
            className="complaint-1"
            style={{ borderBottom: "1px solid #edeff1", paddingBottom: "20px" }}
          >
            <div className="complaint-1-div">
              <div className="rounded-circle--profile">FS</div>
              <div>
                <h3 className="complaint-1--heading">Fletch Skinner</h3>
                <p className="complaint-1--para">
                  Hi, Could you help me with a thing?
                </p>
              </div>
            </div>
            <div className="complaint-1--description">
              <p className="para1">
                What's happened to me? He thought. It wasn't a dream.
              </p>
              <p className="para2">
                A picture that he had recently cut out of an illustrated
                magazine and housed in a nice, glided frame. It showed a lady
                fitted out with a fur hat and fur boa who sat upright, raising a
                heavy.
              </p>
            </div>
          </div>

          <textarea
            className="comment-textarea"
            placeholder="Type the comment..."
          ></textarea>
        </Col>

        <Col xs={12} sm={6} md={2} className="dispute-center-col2">
          <h6>{language.lang.requested_by}</h6>
          <div className="rounded-circle--profile">FS</div>
          <h3 className="dispute-center-col2--heading">Fletch Skinner</h3>

          <div className="my-2 text-center">
            <h6 style={{ fontSize: "14px", margin: "4px" }}>Created by</h6>
            <span className="timing">06 March 2018, 12.30 PM</span>
          </div>

          <div className="my-2 text-center">
            <h6 style={{ fontSize: "14px", margin: "4px" }}>Last Activity</h6>
            <span className="timing">07 March 2018, 05:45 PM</span>
          </div>

          <p>Assigned to</p>
          <div className="rounded-circle--profile">DL</div>
          <h4 className="dispute-center-col2--heading">Dominic L. Ement</h4>

          <button className="book-doc-btn">{language.lang.book} Doctor</button>

          <button className="mark-solve-btn">Mark as Solved</button>

          <div className="add-file">
            <p className="add-file-corp">Add Corporate Logo file</p>
            <p className="add-file-drop">Or Drop File here</p>
            <i className="far fa-paperclip paper-pos"></i>
          </div>

          <button className="submit-btn">Submit</button>
        </Col>

        <Col xs={12} sm={6} md={2} className="dispute-center-col3">
          <div className="rounded-circle--profile blue-color-profile">FS</div>
          <h6 className="col3--heading">Fletch Skinner</h6>

          <div className="rounded-circle--profile orange-color-profile">FS</div>
          <h6 className="col3--heading">Fletch Skinner</h6>

          <div className="rounded-circle--profile greenish-color-profile">
            FS
          </div>
          <h6 className="col3--heading">Fletch Skinner</h6>

          <div className="rounded-circle--profile neon-color-profile">FS</div>
          <h6 className="col3--heading">Fletch Skinner</h6>

          <div className="rounded-circle--profile yellow-color-profile">FS</div>
          <h6 className="col3--heading">Fletch Skinner</h6>

          <div className="rounded-circle--profile darkblue-color-profile">
            FS
          </div>
          <h6 className="col3--heading">Fletch Skinner</h6>
        </Col>
      </Row>
    </div>
  );
};

export default DisputeCenter;
