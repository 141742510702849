import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import actions from "../../redux/fitnessBuddy/actions";
import { IoCloseCircleSharp } from "react-icons/io5";
import Select from "react-select";
import { BiSearchAlt2 } from "react-icons/bi";

const AddInterestViewModal = (props) => {
  const language = useSelector((state) => state.language);
  const languageType = useSelector(
    (state) => state.language.general.preferredlanguage
  );
  const [addInterestList, setAddInterestList] = useState([]);
  const dispatch = useDispatch();
  const interestList = useSelector(
    (state) => state.patientDetails.interestList
  );
  const onSelectChange = (e) => {
    let item = interestList.find((list) => list.id === Number(e.value));
    if (addInterestList.length === 0) {
      setAddInterestList([item]);
    } else {
      let list = addInterestList.find((list) => list.id === Number(e.value));
      if (list === undefined) {
        setAddInterestList([...addInterestList, item]);
      }
    }
  };
  const handleRemove = (id) => {
    const newList = addInterestList.filter((item) => item.id !== id);
    setAddInterestList(newList);
  };
  const applyInterest = () => {
    dispatch({
      type: actions.UPDATE_USER_INTEREST,
      payload: addInterestList ? addInterestList : [],
    });
    setAddInterestList([]);
    props.onHide();
  };
  const getInterestName = (interest) => {
    if (languageType === "ar") {
      return interest.name_ar;
    } else if (languageType === "ku") {
      return interest.name_ku;
    } else {
      return interest.name;
    }
  };
  return (
    <Modal
      className="add-interest"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         <h6>{language.lang.addInterest}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select
          placeholder={
            <div className=" search-icon">
              <BiSearchAlt2 />
              <div className="select-an-activity"> {language.lang.selectActivity}</div>
            </div>
          }
          components={{
            IndicatorSeparator: () => null,
          }}
          className="select-dropdown"
          hideSelectedOptions={true}
          onChange={onSelectChange}
          controlShouldRenderValue={false}
          options={interestList.map((list) => {
            if (languageType === "ar") {
              return { label: list.name_ar, value: list.id };
            } else if (languageType === "ku") {
              return { label: list.name_ku, value: list.id };
            } else {
              return { label: list.name, value: list.id };
            }
          })} //
        ></Select>
        <div className="show-interest">
          <ul>
            {addInterestList.length !== 0 ? (
              addInterestList.map((interest) => {
                return (
                  <li key={interest.id}>
                    <div style={{ backgroundColor: interest.color_code }}>
                      {getInterestName(interest)}
                    </div>
                    <IoCloseCircleSharp
                      className="remove-interest"
                      onClick={() => {
                        handleRemove(interest.id);
                      }}
                    />
                  </li>
                );
              })
            ) : (
              <></>
            )}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={applyInterest}>{language.lang.apply}</button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddInterestViewModal;
