import { useState, useEffect } from "react";
import { BiCheckCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, AutoComplete } from "antd";
import AuthBase from "../../components/AuthBase";
import Loading from "../../components/Loading";
import MMRadioButton from "../../components/MMRadioButton";
import { phoneSigninVerifyOTPRoute } from "../../routes";
import useCountriesPhoneCodes from "../../customHooks/useCountriesPhoneCodes";
import { setCorporateID, setPhoneAuthConfirmationResult, setPhoneNumber } from "../../redux/firebase/acitonCreators";
import useSendPhoneVerificationCode from "../../customHooks/useSendPhoneVerificationCode";

const LOADING_STATE = "LOADING_STATE";
const CODE_SENT_STATE = "CODE_SENT_STATE";
const INITIAL_STATE = "INITIAL_STATE";

export const PhoneSignin = ( props) => {
// Critical River, Varun Mishra, Routing Issue, user is not redirected to previous page before login pop-up
  const { location } = props;
  let nextRoute = '/';
  let practitioner = {};
  let selectedSlot = {};
  if (location && location.state) {
    nextRoute = location.state.nextRoute;
    practitioner = location.state.practitioner;
    selectedSlot = location.state.selectedSlot;
  }
  const [__, sendPhoneVerificationCode] = useSendPhoneVerificationCode();
  const dispatch = useDispatch();
  const history = useHistory();
  const language = useSelector((state) => state.language.lang);
  const [form] = Form.useForm();
  const [_, countryCodes] = useCountriesPhoneCodes();
  const [isCorporate, setIsCorporate] = useState(false);
  const [uiState, setUIState] = useState(INITIAL_STATE);

  useEffect(() => {
    if (!countryCodes) return;
    const values = form.getFieldsValue();
    const countryCode = values.countryCode;
    if (countryCode) return;
    const uaePhoneCode = countryCodes.find((item) => item.phone_code === "+971");
    form.setFieldsValue({ ...values, countryCode: uaePhoneCode.phone_code });
  }, [countryCodes, form]);

  useEffect(() => {
    dispatch(setCorporateID({ corporateID: "" }));
    dispatch(
      setPhoneNumber({
        phoneCountryCode: "",
        phoneNumber: "",
      })
    );
    dispatch(setPhoneAuthConfirmationResult({ confirmationResult: null }));
  }, [dispatch]);

  const onIsCorporateClicked = () => {
    setIsCorporate(!isCorporate);
  };

  const formValidationSuccess = async (values) => {
    var phone = values.phone;
    if (phone.startsWith("0")) {
      phone = phone.substring(1);
    }
    setUIState(LOADING_STATE);
    const success = await sendPhoneVerificationCode({
      phoneNumber: phone,
      phoneCountryCode: values.countryCode,
      corporateID: values.coporateID,
    });
    if (success) {
      setUIState(CODE_SENT_STATE);
      // Critical River, Varun Mishra, Routing Issue, user is not redirected to previous page before login pop-up
      setTimeout(() => {
        history.push(phoneSigninVerifyOTPRoute.path, {
          nextRoute,
          practitioner,
          selectedSlot
        });
      }, 2000);
    } else {
      setUIState(INITIAL_STATE);
      window.location.reload();
    }
  };

  let content;
  if (uiState === CODE_SENT_STATE) {
    content = (
      <>
        <h6 className="text-center">{language["sent"] ?? "Sent"}</h6>
        <div className="check-icon d-flex justify-content-center align-items-start">
          <BiCheckCircle />
        </div>
      </>
    );
  } else if (uiState === LOADING_STATE) {
    content = (
      <>
        <p className="text-center">{language["sending-your-code"] ?? "Sending your code"}</p>
        <Loading className="mt-5" />
      </>
    );
  } else {
    content = (
      <Form onFinish={formValidationSuccess} form={form} size="large" layout="vertical">
        <Form.Item className="phone-field-container" label={language["enter-your-phone-number"] ?? "Enter your phone number"}>
          <Form.Item
            name="countryCode"
            className="phone-country-code-form-item"
            rules={[
              // {
              //   required: true,
              //   message:
              //     language["please-enter-country-code"] ??
              //     "Please enter your country phone code",
              // },
              () => ({
                validator(field, value) {
                  if (!value?.trim()) return Promise.reject("please-enter-country-code" ?? "please-enter-country-code");
                  if (
                    !countryCodes.some((item) => {
                      return item.phone_code === value;
                    })
                  ) {
                    return Promise.reject("please-enter-valid-country-code" ?? "please-enter-valid-country-code");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <AutoComplete
              placeholder={language["phone-country-code"] ?? "Country code"}
              options={(countryCodes ?? []).map((item) => {
                return {
                  label: `${item.name} ${item.phone_code}`,
                  value: item.phone_code,
                };
              })}
              filterOption={(inputValue, option) => {
                // console.log({ inputValue, option });
                return option.label.toLowerCase().includes(inputValue.trim().toLowerCase());
              }}
            />
          </Form.Item>
          <Form.Item
            rules={[
              () => ({
                validator(field, value) {
                  if (!value) {
                    return Promise.reject(language["please-enter-your-phone-number"] ?? "please-enter-your-phone-number");
                  } else if (value.length < 9) {
                    return Promise.reject(language["please-valid-phone-number"] ?? "please-valid-phone-number");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            name="phone"
            className="phone-number-form-item number-input-remove-arrows"
          >
            <Input type="number" className="border-circle-input" placeholder={language["phone-number"] ?? "Phone number"} />
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <div onClick={onIsCorporateClicked} className="corporate-check-box-container d-flex align-items-center">
            <p>{language["are-you-from-corporate"] ?? "Are you from corporate ?"}</p>
            <MMRadioButton selected={isCorporate} />
          </div>
        </Form.Item>
        {isCorporate && (
          <Form.Item
            name="coporateID"
            rules={[
              {
                required: true,
                message: language["please-enter-corporate-id"] ?? "Please enter your corporate ID",
              },
            ]}
          >
            <Input className="border-circle-input corporate-id-field" placeholder={language["corporate-id"] ?? "Corporate ID"} />
          </Form.Item>
        )}
        <p className="text-secondary text-center mb-4">
          {language["tap-next-to-get-sms-confirmation-from-izi-health"] ?? "Tap next to get an SMS confirmation from IZI Health"}
        </p>
        <div className="d-flex justify-content-center">
          <Button id="btn-phone-signin" className="btn-next" type="primary" htmlType="submit">
            {language.next ?? "Next"}
          </Button>
        </div>
      </Form>
    );
  }

  return (
    <AuthBase className="phone-signin">
      <div id="phone-sign-in-captcha"></div>
      {content}
    </AuthBase>
  );
};
