const actions = {
  GET_PATIENT_DETAILS: "GET_PATIENT_DETAILS",
  GET_INTEREST_DETAILS: "GET_INTEREST_DETAILS",
  UPDATE_USER_INTEREST: "UPDATE_USER_INTEREST",
  UPDATE_ABOUT_INFO: "UPDATE_ABOUT_INFO",
  UPDATE_LOCATION: "UPDATE_LOCATION",
  UPDATE_DISTANCE: "UPDATE_DISTANCE",
  UPDATE_MIN_AGE_RANGE: "UPDATE_MIN_AGE_RANGE",
  UPDATE_MAX_AGE_RANGE: "UPDATE_MAX_AGE_RANGE",
  VIEW_BUDDY_DETAILS:"VIEW_BUDDY_DETAILS",
  UPDATE_ADULT_STATUS:"UPDATE_ADULT_STATUS",
  GET_MATCHED_USERS:"GET_MATCHED_USERS",
  GET_USER_CONVERSATION:"GET_USER_CONVERSATION",
  UPDATE_MATCHED_USER:"UPDATE_MATCHED_USER",
  UPDATE_VIEW_MATCHES_LOADING:"UPDATE_VIEW_MATCHES_LOADING",
  UPDATE_NOTIFICATIONS_SETTINGS:"UPDATE_NOTIFICATIONS_SETTINGS",
  UPDATE_GENDER:"UPDATE_GENDER",
  UPDATE_SETTINGS_LOADING:"UPDATE_SETTINGS_LOADING",
  REMOVE_USER_INTEREST:"REMOVE_USER_INTEREST",
  GET_ALL_THE_MATCHES: "GET_ALL_THE_MATCHES",
  UPDATE_LIVE_CHAT_WINDOW_LOADING:"UPDATE_LIVE_CHAT_WINDOW_LOADING",
  UPDATE_LIVE_CHAT_PAGE_LOADING:"UPDATE_LIVE_CHAT_PAGE_LOADING",
  UPDATE_PROFILE_IMAGE:"UPDATE_PROFILE_IMAGE",
  UPDATE_NEW_PROFILE_IMAGE:"UPDATE_NEW_PROFILE_IMAGE",
  UPDATE_REJECTED_USER_DETAILS:"UPDATE_REJECTED_USER_DETAILS",
  REMOVE_REJECTED_USER_DETAILS:"REMOVE_REJECTED_USER_DETAILS",
  UPDATE_FETCHED_USER_DETAILS:"UPDATE_FETCHED_USER_DETAILS",
  UPDATE_MATCHMEWITH:"UPDATE_MATCHMEWITH",
  UPDATE_IS_NEXT_CONVERSATION_REFRESHED:"UPDATE_IS_NEXT_CONVERSATION_REFRESHED",
  SET_DEFAULT_CHAT_USER:"SET_DEFAULT_CHAT_USER",
};

export default actions;
