import React,{useEffect,useState} from "react";
import Avatar from "../../../assets/images/avatar.png";
import { useSelector } from "react-redux";
import VerifyBrokenImage from "../../VerifyBrokenImage";
const TopMenuUserProfile = () => {
  const { userData } = useSelector((state) => state.auth);
  const [validurl, setvalidurl] = useState(true);
  const handleClick = () => {};

  if (!userData) {
    return <span></span>;
  }
const handleImgError = (e) => {
  setvalidurl(false);
  console.log(e.target.src,"ev.target.src");
  e.target.src=Avatar;
}
  return (
    <div className="dropdown">
      <button
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={handleClick}
        className="btn p-0 topMenuUserProfileBtn d-flex justify-content-center align-items-center"
      >
        <VerifyBrokenImage url={userData?.profile_photo_url} name={(userData?.name && userData?.name)+" "+(!!userData?.surname ? userData?.surname : "")}/>
      </button>
      <ul className="dropdown-menu" aria-labelledby="settings-dropdown">
        <li>
          <div className="dropdown-item">
            <i className="fas fa-user mr-3"></i>
            {userData.name} {userData.surname}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default TopMenuUserProfile;
