const actions = {
  SET_CURRENT_APPOINTMENT: "SET_CURRENT_APPOINTMENT",
  SET_STATUS_ONLINE: "SET_STATUS_ONLINE",
  SET_STATUS_OFFLINE: "SET_STATUS_OFFLINE",
  ACCEPT_WALKIN_REQUEST: "ACCEPT_WALKIN_REQUEST",
  UPDATE_PATIENT_PRESCRIPTION: "UPDATE_PATIENT_PRESCRIPTION",
  LOADING: "LOADING",
  STOP_LOADING: "STOP_LOADING",
  NEW_PATIENT_REQUEST: "NEW_PATIENT_REQUEST",
  REMOVE_PATIENT_FROM_NOTIF_ARR: "REMOVE_PATIENT_FROM_NOTIF_ARR",
  REMOVE_PATIENT_FROM_NOTIF_ARR_BY_ID: "REMOVE_PATIENT_FROM_NOTIF_ARR_BY_ID",
  SET_QUEUE_OF_PATIENTS: "SET_QUEUE_OF_PATIENTS",
  ADD_PENDING_WALKIN_REQUESTS: "ADD_PENDING_WALKIN_REQUESTS",
  SET_ALL_APPOINTMENTS_OF_PRACTITIONER: "SET_ALL_APPOINTMENTS_OF_PRACTITIONER",
  SET_PRACTITIONER_DETAILS: "SET_PRACTITIONER_DETAILS",
  SET_PRACTITIONER_VIEW_TYPE: "SET_PRACTITIONER_VIEW_TYPE",
  SET_NEED__TO_CALL_PRACTITIONER_CALENDAR:
    "SET_NEED__TO_CALL_PRACTITIONER_CALENDAR",
  SET_PRACTITIONER_VIEW_SLOT: "SET_PRACTITIONER_VIEW_SLOT",
  SET_PRACTITIONER_SELECTED_SLOT: "SET_PRACTITIONER_SELECTED_SLOT",
  SET_PRACTITIONER_SELECTED_SLOT_AND_VIEW:
    "SET_PRACTITIONER_SELECTED_SLOT_AND_VIEW",
  SET_CALENDAR_LOADING: "SET_CALENDAR_LOADING",
};

export default actions;
