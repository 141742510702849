import { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import MedicineTable from "./MedicineTable";
import LabTable from "./LabTable";
import { useSelector } from "react-redux";

const PrescriptionSection3 = (props) => {
  // const [nav, setNav] = useState(history.location.state ? true : false);

  const { setMedicineList, medicineList, appointment } = props;

  // const [medicineList, setMedicineList] = useState(
  //   history.location.state ? history.location.state.medicineList : []
  // );
  const newInput = useSelector((state) => state.practitioner.prescriptionForm);
  const [labTestList, setLabTestList] = useState(newInput.labTestList);
  const [list, setList] = useState(newInput.medication);
  const language = useSelector((state) => state.language);
  // const emptyInput = {
  //   fName: "",
  //   lName: "",
  //   age: "",
  //   gender: "",
  //   visit: "",
  //   diagnosis: "",
  //   allergies: "",
  // };
  // const [Input, setInput] = useState(
  //   history.location.state
  //     ? history.location.state.Input || emptyInput
  //     : emptyInput
  // );
  // const handleOnInputChange = (e) => {
  //   const nam = e.target.name;
  //   const val = e.target.value;
  //   setInput((prevState) => {
  //     return { ...prevState, [nam]: val };
  //   });
  // };
  return (
    <Col
      xs={12}
      lg={6}
      className={props.className + " pt-2 px-0"}
      style={{ position: "relative" }}
    >
      <div className="d-flex  w-100 mt-2" style={{ flexDirection: "column" }}>
        <label className="pres-modal-title" style={{ fontWeight: "bold" }}>
          {language.lang["create-prescription"]}
        </label>
        <div className="row mx-0 justify-content-between mb-3">
          <div className="col-12 col-md-8 px-0 d-flex align-items-center">
            {props.className === "consultation-section-3" ? (
              <div
                className="d-flex align-items-center"
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  color: "#bbb",
                }}
              >
                {language.lang.pleaseCreatePrescriptionSaveInPdf}
              </div>
            ) : (
              <div
                className="d-flex align-items-center required-field-star"
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                *{language.lang.youCanUploadPrescription}
              </div>
            )}
          </div>
          {props.apply ? (
            <div className="col-12 col-md-3 mt-3 mt-md-0 d-flex justify-content-end px-0 ml-auto">
              <button className="apply-btn" onClick={props.onOk}>
                {language.lang.saveForLater}
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <Row className="create-prescription-details m-0">
        <Col xs={12} md={6} style={{ padding: "0", margin: "0" }}>
          <Form.Label>{language.lang["first-name"]}</Form.Label>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                className="shadow-none"
                name="fName"
                value={props.Input.fName}
                onChange={props.handleOnInputChange}
                disabled="true"
              />
            </Form.Group>
          </Col>
        </Col>
        <Col xs={12} md={5} style={{ padding: "0", margin: "0" }}>
          <Col>
            <Form.Label>{language.lang["last-name"]}</Form.Label>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                className="shadow-none"
                name="lName"
                value={props.Input.lName}
                onChange={props.handleOnInputChange}
                disabled="true"
              />
            </Form.Group>
          </Col>
        </Col>
        <Col xs={12} md={5} style={{ padding: "0", margin: "0" }}>
          <Col>
            <Form.Label>{language.lang.age}</Form.Label>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                className="shadow-none"
                name="age"
                value={props.Input.age}
                onChange={props.handleOnInputChange}
                disabled="true"
              />
            </Form.Group>
          </Col>
        </Col>
        <Col
          xs={12}
          md={6}
          style={{ padding: "0", margin: "0", gap: "5px" }}
          className="d-flex justify-content-between"
        >
          <div style={{ width: "25%" }}>
            <Form.Label className="d-flex" style={{ gap: "3px" }}>
              {language.lang.visits}
              <span className="required-field-star"> *</span>
            </Form.Label>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="number"
                className="shadow-none"
                name="visit"
                value={props.Input.visit}
                onChange={props.handleOnInputChange}
                min="1"
              />
            </Form.Group>
          </div>
          <div style={{ width: "70%" }}>
            <Form.Label className="text-nowrap">
              {language.lang.gender}
            </Form.Label>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                className="shadow-none"
                placeholder="Male"
                name="gender"
                value={props.Input.gender}
                onChange={props.handleOnInputChange}
                disabled="true"
              />
            </Form.Group>
          </div>
        </Col>
        <Col xs={12} style={{ padding: "0", margin: "0" }}>
          <Form.Label>
            {language.lang.diagnosis}
            <span className="required-field-star"> *</span>
          </Form.Label>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              className="shadow-none"
              name="diagnosis"
              value={props.Input.diagnosis}
              onChange={props.handleOnInputChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} style={{ padding: "0", margin: "0" }}>
          <Form.Label>
            {language.lang["drug-allergies"]}
            <span className="required-field-star"> *</span>
          </Form.Label>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              className="shadow-none"
              name="allergies"
              value={props.Input.allergies}
              onChange={props.handleOnInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <MedicineTable
        setMedicineList={setMedicineList}
        medicineList={medicineList}
        setList={props.setList}
        list={list}
      />
      <LabTable
        setLabTestList={props.setLabTestList}
        labTestList={labTestList}
      />
      {props.apply ? (
        <div className="mt-3 text-right">
          <button className="apply-btn" onClick={props.onOk}>
            {language.lang.saveForLater}
          </button>
        </div>
      ) : null}
    </Col>
  );
};

export default PrescriptionSection3;
