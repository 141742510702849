import actions from "./actions";

export const setPhoneNumber = ({ phoneNumber, phoneCountryCode }) => {
  return {
    type: actions.SET_PHONE_NUMBER,
    payload: { phoneNumber, phoneCountryCode },
  };
};

export const setCorporateID = ({ corporateID }) => {
  return {
    type: actions.SET_CORPORATE_ID,
    payload: corporateID,
  };
};

export const setPhoneAuthConfirmationResult = ({ confirmationResult }) => {
  return {
    type: actions.SET_PHONE_AUTH_CONFIRMATION_RESULT,
    payload: confirmationResult,
  };
};

export const setFirebaseAppAction = ({ app }) => {
  return { type: actions.SET_APP, payload: app };
};
