import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useEffect, useState } from "react";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAppointmentDetailsForClinic,
  setCalendarViewType,
} from "../redux/clinic/actionCreators";
import clinicactions from "../redux/clinic/actions";
import { BsChevronDown } from "react-icons/bs";
import FieldFormat from "./FieldFormat";
import clinicActions from "../redux/clinic/actions";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const [bcView, setBCView] = useState("day");
  const ViewType = useSelector((state) => state.clinic.calendarView);
  const Events = useSelector((state) => state.clinic.events);
  // const [width, height] = useWindowDimension();
  // useEffect(() => {console.log(width)
  //   if(width < 768){
  //     dispatch(setCalendarViewType("day"));
  //   }else{
  //     dispatch(setCalendarViewType("week"));
  //   }
  // },[width])
  const dispatch = useDispatch();
  // const handleSlotSelection = ({ start, end, action }) => {
  //   // do something with it all
  //   console.log(start, end, action);
  // };

  const handleSelectedEvent = (event) => {
    // console.log(event);
    dispatch({
      type: clinicactions.SET_VIEW_SLOT,
      payload: true,
    });
    dispatch({
      type: clinicactions.SET_SELECTED_SLOT,
      payload: event,
    });
  };
  let today = new Date();
  // let formats = {
  //   timeGutterFormat: (date, culture, localizer) =>
  //     localizer.format(date, "HH:mm", culture),
  // };
  //  const onNavigate = (date, view, action) => {
  //   console.log(dates.firstVisibleDay(date), dates.lastVisibleDay(date));
  // }
  // const handleonEventDrop = (month, view) => {
  //   console.log(month, view);
  // };

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={Events}
        startAccessor="start"
        endAccessor="end"
        components={{
          toolbar: CustomToolbar,
          event: CustomEvent,
        }}
        // formats={formats}
        defaultDate={moment().toDate()}
        popup={true}
        style={{ minHeight: 500 }}
        view={ViewType}
        onView={setBCView}
        onSelectEvent={(e) => handleSelectedEvent(e)}
        dateFormat="h t"
        timeslots={1}
        step={15}
        min={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9)
        }
        max={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 20)
        }
        //onNavigate={onNavigate}
      />
    </div>
  );
};

const CustomToolbar = (toolbar) => {
  const needToCall = useSelector((state) => state.clinic.needToCall);
  const TopPractitioner = useSelector((state) => state.clinic.top_practitioner);
  const ViewType = useSelector((state) => state.clinic.calendarView);
  const top_list = useSelector(
    (state) => state.clinic.top_practitioner_dropList
  );
  const userData = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();
  const [startDate, setstartDate] = useState(toolbar.date);
  const [endDate, setendDate] = useState(toolbar.date);
  const now = toolbar.date;
  const addMonths = (date, months) => {
    const d = date.getDate();
    date.setMonth(date.getMonth() + months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    console.log(date);
    return date;
  };

  const addWeeks = (date, weeks) => {
    date.setDate(date.getDate() + 7 * weeks);
    return date;
  };

  const addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    console.log(date);
    return date;
  };
  const goToBack = () => {
    if (ViewType === "month") {
      toolbar.onNavigate("prev", addMonths(toolbar.date, -1));
    } else if (ViewType === "week") {
      toolbar.onNavigate("prev", addWeeks(toolbar.date, -1));
    } else {
      toolbar.onNavigate("prev", addDays(toolbar.date, -1));
    }
    fetchAllappointments();
    if (ViewType === "day") {
      setviewDate(moment(toolbar.date).format("DD MMM YYYY"));
    } else {
    }
  };

  const goToNext = () => {
    if (ViewType === "month") {
      toolbar.onNavigate("next", addMonths(toolbar.date, +1));
    } else if (ViewType === "week") {
      toolbar.onNavigate("next", addWeeks(toolbar.date, +1));
    } else {
      toolbar.onNavigate("next", addDays(toolbar.date, +1));
    }
    fetchAllappointments();
    if (ViewType === "day") {
      setviewDate(moment(toolbar.date).format("DD MMM YYYY"));
    } else {
      setviewDate(
        moment(getStartDate(now)).format("DD") +
          "-" +
          moment(getEndDate(now)).format("DD MMM YYYY")
      );
    }
  };

  const goToToday = () => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.date.setDate(now.getDate());
    toolbar.onNavigate("current");
    fetchAllappointments();
    dispatch(setCalendarViewType("day"));
    setviewDate(moment(toolbar.date).format("DD MMM YYYY"));
  };
  const fetchAllappointments = () => {
    dispatch(
      getAllAppointmentDetailsForClinic(
        userData?.id,
        moment(getStartDate(now)).format("YYYY-MM-DD"),
        moment(getEndDate(now)).format("YYYY-MM-DD"),
        selectedDoc?.value,
        9
      )
    );
  };
  const getStartDate = (now) => {
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - now.getDay()
    );
  };
  const getEndDate = (now) => {
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      getStartDate(now).getDate() + 6
    );
  };
  const label = () => {
    const date = moment(toolbar.date);
    return (
      <span>
        <b>{date.format("MMMM")}</b>
        <span> {date.format("YYYY")}</span>
      </span>
    );
  };
  const [viewDate, setviewDate] = useState(moment().format("DD MMM YYYY"));
  useEffect(() => {
    if (ViewType === "week") {
      setstartDate(getStartDate(now));
      setendDate(getEndDate(now));
      // duration
    } else {
      setstartDate(now);
      setendDate(now);
    }
  }, [toolbar]);
  useEffect(() => {
    if (ViewType === "day") {
      setviewDate(moment(toolbar.date).format("DD MMM YYYY"));
    } else {
      setviewDate(
        moment(getStartDate(now)).format("DD") +
          "-" +
          moment(getEndDate(now)).format("DD MMM YYYY")
      );
    }
  }, [ViewType]);
  const setViewType = (e) => {
    dispatch(setCalendarViewType(e));
  };
  const [selectedDoc, setselectedDoc] = useState({});
  const [ViewTypeOptions, setViewTypeOptions] = useState([
    { name: "Day", value: "day" },
    { name: "Week", value: "week" },
  ]);
  useEffect(() => {
    dispatch({
      type: clinicActions.SET_CLINIC_CALENDAR_SETTINGS,
      payload: {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        pracID: selectedDoc.value,
      },
    });
  }, [startDate]);
  useEffect(() => {
    if (needToCall) {
      dispatch(
        getAllAppointmentDetailsForClinic(
          userData?.id,
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD"),
          selectedDoc.value,
          9
        )
      );
      dispatch({
        type: clinicactions.SET_NEED_TO_CALL_CALENDAR,
        payload: false,
      });
    }
  }, [needToCall]);
  const setDocEvent = async (e) => {
    const id = e;
    let docDetails = TopPractitioner.filter((a) => a.id === id)[0];
    // console.log(userData?.id, startDate, endDate, id, 9, docDetails);
    setselectedDoc({ name: docDetails.name, value: docDetails.id });
    dispatch(
      getAllAppointmentDetailsForClinic(
        userData?.id,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        id,
        9
      )
    );
    dispatch({
      type: clinicActions.SET_SELECTED_DOCTOR_INFO,
      payload: {},
    });
  };
  useEffect(() => {
    console.log("TopPractitioner", TopPractitioner);
    if (TopPractitioner.length > 0) setDocEvent(TopPractitioner[0].id);
  }, [TopPractitioner]);

  return (
    <div
      className="w-100 d-flex custom-toolbar justify-content-center align-items-center flex-wrap visiblility-none pt-0 pt-sm-3 "
      style={{ gap: 20 }}
    >
      <div
        className="d-flex w-100 align-items-center justify-content-center flex-wrap flex-lg-nowrap maxContentWidth"
        style={{ gap: "20px" }}
      >
        <FieldFormat
          element={
            <SelectSearch
              value={{
                name: ViewType.toUpperCase(),
                value: ViewType.toLowerCase(),
              }}
              placeholder="select view type"
              options={ViewTypeOptions}
              filterOptions={fuzzySearch}
              search={true}
              onChange={setViewType}
            />
          }
          icon={<BsChevronDown color={"#18a0fb"} size={25} />}
        />
        <FieldFormat
          element={
            <SelectSearch
              value={selectedDoc}
              placeholder="select practitioner"
              options={top_list}
              filterOptions={fuzzySearch}
              search={true}
              onChange={setDocEvent}
            />
          }
          icon={<BsChevronDown color={"#18a0fb"} size={25} />}
          show={Object.keys(selectedDoc).length > 0}
        />
        <div className="d-flex controls col-4">
          <div className="arrow" onClick={goToBack}>
            <i className="fas fa-chevron-left"></i>
          </div>
          <div onClick={goToToday}>Today</div>
          <div>{/*moment(toolbar.date).format("DD MMM YYYY")*/ viewDate}</div>
          <div className="arrow" onClick={goToNext}>
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>
      </div>
      <div
        className="w-100 justify-content-center align-items-center mt-2 status pb-2"
        style={{ gap: "20px" }}
      >
        <div className="status-card pending">Pending</div>
        <div className="status-card ongoing">Ongoing</div>
        <div className="status-card completed">Completed</div>
        <div className="status-card upcoming">Upcoming</div>
        <div className="status-card cancelled">Cancelled</div>
        <div className="status-card failed">Failed</div>
        <div className="status-card skiped">Skipped/Missed</div>
      </div>
    </div>
  );
};

const CustomEvent = (events) => {
  const { event } = events;
  return (
    <div
      className="d-flex justify-content-center align-items-center h-100"
      style={{ backgroundColor: event.color }}
    >
      <div className="doc-name">{event.PatientName}</div>
    </div>
  );
};

export default MyCalendar;
