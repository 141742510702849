import { useSelector } from "react-redux";
import img from "../assets/images/MealPlan1.png";

const SingleFeatureCard = ({ url, Heading, subHeading ,subContent}) => {
  const language = useSelector((state) => state.language);
  return (
    <div className='content-card h-list-item  h-100'>
      <img className="item-img" src={ url || img} alt="Popular Item" />
      <div className="d-flex">
        <div className="content w-100">
          <p className="category-name">{Heading || "Heading" }</p>
          <h6 className="title">{subHeading || "SubHeading"}</h6>
          <div className="description">
            {subContent || "SubContent"}
          </div>
          <div className="d-flex justify-content-end w-100 mt-2" style={{gap:'20px',height:'min-content'}}>
          <button type="button" className="btn shadow-none pay" style={{background:'#f6bd27',color:'#fff'}}>
          {language.lang.view}
          </button>
          {/*<button type="button" className="btn shadow-none pay" style={{background:'#f6bd27',color:'#fff'}}>
            {language.lang.pay}
          </button>*/}
          </div>
        </div>
      </div>
    </div>

    
  );
};

export default SingleFeatureCard;