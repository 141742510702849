import axios from "../../services/Axios";
import { settings } from "../../config";
import { showSuccessNotification } from "../../utility/Helpers";
import actions from "./actions";
import store from "../store";
import { userRoleClinic } from "../../utility/constants";

export const setclinicAppointments = (request) => {
  return {
    type: actions.SET_CLINIC_APPOINTMENT,
    payload: request,
  };
};

export const getClinicdashboardData = async (payload) => {
  return {
    type: actions.SET_PENDING_APPOINTMENTS,
    payload: payload,
  };
};

export const verifyPatientDetails = async (formData) =>{
  try{
    console.log("formData",formData);
    let language = store.getState().language;
   const res = await axios.post(`${settings.apiBaseURL}/verifyPatientDetailsByClinic`, formData)
   showSuccessNotification(language.lang.submitted);
  }
  catch (error) {
    console.log("--- verifyPatientDetails error");
    console.log(error);
    showSuccessNotification("Some Error occurred.");
  }
};

export const setPatientDetail = (patientDetail) => {
  return {
    type: actions.SET_PATIENT_DETAIL,
    payload: patientDetail,
  };
};

export const updatescheduleAppointment = (appointment) => {
  return {
    type: actions.UPDATE_SCHEDULED_APPOINTMENTS,
    payload: appointment,
  };
};

export const getAppointmentDetailsForClinic = (id) => {
  return async (dispatch) => {
    const res = await axios.get(`${settings.apiBaseURL}/getAppointmentDetailsForClinic?appointmentId=${id}`);
    dispatch({
      type: actions.SET_APPOINTMENT_DETAIL,
      payload: res ? res.data.response[0] : null,
    });
  };
};

export const getCurrencies = () => {
  return async (dispatch) => {
    const res = await axios.get(`${settings.apiBaseURL}/getCurrencies`);
    dispatch({
      type: actions.SET_CURRENCIES,
      payload: res ? res.data.response : null,
    });
  };
};

export const getTopPractitioners = () => {
  const state = store.getState();
  const userID = state.auth.userData && state.auth.userData.id;
  const role = state.auth.userData && state.auth.userData.role;
  if(role !== userRoleClinic) return;
  let endURL= `getPractitionersByClinic?clinicId=${userID}`;
  //?userGroup=production&offset=0 ${state.auth.userData.user_group}
  //if (userID)  endURL=`/getTopPractitioners?userGroup=test&offset=${offset}&limit=${limit}`;
  return async (dispatch) => {
      const res = await axios.get(`${settings.apiBaseURL}/${endURL}`);
      dispatch({
        type: actions.GET_TOP_PRACTITIONERS,
        payload: res ? res.data.response : null,
      });
    };
};

export const setCalendarViewType = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_CALENDAR_VIEW_TYPE,
      payload: payload,
    });
  };
};

export const setPractitionerEvents = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_PRACTITIONER_EVENTS,
      payload: payload,
    });
  };
};

export const getAllAppointmentDetailsForClinic = (clinicId, startDate, endDate, pracID) => {
  return async (dispatch) => {
    const res = await axios.get(`${settings.apiBaseURL}/getClinicAllAppointments?clinicId=${clinicId}&startDate=${startDate}&endDate=${endDate}&practitionerId=${pracID}`);
    dispatch({
      type: actions.SET_ALL_APPOINTMENTS_OF_PRACTITIONER,
      payload: res ? res.data?.response : null,
    });
  };
};

