import React from "react";

const SentMsgItem = ({ text }) => {
  return (
    <div className="sent-msg--block">
      <div className="sent-msg">{text}</div>
    </div>
  );
};

export default SentMsgItem;
