//import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReviews } from "../../redux/patient/actionCreators";
import { useHistory } from "react-router-dom";
import * as Routes from "../../routes";
import { showWarningNotification } from "../../utility/Helpers";
import { BulletList } from 'react-content-loader';
import Carousel from 'react-multi-carousel';
import '../../assets/styles/LandingPages/react_multi_carousel.css';
import { FiMinus,FiChevronLeft, FiChevronRight } from "react-icons/fi";

const ReviewCarousel = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const login = useSelector((state) => state.auth.login);
  const language = useSelector((state) => state.language);
  const languageType = useSelector(
    (state) => state.language.general.preferredlanguage
  );
  const Reviews = useSelector((state) => state.patient.reviews);
  const SelectTab = (type) => {
    switch (type) {
      case "mostpopular":
        return Routes.dashboardMostPopularNewRoute.path;
      case "fitness":
        return Routes.dashboardFitnessRoute.path;
      case ["meditation", "yoga"]:
        return Routes.dashboardMeditationYogaRoute.path;
      case "mealplan":
        return Routes.dashboardMealPlanRoute.path;
      case "view-matches":
        return Routes.fitnessBuddyRoute.path;
      case "customer-live-sessions-list":
        return Routes.dashboardLiveSessionRoute.path;
      default:
        return Routes.dashboardBookConsultationRoute.path;
    }
  };

  // const pushTab = (type) => {
  //   const pageRoute = SelectTab(type);
  //   if (pageRoute === Routes.fitnessBuddyRoute.path) {
  //     return handleViewMatchesClicked();
  //   }
  //   history.push(type);
  // };

  useEffect(() => {
    dispatch(setReviews());
  }, []);

  const getPromotionHeader = (item) => {
    switch (languageType) {
      case "en":
        return item.promotion_header;
      case "ar":
        return item.promotion_header_ar;
      case "ku":
        return item.promotion_header_ku;
      default:
        return item.promotion_header;
    }
  };
  const getPromotionText = (item) => {
    switch (languageType) {
      case "en":
        return item.promotion_text;
      case "ar":
        return item.promotion_text_ar;
      case "ku":
        return item.promotion_text_ku;
      default:
        return item.promotion_text;
    }
  };
  const getReviewComments = (item) => {
    switch (languageType) {
      case "en":
        return item.review_comments;
      case "ar":
        return item.review_comments_ar;
      case "ku":
        return item.review_comments_ku;
      default:
        return item.review_comments;
    }
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const CustomDot = ({ onMove, index, onClick, active }) => {
    return (
      <li
        className={active ? "active" : "inactive"}
        onClick={() => onClick()}
      >
        <FiMinus />
      </li>
    );
  };
  const arrowStyle = {
    background: "transparent",
    border: 0,
    color: "#fff",
    fontSize: "80px"
  };
  const CustomRight = ({ onClick }) => (
    <button className="arrow right" onClick={onClick} style={arrowStyle}>
      <FiChevronLeft style={{ fontSize: "50px" }} />
    </button>
  );
  const CustomLeft = ({ onClick }) => (
    <button className="arrow left" onClick={onClick} style={arrowStyle}>
      <FiChevronRight style={{ fontSize: "50px" }} />
    </button>
  );
  return (
<div>
<Carousel swipeable={true}
  draggable={false}
  responsive={responsive}
  ssr={true} 
  infinite={true}
  autoPlay={true}
  autoPlaySpeed={4000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  showDots
  containerClass="carousel-container"
  dotListClass=""
  itemClass="carousel-item-padding-40-px"
  customDot={<CustomDot />}
  customRightArrow={<CustomRight />}
  customLeftArrow={<CustomLeft />}
  >
{Reviews && Reviews.length > 0 ? (
        Reviews.map((item, i) => {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "25rem", minHeight: "25rem"}}
              key={i}
            >
              <div className="card border-success mb-3 render-card">
                <div className="review-image-card">
                  <img src={item.image_url} className="set-review-image"/>
                </div>
                <div className="d-flex justify-content-start p-2 flex-column ml-3">
                    <div className="d-flex mb-2">
                      <div className="mr-auto p-2"><h4 className="name-header">{item.name}</h4></div>
                      <div className="p-2 ml-2"><i className="fas fa-star rating-adjust" style={{color:"#186be8"}}>{item.rating}</i></div>
                      
                    </div>
                    <div className="d-flex"><p className="location-header">{item.location}</p></div>
                    <div className="d-flex justify-content-start p-2 flex-column ml-3">
                    <div className="review-description">
                        <p><i className="fas fa-solid fa-quote-left"></i></p>
                        <p className="review-text">{getReviewComments(item)}</p>
                      </div>
                  </div>
                </div>
                </div>
            </div>
          );
        })
      ) : (
        <BulletList width="150" height="150"/>
      )}
</Carousel>
</div>
  );
};

export default ReviewCarousel;
