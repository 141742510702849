import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { UpdateGeneralSettings } from "../redux/language/actionCreators";
import { firebaseLoginSignup } from "../services/CustomerNetworkService";
import { tokenStorageKey } from "../utility/constants";
import {
  getAuthDataFromResponse,
  storeAuthDataInLocalStorage,
} from "../utility/Helpers";
import authActions from "../redux/authenitcation/actions";
import { getAdditionalUserInfo } from "firebase/auth";

const useHandleFirebaseUserLogin = () => {
  const dispatch = useDispatch();

  const handleSigninSuccess = useCallback(
    async ({
      user,
      email,
      phoneCountryCode,
      phoneWithoutCountryCode,
      corporateID,
      googleProvider,
      facebookProvider,
      userCredential,
    }) => {
      const additionalUserInfo = getAdditionalUserInfo(userCredential);
      localStorage.setItem(tokenStorageKey, user.accessToken);
      const res = await firebaseLoginSignup({
        phoneCountryCode,
        email, // : user.email,
        phone: user.phone,
        userName: user.displayName,
        corporateId: corporateID ?? null,
        phoneWithoutCountryCode,
        phoneNumber:
          phoneCountryCode && phoneWithoutCountryCode
            ? `${phoneCountryCode ?? ""}${phoneWithoutCountryCode ?? ""}`
            : null,
        appleProvider: null,
        googleProvider: googleProvider,
        facebookProvider,
        linkedinProvider: null,
        referralCode: null,
        signinProviderEmail: null,
        accessToken: user.accessToken,
        isNewUser: additionalUserInfo?.isNewUser ?? false,
      });
      // console.log("--- res", res);
      const authData = getAuthDataFromResponse(res);
      storeAuthDataInLocalStorage(authData);
      const userPrefs = {
        preferredlanguage: authData.userData.preferred_language,
        regionId: authData.userData.region_id,
        theme: authData.userData.theme,
      };
      dispatch(UpdateGeneralSettings(userPrefs, false));
      // console.log({ authData });
      dispatch(authActions.loginSuccess(authData));
      return authData;
    },
    [dispatch]
  );

  return handleSigninSuccess;
};

export default useHandleFirebaseUserLogin;
