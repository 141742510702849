import { useState, useEffect, useRef } from "react";
import SectionHeader from "./SectionHeader";
import { useHistory } from "react-router-dom";
import { dashboardBookConsultationRoute } from "../routes";
import Loading from "./Loading";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { settings } from "../config";
import {
  GetPractitionerCurrencyCodeAndFees,
  showErrorNotification,
} from "../utility/Helpers";
import DoctornewInfoCard from "./DoctorNewInfoCard";
import actions from "../redux/patient/actions";
import { Facebook, Instagram,BulletList } from "react-content-loader";

const TopDoctorsSection = ({
  practitioners,
  formData,
  getinsured,
  loading,
  pageLoading,
  searchData,
  showLoadMore,
  loadMoreDoctor,
}) => {
  const language = useSelector((state) => state.language);
  const currentSelectedRegion = language.regionID;
  if (loading && practitioners.length === 0) {
    return <BulletList width={350} height={350} className="mt-5" />;
  }
  
  return (
    <div className="top-doctors w-100">
      <SectionHeader
        btnText={language.lang["see-all"]}
        title={language.lang.availableDoctors}
        titlePadding={false}
        className="pt-4"
        showTrailingButton={false}
      />
      {loading ? (
        <BulletList width={350} height={350}  className="mt-5" />
      ) : (
        <div className="doctor-cards-list customize-filter-doctor doctor-cards-list-pad">
          {practitioners && practitioners.length > 0 ? (
            practitioners.map((practitioner) => {
              return (
                <DoctorCard
                  key={practitioner.id}
                  practitioner={practitioner}
                  formData={formData}
                  getinsured={getinsured}
                  searchData={searchData}
                  currentSelectedRegion={currentSelectedRegion}
                />
              );
            })
          ) : (
            <div className="w-100 d-flex justify-content-center align-items-center">
              {language.lang["could-not-find-doctors"]}
            </div>
          )}
          {pageLoading && <Facebook />}
          {!pageLoading &&
            showLoadMore &&
            practitioners.length > 0 &&
            practitioners.length >= 5 && (
              <div className="d-flex justify-content-center w-100 align-items-center downArrow py-2">
                <div className="load-more btn btn-primary" onClick={loadMoreDoctor}>
                  <label>{language.lang.loadMore}</label>
                  <i className="fas fa-chevron-down"></i>
                </div>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

const DoctorCard = (props) => {
  const { currentSelectedRegion } = props;
  const {
    name,
    name_arabic,
    surname,
    courtesy_title,
    id,
    profile_photo_url,
    userSpecializationsByUserId = [{ specializationById: "" }],
    userLanguagesByUserId = [{ languageById: "" }],
    userConfigsByUserId = [
      {
        json_value: {
          individual: 0,
        },
      },
    ],
    fees = 0,
    insurances = [
      {
        coveredInsurances: {
          aggregate: {
            count: 0,
          },
        },
      },
    ],
    rating = { aggregate: { avg: { rating: 0 } } },
    roleInfo = {
      role_name: "",
    },
  } = props.practitioner;
  const history = useHistory();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const [slots, setSlots] = useState([]);
  const [slotnumber, setslotnumber] = useState(0);
  const [slotID, setSlotID] = useState("");
  const [selectedSlot, setSelectedSlot] = useState({});
  const [pracID, setPracID] = useState("");
  const [pracDetails, setpracDetails] = useState([]);
  const selectedTimeSlotsRef = useRef(null);
  const onSelectedSlotChanged = (selectedSlots) => {
    selectedTimeSlotsRef.current = selectedSlots;
  };

  const bookNowPressed = (slot) => {
    history.push(
      `${dashboardBookConsultationRoute.path}/${props.practitioner.url_name}`,
      {
        practitioner: props.practitioner,
        formData: props.formData,
        getinsured: props.getinsured === "1" ? true : false,
        selectedSlot: slot,
        slotnumber: slotnumber,
        insuranceProvidersCount:
          insurances[0]?.coveredInsurances?.aggregate.count,
        searchData: props?.searchData,
      }
    );
    addSelectedPractitioner(slot);
  };


  const viewAll = () => {
    history.push(
      `${dashboardBookConsultationRoute.path}/${props.practitioner.url_name}`,
      {
        practitioner: props.practitioner,
        formData: props.formData,
        getinsured: props.getinsured === "1" ? true : false,
      }
    );
    addSelectedPractitioner();
  };

  const addSelectedPractitioner = (slot) => {
    dispatch({
      type: actions.SET_SELECTED_PRACTITIONER,
      payload: {
        selectedPractitioner: props.practitioner,
        selectedSlot: slot,
        slotnumber: slotnumber,
      },
    });
  };

  const getDoctorSlotDetails = async () => {
    try {
      const response = await axios.get(
        `${settings.apiBaseURL}/getSlots?practitionerId=${id}`
      );
      let arr = response.data.response
        .filter((slot) => {
          const slotStartTime = new Date(slot.start_time);
          const now = new Date();
          return slotStartTime > now;
        })
        .map((el) => {
          let obj = {};
          obj.key = el.id;
          obj.slot_id = el.id;
          obj.practionerId = el.practitioner_id;
          obj.date = el.start_time.substring(0, 10);
          obj.start_time = el.start_time; // .substring(11, 16);
          obj.end_time = el.end_time; // .substring(11, 16);
          obj.is_available = el.is_available;
          return obj;
        });
      setSlots(arr);
    } catch (e) {
      showErrorNotification(e.response && e.response.data.error);
    }
  };

  const [details, setdetails] = useState({});
  const [insuranceProvidersCount, setInsuranceProviderCount] = useState(0);

  useEffect(() => {
    getDoctorSlotDetails();
  }, []);

  const feesWithMultiCurrency = props.practitioner.feesWithMultiCurrency || {};
  const { currencyCode, practitionerFees } = GetPractitionerCurrencyCodeAndFees(
    {
      selectedRegionID: currentSelectedRegion,
      feesWithMultiCurrency,
    }
  );

  const LangType = useSelector(
    (state) => state.language.general.preferredlanguage
  );
  const doctorName =
    LangType === "ar" && name_arabic !== null && name_arabic !== ""
      ? name_arabic
      : `${courtesy_title|| ""} ${name} ${surname || ""}`;
  return (
    <div className="doctor-card mm-card py-2 flex-wrap row">
      <div
        className="d-flex flex-grow-1 col-sm-12 col-lg-5 mx-auto"
        style={{ gap: "10px" }}
      >
        <div
          className="d-flex justify-content-start align-items-start flex-column"
          style={{ gap: "40px" }}
        >
          <div className="d-flex justify-content-center align-items-start w-100 mt-2">
            {profile_photo_url ? (
              <img
                className="doctor-img"
                src={profile_photo_url}
                alt={doctorName}
              />
            ) : (
              <Avatar name={doctorName} size="100" round={true} />
            )}
          </div>
          <div
            className="d-flex justify-content-center align-items-center w-100 "
            style={{ gap: "10px", marginTop: "-12px" }}
          >
            <span className="doctor-speciality-icon blue-icons">
              <i className="fas fa-video"></i>
            </span>
            <span className="doctor-speciality-icon blue-icons">
              <i className="fas fa-phone-alt"></i>
            </span>
          </div>
        </div>
        <div className="d-flex" style={{ gap: "10px" }}>
          <div className="d-flex justify-content-start align-items-start flex-column w-100">
            <p className="doctor-name">{doctorName}</p>
            {/*<div className="d-flex justify-content-center align-items-center w-100" >
              <span className="rate"><i className="fas fa-star mr-1"></i>{rating.aggregate.avg.rating}</span> <span className="ml-5 exp">15 Years Of Experience</span>
            </div>*/}
            <div className="d-flex justify-content-center align-items-center mt-2">
              <span className="doctor-speciality-icon mr-3">
                <i className="far fa-briefcase-medical"></i>
              </span>
              <span className="doctor-speciality">
                {(userSpecializationsByUserId &&
                  userSpecializationsByUserId[0] &&
                  (LangType === "ar"
                    ? userSpecializationsByUserId[0].specializationById.name_ar
                    : userSpecializationsByUserId[0].specializationById
                        .name)) ||
                  "None"}
              </span>
            </div>
            {/*<div className="d-flex justify-content-center align-items-center">
                <span className="doctor-speciality-icon mr-3"><i className="far fa-ambulance"></i></span>
                <span className="doctor-speciality">
                   15 Years of Experience
                </span>
                    </div>*/}
            {/*<div className="d-flex justify-content-center align-items-center">
                <span className="doctor-speciality-icon mr-3"><i className="far fa-ambulance"></i></span>
                <span className="doctor-speciality">
                   {roleInfo && roleInfo.role_name}
                </span>
                  </div>*/}
            <div className="d-flex justify-content-center align-items-center">
              <span className="doctor-speciality-icon mr-3">
                <i className="fal fa-language mr-3"></i>
                <span className="doctor-speciality mr-1">
                  {(userLanguagesByUserId &&
                    userLanguagesByUserId.length !== 0 &&
                    userLanguagesByUserId.map((userLang) => {
                      return LangType === "ar" ? (
                        <span className="mr-2">
                          {" "}
                          {userLang.languageById.name_ar}
                        </span>
                      ) : (
                        <span className="mr-2">
                          {userLang.languageById.name}
                        </span>
                      );
                    })) ||
                    "None"}
                </span>
              </span>
            </div>
            {practitionerFees >=0 && (
              <div className="d-flex justify-content-center align-items-center">
                <span className="doctor-speciality-icon mr-3">
                  <i className="fas fa-money-bill-alt"></i>
                </span>
                <span className="doctor-speciality">
                  {practitionerFees === 0
                    ? "Free Consultation "
                    : +practitionerFees + " " + language.lang[currencyCode] ||
                      currencyCode}
                </span>
              </div>
            )}
            {practitionerFees === undefined && (
              <div className="d-flex justify-content-center align-items-center">
                <span className="doctor-speciality-icon mr-3">
                  <i className="fas fa-money-bill-alt"></i>
                </span>
                <span className="doctor-speciality">Free Consultation</span>
              </div>
            )}
            {/* <div className='d-flex mt-1 insurance-covered justify-content-center align-items-center'></div> */}
           
            <div className={`d-flex mt-1 justify-content-center align-items-center ${insurances[0]?.coveredInsurances?.aggregate.count > 0 ? "insurance-covered" : "insurance-not-covered"}`}>
              <span>
                {insurances[0]?.coveredInsurances?.aggregate.count > 0
                  ? `${insurances[0]?.coveredInsurances?.aggregate.count} Insurance Covered`
                  : `${language.lang.noInsuranceCovered}`}{" "}
              </span>
            </div>
            {/*<div className="d-flex justify-content-center align-items-center">
                <span className="doctor-speciality-icon mr-3"><i className="fal fa-address-card"></i></span>
                <span className="doctor-speciality">
                 DHA License No - 123456789
                </span>
                </div>*/}
            {/* <div className="d-flex w-100 justify-content-start mt-2 h-100 align-items-end" style={{marginLeft:'-100px'}}>
              <div className="consultation"><span>Video Consultation</span><span className="px-2"><i className="fas fa-video"></i></span></div>
              <div className="consultation"><span className="pl-2">Audio Consultation</span><span className="px-2"><i className="fas fa-phone-alt"></i></span></div>
              </div> */}
          </div>
          {/*<div className="d-flex flex-grow-1 mt-4 text-nowrap insurance-covered" style={{minWidth:'max-content'}}>
       {insuranceProvidersCount > 0 ? insuranceProvidersCount : "No"} Insurance Covered
            </div>*/}
        </div>
      </div>

      <div className="flex-grow-1 d-flex flex-column col-sm-12 col-lg-7  mx-auto pt-1 info-card-container">
        <DoctornewInfoCard
          slots={slots}
          setSlotID={setSlotID}
          setSelectedSlot={setSelectedSlot}
          setPracID={setPracID}
          onSelectedSlotChanged={onSelectedSlotChanged}
          pracDetails={pracDetails}
          bookNowPressed={bookNowPressed}
          viewAll={viewAll}
          setslotnumber={setslotnumber}
          slotnumber={slotnumber}
        />
      </div>
    </div>
  );
};

export default TopDoctorsSection;
