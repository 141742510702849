import { useSelector } from "react-redux";
import IngredientListItem from "./IngredientListItem";

const IngredientsList = ({ addedIngredients, onRemovePressed }) => {
  const language = useSelector((state) => state.language.lang);

  return (
    <div className="ingredients-list">
      {(!addedIngredients || addedIngredients.length === 0) && (
        <p className="text-center mt-3">
          {language["no-ingredients-added"] ?? "no-ingredients-added"}
        </p>
      )}
      {addedIngredients.map((item) => {
        return (
          <IngredientListItem
            key={item.ingredient.id}
            onRemovePressed={onRemovePressed}
            addedIngredient={item}
          />
        );
      })}
    </div>
  );
};

export default IngredientsList;
