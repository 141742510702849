import { useEffect, useState } from "react";
import TopDoctorsSection from "../components/TopDoctorsSection";
import PreviousAppointmentsSection from "../components/PreviousAppointmentsSection";
import FilterSideMenu from "../components/FilterSideMenu";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { settings } from "../config";
import { Drawer } from "antd";
import clientActions from "../redux/client/actions";
import InputFieldFormat from "../components/InputFieldFormat";
import { RiCloseCircleLine } from "react-icons/ri";
import config from "../config";
import TopMenu from "../components/CommonControls/TopMenu";
import { isProductionEnv } from "../config";
import ReactGA from "react-ga4";

const BookConsultation = ({ history }) => {
  const language = useSelector((state) => state.language);
  const userData = useSelector((state) => state.auth.userData);
  const [filtersMenuOpened, setFiltersMenuOpened] = useState(false);
  const [isViewOpened, setisViewOpened] = useState(false);
  const TopPractitioner = useSelector(
    (state) => state.patient.top_practitioner
  );
  const [filtersearch, setfiltersearch] = useState([]);
  const [loading, setloading] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [filterCounter, setfilterCounter] = useState(0);
  const dispatch = useDispatch();
  const selectedRegionID = language.regionID;
  const languageType = useSelector((state) =>
    state.language.general?.preferredlanguage === undefined
      ? "en"
      : state.language.general.preferredlanguage
  );

  useEffect(() => {
    if (isProductionEnv) {
      ReactGA.send("Book Consultation");
    }
  }, []);

  useEffect(() => {
    getFilterResult();
  }, [selectedRegionID]);

  const openFiltersSideMenu = () => {
    setFiltersMenuOpened(true);
  };

  const closeFiltersSideMenu = () => {
    setFiltersMenuOpened(false);
  };

  useEffect(() => {
    //TopPractitioner.length === 0 && dispatch(getTopPractitioners(0));
    if (
      history?.location?.state &&
      history?.location?.state?.practitioners &&
      history?.location?.state?.practitioners.length > 0
    ) {
      setfiltersearch(history?.location?.state?.practitioners);
    }
    // else {
    //   setfiltersearch(TopPractitioner);
    // }
  }, [TopPractitioner]);

  const [formData, setFormData] = useState({
    specialityId: "",
    regionId: "",
    insuranceProviderId: "",
    consultantType: "",
    languageId: "",
    genderId: "",
    feesMin: 0,
    feesMax: 1000,
    userGroup: userData?.user_group,
    offset: 0,
    limit: 10,
    comboInput: "",
    practitionerFirstName: "",
    practitionerLastName: "",
  });
  const [loadMoreOffset, setsLoadMoreOffset] = useState(0);

  const [pageLoading, setpageLoading] = useState(false);
  const [langSelected, setlangSelected] = useState({});
  const [getLanguage, setgetLanguage] = useState([]);
  const [InsuranceProviderSelected, setInsuranceProviderSelected] = useState(
    {}
  );
  const [LanguageSelected, setLanguageSelected] = useState({});
  const [getInsuranceProvider, setgetInsuranceProvider] = useState([]);
  const [RegionSelected, setRegionSelected] = useState(language.regionID);
  const [getRegions, setgetRegions] = useState([]);
  const [getRegionsback, setgetRegionsback] = useState([]);
  const [getinsured, setgetinsured] = useState("1");
  const [genderIdoption, setgenderIdoption] = useState({});
  const [consultantTypeoption, setconsultantTypeoption] = useState({});
  const [comboInputs, setComboInputs] = useState([]);
  const [specialities, setspecialities] = useState([]);
  const [combo, setCombo] = useState({});
  const [speciality, setspeciality] = useState({});
  const [doctor, setDoctor] = useState({});
  const [Practitioners, setPractitioners] = useState([]);
  const [practitionersCategories, setPractitionersCategories] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState(false);
  const [insuranceProvider, setInsuranceProvider] = useState([]);

  const getLanguages = async () => {
    await axios
      .get(`${settings.apiBaseURL}/getLanguages`, formData)
      .then((response) => {
        const data = response.data.response;
        let newFormat = [];
        data.map((item) => {
          newFormat.push({ name: item.name, value: item.id });
        });
        setgetLanguage(newFormat);
      });
  };

  useEffect(() => {
    if (
      practitionersCategories.length !== 0 &&
      insuranceProvider.length !== 0 &&
      Practitioners.length !== 0
    ) {
      setCombo({});
      setgenderIdoption({ name: language.lang.any, value: 0 });
      setconsultantTypeoption({
        name: language.lang.video,
        value: language.lang.video,
      });

      let newFormat = [];
      let newdocs = [];
      let newInsurance = [];
      practitionersCategories.map((item) => {
        if (languageType === "en")
          newFormat.push({ name: item.name, value: item.id });
        if (languageType === "ar")
          newFormat.push({ name: item.name_ar, value: item.id });
        if (languageType === "ku")
          newFormat.push({ name: item.name_ku, value: item.id });
      });
      setspecialities(newFormat);
      Practitioners.map((item) => {
        if (languageType === "en")
          newdocs.push({
            name:
              item.name +
              (item.surname !== null
                ? " " + item.surname.replace(" ", "")
                : ""),
            value: item.id,
          });
        if (languageType === "ar")
          newdocs.push({
            name:
              item.name_arabic === null || item.name_arabic === ""
                ? item.name +
                  (item.surname !== null
                    ? " " + item.surname.replace(" ", "")
                    : "")
                : item.name_arabic,
            value: item.id,
          });
        if (languageType === "ku")
          newdocs.push({
            name:
              item.name +
              (item.surname !== null
                ? " " + item.surname.replace(" ", "")
                : ""),
            value: item.id,
          });
      });
      insuranceProvider.map((item) => {
        if (languageType === "en")
          newInsurance.push({ name: item.name, value: item.id });
        if (languageType === "ar")
          newInsurance.push({ name: item.name_ar, value: item.id });
        if (languageType === "ku")
          newInsurance.push({ name: item.name_ku, value: item.id });
      });
      setgetInsuranceProvider(newInsurance);
      let groups = [
        {
          name: "Specialities",
          type: "group",
          items: newFormat,
        },
        {
          name: "Doctors",
          type: "group",
          items: newdocs,
        },
        {
          name: "Insurance",
          type: "group",
          items: newInsurance,
        },
      ];
      setComboInputs(groups);
    }
  }, [languageType, language]);

  const sortByName = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const fetchSpecialities = async () => {
    try {
      setDropdownLoading(true);
      const response = await axios.get(
        `${settings.apiBaseURL}/getPractitionerCategories`
      );
      setPractitionersCategories(response.data.response);
      const data = response.data.response;
      let newFormat = [];
      data.map((item) => {
        if (languageType === "en")
          newFormat.push({ name: item.name, value: item.id });
        if (languageType === "ar")
          newFormat.push({ name: item.name_ar, value: item.id });
        if (languageType === "ku")
          newFormat.push({ name: item.name_ku, value: item.id });
        if (languageType === undefined)
          newFormat.push({ name: item.name, value: item.id });
      });
      setspecialities(newFormat);
      const res = await axios.get(
        `${settings.apiBaseURL}/getPractitioners?userGroup=${
          userData?.user_group || "production"
        }`
      );
      const filterdoctorsByRegion = res.data.response.filter(
        (doctorDetails) => doctorDetails.region_id === selectedRegionID
      );
      setPractitioners(filterdoctorsByRegion);
      filterdoctorsByRegion.sort(sortByName);
      let newdocs = [];
      filterdoctorsByRegion.map((item) => {
        newdocs.push({
          name:
            item.courtesy_title +
            " " +
            item.name +
            (item.surname !== null ? " " + item.surname.replace(" ", "") : ""),
          value: item.id,
        });
      });
      let insurance = await getInsuranceProvide();
      let groups = [
        {
          name: "Specialities",
          type: "group",
          items: newFormat,
        },
        {
          name: "Doctors",
          type: "group",
          items: newdocs,
        },
        {
          name: "Insurance",
          type: "group",
          items: insurance,
        },
      ];
      setComboInputs(groups);
      setDropdownLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const getFilterResult = async () => {
    sethit(true);
    setloading(true);
    if (selectedRegionID && selectedRegionID !== 1) {
      formData.regionId = selectedRegionID;
    } else if (userData && Object.values(userData).length > 0) {
      formData.regionId = userData.region_id;
    } else {
      formData.regionId = localStorage.getItem("selectedRegionID");
    }
    const response = await axios.post(
      `${settings.apiBaseURL}/getPractitionersByFilters`,
      formData
    );
    // if (response.data.response.length > 1 && formData.offset !== 0) {
    //   setfiltersearch((currentArray) => [...response.data.response]);
    // } else {
    // }
    setfiltersearch(response.data.response);
    setloading(false);
    setpageLoading(false);
    setShowLoadMore(true);
    sethit(false);
    setsLoadMoreOffset(0);
  };

  const getFilterSLoadMoreResult = async (data) => {
    sethit(true);
    if (selectedRegionID && selectedRegionID !== 1) {
      data.regionId = selectedRegionID;
    } else if (userData && Object.values(userData).length > 0) {
      data.regionId = userData.region_id;
    } else {
      data.regionId = localStorage.getItem("selectedRegionID");
    }
    const response = await axios.post(
      `${settings.apiBaseURL}/getPractitionersByFilters`,
      data
    );
    if (response.data.response.length === 0) {
      //showErrorNotification("End of results");
      setShowLoadMore(false);
    } else {
      let filterOut = [];
      setShowLoadMore(true);
      response.data.response.map((item) => {
        if (filtersearch.filter((a) => a.id === item.id).length === 0) {
          filterOut.push(item);
        }
      });
      setfiltersearch((currentArray) => [...currentArray, ...filterOut]);
    }
    setpageLoading(false);
    sethit(false);
  };

  const getInsuranceProvide = async () => {
    let newFormat = [];
    await axios
      .get(`${settings.apiBaseURL}/getInsuranceProviders`)
      .then((response) => {
        const data = response.data.response;
        setInsuranceProvider(response.data.response);
        data.map((item) => {
          if (languageType === "en")
            newFormat.push({ name: item.name, value: item.id });
          if (languageType === "ar")
            newFormat.push({ name: item.name_ar, value: item.id });
          if (languageType === "ku")
            newFormat.push({ name: item.name_ku, value: item.id });
        });
        setgetInsuranceProvider(newFormat);
      });
    return newFormat;
  };

  useEffect(() => {
    getLanguage.length === 0 && getLanguages();
    // getRegions.length === 0 && regions();
    fetchSpecialities();
  }, [selectedRegionID]);

  const [hit, sethit] = useState(false);

  useEffect(() => {
    getFilterResult();
  }, [formData]);

  const countryFlagsMap = new Map();
  countryFlagsMap.set(1, config.UAEFlagIcon);
  countryFlagsMap.set(2, config.IRAQFlagIcon);
  countryFlagsMap.set(3, config.EGYPTFlagIcon);
  const regions = async () => {
    await axios.get(`${settings.apiBaseURL}/getRegions`).then((response) => {
      const data = response.data.response;
      let newFormat = [],
        backformat = [];
      data &&
        data.map((item, i) => {
          backformat.push({ name: item.name, value: item.id });
          newFormat.push({
            name: (
              <div className="d-flex justify-content-start align-items-center">
                <img
                  alt=""
                  src={countryFlagsMap.get(i + 1)}
                  width="30px"
                  className="mr-2"
                />{" "}
                {item.name}{" "}
              </div>
            ),
            value: item.id,
          });
        });
      setgetRegions(newFormat);
      setgetRegionsback(backformat);
      setRegionSelected({
        name: backformat[0].name,
        value: backformat[0].value,
      });
      setFormData((prevState) => {
        return {
          ...prevState,
          regionId: 1,
          offset: 0,
        };
      });
      if (!counter.regionId) {
        setfilterCounter((prevState) => prevState + 1);
        setcounter((prevState) => {
          return {
            ...prevState,
            regionId: true,
          };
        });
      }
    });
  };

  const ComboHandler = (e, target) => {
    let firstName = "",
      secondName = "";
    setCombo({ name: target.name, value: target.value });
    if (target.groupName === "Doctors") {
      setDoctor({ name: target.name, value: target.value });
      let doc = Practitioners.filter((a) => a.id === e);
      if (doc.length > 0) {
        firstName = doc[0]?.name;
        secondName =
          doc[0]?.surname !== null
            ? " " + doc[0]?.surname.replace(" ", "")
            : "";
      }
    }
    setFormData((prevState) => {
      return {
        ...prevState,
        comboInput: target.name,
        practitionerFirstName: firstName,
        practitionerLastName: secondName,
        offset: 0,
      };
    });
    if (target.groupName === "Specialities") specialityHandler(e, target);
    if (target.groupName === "Insurance") insuranceHandler(e, target);
  };

  const [counter, setcounter] = useState({
    specialityId: false,
    regionId: false,
    insuranceProviderId: false,
    languageId: false,
    consultantType: false,
    genderId: false,
    feesMin: false,
    feesMax: false,
  });

  const specialityHandler = (value, e) => {
    setCombo({ name: e.name, value: e.value });
    setspeciality({ name: e.name, value: value });
    setFormData((prevState) => {
      return {
        ...prevState,
        comboInput: e.name,
        specialityId: value,
        offset: 0,
      };
    });
    if (!counter.specialityId) {
      setfilterCounter((prevState) => prevState + 1);
      setcounter((prevState) => {
        return {
          ...prevState,
          specialityId: true,
        };
      });
    }
    if (Object.keys(doctor).length !== 0) {
      let firstName = "",
        secondName = "";
      let doc = Practitioners.filter((a) => a.id === doctor.value);
      if (doc.length > 0) {
        firstName = doc[0]?.name;
        secondName =
          doc[0]?.surname !== null
            ? " " + doc[0]?.surname.replace(" ", "")
            : "";
      }

      setFormData((prevState) => {
        return {
          ...prevState,
          comboInput: doctor.name,
          practitionerFirstName: firstName,
          practitionerLastName: secondName,
          offset: 0,
        };
      });
    }
  };

  const insuranceHandler = (value, e) => {
    setCombo({ name: e.name, value: e.value });
    setInsuranceProviderSelected({ name: e.name, value: value });

    setFormData((prevState) => {
      return {
        ...prevState,
        // comboInput: e.name,
        insuranceProviderId: value,
        offset: 0,
      };
    });
    if (!counter.insuranceProviderId) {
      setfilterCounter((prevState) => prevState + 1);
      setcounter((prevState) => {
        return {
          ...prevState,
          insuranceProviderId: true,
        };
      });
    }
    if (Object.keys(doctor).length !== 0) {
      let firstName = "",
        secondName = "";
      let doc = Practitioners.filter((a) => a.id === doctor.value);
      if (doc.length > 0) {
        firstName = doc[0]?.name;
        secondName =
          doc[0]?.surname !== null
            ? " " + doc[0]?.surname.replace(" ", "")
            : "";
      }

      setFormData((prevState) => {
        return {
          ...prevState,
          comboInput: doctor.name,
          practitionerFirstName: firstName,
          practitionerLastName: secondName,
          offset: 0,
        };
      });
    }
  };

  const languageIdHandler = (value, e) => {
    setlangSelected({ name: e.name, value: value });
    setFormData((prevState) => {
      return {
        ...prevState,
        languageId: value,
        offset: 0,
      };
    });
  };

  const consultantTypeHandler = (value) => {
    dispatch({
      type: clientActions.SET_CALL_TYPE,
      payload: value === `${language.lang.video}` ? true : false,
    });
    setFormData((prevState) => {
      return {
        ...prevState,
        consultantType: value,
        offset: 0,
      };
    });
    setconsultantTypeoption({
      name: `${language.lang.video}`,
      value: value,
    });
    if (!counter.consultantType) {
      setfilterCounter((prevState) => prevState + 1);
      setcounter((prevState) => {
        return {
          ...prevState,
          consultantType: true,
        };
      });
    }
  };

  const locationHandler = (value, e) => {
    setRegionSelected({
      name: getRegionsback[value - 1].name,
      value: getRegionsback[value - 1].value,
    });
    setFormData((prevState) => {
      return {
        ...prevState,
        regionId: value,
        offset: 0,
      };
    });
    if (!counter.regionId) {
      setfilterCounter((prevState) => prevState + 1);
      setcounter((prevState) => {
        return {
          ...prevState,
          regionId: true,
        };
      });
    }
  };

  const genderIdHandler = (value) => {
    let name;
    if (value === 1) {
      name = `${language.lang.male}`;
    } else if (value === 2) {
      name = `${language.lang.female}`;
    } else {
      name = `${language.lang.any}`;
    }
    setgenderIdoption({ name: name, value: value });
    setFormData((prevState) => {
      return {
        ...prevState,
        genderId: value,
        offset: 0,
      };
    });
    if (!counter.genderId) {
      setfilterCounter((prevState) => prevState + 1);
      setcounter((prevState) => {
        return {
          ...prevState,
          genderId: true,
        };
      });
    }
  };

  const minFeeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    console.log(formData);
    if (value > 1000) {
      return;
    }
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
        offset: 0,
      };
    });
    if (!counter[name] && !counter.feesMax) {
      setfilterCounter((prevState) => prevState + 1);
      setcounter((prevState) => {
        return {
          ...prevState,
          [name]: true,
        };
      });
    }
  };

  const maxFeeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value > 1000) {
      return;
    }
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
        offset: 0,
      };
    });
    if (!counter[name] && !counter.feesMin) {
      setfilterCounter((prevState) => prevState + 1);
      setcounter((prevState) => {
        return {
          ...prevState,
          [name]: true,
        };
      });
    }
  };

  const setDefaultValue = () => {
    setconsultantTypeoption({});
    setgenderIdoption({});
    setRegionSelected({});
    setCombo({});
    setspeciality({});
    setInsuranceProviderSelected({});
    setLanguageSelected({});
    setFormData((prevState) => {
      return {
        ...prevState,
        offset: 0,
      };
    });
    setfilterCounter(0);
  };

  // const setMoreFilterValues = () => {
  //   setconsultantTypeoption({});
  //   setgenderIdoption({});
  //   setRegionSelected({});
  //   setspeciality({});
  //   setInsuranceProviderSelected({});
  //   setCombo({});
  //   setFormData((prevState) => {
  //     return {
  //       ...prevState,
  //       specialityId: "",
  //       consultantType: "",
  //       genderId: "",
  //       feesMin: "",
  //       feesMax: "",
  //       regionId: "",
  //       offset: 0,
  //       insuranceProviderId: "",
  //       comboInput:
  //         counter.specialityId || counter.insuranceProviderId
  //           ? ""
  //           : prevState.comboInput,
  //     };
  //   });
  //   setfilterCounter(0);
  //   setcounter(
  //     Object.fromEntries(Object.keys(counter).map((key) => [key, false]))
  //   );
  // };

  const handleClose = () => {
    setisViewOpened(false);
  };
  const loadMoreDoctor = () => {
    setpageLoading(true);
    getFilterSLoadMoreResult({
      ...formData,
      offset: loadMoreOffset + formData.limit,
    });
    setsLoadMoreOffset(loadMoreOffset + formData.limit);
  };

  const [prev, setprev] = useState("");

  const handleFilter = (items) => {
    return (searchValue) => {
      if (searchValue.length === 0) {
        return comboInputs;
      }
      const updatedItems = items.map((list) => {
        const newItems = list.items.filter((item) => {
          return item.name.toLowerCase().includes(searchValue.toLowerCase());
        });
        return { ...list, items: newItems };
      });
      if (searchValue.length > 2) {
        setprev(searchValue);
        let firstName = "",
          secondName = "";
        if (updatedItems[1]?.items?.length > 0) {
          let id = updatedItems[1]?.items[0]?.value;
          let doc = Practitioners.filter((a) => a.id === id);
          if (doc.length > 0) {
            firstName = doc[0]?.name;
            secondName =
              doc[0]?.surname !== null
                ? " " + doc[0]?.surname.replace(" ", "")
                : "";
          }
        }
        if (prev !== searchValue) {
          setFormData((prevState) => {
            return {
              ...prevState,
              comboInput: searchValue,
              practitionerFirstName: firstName,
              practitionerLastName: secondName,
              offset: 0,
            };
          });
          setCombo({ name: searchValue, value: searchValue });
        }
      }
      return updatedItems;
    };
  };
  const closeLanguageProviderSelected=()=>{
    setlangSelected({});
    setFormData((prevState) => {
      return {
        ...prevState,
        languageId: "",
      };
    });
    if (Object.keys(langSelected).length !== 0){
      console.log("Language not cleared");
    }
    else{
      console.log("Language cleared");
    }
  }
  const closeInsuranceProviderSelected = () => {
    setInsuranceProviderSelected({});
    setCombo({});
    setFormData((prevState) => {
      return {
        ...prevState,
        insuranceProviderId: "",
      };
    });
    if (Object.keys(speciality).length !== 0) {
      setFormData((prevState) => {
        return {
          ...prevState,
          specialityId: speciality.value,
          offset: 0,
        };
      });
    }
    if (
      Object.keys(speciality).length === 0 &&
      Object.keys(doctor).length === 0
    ) {
      setFormData((prevState) => {
        return {
          ...prevState,
          comboInput: "",
          practitionerFirstName: "",
          practitionerLastName: "",
          insuranceProviderId: "",
          specialityId: "",
          offset: 0,
        };
      });
    }
  };
  // const closeLanguageSelected = () => {
  //   setLanguageSelected({});
  //   setCombo({});
  //   setFormData((prevState) => {
  //     return {
  //       ...prevState,
  //       languageId: "",
  //     };
  //   });
  //   if (Object.keys(language).length !== 0) {
  //     setFormData((prevState) => {
  //       return {
  //         ...prevState,
  //         languageId: speciality.value,
  //         offset: 0,
  //       };
  //     });
  //   }
  //   if (
  //     Object.keys(speciality).length === 0 &&
  //     Object.keys(doctor).length === 0
  //   ) {
  //     setFormData((prevState) => {
  //       return {
  //         ...prevState,
  //         comboInput: "",
  //         practitionerFirstName: "",
  //         practitionerLastName: "",
  //         insuranceProviderId: "",
  //         specialityId: "",
  //         offset: 0,
  //       };
  //     });
  //   }
  // };
  const closeDoctorSelected = () => {
    setDoctor({});
    setCombo({});
    if (Object.keys(speciality).length !== 0) {
      setFormData((prevState) => {
        return {
          ...prevState,
          comboInput: "",
          practitionerFirstName: "",
          practitionerLastName: "",
          specialityId: speciality.value,
          offset: 0,
        };
      });
    } else if (Object.keys(InsuranceProviderSelected).length !== 0) {
      setFormData((prevState) => {
        return {
          ...prevState,
          comboInput: "",
          practitionerFirstName: "",
          practitionerLastName: "",
          insuranceProviderId: InsuranceProviderSelected.value,
          offset: 0,
        };
      });
    }
    if (
      Object.keys(speciality).length === 0 &&
      Object.keys(InsuranceProviderSelected).length === 0
    ) {
      setFormData((prevState) => {
        return {
          ...prevState,
          comboInput: "",
          practitionerFirstName: "",
          practitionerLastName: "",
          insuranceProviderId: "",
          specialityId: "",
          offset: 0,
        };
      });
    }
  };
  const closeSpecialitySelected = () => {
    setspeciality({});
    setCombo({});
    setFormData((prevState) => {
      return {
        ...prevState,
        specialityId: "",
      };
    });
    if (Object.keys(InsuranceProviderSelected).length !== 0) {
      setFormData((prevState) => {
        return {
          ...prevState,
          insuranceProviderId: InsuranceProviderSelected.value,
          offset: 0,
        };
      });
    }

    if (
      Object.keys(InsuranceProviderSelected).length === 0 &&
      Object.keys(doctor).length === 0
    ) {
      setFormData((prevState) => {
        return {
          ...prevState,
          comboInput: "",
          practitionerFirstName: "",
          practitionerLastName: "",
          insuranceProviderId: "",
          specialityId: "",
          offset: 0,
        };
      });
    }
  };

  return (
    <div className="dashboard-book-consultation">
      <FilterSideMenu
        isOpened={filtersMenuOpened}
        onClosePressed={closeFiltersSideMenu}
        formData={formData}
        getLanguage={getLanguage}
        setFormData={setFormData}
        getRegions={getRegions}
        RegionSelected={RegionSelected}
        maxFeeHandler={maxFeeHandler}
        minFeeHandler={minFeeHandler}
        genderIdHandler={genderIdHandler}
        languageIdHandler={languageIdHandler}
        locationHandler={locationHandler}
        consultantTypeHandler={consultantTypeHandler}
        regions={regions}
        setRegionSelected={setRegionSelected}
        genderIdoption={genderIdoption}
        setDefaultValue={setDefaultValue}
        speciality={speciality}
        setspeciality={setspeciality}
        specialities={specialities}
        specialityHandler={specialityHandler}
        InsuranceProviderSelected={InsuranceProviderSelected}
        getInsuranceProvider={getInsuranceProvider}
        insuranceHandler={insuranceHandler}
        setLanguageSelected={setLanguageSelected}
        setInsuranceProviderSelected={setInsuranceProviderSelected}
        consultantTypeoption={consultantTypeoption}
        setconsultantTypeoption={setconsultantTypeoption}
        setgenderIdoption={setgenderIdoption}
        setfilterCounter={setfilterCounter}
        setcounter={setcounter}
        setCombo={setCombo}
        closeInsuranceProviderSelected={closeInsuranceProviderSelected}
        closeSpecialitySelected={closeSpecialitySelected}
        langSelected={langSelected}
        setlangSelected={setlangSelected}
        closeLanguageProviderSelected={closeLanguageProviderSelected}
      />
      <section className="book-header row px-4 d-flex justify-content-between w-100">
        <TopMenu
          shouldHideBackButton={false}
          shouldHideProfileButton={false}
          shouldHideNotificationButton={false}
          shouldHideLogoButton={false}
          pageTitle={language.lang["book-your-consultation"]}
        />
      </section>
      <section className="search-section row pt-1 px-md-5">
        <div className="row">
          <div className="d-flex flex-sm-row flex-row w-100">
            <div className="order1">
              <InputFieldFormat
                show={Object.keys(combo).length > 0}
                element={
                  <SelectSearch
                    disabled={dropdownLoading}
                    value={combo}
                    autosize={true}
                    placeholder={language.lang.searchByDoctorSpecialtyInsurance}
                    filterOptions={handleFilter}
                    search={true}
                    options={comboInputs}
                    onChange={ComboHandler}
                  />
                }
                preIcon={<i className="fas fa-search"></i>}
                icon={
                  <span
                    disabled={loading}
                    onClick={() => {
                      setInsuranceProviderSelected({});
                      setLanguageSelected({});
                      setspeciality({});
                      setCombo({});
                      setFormData((prevState) => {
                        return {
                          ...prevState,
                          comboInput: "",
                          practitionerFirstName: "",
                          practitionerLastName: "",
                          specialityId: "",
                          insuranceProviderId: "",
                          offset: 0,
                        };
                      });
                      if (counter.specialityId && counter.insuranceProviderId) {
                        setfilterCounter((prevState) => prevState - 1);
                      }
                      if (counter.specialityId || counter.insuranceProviderId) {
                        setfilterCounter((prevState) => prevState - 1);
                        setcounter((prevState) => {
                          return {
                            ...prevState,
                            specialityId: counter.specialityId
                              ? !counter.specialityId
                              : counter.specialityId,
                            insuranceProviderId: counter.insuranceProviderId
                              ? !counter.insuranceProviderId
                              : counter.insuranceProviderId,
                          };
                        });
                      }
                    }}
                    // className="btn position-relative"
                    // style={{ right:"30px",width:"100%",top: "0px", zIndex: 1, color: "#3f83ec", }}
                  >
                    {/* <RiCloseCircleLine size={25} /> */}
                  </span>
                }
              />
            </div>
            <div className="order2 ml-2">
              <button
                className="btn btn-white-filter"
                onClick={openFiltersSideMenu}
              >
                <i className="fas fa-sliders-v fa-lg text-logo-color"></i>
              </button>
            </div>
            {/* <div className={"d-flex d-sm-flex d-md-flex d-lg-flex justify-content-end bd-highlight mb-1 ml-4 mt-1 filter-container flex-column" +(filterCounter > 0 && "")}>
            <div className="d-flex d-sm-flex d-md-flex d-lg-flex justify-content-end bd-highlight mb-1 ml-3  align-items-center justify-content-around mm-card more-filters mt-md-2 position-relative">
              
              <span className="more">{language.lang.moreFilters}</span>
            </div>
          </div> */}
          </div>
        </div>

        <div className="d-flex d-sm-flex d-md-flex d-lg-flex flex-sm-row flex-column ml-3 ">
          <div
            className="d-flex d-sm-flex d-md-flex d-lg-flex justify-content-start  mb-1 ml-2 flex-wrap"
            style={{ gap: "4px" }}
          >
            {Object.keys(InsuranceProviderSelected).length !== 0 && (
              <div className="filter-selected">
                <div>{InsuranceProviderSelected.name}</div>
                <button
                  disabled={loading}
                  onClick={closeInsuranceProviderSelected}
                  className="btn  p-2"
                  style={{
                    zIndex: 1,
                    color: "#3f83ec",
                  }}
                >
                  <RiCloseCircleLine size={25} />
                </button>
              </div>
            )}
            {Object.keys(langSelected).length !== 0 && (
              <div className="filter-selected">
                <div>{langSelected.name}</div>
                <button
                  disabled={loading}
                  onClick={closeLanguageProviderSelected}
                  className="btn  p-2"
                  style={{
                    zIndex: 1,
                    color: "#3f83ec",
                  }}
                >
                  <RiCloseCircleLine size={25} />
                </button>
              </div>
            )}

            {Object.keys(speciality).length !== 0 && (
              <div className="filter-selected">
                <div>{speciality.name}</div>
                <button
                  disabled={loading}
                  onClick={closeSpecialitySelected}
                  className="btn  p-2"
                  style={{
                    zIndex: 1,
                    color: "#3f83ec",
                  }}
                >
                  <RiCloseCircleLine size={25} />
                </button>
              </div>
            )}
            {Object.keys(doctor).length !== 0 && (
              <div className="filter-selected">
                <div>{doctor.name}</div>
                <button
                  disabled={loading}
                  onClick={closeDoctorSelected}
                  className="btn  p-2"
                  style={{
                    zIndex: 1,
                    color: "#3f83ec",
                  }}
                >
                  <RiCloseCircleLine size={25} />
                </button>
              </div>
            )}
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-start w-100 flex-wrap"
          style={{ gap: "10px" }}
        ></div>
      </section>
      <section className="doctor-section row pt-1 px-md-5">
        <div className="section-container d-flex justify-content-between flex-wrap mb-4 px-4 w-100">
          <TopDoctorsSection
            pageLoading={pageLoading}
            practitioners={filtersearch}
            formData={history?.location?.state}
            getinsured={getinsured}
            loading={loading}
            searchData={formData}
            showLoadMore={showLoadMore}
            loadMoreDoctor={loadMoreDoctor}
          />
        </div>
      </section>
      <Drawer
        placement={"right"}
        closable={true}
        onClose={handleClose}
        visible={isViewOpened}
        width={400}
      >
        <PreviousAppointmentsSection />
      </Drawer>
    </div>
  );
};

export default BookConsultation;
