import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { dashboardCardRecipeRoute } from "../routes";
import ClinicPatientAppointment from "./ClinicPatientAppointment";
import ClinicPatientScheduled from "./ClinicPatientScheduled";
import moment from "moment";
const ClientScheduled = () => {
  const language = useSelector((state) => state.language);
  const Scheduled = useSelector((state) => state.clinic.scheduled_appointments);
  const [scheduled, setscheduled] = useState([]);
   const history = useHistory();
  return (
    <div className="next-patient mm-card mt-5 mt-md-0 min-card-width pending-card">
      <div className="patients-next-arr px-2">
        <div className="d-flex justify-content-between">
          <p className="title">{language.lang["scheduled-appointments"]}</p>
        </div>
        <div className="d-flex">
          <p className="col1 sub-title">{language.lang["patient-name"]}</p>
          <p className="col2 sub-title">{language.lang.time}</p>
          <p className="col3 sub-title">{language.lang.doctor}</p>
          <p className="col3 sub-title">{language.lang.status}</p>
          <p className="col4 sub-title">{language.lang.date}</p>
        </div>
        <div className="ind-patient flex-column">
          {Scheduled.length > 0 ? Scheduled.sort((a,b) => moment(a.start_date_time) - moment(b.start_date_time)).map((el, i) => {
              return (
                <ClinicPatientScheduled
                  key={el.appointmentId}
                  el={el}
                  i={i}
                />
              );
            }) : (<div className="d-flex justify-content-center align-items-center w-100">{language.lang.no_scheduled_appointments}</div>)}
          </div>
      </div>
        </div>
  );
};

export default ClientScheduled;
