import React from "react";
import { useSelector } from "react-redux";

const JoinConsultationPatientInfoCard = (props) => {
  const appointment = props.appointment || {};
  const clientDetails = appointment.patientInfo || {};

  let currentYear = new Date().getFullYear();
  let patientAge = "";
  let defaultYear= parseInt(currentYear) - 18;
  if(!!clientDetails.birth_date){
    patientAge = currentYear - parseInt(clientDetails?.birth_date?.split('-')[0] ?? defaultYear)
    console.log({patientAge});
  }
  console.log("clientDetails",clientDetails);
  const language = useSelector((state) => state.language);
  return (
    <div className="join-consultation-patient-info-card mm-card">
      <div className="d-flex align-items-start">
        <i className="fal fa-user"></i>
        <div className="d-flex flex-column ml-3">
          <p className="mb-0 title-text">{language.lang["patient-info"]}</p>
          <p className="mb-0">{language.lang.name}: {clientDetails.name}</p>
          <p className="mb-0">{language.lang.age}: {patientAge}</p>
        </div>
      </div>
      {/* <div className="d-flex align-items-start mt-4">
        <i className="far fa-dollar-sign"></i>
        <div className="d-flex flex-column ml-3">
          <p className="mb-0 title-text">{language.lang["payment-info"]}</p>
          <p className="mb-0">{language.lang["my-s"]}</p>
          <p className="mb-0">$5</p>
        </div>
      </div> */}
      {/* <div className="d-flex align-items-start mt-4">
        <div className="d-flex flex-column ml-3 w-100">
          <p className="mb-0 title-text">Previous Visit Summary</p>
          <div className="d-flex w-100 mt-3">
            <p className="title-text w-25 text-center">No</p>
            <p className="title-text w-75 text-center">Summary</p>
          </div>
          <PreviousAppointmentRow number="1" />
          <PreviousAppointmentRow number="2" />
        </div>
      </div> */}
    </div>
  );
};

const PreviousAppointmentRow = ({ number }) => {
  return (
    <div className="d-flex w-100 mt-3">
      <p className="title-text w-25 text-center">{number}</p>
      <p className="title-text w-75">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text
      </p>
    </div>
  );
};

export default JoinConsultationPatientInfoCard;
