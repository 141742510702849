import { useSelector } from "react-redux";
import img from "../assets/images/MealPlan1.png";
import ContentCard from "./ContentCard";
import VideoCard from "./VideoCard";
import videoImg from "../assets/images/fit.png";
const ComboFeatureCard = ({ url, Heading, subHeading ,subContent}) => {
  const language = useSelector((state) => state.language);
  return (
    // <div className='content-card h-list-item  h-100'>
    //   <img className="item-img" src={ url || img} alt="Popular Item" />
    //   <div className="d-flex">
    //     <div className="content w-100">
    //       <p className="category-name">{Heading || "Heading" }</p>
    //       <h6 className="title">{subHeading || "SubHeading"}</h6>
    //       <div className="description">
    //         {subContent || "SubContent"}
    //       </div>
    //       <div className="d-flex justify-content-end w-100 mt-2" style={{gap:'20px',height:'min-content'}}>
    //       <button type="button" className="btn shadow-none pay" style={{background:'#f6bd27',color:'#fff'}}>
    //       {language.lang.view}
    //       </button>
    //       <button type="button" className="btn shadow-none pay" style={{background:'#f6bd27',color:'#fff'}}>
    //         {language.lang.pay}
    //       </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="d-flex justify-content-center align-items-center comboCard">
        <div className={`most-popular-item content-card h-list-item`}>
        <img className="item-img" src={img} alt="Popular Item" />
        <div className="d-flex w-100">
            <div className="content w-100 p-0">
            <div className="category-name m-0">{Heading || "Heading" }</div>
            <div className="title">{subHeading || "SubHeading"}</div>
            <p className="description">
            {subContent || "SubContent"}
            </p>
            <div className="d-flex justify-content-end align-items-center w-100" style={{gap:20, height:'fit-content'}}>
            <button type="button" className="btn btn-primary pay outline-none shadow-none">
                {language.lang.view}
            </button>
            <button type="button" className="btn btn-primary pay outline-none shadow-none" >
                {language.lang.pay}
            </button>
            </div>
            </div>
        </div>
        </div>
        <div className="video-card d-flex flex-column justify-content-center align-items-center">
     <div className="duration">30 min</div>
     <div className="video-card-thumbnail"><img className="thumb-img py-0 mb-3" src={videoImg} alt="img-thumb" /></div>
    <div className="video-card-headline my-2 clampText w-100">
        <p className="title d-flex justify-content-start w-100 card-tiles-font">Stretching</p>
        <p className="activity">Team Activity</p>
        </div>
    </div>
    </div>
  );
};

export default ComboFeatureCard;