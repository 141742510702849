import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LandingBase from "../../../components/LandingComponents/LandingBase";
import ReviewCarousel from "../../../components/LandingComponents/ReviewCarousal";
import { setHomeBanner } from "../../../redux/patient/actionCreators";
import * as Routes from "../../../routes";
import { showWarningNotification } from "../../../utility/Helpers";
const FitnessLanding = (props) => {
    const windowHeight = window.innerHeight;
    console.log(windowHeight);
    const history = useHistory();
    const dispatch = useDispatch();
    const login = useSelector((state) => state.auth.login);
    const language = useSelector((state) => state.language);
    const languageType = useSelector(
        (state) => state.language.general.preferredlanguage
    );
    const { children, className } = props;
    const SelectTab = (type) => {
        switch (type) {
            case "mostpopular":
                return Routes.dashboardMostPopularNewRoute.path;
            case "fitness":
                return Routes.dashboardFitnessRoute.path;
            case "mealplan":
                return Routes.dashboardMealPlanRoute.path;
            case "view-matches":
                return Routes.fitnessBuddyRoute.path;
            case "customer-live-sessions-list":
                return Routes.dashboardLiveSessionRoute.path;
            default:
                return Routes.dashboardBookConsultationRoute.path;
        }
    };
    useEffect(() => {
        dispatch(setHomeBanner());
    }, []);

    const handleViewMatchesClicked = () => {
        if (!login) {
            showWarningNotification(language.lang.pleaseLoginToUseFitConnect);
            history.push(Routes.clientSignInRoute.path, {
                nextRoute: Routes.viewMatchesRoute.path,
            });
        } else {
            history.push(Routes.viewMatchesRoute.path);
        }
    };
    const pushTab = (type) => {
        const pageRoute = SelectTab(type);
        if (pageRoute === Routes.fitnessBuddyRoute.path) {
            return handleViewMatchesClicked();
        }
        history.push(type);
    };

    return (
        <LandingBase>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-5">
                    <div className="d-flex flex-row  p-2 welcome-header">
                        <div className="d-flex justify-content-start">Welcome to IZI Health</div>
                    </div>
                    <div className="tolldiv">
                        <iframe className="tollframe" src="https://devmindminastorage.blob.core.windows.net/video/LandingPage/FullSizeRender.mp4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-7">
                    <div className="row">
                        <div className="setone">
                            <p className="setone-header mt-2 mb-3">Ever think of meeting people for fitness !!</p>
                            <div className="row">
                                <ul className="ml-3">
                                    <li className="mt-3 mb-3 set-list-design">
                                        <span><i className="fas fa-check-circle"></i></span><span className="ml-3">Meet people on swipe</span>
                                    </li>
                                    <li className="mt-3 mb-3 set-list-design">
                                        <span><i className="fas fa-check-circle"></i></span><span className="ml-3">Meet Pro Trainers in your location</span>
                                    </li>
                                    <li className="mt-3 mb-3 set-list-design">
                                        <span><i className="fas fa-check-circle"></i></span><span className="ml-3">For Fitness Professionals</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="d-flex justify-content-start">
                                <p className="para-text">IZI Health  creates personalized meal plans based on your food preferences, budget, and schedule. Reach your diet and nutritional goals with our calorie calculator, weekly meal plans, grocery lists and more. Create your meal plan right here in seconds.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-10 no-gutters">
                            <p className="setone-header">What our users say</p>
                            <div className="carousel-align">
                                <ReviewCarousel />
                            </div>
                    </div>
                </div>

            </div>
            <div className="d-flex flex-row justify-content-end">
                <button type="button" className="btn btn-white interested-button" onClick={() => pushTab("view-matches")}>
                    I am Interested!
                </button>
            </div>
        </LandingBase>
    );
};

export default FitnessLanding;
