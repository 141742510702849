import ReactCodeInput from "react-code-input";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { BiCheckCircle } from "react-icons/bi";
import { Button } from "antd";
import { useState } from "react";
import { getAuth, updateProfile } from "firebase/auth";
import AuthBase from "../../components/AuthBase";
import Loading from "../../components/Loading";
import {
  dashboardRoute,
  phoneSigninRoute,
  signupUserNameRoute,
} from "../../routes";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../utility/Helpers";
import useSendPhoneVerificationCode from "../../customHooks/useSendPhoneVerificationCode";
import useHandleFirebaseUserLogin from "../../customHooks/useHandleFirebaseUserLogin";
import { getAdditionalUserInfo } from "firebase/auth";

const LOADING_STATE = "LOADING_STATE";
const VERIFICATION_SUCCESS_STATE = "VERIFICATION_SUCCESS_STATE";
const INITIAL_STATE = "INITIAL_STATE";

const PhoneSigninVerifyOTP = (props) => {
  const handleFirebaseUserLogin = useHandleFirebaseUserLogin();
// Critical River, Varun Mishra, Routing Issue, user is not redirected to previous page before login pop-up
  const history = useHistory();
  const { location } = props;
  let nextRoute = dashboardRoute.path;
  let practitioner = {};
  let selectedSlot = {};
  if (location && location.state && location.state.nextRoute) {
    nextRoute = location.state.nextRoute;
    practitioner = location.state.practitioner;
    selectedSlot = location.state.selectedSlot;
  }
  const {
    phoneSigninConfirmationResult,
    corporateID,
    phoneCountryCode,
    phoneNumber,
  } = useSelector((state) => state.firebase);
  const language = useSelector((state) => state.language.lang);
  const [loadingSendVerificationCode, sendPhoneVerificationCode] =
    useSendPhoneVerificationCode();
  const [otpCode, setOTPCode] = useState("");
  const [uiState, setUIState] = useState(INITIAL_STATE);
  const inputStyle = {
    margin: "4px",
    MozAppearance: "textfield",
    width: "3rem",
    height: "3rem",
    borderRadius: "3px",
    fontSize: "1rem",
    textAlign: "center",
    backgroundColor: "white",
    border: "1px solid #eee",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  };

  const onVerifyClicked = async () => {
    try {
      setUIState(LOADING_STATE);
      const result = await phoneSigninConfirmationResult.confirm(otpCode);
      if (result?.user) {
        handleSigninSuccess(result);
      } else {
        console.log("--- onVerifyClicked error no user");
        showErrorNotification();
        setUIState(INITIAL_STATE);
      }
    } catch (e) {
      console.log("--- onVerifyClicked error");
      console.log(e);
      if (e.code === "auth/invalid-verification-code") {
        showErrorNotification(
          language["invalid-otp-code"] ?? "invalid-otp-code"
        );
      } else {
        showErrorNotification();
      }
      setUIState(INITIAL_STATE);
    }
  };

  const handleSigninSuccess = async (userCredential) => {
    history.location.state = { nextRoute: dashboardRoute.path };
    const authData = await handleFirebaseUserLogin({
      user: userCredential.user,
      corporateID,
      phoneCountryCode,
      phoneWithoutCountryCode: phoneNumber,
      userCredential: userCredential,
    });
    if (!authData) {
      return showErrorNotification();
    }
    const additionalUserInfo = getAdditionalUserInfo(userCredential);
    if (additionalUserInfo.isNewUser && !authData.userData?.name) {
      history.replace(signupUserNameRoute.path);
    } else {
      if (!userCredential.user.displayName) {
        const fireAuth = getAuth();
        await updateProfile(fireAuth.currentUser, {
          displayName: authData.userData?.name,
        });
      }
// Critical River, Varun Mishra, Routing Issue, user is not redirected to previous page before login pop-up
      history.replace(nextRoute, {
        selectedSlot,
        practitioner,
      });
    }
    setUIState(VERIFICATION_SUCCESS_STATE);
  };

  const resendCodeClicked = async () => {
    const success = await sendPhoneVerificationCode({
      corporateID,
      phoneCountryCode,
      phoneNumber,
    });
    if (success) {
      showSuccessNotification(
        language["code-sent-successfully"] ?? "code-sent-successfully"
      );
    } else {
      showErrorNotification(
        language["could-not-send-phone-code"] ?? "could-not-send-phone-code"
      );
      history.goBack();
    }
  };

  if (!phoneSigninConfirmationResult) {
    return <Redirect to={phoneSigninRoute.path} />;
  }

  let content;
  if (uiState === VERIFICATION_SUCCESS_STATE) {
    content = (
      <>
        <h6 className="text-center">
          {language["verification-success"] ?? "verification-success"}
        </h6>
        <div className="check-icon d-flex justify-content-center align-items-start mb-2">
          <BiCheckCircle />
        </div>
        <h6 className="text-center">
          {language["taking-back-home"] ?? "taking-back-home"}
        </h6>
      </>
    );
  } else if (uiState === LOADING_STATE) {
    content = (
      <>
        <p className="text-center">{language["verifying"] ?? "verifying"}</p>
        <Loading className="mt-5" />
      </>
    );
  } else {
    content = (
      <>
        <p className="text-center mb-4">
          {`${language["enter-code-sent-to"] ?? "enter-code-sent-to"} `}
          <span className="user-phone-number">
            {phoneCountryCode}-{phoneNumber}
          </span>
        </p>
        <div className="d-flex justify-content-center mb-4">
          <ReactCodeInput
            className="phone-otp-input number-input-remove-arrows"
            type="number"
            fields={6}
            inputStyle={inputStyle}
            onChange={(e) => {
              setOTPCode(e);
            }}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center mb-3">
          <p>{language["did-not-receive-code"] ?? "did-not-receive-code"}</p>
          <Button
            loading={loadingSendVerificationCode}
            type="link"
            onClick={resendCodeClicked}
          >
            {language["resend-code"] ?? "resend-code"}
          </Button>
        </div>
        <div className="d-flex justify-content-center align-items-center mb-3">
          <Button
            onClick={onVerifyClicked}
            disabled={
              otpCode.trim().length !== 6 || loadingSendVerificationCode
            }
            size="large"
            className="btn-verify"
            type="primary"
          >
            {language["verify"] ?? "verify"}
          </Button>
        </div>
      </>
    );
  }

  return (
    <AuthBase className="phone-signin-verify-otp">
      <div id="phone-sign-in-captcha"></div>
      {content}
    </AuthBase>
  );
};

export default PhoneSigninVerifyOTP;
