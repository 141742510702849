import Config from "../config/index";
import SideMenuLink from "./SideMenuLink";
import { useSelector } from "react-redux";
import {
  dashboardPrivacyPolicy,
  dashboardTermsAndConditions,
  dashboardAboutUs,
  dashboardContactUs,
  dashboardCancelationPolicy,
  dashboardFeedback,
  dashboardJoinUs,
  dashboardSupport,
} from "../routes";
import AppStoreLinkButton from "./AppStoreLinkButton";
import PlayStoreLinkButton from "./PlayStoreLinkButton";

const FooterMenu = () => {
  const language = useSelector((state) => state.language);
  return (
    <div className="Box">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="FooterLink">
              <a href="/"><img src={Config.logoLink} alt="visa" className="paylinks-logo" /></a>
            </div>
            <div className="FooterLink" href="#">
              <img
                src={Config.visaLink}
                alt="visa"
                className="paylinks-header"
              />
              <img
                src={Config.mastercardLink}
                alt="mastercard"
                className="paylinks-header"
              />
            </div>
            <div className="FooterLinkSmall" href="#">
              This network does not serve as a helpline.
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="FooterHeading"></div>
            <div className="FooterLink" href="#">
              <SideMenuLink
                className="custom-footer-link"
                label={language.lang["join-as-clinic"]}
                to={dashboardJoinUs.path}
              />
            </div>
            <div className="FooterLink" href="#">
              <SideMenuLink
                className="custom-footer-link"
                label={language.lang["join-as-practitioner"]}
                to={dashboardJoinUs.path}
              />
            </div>
            <div className="FooterLink" href="#">
              <SideMenuLink
                className="custom-footer-link"
                label={language.lang["feedback"]}
                to={dashboardFeedback.path}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-2 col-sm-12">
            <div className="FooterHeading"></div>
            <div className="FooterLink" href="#">
              <SideMenuLink
                className="custom-footer-link"
                label={language.lang["about-us"]}
                to={dashboardAboutUs.path}
              />
            </div>
            <div className="FooterLink" href="#">
              <SideMenuLink
                className="custom-footer-link"
                label={language.lang["contact-us"]}
                to={dashboardContactUs.path}
              />
            </div>
            <div className="FooterLink" href="#">
              <SideMenuLink
                className="custom-footer-link"
                label={language.lang["support"]}
                to={dashboardSupport.path}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="FooterHeading"></div>
            <div className="FooterLink" href="#">
              {" "}
              <SideMenuLink
                className="custom-footer-link"
                label={language.lang["terms-and-conditions"]}
                to={dashboardTermsAndConditions.path}
              />{" "}
            </div>
            <div className="FooterLink" href="#">
              {" "}
              <SideMenuLink
                className="custom-footer-link"
                label={language.lang["privacy-policy"]}
                to={dashboardPrivacyPolicy.path}
              />{" "}
            </div>
            <div className="FooterLink" href="#">
              {" "}
              <SideMenuLink
                className="custom-footer-link"
                label={language.lang["cancellation-policy"]}
                to={dashboardCancelationPolicy.path}
              />{" "}
            </div>
            <div className="FooterLink" href="#"></div>
          </div>
        </div>
      </div>
      <div className="BottomContainer">
        <div className="row" style={{ textAlign: "center" }}>
          <div className="col-sm-12">
            <div
              className="FooterLinkBottom"
              style={{ marginTop: ".2rem" }}
              href="#"
            >
              ©2023 IZI HEALTH. All rights reserved.
            </div>
            <div className="FooterLinkBottom">
              <a href="https://doctorsae.com">
                <span>Product of</span>
                <img
                  src={Config.doctorsLogo}
                  alt="visa"
                  className="paylinks-header-logo"
                />
                <span> Group Ltd</span>
              </a>
            </div>
            <div className="FooterLinkBottom" href="#">
              Trade License: 000003076
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterMenu;
