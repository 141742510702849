import { AutoComplete, InputNumber, Button, Form, Input } from "antd";
import { FiChevronDown, FiPlus } from "react-icons/fi";
import { useSelector } from "react-redux";
import useIngredientName, { useIngredientDescription } from "../../../../customHooks/useIngredientName";
import MealPlanBrokenImage from "../../Shared/MealPlanBrokenImage";
import IngredientBrokenImage from "../../Shared/MealPlanBrokenImage";
import Config from "../../../../config/index";

const DrawerIngredientListItem = ({ ingredient, onAddPressed }) => {
  const language = useSelector((state) => state.language.lang);
  const getIngredientName = useIngredientName();
  const [form] = Form.useForm();
  const name = getIngredientName(ingredient);
  const getIngredientDescription = useIngredientDescription();
  const description = getIngredientDescription(ingredient);

  const firstUnit =
    ingredient.ingredientQuantityDetails && ingredient.ingredientQuantityDetails.length > 0
      ? ingredient.ingredientQuantityDetails[0].unit_name
      : "";

  const onValidFormSubmitted = (values) => {
    console.log({ values });
    const unit = values.unit ?? firstUnit;
    if (onAddPressed) {
      onAddPressed({
        quantity: values.quantity,
        unit: (ingredient.ingredientQuantityDetails ?? []).find((item) => item.unit_name === unit),
        ingredient,
      });
    }
  };

  const onFormValuesChanged = (changedValues, allValues) => {
    const unitName = changedValues.unit;
    if (!unitName) return;
    const unit = ingredient.ingredientQuantityDetails.find((item) => item.unit_name === unitName);
    if (!unit) return;
    // console.log({ unit });
  // Critical River, Varun Mishra wrong default value in add ingredients list
    form.setFieldsValue({
      ...allValues,
      quantity: unit.unit_quantity ?? 1,
    });
  };
  const truncate = (str) => {
    return (!!str && str.length) > 25 ? str.substring(0, 24) + "..." : str;
  };
  return (
    <Form form={form} onValuesChange={onFormValuesChanged} onFinish={onValidFormSubmitted}>
      <div className="drawer-ingredient-list-item">
        <MealPlanBrokenImage url={ingredient.image_url} fallbackUrl={Config.ingredientFallbackURL} name={name} className="rounded-circle chat-img" alt={name}/>
        <div className="ing-name">
          <div>{truncate(name)}</div>
          {/* Critical River, Varun Mishra desription widget of ingredient */}
          <div>{truncate(description)}</div>
        </div>
        <Form.Item
          name="quantity"
          initialValue={
            // Critical River, Varun Mishra wrong default value in add ingredients list
            ingredient.ingredientQuantityDetails && ingredient.ingredientQuantityDetails.length > 0
              ? ingredient.ingredientQuantityDetails[0].unit_quantity
              : 1
          }
          rules={[
            {
              required: true,
              message: language["please-enter-quantity"] ?? "please-enter-quantity",
            },
            () => ({
              validator(field, value) {
                if (!value) {
                  return Promise.reject(language["please-enter-quantity"] ?? "please-enter-quantity");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="unit"
          // initialValue=
          rules={[
            // {
            //   required: true,
            //   message: language["please-select-unit"] ?? "please-select-unit",
            // },
            () => ({
              validator(field, value) {
                if (!value) return Promise.resolve();
                if (!ingredient.ingredientQuantityDetails.some((item) => item.unit_name === value)) {
                  return Promise.reject(language["please-select-valid-unit"] ?? "please-select-valid-unit");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <AutoComplete
            // onClick={() => {
            //   console.log("--- clear");
            //   const fieldsValue = form.getFieldsValue();
            //   if (fieldsValue.unit) {
            //     form.setFieldsValue({ ...fieldsValue, unit: null });
            //   }
            // }}
            options={(ingredient.ingredientQuantityDetails ?? []).map((item) => {
              return {
                value: item.unit_name,
                label: item.unit_name,
              };
            })}
            // filterOption={(inputValue, option) => {
            //   return option.label.trim().toLocaleLowerCase().includes(inputValue.trim().toLocaleLowerCase());
            // }}
          >
            <Input suffix={<FiChevronDown />} placeholder={firstUnit} />
          </AutoComplete>
        </Form.Item>
        <Button htmlType="submit" className="add-ing-btn" shape="circle">
          <FiPlus />
        </Button>
      </div>
    </Form>
  );
};

export default DrawerIngredientListItem;
