import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import { GetPractitionerCurrencyCodeAndFees } from "../utility/Helpers";

const DoctorTile = (props) => {
  const {
    name,
    name_arabic,
    surname,
    courtesy_title,
    clinic,
    profile_photo_url,
    insurances = [
      {
        coveredInsurances: {
          aggregate: {
            count: 0,
          },
        },
      },
    ],
    userSpecializationsByUserId = [{ specializationById: "" }],
    rating = { aggregate: { avg: { rating: 0 } } },
    userConfigsByUserId = [
      {
        json_value: {
          individual: 0,
        },
      },
    ],
    qualifications = [{}],
  } = props.practitioner;
  useEffect(() => {
    if (clinic !== null) {
      props.setinsuranceCount(insurances[0].coveredInsurances.aggregate.count);
    }
  }, [props]);

  //const history = useHistory();
  const language = useSelector((state) => state.language);
  const [hide, sethide] = useState(false);
  const LangType = useSelector(
    (state) => state.language.general.preferredlanguage
  );
  /* useEffect(() => {
        getPractitionerDetails();
       },[])
       const getPractitionerDetails = async () =>{
        await axios.get(`${settings.apiBaseURL}/getPractitionerDetails?practitionerId=${id}`)
        .then((response) => {
          console.log(response.data.response[0],"details");
          setdetails(response.data.response[0])
         });
      }*/

  const { currencyCode, practitionerFees } = GetPractitionerCurrencyCodeAndFees(
    {
      selectedRegionID: language.regionID,
      feesWithMultiCurrency: props.practitioner.feesWithMultiCurrency,
    }
  );

  // console.log("--- currencyCode", currencyCode, practitionerFees);

  const doctorName =
    LangType === "ar" && name_arabic !== null && name_arabic !== ""
      ? name_arabic
      : `${courtesy_title|| ""} ${name} ${surname || ""}`;

  const specialization =
    userSpecializationsByUserId &&
    userSpecializationsByUserId[0] &&
    (LangType === "ar"
      ? userSpecializationsByUserId[0].specializationById.name_ar
      : userSpecializationsByUserId[0].specializationById.name);
  const hasLanguages =
    props.practitioner &&
    props.practitioner.userLanguagesByUserId &&
    props.practitioner.userLanguagesByUserId.length > 0;

  return (
    <div className="doctor-card mm-card d-flex flex-column p-0">
      <div
        className="doctor-card mm-card py-2 flex-wrap w-100 shadow-none"
        style={{ backgroundColor: "transparent" }}
      >
        <div
          className="d-flex flex-grow-1 col-12 col-lg-4"
          style={{ gap: "10px" }}
        >
          <div
            className="d-flex justify-content-start align-items-start flex-column"
            style={{ gap: "40px" }}
          >
            <div className="d-flex justify-content-center align-items-start w-100 mt-2">
              {profile_photo_url ? (
                <img
                  className="doctor-img"
                  src={profile_photo_url}
                  alt={doctorName}
                />
              ) : (
                <Avatar name={doctorName} size="100" round={true} />
              )}
            </div>
            <div
              className="d-flex justify-content-center align-items-center w-100 "
              style={{ gap: "10px", marginTop: "-12px" }}
            >
              <span className="doctor-speciality-icon blue-icons">
                <i className="fas fa-video"></i>
              </span>
              <span className="doctor-speciality-icon blue-icons">
                <i className="fas fa-phone-alt"></i>
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-start align-items-start flex-column w-100">
            <p className="doctor-name">{doctorName}</p>
            {specialization && (
              <div className="d-flex justify-content-center align-items-center">
                <span className="doctor-speciality-icon mr-3">
                  <i className="far fa-briefcase-medical"></i>
                </span>
                <span className="doctor-speciality">
                  {specialization || "None"}
                </span>
              </div>
            )}
            {/*<div className="d-flex justify-content-center align-items-center">
                  <span className="doctor-speciality-icon mr-3"><i className="far fa-ambulance"></i></span>
                  <span className="doctor-speciality">
                     15 Years of Experience
                  </span>
              </div>*/}
            {hasLanguages && (
              <div className="d-flex justify-content-center align-items-center">
                <span className="doctor-speciality-icon mr-3">
                  <i className="fal fa-language"></i>
                </span>
                <span className="doctor-speciality">
                  {hasLanguages ? (
                    props.practitioner.userLanguagesByUserId.map((language) => {
                      return (
                        <span key={language.id} className="mr-1">
                          {language?.languageById?.name}
                        </span>
                      );
                    })
                  ) : (
                    <span>None</span>
                  )}
                </span>
              </div>
            )}
            <div className="d-flex justify-content-center align-items-center">
              <span className="doctor-speciality-icon mr-3">
                <i className="fas fa-money-bill-alt"></i>
              </span>
              <span className="doctor-speciality">
                {practitionerFees === 0 || practitionerFees === undefined
                  ? "Free Consultation "
                  : +practitionerFees + " " + language.lang[currencyCode] ||
                    currencyCode}
              </span>
            </div>
          </div>
        </div>
        {!props.viewType && (
          <div className="flex-grow-1 d-flex flex-column col-12 col-lg-7 w-100">
            <div className="d-flex justify-content-start align-items-start align-items-center about-doctor">
              <div
                className={!hide ? "clampText" : ""}
                dangerouslySetInnerHTML={{
                  __html: props.practitioner.about_info,
                }}
              ></div>
              {/* <i className="far fa-medal"></i> */}
            </div>

            <div className="d-flex justify-content-between align-items-start mt-2 w-100 working-time">
              <div className="mt-3">
                <div className="head">{language.lang.workingTime}</div>
                <div className="tail">Mon-Fri 09:00 am - 08:00 pm</div>
              </div>
              <div
                className="d-flex mt-4 text-nowrap insurance-covered"
                style={{ minWidth: "max-content" }}
              >
                {clinic !== null &&
                insurances.length !== 0 &&
                insurances[0].coveredInsurances.aggregate.count > 0
                  ? `${insurances[0].coveredInsurances.aggregate.count} Insurance Covered`
                  : `${language.lang.noInsuranceCovered}`}
              </div>
            </div>
            {hide && (
              <div>
                <div className="d-flex justify-content-between align-items-center m-0 p-0 flex-column mt-1 qualifications">
                  <div className="head w-100">
                    {language.lang.expertiseAndQualifications}
                  </div>
                  <div className="d-flex flex-column w-100">
                    {qualifications && qualifications.length > 0 ? (
                      qualifications.map((quality) => {
                        return (
                          <div
                            key={quality.id}
                            className="quality w-100 d-flex justify-content-between"
                          >
                            {" "}
                            {quality.title}{" "}
                            <i className="fad fa-file-certificate"></i>
                          </div>
                        );
                      })
                    ) : (
                      <div>None Quality Available</div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {/*props.viewType && <DoctorTileCard
              slots={props.slots}
              setSlotID={props.setSlotID}
              setSelectedSlot={props.setSelectedSlot}
              setPracID={props.setPracID}
              onSelectedSlotChanged={props.onSelectedSlotChanged}
              pracDetails={props.pracDetails}
            />*/}
      </div>
      <div
        className="d-flex justify-content-center w-100 align-items-center downArrow py-2"
        onClick={() => {
          sethide(!hide);
        }}
      >
        {!hide && <i className="fas fa-chevron-down"></i>}{" "}
        {hide && <i className="fas fa-chevron-up"></i>}
      </div>
    </div>
  );
};

export default DoctorTile;
