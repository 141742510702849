import moment from "moment";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdatePatientProfile } from "../redux/language/actionCreators";
import {
  categoryMentalHealth,
  fileTypeImage,
  storageTypeImage,
  userTypeUser,
} from "../utility/constants";
import { showErrorNotification, uploadFile } from "../utility/Helpers";
import EmiratesIDImageField from "./EmiratesIDImageField";
import GenderSelectField from "./GenderSelectField";
import Loading from "./Loading";
import ProfileImageField from "./ProfileImageField";
import { useHistory } from "react-router-dom";
import { dashboardRoute } from "../routes";

const ProfileSettings = () => {
  const language = useSelector((state) => state.language);
  const userData = useSelector((state) => state.auth.userData);
  const history = useHistory();

  const dispatch = useDispatch();
  const IDRegex = RegExp(/^[0-9]{15}$/);
  const [loading, setloading] = useState(false);
  const [Input, setInput] = useState({
    name: language.profile.name,
    phone: language.profile.phone,
    email: language.profile.email,
    emiratesId: language.profile.emiratesId,
    emiratesIdExpiryDate: language.profile.emiratesIdExpiryDate,
    emiratesIdPhotoUrl: language.profile.emiratesIdPhotoUrl,
    insuranceProvider: language.profile.insuranceProvider,
    insuranceNo: language.profile.insuranceNo,
    gender: language.profile.gender,
    dob: language.profile.dob,
    profilePhotoUrl: language.profile.profilePhotoUrl,
    userId: userData.id,
  });
  const emiratesIDImageRef = useRef(null);
  const profileImageRef = useRef(null);
  const handleEmiratesIDImgChange = (file) => {
    emiratesIDImageRef.current = file;
  };
  const handleProfileIDImgChange = (file) => {
    profileImageRef.current = file;
  };
  const handleGenderChange = (gender) => {
    SetInputMethod("gender", gender);
  };
  const handleInputChange = (e) => {
    let name = e.target.name,
      value = e.target.value;
    SetInputMethod(name, value);
  };
  const SetInputMethod = (name, value) => {
    setInput((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const submitProfile = async () => {
    console.log(Input);
    setloading(true);
    for (const [key, value] of Object.entries(Input)) {
      if (
        !value &&
        key !== "gender" &&
        key !== "insuranceProvider" &&
        key !== "emiratesIdPhotoUrl" &&
        key !== "profilePhotoUrl"
      ) {
        return showErrorNotification("Fill the form fields.");
      } else if (IDRegex.test(value) === false && key === "emiratesId") {
        return showErrorNotification(
          "Emirates ID should be a number of length 15"
        );
      }
    }
    let newform = { ...Input };
    if (emiratesIDImageRef.current !== null) {
      newform.emiratesIdPhotoUrl = await uploadEmiratesID(
        emiratesIDImageRef.current
      );
    }
    if (profileImageRef.current !== null) {
      newform.profilePhotoUrl = await uploadEmiratesID(profileImageRef.current);
    }
    dispatch(UpdatePatientProfile(newform));
    setloading(false);
  };
  const uploadEmiratesID = async (emiratesIDImage) => {
    if (!emiratesIDImage) return null;
    const data = {
      storageType: storageTypeImage,
      category: categoryMentalHealth,
      userType: userTypeUser,
      fileType: fileTypeImage,
      userId: userData.id,
    };
    data["multi-files"] = emiratesIDImage;
    return uploadFile(data);
  };
  const navigateTo = () => {
    history.push(dashboardRoute.path);
  };
  return (
    <div className="profileSettings mb-2">
      <div className="">
        <div className="row">
          <div className="col">
            <div
              className={
                "custom-profile-setting-card p-1" + `${language.direction}`
              }
            >
              <section className="d-flex flex-wrap" style={{ gap: "50px" }}>
                <div className="col-12 col-lg-5 mx-auto mt-2 ml-0">
                  <div className="my-3 mx-2 w-100 d-flex align-items-center">
                    <div className="custom-profile-setting-label">
                      {language.lang.name}
                    </div>
                    <div className="custom-profile-setting-input">
                      <input
                        value={Input.name}
                        onChange={handleInputChange}
                        name="name"
                        type="text"
                        className="neumorphic-input"
                        placeholder={language.lang.name}
                        pattern="/^[A-Za-z]+$/"
                      />
                    </div>
                  </div>

                  <div className="my-3 mx-2 w-100 d-flex align-items-center">
                    <div className="custom-profile-setting-label">
                      {language.lang["phone-number"]}
                    </div>
                    <div className="custom-profile-setting-input">
                      <input
                        value={Input.phone}
                        onChange={handleInputChange}
                        name="phone"
                        className="neumorphic-input"
                        placeholder={language.lang["phone-number"]}
                        pattern="[0-9]{10}"
                      />
                    </div>
                  </div>

                  <div className="my-3 mx-2 w-100 d-flex align-items-center">
                    <div className="custom-profile-setting-label">
                      {language.lang["email-address"]}
                    </div>
                    <div className="custom-profile-setting-input">
                      <input
                        value={Input.email}
                        onChange={handleInputChange}
                        name="email"
                        type="email"
                        className="neumorphic-input"
                        placeholder={language.lang["email-address"]}
                        pattern="[0-9]{10}"
                      />
                    </div>
                  </div>

                  <div className="my-3 mx-2 w-100 d-flex align-items-center">
                    <div className="custom-profile-setting-label">
                      {language.lang["emirates-id"]}
                    </div>
                    <div className="custom-profile-setting-input">
                      <input
                        value={Input.emiratesId}
                        onChange={handleInputChange}
                        name="emiratesId"
                        className="neumorphic-input"
                        placeholder={language.lang["emirates-id"]}
                      />
                    </div>
                  </div>

                  <div className="my-3 mx-2 w-100 d-flex align-items-center">
                    <div className="custom-profile-setting-label">
                      {language.lang["expiry-date"]}
                    </div>
                    <div className="custom-profile-setting-input">
                      <input
                        value={Input.emiratesIdExpiryDate}
                        onChange={handleInputChange}
                        name="emiratesIdExpiryDate"
                        className="neumorphic-input"
                        placeholder="DD - MM - YYYY"
                        type="date"
                      />
                    </div>
                  </div>

                  <div className="my-3 mx-2 w-100 d-flex align-items-center">
                    <EmiratesIDImageField
                      url={Input.emiratesIdPhotoUrl}
                      onChange={handleEmiratesIDImgChange}
                    />
                  </div>

                  <div className="my-3 mx-2 w-100 d-flex align-items-center">
                    <div className="custom-profile-setting-label">
                      {language.lang["insurance-id"]}
                    </div>
                    <div className="custom-profile-setting-input">
                      <input
                        value={Input.insuranceNo}
                        onChange={handleInputChange}
                        name="insuranceNo"
                        className="neumorphic-input"
                        placeholder={language.lang["insurance-id"]}
                      />
                    </div>
                  </div>

                  <div className="my-3 mx-2 w-100 d-flex align-items-center">
                    <GenderSelectField
                      onChange={handleGenderChange}
                      value={Input.gender}
                    />
                  </div>

                  <div className="my-3 mx-2 w-100 d-flex align-items-center">
                    <div className="custom-profile-setting-label">
                      {language.lang["date-of-birth"]}
                    </div>
                    <div className="custom-profile-setting-input">
                      <input
                        name="dob"
                        type="date"
                        id="dob"
                        value={Input.dob}
                        onChange={handleInputChange}
                        className="neumorphic-input"
                        min={moment()
                          .subtract(100, "years")
                          .format("YYYY-MM-DD")}
                        max={moment().format("YYYY-MM-DD")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-5 mx-auto">
                  <div className="settings-profile-container p-3">
                    <div className="settings-profile">
                      <ProfileImageField
                        url={Input.profilePhotoUrl}
                        onChange={handleProfileIDImgChange}
                      />
                    </div>
                  </div>
                </div>
              </section>
              <div className="d-flex justify-content-end p-3 mr-5 mt-2">
                {!loading ? (
                  <div className="d-flex flex-wrap profile-settings-button-gap">
                    <div className="order1 p2">
                      <button
                        className="btn shadow-none custom-profile-setting-btn"
                        onClick={() => submitProfile()}
                      >
                        {language.lang.apply}
                      </button>
                    </div>
                    <div className="order2 p2">
                      <button onClick={navigateTo} className="btn shadow-none custom-profile-setting-btn btn-gray">
                        {language.lang.cancel}
                      </button>
                    </div>
                  </div>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
