import React from "react";
import { useSelector } from "react-redux";

const JoinConsultationDoctorInfoCard = () => {
  const language = useSelector((state) => state.language);
  return (
    <section className="doctor-info-card mm-card">
      <div className={"doctor-numbers"+ `${language.direction}`}>
        <i className="fal fa-user-friends"></i>
        <div className="d-flex flex-column align-items-center">
          <span className="patients-number">1000+</span>
          <span className="patients-text">{language.lang.patients}</span>
        </div>
        <div className="d-flex flex-column align-items-center ml-auto">
          <span className="patients-number">5 {language.lang.years}</span>
          <span className="patients-text">{language.lang.experience}</span>
        </div>
      </div>
      <h6 className="about-title mt-4">{language.lang["about-doctor"]}</h6>
      <div className="d-flex align-items-center">
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
        <i className="fal fa-medal p-4"></i>
      </div>
      <h6 className={"about-title mt-4"+`${language.direction}`}>{language.lang["working-time"]}</h6>
      <p>Mon Fri 09:00 am - 08:00 pm</p>
    </section>
  );
};

export default JoinConsultationDoctorInfoCard;
