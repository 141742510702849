import axios from "./Axios";
import store from "../redux/store";

export const completeStripePayment = async ({
  paymentIntent,
  amount,
  statusID = 6,
}) => {
  try {
    const response = await axios.post("/stripeElementPaymentComplete", {
      statusId: statusID,
      paymentIntent: paymentIntent,
      paymentResponse: {
        amount,
        id: paymentIntent,
      },
    });
    return response.data.response[0].transaction_id;
  } catch (e) {
    console.log("--- completeStripePayment error");
    console.log(e);
    return null;
  }
};

export const registerPayment = async ({
  amount,
  moduleType,
  moduleID,
  currency = "aed",
}) => {
  try {
    const userData = store.getState().auth.userData;
    const response = await axios.post("/stripeElementPaymentRegistration", {
      userId: userData.id,
      amount: amount,
      moduleType: moduleType,
      moduleId: moduleID,
      currency,
    });
    return response.data.response;
  } catch (e) {
    console.log("--- registerPayment error");
    console.log(e);
    return null;
  }
};

export const getStripeConfig = async () => {
  try {
    const response = await axios.get("/stripeConfig");
    return response.data.response;
  } catch (e) {
    console.log("--- getStripeConfig error");
    console.log(e);
    return null;
  }
};
