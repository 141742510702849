// import { useSelector } from "react-redux";
// import { mealPlanOnboardingRoute } from "../../../routes";

// const DietGoals = ({ dietGoals, history }) => {
//   const handleGoalClicked = (goal) => {
//     history.push(`${mealPlanOnboardingRoute.path}/diet-types`, { dietGoal: goal });
//   };

//   // console.log("--- location", history.location);

//   return (
//     <div className="diet-goals">
//       {dietGoals?.map((item) => {
//         return <DietGoalItem key={item.id} onClick={() => handleGoalClicked(item)} goal={item} />;
//       })}
//     </div>
//   );
// };

// const DietGoalItem = ({ goal, onClick }) => {
//   const languagePrefs = useSelector((state) => state.language);
//   const currentLang = languagePrefs.general.preferredlanguage;

//   let name;
//   let description;
//   if (currentLang === "ar") {
//     name = goal?.name_ar ?? goal?.name;
//     description = goal.description_ar ?? goal.description;
//   } else if (currentLang === "ku") {
//     name = goal?.name_ku ?? goal.name;
//     description = goal.description_ku ?? goal.description;
//   } else {
//     name = goal?.name;
//     description = goal.description;
//   }

//   return (
//     <div onClick={onClick} className="diet-goal-item">
//       <div className="name">{name}</div>
//       <div className="description">{description}</div>
//     </div>
//   );
// };

// export default DietGoals;
import { useState } from "react";
import { useSelector } from "react-redux";
import { mealPlanOnboardingRoute } from "../../../routes";
import BMRcalories from "./BMRcalories";


const DietGoals = ({ dietGoals, history }) => {
  const language = useSelector((state) => state.language.lang);
  const [selectedGoal, setSelectedGoal] = useState(dietGoals[0]);


  
  const handleGoalClicked = (goal) => {
    // console.log(goal)
    setSelectedGoal(goal)
    // history.push(`${mealPlanOnboardingRoute.path}/diet-types`, { dietGoal: goal });
  };

  // console.log("--- location", history.location);

  return (
    <section className="meal-paln-calories">
      <div className="diet-goals">
        {dietGoals?.map((item) => {
          return <DietGoalItem key={item.id} onClick={() => handleGoalClicked(item)} goal={item} isSelected={selectedGoal === item}/>;
        })}
      </div>
      <div>
        <BMRcalories selectedItemId={selectedGoal?.id} selectedItemName={selectedGoal?.name}/>
      </div>
    </section>
  );
};

const DietGoalItem = ({ goal, onClick,isSelected }) => {
  const languagePrefs = useSelector((state) => state.language);
  const currentLang = languagePrefs.general.preferredlanguage;

  let name;
  let image = goal.image_url;
  if (currentLang === "ar") {
    name = goal?.name_ar ?? goal?.name;
  } else if (currentLang === "ku") {
    name = goal?.name_ku ?? goal.name;
  } else {
    name = goal?.name;
  }
  return (
    <>
      <div onClick={onClick}   className={`diet-goal-item ${isSelected ? 'selected-item' : ''}`}>
        <div className="description"> {image && <img src={image} alt={name} />} </div>
        <div className="name">{name}</div>
      </div>

    </>
  );
};

export default DietGoals;
