import ReactPlayer from "react-player"
import { Carousel } from "react-responsive-carousel"
const VideoCarousel = ({data}) => {
    //data = data &&  data?.featureContent?.find(e => e.galleryItemInfo.type !== "image/png");

    return (
        <div>
        <Carousel className="py-3" showThumbs={false} infiniteLoop={true} showArrows={false}  style={{height:'600px'}}>
            {data && data.featureContent && data.featureContent.filter(e => e.galleryItemInfo.type !== "image/png").map((item,i) =>{
                return (
                <div className="position-relative" style={{height:'500px'}} key={i}>
                   
                    {item.galleryItemInfo.type ==="video/mp4" ? <ReactPlayer
                        volume={ 50 / 100 }
                        muted={false}
                         controls={true}
                        width="800px"
                        height="500px"
                        style={{objectFit:'cover'}} className="w-100 h-100"
                        url={item.galleryItemInfo.url}
                    /> :
                 ( <img width="100%" height="100%" src={item.galleryItemInfo.url} style={{objectFit:'cover'}} />) }
                    {/*<div className="position-absolute fitness-duration">{item.time}</div>*/}
                </div>)
            })}
        </Carousel>
        </div>
    )
}

export default VideoCarousel
