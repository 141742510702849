import { useSelector } from "react-redux";

const MostPopularCard = ({ videoCard, className, recipe }) => {
  const language = useSelector((state) => state.language);
  return (
    <div className="content-card h-list-item  h-100">
      <img
        className="item-img"
        src={recipe.session_image_url}
        alt="Popular Item"
      />
      <div className="d-flex">
        <div className="content w-100">
          <div
            className="d-flex justify-content-between"
            style={{ height: "auto" }}
          >
            <p className="category-name category-name-yel">
              {recipe.sessionCategoryName.category}
            </p>
            <p className="paid category-name ">
              {recipe.paid || recipe.is_paid ? `AED ${recipe.amount}` : "Free"}
            </p>
          </div>
          <h6 className="title">{recipe.title.substring(0, 20) + "..."}</h6>
          <div className="description" style={{ height: "2rem" }}>
            {recipe.description.substring(0, 50) + "..."}
          </div>
          <div
            className="d-flex justify-content-end w-100"
            style={{ height: "fit-content", bottom: "0" }}
          >
            <button type="button" className="btn btn-primary">
              {language.lang["view-details"]}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostPopularCard;
