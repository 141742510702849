import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserNotificationsAction, ResetNotificationsAction, } from "../../../redux/user/actionsCreators";
import NotificationsSideMenu from "../../NotificationsSideMenu";
import Config from '../../../config/index';

const TopMenuNotification = ({ className }) => {
  const dispatch = useDispatch();
  const [openSideMenu, setOpenSideMenue] = useState(false);
  const userNotifications = useSelector((state) => state.user.notifications);
  const userNotificationCount = useSelector(
    (state) => state.user.notification_count
  );
  const language = useSelector((state) => state.language);

  useEffect(() => {
    FetchNotifications();
  }, []);

  const FetchNotifications = () => {
    dispatch(fetchUserNotificationsAction());
  };

  const closeSideMenu = () => {
    if (!openSideMenu) return;
    setOpenSideMenue(false);
  };

  const handleBtnPressed = () => {
    setOpenSideMenue(true);
    FetchNotifications();
    dispatch(ResetNotificationsAction());
  };

  const notificationsList =
    (userNotifications && userNotifications.notificationDetails) || []; // .slice(0, 100);

  // notificationsList = notificationsList.filter((item) => item.details !== "");
  const notificationsCount =
    (notificationsList &&
      notificationsList.filter((notification) => !notification.is_viewed)
        .length) ||
    0;

  // console.log("--- count ", notificationsCount, notificationsList);
  return (
    <>
      <NotificationsSideMenu
        isOpened={openSideMenu}
        onClosePressed={closeSideMenu}
        userNotifications={userNotifications}
      />
      <button
        onClick={handleBtnPressed}
        className={`btn topMenuNotificationBtn ${className}`}
      >
        <img src={Config.bellbutton} alt="BellButton" className="circle-button"/>
        {userNotificationCount && userNotificationCount > 0 ? (
          <div
            className="topMenuNotificationCount"
            style={
              language.direction === " direction-rtl"
                ? {
                    right: "-10px",
                  }
                : {}
            }
          >
            {userNotificationCount}
          </div>
        ) : null}
      </button>
    </>
  );
};

export default TopMenuNotification;
