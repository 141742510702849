import FacebookSigninButton from "./FacebookSigninButton";
import GoogleSigninButton from "./GoogleSigninButton";
// import { logOutAction } from "../redux/authenitcation/actionCreator";
//const LOADING_STATE = "LOADING_STATE";
//const INITIAL_STATE = "INITIAL_STATE";

const SocialAuthButtons = (props) => {
// Critical River, Varun Mishra, Routing Issue, user is not redirected to previous page before login pop-up
  const { nextRoute, practitioner = {}, selectedSlot = {} } = props;
  //const [uiState, setUIState] = useState(INITIAL_STATE);
  //setUIState(LOADING_STATE);

  return (
    <div className="auth-buttons d-flex justify-content-center mb-3">
      <FacebookSigninButton />
      <div style={{ width: "20px" }} />
  {/* Critical River, Varun Mishra, Routing Issue, user is not redirected to previous page before login pop-up */}
      <GoogleSigninButton nextRoute={nextRoute} practitioner={practitioner} selectedSlot={selectedSlot} />
    </div>
  );
};

export default SocialAuthButtons;
