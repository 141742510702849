import { useHistory } from "react-router-dom";
import { dashboardBookConsultationRoute } from "../routes";
import { useDispatch, useSelector } from "react-redux";
import actions from "../redux/patient/actions";
import VerifyBrokenImage from "./VerifyBrokenImage";
import { GetPractitionerCurrencyCodeAndFees } from "../utility/Helpers";

const TopDoctorCards = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const { currentSelectedRegion, info } = props;
  const { currencyCode, practitionerFees } = GetPractitionerCurrencyCodeAndFees(
    {
      selectedRegionID: currentSelectedRegion,
      feesWithMultiCurrency: info.feesWithMultiCurrency,
    }
  );

  // console.log("--- practitionerFees-", props.info);

  const bookNowPressed = () => {
    history.push(`${dashboardBookConsultationRoute.path}/${info.url_name}`, {
      practitioner: props.info,
    });
    addSelectedPractitioner();
  };

  const addSelectedPractitioner = () => {
    dispatch({
      type: actions.SET_SELECTED_PRACTITIONER,
      payload: {
        selectedPractitioner: props.info,
      },
    });
  };

  const truncate = (str) => {
    return str.length > 10 ? str.substring(0, 2) + "..." : str;
  };
  // const [details, setdetails] = useState({});
  // useEffect(() => {
  //   props && props.info && getPractitionerDetails();
  // }, [props]);
  // const getPractitionerDetails = async () => {
  //   await axios.get(`${settings.apiBaseURL}/getPractitionerDetails?practitionerId=${props.info.id}`).then((response) => {
  //     //console.log(response.data.response[0]);
  //     setdetails(response.data.response[0]);
  //   });
  // };
  const langType = useSelector(
    (state) => state.language.general.preferredlanguage
  );
  return (
    <div className="doctor-card mm-card d-flex align-items-center flex-wrap position-relative homelist custom-card-styling-pract">
      <div className="d-flex  flex-column">
        {/* {props.info.profile_photo_url ? (
          <img className="doctor-img" src={props.info.profile_photo_url} alt={props.info.name} />
        ) : (
          <Avatar className="mr-2" name={props.info.name} size="50" round={true} />
        )} */}
        <VerifyBrokenImage
          className="doctor-img"
          url={props.info.profile_photo_url}
          name={
            (!!props.info.name && props.info.name) +
            " " +
            (!!props.info.surname ? props.info.surname : "")
          }
        />
        <div className="d-flex justify-content-center align-items-center position-absolute online-tag-align">
          <div
            className={props.info.is_online ? "online-green-tag" : "away-tag"}
          ></div>
          <div
            className={
              props.info.is_online ? "online-text-tag" : "offline-text-tag"
            }
          >
            {props?.info?.is_online ? language.lang.online : "Away"}
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center position-absolute online-tag-align">
          <div className="fees-align">
            {/* {props.info.rating && props.info.rating.aggregate && props.info.rating.aggregate.avg && props.info.rating.aggregate.avg.rating && (
              <div className="d-flex justify-content-center align-items-center" style={{ gap: "5px" }}>
                <i className="fas fa-star"></i>
                <span className="rating">{props.info.rating.aggregate.avg.rating}</span>
              </div>
            )} */}
            {practitionerFees ? (
              // Critical River, Varun Mishra, Currency Bug for Iraq
              // iraqi dinar currency value is goes out of the card.
              <div className="fees-amount" style={{ width: "5rem" }}>
                {`${language.lang[currencyCode] || currencyCode} ${
                  practitionerFees ? practitionerFees : ""
                }`}
              </div>
            ) : (
              <div className="fees-amount online-text-tag">
                {language.lang.free}
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <div className="pt-2">
              <h6 className="doctor-name top-doc-style text-truncate">
                {(!!props.info.name ? props.info.name : "") +
                  truncate(
                    !!props.info.surname ? " " + props.info.surname : ""
                  )}
              </h6>
              <h6 className="doctor-name w-100 top-doc-style">
                <span className="clampProfession text-truncate">
                  {props &&
                    props.info &&
                    props.info.userSpecializationsByUserId &&
                    props.info.userSpecializationsByUserId[0] &&
                    props.info.userSpecializationsByUserId[0]
                      .specializationById &&
                    ((langType=='ar') ? props.info.userSpecializationsByUserId[0].specializationById.name_ar : 
                    (langType=='ku') ? props.info.userSpecializationsByUserId[0].specializationById.name_ku :
                    props.info.userSpecializationsByUserId[0].specializationById.name)
                    }
                    
                </span>
              </h6>
            </div>
          </div>
          {true && <p className="call-type">{}</p>}
          {true && <p className="appointment-time">{}</p>}
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center w-100">
        {
          <button
            onClick={bookNowPressed}
            className="btn mt-2 quick-book"
            type="button"
            disabled={false}
          >
            {language.lang.book}
          </button>
        }
      </div>
    </div>
  );
};

export default TopDoctorCards;
