import { useState, useEffect } from "react";
import WorkDayItem from "./WorkDayItem";
import TimeSlotItem from "./TimeSlotItem";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useSelector } from "react-redux";

const WorkDayItemList = ({
  slotMap,
  setSlotID,
  setPracID,
  setSelectedSlot,
  onSelectedChanged,
  selectedSlot,
  slotnumber,
  setMonth,
  getMonth,
  setslotnumber,
}) => {
  const [selected, setSelected] = useState([]);
  const [slotArr, setSlotArr] = useState([]);
  const [morningSlots, setmorningSlots] = useState([]);
  const [eveSlots, seteveSlots] = useState([]);
  const userData = useSelector((state) => state.auth.userData);
  const isLoggedIn = useSelector((state) => state.auth.login);
  const Patient = useSelector((state) => state.patient);
  const [increment, setIncrement] = useState(0);
  useEffect(() => {
    if (isLoggedIn) {
      setIncrement(userData?.user_group === "test" ? 0 : 0);
    } else {
      setIncrement(0);
    }
  }, [isLoggedIn]);
  //let increment = userData?.user_group === "test" ? 0 : 1;
  const [slotsSelected, setSlotsSelected] = useState([]);

  const onSelectSlot = (slotId, length, index, pracID, slot) => {
    //check auth here
    setSlotID(slotId);
    setPracID(pracID);
    setSelectedSlot(slot);
    let slots_arr = [];
    for (let i = 0; i < length; i++) {
      slots_arr.push(false);
    }
    slots_arr[index] = true;
    setSlotsSelected(slots_arr);
    if (onSelectedChanged) onSelectedChanged(slots_arr);
  };

  useEffect(() => {
    let morningslots =
      slotArr &&
      slotArr.length > 0 &&
      slotArr[Patient?.slotnumber || slotnumber]?.value.filter(function (
        element
      ) {
        return (
          moment(element.start_time) > moment().add(increment, "hours") &&
          moment(element.start_time).format("HH") < 13
        );
      }); /*.filter((a) => moment(a.start_time).hours() < 13)*/
    let eveslots =
      slotArr &&
      slotArr.length > 0 &&
      slotArr[Patient?.slotnumber || slotnumber]?.value.filter(function (
        element
      ) {
        return (
          moment(element.start_time) > moment().add(increment, "hours") &&
          moment(element.start_time).format("HH") >= 13
        );
      }); /*.filter((a) => moment(a.start_time).hours() >= 13)*/
    setmorningSlots(morningslots);
    seteveSlots(eveslots);
    if (
      Object.keys(selectedSlot).length > 0 &&
      slotArr.length > 0 &&
      slotnumber >= 0
    ) {
      let index = slotArr[Patient?.slotnumber || slotnumber].value.findIndex(
        (a) => a.slot_id === Patient?.selectedSlot.slot_id
      );
      let ln = 0;
      if (moment(Patient?.selectedSlot.start_time).format("HH") <= 13) {
        ln = morningSlots.length;
      } else {
        ln = eveslots.length;
      }
      onClickWorkDayItem(Patient?.slotnumber);
      onSelectSlot(
        Patient?.selectedSlot.slot_id,
        ln,
        index,
        Patient?.selectedSlot.practionerId,
        Patient?.selectedSlot
      );
    } else {
      onClickWorkDayItem(0);
    }
  }, [slotArr, Patient]);
  const onClickWorkDayItem = (key) => {
    let arr = [];
    for (let i = 0; i < slotMap.size; i++) {
      arr.push(false);
    }
    setslotnumber(key);
    arr[key] = true;
    setSelected(arr);
    setSlotsSelected([]);
  };

  useEffect(() => {
    let selected_arr = [];
    for (let i = 0; i < slotMap.size; i++) {
      selected_arr.push(false);
    }
    // selected_arr[0]=true;
    setSelected(selected_arr);

    let count = 0;
    let slot_arr = [];
    slotMap.forEach((value, key) => {
      slot_arr.push({
        index: count,
        date: key,
        value: value,
      });
      count++;
    });
    setSlotArr(slot_arr);
    if (Object.keys(selectedSlot).length === 0) {
      if (slot_arr.length > 0) {
        let rt = slot_arr.sort((a, b) => moment(a.date) - moment(b.date));
        setMonth(moment(rt[0].date).format("MMMM"));
      }
    } else {
      setMonth(moment(selectedSlot.start_time).format("MMMM"));
    }
  }, [slotMap]);
  useEffect(() => {
    // setSelected([]);
    // onClickWorkDayItem(1);
  }, [getMonth]);

  return (
    <>
      <div className="h-list pb-2 custom-scroll">
        {slotArr
          .sort((a, b) => moment(a.date) - moment(b.date))
          //.filter(a => moment(a.date).format("MMMM") === getMonth)
          .map((el, i) => {
            // console.log(moment(el.date).format("MMMM"))
            // if (moment(el.date).format("MMMM") === getMonth) {
            //   return <WorkDayItem key={uuidv4()} date={el.date} onClickWorkDayItem={onClickWorkDayItem} selected={selected[i]} count={i} selectedMonth = {getMonth} />;
            // }
            return (
              <WorkDayItem
                key={uuidv4()}
                date={el.date}
                onClickWorkDayItem={onClickWorkDayItem}
                selected={selected[i]}
                count={i}
              />
            );
          })}
      </div>
      {selected && selected.length > 0 ? (
        <div>
          {(morningSlots.length > 0 ||
            moment().format("DD") ===
              moment(selectedSlot?.start_time).format("DD")) && (
            <>
              {/* <div className="day-type"><span ><i className="far fa-sun"></i></span><span className="head">{language.lang.morning}</span></div>*/}
              <div className="time-slots mt-4">
                {selected.map((el, index) => {
                  if (el) {
                    return (
                      slotArr[index].value
                        // .filter(function (element) {
                        //   return moment(element.start_time) > moment().add(increment, "hours");
                        // }) /*.filter(function (a) { if(a.is_available) return a})*/
                        .map((element, i) => {
                          if (
                            moment(element.start_time).hours() < 13 &&
                            element.is_available &&
                            moment(element.start_time) >
                              moment().add(increment, "hours")
                          ) {
                            return (
                              <TimeSlotItem
                                key={uuidv4()}
                                start_time={moment(element.start_time).format(
                                  "hh:mm a"
                                )}
                                end_time={moment(element.end_time).format(
                                  "hh:mm a"
                                )}
                                is_available={element.is_available}
                                slotId={element.slot_id}
                                onSelectSlot={onSelectSlot}
                                length={slotArr[index].value.length}
                                index={i}
                                selected={slotsSelected[i]}
                                pracID={element.practionerId}
                                slot={element}
                              />
                            );
                          }
                        })
                    );
                  }
                })}
              </div>
            </>
          )}
          {eveSlots.length > 0 && (
            <>
              {/*<div className="day-type mt-2"><span ><i className="far fa-moon"></i></span><span className="head">{language.lang.evening}</span></div>*/}
              <div className="time-slots mt-4">
                {selected.map((el, index) => {
                  if (el) {
                    return (
                      slotArr[index].value
                        // .filter(function (element) {
                        //   return moment(element.start_time) > moment().add(increment, "hours");
                        // }) /*.filter(function (a) { if(a.is_available) return a})*/
                        .map((element, i) => {
                          if (
                            moment(element.start_time).hours() >= 13 &&
                            element.is_available &&
                            moment(element.start_time) >
                              moment().add(increment, "hours")
                          ) {
                            return (
                              <TimeSlotItem
                                key={uuidv4()}
                                start_time={moment(element.start_time).format(
                                  "hh:mm a"
                                )}
                                end_time={moment(element.end_time).format(
                                  "hh:mm a"
                                )}
                                is_available={element.is_available}
                                slotId={element.slot_id}
                                onSelectSlot={onSelectSlot}
                                length={slotArr[index].value.length}
                                index={i}
                                selected={slotsSelected[i]}
                                pracID={element.practionerId}
                                slot={element}
                              />
                            );
                          }
                        })
                    );
                  }
                })}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="w-100 d-flex justify-content-center align-items-center mt-3">
          {slotArr.filter((a) => moment(a.date).format("MMMM") === getMonth)
            .length > 0
            ? "Select a Day Slot"
            : "No Slots Available"}
        </div>
      )}
    </>
  );
};

export default WorkDayItemList;
