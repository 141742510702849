import { getAuth } from "firebase/auth";
import axios from "../../services/Axios";
import actions from "./actions";
import { localStorageErrorMsg, userDataStorageKey } from "../../utility/constants";
import { getAuthDataFromResponse, storeAuthDataInLocalStorage } from "../../utility/Helpers";
import store from "../store";
import { UpdateGeneralSettings } from "../language/actionCreators";

const { loginBegin, loginSuccess, loginErr, logoutSuccess } = actions;

export const setUserDataAction = ({ userData }) => {
  return {
    type: actions.SET_USER_DATA,
    payload: userData,
  };
};

export const updateUserLanguageAction = ({ language, currentUser }) => {
  const updatedUser = Object.assign({}, currentUser, {
    preferred_language: language,
  });
  localStorage.setItem(userDataStorageKey, JSON.stringify(updatedUser));
  return {
    type: actions.UPDATED_USER_LANGUAGE,
    payload: language,
  };
};

export const updateUserRegionAction = ({ regionID, currentUser }) => {
  const updatedUser = Object.assign({}, currentUser, {
    region_id: regionID,
  });
  localStorage.setItem(userDataStorageKey, JSON.stringify(updatedUser));
  return {
    type: actions.UPDATE_USER_REGION,
    payload: regionID,
  };
};

export const setSocketIDAction = (socketID) => {
  return { type: actions.SET_SOCKET_ID, payload: socketID };
};

const login = (email, password, corporateID) => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin());
      const res = await axios.post(`/login`, {
        email,
        password,
        corporateId: corporateID,
      });
      if (res.data.response.error) {
        dispatch(loginErr(res.data.response.error));
      } else {
        const { response } = res.data;
        // set the data in the local storage instead of cookie
        const authData = getAuthDataFromResponse(response);
        if (typeof Storage !== "undefined" && authData.userData.is_approved) {
          storeAuthDataInLocalStorage(response);
          dispatch(loginSuccess(authData));
          const userPrefs = {
            preferredlanguage: authData.userData.preferred_language,
            regionId: authData.userData.region_id,
            theme: authData.userData.theme,
          };
          dispatch(UpdateGeneralSettings(userPrefs, false));
          return authData;
        } else {
          console.log(localStorageErrorMsg);
          dispatch(loginErr(null));
          return authData;
        }
      }
    } catch (err) {
      dispatch(loginErr(err));
      return err.response.data.error;
    }
  };
};

export const logout = async () => {
  const { userData, socketID, accessToken } = store.getState().auth;
  try {
    const fireAuth = getAuth();
    const res = await Promise.all([
      axios.post("/logoutPasswordless", {
        // refreshToken: accessToken,
        userId: userData.id,
        socketId: socketID,
        accessToken,
      }),
      fireAuth.signOut(),
    ]);
    return res[0].data.response !== null;
  } catch (e) {
    // debugger;
    console.log("--- logout error");
    console.log(e);
    return false;
  }
};

const logOutAction = () => {
  logout();
  localStorage.clear();
  return logoutSuccess(null);
  // return async (dispatch) => {
  //   try {
  //     dispatch();
  //   } catch (err) {
  //     dispatch(logoutErr(err));
  //   }
  // };
};

export { login, logOutAction };
