import { useSelector } from "react-redux";
import TopMenu from "./CommonControls/TopMenu";
const PrivacyPolicy = () => {
  const language = useSelector((state) => state.language);
  const { loading } = useSelector((state)=>state.language);
  return (
    <section className="container-fluid mb-4 px-4">
      <div className="row">
        <TopMenu
          shouldHideBackButton={false}
          shouldHideProfileButton={false}
          shouldHideNotificationButton={false}
          shouldHideLogoButton={false}
          pageTitle={language.lang.PrivacyPolicy}
        />
      </div>
      <div className="d-flex px-4 m-0 mobile-px-none">
          <div className="footer-container mb-4 glossy-style">
            <div className="row">
            <div class="todo-timeline mb-5">
              <div class="row">
                <div class="row">
                  <ul class="v-timeline">
                    <li class="col">
                      <span class="v-timeline-icon"></span>
                      <span class="pl-30 top-bold-label">{language.lang.PrivacyPolicy}</span>
                      <p class="pl-30 mid-paragraph"><p>
                        {language.lang.PrivacyPolicyPara1}
                      </p><p>{language.lang.PrivacyPolicyPara2}
                      </p><p>{language.lang.PrivacyPolicyPara3}
                      </p>
                      </p>
                    </li>
                    <li class="col">
                      <span class="v-timeline-icon"></span>
                      <span class="pl-30 top-bold-label">{language.lang.PrivacyPolicyPara4}</span>
                      <p class="pl-30 mid-paragraph"> 
                        <p>1.{language.lang.PrivacyPolicyPara5}
                        </p><p>2.{language.lang.PrivacyPolicyPara6}
                        </p><p>3.{language.lang.PrivacyPolicyPara7}
                        </p><p>4.{language.lang.PrivacyPolicyPara8}
                        </p><p>5.{language.lang.PrivacyPolicyPara9}
                        </p>
                        <p>
                          {language.lang.PrivacyPolicyPara10}
                        </p>
                      </p>
                    </li>
                    <li class="col">
                      <span class="v-timeline-icon"></span>
                      <span class="pl-30 top-bold-label">{language.lang.PrivacyPolicyPara11}</span>
                      <p class="pl-30 mid-paragraph">
                        <p>{language.lang.PrivacyPolicyPara12}</p> 
                        <p>1.{language.lang.PrivacyPolicyPara13}</p>
                        <p>2.{language.lang.PrivacyPolicyPara14}
                          </p><p>3.{language.lang.PrivacyPolicyPara15}
                          </p><p>4.{language.lang.PrivacyPolicyPara16}
                          </p><p>5.{language.lang.PrivacyPolicyPara17}
                          </p><p>6.{language.lang.PrivacyPolicyPara18}
                          </p><p>7.We use <a href="https://www.apple.com/legal/privacy/data/en/apple-pay/">Apple Pay</a>/ <a href="https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en-GB">Google Pay</a> for offline events, appointments and diet planning charges
                          </p>
                        </p>
                    </li>
                    <li class="col">
                      <span class="v-timeline-icon"></span>
                      <span class="pl-30 top-bold-label">{language.lang.PrivacyPolicyPara19}</span>
                      <p class="pl-30 mid-paragraph">
                        <p>{language.lang.PrivacyPolicyPara20}</p> 
                      </p>
                    </li>
                    <li class="col">
                      <span class="v-timeline-icon"></span>
                      <span class="pl-30 top-bold-label">{language.lang.PrivacyPolicyPara21}</span>
                      <p class="pl-30 mid-paragraph">
                        <p>{language.lang.PrivacyPolicyPara22}</p> 
                      </p>
                    </li>
                    <li class="col">
                      <span class="v-timeline-icon"></span>
                      <span class="pl-30 top-bold-label">{language.lang.PrivacyPolicyPara23}</span>
                      <p class="pl-30 mid-paragraph">
                        <p>{language.lang.PrivacyPolicyPara24}</p>
                        <p>{language.lang.PrivacyPolicyPara25}</p>
                      </p>
                    </li>
                    <li class="col">
                      <span class="v-timeline-icon"></span>
                      <span class="pl-30 top-bold-label">{language.lang.PrivacyPolicyPara26}</span>
                      <p class="pl-30 mid-paragraph">
                        <p>{language.lang.PrivacyPolicyPara27}</p> 
                      </p>
                    </li>
                    <li class="col">
                      <span class="v-timeline-icon"></span>
                      <span class="pl-30 top-bold-label">{language.lang.PrivacyPolicyPara28}</span>
                      <p class="pl-30 mid-paragraph">
                        <p>{language.lang.PrivacyPolicyPara29}</p> 
                      </p>
                    </li>
                    <li class="col">
                      <span class="v-timeline-icon"></span>
                      <span class="pl-30 top-bold-label">{language.lang.PrivacyPolicyPara30}</span>
                      <p class="pl-30 mid-paragraph">
                        <p>{language.lang.PrivacyPolicyPara31}</p> 
                      </p>
                    </li>
                    <li class="col">
                      <span class="v-timeline-icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>);
};

export default PrivacyPolicy;
