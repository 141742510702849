import { AiOutlineClose } from "react-icons/ai";

export const SelectedCustomExclusionItem = ({ name, onRemoveClicked }) => {
  return (
    <div onClick={() => onRemoveClicked(name)} className="custom-exclusion-item">
      <div className="name">{name}</div>
      <div className="remove">
        <AiOutlineClose />
      </div>
    </div>
  );
};
