import actions from "./actions";
import store from "../store";
import axios from "../../services/Axios";
import { settings } from "../../config";

export const setLoadingNotificationsAction = ({ loading }) => {
  return {
    type: actions.SET_LOADING_NOTIFICATIONS,
    payload: loading,
  };
};

const fetchUserNotificationsAction = () => {
  return async (dispatch) => {
    const state = store.getState();
    const userID = state.auth.userData && state.auth.userData.id;
    if (!userID) return;
    const notifications = state.user.notifications;
    if (notifications && notifications.notificationAggregate) return;
    const res = await axios.get("/getUserNotifications", {
      params: { userId: userID },
      headers: {
        preferredLanguage: state.auth.userData.preferred_language,
      },
    });
    if (!res?.data?.response) return;
    dispatch({
      type: actions.FETCH_USER_NOTIFICATIONS,
      payload: res.data.response,
    });
  };
};

const ResetNotificationsAction = () => {
  return async (dispatch) => {
    dispatch({
      type: actions.RESET_NOTIFICATIONS,
    });
    //  const { id } = store.getState().auth.userData;
    //  const res = await axios.post("/clearUserNotifications", { userId: id });
    //console.log("--- clearUserNotifications", res.data);
  };
};

const UpdateNotificationsAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_USER_NOTIFICATIONS,
      payload: payload,
    });
  };
};

const ShowOldNotificationsAction = (condition) => {
  return (dispatch) => {
    dispatch({
      type: actions.SHOW_OLD_NOTIFICATIONS,
      payload: condition,
    });
  };
};

const FetchOldNotificationsAction = () => {
  return async (dispatch) => {
    const state = store.getState();
    const userID = state.auth.userData && state.auth.userData.id;
    if (!userID) return;
    const response = await axios.get(
      `${settings.apiBaseURL}/getUserPastNotifications?userId=${userID}`,
      {
        headers: {
          preferredLanguage: state.auth.userData.preferred_language,
        },
      }
    );
    dispatch({
      type: actions.FETCH_OLD_NOTIFICATIONS,
      payload: response.data.response.notificationDetails,
    });
  };
};

const resetOldNotificationsAction = (id, prefer) => {
  return async (dispatch) => {
    const response = await axios.get(
      `${settings.apiBaseURL}/getUserPastNotifications?userId=${id}`,
      {
        headers: {
          preferredLanguage: prefer,
        },
      }
    );
    dispatch({
      type: actions.FETCH_OLD_NOTIFICATIONS,
      payload: response.data.response.notificationDetails,
    });
  };
};
const resetnewNotificationsAction = (id, prefer) => {
  return async (dispatch) => {
    const res = await axios.get(
      `${settings.apiBaseURL}/getUserNotifications?userId=${id}`,
      {
        headers: {
          preferredLanguage: prefer,
        },
      }
    );
    dispatch({
      type: actions.FETCH_USER_NOTIFICATIONS,
      payload: res ? res.data.response : null,
    });
  };
};
const setsidebar = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_SIDEBAR,
      payload: payload,
    });
  };
};
export {
  fetchUserNotificationsAction,
  ResetNotificationsAction,
  ShowOldNotificationsAction,
  FetchOldNotificationsAction,
  UpdateNotificationsAction,
  resetOldNotificationsAction,
  resetnewNotificationsAction,
  setsidebar,
};
