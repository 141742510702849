const MMRadioButton = ({ label, selected, classes, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`mm-radio-button ${classes ? classes : ""}`}
    >
      <div className={`radio ${selected ? "selected" : ""} `}>
        {selected && <i className="fas fa-check text-light"></i>}
      </div>
      <span className="label">{label}</span>
    </div>
  );
};

export default MMRadioButton;
