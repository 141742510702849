import {useSelector } from "react-redux";
import TopMenu from "./CommonControls/TopMenu";

const AboutUs = () => {
  const language = useSelector((state) => state.language);
    return (
    <section className="container-fluid mb-4 px-4">
      <div className="row">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.lang["about-us"]}
      />
      </div>
      <div className="d-flex justify-content-between px-4 m-0 align-items-center mobile-px-none">
      <div className="d-flex align-items-center">
       <div className="footer-container mb-4 glossy-style screen-min-height">
         <div className="row">
         <h3 className="mt-2 mb-4">{language.lang["about-us"]}</h3>
          <h5>
          {language.lang.aboutUsPara1}
            
          </h5>
        </div>
        <div className="row">
          <p className="mt-2 mb-4">
          {language.lang.aboutUsPara2}
            </p>
          </div>
          <ul className="ml-4" style={{listStyleType:"square"}}>
          <li className="mt-2">{language.lang.aboutUsPara3}
          </li><li className="mt-2">{language.lang.aboutUsPara4}
          </li><li className="mt-2">{language.lang.aboutUsPara5}
          </li><li className="mt-2">{language.lang.aboutUsPara6}
          </li>
          </ul>
       </div>
       </div>
        </div>
  </section>);
};

export default AboutUs;
