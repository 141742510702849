import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import config, { settings } from "../../config";
import { FaAddressCard } from "react-icons/fa";
import { customerCalendarRoute, paymentInfoRoute } from "../../routes";
import axios from "axios";
import PaymentCard from "./PaymentCard";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { useHistory } from "react-router-dom";
import { setCompletedTransaction } from "../../redux/client/actionsCreators";

export default function CheckoutForm({
  fee,
  setClientSecret,
  paramsclientSecret,
  payStatus,
}) {
  const history = useHistory();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const completedTransaciton = useSelector(
    (state) => state.client.completedTransaciton
  );
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [Results, setResults] = useState(false);
  // const paramsclientSecret = new URLSearchParams(window.location.search).get(
  //   "payment_intent_client_secret"
  // );
  useEffect(() => {
    // if (!stripe) {
    //   return;
    // }
    if (!paramsclientSecret) {
      return;
    }
    setResults(true);
    stripe
      ?.retrievePaymentIntent(paramsclientSecret)
      .then(({ paymentIntent }) => {
        console.log("paymentIntent", paymentIntent);
        let payload = {
          paymentIntent: paymentIntent.id,
          statusId: paymentIntent.status === "succeeded" ? 6 : 7,
          paymentResponse: paymentIntent,
        };
        completePayment(payload);
      });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}${paymentInfoRoute.path}`,
      },
    });
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }
    setIsLoading(false);
  };

  const completePayment = async (payload) => {
    console.log("completePayment", payload);
    try {
      const res = await axios.post(
        `${settings.apiBaseURL}/stripeElementPaymentComplete`,
        payload
      );
      const data = res.data.response;
      console.log("Payment Done", data);
    } catch (e) {
      console.log("--- registerPayment error");
      console.log(e);
    }
    dispatch(setCompletedTransaction({}));
    localStorage.setItem("PublishKey", "");
    setResults(false);
  };

  return (
    <>
      {Object.keys(completedTransaciton).length === 0 ? (
        <div className="w-100 rounded-0 payment-card d-flex justify-content-center flex-wrap-reverse">
          {!Results ? (
            <>
              <div className="d-flex flex-column col-sm-12 col-lg-6 mx-auto description-content">
                <p className="font-weight-normal py-3">
                  Choose your convenient option to pay
                </p>
                <p className="pb-2">
                  The easy-to-use contactless digital payment option is
                  available from within the app to the millions of customers.
                </p>
                <p className="font-weight-bold">
                  You can pay using the following options
                </p>
                <div>
                  <img
                    src={config.mastercardLink}
                    alt="mastercard"
                    className="payment-service ml-md-2"
                  />
                  <img
                    src={config.visaLink}
                    alt="visa"
                    className="payment-service ml-md-4"
                  />
                </div>
              </div>
              <div className="d-flex flex-column col-sm-12 col-lg-6 field-content align-items-center">
                <form
                  id="payment-form"
                  className="w-100"
                  onSubmit={handleSubmit}
                >
                  <div className="w-100 payment-container">
                    <div className="payment-card card-card d-flex justify-content-center align-items-center m-3">
                      <div>
                        <FaAddressCard size={30} />
                      </div>
                      <div>Card</div>
                    </div>
                    {/* <PaymentInputField
                  label="Card Number"
                  parentclassName="col-12 py-3"
                  field={(<input className="neumorphic-input w-100" placeholder="1234 1234 1234 1234"/>)}
                  placeholderImages={
                    <>
                    <img src={config.mastercardLink} alt="mastercard" />
                    <img src={config.visaLink} alt="visa" />
                    </>
                  }
                />
                <div className="d-flex justify-content-center align-items-center flex-wrap py-4">
                <PaymentInputField
                  label="Expiry"
                  parentclassName="col-md-8"
                  field={(<input className="neumorphic-input w-100" placeholder="MM/YY"/>)}
                />
                 <PaymentInputField
                  label="CVC"
                  parentclassName="col-md-4"
                  field={(<input className="neumorphic-input w-100" placeholder="CVC"/>)}
                  placeholderImages={(<FaCreditCard size={30} color="#18b9dd"/>)}
                />
                </div>
                <PaymentInputField
                  label="Country"
                  parentclassName="col-12"
                  field={(<input className="neumorphic-input w-100" placeholder="Country"/>)}
                  placeholderImages={(<FaChevronDown size={25} color="#999"/>)}
                /> */}
                    <PaymentElement id="payment-element" />
                  </div>
                  {!isLoading ? (
                    <div
                      className="w-100 d-flex justify-content-center align-items-center mt-5"
                      style={{ gap: 20 }}
                    >
                      <button
                        className="payment-btn cancel"
                        onClick={() => {
                          history.replace(customerCalendarRoute.path);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={isLoading || !stripe || !elements}
                        id="submit"
                        className="payment-btn"
                      >
                        Pay AED {fee}
                      </button>
                    </div>
                  ) : (
                    <div className="mt-3" style={{ maxHeight: "150px" }}>
                      <Loading />
                    </div>
                  )}
                </form>
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      ) : (
        <PaymentCard
          setpaystatus={setClientSecret}
          Status={payStatus === "succeeded" ? true : false}
        />
      )}
    </>
  );
}
