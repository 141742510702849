import { useCallback } from "react";
import { useSelector } from "react-redux";
import { regionCurrencyMap } from "../utility/constants";

const usePriceBasedOnRegion = () => {
  const regionID = useSelector((state) => state.language.regionID);

  const getPriceBasedOnRegion = useCallback(
    (currencyPrices) => {
      const currencyCode = regionCurrencyMap[regionID];
      return [currencyCode, currencyPrices[currencyCode]];
    },
    [regionID]
  );

  return getPriceBasedOnRegion;
};

export default usePriceBasedOnRegion;
