import axios from "axios";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import { settings } from "../config";

const DoctorAppointmentCard = ({ name, imgSrc, rate, middle, bottom, meetDoctorPressed, activeButton, shownow, id }) => {
  const language = useSelector((state) => state.language);
  const [details, setDetails] = useState({});
  const getDetails = async () => {
    await axios.get(`${settings.apiBaseURL}/getPractitionerDetails?practitionerId=${id}`).then((response) => {
        setDetails(response.data.response[0]);
      });
  }
  useEffect(() =>{
      if(id){
          getDetails();
      }
  },[id])
  return (
    <div className="doctor-card mm-card d-flex align-items-center flex-wrap">
      {details?.profile_photo_url ? <img className="doctor-img mr-3" src={details?.profile_photo_url} alt={name} /> : <Avatar className="mr-2" name={name} size="50" round={true}/>}
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="doctor-name">{name}</h6>
          {rate && (
            <>
              {/* <i className="fas fa-star"></i> */}
              {/* <span className="rating">{rate}</span> */}
            </>
          )}
        </div>
        {middle && <p className="call-type">{middle}</p>}
        {bottom && <p className="appointment-time">{bottom}</p>}
      </div>
      <div className="d-flex justify-content-end align-items-center w-100">
      {shownow &&  <button
                onClick={meetDoctorPressed}
                className={`btn mt-4 mt-lg-0 ${
                  activeButton ? "btn-primary": "btn-secondary"
                } meet-doctor-btn`}
                type="button"
                disabled={!activeButton}
              >
                {language.lang["start-call"]}
        </button>}
        </div>
    </div>
  );
};

export default DoctorAppointmentCard;
