import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment";
import { IoIosCard, IoIosStar } from "react-icons/io";
import { FaCloudDownloadAlt, FaHeadphones } from "react-icons/fa";
import {
  currentAppointmentDetailsKey,
  paymentModuleTypeScheduledAppointment,
} from "../utility/constants";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  agoraRoute,
  dashboardPrescriptionRoute,
  stripePaymentCheckoutRoute,
} from "../routes";
import {
  getAppointmentDetails,
  isMobileOrTabletBrowser,
  showErrorNotification,
} from "../utility/Helpers";
import { updateConsultationStatusAction } from "../pages/DoctorJoinForConsultation/actions";
import { useEffect, useState } from "react";
import Loading from "./Loading";
import MobileAppPropmtModal from "./MobileAppPropmtModal";

const CustomerCalendarAppointmentCard = ({ selectedSlot }) => {
  // const { userData } = useSelector((state) => state.auth);
  const history = useHistory();
  const language = useSelector((state) => state.language);
  const [showMobilePromptModal, setShowMobilePromptModal] = useState(false);
  const [isJoinedDisabled, setIsJoinedDisabled] = useState(false);
  const [isJoinHidden, setIsJoinHidden] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // console.log("--- selectedSlot", selectedSlot);

  const getBtnIcon = (status) => {
    switch (status) {
      case "Completed Appointment":
        if (selectedSlot.prescription && selectedSlot.prescription.id) {
          return {
            icon: (
              <FaCloudDownloadAlt
                className="mr-1"
                style={{ width: "30px", height: "30px" }}
              />
            ),
            text: language.lang["download-prescription"],
          };
        } else {
          return {
            icon: null,
            text: language.lang.noPrescription,
            disabled: true,
          };
        }
      case "Failed Appointment":
        return null;
      case "Upcoming Appointment":
        return { icon: <FaHeadphones className="mr-1" />, text: "Join Call" };
      case "Ongoing Appointment":
        return { icon: <FaHeadphones className="mr-1" />, text: "Re-join" };
      case "Pending Appointment":
        if (selectedSlot.statusID === 23) {
          return { icon: <IoIosCard className="mr-1" />, text: "Pay Now" };
        } else {
          return {
            icon: <IoIosStar className="mr-1" />,
            text: "Verfication Pending",
          };
        }
      default:
        return null;
    }
  };

  const cardButton = getBtnIcon(selectedSlot.statusText);

  const payPending = () => {
    const paymentRedirectURL = `${window.location.protocol}//${window.location.host}?amount=${selectedSlot.amount_to_pay}&currency=${selectedSlot.amount_to_pay_currency_code}&startDateTime=${selectedSlot.start_date_time}&endDateTime=${selectedSlot.end_date_time}&buttonText=viewAppointment`;
    // const paymentRedirectURL = `${window.location.protocol}//${window.location.host}${dashboardLiveSessionRoute.path}?amount=${selectedSlot.amount_to_pay}&currency=${selectedSlot.amount_to_pay_currency_code}&startDateTime=${selectedSlot.start_date_time}&endDateTime=${selectedSlot.end_date_time}&buttonText=View Appointment`;
    history.push(stripePaymentCheckoutRoute.path, {
      moduleID: selectedSlot.appointmentId,
      currency: selectedSlot.amount_to_pay_currency_code,
      amount: selectedSlot.amount_to_pay,
      moduleType: paymentModuleTypeScheduledAppointment,
      redirectURL: paymentRedirectURL,
    });

    // let newdata = {
    //   amount: selectedSlot.amount_to_pay,
    //   moduleType: scheduledAppointment,
    //   moduleId: selectedSlot.id,
    //   userId: userData.id,
    // };

    // history.push(paymentInfoRoute.path, {
    //   paymentRegister: true,
    //   formData: {
    //     ...newdata,
    //     start_time: selectedSlot.start_date_time,
    //     end_time: selectedSlot.end_date_time,
    //     practitioner: {
    //       ...selectedSlot.practitionerInfo,
    //       id: selectedSlot.id,
    //     },
    //   },
    //   medicalDocFile: "",
    //   emiratesIDImage: "",
    //   recordFile: "",
    //   selectedSlot: "",
    //   practitioner: {
    //     ...selectedSlot.practitionerInfo,
    //     id: selectedSlot.practitioner_id,
    //   },
    //   emiratesIDBackImage: "",
    // });
  };

  const joinDoctorPressed = async (appointmentid) => {
    // setShowLoader(true);
    setIsLoading(true);
    setIsJoinHidden(true);
    let appointmentDetails = await getAppointmentDetails(appointmentid);
    localStorage.setItem(
      currentAppointmentDetailsKey,
      JSON.stringify(appointmentDetails)
    );
    let endedCall = appointmentDetails.practitioner_ended_call;
    // setstatus(!endedCall);
    let endTime = appointmentDetails.end_date_time;
    if (endedCall) {
      showErrorNotification("Doctor has ended the call");
      setIsLoading(false);
      setIsJoinHidden(false);

      return;
    } else if (moment().isAfter(endTime)) {
      setIsLoading(false);
      setIsJoinHidden(false);

      showErrorNotification(language.lang["appointment-time"] + " Ended");
    } else if (!moment().isAfter(endTime) && !endedCall) {
      const res = await updateConsultationStatusAction(
        appointmentid,
        "15",
        false
      );
      joinAppointmentCall();
      setIsLoading(false);
      setIsJoinHidden(false);
    }
  };

  const joinAppointmentCall = () => {
    if (isMobileOrTabletBrowser()) {
      setShowMobilePromptModal(true);
    } else {
      history.replace(agoraRoute.path);
    }
  };

  // console.log("--- isMobileOrTabletBrowser", isMobileOrTabletBrowser());

  const meetDoctorPressed = async (appointmentid) => {
    setIsLoading(true);
    setIsJoinHidden(true);
    let appointmentDetails = await getAppointmentDetails(appointmentid);
    localStorage.setItem(
      currentAppointmentDetailsKey,
      JSON.stringify(appointmentDetails)
    );
    let endedCall = appointmentDetails.practitioner_ended_call;
    // setstatus(!endedCall);
    let endTime = appointmentDetails.end_date_time;
    if (endedCall) {
      showErrorNotification("Doctor has ended the call");
      setIsLoading(false);
      setIsJoinHidden(false);
      return;
    } else if (moment().isAfter(endTime)) {
      setIsLoading(false);
      setIsJoinHidden(false);
      showErrorNotification(language.lang["appointment-time"] + " Ended");
    } else if (!moment().isAfter(endTime) && !endedCall) {
      joinAppointmentCall();
      setIsLoading(false);
      setIsJoinHidden(false);
    }
  };

  const downloadPrescription = (id) => {
    setIsJoinHidden(true);
    setIsLoading(true);
    history.push(dashboardPrescriptionRoute.path, {
      presId: id,
    });
  };

  const handleEventChange = (e) => {
    console.log(selectedSlot);
    switch (selectedSlot.statusText) {
      case "Pending Appointment":
        if (selectedSlot.statusID === 23) payPending();
        break;
      case "Upcoming Appointment":
        joinDoctorPressed(selectedSlot.appointmentId);
        break;
      case "Ongoing Appointment":
        meetDoctorPressed(selectedSlot.appointmentId);
        break;
      case "Completed Appointment":
        downloadPrescription(selectedSlot.prescription.id);
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (
      selectedSlot.statusText === "Upcoming Appointment" &&
      selectedSlot.practitioner_started_call === false
    ) {
      setIsJoinedDisabled(true);
    } else {
      setIsJoinedDisabled(false);
    }
  }, [selectedSlot.practitioner_started_call, selectedSlot.statusText]);

  const onMobileModalPromptClose = () => {
    setShowMobilePromptModal(false);
    history.replace(agoraRoute.path);
  };

  return (
    <div className="d-flex w-100 flex-column">
      <MobileAppPropmtModal
        show={showMobilePromptModal}
        onCloseClicked={onMobileModalPromptClose}
      />
      <div className="d-flex w-100 mt-1">
        <div className="col-2 p-0 d-flex flex-column text-end">
          <span className="start-time">
            {moment(selectedSlot.start).format("hh:mm")}
          </span>
          <span className="end-time">
            {moment(selectedSlot.end).format("hh:mm")}
          </span>
        </div>
        <div className=" col-9 p-0 mx-2">
          <div className="d-flex justify-content-center align-items-center w-100">
            <div className="side-show mr-2"></div>
            <div
              className="selected-appoint-card w-100"
              style={{ backgroundColor: selectedSlot.color }}
            >
              <div className="d-flex justify-content-between w-100 align-items-center">
                <span>{selectedSlot.statusText}</span>
                <span>
                  <BsThreeDotsVertical color={"white"} />
                </span>
              </div>
              <div className="mt-1">{selectedSlot.PractitionerName}</div>

              <div className="d-flex justify-content-end w-100 align-items-center mt-auto">
                {isLoading && <Loading />}
              </div>
              {cardButton && (
                <div className="d-flex justify-content-end w-100 align-items-center mt-auto">
                  <div hidden={isJoinHidden}>
                    <button
                      className="pay-btn w-100 d-flex justify-content-center align-items-center"
                      disabled={isJoinedDisabled || cardButton.disabled}
                      onClick={handleEventChange}
                    >
                      {cardButton.icon} {cardButton.text}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {selectedSlot.statusID === 18 && !selectedSlot.amountToPay && (
        <div
          className="mt-2"
          style={{ fontSize: "14px", display: "flex", padding: "2rem" }}
        >
          <span className="required-field-star"> *</span> Please wait for the
          verification to be get completed. It wont take much time to get verify
          your details. If the appointment is created after 6 PM then the
          verification will occurs by next working day 9.am to 6.pm
        </div>
      )}
      {isJoinedDisabled && (
        <div
          className="mt-2"
          style={{ fontSize: "14px", display: "flex", padding: "2rem" }}
        >
          <span className="required-field-star"> *</span> Join button will be
          enabled once the practitioner starts the call
        </div>
      )}
    </div>
  );
};

export default CustomerCalendarAppointmentCard;
