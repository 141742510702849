import React, { useEffect, useState } from "react";
import ProfileCircleButton from "../components/ProfileCircleButton";
import NotificationsButton from "../components/NotificationsButton";
import BackButton from "../components/BackButton";
import sign from "../assets/images/sign.png";
import { Row, Container, Col } from "react-bootstrap";
import PrescriptionSection1 from "./PrescriptionSection1";
import PrescriptionSection2 from "./PrescriptionSection2";
import { useHistory } from "react-router-dom";
import axios from "../services/Axios";
import { settings } from "../config";
import Loading from "./Loading";
import { useSelector } from "react-redux";
import moment from "moment";
import TopMenu from "./CommonControls/TopMenu";

const DashboardPrescription = () => {
  const history = useHistory();
  const reduxStore = useSelector((state) => state);
  const [loadingState, setLoadingState] = useState(false);
  const [medicineList, setMedicineList] = useState([]);
  const language = useSelector((state) => state.language);
  const [patientData, setPatientData] = useState({
    customerInfo: {
      name: "",
      age: "",
    },
    practitionerInfo: {
      name: "",
    },
  });

  const fetchDetails = async () => {
    const response = await axios.get(
      `${settings.apiBaseURL}/readPrescription?prescriptionId=${history.location.state.presId}`
    );
    setPatientData(response.data.response[0]);
    let arr = response.data.response[0].medication.map((el) => {
      return el;
    });

    setMedicineList(arr);
    setLoadingState(false);
  };

  useEffect(() => {
    if (history.location.state && "presId" in history.location.state) {
      setLoadingState(true);
      fetchDetails();
    }
  }, []);

  return (
    <div className="consultation mb-4">
      <div className="dashboard-book-consultation">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={true}
        pageTitle={language.lang["download-prescription"]}
      />
      </div>
      <Container fluid className="mt-3">
        <Row className="mx-0">
          {loadingState && (
            <Col
              xs={12}
              className="consultation-section-1"
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Loading className="m-3" />
            </Col>
          )}

          {!loadingState && (
            <PrescriptionSection1
              centerName="Medical Prescription by Klippa Medical Center"
              centerAddress="Lubeckweg 2, 9723HE Groningen, The Netherlands"
              patientName={patientData.customerInfo.name}
              age={moment().diff(
                moment(patientData.customerInfo.birth_date),
                "years"
              )}
              date={moment(patientData.created_date).format("DD-MM-YYYY")}
              medicineList={medicineList}
              doctorName={patientData.practitionerInfo.name}
              doctorSignature={sign}
              email={reduxStore.auth.userData.email}
              headingDoc={language.lang["download-prescription"]}
              width={6}
              prescriptionUrl={patientData.prescription_url}
            />
          )}
          {!loadingState && (
            <PrescriptionSection2 prescriptionInfo={patientData} />
          )}
        </Row>
      </Container>
    </div>
  );
};

export default DashboardPrescription;
