const actions = {
  SET_LOADING: "SET_LOADING",
  SET_RTM_CLIENT: "SET_RTM_CLIENT",
  SET_RTM_CHANNEL: "SET_RTM_CHANNEL",
};

export const setRTMChannelAction = ({ channel }) => {
  return {
    type: actions.SET_RTM_CHANNEL,
    payload: channel,
  };
};

export const setRTMClientAction = ({ client }) => {
  return {
    type: actions.SET_RTM_CLIENT,
    payload: client,
  };
};

export const setLoadingAction = ({ loading }) => {
  return {
    type: actions.SET_LOADING,
    payload: loading,
  };
};

export default actions;
