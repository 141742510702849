
const CirclesBackground = ({ children }) => {
  return (
    <div className="auth-base-layout">
      <div className="top-right-circle" />
      <div className="bottom-left-circle" />
      {children}
    </div>
  );
};

export default CirclesBackground;
