import videoImg from "../assets/images/MealPlan2.png";
const MostPopularAppointment = () => {
  return (
    <div>
      <div
        className="card flex-row p-2"
        style={{ gap: "10px", borderRadius: "15px" }}
      >
        <div className="most-upcoming-img">
          <img src={videoImg} alt="img-thumb" />
        </div>
        <div>
          <div style={{ fontSize: "22px", fontWeight: "600" }}>
            Doctor Appointment
          </div>
          <div
            className="d-flex"
            style={{ fontSize: "14px", gap: "20px", color: "rgb(102 102 102)" }}
          >
            <div>Aug 03,2021</div>
            <div>5pm-6pm</div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center ml-2"
          style={{
            backgroundColor: "#03ad53",
            width: "60px",
            height: "60px",
            borderRadius: "10px",
            color: "#fff",
            fontSize: "20px",
          }}
        >
          <i className="fas fa-chevron-right"></i>
        </div>
      </div>
      <div
        className="text-center pt-1"
        style={{ color: "#f6bd27", fontSize: "14px", fontWeight: "500" }}
      >
        Less than an hour left
      </div>
    </div>
  );
};
export default MostPopularAppointment;
