import { useState, useEffect } from "react";
import { Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import axios from "../services/Axios";
import SideMenuField from "./SideMenuField";
import {
  setPreferredLanguageAction,
  UpdateGeneralSettings,
} from "../redux/language/actionCreators";
import { updateUserLanguageAction } from "../redux/authenitcation/actionCreator";

const selectedLanguageStorageKey = "selectedLanguage";

const LanguagesDropdown = ({ isExpanded, isMobile,showLabel }) => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const currentUser = useSelector((state) => state.auth.userData);
  const [languages, setLangauges] = useState([]);
  const isAuth = currentUser && Object.values(currentUser).length > 0;
  const languageFromLocalStorage = localStorage.getItem(
    selectedLanguageStorageKey
  );

  useEffect(() => {
    getLanguage();
  }, []);

  useEffect(() => {
    let currentSelectedLang;
    if (currentUser && currentUser.preferred_language) {
      currentSelectedLang = currentUser.preferred_language;
    } else if (languageFromLocalStorage) {
      currentSelectedLang = languageFromLocalStorage;
    } else if (languages.length > 0) {
      currentSelectedLang = languagesMap.get(languages[1].id);
    }
    dispatch(setPreferredLanguageAction({ lang: currentSelectedLang }));
  }, [currentUser, languageFromLocalStorage, languages]);

  useEffect(() => {
    const languageFromLocalStorage = localStorage.getItem(
      selectedLanguageStorageKey
    );
    let currentLang;
    if (isAuth) {
      currentLang = currentUser.preferred_language;
    } else if (languageFromLocalStorage) {
      currentLang = languageFromLocalStorage;
    } else {
      currentLang = "en";
    }
    const userPrefs = {
      preferredlanguage: currentLang,
      regionId: language.regionID,
      theme: language.general.theme,
    };
    dispatch(UpdateGeneralSettings(userPrefs, false));
  }, [currentUser, language.lang]);

  const getLanguage = async () => {
    const res = await axios.get("/getProfileLanguages");
    setLangauges(res.data.response || []);
  };

  const handleLanguageChange = (value) => {
    if (isAuth) {
      handleAuthLanguageChange(value);
    } else {
      handleUnauthLanguageChange(value);
    }
    dispatch(
      UpdateGeneralSettings({
        preferredlanguage: value,
        regionId: language.regionID,
        theme: language.general.theme,
      })
    );
  };

  const handleUnauthLanguageChange = (value) => {
    localStorage.setItem("selectedLanguage", value);
  };

  const handleAuthLanguageChange = (value) => {
    // console.log("--- lang change", value, userProfilePreferences);
    // updateUserSettings({
    //   regionID: language.general.regionId,
    //   language: value,
    //   theme: language.general.theme,
    // });
    dispatch(updateUserLanguageAction({ language: value, currentUser }));
  };

  let currentSelectedLang;
  if (currentUser && currentUser.preferred_language) {
    currentSelectedLang = currentUser.preferred_language;
  } else if (languageFromLocalStorage) {
    currentSelectedLang = languageFromLocalStorage;
  } else if (languages.length > 0) {
    currentSelectedLang = languagesMap.get(languages[1].id);
  }

  // console.log("---- profile", language.general.preferredlanguage);

  if (languages.length === 0) return <span></span>;

  return (
    <SideMenuField
      name={showLabel ? language.lang.language : ""}
      field={
        <Select
          onChange={handleLanguageChange}
          value={currentSelectedLang}
          name="preferredlanguage"
          className="language-dropdown"
          style={{
            maxWidth: isExpanded || isMobile ? "100px" : "30px",
            minWidth: isExpanded || isMobile ? "80px" : "50px",
          }}
          showArrow={isExpanded || isMobile}
        >
          {languages.map((lang) => {
            return (
              <Select.Option key={lang.id} value={languagesMap.get(lang.id)}>
                {isExpanded || isMobile
                  ? lang.name
                  : lang.name.substring(0, 2).toUpperCase()}
              </Select.Option>
            );
          })}
        </Select>
      }
      isExpanded={isExpanded || isMobile}
    />
  );
};

const languagesMap = new Map();
languagesMap.set(93, "ku");
languagesMap.set(43, "en");
languagesMap.set(192, "ar");

export default LanguagesDropdown;
