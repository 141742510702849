import actions from "./actions";

export const initState = {
  loading: true,
  client: {},
  channel: {},
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.SET_RTM_CHANNEL:
      return Object.assign({}, state, {
        channel: payload,
      });
    case actions.SET_RTM_CLIENT:
      return Object.assign({}, state, {
        client: payload,
      });
    case actions.SET_LOADING:
      return Object.assign({}, state, {
        loading: payload,
      });
    default:
      return state;
  }
};

export default reducer;
