import { useEffect, Suspense, lazy } from "react";
import CirclesBackground from "../components/CirclesBackground";
import { Switch, Route } from "react-router-dom";
import {
  dashboardFitnessRoute,
  dashboardRoute,
  dashboardBookConsultationRoute,
  customerConfirmConsultationRoute,
  dashboardConsultationRoute,
  walkinConfirmationRoute,
  dashboardPrescriptionRoute,
  dashboardCreatePrescriptionRoute,
  agoraRoute,
  joinForConsultaitonRoute,
  doctorJoinConsultationRoute,
  dashboardSlotManagementRoute,
  dashboardContentManagerRoute,
  dashboardVoucherRoute,
  dashboardPractitionerRoute,
  dashboardCorporatesRoute,
  dashboardPermissionsRoute,
  dashboardDisputeCenterRoute,
  dashboardMostPopularRoute,
  dashboardMealPlanRoute,
  dashboardMeditationYogaRoute,
  dashboardAppointmentsRoute,
  dashboardMostPopularNewRoute,
  MostPopularCardDashRoute,
  paymentInfoRoute,
  dashboardCardRecipeRoute,
  settingsRoute,
  dashboardPrivacyPolicy,
  dashboardTermsAndConditions,
  dashboardContactUs,
  dashboardAboutUs,
  dashboardFeedback,
  dashboardSupport,
  dashboardJoinUs,
  cliniccalendarRoute,
  clinicVerifyPatientDetailsRoute,
  VerifyPatientDetailsRoute,
  ViewScheduledPatientRoute,
  practiitionercalendarRoute,
  customerCalendarRoute,
  practitionerLiveSessionTabRoute,
  practitionerLiveSessionRoute,
  dashboardLiveSessionRoute,
  dashboardSessionDetailRoute,
  fitnessBuddyRoute,
  viewMatchesRoute,
  matchedProfileRoute,
  buddysLiveChatRoute,
  stripePaymentCheckoutRoute,
  VerifyPatientDetailsBasedOnRegionRoute,
  ViewScheduledPatientBasedOnRegionRoute,
  createRecipeRoute,
  viewRecipeDetailsRoute,
  editRecipeRoute,
  mealPlanOnboardingRoute,
  subscriptionPlanDetailsRoute,
  selectSubscriptionPlanRoute,
  bookDieticianAppointmentRoute,
} from "../routes";
import ProtectedRoute from "../utility/ProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
import OSignal from "../components/OSignal";
import SideBar from "./Sidebar";
import Footer from "./Footer";
import { setpreferredLanguage } from "../redux/language/actionCreators";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import UserActions from "../redux/user/actions";
import { fetchUserNotificationsAction } from "../redux/user/actionsCreators";
import HasRoleOrUnauthRoute from "../utility/HasRoleOrUnauthRoute";
import { userRoleClient } from "../utility/constants";
// import Tawk from "../components/Tawk";
import Whatsapp from "../components/Whatsapp";
import ViewScheduledPatientBasedOnRegion from "../components/ViewScheduledPatientBasedOnRegion";
import MustHaveRoleRoute from "../utility/MustHaveRoleRoute";
import RouteNotFound from "../components/RouteNotFound";
// import Loading from "../components/Loading";
import { Instagram } from "react-content-loader";
import Loading from "../components/Loading";
import useCompleteEmailLinkSignin from "../customHooks/useCompleteEmailLinkSignin";
const CustomerCalendar = lazy(() => import("../components/CustomerCalendar"));
const StripePaymentCheckout = lazy(() => import("./StripePaymentCheckout"));
const PaymentInfo = lazy(() => import("./PaymentInfo"));
const DoctorJoinForConsultation = lazy(() => import("./DoctorJoinForConsultation"));
const ClientJoinForConsultation = lazy(() => import("./ClientJoinForConsultation"));
const PractitionerLiveSessionList = lazy(() => import("../components/PractitionerLiveSessionList"));
const WalkinConfirmation = lazy(() => import("./WalkinConfirmation"));
const DashboardConsultation = lazy(() => import("../components/DashboardConsultation"));
const DashboardPrescription = lazy(() => import("../components/DashboardPrescription"));
const CreatePrescription = lazy(() => import("../components/CreatePrescription"));
const SlotManagement = lazy(() => import("../components/SlotManagement"));
const PractitionerLiveSessionTab = lazy(() => import("../components/PractitionerLiveSessionTab"));
const VideoCallScreen = lazy(() => import("./VideoCallScreen"));
const DashboardMostPopular = lazy(() => import("../components/DashboardMostPopular"));
const MostPopularNew = lazy(() => import("../components/MostPopularNew"));
const BookConsultation = lazy(() => import("./BookConsultation"));
const FitnessBuddy = lazy(() => import("./FitnessBuddy/FitnessBuddy"));
const ViewMatches = lazy(() => import("./FitnessBuddy/ViewMatches/ViewMatches"));
const MatchedProfile = lazy(() => import("./FitnessBuddy/ViewMatches/MatchedProfile"));
const BuddysLiveChat = lazy(() => import("./FitnessBuddy/BuddysLiveChat/BuddyLiveChat"));
const MostPopularCardDash = lazy(() => import("../components/MostPopularCardDash"));
// const DashboardMealPlan = lazy(() =>
//   import("../components/MealPlan/MealPlanDashboard/MealPlanDashboard")
// );
const CreateRecipe = lazy(() => import("../components/MealPlan/Recipe/CreateRecipe"));
const DashboardLiveSession = lazy(() => import("../components/DashboardLiveSession"));
const CardRecipe = lazy(() => import("../components/CardRecipe"));
const LiveSessionDetailsCard = lazy(() => import("../components/LiveSessions/LiveSessionDetailsCard"));
const DashboardMeditationYoga = lazy(() => import("../components/DashboardMeditationYoga"));
const DashboardFitness = lazy(() => import("../components/DashboardFitness"));
const DashboardAppointments = lazy(() => import("../components/DashboardAppointments"));
const VerifyPatientDetails = lazy(() => import("../components/VerifyPatientDetails"));
const VerifyPatientDetailsBasedOnRegion = lazy(() => import("../components/VerifyPatientDetailsBasedOnRegion"));
const ViewScheduledPatient = lazy(() => import("../components/ViewScheduledPatient"));
const ClinicCalendar = lazy(() => import("../components/ClinicCalendar"));
const PractitionerCalendar = lazy(() => import("../components/PractitionerCalendar"));
const Settings = lazy(() => import("../components/Settings"));
const PrivacyPolicy = lazy(() => import("../components/PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("../components/TermsAndConditions"));
const ContactUs = lazy(() => import("../components/ContactUs"));
const AboutUs = lazy(() => import("../components/AboutUs"));
const JoinUs = lazy(() => import("../components/JoinUs"));
const Feedback = lazy(() => import("../components/Feedback"));
const Support = lazy(() => import("../components/Support"));
const DashboardHome = lazy(() => import("./DashboardHome"));
const ContentManager = lazy(() => import("../components/ContentManager"));
const Voucher = lazy(() => import("../components/Voucher"));
const Corporates = lazy(() => import("../components/Corporates"));
const Permissions = lazy(() => import("../components/Permissions"));
const DisputeCenter = lazy(() => import("../components/DisputeCenter"));
const Practitioner = lazy(() => import("../components/Practioner"));
const PractitionerBookConsultation = lazy(() => import("./PractitionerBookConsultation"));

const Dashboard = () => {
  const dispatch = useDispatch();
  const [loadingCompleteEmailLinkSignin, _] = useCompleteEmailLinkSignin();
  const currentUser = useSelector((state) => state.auth.userData) || {};
  const userID = currentUser.id;
  const preferred_language = currentUser.preferred_language;

  useEffect(() => {
    dispatch(setpreferredLanguage(preferred_language));
    dispatch(fetchUserNotificationsAction());
  }, [dispatch, preferred_language]);

  // useEffect(() => {
  //   const events = [
  //     {
  //       listener: "once",
  //       event: "subscriptionChange",
  //       callback: (isSubscribed) => {
  //         console.log("--- The user subscription state is now:", isSubscribed);
  //       },
  //     },
  //     {
  //       event: "notificationDisplay",
  //       callback: (event) => {
  //         console.log("--- OneSignal notification displayed:", event);
  //       },
  //     },
  //     {
  //       event: "notificationDismiss",
  //       callback: (event) => {
  //         console.log("--- OneSignal notification dismissed:", event);
  //       },
  //     },
  //   ];
  //   const options = {
  //     allowLocalhostAsSecureOrigin: true,
  //     autoResubscribe: true,
  //     autoRegister: true,
  //   };
  //   OneSignal.initialize(onesignalAppID, options, events);
  // }, []);

  // useOneSignalSetup(() => {
  //   console.log("---- registerForPushNotifications");
  //   OneSignal.registerForPushNotifications();
  //   if (userID) OneSignal.setExternalUserId(userID);
  // });
  const Scrollref = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!Scrollref.current) return;
    Scrollref.current.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch({
      type: UserActions.SET_PREVIOUS_PAGE_PATH,
      payload: pathname,
    });
  }, [pathname, dispatch]);

  let content;
  if (loadingCompleteEmailLinkSignin) {
    content = (
      <div className="d-flex justify-content-center mt-5 w-100">
        <Loading />
      </div>
    );
  } else {
    content = (
      <main ref={Scrollref} className="main container-fluid">
        <Suspense fallback={<Instagram className="mt-5" />}>
          <Switch>
            <ProtectedRoute
              component={bookDieticianAppointmentRoute.component}
              path={bookDieticianAppointmentRoute.path}
              exact={bookDieticianAppointmentRoute.exact}
            />
            <ProtectedRoute
              component={selectSubscriptionPlanRoute.component}
              path={selectSubscriptionPlanRoute.path}
              exact={selectSubscriptionPlanRoute.exact}
            />
            <ProtectedRoute
              component={subscriptionPlanDetailsRoute.component}
              path={subscriptionPlanDetailsRoute.path}
              exact={subscriptionPlanDetailsRoute.exact}
            />
            <ProtectedRoute
              component={mealPlanOnboardingRoute.component}
              path={mealPlanOnboardingRoute.path}
              exact={mealPlanOnboardingRoute.exact}
            />
            <ProtectedRoute
              // component={stripePaymentCheckoutRoute.component}
              component={StripePaymentCheckout}
              path={stripePaymentCheckoutRoute.path}
            />
            <ProtectedRoute
              // component={paymentInfoRoute.component}
              component={PaymentInfo}
              path={paymentInfoRoute.path}
            />
            <ProtectedRoute
              // component={doctorJoinConsultationRoute.component}
              component={DoctorJoinForConsultation}
              path={doctorJoinConsultationRoute.path}
            />
            <ProtectedRoute
              // component={joinForConsultaitonRoute.component}
              component={ClientJoinForConsultation}
              path={joinForConsultaitonRoute.path}
            />
            <ProtectedRoute
              // component={practitionerLiveSessionRoute.component}
              component={PractitionerLiveSessionList}
              path={practitionerLiveSessionRoute.path}
            />
            <ProtectedRoute
              // component={walkinConfirmationRoute.component}
              component={WalkinConfirmation}
              path={walkinConfirmationRoute.path}
            />
            {/*<ProtectedRoute component={CustomerConfirmConsultation} path={customerConfirmConsultationRoute.path} />*/}
            {/*<ProtectedRoute component={BookConsultation} path={dashboardBookConsultationRoute.path} />*/}
            <Route component={DashboardConsultation} path={dashboardConsultationRoute.path} />
            <ProtectedRoute path={dashboardPrescriptionRoute.path} component={DashboardPrescription} />
            <ProtectedRoute
              // component={dashboardCreatePrescriptionRoute.component}
              component={CreatePrescription}
              path={dashboardCreatePrescriptionRoute.path}
            />
            <ProtectedRoute
              // component={customerCalendarRoute.component}
              component={CustomerCalendar}
              path={customerCalendarRoute.path}
            />
            <ProtectedRoute component={SlotManagement} path={dashboardSlotManagementRoute.path} />
            <ProtectedRoute
              // component={practitionerLiveSessionTabRoute.component}
              component={PractitionerLiveSessionTab}
              path={practitionerLiveSessionTabRoute.path}
            />
            <ProtectedRoute
              // component={agoraRoute.component}
              component={VideoCallScreen}
              path={agoraRoute.path}
            />
            <Route path={dashboardMostPopularRoute.path} component={DashboardMostPopular} />
            <Route path={dashboardMostPopularNewRoute.path} component={MostPopularNew} />
            <HasRoleOrUnauthRoute
              path={customerConfirmConsultationRoute.path}
              // component={CustomerConfirmConsultation}
              component={PractitionerBookConsultation}
              exact={customerConfirmConsultationRoute.exact}
              roles={[userRoleClient]}
            />
            <HasRoleOrUnauthRoute
              path={dashboardBookConsultationRoute.path}
              component={BookConsultation}
              exact={dashboardBookConsultationRoute.exact}
              roles={[userRoleClient]}
            />
            <MustHaveRoleRoute
              path={fitnessBuddyRoute.path}
              component={FitnessBuddy}
              exact={fitnessBuddyRoute.exact}
              roles={[userRoleClient]}
            />
            <MustHaveRoleRoute
              path={viewMatchesRoute.path}
              // component={viewMatchesRoute.component}
              component={ViewMatches}
              exact={viewMatchesRoute.exact}
              roles={[userRoleClient]}
            />
            <MustHaveRoleRoute
              path={matchedProfileRoute.path}
              // component={matchedProfileRoute.component}
              component={MatchedProfile}
              exact={matchedProfileRoute.exact}
              roles={[userRoleClient]}
            />
            <MustHaveRoleRoute
              path={buddysLiveChatRoute.path}
              // component={buddysLiveChatRoute.component}
              component={BuddysLiveChat}
              exact={buddysLiveChatRoute.exact}
              roles={[userRoleClient]}
            />
            <Route path={MostPopularCardDashRoute.path} component={MostPopularCardDash} />
            <Route
              path={dashboardMealPlanRoute.path}
              component={dashboardMealPlanRoute.component}
              exact={dashboardMealPlanRoute.exact}
            />
            <Route path={editRecipeRoute.path} component={editRecipeRoute.component} exact={editRecipeRoute.exact} />
            <Route
              path={viewRecipeDetailsRoute.path}
              component={viewRecipeDetailsRoute.component}
              exact={viewRecipeDetailsRoute.exact}
            />
            <ProtectedRoute path={createRecipeRoute.path} component={createRecipeRoute.component} />
            <Route path={dashboardLiveSessionRoute.path} component={DashboardLiveSession} />
            <Route path={dashboardCardRecipeRoute.path} component={CardRecipe} />
            <HasRoleOrUnauthRoute
              path={dashboardSessionDetailRoute.path}
              component={LiveSessionDetailsCard}
              roles={[userRoleClient]}
            />
            <Route path={dashboardMeditationYogaRoute.path} component={DashboardMeditationYoga} />
            <Route path={dashboardFitnessRoute.path} component={DashboardFitness} />
            <ProtectedRoute
              path={dashboardAppointmentsRoute.path}
              // component={dashboardAppointmentsRoute.component}
              component={DashboardAppointments}
            />
            <Route path={VerifyPatientDetailsRoute.path} component={VerifyPatientDetails} />
            <Route path={VerifyPatientDetailsBasedOnRegionRoute.path} component={VerifyPatientDetailsBasedOnRegion} />
            <Route path={ViewScheduledPatientRoute.path} component={ViewScheduledPatient} />
            <Route path={ViewScheduledPatientBasedOnRegionRoute.path} component={ViewScheduledPatientBasedOnRegion} />
            <ProtectedRoute
              path={cliniccalendarRoute.path}
              // component={cliniccalendarRoute.component}
              component={ClinicCalendar}
            />
            <ProtectedRoute
              // component={practiitionercalendarRoute.component}
              component={PractitionerCalendar}
              path={practiitionercalendarRoute.path}
            />
            <Route path={settingsRoute.path} component={Settings} />
            <Route path={dashboardPrivacyPolicy.path} component={PrivacyPolicy} />
            <Route path={dashboardTermsAndConditions.path} component={TermsAndConditions} />
            <Route path={dashboardContactUs.path} component={ContactUs} />
            <Route path={dashboardAboutUs.path} component={AboutUs} />
            <Route path={dashboardJoinUs.path} component={JoinUs} />
            <Route path={dashboardFeedback.path} component={Feedback} />
            <Route path={dashboardSupport.path} component={Support} />
            <Route render={(props) => <DashboardHome {...props} />} exact path={dashboardRoute.path} />
            <ProtectedRoute
              // component={dashboardContentManagerRoute.component}
              component={ContentManager}
              path={dashboardContentManagerRoute.path}
            />
            <ProtectedRoute component={VerifyPatientDetails} path={clinicVerifyPatientDetailsRoute.path} />
            <ProtectedRoute component={Voucher} path={dashboardVoucherRoute.path} />
            <ProtectedRoute component={Corporates} path={dashboardCorporatesRoute.path} />
            <ProtectedRoute component={Permissions} path={dashboardPermissionsRoute.path} />
            <ProtectedRoute component={DisputeCenter} path={dashboardDisputeCenterRoute.path} />
            <ProtectedRoute component={Practitioner} path={dashboardPractitionerRoute.path} />
            <Route path="*" component={RouteNotFound} />
          </Switch>
        </Suspense>
        <span
          style={{
            zIndex: 10001,
            bottom: 0,
            padding: "1rem 2rem",
            right: "4rem",
            position: "absolute",
          }}
        >
          <Whatsapp />
        </span>
        <Footer></Footer>
      </main>
    );
  }

  return (
    <CirclesBackground>
      {userID && <OSignal />}
      <div className="page-container d-flex">
        {/* <Tawk /> */}
        <SideBar />
        {content}
      </div>
    </CirclesBackground>
  );
};

export default Dashboard;

// Most Popular
// Top Doctors
// Fitness
// Meal Plans
// Sleep
// Mediation
// Mental Health
// Travel
