import { Button, Input } from "antd";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { showErrorNotification } from "../../../../utility/Helpers";

const EditCookingInstructionModal = ({
  show,
  onClose,
  onLinkEdited,
  onInstructionEdited,
  id,
  cookingInstructions,
  onRemoveClicked,
}) => {
  const language = useSelector((state) => state.language.lang);
  const [text, setText] = useState("");
  const [link, setLink] = useState("");
  const cookingInstruction = cookingInstructions.find((item) => item.id === id);

  useEffect(() => {
    if (cookingInstruction?.isLink) {
      setLink(cookingInstruction?.value ?? "");
      setText("");
    } else {
      setText(cookingInstruction?.value ?? "");
      setLink("");
    }
  }, [show, cookingInstruction]);

  const addInstructionClicked = () => {
    if (!text.trim()) {
      return showErrorNotification(language["please-enter-instruction-text"] ?? "please-enter-instruction-text");
    }
    if (onInstructionEdited) {
      onInstructionEdited(id, text);
    }
  };

  const addLinkClicked = () => {
    if (!link.trim()) {
      return showErrorNotification(language["please-enter-link"] ?? "please-enter-link");
    }
    if (onLinkEdited) {
      onLinkEdited(id, link);
    }
  };

  return (
    <Modal
      backdrop="static"
      className="add-cooking-instruction-modal"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center">
          {language["add-step-by-step-cooking-instructions"] ?? "add-step-by-step-cooking-instructions"}
          <Button type="text" onClick={onClose}>
            <AiOutlineClose />
          </Button>
        </div>
        <Input.TextArea value={text} onChange={(e) => setText(e.target.value)} rows={6} />
        <div className="d-flex justify-content-end">
          <Button onClick={addInstructionClicked} type="primary">
            {language["add"] ?? "add"}
          </Button>
        </div>
        <p className="text-center">{language["or"] ?? "or"}</p>
        <div className="d-flex justify-content-between align-items-center">{language["add-video-link"] ?? "add-video-link"}</div>
        <Input.TextArea value={link} onChange={(e) => setLink(e.target.value)} rows={6} />
        <div className="d-flex justify-content-end">
          <Button onClick={addLinkClicked} type="primary">
            {language["add"] ?? "add"}
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <Button onClick={() => onRemoveClicked(id)} type="link">
            {language["remove"] ?? "remove"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditCookingInstructionModal;
