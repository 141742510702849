import BackButton from "../components/BackButton";
import { Tabs, Table } from "antd";

const { TabPane } = Tabs;

const Permissions = () => {
  const columns = [
    {
      title: "Roles",
      dataIndex: "Roles",
      key: "Roles",
      // width: 90,
    },
    {
      title: "LIVE SUPPORT",
      dataIndex: "LIVESUPPORT",
      key: "LIVESUPPORT",
    },
    {
      title: "CUSTOMERS",
      dataIndex: "CUSTOMERS",
      key: "CUSTOMERS",
    },
    {
      title: "PRACTITIONERS",
      dataIndex: "PRACTITIONERS",
      key: "PRACTITIONERS",
    },
    {
      title: "ADMIN",
      dataIndex: "ADMIN",
      key: "ADMIN",
    },
  ];

  const data = [];
  for (let i = 0; i < 2; i++) {
    data.push({
      key: i,
      Roles: <div>Approve/Deny/Reject</div>,
      LIVESUPPORT: (
        <div>
          <input type="checkbox" id="" name="" value="" />
        </div>
      ),
      CUSTOMERS: (
        <div>
          <input type="checkbox" id="" name="" value="" />
        </div>
      ),
      PRACTITIONERS: (
        <div>
          <input type="checkbox" id="" name="" value="" />
        </div>
      ),
      ADMIN: (
        <div>
          <input type="checkbox" id="" name="" value="" />
        </div>
      ),
    });
  }

  const columns1 = [
    {
      title: "Features",
      dataIndex: "Features",
      key: "Features",
      // width: 90,
    },
    {
      title: "MASDAR",
      dataIndex: "MASDAR",
      key: "MASDAR",
    },
    {
      title: "ADNOC",
      dataIndex: "ADNOC",
      key: "ADNOC",
    },
    {
      title: "MICROSOFT",
      dataIndex: "MICROSOFT",
      key: "MICROSOFT",
    },
    {
      title: "ADNIC",
      dataIndex: "ADNIC",
      key: "ADNIC",
    },
  ];

  const arr = [
    "Own Calendar",
    "Calendars",
    "Can Book Appointments",
    "Home",
    "Mental Health",
    "Fitness",
    "Can download Reports",
    "Messages",
    "Services",
  ];

  const data1 = [];
  for (let i = 0; i < 9; i++) {
    data1.push({
      key: i,
      Features: <div>{arr[i]}</div>,
      MASDAR: (
        <div>
          <input type="checkbox" id="" name="" value="" />
        </div>
      ),
      ADNOC: (
        <div>
          <input type="checkbox" id="" name="" value="" />
        </div>
      ),
      MICROSOFT: (
        <div>
          <input type="checkbox" id="" name="" value="" />
        </div>
      ),
      ADNIC: (
        <div>
          <input type="checkbox" id="" name="" value="" />
        </div>
      ),
    });
  }

  return (
    <div className="permissions">
      <div className="dashboard-book-consultation">
        <div className="col-12 notifications-and-profile mt-3 mt-md-0 justify-content-between mx-2">
          <div>
            <BackButton />
            <span className="consultation-heading">Settings</span>
          </div>
        </div>
      </div>

      <h5 className="permissions-heading">Permissions</h5>

      <Tabs defaultActiveKey="1">
        <TabPane tab="Feature Permissions" key="1">
          <Table
            columns={columns1}
            dataSource={data1}
            scroll={{ x: 900, y: 530 }}
            pagination={false}
          />

          <div className="text-right mt-3 mr-2">
            <button className="submit-corporate-btn" style={{ width: "200px" }}>
              Submit
            </button>
          </div>
        </TabPane>

        <TabPane tab="User Permissions" key="2">
          <Table
            columns={columns}
            dataSource={data}
            scroll={{ x: 900, y: 530 }}
            pagination={false}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Permissions;
