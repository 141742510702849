import actions from "./actions";

const initState = {
  app: null,
  phoneSigninConfirmationResult: null,
  phoneNumber: "",
  phoneCountryCode: "",
  corporateID: "",
};

const reducer = (state = initState, { type, payload }) => {
  if (type === actions.SET_APP) {
    return Object.assign({}, state, {
      app: payload,
    });
  } else if (type === actions.SET_PHONE_AUTH_CONFIRMATION_RESULT) {
    return Object.assign({}, state, {
      phoneSigninConfirmationResult: payload,
    });
  } else if (type === actions.SET_CORPORATE_ID) {
    return Object.assign({}, state, {
      corporateID: payload,
    });
  } else if (type === actions.SET_PHONE_NUMBER) {
    return Object.assign({}, state, {
      phoneCountryCode: payload.phoneCountryCode,
      phoneNumber: payload.phoneNumber,
    });
  }
  return state;
};

export default reducer;
