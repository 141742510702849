import {
  IoChevronDownCircleSharp,
  IoChevronUpCircleSharp,
} from "react-icons/io5";
import { useSelector } from "react-redux";

const LiveSessionAudianceList = ({
  expandAttendeesList,
  toggleExpandAttendeesList,
  isHost,
  handleHostToggleMuteAllClicked,
}) => {
  // const [showList, setShowList] = useState(false);
  const language = useSelector((state) => state.language.lang);
  const attendeesList = useSelector((state) => state.liveSession.attendeesList);
  const currentUserData = useSelector((state) => state.auth.userData);

  // const toggleButtonPressed = () => {
  //   setShowList(!showList);
  // };

  // ${showList ? "show" : ""}

  return (
    <>
      {/* <button
        onClick={toggleButtonPressed}
        className="btn btn-toggle-audiance-list"
      >
        {showList ? (
          <i className="fas fa-chevron-right"></i>
        ) : (
          <i className="fas fa-chevron-left"></i>
        )}
      </button> */}
      <div
        className={`live-session-audiance-list show w-100 ${
          expandAttendeesList ? "expand" : ""
        }`}
      >
        <div className="d-flex justify-content-between align-items-center p-2 text-secondary">
          {isHost ? language.basicControls : language.allParticipants}
          <button
            onClick={toggleExpandAttendeesList}
            className="btn toggle-list-btn"
          >
            {expandAttendeesList ? (
              <IoChevronUpCircleSharp />
            ) : (
              <IoChevronDownCircleSharp />
            )}
          </button>
        </div>
        {expandAttendeesList && (
          <div className="items-list">
            {isHost && (
              <HostMuteAllButton
                handleHostToggleMuteAllClicked={handleHostToggleMuteAllClicked}
              />
            )}
            {isHost && (
              <p className="text-secondary mt-3 px-2">
                {language.allParticipants}
              </p>
            )}
            {attendeesList.length === 0 && (
              <p className="text-secondary text-center">
                {language.noAttendeesList}
              </p>
            )}
            {attendeesList
              .filter(
                (attendee) =>
                  attendee.id != currentUserData.id && attendee.detail
              )
              .map((attendee) => {
                return (
                  <AudianceListItem
                    key={attendee.id}
                    name={`${attendee.detail.name} ${
                      attendee.detail.surname || ""
                    }`}
                    imageURL={attendee.detail.profile_photo_url}
                  />
                );
              })}
            {/* <AudianceListItem />
          <AudianceListItem />
          <AudianceListItem />
          <AudianceListItem />
          <AudianceListItem />
          <AudianceListItem />
          <AudianceListItem />
          <AudianceListItem />
          <AudianceListItem />
          <AudianceListItem />
          <AudianceListItem />
          <AudianceListItem />
          <AudianceListItem />
          <AudianceListItem />
          <AudianceListItem />
          <AudianceListItem /> */}
          </div>
        )}
      </div>
    </>
  );
};

const HostMuteAllButton = ({ handleHostToggleMuteAllClicked }) => {
  const language = useSelector((state) => state.language.lang);
  const loading = useSelector((state) => state.liveSession.loadingHostMuteAll);
  const hostMuteAll = useSelector((state) => state.liveSession.hostMuteAll);

  let text;
  if (loading) {
    text = `${language.loading}...`;
  } else if (hostMuteAll) {
    text = language.unmuteAll;
  } else {
    text = language.muteAll;
  }

  return (
    <button
      disabled={loading}
      onClick={handleHostToggleMuteAllClicked}
      className="btn mute-all-btn text-secondary mt-2"
    >
      {text}
    </button>
  );
};

const AudianceListItem = ({ name, imageURL }) => {
  return (
    <div className="audiance-list-item">
      {imageURL ? (
        <img className="user-img" alt={name} src={imageURL} />
      ) : (
        <i className="fas fa-solid fa-user p-2"></i>
      )}
      <p className="name mr-auto">{name}</p>
      {/* <button className="btn btn-primary btn-mute">
        <i className="fas fa-microphone-alt-slash"></i>
      </button> */}
    </div>
  );
};

export default LiveSessionAudianceList;
