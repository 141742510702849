import { useEffect, useState } from "react";
import { getNutritionDescriptions } from "../services/MealPlanNetworkService";

const useNutritionDescriptions = ({ key }) => {
  const [loading, setLoading] = useState(false);
  const [nutritionDescriptions, setNutritionDescriptions] = useState(new Map());

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await getNutritionDescriptions();
      const nutritionsMap = new Map();
      for (const item of res ?? []) {
        nutritionsMap.set(item[key], item);
      }
      setNutritionDescriptions(nutritionsMap);
    })();
  }, [key]);

  return [loading, nutritionDescriptions];
};

export default useNutritionDescriptions;
