import {useSelector } from "react-redux";
import TopMenu from "./CommonControls/TopMenu";
const JoinUs = () => {
  const language = useSelector((state) => state.language);
  return (
  <section className="container-fluid mb-4 px-4">
    <div className="row">
    <TopMenu
      shouldHideBackButton={false}
      shouldHideProfileButton={false}
      shouldHideNotificationButton={false}
      shouldHideLogoButton={false}
      pageTitle={language.lang.joinUs}
    />
    </div>
    <div className="d-flex justify-content-between px-4 m-0 align-items-center mobile-px-none">
        <div className="d-flex align-items-center">
         <div className="footer-container mb-4 glossy-style screen-min-height">
           <div className="row">
           <h2 className="mt-2 mb-4">{language.lang.joinUs}</h2>
            <h4 className="mt-2 mb-4">{language.lang.joinAsPractitionerPara1}</h4>
            <p>{language.lang.joinAsPractitionerPara2}</p>
            <p>{language.lang.joinAsPractitionerPara3}</p>
        </div>
         </div>
         </div>
          </div>
    </section>);
  };
  
  export default JoinUs;
  