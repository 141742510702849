import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import axios from "../../services/Axios";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../utility/Helpers";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../redux/authenitcation/actionCreator";
import { dashboardRoute, viewMatchesRoute } from "../../routes";
import authActions from "../../redux/authenitcation/actions";
import { signupUserId } from "../../utility/constants";

const { loginErr } = authActions;
const CoSignUpOTPModal = ({
  show,
  handleModalClose,
  email,
  password,
  handleModalCancelPressed,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const language = useSelector((state) => state.language);
  const [otp, setOTP] = useState("");

  const [otpError, setOTPError] = useState("");
  const [loading, setLoading] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  const handleModalOkPressed = async () => {
    const isValid = validateOTP();
    if (!isValid) return;
    setLoading(true);
    const success = await submitOTP();
    setLoading(false);
    if (success) {
      setVerificationSuccess(true);
    } else {
      showErrorNotification("Invalid OTP code");
    }
  };

  const submitOTP = async () => {
    try {
      const userId = localStorage.getItem(signupUserId);
      const res = await axios.post("/verifySignupOtp", {
        otp: otp,
        userId: userId,
      });
      console.log("--- submit ", res);
      return res && res.data && !res.data.error;
    } catch (e) {
      console.log("--- submitOTP");
      console.log(e);
      return false;
    }
  };

  const validateOTP = () => {
    if (!otp) {
      setOTPError(language.lang["please-enter-otp"]);
      return false;
    } else if (otp.length !== 6) {
      setOTPError(language.lang["otp-must-be-6-chars"]);
      return false;
    }
    setOTPError("");
    return true;
  };

  const handleVerificationSuccessOkPressed = async () => {
    try {
      setLoading(true);
      const authData = await dispatch(login(email, password));
      // console.log("--- authData", authData);
      const dataType = typeof authData === "object";
      if (dataType && authData.userData.is_approved) {
        setLoading(false);
        handleLoginSuccess();
      } else if (!authData.userData.is_approved) {
        dispatch(loginErr(null));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleLoginSuccess = () => {
    if (history.location.state && history.location.state.nextRoute) {
      history.push(history.location.state.nextRoute, {
        ...history.location.state,
      });
    } else {
      history.push(dashboardRoute.path);
    }
  };

  const handleResendPressed = async () => {
    setLoading(true);
    try {
      const res = await axios.post("/resendOtp", { email });
      if (res.data.error) {
        throw new Error("Something went wrong");
      } else {
        showSuccessNotification(res.data.response);
      }
    } catch (e) {
      console.log("--- handleResendPressed error");
      console.log(e);
      showErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      className="sign-up-otp-modal"
      size="sm"
      backdrop="static"
      show={show}
      onHide={handleModalClose}
      centered
    >
      <Modal.Header>
        <Modal.Title>Register</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {verificationSuccess && (
          <p className="text-primary">
            {language.lang.registeredAccoundSuccesfullyOkToLogin}
          </p>
        )}
        {!verificationSuccess && (
          <p className="text-primary">
            {language.lang.verificationCodeSentToCompanyEmail}
          </p>
        )}
        {!verificationSuccess && (
          <p className="text-primary">{language.lang.enterVerificationCode}</p>
        )}
        {!verificationSuccess && (
          <input
            name="username"
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
            className="neumorphic-input w-100"
            placeholder={language.lang["email-otp"]}
          />
        )}
        {!verificationSuccess && otpError && (
          <p className="mb-0 mt-2 text-danger text-center">{otpError}</p>
        )}
      </Modal.Body>
      {verificationSuccess && !loading && (
        <Modal.Footer>
          <Button
            className="btn-primary d-flex justify-content-center align-items-center"
            onClick={handleVerificationSuccessOkPressed}
          >
            {language.lang.ok}
          </Button>
        </Modal.Footer>
      )}
      {loading && (
        <Modal.Footer>
          <Loading />
        </Modal.Footer>
      )}
      {!loading && !verificationSuccess && (
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            className="btn-primary d-flex justify-content-center align-items-center"
            onClick={handleModalCancelPressed}
          >
            {language.lang.cancel}
          </Button>
          <Button
            className="btn-primary d-flex justify-content-center align-items-center"
            onClick={handleModalOkPressed}
          >
            {language.lang.ok}
          </Button>
          <div className="d-flex justify-content-center align-items-center w-100">
            <button className="btn text-primary" onClick={handleResendPressed}>
              {language.lang.resend}
            </button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CoSignUpOTPModal;
