import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { dashboardMealPlanRoute } from "../../../routes";
import TopMenu from "../../CommonControls/TopMenu";
import SubscriptionPriceCard from "./SubscriptionPriceCard";

const SelectSubscriptionPlan = ({ history }) => {
  //   console.log("--- SelectSubscriptionPlan", history.location);
  const language = useSelector((state) => state.language.lang);
  const subscriptionPlan = history.location?.state?.subscriptionPlan;

  if (!subscriptionPlan) {
    return <Redirect to={dashboardMealPlanRoute.path} />;
  }

  return (
    <div className="select-subscription-plan">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language["pay-as-you-go"] ?? "pay-as-you-go"}
        hideCustomclass={true}
      />
      <div className="select-subscription-plan-card">
        <div className="price-cards-list">
          {subscriptionPlan?.subscriptionPrices?.map((item) => {
            return <SubscriptionPriceCard key={item.id} priceModel={item} history={history} />;
          })}
        </div>
        <div className="text-center mt-5">
          {language["you-can-cancel-subscription-any-time-from-settings"] ?? "you-can-cancel-subscription-any-time-from-settings"}
        </div>
      </div>
    </div>
  );
};

export default SelectSubscriptionPlan;
