import { Button, Drawer, Input } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useSelector } from "react-redux";
import useAddIngredientsDrawer from "../../../../customHooks/useAddIngredientsDrawer";
import Loading from "../../../Loading";
import DrawerAddedIngredientListItem from "./DrawerAddedIngredientListItem";
import DrawerIngredientListItem from "./DrawerIngredientListItem";

const AddIngredientDrawer = ({ open, onClose, onAddPressed, initialAddedIngs }) => {
  const language = useSelector((state) => state.language.lang);
  const [searchText, setSearchText] = useState("");
  const [addedIngredients, setAddedIngredients] = useState([]);
  const [loading, foodItemsTypesMap] = useAddIngredientsDrawer({ searchText: searchText.trim() });

  useEffect(() => {
    if (!initialAddedIngs) return;
    setAddedIngredients([...initialAddedIngs]);
    setSearchText("");
  }, [initialAddedIngs, open]);

  // useEffect(() => {
  //   if (!searchText.trim().length < 3) return;
  //   console.log("--- search", searchText);
  // }, [searchText]);

  const onAddIngredientPressed = (addedIngredient) => {
    setAddedIngredients((prevState) => [...(prevState ?? []), addedIngredient]);
  };

  const onRemoveIngredientPressed = (ingredient) => {
    setAddedIngredients((prevState) => {
      return prevState.filter((item) => item.ingredient.id !== ingredient.ingredient.id);
    });
  };

  const handleSearchFieldChanged = (e) => {
    // console.log("--- e", e.target.value);
    setSearchText(e.target.value);
  };
// Critical River, Varun Mishra, bug 3427, search functionality, This function filters 10 ingredients each from Common Foods, Branded Foods and Custom Foods
  const filterIngredientData = (foodItems) => {
    let updatedIngredientItems = [];
    let c1 = 1; // Common Foods counter
    let c2 = 1; // Branded Foods counter
    let c3 = 1; // Custom Foods counter
    foodItems.forEach((ingredient) => {
      if(ingredient.foodType.id === 1 && c1 <= 10) {
        updatedIngredientItems.push(ingredient);
        c1++;
      }
      if(ingredient.foodType.id === 2 && c2 <= 10) {
        updatedIngredientItems.push(ingredient);
        c2++;
      }
      if(ingredient.foodType.id === -1 && c3 <= 10) {
        updatedIngredientItems.push(ingredient);
        c3++;
      }
    });
    return updatedIngredientItems;
  };

  let content;
  if (loading) {
    content = (
      <div className="d-flex justify-content-center pt-5">
        <Loading />
      </div>
    );
  } else if (!foodItemsTypesMap.size) {
    content = (
      <div className="d-flex justify-content-center pt-5">
        <h5 className="text-center">{language["no-ingredients-found"] ?? "no-ingredients-found"}</h5>
      </div>
    );
  } else {
    content = (
      <>
        {addedIngredients && addedIngredients.length > 0 && (
          <div className="food-type-section">
            <div className="food-items-section-title mb-1">{language["ingredients-list"] ?? "ingredients-list"}</div>
            {addedIngredients.map((item) => {
              return (
                <DrawerAddedIngredientListItem
                  key={`added-${item.id}`}
                  addedIngredient={item}
                  onRemovePressed={onRemoveIngredientPressed}
                />
              );
            })}
          </div>
        )}
        {[...foodItemsTypesMap.keys()]
          .filter((key) => foodItemsTypesMap.get(key) && foodItemsTypesMap.get(key).length > 0)
          .map((key) => {
            const foodItems = (foodItemsTypesMap.get(key) ?? []).filter((item) => {
              return !addedIngredients.some((addedIng) => addedIng.ingredient.id === item.id);
            });
            // Critical River, Varun Mishra, Bug-3427, Search Functionality, Show only Limited Data on screen after removing the limit
            let updatedIngredientList = filterIngredientData(foodItems);
            let sectionName;
            if (key === -1) {
              sectionName = "custom-food";
            } else {
              sectionName = updatedIngredientList.length === 0 ? "" : updatedIngredientList[0].foodType.name;
            }
            return (
              <div key={key} className="food-type-section">
                <div className="food-items-section-title mb-1">{language[sectionName] ?? sectionName}</div>
                {updatedIngredientList.map((item) => {
                  return <DrawerIngredientListItem key={item.id} ingredient={item} onAddPressed={onAddIngredientPressed} />;
                })}
                {!updatedIngredientList || updatedIngredientList.length === 0 ? (
                  <div>{`${language["no"] ?? "no"} ${language[sectionName] ?? sectionName} ${language["found"] ?? "found"}`}</div>
                ) : null}
              </div>
            );
          })}
        {(foodItemsTypesMap?.size ?? 0) === 0 && <div>{language["no-results-found"] ?? "no-results-found"}</div>}
      </>
    );
  }

  return (
    <Drawer
      className="create-recipe-add-ingredient-drawer"
      onClose={onClose}
      placement="right"
      closable={false}
      key="add-ingredient-drawer"
      visible={open}
      mask={true}
      maskClosable={false}
    >
      <div className="drawer-container">
        <div className="d-flex justify-content-end">
          <Button onClick={onClose} type="link">
            {language["discard"] ?? "discard"}
          </Button>
          <Button onClick={() => onAddPressed(addedIngredients)} type="link">
            {language["add"] ?? "add"}
          </Button>
        </div>
        <div className="px-3">
          <Input
            className="search-field"
            size="large"
            placeholder={language["search-ingredients-to-add"] ?? "search-ingredients-to-add"}
            prefix={<AiOutlineSearch />}
            onChange={(e) => handleSearchFieldChanged(e)} // binding function
            value={searchText}
            allowClear
          />
        </div>
        <div className="ingredients-list">{content}</div>
        <div className="d-flex justify-content-end">
          <Button onClick={onClose} type="link">
            {language["discard"] ?? "discard"}
          </Button>
          <Button onClick={() => onAddPressed(addedIngredients)} type="link">
            {language["add"] ?? "add"}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default AddIngredientDrawer;
