import React from "react";
import ProfileCircleButton from "../components/ProfileCircleButton";
import NotificationsButton from "../components/NotificationsButton";
import BackButton from "../components/BackButton";
import { Row, Col, Form, Container } from "react-bootstrap";
import { Rate } from "antd";
import "antd/dist/antd.css";
import { VscFilePdf } from "react-icons/vsc";
import { IconContext } from "react-icons";
import sign from "../assets/images/sign.png";
import DocProfile from "../components/DoctorConsultationProfile";
import { useSelector } from "react-redux";

const DashboardConsultation = () => {
  const language = useSelector((state) => state.language);
  return (
    <div className="consultation">
      <div className="dashboard-book-consultation">
        <div className="col-12 notifications-and-profile mt-3 mt-md-0 justify-content-between mx-2">
          <div className="book-back-btn">
            <BackButton />
            <span className="consultation-heading">{language.lang.in_consultation}</span>
          </div>
          <div style={{ paddingRight: "15px" }}>
            <NotificationsButton />
            <ProfileCircleButton />
          </div>
        </div>
      </div>
      <Container fluid className="mt-5">
        <Row>
          <Col
            xs={12}
            md={6}
            className="consultation-section-1"
            style={{ position: "relative" }}
          >
            <h4 className="doctor-prescription--heading">
              {language.lang["download-prescription"]}
            </h4>
            <div style={{ position: "absolute", top: "11px", right: "27px" }}>
              <IconContext.Provider value={{ color: "#ee2c34" }}>
                <VscFilePdf size={52} />
              </IconContext.Provider>
            </div>
            <Row className="prescription-details mx-0">
              <Col xs={12} className="pres-download-btn">
                Click on the {language.lang["download-prescription"]} Button to download the
                prescription in pdf format
              </Col>
              <Col xs={12} className="pres-slip">
                <h4 className="pres-slip--heading bg-green">
                  Medical Prescription by Klippa Medical Center
                </h4>
                <p className="pres-slip-address bg-green">
                  Lubeckweg 2, 9723HE Groningen, The Netherlands
                </p>
              </Col>
              <Col xs={12} className="bg-peach pt-5">
                <div className="pres-detail--row">
                  <div className="pres-key">{language.lang["patient-name"]}:</div>
                  <div className="pres-value bg-green">
                    Mario Carlos van der Vaart
                  </div>
                </div>
                <div className="pres-detail--row">
                  <div className="pres-key">{language.lang["patient-age"]}:</div>
                  <div className="pres-value bg-green">28</div>
                </div>
                <div className="pres-detail--row">
                  <div className="pres-key">{language.lang.date}:</div>
                  <div className="pres-value bg-green">20-01-2005</div>
                </div>
              </Col>
              <Col xs={12} className="bg-peach">
                <Row className="my-2">
                  <div className="pres-detail--row">
                    <div className="pres-code pres-key">&#8478;</div>
                    <div className="pres-medicines">
                      <div
                        className="pres-value bg-green"
                        style={{ fontSize: "20px" }}
                      >
                        Paracetamol - 50 mg
                      </div>
                      <div
                        className="pres-value bg-green"
                        style={{ fontSize: "20px", marginTop: "5px" }}
                      >
                        Ibuprofen - 150 mg
                      </div>
                    </div>
                  </div>
                </Row>
              </Col>
              <Col xs={12} className="bg-peach">
                <Row className="my-2">
                  <div className="pres-detail--row justify-content-between">
                    <div>
                      <div
                        className="mb-3"
                        style={{ fontSize: "16.5px", fontWeight: "600" }}
                      >
                        Signed By:
                      </div>
                      <div
                        className="pres-value bg-green"
                        style={{ fontSize: "20px" }}
                      >
                        Dr. Bill Andrew Balmer
                      </div>
                    </div>
                    <div className="pres-value" style={{padding: '0'}}>
                      <img alt="doctor-img" src={sign} className="doc-sign" />
                    </div>
                  </div>
                </Row>
              </Col>
              <div style={{ textAlign: "right" }}>
                <button className="shadow-none btn btn-primary custom-btn-2">
                  Download prescription
                </button>
              </div>
            </Row>
          </Col>

          <Col xs={12} md={6} className="consultation-section-2">
            <div
              className="dashboard-book-consultation"
              style={{ height: "100px" }}
            >
              <div className="col-12 notifications-and-profile mt-3 justify-content-center mx-2">
                <div>
                  <DocProfile />
                </div>
              </div>
            </div>
            <div>
              <div className="doctor-details">
                <h4 className="doctor-details--heading">
                  How was your experience with <br />
                  <span className="dark-blue">Dr Tanisha Khan ?</span>
                </h4>
                <div className="mt-3 mb-2">
                  <p className="text-center">
                    <Rate />
                  </p>
                </div>
                <div className="">
                  <div className="doctor-details-text">
                    <div className="doctor-details-text-1">Write a comment</div>
                    <div className="doctor-details-text-2">Max 600 words</div>
                  </div>
                  <Form className="text-center shadow-none">
                    <textarea
                      className="doctor-form--input"
                      placeholder="Tanisha is a good doctor. She know how to treatment a patients. Very Good Service. Well Professional."
                    ></textarea>
                  </Form>
                </div>
                <div className="doctor-details-question">
                  Would you recommend Dr. Tanisha Khan to your friend ?
                </div>
                <div className="doctor-form-radio">
                  <input
                    className="form-check-input mr-2"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="yes"
                  />
                  <label
                    className="form-check-label mr-3"
                    for="flexRadioDefault1"
                  >
                    {language.lang.yes}
                  </label>
                  <input
                    className="form-check-input mr-2"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value="no"
                  />
                  <label
                    className="form-check-label mr-3"
                    for="flexRadioDefault2"
                  >
                    {language.lang.no}
                  </label>
                </div>
                <div className="text-center">
                  <button className="shadow-none btn btn-primary custom-btn">
                    Give Review
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardConsultation;
