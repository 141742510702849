const actions = {
  SET_AGORA_CLIENT: "SET_AGORA_CLIENT",
  ADD_REMOTE_STREAM_ID: "ADD_REMOTE_STREAM_ID",
  REMOVE_REMOTE_STREAM_ID: "REMOVE_REMOTE_STREAM_ID",
  ENABLE_VIDEO: "ENABLE_VIDEO",
  ENABLE_AUDIO: "ENABLE_AUDIO",
};

export const enableAudioAction = (enabled = true) => {
  return { type: actions.ENABLE_AUDIO, payload: enabled };
};

export const enableVideoAction = (enabled = true) => {
  return { type: actions.ENABLE_VIDEO, payload: enabled };
};

export const removeRemoteStreamID = (id) => {
  return { type: actions.REMOVE_REMOTE_STREAM_ID, payload: id };
};

export const setAgoraClientAction = (client) => {
  return { type: actions.SET_AGORA_CLIENT, payload: client };
};

export const addRemoteStreamID = (id) => {
  return { type: actions.ADD_REMOTE_STREAM_ID, payload: id };
};

export default actions;
