import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "./BackButton";
import NotificationsButton from "./NotificationsButton";
import ProfileCircleButton from "./ProfileCircleButton";
import MyCalendar from "./MyCalendar";
import { Drawer } from "antd";
import { getTopPractitioners } from "../redux/clinic/actionCreators";
import clinicactions from "../redux/clinic/actions";
import { BsFilterRight } from "react-icons/bs";
import ClinicAppointmentCard from "./ClinicAppointmentCard";
import TopMenu from "./CommonControls/TopMenu";
import { lang } from "moment";

const ClinicCalendar = () => {
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch({ type: clinicactions.SET_VIEW_SLOT, payload: false });
  };
  const TopPractitioner = useSelector((state) => state.clinic.top_practitioner);
  useEffect(() => {
    TopPractitioner.length === 0 && dispatch(getTopPractitioners());
  }, []);
  const viewSlot = useSelector((state) => state.clinic.viewSlot);
  const selectedSlot = useSelector((state) => state.clinic.selectedSlot);
  return (
    <div className="px-sm-4 pb-sm-5 doctor-dashboard-home calendar-screen">
      <div className="row">
      <TopMenu
          shouldHideBackButton={false}
          shouldHideProfileButton={false}
          shouldHideNotificationButton={false}
          shouldHideLogoButton={false}
          pageTitle={language.lang.calendar}
          hideCustomclass={true}
        />
      </div>
      <div className="mt-2 d-flex justify-content-center w-100">
        <div className="mm-card doctor-dashboard-card min-card-width p-0 w-100 pb-4">
          <MyCalendar TopPractitioner={TopPractitioner} />
          {/* <ClinicCalendarDoctor />*/}
        </div>
      </div>
      <Drawer
        placement={"right"}
        closable={true}
        onClose={handleCancel}
        visible={viewSlot}
        width={400}
      >
        <div>{language.lang.appointments}</div>
        <div className="d-flex w-100 mt-4 mb-3 selected-appoint">
          <div className="col-2 p-0 headings text-end">Time</div>
          <div className="col-8 p-0 mx-2 ml-3 headings">Details</div>
          <div className="col-2 p-0 headings">
            <BsFilterRight size={25} />
          </div>
        </div>
        <ClinicAppointmentCard selectedSlot={selectedSlot} />
      </Drawer>
    </div>
  );
};

export default ClinicCalendar;
