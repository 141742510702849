import actions from "./actions";

export const setInitiatedAndCompletedTransaciton = (payload) => {
  return {
    type: actions.SET_INITIATED_AND_COMPLETED_TRANSACTION,
    payload,
  };
};

export const setCompletedTransaction = (transaction) => {
  return {
    type: actions.SET_COMPLETED_TRASNACTION,
    payload: transaction,
  };
};

export const setInitiatedTransactionIDAction = (id) => {
  return {
    type: actions.SET_INITIATED_TRANSACTION_ID,
    payload: id,
  };
};

export const setDoctorStartedAppointmentIDAction = (appointmentID) => {
  return {
    type: actions.SET_DOCTOR_STARTED_APPOINTMENT_ID,
    payload: appointmentID,
  };
};

export const setDoctorStartedAppointmentDetails = (status) => {
  return {
    type: actions.SET_DOCTOR_STARTED_APPOINTMENT_DETAILS,
    payload: status,
  };
};

export const setAppointmentDetailsAction = (appointment) => {
  return {
    type: actions.SET_APPOINTMENT_DETAILS,
    payload: appointment,
  };
};

export const setIsWatingForDoctorApprovalAction = (payload) => {
  return { type: actions.SET_IS_WAITING_DOCTOR_APPROVAL, payload };
};

export const setIsNotWatingForDoctorApprovalAction = () => {
  return { type: actions.SET_IS_NOT_WAITING_DOCTOR_APPROVAL };
};
