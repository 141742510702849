import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer } from "antd";
import Loading from "../../Loading";
import InputFieldFormat from "../../InputFieldFormat";
import { MultiSelect } from "primereact/multiselect";
const MealPlanFilterSideMenu = ({
  isOpened,
  onClosePressed,
  formData,
  setFormData,
  setfilterCounter,
  setcounter,
  setCombo,
  FoodTypeProviderSelected,
  getFoodTypeProvider,
  FoodTypeProviderHandler,
  setFoodTypeProviderSelected,
  closeFoodTypeProviderSelected,
  FoodRecipeCategoryProviderSelected,
  getFoodRecipeCategoryProvider,
  FoodRecipeCategoryHandler,
  setFoodRecipeCategoryProviderSelected,
  closeFoodRecipeCategoryProviderSelected,
  //Food Type
  passFoodTypeFilterValues,
  selectedFoodType,
  setSelectedFoodType,
  setFoodTypeDisplay,
  //Food Category
  passFoodCategoryFilterValues,
  selectedFoodCategory,
  setSelectedFoodCategory,
  setFoodCategoryDisplay,
  setFinalSelectedFoodCategory,
  //showHideTiles
  setShowBrandedFoods,
  setShowCommonFoods,
  setShowMyFoods,
  setShowMyRecipes,
  setShowRecipes
})=> {
    const TypeList = {
      Type: "type",
      Category: "category",
      Serving: "serving",
    };
    const [foodCategoryList,setFoodCategoryList]=useState([]);
    const language = useSelector((state) => state.language);
    const [loading, setloading] = useState(true);
    let defaultData = {
      specialityId: "",
      consultantType: "",
      genderId: "",
      feesMin: "",
      feesMax: "",
      regionId: "",
      userGroup: "",
      offset: 0,
      FoodRecipeCategoryProviderId: "",
      FoodTypeProviderId: "",
      comboInput: "",
    };  
  const handleClose = (e) => {
    onClosePressed();
  };
  const applyPressed=(e) =>{
    if(foodCategoryList.length > 0){
      setFinalSelectedFoodCategory(foodCategoryList);
      passFoodCategoryFilterValues();
    }
    handleClose();
  };
  const FoodRecipeCategoryHandlerr = (e) => {
    let categoryIds=[];
    setSelectedFoodCategory(e.value);
    e.value.forEach((item)=>{
      setFoodCategoryDisplay(item[0]);
      categoryIds.push(item[1]);
      setFoodCategoryList(categoryIds);
    });
  }
  const FoodTypeHandlerr = (e) => {
    setSelectedFoodType(e.value);
    if(e.value.length >= 1){
      setShowBrandedFoods(false);
      setShowCommonFoods(false);
      setShowMyFoods(false);
      setShowMyRecipes(false);
      setShowRecipes(false);
    }
    else{
      setShowBrandedFoods(true);
      setShowCommonFoods(true);
      setShowMyFoods(true);
      setShowMyRecipes(true);
      setShowRecipes(true);
      setFoodTypeDisplay({});
    }
    e.value.forEach((item)=>{
        setFoodTypeDisplay(item[0]);
        console.log("All Elements: ",item[0]);
        if(item[0]=='Branded Foods')
          setShowBrandedFoods(true);
        if(item[0]=='Common Foods')
          setShowCommonFoods(true);
        if(item[0]=='My Foods')
          setShowMyFoods(true);
        if(item[0]=='My Recipes')
          setShowMyRecipes(true);
        if(item[0]=='Recipes')
          setShowRecipes(true);
    });
    
    ///console.log((!!e.value && e.value.length >= 2) ? e.value[0] + " and " + selectedFoodType.length - 1 + " more" : foodTypeDisplay);
    //setFoodTypeCounter(e.value.length);
    //console.log('Type : ',selectedFoodType);
  }
  
  return (
    <Drawer
      placement={"right"}
      closable={true}
      onClose={handleClose}
      visible={isOpened}
      width={350}
      className="filter-side-menu"
      >
      <div className="filters-side-menu slim-scroll mt-2 ml-2 mr-2">
        <div className="mt-2">
          <h4 className="title py-2">Food Types</h4>
          <InputFieldFormat
            show={true}
            element={
            <MultiSelect
              value={selectedFoodType}
              options={getFoodTypeProvider}
              onChange={FoodTypeHandlerr}
              optionLabel="name"
              placeholder="Food Types"
              filter 
              className="multiselect-custom"
              showSelectAll={false}
              maxSelectedLabels={3} /> 
            }>
            </InputFieldFormat>
          <h4 className="title py-2">Food Category</h4>
          <InputFieldFormat
            show={true}
            element={
            <MultiSelect
            value={selectedFoodCategory}
            options={getFoodRecipeCategoryProvider}
            //onChange={(e) =>setSelectedFoodRecipeCategory({ ...selectedFoodRecipeCategory, value: e.value })}
            //onChange={(e) =>setSelectedFoodRecipeCategory({...selectedFoodRecipeCategory,value: e.value})}
            onChange={FoodRecipeCategoryHandlerr}
            optionLabel="name"
            placeholder="Food Category"
            filter 
            className="multiselect-custom"
            showSelectAll={false}
            maxSelectedLabels={3} /> } ></InputFieldFormat>
          <h4 className="title py-2">Servings</h4>
          {/* <InputFieldFormat
            show={true}
            element={
            <MultiSelect
            value={selectedFoodRecipeCategory}
            options={getFoodRecipeCategoryProvider}
            //onChange={(e) =>setSelectedFoodRecipeCategory({ ...selectedFoodRecipeCategory, value: e.value })}
            optionLabel="name"
            placeholder="Servings"
            filter 
            className="multiselect-custom"
            maxSelectedLabels={3} /> } ></InputFieldFormat> */}
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center mt-3" style={{ gap: 20 }} > 
            <button type="button" className="filter-apply-btn" onClick={applyPressed}>
              {language.lang.apply}
            </button> 
            <button type="button" className="filter-cancel-btn" onClick={handleClose}> 
            {language.lang.cancel} 
            </button> 
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </Drawer>
  );
};
export default MealPlanFilterSideMenu;