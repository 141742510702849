import { Button } from "antd";
import { AiFillCheckCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import usePriceBasedOnRegion from "../../../customHooks/usePriceBasedOnRegion";
import useSubscriptionPlan from "../../../customHooks/useSubscriptionPlan";
import { selectSubscriptionPlanRoute } from "../../../routes";
import TopMenu from "../../CommonControls/TopMenu";
import Loading from "../../Loading";
import {
  subscriptionValidityUnitTwoLetterShort,
  subscriptionValidityUnitText,
  subscriptionValidityUnitName,
} from "../../../utility/constants";

const SubscriptionPlanDetails = ({ history }) => {
  const language = useSelector((state) => state.language.lang);
  const languagePrefs = useSelector((state) => state.language);
  const currentLang = languagePrefs.general.preferredlanguage;
  const [loading, subscriptionPlan] = useSubscriptionPlan({});
  const getPriceBasedOnRegion = usePriceBasedOnRegion();

  // console.log({ subscriptionPlan });

  const onGetStartedClicked = () => {
    history.push(selectSubscriptionPlanRoute.path, { subscriptionPlan });
  };

  let content;
  if (loading) {
    content = (
      <div className="d-flex justify-content-center align-items-center py-5">
        <Loading />
      </div>
    );
  } else if (!subscriptionPlan) {
    content = <h5 className="d-flex justify-content-center align-items-center">{language["error"] ?? "error"}</h5>;
  } else {
    const firstPriceModel = subscriptionPlan.subscriptionPrices[0];
    const secondPriceModel = subscriptionPlan.subscriptionPrices[1];
    const [firstPriceModelCurrency, firstPriceModelPrice] = getPriceBasedOnRegion(firstPriceModel?.multiCurrencyPrices ?? {});
    const [secondPriceModelCurrency, secondPriceModelPrice] = getPriceBasedOnRegion(secondPriceModel?.multiCurrencyPrices ?? {});
    content = (
      <div>
        <h4 className="text-center">{language["premium"] ?? "premium"}</h4>
        <div className="subscription-price">
          <div className="price">{`${firstPriceModelCurrency} ${firstPriceModelPrice}`}</div>
          <div className="validity">{`/${
            language[subscriptionValidityUnitTwoLetterShort[firstPriceModel.validity_unit]] ??
            subscriptionValidityUnitTwoLetterShort[firstPriceModel.validity_unit]
          }`}</div>
        </div>
        <div className="text-center text-secondary mb-3">
          {`${language["billed"] ?? "billed"} ${
            language[subscriptionValidityUnitText[secondPriceModel.validity_unit]] ?? [
              subscriptionValidityUnitText[secondPriceModel.validity_unit],
            ]
          } `}
          {`${secondPriceModelCurrency} ${secondPriceModelPrice}`}
          {` / ${
            language[subscriptionValidityUnitName[secondPriceModel.validity_unit]] ??
            subscriptionValidityUnitName[secondPriceModel.validity_unit]
          }`}
        </div>
        <div>
          {subscriptionPlan?.subscriptionDetails?.details?.map((item) => {
            let text;
            if (currentLang === "ar") {
              text = item.detail_ar ?? item.detail_en;
            } else if (currentLang === "ku") {
              text = item.detail_ku ?? item.detail_en;
            } else {
              text = item.detail_en;
            }
            return (
              <div key={item.detail_en} className="plan-feature">
                <div className="icon">
                  <AiFillCheckCircle />
                </div>
                <div className="text">{text}</div>
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-center mb-3">
          <Button onClick={onGetStartedClicked} size="large" type="primary">
            {language["get-started"] ?? "get-started"}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="subscription-plan-details">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language["pay-as-you-go"] ?? "pay-as-you-go"}
        hideCustomclass={true}
      />
      <div className="subscription-plan-details-card">{content}</div>
    </div>
  );
};

export default SubscriptionPlanDetails;
