import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import PractitionerNetworkService from "../../services/PractitionerNetworkService";
import Loading from "../Loading";
import { FaTimes } from "react-icons/fa";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { customerCalendarRoute, dashboardRoute } from "../../routes";

const SessionRegisterSuccessModal = ({
  show,
  sessionDetails,
  onHide,
  onCloseClicked,
}) => {
  const language = useSelector((state) => state.language);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [practitionerDetails, setPractitionerDetails] = useState({});
  const startDateTime =
    sessionDetails &&
    sessionDetails.start_date_time_utc &&
    sessionDetails.start_date_time_utc.split(" ")[0];
  const endDateTime =
    sessionDetails &&
    sessionDetails.end_date_time_utc &&
    sessionDetails.end_date_time_utc.split(" ")[0];
  const startDateTimeMoment = moment.utc(startDateTime).local();
  const endDateTimeMoment = moment.utc(endDateTime).local();

  // console.log({ startDateTimeMoment });

  useEffect(() => {
    if (!show) return;
    // console.log("--- session ", sessionDetails);
    PractitionerNetworkService.getPractitionerDetails({
      practitionerID: sessionDetails.host_id,
    })
      .then((practitioner) => setPractitionerDetails(practitioner))
      .finally(() => setLoading(false));
  }, [show]);

  const handleCloseClicked = () => {
    if (onCloseClicked) {
      return onCloseClicked();
    }
    history.replace(dashboardRoute.path);
  };

  const onOkClicked = () => {
    history.replace(customerCalendarRoute.path);
  };

  // console.log("--- sessionDetails ", sessionDetails);

  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header>
        <Modal.Title className="d-flex justify-content-between">
          {language.lang.eventRegisterationDetails}{" "}
          <button onClick={handleCloseClicked} className="btn">
            <FaTimes />
          </button>
        </Modal.Title>
      </Modal.Header>
      {loading ? (
        <Loading />
      ) : (
        <Modal.Body>
          <h5 className="text-success">
            {language.lang.successfulyRegisteredForSession}
          </h5>
          <h5 className="mb-3">
            {language.lang.sessionDetailsAddedToCalendar}
          </h5>
          <div className="d-flex">
            <p className="mr-2">{language.lang.organized}: </p>
            <p>
              {practitionerDetails.name} {practitionerDetails.surname}
            </p>
          </div>
          {startDateTime && (
            <div className="d-flex">
              <p className="mr-2">{language.lang.date}: </p>
              <p>{startDateTimeMoment.format("DD/MM/YYYY")}</p>
            </div>
          )}
          <div className="d-flex">
            <p className="mr-2">{language.lang.startTime}: </p>
            <p>{startDateTimeMoment.format("hh:mm a")}</p>
          </div>
          {endDateTime && (
            <div className="d-flex">
              <p className="mr-2">{language.lang.endTime}: </p>
              <p>{endDateTimeMoment.format("hh:mm a")}</p>
            </div>
          )}
        </Modal.Body>
      )}
      <Modal.Footer className="d-flex justify-content-around">
        <button onClick={handleCloseClicked} className="btn btn-primary">
          {language.lang.cancel}
        </button>
        <button onClick={onOkClicked} className="btn btn-primary">
          {language.lang.ok}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SessionRegisterSuccessModal;
