import React from 'react'
import { useSelector } from 'react-redux';

const VoucherCard = ({className}) => {
    const language = useSelector((state) => state.language);
    return (
        <div className="voucher-outer-box">
            <div className={className}>
                <div className="voucher-card--upper">
                    <p>Voucher Value</p>
                    <h5>AED 120</h5>
                </div>

                <div className="voucher-card--lower">
                    <div className="voucher-div">
                        <h5>MAS-VOUCHER</h5>
                        <p>{language.lang.save} 98%</p>
                    </div>
                    <div className="voucher-div">
                        <p className="voucher-para">Redeem on all plan 60</p>
                        <p>Sold 2</p>
                    </div>
                </div>
                <div className="rounded-circle--left">lsls</div>
                <div className="rounded-circle--right">lsls</div>

            </div>
        </div>
    )
}

export default VoucherCard;