import { settings } from "../../config";
import store from "../store";
import actions from "./actions";
import axios from "../../services/Axios";

export const resetBasicFoodsAction = () => {
  return {
    type: actions.RESET_BASIC_FOODS,
  };
};
export const setBasicFoodsAction = ({ basicfoods }) => {
  return {
    type: actions.GET_BASIC_FOODS,
    payload: basicfoods,
  };
};
export const resetBrandedFoodsAction = () => {
  return {
    type: actions.RESET_BRANDED_FOODS,
  };
};
export const setBrandedFoodsAction = ({ brandedfoods }) => {
  return {
    type: actions.GET_BRANDED_FOODS,
    payload: brandedfoods,
  };
};
export const resetRecipesAction = () => {
  return {
    type: actions.RESET_RECIPES,
  };
};
export const setRecipesAction = ({ recipes }) => {
  return {
    type: actions.GET_RECIPES,
    payload: recipes,
  };
};


export const getIngredients = (offset, limit) => {
    let endURL = `getIngredients?ingredientId=1&ingredientType=1&ingredientCategory=10&ingredientUrlName=testIngredient&offset=0&limit=5`;
    return async (dispatch) => {
      try {
        const res = await axios.get(`${settings.apiBaseURL}/${endURL}`);
      }
      catch (e){

      }
    }
}