import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import axios from "../../../services/Axios";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../utility/Helpers";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../../redux/authenitcation/actionCreator";
import { dashboardRoute, viewMatchesRoute } from "../../../routes";
import authActions from "../../../redux/authenitcation/actions";
import { signupUserId } from "../../../utility/constants";

const { loginErr } = authActions;
const EventsLanding = ({
  show,
  handleModalClose,
  email,
  password,
  handleModalCancelPressed,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const language = useSelector((state) => state.language);
  const [otp, setOTP] = useState("");

  const [otpError, setOTPError] = useState("");
  const [loading, setLoading] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  const handleModalOkPressed = async () => {
    const isValid = validateOTP();
    if (!isValid) return;
    setLoading(true);
    const success = await submitOTP();
    setLoading(false);
    if (success) {
      setVerificationSuccess(true);
    } else {
      showErrorNotification("Invalid OTP code");
    }
  };

  const submitOTP = async () => {
    try {
      const userId = localStorage.getItem(signupUserId);
      const res = await axios.post("/verifySignupOtp", {
        otp: otp,
        userId: userId,
      });
      console.log("--- submit ", res);
      return res && res.data && !res.data.error;
    } catch (e) {
      console.log("--- submitOTP");
      console.log(e);
      return false;
    }
  };

  const validateOTP = () => {
    if (!otp) {
      setOTPError(language.lang["please-enter-otp"]);
      return false;
    } else if (otp.length !== 6) {
      setOTPError(language.lang["otp-must-be-6-chars"]);
      return false;
    }
    setOTPError("");
    return true;
  };

  const handleVerificationSuccessOkPressed = async () => {
    try {
      setLoading(true);
      const authData = await dispatch(login(email, password));
      // console.log("--- authData", authData);
      const dataType = typeof authData === "object";
      if (dataType && authData.userData.is_approved) {
        setLoading(false);
        handleLoginSuccess();
      } else if (!authData.userData.is_approved) {
        dispatch(loginErr(null));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleLoginSuccess = () => {
    if (history.location.state && history.location.state.nextRoute) {
      history.push(history.location.state.nextRoute, {
        ...history.location.state,
      });
    } else {
      history.push(dashboardRoute.path);
    }
  };

  const handleResendPressed = async () => {
    setLoading(true);
    try {
      const res = await axios.post("/resendOtp", { email });
      if (res.data.error) {
        throw new Error("Something went wrong");
      } else {
        showSuccessNotification(res.data.response);
      }
    } catch (e) {
      console.log("--- handleResendPressed error");
      console.log(e);
      showErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      className="sign-up-otp-modal"
      size="sm"
      backdrop="static"
      show={show}
      onHide={handleModalClose}
      centered
    >
      <Modal.Header>
        <Modal.Title>Register</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
      </Modal.Body>
      
        <Modal.Footer className="d-flex justify-content-between">
          
        </Modal.Footer>
      
    </Modal>
  );
};

export default EventsLanding;
