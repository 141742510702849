import axios from "../../services/Axios";
import React, { useState, useRef, useEffect } from "react";
import Loading from "../../components/Loading";
import CirclesBackground from "../../components/CirclesBackground";
import { paymentErrorRoute } from "../../routes";

const Payment = ({ location, history }) => {
  const formRef = useRef(null);
  const searchParams = new URLSearchParams(location.search);
  const [paymentInfo, setPaymentInfo] = useState({});

  useEffect(() => {
    payPressed();
  }, []);

  const payPressed = async () => {
    const userID = searchParams.get("userID");
    const amount = searchParams.get("amount");
    // console.log("--- params", userID, amount);
    if (!userID || !amount) {
      history.replace(paymentErrorRoute.path);
      return;
    }
    try {
      const res = await axios.post("/paymentRegistration", {
        userId: userID,
        amount: amount,
      });
      setPaymentInfo(res.data.response);
      // console.log("--- pay", res.data.response);
      formRef.current.submit();
    } catch (e) {
      console.log("--- /paymentRegistration error");
      console.log(e);
      history.replace(paymentErrorRoute.path);
    }
  };

  return (
    <CirclesBackground>
      <div className="page-container">
        <h1 className="my-5 text-center">Please wait</h1>
        <Loading />
        {paymentInfo && Object.values(paymentInfo).length > 0 && (
          <form ref={formRef} method="POST" action={paymentInfo.PaymentPortal}>
            <input
              type="hidden"
              name="TransactionID"
              value={paymentInfo.TransactionID}
            />
            <input type="hidden" name="PayButtonColor" value="#186cd8" />
            <input type="hidden" name="PayButtonTextColor" value="#FFF" />
            <input type="hidden" name="ShowLogo" value="True" />
          </form>
        )}
      </div>
    </CirclesBackground>
  );
};

export default Payment;
