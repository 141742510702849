import { useSelector } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import { mealPlanOnboardingRoute } from "../../../routes";

const DietTypes = ({ dietTypes, history }) => {
  const location = useLocation();
  const dietGoal = location.state?.dietGoal;

  // console.log({ dietTypes });
  // console.log("--- location", history.location);

  if (!dietGoal) {
    return <Redirect to={mealPlanOnboardingRoute.path} />;
  }

  const handleTypeClick = (type) => {
    history.push(`${mealPlanOnboardingRoute.path}/allergies-exclusions`, { dietGoal, dietType: type });
  };

  return (
    <div className="diet-types">
      {dietTypes?.map((item) => {
        return <DietTypeItem key={item.id} onClick={handleTypeClick} type={item} />;
      })}
    </div>
  );
};

const DietTypeItem = ({ type, onClick }) => {
  const languagePrefs = useSelector((state) => state.language);
  const currentLang = languagePrefs.general.preferredlanguage;

  let name;
  let description;
  if (currentLang === "ar") {
    name = type?.name_ar ?? type?.name;
    description = type.description_ar ?? type.description;
  } else if (currentLang === "ku") {
    name = type?.name_ku ?? type.name;
    description = type.description_ku ?? type.description;
  } else {
    name = type?.name;
    description = type.description;
  }

  return (
    <div onClick={() => onClick(type)} className="diet-type-item">
      <div className="name">{name}</div>
      <div className="description">{description}</div>
    </div>
  );
};

export default DietTypes;
