import { useRef, useReducer, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import reducer, { initialState } from "./reducer";
import { inputChangeAction } from "./actions";
import { showErrorNotification } from "../../utility/Helpers";
import MedicalDocumentField from "../MedicalDocumentField";
import VoiceRecordField from "../VoiceRecordField";
import GenderSelectField from "../GenderSelectField";
import MMRadioButton from "../MMRadioButton";
import Loading from "../Loading";
import axios from "../../services/Axios";
import moment from "moment";
import { dashboardBookConsultationRoute } from "../../routes";
import { GetPractitionerCurrencyCodeAndFees } from "../../utility/Helpers";

const OnboardingFormForRole = (props) => {
  const { onFormSubmited } = props;
  const language = useSelector((state) => state.language);
  const [loading, setloading] = useState(false);
  const { userData } = useSelector((state) => state.auth);
  const [data, Setdata] = useState(false);
  const [Fdata, SetFdata] = useState("");
  const [appear, setappear] = useState(false);
  const [agree, setagree] = useState("1");
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const recordFileRef = useRef(null);
  const medicalDocFileRef = useRef(null);
  const { currencyCode, practitionerFees } = GetPractitionerCurrencyCodeAndFees(
    {
      selectedRegionID: language.regionID,
      feesWithMultiCurrency: props.practitioner.feesWithMultiCurrency,
    }
  );

  useEffect(() => {
    dispatch(inputChangeAction({ name: "profileCompleteStep", value: "0" }));
  }, []);

  useEffect(() => {
    getPatientFormData();
    if (data) SortNow();
  }, [data]);

  const getPatientFormData = async () => {
    setloading(true);
    await axios
      .get("/getPatientDetails", {
        params: { patientId: userData.id },
      })
      .then((res) => {
        SetFdata(res.data.response[0]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        Setdata(true);
      });
    setloading(false);
  };

  const handleInputChange = (e) => {
    var tar = e.target;
    dispatch(inputChangeAction(tar));
  };

  const handleMedicalRecordChange = (file) => {
    medicalDocFileRef.current = file;
  };

  const handleRecordChange = (file) => {
    recordFileRef.current = file;
  };

  const handleGenderChange = (gender) => {
    dispatch(inputChangeAction({ name: "gender_id", value: gender }));
    dispatch(inputChangeAction({ name: "gender", value: gender }));
  };

  const OnSelectCountryHandler = (e) => {
    dispatch(inputChangeAction({ name: "regionId", value: e.target.value }));
  };
  const SortNow = () => {
    Object.keys(Fdata).map((key, value) => {
      let newkey = "";
      let flag = true;
      if (!state.formData.hasOwnProperty(key)) {
        switch (key) {
          case "birth_date":
            newkey = "dob";
            break;
          case "region_id":
            newkey = "regionId";
            break;
          default:
            flag = false;
            break;
        }
      }
      if (flag) {
        let x = { name: newkey || key, value: Fdata[key] };
        dispatch(inputChangeAction(x));
      }
    });
  };
  const meetDoctorPressed = async () => {
    if (!validForm()) return;
    const onboardingFormData = {
      ...state.formData,
      userId: userData.id,
      appointmentType: "WalkIn",
    };
    localStorage.setItem("patient-data", JSON.stringify(onboardingFormData));

    if (history.location.state && history.location.state.slotId) {
      onboardingFormData.callType = "video";
      onboardingFormData.slotId = history.location.state.slotId;
      onboardingFormData.practitionerId = history.location.state.practitionerId;
    }
    if (onFormSubmited) {
      onFormSubmited(
        onboardingFormData,
        medicalDocFileRef.current,
        recordFileRef.current,
        props &&
          props.history &&
          props.history.location &&
          props.history.location.state &&
          props.history.location.state.book_consultation
      );
    }
  };

  const validForm = () => {
    for (const [key, value] of Object.entries(state.formData)) {
      if (
        key === "name" &&
        (value === null || value.trim() === "" || value === " ")
      ) {
        showErrorNotification("Please fill the Name");
        return false;
      } else if (
        key === "phone" &&
        (value === null || value.trim() === "" || value === " ")
      ) {
        showErrorNotification("Please fill the Phone Number");
        return false;
      } else if (
        key === "email" &&
        (value === null || value.trim() === "" || value === " ")
      ) {
        showErrorNotification("Please fill the Email");
        return false;
      } else if (
        key === "reason" &&
        (value === null || value.trim() === "" || value === " ") &&
        recordFileRef.current === null
      ) {
        showErrorNotification("Please fill the Reason or Upload Voice Record ");
        return false;
      } else if (
        key === "dob" &&
        (value === null || value.trim() === "" || value === " ")
      ) {
        showErrorNotification("Please fill the Date of Birth");
        return false;
      }
    }

    return true;
  };

  return (
    <section className="doctor-tiles row mt-4 pb-lg-5 onboardform-region">
      {loading ? (
        <div className="w-100" style={{ height: "80vh" }}>
          <Loading />
        </div>
      ) : (
        <>
          <div className="tiles-container col-12 col-lg-5 ">
            <div className="d-flex align-items-center input-field">
              <label htmlFor="name">
                {language.lang.location}{" "}
                <span className="required-field-star"> *</span>
              </label>
              <select
                className="neumorphic-input"
                value={state.formData.regionId}
                onChange={OnSelectCountryHandler}
                disabled
              >
                {language.general.regions &&
                  language.general.regions.length > 0 &&
                  language.general.regions.map((region, i) => {
                    return (
                      <option key={i} value={region.id}>
                        {region.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="d-flex align-items-center input-field mt-3">
              <label htmlFor="name">
                {language.lang.name}{" "}
                <span className="required-field-star"> *</span>
              </label>
              <input
                value={state.formData.name}
                onChange={handleInputChange}
                name="name"
                className="neumorphic-input"
                placeholder={language.lang.name}
                pattern="/^[A-Za-z]+$/"
              />
            </div>
            <div className="d-flex align-items-center input-field mt-3">
              <label htmlFor="phone">
                {language.lang["phone-number"]}{" "}
                <span className="required-field-star"> *</span>
              </label>
              <input
                value={state.formData.phone}
                onChange={handleInputChange}
                name="phone"
                className="neumorphic-input"
                placeholder={language.lang["phone-number"]}
                pattern="[0-9]{10}"
              />
            </div>
            <div className="d-flex align-items-center input-field mt-3">
              <label htmlFor="name">
                {language.lang["email-address"]}{" "}
                <span className="required-field-star"> *</span>
              </label>
              <input
                value={state.formData.email}
                onChange={handleInputChange}
                name="email"
                className="neumorphic-input"
                placeholder={language.lang["email-address"]}
              />
            </div>

            <GenderSelectField
              onChange={handleGenderChange}
              value={
                !!state.formData && state.formData?.gender_id !== null
                  ? state.formData.gender_id
                  : 1
              }
            />
            <div
              className="d-flex align-items-center input-field mt-3"
              style={{ gap: "10px" }}
            >
              <label htmlFor="age">
                {language.lang["date-of-birth"]}{" "}
                <span className="required-field-star"> *</span>
              </label>
              <input
                name="dob"
                type="date"
                id="dob"
                value={state.formData.dob}
                onChange={handleInputChange}
                className="neumorphic-input"
                min={moment().subtract(100, "years").format("YYYY-MM-DD")}
                max={moment().format("YYYY-MM-DD")}
              />
            </div>
            <div className="d-flex align-items-center input-field mt-3 ">
              <label style={{ width: "55%" }}>
                {language.lang.totalAmountToPay} :
              </label>
              <div className="d-flex justify-content-center align-items-center mb-1">
                {practitionerFees === 0 || practitionerFees === undefined
                  ? "Free Consultation "
                  : +practitionerFees + " " + language.lang[currencyCode] ||
                    currencyCode}
              </div>
            </div>
            <div
              className="visit-reason-field d-flex  input-field mt-3"
              style={{ flexDirection: "column" }}
            >
              <p className="label">
                {language.lang["reason-of-visit"]}{" "}
                <span className="required-field-star"> *</span>
              </p>
              <textarea
                value={state.formData.reason}
                onChange={handleInputChange}
                className="neumorphic-input text-area-radius"
                name="reason"
                style={{ width: "100%" }}
              />
            </div>
            <VoiceRecordField
              onChange={handleRecordChange}
              valueRef={recordFileRef}
            />
            <MedicalDocumentField
              appear={appear}
              setappear={setappear}
              setagree={setagree}
              onChange={handleMedicalRecordChange}
            />
            {appear && (
              <div className="gender-select-field mt-3 flex-column">
                <span style={{ color: "#1B68DF", fontWeight: "600" }}>
                  {language.lang["medical-record-concent"]}
                </span>
                <div className="check-box-container justify-content-evenly py-2 agreeNdisagree">
                  <MMRadioButton
                    label={language.lang.agree}
                    onClick={() => {
                      setagree("1");
                      dispatch(
                        inputChangeAction({
                          name: "isAllowUploadDocument",
                          value: true,
                        })
                      );
                    }}
                    classes="mr-2"
                    selected={agree === "1"}
                  />
                  <MMRadioButton
                    label={language.lang.disagree}
                    onClick={() => {
                      setagree("0");
                      dispatch(
                        inputChangeAction({
                          name: "isAllowUploadDocument",
                          value: false,
                        })
                      );
                    }}
                    selected={agree === "0"}
                  />
                </div>
              </div>
            )}
            {!props.type && (
              <div className="d-flex justify-content-end mt-4 w-100">
                <button
                  onClick={meetDoctorPressed}
                  className="btn btn-primary mt-4"
                  disabled={agree === "1" ? false : true}
                >
                  {language.lang.meet_doctor}
                </button>
              </div>
            )}
            {props.type && (
              <div
                className="d-flex justify-content-center w-100"
                style={{ gap: "10px" }}
              >
                <div className="d-flex justify-content-end mt-4 flex-grow-1">
                  <button
                    //onClick={meetDoctorPressed}
                    className="btn btn-secondary w-100 slot-info-btn"
                    onClick={() => {
                      history.push(dashboardBookConsultationRoute.path);
                    }}
                  >
                    {language.lang.cancel}
                  </button>
                </div>
                <div className="d-flex justify-content-end flex-grow-1 mt-4">
                  {props.Loading ? (
                    <Loading />
                  ) : (
                    <button
                      onClick={meetDoctorPressed}
                      className={
                        agree === "1"
                          ? `btn btn-primary slot-info-btn mt-4 w-100 text-nowrap w-100`
                          : `disagree-btn btn btn-primary slot-info-btn mt-4 w-100 text-nowrap w-100`
                      }
                      disabled={agree === "1" ? false : true}
                    >
                      {language.lang.confirmBooking}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default OnboardingFormForRole;
