import { useSelector } from "react-redux";
import RecipeTotalsPieChart from "../Recipe/CreateRecipe/RecipeTotalsPieChart";
import { servingUnit } from "../../../utility/constants";

const ViewRecipeTotals = ({ recipe, selectedUnit, selectedUnitQuantity }) => {
  const language = useSelector((state) => state.language.lang);

  let calories;
  let carbs;
  let fats;
  let proteins;
  if (selectedUnit === servingUnit) {
    const nutritionsForOneServe = recipe.recipeActualTotals;
    calories = nutritionsForOneServe.calories * selectedUnitQuantity;
    carbs = nutritionsForOneServe.carbs * selectedUnitQuantity;
    fats = nutritionsForOneServe.fats * selectedUnitQuantity;
    proteins = nutritionsForOneServe.proteins * selectedUnitQuantity;
  } else {
    let recipeTotalWeightInGramsForSelectedServing = 0;
    for (const ing of recipe.recipeIngredients) {
      recipeTotalWeightInGramsForSelectedServing += ing.plain_unit_quantity;
    }
    const totalRecipeCalories = recipe?.recipeCalculatedTotals?.calories ?? 0;
    const totalRecipeCarbs = recipe?.recipeCalculatedTotals?.carbs ?? 0;
    const totalRecipeFats = recipe?.recipeCalculatedTotals?.fats ?? 0;
    const totalRecipeProtein = recipe?.recipeCalculatedTotals?.proteins ?? 0;
    calories = selectedUnitQuantity * (totalRecipeCalories / recipeTotalWeightInGramsForSelectedServing);
    carbs = selectedUnitQuantity * (totalRecipeCarbs / recipeTotalWeightInGramsForSelectedServing);
    fats = selectedUnitQuantity * (totalRecipeFats / recipeTotalWeightInGramsForSelectedServing);
    proteins = selectedUnitQuantity * (totalRecipeProtein / recipeTotalWeightInGramsForSelectedServing);
  }

  const nutritionsSum = carbs + fats + proteins;
  const total = nutritionsSum === 0 ? 1 : nutritionsSum;
  const carbsPercentage = (carbs / total) * 100;
  const fatsPercentage = (fats / total) * 100;
  const proteinsPercentage = (proteins / total) * 100;

  return (
    <div className="recipe-totals">
      <div className="d-flex flex-column totals-numbers">
        <div className="title mb-2">{language["recipe-totals"] ?? "recipe-totals"}</div>
        <div className="nutrition-number">
          <div className="calories">{language["calories"] ?? "calories"}</div>
          <div>
            {calories?.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })}
            {` ${language["kcal"] ?? "kcal"}`}
          </div>
          <div></div>
        </div>
        <div className="nutrition-number">
          <div className="carbs">{language["carbs"] ?? "carbs"}</div>
          <div className="value">
            {carbs.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })} {language["g"] ?? "g"}
          </div>
          <div className="percentage">{carbsPercentage.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })} %</div>
        </div>
        <div className="nutrition-number">
          <div className="fats">{language["fats"] ?? "fats"}</div>
          <div className="value">
            {fats.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })} {language["g"] ?? "g"}
          </div>
          <div className="percentage">{fatsPercentage.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })} %</div>
        </div>
        <div className="nutrition-number">
          <div className="protein">{language["protein"] ?? "protein"}</div>
          <div className="value">
            {proteins.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })} {language["g"] ?? "g"}
          </div>
          <div className="percentage">
            {proteinsPercentage.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })} %
          </div>
        </div>
      </div>
      <div className="d-flex recipe-totals-pie-chart">
        <RecipeTotalsPieChart
          showPercentageOnChart={false}
          carbs={recipe.recipeActualTotals.carbs}
          fats={recipe.recipeActualTotals.fats}
          proteins={recipe.recipeActualTotals.proteins}
        />
      </div>
    </div>
  );
};

export default ViewRecipeTotals;
