import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { Rate } from "antd";
// Critical River, Varun Mishra, this package is not compatible with react version 18.
// import ImagePicker from "react-image-picker";
// import "react-image-picker/dist/index.css";
import { Select } from "antd";
import axios from "../services/Axios";
import {
  showErrorNotification,
  showSuccessNotification,
  arr,
} from "../utility/Helpers";
import { settings } from "../config";
import { BsPlus } from "react-icons/bs";
import Switch from "react-switch";
import { GrCheckboxSelected } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { setGalleryMedia } from "../redux/superAdmin/actionCreators";
import videoImg from "../assets/images/fit.png";
import Loading from "./Loading";
import SingleFeatureCard from "./SingleFeatureCard";
// Critical River, Varun Mishra, earlier package wass not compatible with react version 18.
import LazyLoad from "react-lazyload-v18";
const { Option } = Select;

const SingleFeatureModal = (props) => {
  const GalleryMedia = useSelector((state) => state.superAdmin);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const [image, setImage] = useState(null);
  const [dropData, SetdropData] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [shown, Setshown] = useState(false);
  const [preview, setpreview] = useState(false);
  const [updata, Setupdata] = useState(0);
  const [srchImg, SetsrchImg] = useState("");
  const [srchVid, SetsrchVid] = useState("");
  const [imageList, SetimageList] = useState([]);
  const [videosList, SetvideosList] = useState([]);
  const [documents, SetdocumentsList] = useState([]);
  const [animations, SetanimationsList] = useState([]);
  const [backUpImages, setbackUpImages] = useState([]);
  const [backUpVideos, setbackUpVideos] = useState([]);
  const [Inputnew, SetInputnew] = useState({
    featureMasterId: 0,
    featureSectionName: "",
  });
  const [Input, setInput] = useState({
    featureMasterId: "",
    featureSection: "",
    descriptionMainHeader: "",
    descriptionSubHeader: "",
    descriptionContent: "",
    isMostPopular: false,
    isPaidContent: false,
    isCorporateSpecific: false,
    content: [],
    thumbnail: [],
    rating: "1",
  });

  const setMedia = () => {
    SetimageList([...GalleryMedia.images]);
    SetvideosList([...GalleryMedia.videos]);
  };

  const separatenewMedia = (data) => {
    data.map((obj) => {
      if (obj.galleryItemInfo.type === "image/png")
        SetimageList((prevArray) => [
          ...prevArray,
          { ...obj.galleryItemInfo, selected: true },
        ]);
      if (obj.galleryItemInfo.type == "video/mp4")
        SetvideosList((prevArray) => [
          ...prevArray,
          { ...obj.galleryItemInfo, selected: true },
        ]);
    });
  };
  const giveData = async (id) => {
    console.log(id);
    if (!id) return;
    await axios
      .get(`/getFeatureSection?featureMasterId=${id}`)
      .then((response) => {
        SetdropData(response.data.response[0].featureSection);
      })
      .catch((error) => {
        console.log(error.response.status);
      });
  };
  const postData = async () => {
    console.log(Inputnew);
    if (Inputnew.featureMasterId != -1) {
      await axios({
        method: "post",
        url: "/createFeatureSection",
        data: Inputnew,
        config: { headers: { "Content-Type": "application/json" } },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          Setshown(!shown);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
    giveData(updata);
  };
  const onChangenewHandler = (e) => {
    if (typeof e === "number") {
      Setupdata(e);
      giveData(e);
      SetdropData([]);
      setInput((prevState) => {
        return {
          ...prevState,
          featureMasterId: e,
        };
      });
      SetInputnew((prevState) => {
        return {
          ...prevState,
          featureMasterId: e,
        };
      });
    } else {
      let val = e.target.value;
      setInput((prevState) => {
        return {
          ...prevState,
          featureSection: val,
        };
      });
      if (e.target.name == "featureSectionName") {
        SetInputnew((prevState) => {
          return {
            ...prevState,
            featureSectionName: val,
          };
        });
      }
    }
  };
  const sortData = () => {
    setInput((prevState) => {
      return {
        ...prevState,
        featureMasterId:
          props && props.data[0] && props.data[0].feature_master_id,
        featureSection:
          props &&
          props.data[0] &&
          props.data[0].featureSection &&
          props.data[0].featureSection.name,
        descriptionMainHeader:
          props && props.data[0] && props.data[0].description_main_header,
        descriptionSubHeader:
          props && props.data[0] && props.data[0].description_sub_header,
        descriptionContent:
          props && props.data[0] && props.data[0].description_content,
        isMostPopular: props && props.data[0] && props.data[0].is_most_popular,
        thumbnail: props && props.data[0] && props.data[0].thumbnail,
        rating:
          props &&
          props.data[0] &&
          props.data[0].rating &&
          props.data[0].rating.aggregate &&
          props.data[0].rating.aggregate.avg &&
          props.data[0].rating.aggregate.avg.rating,
      };
    });
  };
  useEffect(() => {
    props.data.length === 0 &&
      GalleryMedia.gallery.length === 0 &&
      dispatch(setGalleryMedia());
    props.data.length === 0 && GalleryMedia.gallery.length > 0 && setMedia();
    props.data && props.data.length > 0 && sortData();
    props.data &&
      props.data.length > 0 &&
      props.data[0] &&
      props.data[0].featureContent &&
      separatenewMedia(props.data[0].featureContent);
  }, [props, GalleryMedia]);

  const onPick = (media) => {};

  const addImage = (i, select) => {
    const newImage = [...imageList];
    newImage[i] = { ...newImage[i], selected: !select };
    SetimageList(newImage);
    if (select) {
      removeMedia(newImage[i].id, i);
      return;
    }
    if (!select) {
      setInput((prevState) => {
        return {
          ...prevState,
          content: [...prevState.content, newImage[i].id],
          thumbnail: [
            prevState.content.length > 0
              ? prevState.content[0]
              : newImage[i].id,
          ],
        };
      });
    }
  };

  const addVideo = (i, select) => {
    const newVideo = [...videosList];
    newVideo[i] = { ...newVideo[i], selected: !select };
    SetvideosList(newVideo);
    if (select) {
      removeMedia(newVideo[i].id, i);
      return;
    }
    if (!select) {
      setInput((prevState) => {
        return {
          ...prevState,
          content: [...prevState.content, newVideo[i].id],
        };
      });
    }
  };
  const removeMedia = (id) => {
    const changeInput = Input.content.filter((val) => val !== id);
    setInput((prevState) => {
      return {
        ...prevState,
        content: changeInput,
      };
    });
  };
  const onSelectHandler = (e) => {
    // console.log(e);
    setInput((prevState) => {
      return {
        ...prevState,
        featureSection: e,
      };
    });
  };

  const onSelectHandlerCorporate = (e) => {
    //  console.log(e);
    setInput((prevState) => {
      return {
        ...prevState,
        corporate: e,
      };
    });
  };

  const onChangeHandler = (e) => {
    if (typeof e === "number") {
      console.log("rating->", e);
      setInput((prevState) => {
        return {
          ...prevState,
          rating: e,
        };
      });
    } else {
      // console.log(e);
      let nam = e.target.name;
      let val = e.target.value;
      setInput((prevState) => {
        return {
          ...prevState,
          [nam]: val,
        };
      });
    }
  };

  const submitHandler = async () => {
    setLoading(true);
    console.log(Input);
    if (
      !(
        Input["content"].length > 0 &&
        Input["featureMasterId"] &&
        Input["featureSection"] &&
        Input["descriptionMainHeader"] &&
        Input["descriptionContent"] &&
        Input["rating"]
      )
    ) {
      showErrorNotification("Please fill the form.");
    } else {
      try {
        const response = await axios.post(
          `${settings.apiBaseURL}/createSingleFeature`,
          Input
        );
        console.log("The response is", response);
        showSuccessNotification("Submitted");
        props.hide(false);
      } catch (e) {
        showErrorNotification(e.response.data.error);
      }
    }
    setLoading(false);
  };

  const searchContent = async (type) => {
    setbackUpImages(imageList);
    setbackUpVideos(videosList);
    type === "image" && SetimageList(false);
    type === "video" && SetvideosList(false);
    var key = type === "image" ? srchImg : srchVid;
    await axios
      .get(`${settings.apiBaseURL}/searchGalleryMedia?tagName=${key}`)
      .then((response) => {
        const data = response.data.response;
        console.log(data, "data");
        data.map((obj) => {
          if (obj.galleryMediaInfo.type === "image/png")
            SetimageList((prevArray) => [
              ...prevArray,
              { ...obj.galleryMediaInfo, selected: false },
            ]);
          if (obj.galleryMediaInfo.type == "video/mp4")
            SetvideosList((prevArray) => [
              ...prevArray,
              { ...obj.galleryMediaInfo, selected: false },
            ]);
        });
      });
  };
  useEffect(() => {
    backUpImages.length > 0 && !srchImg && SetimageList(backUpImages);
  }, [srchImg]);
  useEffect(() => {
    backUpVideos.length > 0 && !srchVid && SetvideosList(backUpVideos);
  }, [srchVid]);
  return (
    <div className="single-feature-modal">
      <Modal.Header closeButton>
        <h3 className="single-feature-heading">
          Map the Content for Single Feature
        </h3>
      </Modal.Header>
      <Row>
        <Col xs={12} lg={6}>
          <div>
            <Form.Label>
              Feature Category <sup>*</sup>{" "}
            </Form.Label>
            <Select
              value={Input.featureMasterId}
              onChange={onChangenewHandler}
              name="featureMasterId"
              style={{ width: "100%" }}
            >
              {arr.map((el) => {
                return <Option value={el.id}>{el.feature_description}</Option>;
              })}
            </Select>
          </div>

          <div style={{ marginBottom: "15px", marginTop: "20px" }}>
            <div className="d-flex justify-content-between align-items-center">
              <Form.Label>
                Feature Sub Category <sup>*</sup>{" "}
              </Form.Label>
              <div
                className="add_new_link"
                onClick={() => {
                  Setshown(!shown);
                }}
              >
                {language.lang["add-new"]} <BsPlus />
              </div>
            </div>

            {shown ? (
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    className="shadow-none"
                    name="featureSectionName"
                    onChange={onChangenewHandler}
                  />
                </Form.Group>{" "}
                <div className="relbtns" onClick={postData}>
                  <div className="save">{language.lang.save}</div>
                  <div className="discard" onClick={() => Setshown(!shown)}>
                    Discard
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {!shown ? (
              <Select
                value={Input.featureSection}
                onChange={onSelectHandler}
                name="featureSection"
                style={{ width: "100%" }}
              >
                {dropData.map((el) => {
                  return <Option value={el.id}>{el.name}</Option>;
                })}
              </Select>
            ) : (
              ""
            )}
          </div>

          <div>
            <Form.Label>
              Section Content Header <sup>*</sup>{" "}
            </Form.Label>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                className="shadow-none"
                name="descriptionMainHeader"
                value={Input.descriptionMainHeader}
                onChange={onChangeHandler}
              />
            </Form.Group>
          </div>

          {/*<div className="d-flex">
                                <Form.Label className="mr-2">Is Most Popular ?</Form.Label>
                                <Switch
                                    checked={isMostPopular}
                                    onChange={(val)=>{
                                        setIsMostPopular(val);
                                    }}
                                    onColor="#bff5de"
                                    onHandleColor="#56c596"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    id="material-switch"
                                />
                                </div>*/}
          <div>
            <Form.Label>Section Content Sub Header </Form.Label>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                className="shadow-none"
                name="descriptionSubHeader"
                value={Input.descriptionSubHeader}
                onChange={onChangeHandler}
              />
            </Form.Group>
          </div>

          <div>
            <Form.Label>
              Content <sup>*</sup>{" "}
            </Form.Label>
            <textarea
              className="single-feature-input content-textarea"
              name="descriptionContent"
              value={Input.descriptionContent}
              onChange={onChangeHandler}
            ></textarea>
          </div>

          <div
            className="d-flex justify-content-start flex-column align-items-center"
            style={{ margin: "17px 0" }}
          >
            <Form.Label className="w-100">
              Is {language.lang["most-popular"]}
              <sup>*</sup>
            </Form.Label>
            <div
              className="d-flex w-100 justify-content-start"
              style={{ gap: "20px" }}
            >
              <div
                className="d-flex justify-content-center"
                style={{ gap: "10px" }}
              >
                <Form.Label>{language.lang.yes}</Form.Label>
                <Switch
                  checked={Input.isMostPopular}
                  onChange={(val) => {
                    setInput((prevState) => {
                      return {
                        ...prevState,
                        isMostPopular: val,
                      };
                    });
                  }}
                  onColor="#bff5de"
                  onHandleColor="#56c596"
                  handleDiameter={15}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="material-switch"
                />
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ gap: "10px" }}
              >
                <Form.Label>{language.lang.no}</Form.Label>
                <Switch
                  checked={!Input.isMostPopular}
                  onChange={(val) => {
                    setInput((prevState) => {
                      return {
                        ...prevState,
                        isMostPopular: !val,
                      };
                    });
                  }}
                  onColor="#bff5de"
                  onHandleColor="#56c596"
                  handleDiameter={15}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="material-switch"
                />
              </div>
            </div>
          </div>

          <div
            className="d-flex justify-content-start flex-column align-items-center"
            style={{ margin: "17px 0" }}
          >
            <Form.Label className="w-100">
              Is Paid Content<sup>*</sup>
            </Form.Label>
            <div
              className="d-flex w-100 justify-content-start"
              style={{ gap: "20px" }}
            >
              <div
                className="d-flex justify-content-center"
                style={{ gap: "10px" }}
              >
                <Form.Label>{language.lang.yes}</Form.Label>
                <Switch
                  checked={Input.isPaidContent}
                  onChange={(val) => {
                    setInput((prevState) => {
                      return {
                        ...prevState,
                        isPaidContent: val,
                      };
                    });
                  }}
                  onColor="#bff5de"
                  onHandleColor="#56c596"
                  handleDiameter={15}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="material-switch"
                />
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ gap: "10px" }}
              >
                <Form.Label>{language.lang.no}</Form.Label>
                <Switch
                  checked={!Input.isPaidContent}
                  onChange={(val) => {
                    setInput((prevState) => {
                      return {
                        ...prevState,
                        isPaidContent: !val,
                      };
                    });
                  }}
                  onColor="#bff5de"
                  onHandleColor="#56c596"
                  handleDiameter={15}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="material-switch"
                />
              </div>
            </div>
          </div>

          <div
            className="d-flex justify-content-start align-items-center flex-column"
            style={{ margin: "17px 0" }}
          >
            <Form.Label className="w-100">
              Is Corporate Specific<sup>*</sup>
            </Form.Label>
            <div
              className="d-flex w-100 justify-content-start"
              style={{ gap: "20px" }}
            >
              <div
                className="d-flex justify-content-center"
                style={{ gap: "10px" }}
              >
                <Form.Label>{language.lang.yes}</Form.Label>
                <Switch
                  checked={Input.isCorporateSpecific}
                  onChange={(val) => {
                    setInput((prevState) => {
                      return {
                        ...prevState,
                        isCorporateSpecific: val,
                      };
                    });
                  }}
                  onColor="#bff5de"
                  onHandleColor="#56c596"
                  handleDiameter={15}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="material-switch"
                />
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ gap: "10px" }}
              >
                <Form.Label>{language.lang.no}</Form.Label>
                <Switch
                  checked={!Input.isCorporateSpecific}
                  onChange={(val) => {
                    setInput((prevState) => {
                      return {
                        ...prevState,
                        isCorporateSpecific: !val,
                      };
                    });
                  }}
                  onColor="#bff5de"
                  onHandleColor="#56c596"
                  handleDiameter={15}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="material-switch"
                />
              </div>
            </div>
          </div>
          {Input.isCorporateSpecific && (
            <>
              <Form.Label className="mb-1">Corporate </Form.Label>
              <Select
                name="corporate"
                onChange={onSelectHandlerCorporate}
                style={{ width: "100%" }}
              >
                <Option value="1">Adnic</Option>
                <Option value="2">Masdar</Option>
                <Option value="3">Microsoft</Option>
              </Select>
            </>
          )}
        </Col>

        <Col xs={12} lg={6} className="feature-col-2">
          <div className="d-flex justify-content-center select-img py-1 align-items-center py-3">
            <Form.Label>Select Display Images</Form.Label>
            <div className="d-flex justify-content-center align-items-center">
              <Form.Group controlId="formBasicEmail" className="m-0">
                <Form.Control
                  type="text"
                  className="shadow-none "
                  name="featureCategory"
                  onChange={(e) => SetsrchImg(e.target.value)}
                />
              </Form.Group>
              <div className="search" onClick={() => searchContent("image")}>
                {language.lang.search}
              </div>
            </div>
          </div>

          {/*<div className="image-thumbnails-block">
                                <Form.Label>Select Thumbnails </Form.Label>
                                </div>*/}
          <div className="image_picker mb-2">
            <div className="videolist">
              {imageList.length > 0 ? (
                imageList.map((img, i) => {
                  return (
                    <div
                      style={{ position: "relative", height: "max-content" }}
                      onClick={() => addImage(i, img.selected)}
                    >
                      <LazyLoad
                        height={100}
                        offset={50}
                        throttle={200}
                        height={300}
                        once={true}
                      >
                        {" "}
                        <img
                          key={i}
                          className={`pervideo thumbnail ${
                            img.selected ? "selected" : ""
                          } `}
                          src={img.url}
                        />
                      </LazyLoad>
                      {img.selected && (
                        <GrCheckboxSelected className="selected-video" />
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="videolist d-flex justify-content-center align-items-center none-msg mb-2 pt-3">
                  No Image Available
                </div>
              )}
            </div>
          </div>

          <div
            className="d-flex justify-content-center select-img py-3 align-items-center"
            style={{ marginTop: "10px" }}
          >
            <Form.Label>Select Videos</Form.Label>
            <div className="d-flex justify-content-center align-items-center">
              <Form.Group controlId="formBasicEmail" className="m-0">
                <Form.Control
                  type="text"
                  className="shadow-none "
                  name="featureCategory"
                  onChange={(e) => SetsrchVid(e.target.value)}
                />
              </Form.Group>
              <div className="search" onClick={() => searchContent("video")}>
                {language.lang.search}
              </div>
            </div>
          </div>

          <div className="image_picker mb-2">
            <div className="videolist">
              {videosList.length > 0 ? (
                videosList.map((vid, i) => {
                  return (
                    <div
                      style={{ position: "relative", height: "max-content" }}
                      onClick={() => addVideo(i, vid.selected)}
                    >
                      <video
                        key={i}
                        className={`pervideo thumbnail ${
                          vid.selected ? "selected" : ""
                        } `}
                        autoPlay="true"
                      >
                        <source src={vid.url} type="video/mp4"></source>
                      </video>
                      {vid.selected && (
                        <GrCheckboxSelected className="selected-video" />
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="videolist d-flex justify-content-center align-items-center none-msg mb-2">
                  No Videos Available
                </div>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-center select-img align-items-center py-3">
            <Form.Label>Select Documents</Form.Label>
            <div className="d-flex justify-content-center align-items-center">
              <Form.Group controlId="formBasicEmail" className="m-0">
                <Form.Control
                  type="text"
                  className="shadow-none "
                  name="featureCategory"
                  onChange={(e) => SetsrchImg(e.target.value)}
                />
              </Form.Group>
              <div className="search" onClick={() => {}}>
                Search
              </div>
            </div>
          </div>
   {/* Critical River, Varun Mishra, commented the code, because this package is not compatible with react version 18. */}
          {/* {documents.length > 0 ? (
            <ImagePicker
              images={documents.map((image, i) => ({
                src: image.url,
                value: i,
              }))}
              onPick={onPick}
              multiple
              style={{ marginLeft: "-10px" }}
            />
          ) : (
            <div className="videolist d-flex justify-content-center align-items-center none-msg mt-1 smallvideolist ">
              No Document Available
            </div>
          )} */}
          <div className="d-flex justify-content-center select-img align-items-center py-3">
            <Form.Label>Select Animations </Form.Label>
            <div className="d-flex justify-content-center align-items-center">
              <Form.Group controlId="formBasicEmail" className="m-0">
                <Form.Control
                  type="text"
                  className="shadow-none "
                  name="featureCategory"
                  onChange={(e) => SetsrchImg(e.target.value)}
                />
              </Form.Group>
              <div className="search" onClick={() => {}}>
                Search
              </div>
            </div>
          </div>

          {/* {animations.length > 0 ? (
            <ImagePicker
              images={animations.map((image, i) => ({
                src: image.url,
                value: i,
              }))}
              onPick={onPick}
              multiple
            />
          ) : (
            <div className="videolist d-flex justify-content-center align-items-center none-msg">
              {language.lang.no} Animation Available
            </div>
          )} */}
        </Col>

        <div className="my-3 text-right d-flex justify-content-between">
          <Modal
            className="modal_bkg"
            show={preview}
            onHide={setpreview}
            size="lg"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="d-flex justify-content-center align-items-center flex-column">
                <h3
                  className="d-flex justify-content-center align-items-center py-2"
                  style={{ fontWeight: "bold" }}
                >
                  Preview
                </h3>
                <SingleFeatureCard
                  url={
                    imageList &&
                    imageList.length > 0 &&
                    imageList.filter((a) => a.selected).length > 0 &&
                    imageList.filter((a) => a.selected)[0]?.url
                  }
                  Heading={
                    dropData &&
                    dropData[0] &&
                    dropData.length > 0 &&
                    Input.featureSection &&
                    dropData.filter((a) => a.id === Input.featureSection)[0]
                      ?.name
                  }
                  subHeading={Input.descriptionMainHeader}
                  subContent={Input.descriptionSubHeader}
                />
              </div>
            </Modal.Body>
          </Modal>
          <button
            className="shadow-none btn btn-primary create-btn"
            onClick={() => setpreview(true)}
          >
            Preview
          </button>
          <div className="d-flex align-items-center">
            <Form.Label>
              Rating <sup>*</sup>{" "}
            </Form.Label>
            <Rate
              name="rating"
              value={Input.rating}
              onChange={onChangeHandler}
            />
          </div>
          <div
            className="d-flex justify-content-between "
            style={{ gap: "50px" }}
          >
            {loading ? (
              <Loading width={50} height={50} />
            ) : (
              <button
                className="shadow-none btn btn-primary create-btn"
                onClick={submitHandler}
              >
                Create
              </button>
            )}
          </div>
        </div>
      </Row>
    </div>
  );
};

export default SingleFeatureModal;
