import { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { dashboardAppointmentsRoute } from "../routes";
import { useHistory } from "react-router-dom";

const ModalNotificationItem = ({ text, type }) => {
  const history = useHistory();
  const language = useSelector((state) => state.language.lang);
  const [hide, sethide] = useState(true);
  // const CloseModal = () => {
  //     sethide(!hide)
  // }

  const modalOkPressed = () => {
    history.push(dashboardAppointmentsRoute.path, {
      pending: "Pending",
    });
    sethide(!hide);
  };

  return (
    <Modal
      size="sm"
      backdrop="static"
      show={hide}
      // onHide={CloseModal}
      className="modal_custom"
    >
      {/* {(text === "Your appointment request has been processed successfully" ||
        type === "AppointmentUpdatedByClinic") && (
        <Modal.Header closeButton></Modal.Header>
      )} */}
      <Modal.Body>
        <div
          className="d-flex flex-column align-items-center"
          style={{ maxWidth: 500 }}
        >
          {text}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={modalOkPressed} className="btn btn-success">
          {language.ok}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalNotificationItem;
