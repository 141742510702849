import React from 'react'

const InputFieldFormat = ({element, preIcon, icon, show,postIcon}) => {
    return (
        <div className="position-relative d-flex  align-items-center res-book-field">
            <span className="position-relative" style={{zIndex:2,left:'36px',color:'#1B68DF',fontSize:20}}>{preIcon}</span>
            {element}
            <span className="position-relative" style={{zIndex:2,right:'36px',color:'#1B68DF',fontSize:20}}>{postIcon}</span>
            {show && icon}
        </div>
    )
}

export default InputFieldFormat
