import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "antd/dist/antd.css";
import { IconContext } from "react-icons";
import sign from "../assets/images/sign.png";
import { VscFilePdf } from "react-icons/vsc";
import jsPDF from "jspdf";
import { v4 as uuidv4 } from "uuid";
import Loading from "./Loading";
import { settings } from "../config";
import axios from "axios";
import { showErrorNotification } from "../utility/Helpers";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import { Document, Page } from "react-pdf";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";

const PrescriptionSection1 = (props) => {
  const [loading, setloading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const language = useSelector((state) => state.language);
  const medicineList = props.medicineList;

  const downloadPres = () => {
    console.log(props);
    if (props.prescriptionUrl !== "" && props.prescriptionUrl !== null) {
      fetch(props.prescriptionUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Prescription.pdf";
          link.click();
        })
        .catch(console.error);
    } else {
      const printArea = document.getElementById("doc-pres");
      html2canvas(printArea).then((canvas) => {
        const dataURL = canvas.toDataURL();
        const pdf = new jsPDF();
        pdf.addImage(dataURL, "JPEG", 20, 20, 180, 160);
        pdf.save("Prescription.pdf");
      });
    }
  };

  const sendEmail = async () => {
    setloading(true);
    let formdata = new FormData();
    if (props.prescriptionUrl !== "" && props.prescriptionUrl !== null) {
      fetch(props.prescriptionUrl)
        .then((response) => response.blob())
        .then((blob) => {
          let newBlob = new Blob([blob], { type: "application/pdf" });
          formdata.append("prescriptionFile", newBlob);
          formdata.append("to", props.email);
          let res = axios
            .post(`${settings.apiBaseURL}/sendEmailPrescription`, formdata)
            .finally(() => {
              showErrorNotification(
                language.lang["prescription-sent-to-email"]
              );
              setloading(false);
            });
        })
        .catch(console.error);
    } else {
      const printArea = document.getElementById("doc-pres");
      html2canvas(printArea).then((canvas) => {
        const dataURL = canvas.toDataURL();
        const pdf = new jsPDF();
        pdf.addImage(dataURL, "JPEG", 20, 20, 180, 160);
        const blob = new File([pdf.output("blob")], "myDoc.pdf", {
          type: "application/pdf",
        });
        formdata.append("prescriptionFile", blob);
        formdata.append("to", props.email);
        axios
          .post(`${settings.apiBaseURL}/sendEmailPrescription`, formdata)
          .finally(() => {
            showErrorNotification(language.lang["prescription-sent-to-email"]);
            setloading(false);
          });
      });
    }
  };

  // const uploadThisFile = async (file) => {
  //   const data = {
  //     storageType: storageTypeDocument,
  //     category: categoryMentalHealth,
  //     fileType: fileTypePrescription,
  //     userType: userTypeUser,
  //     userId: userData.id,
  //   };
  //   data["multi-files"] = file;
  //   return await uploadFile(data);
  // };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const changePage = (offset) => {
    setPageNumber(pageNumber + Number(offset));
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <Col
      xs={12}
      lg={props.width}
      className="consultation-section-1 px-0 px-lg-3"
      style={{ position: "relative" }}
    >
      <div className="d-flex justify-content-between w-100 mt-2">
        <h4 className="doctor-prescription--heading">{props.headingDoc}</h4>
        <IconContext.Provider value={{ color: "#ee2c34" }}>
          <VscFilePdf size={52} />
        </IconContext.Provider>
      </div>
      {"prescriptionUrl" in props &&
      props.prescriptionUrl !== null &&
      props.prescriptionUrl !== "" ? (
        <div>
          <Document
            file={{ url: props.prescriptionUrl }}
            options={{ workerSrc: "/pdf.worker.js" }}
            onLoadSuccess={onDocumentLoadSuccess}
            loading="Loading Prescription"
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <div className="page-group">
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              <AiOutlineArrowLeft />
            </button>
            <p>
              {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </p>

            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              <AiOutlineArrowRight />
            </button>
          </div>
          <div
            className="d-flex justify-content-between mt-3 px-0"
            style={{ gap: "20px" }}
          >
            {!loading ? (
              <button
                className="shadow-none btn btn-primary custom-btn-2"
                onClick={sendEmail}
              >
                {language.lang["send-to-email"]}
              </button>
            ) : (
              <Loading width={"50px"} height={"50px"} />
            )}
            <button
              className="shadow-none btn btn-primary custom-btn-2"
              onClick={downloadPres}
            >
              {language.lang["download-prescription"]}
            </button>
          </div>
        </div>
      ) : (
        <Row className="prescription-details mx-0">
          <section id="doc-pres" className="bg-peach">
            <Col xs={12} className="pres-slip">
              <h4 className="pres-slip--heading bg-green">
                {props.centerName}
              </h4>
              <p className="pres-slip-address bg-green">
                {props.centerAddress}
              </p>
            </Col>
            <Col xs={12} className="pt-3">
              <div className="pres-detail--row row mx-0">
                <div className="pres-key col-12 col-md-5 px-0">
                  {language.lang["patient-name"]}:
                </div>
                <div className="pres-value bg-green col-12 col-md-7">
                  {props.patientName}
                </div>
              </div>
              <div className="pres-detail--row row mt-2 mx-0">
                <div className="pres-key col-12 col-md-5 px-0">
                  {language.lang["patient-age"]}:
                </div>
                <div className="pres-value bg-green col-12 col-md-7">
                  {props.age}
                </div>
              </div>
              <div className="pres-detail--row row mt-2 mx-0">
                <div className="pres-key col-12 col-md-5 px-0">
                  {language.lang.date}:
                </div>
                <div className="pres-value bg-green col-12 col-md-7">
                  {props.date}
                </div>
              </div>
            </Col>
            <Col xs={12}>
              <Row className="my-2 mx-0">
                <div className="pres-detail--row px-0">
                  <div className="pres-code pres-key">&#8478;</div>
                  <div className="pres-medicines">
                    {medicineList &&
                      medicineList.map((element, index) => {
                        return (
                          <div
                            key={uuidv4()}
                            className="pres-value bg-green"
                            style={{
                              fontSize: "20px",
                              marginTop: index > 0 ? "5px" : "0px",
                            }}
                          >
                            {element.medicine} - {element.dosage}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Row>
            </Col>
            <Col xs={12}>
              <Row className="my-2 mx-0">
                <div className="pres-detail--row justify-content-between px-0">
                  <div>
                    <div
                      className="mb-3"
                      style={{ fontSize: "16.5px", fontWeight: "600" }}
                    >
                      {language.lang["signed-by"]}:
                    </div>
                    <div
                      className="pres-value bg-green"
                      style={{ fontSize: "16px" }}
                    >
                      {props.doctorName}
                    </div>
                  </div>
                  <div className="pres-value">
                    <img
                      src={sign}
                      className="doc-sign"
                      alt="doctor-signature"
                    />
                  </div>
                </div>
              </Row>
            </Col>
          </section>
          {"createBtn" in props ? (
            <div className="row mx-0 px-0 mt-3">
              {!props.loadingState && (
                <>
                  <div className="col-6 px-1">
                    <button
                      className="shadow-none btn btn-primary custom-btn-3"
                      onClick={props.handleCancel}
                    >
                      {language.lang.cancel}
                    </button>
                  </div>
                  <div className="col-6 px-1">
                    <button
                      className="shadow-none btn btn-primary custom-btn-3"
                      onClick={props.createPrescriptionHandler}
                    >
                      {language.lang.confirm_prescription}
                    </button>
                  </div>
                </>
              )}
              {props.loadingState && <Loading />}
            </div>
          ) : (
            <div
              className="d-flex justify-content-between mt-0 mt-md-3 px-0"
              style={{ gap: "20px" }}
            >
              {!loading ? (
                <button
                  className="shadow-none btn btn-primary custom-btn-2"
                  onClick={sendEmail}
                >
                  {language.lang["send-to-email"]}
                </button>
              ) : (
                <Loading width={"50px"} height={"50px"} />
              )}
              <button
                className="shadow-none btn btn-primary custom-btn-2"
                onClick={downloadPres}
              >
                {language.lang["download-prescription"]}
              </button>
            </div>
          )}
        </Row>
      )}
    </Col>
  );
};

export default PrescriptionSection1;
