import { useSelector } from "react-redux"
import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom"
import axios from "../../../services/Axios";
import { settings } from "../../../config";
import Recipeswithmealselection from "./Recipeswithmealselection";
import { subscriptionPlanDetailsRoute } from "../../../routes";
import { mealPlanOnboardingRoute } from "../../../routes";
import { getPaymentDetails, getmealplanforweek } from "../../../utility/Helpers";
import { tokenStorageKey } from "../../../utility/constants";
import { getUserValidMealPlans, getactiveMealPlansbyuserId } from "../../../services/MealPlanNetworkService";
import DatePickerMeal from "./DatePickerMeal";
import Loading from "../../Loading";


const BMRcalories = ({ selectedItemId, selectedItemName }) => {
    const history = useHistory()
    const language = useSelector((state) => state.language.lang);
    const UserId = useSelector((state) => state.auth.userData.id)
    const [showModal, setShowModal] = useState(false);
    const [goal, setGoal] = useState('Looseweight');
    const [units, setunits] = useState("Metric")
    const [gender, setGender] = useState('Male');
    const [exactgoal, setExactGoal] = useState("nothanks")
    const [age, setAge] = useState(1);
    const [fetheight, setfetHeight] = useState(1);
    const [inchheight, setinchHeight] = useState(1);
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState(1)
    const [exactgoalweight, setExactgoalweight] = useState(0)
    const [fat, setfat] = useState('low');
    const [activityLevel, setActivityLevel] = useState('Sedentary');
    const [expectedCalories, setexpectedCalories] = useState(false);
    const [resultedBMR, setresultedBMR] = useState([])
    const [data, setdata] = useState(null)
    const [showGoalSections, setShowGoalSections] = useState(false);
    const [exactgoalperweek, setExactgoalperweek] = useState("")
    const [goalDate, setGoalDate] = useState()
    const [tab, setTab] = useState('day')


    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split('T')[0]
    //handling the date for usernon expiredmeals
    const [startDate, setStartDate] = useState(formattedCurrentDate)
    const [endDate, setEndDate] = useState(formattedCurrentDate)
    const [subscriptionDate, setsubscriptionendDate] = useState()
    //Handling the generatecalories states

    const [calories, setCalories] = useState("")
    const [meallevel, setmeallevel] = useState("4")
    
    //handling the error states
    const [weightError, setWeightError] = useState('');
    const [ageError, setAgeError] = useState('');
    const [heightError, setHeightError] = useState('');
    //handling the goal

    const [loading, setLoading] = useState(false);
    const [mealplanId,setMealplanId]=useState("");
    const [reloadData, setReloadData] = useState(false);


    const handlegoalClick = (goalclicked) => {
        setGoal(goalclicked);
    };
    // handling the units
    const unitsButtonClick = (units) => {
        setunits(units)
    }

    // handling the gender
    const handleGenderClick = (selectedGender) => {
        setGender(selectedGender);
    };

    // handling the fat
    const handlefatClick = (fatclicked) => {
        setfat(fatclicked);
    };

    //handling the Activity level
    const handleActivityLevelClick = (e) => {

        setActivityLevel(e.target.value);
    };
    //Handle the api data
    const handlemetricinput = (e) => {
        setinchHeight(e.target.value);
        setfetHeight(0);
    }
    const goalButtonClick = (choice) => {
        if (choice === 'nothanks') {
            setShowGoalSections(false);
            setExactGoal(choice)
        } else if (choice === 'letsdoit') {
            setShowGoalSections(true);
            setExactGoal(choice)
        }
    }

    const handleExactGoalPerWeek = (e) => {
        const inputVal = e.target.value;
        setExactgoalperweek(inputVal);
        // Check if exactgoalperweek is not zero before calculation
        if (parseFloat(inputVal) !== 0) {
            const weightChangeRatio = (weight - exactgoalweight) / parseFloat(inputVal);
            if (weightChangeRatio > 0) {
                const weeksToAdd = parseFloat(weightChangeRatio); 
                const currentDate = new Date(); 
                const futureDate = new Date(currentDate.getTime() + weeksToAdd * 7 * 24 * 60 * 60 * 1000); 
                const options = { month: 'long', year: 'numeric' };
                const formattedDate = new Intl.DateTimeFormat('en-US', options).format(futureDate);
                setGoalDate(formattedDate)
            }

        } else {
            console.log("exactgoalperweek should not be zero for the calculation");
        }


    };

    const handleSendData = async () => {
        if (weight <= 0) {
            setWeightError('Weight should be greater than zero');
            return;
        } else {
            setWeightError('');
        }

        // Validation for age
        if (age <= 0) {
            setAgeError('Age should be greater than zero');
            return;
        } else {
            setAgeError('');
        }

        // Validation for height
        if (fetheight <= 0 && inchheight <= 0) {
            setHeightError('Height should be greater than zero');
            return;
        } else {
            setHeightError('');
        }
        const inputData = {
            currentDietType: selectedItemName,
            goal: goal,
            preferredUnits: units,
            gender: gender,
            height_m: fetheight,
            height_cm: inchheight,
            // height_m:height,
            weight: weight,
            age: age,
            bodyfat: fat,
            activityLevel: activityLevel
        }
        localStorage.setItem("inputsforCalories", JSON.stringify(inputData))
        try {
            const response = await axios.post("/calculateCalories",
                inputData
            );
            if (response.status === 200) {
                const result = response.data;
                setexpectedCalories(true)
                setresultedBMR(result.response)
                localStorage.setItem("calculateCalories", JSON.stringify(result.response))
            } else if (response.status !== 200) {
                console.log("something went wrong")
            }

        } catch (error) {
            console.error("Error calculating calories:", error.message);
        }
    }

    useEffect(() => {
        const userDetails = JSON.parse(localStorage.getItem("inputsforCalories"));
        const caloriesuserdetails = JSON.parse(localStorage.getItem("calculateCalories"))
        if (userDetails) {
            setGoal(userDetails.goal)
            setunits(userDetails.preferredUnits)
            setGender(userDetails.gender)
            setfetHeight(userDetails.height_m)
            setinchHeight(userDetails.height_cm)
            setWeight(userDetails.weight)
            setAge(userDetails.age)
            setfat(userDetails.bodyfat)
            setActivityLevel(userDetails.activityLevel)
        }
        if (caloriesuserdetails) {
            setresultedBMR(caloriesuserdetails)
            setexpectedCalories(true)
        }
    }, [])




    const handleModalToggle = () => {
        setShowModal(!showModal);
    };
    const handleApplyingcalories = () => {
        setCalories(resultedBMR.calories)
        setShowModal(!showModal);
    }

    let content = (
        <section className="popup-calculation">
            <div className="popup-modalboxes " >
                <div>
                    <label className="p-2 ">
                        This calculator uses a standard BMR equation (the Mifflin-St Jeor formula) to estimate your Calorie needs. We also make some rough macronutrient suggestions, but you're free to completely customize these values when you create a free account.<br></br><br></br>
                        <strong>Keep in mind that this is a general estimate.</strong> For best results, consult your healthcare provider.
                    </label>
                </div>


                <div className="modal-flexbox">
                    <div >
                        <label>Current diet type</label>
                        <label>{selectedItemName}</label>
                    </div>
                    <div className="modal-flexbox-trippleinput">
                        <label>I want to</label>
                        <div>
                            <button onClick={() => handlegoalClick('Looseweight')}
                                style={{
                                    backgroundColor: goal === 'Looseweight' ? '#1b68df' : '',
                                    color: goal === 'Looseweight' ? 'white' : '',
                                }}>Loose weight</button>
                            <button onClick={() => handlegoalClick('Maintain')}
                                style={{
                                    backgroundColor: goal === 'Maintain' ? '#1b68df' : '',
                                    color: goal === 'Maintain' ? 'white' : '',
                                }}>maintain</button>
                            <button onClick={() => handlegoalClick('BuildMuscle')}
                                style={{
                                    backgroundColor: goal === 'BuildMuscle' ? '#1b68df' : '',
                                    color: goal === 'BuildMuscle' ? 'white' : '',
                                }}>Build Muscle</button>
                        </div>
                    </div>
                    <div className="modal-flexbox-doubleinput"
                    >
                        <label>preferred units</label>
                        <div>
                            <button onClick={() => unitsButtonClick('U.S Standard')}
                                style={{
                                    backgroundColor: units === 'U.S Standard' ? '#1b68df' : '',
                                    color: units === 'U.S Standard' ? 'white' : '',
                                }} >U.S. Standard</button>
                            <button onClick={() => unitsButtonClick('Metric')}
                                style={{
                                    backgroundColor: units === 'Metric' ? '#1b68df' : '',
                                    color: units === 'Metric' ? 'white' : '',
                                }}>Metric</button>
                        </div>
                    </div>
                    <div className="modal-flexbox-doubleinput">
                        <label>I am</label>
                        <div>
                            <button onClick={() => handleGenderClick('Male')}
                                style={{
                                    backgroundColor: gender === 'Male' ? '#1b68df' : '',
                                    color: gender === 'Male' ? 'white' : '',
                                }}>Male</button>
                            <button onClick={() => handleGenderClick('Female')}
                                style={{
                                    backgroundColor: gender === 'Female' ? '#1b68df' : '',
                                    color: gender === 'Female' ? 'white' : '',
                                }}>Female</button>
                        </div>
                    </div>
                    <div >
                        <label>Height</label>

                        {units === 'U.S Standard' ? (
                            <div className="modal-flexbox-doubleinput">
                                <input
                                    type="text"
                                    placeholder="ft"
                                    value={fetheight}
                                    onChange={(e) => setfetHeight(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder="inch"
                                    value={inchheight}
                                    onChange={(e) => setinchHeight(e.target.value)}
                                />

                            </div>
                        ) : (
                            <input
                                type="text"
                                placeholder="cms"
                                value={inchheight}
                                onChange={handlemetricinput}
                            />
                        )}
                    </div>
                    {heightError && <span className="error">{heightError}</span>}
                    <div >
                        <label>weight</label>
                        <input
                            type="text"
                            placeholder={units === 'U.S Standard' ? 'lbs' : 'kgs'}
                            value={weight}
                            onChange={(e) => setWeight(e.target.value)}
                        />
                    </div>
                    {weightError && <span className="error">{weightError}</span>}
                    <div >
                        <label>age</label>
                        {/* <div> */}
                        <input
                            type="text"
                            placeholder="yrs"
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                        />
                        {/* <span>yrs</span> */}
                        {/* </div> */}

                    </div>
                    {ageError && <span className="error">{ageError}</span>}

                    <div className="modal-flexbox-trippleinput">
                        <label>Body fat</label>
                        <div>
                            <button onClick={() => handlefatClick('low')}
                                style={{
                                    backgroundColor: fat === 'low' ? '#1b68df' : '',
                                    color: fat === 'low' ? 'white' : '',
                                }}>Low</button>
                            <button onClick={() => handlefatClick('medium')}
                                style={{
                                    backgroundColor: fat === 'medium' ? '#1b68df' : '',
                                    color: fat === 'medium' ? 'white' : '',
                                }}>medium</button>
                            <button onClick={() => handlefatClick('high')}
                                style={{
                                    backgroundColor: fat === 'high' ? '#1b68df' : '',
                                    color: fat === 'high' ? 'white' : '',
                                }}
                            >High</button>
                        </div>
                    </div>
                    <div >
                        <label>Activity level</label>
                        <select value={activityLevel} onChange={handleActivityLevelClick}>
                            <option value="Sedentary">Sedentary</option>
                            <option value="Lightly Active">Lightly Active</option>
                            <option value="Moderately Active">Modertaly Active</option>
                            <option value="Very Active">Very Active</option>
                        </select>
                    </div>
                    <div className="modal-flexbox-doubleinput"
                    >
                        <label>Set a weight goal?</label>
                        <div>
                            <button onClick={() => goalButtonClick('nothanks')}
                                style={{
                                    backgroundColor: exactgoal === 'nothanks' ? '#1b68df' : '',
                                    color: exactgoal === 'nothanks' ? 'white' : '',
                                }} >No thanks</button>
                            <button onClick={() => goalButtonClick('letsdoit')}
                                style={{
                                    backgroundColor: exactgoal === 'letsdoit' ? '#1b68df' : '',
                                    color: exactgoal === 'letsdoit' ? 'white' : '',
                                }}>Yeah let's do it</button>
                        </div>
                    </div>
                    {showGoalSections && (
                        <>
                            <div >
                                <label>Enter your goal weight</label>
                                <input
                                    type="text"
                                    placeholder={units === 'U.S Standard' ? 'lbs' : 'kgs'}
                                    value={weight}
                                    onChange={(e) => setWeight(e.target.value)}
                                />
                            </div>
                            <div >
                                <label>weight change rate</label>
                                <div style={{ "border": "none", "gap": "10px", "display": "flex" }}>

                                    <label>Lose</label>
                                    <input
                                        type="number"
                                        value={exactgoalperweek}
                                        onChange={(e) => handleExactGoalPerWeek(e)}
                                    />
                                    <label>kgs per week</label>
                                </div>
                            </div>
                            {
                                goalDate && (
                                    <>
                                        <label style={{ "marginTop": "2rem", "display": "flex", "justifyContent": "center" }} >You should reach your goal in {goalDate}</label>
                                    </>
                                )
                            }
                        </>

                    )}

                </div>

            </div>

            <button className="calculate-unknownCalories" onClick={handleSendData}>
                <i className="fa fa-calculator" aria-hidden="true"></i> &nbsp; <strong>Calculate</strong>
            </button>

            {expectedCalories && (
                <div className="Result_calories">
                    <div>
                        <h6>Suggested Calories</h6>
                        <h6>Carbs</h6>
                        <h6>Fat</h6>
                        <h6>Protein</h6>
                    </div>

                    <div>
                        <label>{resultedBMR.calories}kcal</label>
                        <label>{`${resultedBMR.mincarborange}g -${resultedBMR.maxcarborange}g`}</label>
                        <label>{`${resultedBMR.minfatrange}g- ${resultedBMR.maxfatrange}g`}</label>
                        <label>{`${resultedBMR.minproteinrange}g - ${resultedBMR.maxproteinrange}g`}</label>

                    </div>

                    <button className="calculate-unknownCalories" onClick={handleApplyingcalories} >
                        <i className="fa fa-check" aria-hidden="true"></i> &nbsp; <strong>Apply these settings</strong>
                    </button>
                </div>

            )}
        </section>



    )

    // Handling the reciepes generator
    let recipeIds = []
    const handleGenerateRecipes = async (recipeIds) => {
        const inputData = {
            diet_type: selectedItemId,
            level: meallevel,
            calories: calories,
            restricted_ids: recipeIds
        }
        // console.log(inputData)
        // localStorage.setItem("mealplanInputs", JSON.stringify(inputData))
        try {
            const response = await axios.post(`${settings.apiBaseURL}/getRecipesByMealLevelCalories`, inputData);
            if (response.status === 200) {
                const result = await response.data;
                const caloriesresult = result.results
                setdata(caloriesresult)
                // localStorage.setItem("mealplan", JSON.stringify(caloriesresult))
            } else if (response.status !== 200) {
                console.log("something went wrong", response.error)
            }
        } catch (error) {
            console.log(error.message)
        }
    }
    useEffect(() => {
        const userDetails = JSON.parse(localStorage.getItem("mealplanInputs"))
        const mealplanDetails = JSON.parse(localStorage.getItem("mealplan"))
        if (userDetails) {
            setCalories(userDetails.calories)
            setmeallevel(userDetails.level)
        }
        if (mealplanDetails) {
            setdata(mealplanDetails)
        }
    }, [])

    const handleGenerateRecipesforweek = async () => {
        setLoading(true);
        const genderValue = gender === "male" ? 1 : 2;
        const BMRInputs = {
            currentDietType: selectedItemName,
            goal: goal,
            preferredUnits: units,
            gender: genderValue ,
            height_m: fetheight,
            height_cm: inchheight,
            // height_m:height,
            weight: weight,
            age: age,
            bodyfat: fat,
            activityLevel: activityLevel
        }
        const today = new Date();
        const endDate = new Date(today);
        endDate.setDate(endDate.getDate() + 6);
        const startDateString = today.toISOString().slice(0, 10);
        const endDateString = endDate.toISOString().slice(0, 10);
        const inputData = {
            diet_type: selectedItemId,
            level: meallevel,
            calories: calories,
            startDate: startDateString,
            endDate: endDateString,
            // restricted_ids: recipeIds,
            BMRInputs: BMRInputs
        }
        try {
            const response = await axios.post("/generateandCreateMealPlanforweek", inputData)
            if (response.status === 200) {
                const result = response.data;
               setMealplanId(result.response.id)
               // Toggle reloadData to trigger useEffect
               setReloadData(prevState => !prevState);
            } else if (response.status !== 200) {
                console.log("something went wrong")
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        if (mealplanId || reloadData) {
            setdata('')
          const fetchData = async () => {
            try {
              const activemealplans = await getactiveMealPlansbyuserId(UserId, new Date());
              setdata(activemealplans.results);
            } catch (error) {
              console.error("Error fetching active meal plans:", error);
            }
          };
          fetchData();
        }
      }, [mealplanId, UserId, reloadData]);
    
    //     useEffect(()=>{
    // const transctaionID=localStorage.getItem("")
    //     },[])
    const daybuttonpressed = () => {
        setTab("day")
        handleGenerateRecipes(recipeIds)

    }

    const navigate = async () => {
        const res = await axios.get("/getPatientDetails", {
            params: { patientId: UserId },
        });
        const result = res.data.response;
        let subscriptionEndDates = [];
        result.forEach((item) => {
            item.userSubscriptions.forEach((subscriptiondate) => {
                subscriptionEndDates.push(new Date(subscriptiondate.end_date));
            });
        });

        const currentDate = new Date();
        const isSubscriptionExpired = subscriptionEndDates.some((endDate) => currentDate > endDate);

        if (isSubscriptionExpired || subscriptionEndDates.length <= 0) {
            history.push(subscriptionPlanDetailsRoute.path);
        } else {
            setTab("week")
            const userNonExpiredMealPlans = await getUserValidMealPlans();
            if (userNonExpiredMealPlans && userNonExpiredMealPlans.length > 0) {
                setStartDate(userNonExpiredMealPlans[0].mp_start_date);
                setEndDate(userNonExpiredMealPlans[0].mp_end_date);
            }
        }

    }
    return (
        <>
         
            <div className="bmrCalculator-maincontainer" >
                <div className="bmrCalories-input">
                    <label>{language["I want to eat"] ?? "I want to eat"}</label>
                    <div className="Calories-calculator ">
                        <input type="number" min="0" max="20000" step="100" placeholder="####" className="text-md-right" value={calories} onChange={(e) => setCalories(e.target.value)} />
                        <label>Calories</label>
                        <button onClick={handleModalToggle}>
                            <i className="fa fa-calculator" aria-hidden="true"></i> Not sure?
                        </button>
                    </div>
                </div>
                <div className="bmrCalories-input">
                    <label>{language["in"] ?? "in"}</label>
                    <div className="Calories-calculator ">
                        <select value={meallevel}
                            onChange={(e) => setmeallevel(e.target.value)}>
                            <option value="1">1 Meal</option>
                            <option value="2">2 Meals</option>
                            <option value="3">3 Meals</option>
                            <option value="4">4 Meals</option>
                            <option value="5">5 Meals</option>
                        </select>
                    </div>
                </div>
                <button className="Caloriegenerate-button" onClick={() => handleGenerateRecipesforweek()}>Generate</button>
                {loading && <Loading height="auto" width="64px" />}

                {
                    data && (
                        <div className="daytabs">
                            <button className={tab === 'day' ? 'tab active-tab' : 'tab inactive-tab'} onClick={daybuttonpressed}>Day</button>
                            <button className={tab === 'week' ? 'tab active-tab' : 'tab inactive-tab'} onClick={navigate}>Week</button>
                        </div>
                    )
                }


                {data && (
                    <div className="recipes-loaded">

                        {

                            <Recipeswithmealselection recipedata={data} handleAPIRequest={handleGenerateRecipes} startDate={startDate} endDate={endDate} tab={tab} mealplanId={mealplanId} diet_type={selectedItemId}/>

                        }
                    </div>
                )}
                {/* Modal */}
                <Modal show={showModal} onHide={handleModalToggle} centered >
                    <Modal.Header closeButton style={{ width: '700px', margin: 'auto', background: "white" }} >
                        <Modal.Title >Nutrition Calculator</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ width: "700px", margin: 'auto', background: "white" }}>
                        {content}
                    </Modal.Body>
                    <Modal.Footer style={{ width: '700px', margin: 'auto', background: "white" }}>
                        <Button variant="secondary" onClick={handleModalToggle}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div >
        </>



    )
}




export default BMRcalories


