import actions from "./actions";

export const initState = {
  loading: true,
  publishKey: null,
  paymentRegisterationData: null,
  loadStripePromise: null,
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.SET_STRIPE_FORM_CONFIG:
      return Object.assign({}, state, {
        loading: false,
        publishKey: payload.paymentInfo.apiPublishKey,
        paymentRegisterationData: payload.registerationData,
        loadStripePromise: payload.loadStripePromise,
      });
    default:
      return state;
  }
};

export default reducer;
