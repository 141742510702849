import { Component, Fragment } from "react";
import Avatar from "react-avatar";

class MealPlanBrokenImage extends Component {
  state = {
    src: this.props.url,
    fallbackSrc:this.props.fallbackUrl,
    name: this.props.name,
    loaded: false,
    error: !this.props.url ? true : false,
  };

  onImageLoaded = () => {
    this.setState({ loaded: true });
  };
  onImageError = () => {
    this.setState({ error: true });
  };

  render() {
    const { src, loaded, fallbackSrc, error, name } = this.state;
    let imgSrc = !error ? src : fallbackSrc;
    return (
      <>
        {error ? (
           <img
           src={fallbackSrc}
           onLoad={this.onImageLoaded}
           onError={this.onImageError}
           className={this.props.className}
           alt={this.props.alt}
         />
        ) : (
          <img
            src={src}
            onLoad={this.onImageLoaded}
            onError={this.onImageError}
            className={this.props.className}
            alt={this.props.alt}
          />
        )}
      </>
    );
  }
}

export default MealPlanBrokenImage;