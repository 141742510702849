import axios from "axios";
import { useEffect, useState } from "react";
import { settings } from "../config";
import AppointmentCards from "./AppointmentCards";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updatePatientAllAppointments } from "../redux/patient/actionCreators";
import moment from "moment";
import TopMenu from "./CommonControls/TopMenu";
import { getScheduleTypes } from "../services/CustomerNetworkService";

const DashboardAppointments = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appointments = useSelector((state) => state.patient.appointments);
  const [active, Setactive] = useState(
    (history.location.state && history.location.state.previous) ||
      (history.location.state && history.location.state.pending) ||
      "Ongoing"
  );

  const [fetch, setfetch] = useState(false);
  // const [appointmentData, SetappointmentData] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (!fetch) {
      getData();
    }
  }, [fetch]);

  /*useEffect(() => { 
    console.log("Update all appointments--->",appointments);
    SetappointmentData({appointments})},[appointments]);*/

  // useEffect(() => {
  //   if (
  //     appointments &&
  //     appointments.pendingAppointments &&
  //     appointments.pendingAppointments.length === 0 &&
  //     history.location.state?.pending === "Pending"
  //   ) {
  //     Setactive("Upcoming");
  //   } else if (
  //     appointments &&
  //     appointments.pendingAppointments &&
  //     appointments.pendingAppointments.length === 0 &&
  //     appointments.upcomingAppointments &&
  //     appointments.upcomingAppointments.length === 0 &&
  //     history.location.state?.pending === "Pending"
  //   ) {
  //     Setactive("Cancelled");
  //   } else if (
  //     appointments &&
  //     appointments.pendingAppointments &&
  //     appointments.pendingAppointments.length === 0 &&
  //     appointments &&
  //     appointments.upcomingAppointments &&
  //     appointments.upcomingAppointments.length === 0 &&
  //     appointments &&
  //     appointments.cancelledAppointments &&
  //     appointments.cancelledAppointments.length === 0 &&
  //     history.location.state?.pending === "Pending"
  //   ) {
  //     Setactive("Previous");
  //   } else {
  //     if (history.location.state?.pending)
  //       Setactive(history.location.state?.pending);
  //   }
  // }, [appointments]);
  const getStartDate = (now) => {
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 15);
  };

  const getEndDate = (now) => {
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 15);
  };

  const getData = async () => {
    const customer = JSON.parse(localStorage.getItem("userData"));
    const today = new Date();
    setloading(true);
    const types = await getScheduleTypes();
    const scheduleTypeId = types[0].id;
    const startDate = moment(getStartDate(today)).format("YYYY-MM-DD");
    const endDate = moment(getEndDate(today)).format("YYYY-MM-DD");
    await axios
      .get(
        `${settings.apiBaseURL}/getCustomerAllAppointments?startDate=${startDate}&endDate=${endDate}&customerId=${customer.id}&schedulerType=${scheduleTypeId}`
      )
      .then(function (response) {
        const data = response.data.response;

        dispatch(updatePatientAllAppointments(data));
      })
      .catch(function (response) {
        console.log(response);
      })
      .finally(function () {
        setfetch(true);
      });
    setloading(false);
  };
  const language = useSelector((state) => state.language);
  return (
    <div class="row">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.lang.appointments}
      />
      <section className="d-flex flex-row flex-nowrap ml-3 appointments-card-style">
        {/* <div className="d-flex flex-row">
        <section className="row"> */}
        <div className="w-100" style={{ backgroundColor: "#ffffff9e" }}>
          <div
            className="py-3 d-flex justify-content-center tabs w-100"
            style={{ gap: "20px", fontFamily: "Montserrat" }}
          >
            <div
              className={`tab d-flex justify-content-center ${
                active === "Ongoing" ? "active" : ""
              }`}
              onClick={() => Setactive("Ongoing")}
            >
              <span>{language.lang.ongoing}</span>
            </div>
            <div
              className={`tab d-flex justify-content-center ${
                active === "Previous" ? "active" : ""
              }`}
              onClick={() => Setactive("Previous")}
            >
              <span>{language.lang.previous}</span>
            </div>
            <div
              className={`tab d-flex justify-content-center ${
                active === "Cancelled" ? "active" : ""
              }`}
              onClick={() => Setactive("Cancelled")}
            >
              <span>{language.lang.cancelled}</span>
            </div>
            <div
              className={`tab d-flex justify-content-center ${
                active === "Upcoming" ? "active" : ""
              }`}
              onClick={() => Setactive("Upcoming")}
            >
              <span>{language.lang.upcoming}</span>
            </div>
            <div
              className={`tab d-flex justify-content-center ${
                active === "Pending" ? "active" : ""
              }`}
              onClick={() => Setactive("Pending")}
            >
              <span>{language.lang.pending}</span>
            </div>
          </div>
          <div
            className="row"
            style={{ "min-height": "400px", fontFamily: "Montserrat" }}
          >
            {active === "Ongoing" && (
              <AppointmentCards
                title={active}
                data={appointments.customerOngoingAppointments?.sort(function (
                  a,
                  b
                ) {
                  return moment(b.end_date_time) - moment(a.end_date_time);
                })}
                loading={loading}
              />
            )}
            {active === "Previous" && (
              <AppointmentCards
                title={active}
                data={appointments.customerCompletedAppointments?.sort(
                  function (a, b) {
                    return moment(b.end_date_time) - moment(a.end_date_time);
                  }
                )}
                loading={loading}
              />
            )}
            {active === "Cancelled" && (
              <AppointmentCards
                title={active}
                data={appointments.customerCancelledAppointments?.sort(
                  function (a, b) {
                    return moment(b.end_date_time) - moment(a.end_date_time);
                  }
                )}
                loading={loading}
              />
            )}
            {active === "Upcoming" && (
              <AppointmentCards
                title={active}
                data={appointments.customerUpcomingAppointments?.sort(function (
                  a,
                  b
                ) {
                  return moment(a.end_date_time) - moment(b.end_date_time);
                })}
                loading={loading}
              />
            )}
            {active === "Pending" && (
              <AppointmentCards
                title={active}
                data={
                  appointments &&
                  appointments.customerPendingAppointments &&
                  appointments.customerPendingAppointments.length > 0 &&
                  appointments.customerPendingAppointments
                    .filter(
                      (a) =>
                        a.status_id === 23 ||
                        a.status.name === "Pending on Clinic"
                    )
                    ?.sort(function (a, b) {
                      return moment(b.end_date_time) - moment(a.end_date_time);
                    })
                }
                loading={loading}
              />
            )}
          </div>
        </div>
        {/* </section>
      </div> */}
      </section>
    </div>
  );
};
export default DashboardAppointments;
