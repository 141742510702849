import axios from "./Axios";

export const getAgoraRTMToken = async ({ userID, channelID }) => {
  try {
    const res = await axios.post("/rtmToken", {
      userId: userID,
      channelName: channelID,
    });
    return res.data.response;
  } catch (e) {
    console.log("--- getAgoraRTMToken error");
    console.log(e);
    return null;
  }
};
