import { useSelector } from "react-redux";
import { dashboardRoute } from "../../routes";
import TopMenu from "../CommonControls/TopMenu";
import NewBackButton from "../NewBackButton";
import PractitionerNewLiveSessionFormTab from "./PractitionerNewLiveSessionFormTab";

const PractitionerLiveSessionTab = () => {
  const language = useSelector((state) => state.language.lang);
  // const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  // const onTabSelected = (index) => {
  //   if (selectedTabIndex === index) return;
  //   setSelectedTabIndex(index);
  // };

  return (
    <section className="practitioner-live-session-tab">
      {/* <div className="d-flex align-items-center mx-4 my-3">
        <NewBackButton epath={dashboardRoute} />
        <h5 className="ml-3 mb-0">{language.newEvent}</h5>
      </div> */}
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.events}
      /> 
      <div className="container-fluid">
        <div className="card mb-3">
          {/* <h4 className="title w-100">Live Session Settings</h4> */}
          <div className="container-fluid">
            <div className="row mx-0">
              {/* <div className="col-6 col-md-5 d-flex">
                <h4
                  className={`title tab-button ${
                    selectedTabIndex === 0 ? "selected" : ""
                  }`}
                  onClick={() => onTabSelected(0)}
                >
                  New Session
                </h4>
              </div> */}
              {/* <div className="col-6 col-md-5 d-flex">
                <h4
                  className={`title tab-button ${
                    selectedTabIndex === 1 ? "selected" : ""
                  }`}
                  onClick={() => onTabSelected(1)}
                >
                  Approved / Rejected
                </h4>
              </div> */}
            </div>
            <div className="row mx-0">
              {/* <div className="col-12">
                {selectedTabIndex === 0 ? (
                  <PractitionerNewLiveSessionFormTab />
                ) : (
                  <PractitionerLiveSessionsList />
                )}
              </div> */}
              <div className="col-12 px-0">
                <PractitionerNewLiveSessionFormTab />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PractitionerLiveSessionTab;
