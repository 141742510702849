import videoImg from "../assets/images/fit.png";
import { useSelector } from "react-redux";

const VideoCard = ({ data, liveCard = false, subtitle }) => {
  // console.log("livecarddata", data);
  const language = useSelector((state) => state.language);
  let image = liveCard
    ? data && data.session_image_url
    : data &&
      data.thumbnail &&
      data.thumbnail.find((e) => e.galleryItemInfo.type === "image/png");
  // console.log("duration", data);
  // const getTime = liveCard && data?.duration?.split(":");
  // const hour = liveCard && getTime[0];
  // const min = liveCard && getTime[1];

  // const getDuration = (hour, min) => {
  //   if (hour === "00") {
  //     return `${min} min`;
  //   } else if (min === "00") {
  //     return `${hour} hour`;
  //   } else {
  //     return `${hour} hour ${min} min`;
  //   }
  // };

  // const duration = getDuration(hour, min);
  // console.log(`duration: ${hour} hours ${min} min`);
  return (
    <div className="video-card d-flex flex-column justify-content-center align-items-center">
      {/* <div className="duration">30 min</div> */}
      <div className="video-card-thumbnail">
        {liveCard ? (
          <img
            className="thumb-img py-0 mb-3"
            src={image ? image : videoImg}
            alt="img-thumb"
          />
        ) : (
          <img
            className="thumb-img py-0 mb-3"
            src={image ? image.galleryItemInfo.url : videoImg}
            alt="img-thumb"
          />
        )}
        {liveCard && <p className="timing">{data.duration} Minutes</p>}
        {/* <img
          className="thumb-img py-0 mb-3"
          src={image ? image.galleryItemInfo.url : videoImg}
          alt="img-thumb"
        /> */}
      </div>
      <div className="video-card-headline my-2 clampText d-flex flex-column ">
        {liveCard ? (
          <p className="title d-flex justify-content-start w-100 card-tiles-font font-weight-bold">
            {data && data.title}
          </p>
        ) : (
          <p className="title d-flex justify-content-start w-100 card-tiles-font">
            {data && data.featureSection && data.name}
          </p>
        )}
        {subtitle && (
          <>
            <div className="d-flex align-items-center justify-content-between gap-2">
              <p className="session-type">{subtitle}</p>
            </div>
            <div className="d-flex justify-content-end mt-auto">
              <button className="live-details-button">
                {language.lang["view-details"]}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VideoCard;
