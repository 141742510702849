import { useRef, useReducer, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import reducer, { initialState } from "./reducer";
import { inputChangeAction } from "./actions";
import { showErrorNotification } from "../../utility/Helpers";
import EmiratesIDImageField from "../EmiratesIDImageField";
import MedicalDocumentField from "../MedicalDocumentField";
import VoiceRecordField from "../VoiceRecordField";
import GenderSelectField from "../GenderSelectField";
import MMRadioButton from "../MMRadioButton";
import Loading from "../Loading";
import axios from "../../services/Axios";
import moment from "moment";
import ChoiceField from "./ChoiceField";
import { dashboardBookConsultationRoute } from "../../routes";
import { settings } from "../../config";

const OnboardingForm = (props) => {
  const { onFormSubmited } = props;
  const language = useSelector((state) => state.language);
  const [loading, setloading] = useState(false);
  const { userData } = useSelector((state) => state.auth);
  const [data, Setdata] = useState(false);
  const [Fdata, SetFdata] = useState("");
  const [appear, setappear] = useState(false);
  const [agree, setagree] = useState("1");
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const recordFileRef = useRef(null);
  const medicalDocFileRef = useRef(null);
  const emiratesIDImageRef = useRef(null);
  const emiratesIDBackImageRef = useRef(null);
  const [resident, setresident] = useState([]);
  const [insuranceProvider, setinsuranceProvider] = useState([]);
  const [clientType, setClientType] = useState(true);
  const IDRegex = RegExp(/^[0-9]{15}$/);
  useEffect(() => {
    dispatch(inputChangeAction({ name: "profileCompleteStep", value: "0" }));
  }, []);
  useEffect(() => {
    //if(userData.profile_complete_step !== 1)  dispatch(inputChangeAction({name:"profileCompleteStep",value:"0"}));
    getPatientFormData();
    if (data) SortNow();
  }, [data]);
  // useEffect(() => {console.log("emiratesIDBackImageRef",emiratesIDBackImageRef)},[emiratesIDBackImageRef.current]);
  useEffect(() => {
    getResident();
    if (props?.insuranceProvidersCount > 0) {
      getInsuranceProvideByClinic();
    } else {
      getInsuranceProvide();
    }
  }, []);
  useEffect(() => {
    /*if(props.type){
      handlePaymentChange(!(history && history.location && history.location.state && history.location.state.getinsured ));
      handleInsuredChange((history && history.location && history.location.state && history.location.state.getinsured ));
    }*/
    if (props?.searchData)
      dispatch(
        inputChangeAction({
          name: "insuranceProvider",
          value: props?.searchData?.insuranceProviderId,
        })
      );
    console.log("Searched Data -->", props?.searchData);
  }, [insuranceProvider]);
  useEffect(() => {
    setClientType(state.formData.residentStatusId === "3");
    console.log("triggering");
    if (props.type) {
      console.log("triggering 2");
      if (state.formData.residentStatusId === "3") {
        console.log("Tourist");
        dispatch(
          inputChangeAction({ name: "isProceedWithFullPayment", value: true })
        );
        dispatch(inputChangeAction({ name: "isInsured", value: false }));
      }
    }
  }, [state.formData.residentStatusId]);
  const getResident = async () => {
    await axios
      .get("/getResidents")
      .then((res) => {
        setresident(res.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInsuranceProvideByClinic = async () => {
    await axios
      .get(
        `${settings.apiBaseURL}/getInsuranceProvidersByClinicId?clinicId=${props.clinicid}`
      )
      .then((res) => {
        let changeFormat = [];
        res.data.response.map((item) => {
          changeFormat.push(item.insuranceProviders);
        });
        setinsuranceProvider(changeFormat);
        console.log("getInsuranceProvideByClinic", changeFormat);
        // if(res.data.response.length > 0)  dispatch(inputChangeAction({ name: "insuranceProvider", value: 1 }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getInsuranceProvide = async () => {
    await axios
      .get("/getInsuranceProviders")
      .then((res) => {
        console.log("getInsuranceProvide", res.data.response);
        setinsuranceProvider(res.data.response);
        // if(res.data.response.length > 0)  dispatch(inputChangeAction({ name: "insuranceProvider", value: 1 }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SortNow = () => {
    Object.keys(Fdata || {}).map((key, value) => {
      var newkey = "";
      var flag = true;
      if (!state.formData.hasOwnProperty(key)) {
        switch (key) {
          case "emirates_id":
            newkey = "emiratesId";
            break;
          /* case "insurance_no":
            newkey = "insuranceNo";
            break;*/
          case "emirates_id_expiry_date":
            newkey = "emiratesIdExpiryDate";
            break;
          case "birth_date":
            newkey = "dob";
            break;
          case "emirates_id_photo_url":
            newkey = "emiratesIdPhotoUrl";
            break;
          case "emirates_id_photo_url_for_back":
            newkey = "emiratesIdPhotoUrlForBack";
            break;
          default:
            flag = false;
            break;
        }
      }
      if (flag) {
        var x = { name: newkey || key, value: Fdata[key] };
        dispatch(inputChangeAction(x));
      }
    });
  };
  const getPatientFormData = async () => {
    setloading(true);
    await axios
      .get("/getPatientDetails", {
        params: { patientId: userData.id },
      })
      .then((res) => {
        SetFdata(res.data.response[0]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        Setdata(true);
      });
    setloading(false);
  };

  const handleInputChange = (e) => {
    var tar = e.target;
    dispatch(inputChangeAction(tar));
  };

  const handleEmiratesIDImgChange = (file) => {
    emiratesIDImageRef.current = file;
  };
  const handleEmiratesIDBackImgChange = (file) => {
    emiratesIDBackImageRef.current = file;
  };

  const handleMedicalRecordChange = (file) => {
    medicalDocFileRef.current = file;
  };

  const handleRecordChange = (file) => {
    recordFileRef.current = file;
  };

  const handleGenderChange = (gender) => {
    dispatch(inputChangeAction({ name: "gender_id", value: gender }));
    dispatch(inputChangeAction({ name: "gender", value: gender }));
  };
  const handleInsuredChange = (insured) => {
    console.log("Insured-->", insured);
    if (state.formData.residentStatusId !== "3") {
      if (props.insuranceProvidersCount === 0) {
        dispatch(inputChangeAction({ name: "isInsured", value: true }));
      } else {
        dispatch(inputChangeAction({ name: "isInsured", value: insured }));
      }
      if (props.type) {
        dispatch(
          inputChangeAction({
            name: "isProceedWithFullPayment",
            value: !insured,
          })
        );
      }
    }
  };
  const handlePaymentChange = (payment) => {
    console.log("Payment-->", payment);
    console.log("insuranceProvidersCount", props.insuranceProvidersCount);
    if (state.formData.residentStatusId !== "3") {
      if (props.insuranceProvidersCount > 0) {
        console.log("insuranceProvidersCount", props.insuranceProvidersCount);
        dispatch(
          inputChangeAction({
            name: "isProceedWithFullPayment",
            value: payment,
          })
        );
      } else {
        dispatch(
          inputChangeAction({ name: "isProceedWithFullPayment", value: true })
        );
      }
      if (props.type) {
        dispatch(inputChangeAction({ name: "isInsured", value: !payment }));
      }
    }
  };
  const OnSelectResidentHandler = (e) => {
    dispatch(
      inputChangeAction({ name: "residentStatusId", value: e.target.value })
    );
  };
  const OnSelectCountryHandler = (e) => {
    dispatch(inputChangeAction({ name: "regionId", value: e.target.value }));
  };
  const OnSelectInsuranceHandler = (e) => {
    dispatch(
      inputChangeAction({ name: "insuranceProvider", value: e.target.value })
    );
    dispatch(inputChangeAction({ name: "insuranceNo", value: "" }));
    // props.history.location.state && props.history.location.state.book_consultation && getPractitionersByInsuranceProvider(e.target.value);
  };
  /*const getPractitionersByInsuranceProvider = async (value) =>{
    let newPrac = [];
    await axios.get(`/getPractitionersByInsuranceProvider?insuranceProviderId=${value}&userGroup=${userData.user_group}`)
    .then((res) => {
      res.data.response.map((q)=>{
      q.clinic.map((t) =>{
        t.practitioner.map((s) => {
          newPrac.push(s);
        });});});
    })
    .finally(() => {
      setPractitioners(newPrac);
    })
  }*/
  const meetDoctorPressed = async () => {
    if (state.formData.residentStatusId !== "3") {
      let v = state.formData.emiratesId;
      if (IDRegex.test(v) === false) {
        return showErrorNotification(
          "Emirates ID should be a number of length 15"
        );
      }
    }
    if (!validForm()) return;
    const onboardingFormData = {
      ...state.formData,
      userId: userData.id,
      appointmentType: "WalkIn",
    };
    localStorage.setItem("patient-data", JSON.stringify(onboardingFormData));

    if (history.location.state && history.location.state.slotId) {
      onboardingFormData.callType = "video";
      onboardingFormData.slotId = history.location.state.slotId;
      onboardingFormData.practitionerId = history.location.state.practitionerId;
    }
    //console.log("--- onboard", onboardingFormData);
    if (onFormSubmited) {
      onFormSubmited(
        onboardingFormData,
        emiratesIDImageRef.current,
        emiratesIDBackImageRef.current,
        medicalDocFileRef.current,
        recordFileRef.current,
        props &&
          props.history &&
          props.history.location &&
          props.history.location.state &&
          props.history.location.state.book_consultation
        //practitioners
      );
    }

    // const appointmentID = await submitOboardingForm(
    //   onboardingFormData,
    //   dispatch
    // );
    // if (!appointmentID) {
    //   showErrorNotification();
    //   return;
    // }
    // history.push(walkinConfirmationRoute.path);
    // reduxDispatch(setIsWatingForDoctorApprovalAction(appointmentID));
  };

  const validForm = () => {
    /*for (var propName in state.formData) {
      if (!(state.formData[propName] || state.formData[propName] === false)) {
          delete state.formData[propName];
      }
    }*/
    // console.log("--- form data", state.formData);
    if (!state.formData.phone) {
      // debugger;
      return showErrorNotification(language.lang.pleaseEnterPhoneNumber);
    }
    // debugger;
    if (state.formData.residentStatusId !== "3") {
      delete state.formData["passportNo"];
      delete state.formData["visaUidNo"];
      delete state.formData["passportExpiryDate"];
      if (
        state.formData.isProceedWithFullPayment &&
        props?.insuranceProvidersCount !== 0
      ) {
        delete state.formData["insuranceProvider"];
        delete state.formData["insuranceNo"];
      }
    } else {
      delete state.formData["insuranceProvider"];
      delete state.formData["emiratesId"];
      delete state.formData["insuranceNo"];
      delete state.formData["emiratesIdPhotoUrlForBack"];
      delete state.formData["emiratesIdPhotoUrl"];
      delete state.formData["isInsured"];
    }
    if (recordFileRef.current && !state.formData["reason"])
      delete state.formData["reason"];
    //  console.log(state.formData,state.formData.emiratesIdPhotoUrlForBack);
    for (const [key, value] of Object.entries(state.formData)) {
      // console.log("---key", key, value);
      if (
        !value &&
        key !== "age" &&
        key !== "howOftenProblem" &&
        key !== "isInsured" &&
        key !== "emiratesIdPhotoUrl" &&
        key !== "howOftenProblem" &&
        key !== "emiratesIdPhotoUrlForBack" &&
        key !== "isProceedWithFullPayment" &&
        key !== "insuranceProvider" &&
        key !== "isAllowUploadDocument"
      ) {
        if (key === "insuranceNo" && value === "") {
          continue;
        } else if (
          (key === "name" && value === "") ||
          (key === "phone" && value === "") ||
          (key === "email" && value === "") ||
          (key === "reason" && value === "")
        ) {
          showErrorNotification("Please fill all required fields");
          return false;
        }
      }

      if (
        key === "insuranceProvider" &&
        (value === "null" || !value) &&
        !state.formData.isProceedWithFullPayment
      ) {
        showErrorNotification("Please select insurance provider.");
        return false;
      }
      if (state.formData.emiratesIdPhotoUrlForBack === null) {
        if (
          state.formData.residentStatusId !== "3" &&
          !emiratesIDBackImageRef.current
        ) {
          showErrorNotification("Please upload photo of Emirates ID");
          return false;
        }
      }
    }
    if (
      // !recordFileRef.current ||
      // !medicalDocFileRef.current ||
      !emiratesIDImageRef.current &&
      state.formData.emirates_id_photo_url === ""
    ) {
      showErrorNotification("Please select your emirates ID image");
      return false;
    }
    return true;
  };

  const now = new Date();
  const minExpiryDate = `${now.getFullYear()}-${
    now.getMonth() > 9 ? now.getMonth() : `0${now.getMonth()}`
  }-${now.getDate()}`;

  return (
    <section className="doctor-tiles row mt-4 pb-lg-5">
      {loading ? (
        <div className="w-100" style={{ height: "80vh" }}>
          <Loading />
        </div>
      ) : (
        <>
          <div className="tiles-container col-12 col-lg-5 mx-auto">
            <div className="d-flex align-items-center input-field">
              <label htmlFor="name">
                {language.lang.country}{" "}
                <span className="required-field-star"> *</span>
              </label>
              <select
                className="neumorphic-input"
                value={state.formData.regionId}
                onChange={OnSelectCountryHandler}
                disabled
              >
                {language.general.regions &&
                  language.general.regions.length > 0 &&
                  language.general.regions.map((region, i) => {
                    return (
                      <option key={i} value={region.id}>
                        {region.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="d-flex align-items-center input-field mt-3">
              <label htmlFor="name">
                {language.lang.name}{" "}
                <span className="required-field-star"> *</span>
              </label>
              <input
                value={state.formData.name}
                onChange={handleInputChange}
                name="name"
                className="neumorphic-input"
                placeholder={language.lang.name}
                pattern="/^[A-Za-z]+$/"
              />
            </div>
            <div className="d-flex align-items-center input-field mt-3">
              <label htmlFor="phone">
                {language.lang["phone-number"]}{" "}
                <span className="required-field-star"> *</span>
              </label>
              <input
                value={state.formData.phone}
                onChange={handleInputChange}
                name="phone"
                className="neumorphic-input"
                placeholder={language.lang["phone-number"]}
                pattern="[0-9]{10}"
              />
            </div>
            <div className="d-flex align-items-center input-field mt-3">
              <label htmlFor="name">
                {language.lang["email-address"]}{" "}
                <span className="required-field-star"> *</span>
              </label>
              <input
                value={state.formData.email}
                onChange={handleInputChange}
                name="email"
                className="neumorphic-input"
                placeholder={language.lang["email-address"]}
              />
            </div>
            <div className="d-flex align-items-center input-field mt-3">
              <label htmlFor="name">
                {language.lang.resident_status}{" "}
                <span className="required-field-star"> *</span>
              </label>
              <select
                className="neumorphic-input"
                onChange={OnSelectResidentHandler}
                touchUi={true}
              >
                {resident &&
                  resident.map((item, i) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            {clientType && (
              <div className="d-flex align-items-center input-field mt-3">
                <label htmlFor="phone">
                  {language.lang.passport_number}
                  <span className="required-field-star"> *</span>
                </label>
                <input
                  value={state.formData.passportNo}
                  onChange={handleInputChange}
                  name="passportNo"
                  className="neumorphic-input"
                  placeholder={language.lang.passport_number}
                  pattern="[0-9]{10}"
                />
              </div>
            )}
            {!clientType && (
              <div className="d-flex align-items-center input-field mt-3">
                <label htmlFor="emiratesID">
                  {language.lang["emirates-id"]}{" "}
                  <span className="required-field-star"> *</span>
                </label>
                <input
                  value={state.formData.emiratesId}
                  onChange={handleInputChange}
                  name="emiratesId"
                  className="neumorphic-input"
                  placeholder={language.lang["emirates-id"]}
                />
              </div>
            )}
            {!clientType && (
              <div
                className="d-flex align-items-center input-field mt-3"
                style={{ gap: "10px" }}
              >
                <label htmlFor="emiratesIdExpiryDate">
                  {language.lang["expiry-date"]}{" "}
                  <span className="required-field-star"> *</span>
                </label>
                <input
                  value={state.formData.emiratesIdExpiryDate}
                  onChange={handleInputChange}
                  name="emiratesIdExpiryDate"
                  className="neumorphic-input"
                  placeholder="DD - MM - YYYY"
                  type="date"
                  min={minExpiryDate}
                />
              </div>
            )}
            {clientType && (
              <div
                className="d-flex align-items-center input-field mt-3"
                style={{ gap: "10px" }}
              >
                <label htmlFor="passportExpiryDate">
                  {language.lang["expiry-date"]}{" "}
                  <span className="required-field-star"> *</span>
                </label>
                <input
                  value={state.formData.passportExpiryDate}
                  onChange={handleInputChange}
                  name="passportExpiryDate"
                  className="neumorphic-input"
                  placeholder="DD - MM - YYYY"
                  type="date"
                  min={minExpiryDate}
                />
              </div>
            )}
            {!clientType && (
              <>
                <EmiratesIDImageField
                  url={state.formData.emiratesIdPhotoUrl}
                  onChange={handleEmiratesIDImgChange}
                  type="front"
                />
                <EmiratesIDImageField
                  url={state.formData.emiratesIdPhotoUrlForBack}
                  onChange={handleEmiratesIDBackImgChange}
                  type="back"
                />
              </>
            )}
            <GenderSelectField
              onChange={handleGenderChange}
              value={!!state.formData ? state.formData.gender_id : ""}
            />
            <div
              className="d-flex align-items-center input-field mt-3"
              style={{ gap: "10px" }}
            >
              <label htmlFor="age">
                {language.lang["date-of-birth"]}{" "}
                <span className="required-field-star"> *</span>
              </label>
              <input
                name="dob"
                type="date"
                id="dob"
                value={state.formData.dob}
                onChange={handleInputChange}
                className="neumorphic-input"
                min={moment().subtract(100, "years").format("YYYY-MM-DD")}
                max={moment().format("YYYY-MM-DD")}
              />
            </div>
          </div>
          <div className="tiles-container col-12 col-lg-5">
            {clientType && (
              <div className="d-flex align-items-center input-field mt-3">
                <label htmlFor="phone">
                  {language.lang.visa_uid_number}{" "}
                  <span className="required-field-star"> *</span>
                </label>
                <input
                  value={state.formData.visa_uid_no}
                  onChange={handleInputChange}
                  name="visaUidNo"
                  className="neumorphic-input"
                  placeholder={language.lang.visa_uid_number}
                  pattern="[0-9]{10}"
                />
              </div>
            )}
            <div className="full-payment mt-3">
              <label htmlFor="insured">
                {language.lang.proceedWithFullPayment}
                <span className="required-field-star"> *</span>
              </label>
              <ChoiceField
                first="Yes"
                second="No"
                value={state.formData.isProceedWithFullPayment}
                handleInsuredChange={handlePaymentChange}
                schedule={props.type}
              />
              {/* {props.insuranceProvidersCount !== 0 && <div className="d-flex justify-content-center align-items-center payment-tag ml-3">{props.fees} AED</div>} */}
            </div>

            <div className="col md-6 total-amount mt-3  ">
              <label className="col-sm">
                {language.lang.totalAmountToPay} :
              </label>
              <div className="col-sm d-flex justify-content-center align-items-center mb-1">
                {props.fees} AED
              </div>
            </div>

            {props.insuranceProvidersCount === 0 &&
              state.formData.residentStatusId !== "3" && (
                <div className="d-flex align-items-center input-field mb-3 mt-1 no-insurance">
                  No Insurance covered for this Practitioner. We would like to
                  know about your insurance provider. This will help us to find
                  right coverage partner for you in future.
                </div>
              )}

            {(!clientType ||
              (state.formData.isProceedWithFullPayment &&
                props.type &&
                state.formData.residentStatusId !== "3")) && (
              <>
                {state.formData.residentStatusId !== "3" &&
                  !state.formData.isProceedWithFullPayment &&
                  props.insuranceProvidersCount > 0 && (
                    <div className="is-insured mt-3">
                      <label htmlFor="insured">
                        {language.lang.are_you_insured}{" "}
                        <span className="required-field-star"> *</span>
                      </label>
                      <ChoiceField
                        first={language.lang.yes}
                        second={language.lang.no}
                        value={state.formData.isInsured}
                        handleInsuredChange={handleInsuredChange}
                        schedule={props.type}
                      />
                    </div>
                  )}
                {(state.formData.isInsured ||
                  props.insuranceProvidersCount === 0) && (
                  <>
                    <div className="d-flex align-items-center input-field mt-3">
                      <label htmlFor="name">
                        {language.lang.insurance_provider}{" "}
                        {!state.formData.isProceedWithFullPayment && (
                          <span className="required-field-star"> *</span>
                        )}
                      </label>
                      <select
                        className="neumorphic-input"
                        value={state.formData.insuranceProvider}
                        onChange={OnSelectInsuranceHandler}
                      >
                        <option value="" disabled selected>
                          {language.lang.pleaseSelectInsuranceProvider}
                        </option>
                        {insuranceProvider &&
                          insuranceProvider.map((item, i) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>

                    <div className="d-flex align-items-center input-field mt-3">
                      <label htmlFor="insurance_no">
                        {language.lang.insuranceCardNumber}
                      </label>
                      <input
                        value={
                          state.formData.insuranceNo !== "null"
                            ? state.formData.insuranceNo
                            : ""
                        }
                        onChange={handleInputChange}
                        name="insuranceNo"
                        className="neumorphic-input"
                        placeholder={`${language.lang["insurance-id"]}`}
                        minlength="8"
                      />
                    </div>
                  </>
                )}
              </>
            )}
            <div className="visit-reason-field">
              <p className="label">
                {language.lang["reason-of-visit"]}{" "}
                <span className="required-field-star"> *</span>
              </p>
              <textarea
                value={state.formData.reason}
                onChange={handleInputChange}
                className="neumorphic-input"
                name="reason"
              />
            </div>
            <VoiceRecordField
              onChange={handleRecordChange}
              valueRef={recordFileRef}
            />
            <div className="problem-field mt-3">
              <p className="label">
                {language.lang["how-offten-you-have-this-problem"]}
              </p>
              <textarea
                name="howOftenProblem"
                value={state.formData.howOftenProblem}
                onChange={handleInputChange}
                className="neumorphic-input"
              />
            </div>
            <MedicalDocumentField
              appear={appear}
              setappear={setappear}
              setagree={setagree}
              onChange={handleMedicalRecordChange}
            />
            {appear && (
              <div className="gender-select-field mt-3 flex-column">
                <span style={{ color: "#1B68DF", fontWeight: "600" }}>
                  {language.lang["medical-record-concent"]}
                </span>
                <div className="check-box-container justify-content-evenly py-2 agreeNdisagree">
                  <MMRadioButton
                    label={language.lang.agree}
                    onClick={() => {
                      setagree("1");
                      dispatch(
                        inputChangeAction({
                          name: "isAllowUploadDocument",
                          value: true,
                        })
                      );
                    }}
                    classes="mr-2"
                    selected={agree === "1"}
                  />
                  <MMRadioButton
                    label={language.lang.disagree}
                    onClick={() => {
                      setagree("0");
                      dispatch(
                        inputChangeAction({
                          name: "isAllowUploadDocument",
                          value: false,
                        })
                      );
                    }}
                    selected={agree === "0"}
                  />
                </div>
              </div>
            )}
            {!props.type && (
              <div className="d-flex justify-content-end mt-4 w-100">
                <button
                  onClick={meetDoctorPressed}
                  className="btn btn-primary mt-4"
                  disabled={agree === "1" ? false : true}
                >
                  {language.lang.meet_doctor}
                </button>
              </div>
            )}
            {props.type && (
              <div
                className="d-flex justify-content-center w-100"
                style={{ gap: "10px" }}
              >
                <div className="d-flex justify-content-end mt-4 flex-grow-1">
                  <button
                    //onClick={meetDoctorPressed}
                    className="btn btn-secondary w-100 slot-info-btn"
                    disabled={agree === "1" ? false : true}
                    onClick={() => {
                      history.push(dashboardBookConsultationRoute.path);
                    }}
                  >
                    {language.lang.cancel}
                  </button>
                </div>
                <div className="d-flex justify-content-end flex-grow-1 mt-4">
                  {props.Loading ? (
                    <Loading />
                  ) : (
                    <button
                      onClick={meetDoctorPressed}
                      className="btn btn-primary slot-info-btn mt-4 w-100 text-nowrap w-100"
                      disabled={agree === "1" ? false : true}
                    >
                      {language.lang.confirmBooking}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default OnboardingForm;
