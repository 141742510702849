import React, { useState } from "react";
import { Form, Table } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaCheckCircle } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";

const MedicineTable = ({ setMedicineList, medicineList, setList, list }) => {
  const [show, setShow] = useState(false);
  const [Input, setInput] = useState({
    medicine: "",
    dosage: "",
    duration: "",
    instructions: "",
  });
  const [response, setResponse] = useState(list ? list : []);

  const handleOnInputChange = (e) => {
    const nam = e.target.name;
    const val = e.target.value;
    setInput((prevState) => {
      return { ...prevState, [nam]: val };
    });
  };

  const handleAddNewMedicine = () => {
    if (show) {
      if (
        Input.medicine.trim() === "" ||
        Input.dosage.trim() === "" ||
        Input.duration.trim() === "" ||
        Input.instructions.trim() === ""
      ) {
        console.log("Please fill in all the fields!");
        return;
      } else {
        console.log("The values", Input);
        setMedicineList((prevState) => {
          return [...prevState, Input];
        });
        setList((prevState) => {
          Input.id = uuidv4();

          return [...prevState, Input];
        });

        setResponse((prevState) => {
          Input.id = uuidv4();

          return [...prevState, Input];
        });
        setInput({
          medicine: "",
          dosage: "",
          duration: "",
          instructions: "",
        });
        setShow(true);
      }
    } else {
      setShow(true);
    }
  };

  const deleteEntry = (index) => {
    console.log("the index", index);
    if (index > -1) {
      setResponse((prevState) => {
        let arr = prevState;
        let removed = [];
        arr.forEach((element, i) => {
          if (i !== index) {
            removed.push(element);
          }
        });

        return removed;
      });
      setMedicineList((prevState) => {
        let arr = prevState;
        let removed = [];
        arr.forEach((element, i) => {
          if (i !== index) {
            removed.push(element);
          }
        });
        return removed;
      });
    }
  };
  const language = useSelector((state) => state.language);
  return (
    <div className="pres-table">
      <div className="medicines-heading">{language.lang.medication}<span className="required-field-star"> *</span></div>
      <div>
        <button className="med-btn">{language.lang.medicine} {language.lang.ongoing}</button>
        <button className="btn-add" onClick={handleAddNewMedicine}>
          {language.lang["add-new"]} +
        </button>
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th scope="col">{language.lang.number}</th>
            <th scope="col">{language.lang.medicine}</th>
            <th scope="col">{language.lang.dosage}</th>
            <th scope="col">{language.lang.duration}</th>
            <th scope="col">{language.lang.instructions}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {response.map((element, index) => {
            return (
              <tr key={uuidv4()}>
                <td className="serial-num">{index + 1}</td>
                <td>
                  <Form.Control
                    type="text"
                    className="shadow-none"
                    value={element.medicine}
                    onChange={() => {}}
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    className="shadow-none color-dark"
                    value={element.dosage}
                    onChange={() => {}}
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    className="shadow-none color-dark"
                    value={element.duration}
                    onChange={() => {}}
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    className="shadow-none"
                    value={element.instructions}
                    onChange={() => {}}
                  />
                </td>
                <td>
                  <RiDeleteBin6Line
                    size={20}
                    className="del-icon"
                    onClick={() => deleteEntry(index)}
                  />
                </td>
              </tr>
            );
          })}

          {show ? (
            <tr>
              <td className="serial-num">{response.length + 1}</td>
              <td>
                <Form.Control
                  type="text"
                  className="shadow-none"
                  name="medicine"
                  value={Input.medicine}
                  onChange={handleOnInputChange}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  className="shadow-none color-dark"
                  name="dosage"
                  value={Input.dosage}
                  onChange={handleOnInputChange}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  className="shadow-none color-dark"
                  name="duration"
                  value={Input.duration}
                  onChange={handleOnInputChange}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  className="shadow-none"
                  name="instructions"
                  value={Input.instructions}
                  onChange={handleOnInputChange}
                />
              </td>
              <td>
                <span onClick={handleAddNewMedicine}>
                  <FaCheckCircle
                    style={{ color: "#8bab7e", cursor: "pointer" }}
                  />
                </span>
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </div>
  );
};

export default MedicineTable;
