import { useState } from "react";
import { useSelector } from "react-redux";
import { BiCheckCircle } from "react-icons/bi";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import AuthBase from "../../components/AuthBase";
import Loading from "../../components/Loading";
import { Button, Form, Input } from "antd";
import MMRadioButton from "../../components/MMRadioButton";
import {
  emailSigninStorageKey,
  emailSigninCorporateIDKey,
} from "../../utility/constants";
import { showErrorNotification } from "../../utility/Helpers";
import { completeEmailLinkSigninRoute } from "../../routes";

const LOADING_STATE = "LOADING_STATE";
const LINK_SENT_STATE = "LINK_SENT_STATE";
const INITIAL_STATE = "INITIAL_STATE";

const EmailSignin = () => {
  const language = useSelector((state) => state.language.lang);
  const [uiState, setUIState] = useState(INITIAL_STATE);
  const [isCorporate, setIsCorporate] = useState(false);

  const formValidationSuccess = async (values) => {

    // console.log("--- values", firebaseDomain);
    // const currentDomain = `${window.location.protocol}//${window.location.host}`;
    let url = `${window.location.protocol}//${window.location.host}${completeEmailLinkSigninRoute.path}?${emailSigninStorageKey}=${values.email}`;    
    if (values.coporateID) {
      url = `${url}&${emailSigninCorporateIDKey}=${values.coporateID}`;
    }
    const actionCodeSettings = {
      url,
      handleCodeInApp: true,
      // dynamicLinkDomain: dynamicLinkDomain,
      // iOS: {
      //   bundleId: iOSBundleID,
      // },
      // android: {
      //   packageName: androidPackageName,
      //   installApp: true,
      //   minimumVersion: "12",
      // },
    };
    setUIState(LOADING_STATE);
    try {
      const fireAuth = getAuth();
      await sendSignInLinkToEmail(fireAuth, values.email, actionCodeSettings);
      localStorage.setItem(emailSigninStorageKey, values.email);
      if (values.coporateID) {
        localStorage.setItem(emailSigninCorporateIDKey, values.coporateID);
      }
      setUIState(LINK_SENT_STATE);
    } catch (e) {
      console.log("--- EmailSignin error");
      console.log(e);
      setUIState(INITIAL_STATE);
      showErrorNotification();
    }
  };

  const onIsCorporateClicked = () => {
    setIsCorporate(!isCorporate);
  };

  let content;
  if (uiState === LINK_SENT_STATE) {
    content = (
      <>
        <h6 className="text-center">{language["sent"] ?? "Sent"}</h6>
        <div className="check-icon d-flex justify-content-center align-items-start">
          <BiCheckCircle />
        </div>
        {/* <p>
          {language["note-check-inbox-spam-folders-of-your-email"] ??
            "note-check-inbox-spam-folders-of-your-email"}
        </p> */}
      </>
    );
  } else if (uiState === LOADING_STATE) {
    content = (
      <>
        <p className="text-center">
          {language["sending-your-code"] ?? "Sending your code"}
        </p>
        <Loading className="mt-5" />
      </>
    );
  } else {
    content = (
      <Form onFinish={formValidationSuccess} size="large" layout="vertical">
        <h6>{language["sign-in-via-email"]}</h6>
        <Form.Item
          rules={[
            {
              type: "email",
              required: true,
              message: language["please-valid-email"] ?? "please-valid-email",
            },
          ]}
          name="email"
          className="email-form-item"
        >
          <Input
            type="email"
            className="border-circle-input"
            placeholder={language["email"] ?? "email"}
          />
        </Form.Item>
        <Form.Item>
          <div
            onClick={onIsCorporateClicked}
            className="corporate-check-box-container d-flex align-items-center"
          >
            <p>
              {language["are-you-from-corporate"] ?? "Are you from corporate ?"}
            </p>
            <MMRadioButton selected={isCorporate} />
          </div>
        </Form.Item>
        {isCorporate && (
          <Form.Item
            name="coporateID"
            rules={[
              {
                required: true,
                message:
                  language["please-enter-corporate-id"] ??
                  "Please enter your corporate ID",
              },
            ]}
          >
            <Input
              className="border-circle-input corporate-id-field"
              placeholder={language["corporate-id"] ?? "Corporate ID"}
            />
          </Form.Item>
        )}
        <p className="text-secondary text-center mb-4">
          {language["tap-next-to-get-login-link-via-email"] ??
            "tap-next-to-get-login-link-via-email"}
        </p>
        <div className="d-flex justify-content-center">
          <Button
            id="btn-email-signin"
            className="btn-next"
            type="primary"
            htmlType="submit"
          >
            {language.next ?? "Next"}
          </Button>
        </div>
      </Form>
    );
  }

  return (
    <AuthBase className="email-signin">
      <div id="email-sign-in-captcha"></div>
      {content}
    </AuthBase>
  );
};

export default EmailSignin;