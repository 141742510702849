import actions from "./actions";

const initState = {
  gallery : [],
  images : [],
  videos : [],
  single_features : [],
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.SET_GALLERY_MEDIA:
        if(!payload) return state;
        let img = [], vid=[];
        payload.map((a) => {
          if(a.type === "image/png") img.push({...a,selected : false});
          if(a.type === "video/mp4") vid.push({...a,selected : false});
        });
        console.log(img);
        return Object.assign({}, state, {
          ...state,
             gallery : [...payload],
             images : [...img],
             videos : [...vid],
          });
    case actions.SET_SINGLE_FEATURES:
      if(!payload) return state;
      console.log("SingleFeatures",payload)
      return Object.assign({}, state, {
          ...state,
          single_features : [...state.single_features, ...payload],
      });
    default:
      return state;
  }
};

export default reducer;
