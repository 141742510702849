import React from "react";
import queuePersonActiveImg from "../assets/images/1.png";
import queuePersonImg from "../assets/images/2.png";
import moment from "moment";
import { useSelector } from "react-redux";

const QueueCard = ({ appointmentDetails, startTime, endTime }) => {
  const language = useSelector((state) => state.language);
  const queue = [];
  for (let index = 0; index < appointmentDetails.inFrontOfYou; index++) {
    queue.push(
      <img
        key={index}
        className="queue-person"
        src={queuePersonImg}
        alt="queue-person"
      />
    );
  }
  return (
    <div className="queue-card mm-card">
      <h3 className="appointment-time">
        {`${moment(startTime).format("MMMM Do YYYY, h:mm a")} - ${moment(
          endTime
        ).format("h:mm a")}`}
      </h3>
      {/* <h4 className="user-msg">Sorry we are running 20 minutes late.</h4> */}
      <div className="queue">
        {queue}
        <img
          className="queue-person"
          src={queuePersonActiveImg}
          alt="queue-person-active"
        />
      </div>
      <h3 className="text queue-count">{appointmentDetails.inFrontOfYou}</h3>
      <h3 className="text patients">{language.lang.patients}</h3>
      <h3 className="text in-front-of-u">{language.lang["infront-of-u"]}</h3>
    </div>
  );
};

export default QueueCard;
