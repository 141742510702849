import moment from "moment";
import VerifyBrokenImage from "./VerifyBrokenImage";

const ReceivedMsgItem = ({ userImg, text, data, name }) => {
  // console.log("--- recieved -", name);
  return (
    <div className="bubble flex-column">
      <div className="d-flex">
        <div>
          <VerifyBrokenImage
            url={userImg}
            name={name}
            className="rounded-circle chat-img"
            alt="dP"
          />
          {/* <img src={"sd"} className="rounded-circle" style={{objectFit:'cover'}} alt="dP" width="40px" height="40px"/> */}
        </div>
        <div className="txt">
          {/*<p className="name">Benni</p>*/}
          <div className="message">{text}</div>
          <span className="timestamp">
            {moment(data.date_time).format("hh:mm a")}
          </span>
        </div>
      </div>
      <span className="sender-name">{name}</span>
    </div>
  );
};

export default ReceivedMsgItem;
