import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "./Loading";
import { setPatientDetail } from "../redux/clinic/actionCreators";
import moment from "moment";
import actions from "../redux/clinic/actions";
import { settings } from "../config";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  VerifyPatientDetailsRoute,
  VerifyPatientDetailsBasedOnRegionRoute,
} from "../routes";

const ClinicPatientAppointment = ({ el, i }) => {
  const history = useHistory();
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  //const appointment = useSelector((state) => state.clinic.appointmentDetail);
  //const appointment = useSelector((state) => state.clinic.appointmentDetail.appointment)
  const [appointment, setappointment] = useState({});

  const onAccept = async () => {
    /*setLoading(true);
      await dispatch(acceptWalkinRequestAction(el, i));
      setLoading(false);*/
    if (appointment.appointment_source === "scheduleAppointmentByRegion") {
      history.push(VerifyPatientDetailsBasedOnRegionRoute.path);
    } else {
      history.push(VerifyPatientDetailsRoute.path);
    }

    dispatch(setPatientDetail(appointment.patientInfo));
    dispatch({
      type: actions.SET_APPOINTMENT_DETAIL,
      payload: appointment,
    });
    // history.push(clinicVerifyPatientDetailsRoute.path, { ViewType: false });
    dispatch({
      type: actions.SET_VIEW_SCHEDULED_APPOINTMENTS,
      payload: false,
    });
  };

  // const onReject = () => {
  //   debugger;
  //   dispatch({
  //     type: actions.UPDATE_PENDING_APPOINTMENTS,
  //     payload: el.id,
  //   });
  // };

  useEffect(() => {
    getappointmentDetails();
  }, [el]);

  const getappointmentDetails = async () => {
    //el && el.id &&  dispatch(getAppointmentDetailsForClinic(el.id));
    if (el.id) {
      const res = await axios.get(
        `${settings.apiBaseURL}/getAppointmentDetailsForClinic?appointmentId=${el.id}`
      );
      setappointment(res.data.response[0]);
    }
  };

  return (
    <>
      {Object.keys(appointment).length !== 0 ? (
        <div className="ind-patient">
          <div className="patient-col1">
            <i className="fas fa-clock pending-clock mr-1"></i>
            <h6 className="mr-3 heading">
              {appointment && appointment.patientInfo.name}
            </h6>
          </div>
          <div className="patient-col1 light-heading">
            {(appointment &&
              moment(appointment.start_date_time).format("hh:mm a")) ||
              "NONE"}
          </div>
          <div className="patient-col1">
            <h6 className="mr-3 heading">
              {appointment &&
                appointment.practitionerInfo &&
                appointment.practitionerInfo.name}
            </h6>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <h6 className="mr-3 heading date">
              {appointment &&
                (parseInt(moment(appointment.start_date_time).format("DD")) ===
                parseInt(moment().format("DD"))
                  ? language.lang.today
                  : moment(appointment.start_date_time).format("DD-MM-YYYY"))}
            </h6>
          </div>
          {!loading && (
            <div className="d-flex ml-2 w-100 justify-content-center">
              <button className={"accept-btn "} onClick={onAccept}>
                {language.lang.accept}
              </button>
              <button className={"reject-btn "} onClick={onAccept}>
                {language.lang.reject}{" "}
              </button>
            </div>
          )}
          {loading && <Loading height="auto" width="64px" />}
        </div>
      ) : (
        <Loading height="auto" width="25px" />
      )}
    </>
  );
};

export default ClinicPatientAppointment;
