import { useEffect, useState } from "react";
import {
  getCuisines,
  getFoodAndRecipeCategories,
} from "../services/CustomerNetworkService";

const useInitCreateRecipeForm = () => {
  const [loading, setLoading] = useState(true);
  const [cuisines, setCuisines] = useState(null);
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    (async () => {
      const promisesResult = await Promise.all([
        getFoodAndRecipeCategories(),
        getCuisines(),
      ]);
      setCategories(promisesResult[0]);
      setCuisines(promisesResult[1]);
      setLoading(false);
    })();
  }, []);

  return [loading, categories, cuisines];
};

export default useInitCreateRecipeForm;
