import { useEffect, useState } from "react";
import Loading from "./Loading";
import WorkDayItemList from "./WorkDayItemList";
import moment from "moment";
import { useSelector } from "react-redux";

const DoctorInfoCard = ({
  slots,
  setSlotID,
  setPracID,
  setSelectedSlot,
  onSelectedSlotChanged,
  pracDetails,
  selectedSlot,
  slotnumber,
  setslotnumber,
}) => {
  const [slotMap, setSlotMap] = useState(new Map());
  const [getMonth, setMonth] = useState(moment().format("MMMM"));
   const language = useSelector((state) => state.language);

  useEffect(() => {
    const map1 = new Map();
    slots.map((el) => {
      if (map1.has(el.date)) {
        let arr = map1.get(el.date);
        arr.push({
          start_time: el.start_time,
          end_time: el.end_time,
          is_available: el.is_available,
          slot_id: el.slot_id,
          practionerId: el.practionerId,
        });
        map1.set(el.date, arr);
      } else {
        let arr = [];
        arr.push({
          start_time: el.start_time,
          end_time: el.end_time,
          is_available: el.is_available,
          slot_id: el.slot_id,
          practionerId: el.practionerId,
        });
        map1.set(el.date, arr);
      }
    });
    // console.log("map1", map1);
    setSlotMap(map1);
  }, [slots]);

  const onSelectedTimeSlotChanged = (selectedSlots) => {
    if (onSelectedSlotChanged) onSelectedSlotChanged(selectedSlots);
  };

  // const onChangeHandler = (e) =>{
  //   setSelectedSlot({});
  //   setMonth(e);
  // }

  // console.log("--- slotMap", slotMap);

  return (
    <div className="doctor-info-card">
      {/* <div className="mb-1">
                <Select style={{minWidth:'100px'}} value={getMonth} onChange={onChangeHandler}>
                {moment.months()/*.filter((a,i) => i+1 >= moment().format("MM") ).map(item => (
                   <Option key={item}>{item}</Option>
                 ))}   
                </Select>
      </div> */}
      <div className="work-days-list mt-3 hide-scrollbar">
        {slots.length === 0 ? (
          <div className="no-slots-available">{language.lang.noSlotsAvailable}</div>
        ) : (
          <WorkDayItemList
            slotMap={slotMap}
            setSlotID={setSlotID}
            setSelectedSlot={setSelectedSlot}
            setPracID={setPracID}
            onSelectedChanged={onSelectedTimeSlotChanged}
            selectedSlot={selectedSlot}
            slotnumber={slotnumber}
            setMonth={setMonth}
            getMonth={getMonth}
            setslotnumber={setslotnumber}
          />
        )}
      </div>
    </div>
  );
};

export default DoctorInfoCard;
