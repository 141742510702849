import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { dashboardRoute, dashboardAppointmentsRoute } from "../../routes";
import { useHistory } from "react-router-dom";

const PaymentCard = ({ Status, setpaystatus }) => {
  const completedTransaciton = useSelector(
    (state) => state.client.completedTransaciton
  );
  const history = useHistory();
  const [status, setstatus] = useState(Status);
  const navigateToUpcoming = () => {
    history.push(dashboardAppointmentsRoute.path, { pending: "Upcoming" });
  };
  const retryTransaction = () => {
    setpaystatus("");
  };
  const navigateToDashboard = () => {
    history.push(dashboardRoute.path);
  };
  return (
    <div className="row px-4 pb-5 doctor-dashboard-home payment-status-card p-0 d-flex justify-content-center align-items-center shadow-none bg-transparent border-0">
      <div
        className="mm-card doctor-dashboard-card"
        style={{ maxWidth: "60vw" }}
      >
        <div className="row">
          <div className="d-flex w-100 justify-content-start align-items-center">
            <div
              className={
                "d-flex justify-content-center align-items-center " +
                (status ? "success" : "failed")
              }
            >
              <MMRadioButton label="" selected={1} />
            </div>
            <div className={"status " + (status ? "success" : "failed")}>
              {status ? "Payment Successful" : "Payment Failed !"}
            </div>
          </div>
          <div className="d-flex justify-content-end w-100">
            <div
              className="success d-flex justify-content-end align-items-center sub-heading flex-column"
              style={{ width: "max-content" }}
            >
              {status
                ? "You Payment has been processed ! Details of the transaction included below"
                : "You Payment has been failed ! Details of the transaction included below"}{" "}
              <div className="border-line"></div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <table striped bordered hover>
            <thead>
              <tr height="50px">
                <th>Amount</th>
                <th>
                  <span>AED</span>
                  <span className="ml-2">{completedTransaciton?.amount}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr height="50px">
                <td>Transaction ID</td>
                <td>{completedTransaciton?.transaction_id}</td>
              </tr>
              <tr height="50px">
                <td>Payment Type</td>
                <td>Card</td>
              </tr>
              <tr height="50px">
                <td>Transaction Time</td>
                <td colSpan="2">
                  {moment(completedTransaciton?.created_date).format(
                    "DD-MMM-YYYY HH:mm:ss"
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className={
            "row w-100 d-flex align-items-center" +
            (status ? " justify-content-center" : " justify-content-end")
          }
        >
          {status ? (
            <button className="accept-btn ml-5" onClick={navigateToUpcoming}>
              View Appointment
            </button>
          ) : (
            <div
              className="w-100 d-flex justify-content-end align-items-center flex-wrap"
              style={{ gap: "20px" }}
            >
              <button className="accept-btn" onClick={retryTransaction}>
                Retry
              </button>
              <button className="accept-btn " onClick={navigateToDashboard}>
                Go to Dashboard
              </button>
              <button className="accept-btn ">Contact Support</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const MMRadioButton = ({ label, selected, classes, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`mm-radio-button ${classes ? classes : ""}`}
      style={{ gap: "5px" }}
    >
      <div className={`radio ${selected ? "selected" : ""} `}>
        {selected && <i className="fas fa-check text-light"></i>}
      </div>
      <span className="label">{label}</span>
    </div>
  );
};

export default PaymentCard;
