import { useSelector } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";
import { dashboardRoute } from "../routes";

const NoAuthOnlyRoute = ({ component, path, exact }) => {
  const location = useLocation();
  const userData = useSelector((state) => state.auth.userData);
  const isAuth = userData && Object.keys(userData).length > 0;
  const hasNextRoute = location.state && location.state.nextRoute;

  // console.log("--- NoAuthOnlyRoute", isAuth, hasNextRoute);

  if (isAuth && !hasNextRoute) {
    return <Redirect to={dashboardRoute.path} />;
  }
  return <Route component={component} path={path} exact={exact} />;
};

export default NoAuthOnlyRoute;
