/* eslint-disable jsx-a11y/alt-text */
import { useDispatch, useSelector } from "react-redux";
import { viewMatchesRoute } from "../../../routes";
import { useHistory } from "react-router-dom";
import actions from "../../../redux/fitnessBuddy/actions";
import { buddysLiveChatRoute } from "../../../routes";
import AvatarImg from "../../../assets/images/avatar.png";
import PartPopper from "../UtilityComponents/PartyPopperSVG";
import Config from "../../../config/index";
import TopMenu from "../../../components/CommonControls/TopMenu";

const MatchedProfile = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const languageType = useSelector(
    (state) => state.language.general.preferredlanguage
  );
  const userDetails = useSelector((state) => state.patientDetails.userDetails);
  const viewBuddyDetails = useSelector(
    (state) => state.patientDetails.currentBuddyDetails
  );
  const history = useHistory();
  const handleMessage = () => {
    dispatch({
      type: actions.UPDATE_MATCHED_USER,
      payload: {
        selectedUserID: {
          id: viewBuddyDetails.id,
          name: viewBuddyDetails.name,
          profile_photo_url: viewBuddyDetails.profilePhotoUrl,
        },
        selectedUserStatus: true,
      },
    });
    history.push(buddysLiveChatRoute.path);
  };

  const handleKeepSwipe = () => {
    dispatch({
      type: actions.UPDATE_VIEW_MATCHES_LOADING,
      payload: { isViewMatchesLoading: true },
    });
    history.push(viewMatchesRoute.path);
  };
  return (
    <div>
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.lang.settings}
      />
      <div className="container" style={{ paddingBottom: "10px" }}>
        <div className="matched-buddy-container">
          <div
            className={
              languageType === "ar"
                ? `match-heading right ${language.direction}`
                : `match-heading left ${language.direction}`
            }
          >
            {language.lang.itsMatch}
            <PartPopper />
          </div>
          <div className="match-details">
            <div
              className={
                languageType === "ar"
                  ? `match-text right ${language.direction}`
                  : `match-text left ${language.direction}`
              }
            >
              {language.lang.youAnd} {viewBuddyDetails.name}{" "}
              {language.lang.haveSameTastes}
            </div>
            <div className="buddy-pictures">
              <img
                className="user-pic"
                src={
                  !!userDetails.profilePhotoUrl
                    ? userDetails.profilePhotoUrl
                    : AvatarImg
                }
              />

              <div className="circle">
                <img
                  src={Config.fitnessBuddyIcon}
                  alt="match"
                  className="dashboard-icons-settings"
                />
              </div>
              <img
                className="buddy-pic"
                src={
                  !!viewBuddyDetails.profilePhotoUrl
                    ? viewBuddyDetails.profilePhotoUrl
                    : AvatarImg
                }
              />
            </div>
            <div
              className={
                languageType === "ar"
                  ? `btn-items right ${language.direction}`
                  : `btn-items left ${language.direction}`
              }
            >
              <button
                className="btn-message"
                onClick={() => {
                  handleMessage();
                }}
              >
                {language.lang.message} {viewBuddyDetails.name}
              </button>
            </div>

            <div className="swipe" onClick={handleKeepSwipe}>
              {language.lang.keepSwipingMatches}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MatchedProfile;
