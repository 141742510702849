import { useEffect, useState } from "react";
import Loading from "./Loading";
import { useHistory } from "react-router-dom";
import LiveSessionCard from "../components/LiveSessions/LiveSessionCard";
import { getCustomerLiveSessionsList } from "../services/LiveSessionsNetworkService";
import { showErrorNotification } from "../utility/Helpers";
import { dashboardSessionDetailRoute } from "../routes";
import { useSelector } from "react-redux";
import TopMenu from "../components/CommonControls/TopMenu";

const DashboardLiveSession = () => {
  const history = useHistory();
  const language = useSelector((state) => state.language);
  const [sessions, setSessions] = useState([]);
  const [pageLoading, setpageLoading] = useState(true);
  // const [showSessionRegisterSuccessModal, setShowSessionRegisterSuccessModal] =
  //   useState(false);
  // const search = useLocation().search;
  // const urlParams = new URLSearchParams(search);
  // const paymentIntent = urlParams.get("payment_intent");
  // const paymentIntentClientSecret = urlParams.get(
  //   "payment_intent_client_secret"
  // );
  // const paymentRedirectStatus = urlParams.get("redirect_status");
  // const showPaymentCompletedComponent =
  //   paymentIntent && paymentIntentClientSecret && paymentRedirectStatus;
  // const paidSessionHostID = urlParams.get("hostID");

  useEffect(() => {
    // if (showPaymentCompletedComponent) {
    //   return handleShowPaymentCompletedComponent();
    // }
    getSessions();
  }, []); // showPaymentCompletedComponent

  // const handleShowPaymentCompletedComponent = () => {
  //   if (paymentRedirectStatus === "succeeded" && paidSessionHostID) {
  //     setShowSessionRegisterSuccessModal(true);
  //   }
  // };

  // const handleModalClose = () => {
  //   setShowSessionRegisterSuccessModal(false);
  // };

  const getSessions = async () => {
    const response = await getCustomerLiveSessionsList({});
    setpageLoading(false);
    if (response == null) {
      return showErrorNotification();
    }
    setSessions(response);
  };

  // TODO- Wanna handle the Live session Details Pages
  // const routeRecipe = (recipe) => {
  //   history.push(dashboardSessionDetailRoute.path, {
  //     session: recipe,
  //   });
  // };

  const handleScroll = (event) => {
    // setpageLoading(true);
    // const target = event.target;
    // if (target.scrollHeight - target.scrollTop === target.clientHeight) {
    //   setoffset(offset + limit);
    //   FetchLiveSessions(offset + limit);
    //   if (limit === 15) setlimit(5);
    // }
  };

  const handleSessionClick = (session) => {
    history.push(dashboardSessionDetailRoute.path, {
      session: session,
    });
  };

  let content;
  if (pageLoading) {
    content = (
      <div className="col-12" style={{ height: "10rem" }}>
        <Loading />
      </div>
    );
  } else if (sessions == null) {
    content = <h5>Something went wrong.</h5>;
  } else if (sessions.length === 0) {
    content = (
      <div style={{ minHeight: "20rem" }}>
        <div className="container">
          <div className="row">
            <div className="card text-left not-found-card mb-2">
              <div className="card-body">
                <h5 className="card-title">No Events found !!</h5>
                <p className="card-text">
                  Please contact live support for further assitance
                </p>
                <a href="#" className="btn btn-primary">
                  Live Support
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    content = (
      <div
        className="mt-3 pb-0 grid-layout justify-content-center"
        style={{ width: "100vw", gap: "20px", maxHeight: "800px" }}
        onScroll={handleScroll}
      >
        {sessions.map((item) => {
          return (
            <div
              className="position-relative d-flex justify-content-between"
              style={{ maxHeight: "200px", cursor: "pointer" }}
              key={item.id}
              onClick={() => handleSessionClick(item)}
            >
              <LiveSessionCard recipe={item} />
            </div>
          );
        })}
        {pageLoading && (
          <div className="d-flex justify-content-center align-items-center">
            <Loading />
          </div>
        )}
      </div>
    );
  }

  return (
    <section className="dashboard-live-session row ml-1">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.lang.events}
      />
      {content}
    </section>
  );
};

export default DashboardLiveSession;
