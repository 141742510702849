import { useHistory } from "react-router-dom";
import CirclesBackground from "./CirclesBase";
import TopMenuBackButton from "../../components/CommonControls/GenericInnerPageComponents/TopMenuBackButton";
import { dashboardRoute } from "../../routes";

const LandingBase = (props) => {
  const { children, className } = props;
  const history = useHistory();
  const closePressed = () => {
    history.push(dashboardRoute.path);
  };

  return (
    <CirclesBackground>
      <div className={`layout-container ${className ? className : ""}`}>
        <div className="d-flex justify-content-start">
          <button onClick={closePressed} className="btn back-button-landing">
            <TopMenuBackButton />
          </button>
        </div>
        <div className="d-flex justify-content-center pb-2 mt-1 mb-1">
          <div className="landing-base-layout-card px-4 px-lg-5 col-10 col-sm-10 col-md-6 col-lg-5 col-xl-4">
            {children}
            {/* <div className="" style={{ position: "relative" }}></div> */}
          </div>
        </div>
      </div>
    </CirclesBackground>
  );
};

export default LandingBase;
