import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setsidebar } from "../redux/user/actionsCreators";
import { useWindowDimension } from "./WindowsSize";

const LogoSideButton = () => {
  const dispatch = useDispatch();
  const [width, height] = useWindowDimension();
  const Side_Bar = useSelector((state) => state.user.side_bar);
  const toggleCollapse = () => {
    dispatch(setsidebar(!Side_Bar));
  };
  useEffect(() => {
    setIsCollapsed(width < 2000);
  }, [width]);
  const [isCollapsed, setIsCollapsed] = useState(width < 2000);
  return (
    <>
      {isCollapsed && (
        <div
          className="d-flex justify-content-start flex-column menuToggle"
          style={{ marginTop: ".5rem" }}
          onClick={toggleCollapse}
        >
          <i className="fas fa-bars"></i>
        </div>
      )}
      {/* <img className="home-logo" src={config.logoizi} alt="IZI"/>*/}
    </>
  );
};

export default LogoSideButton;
