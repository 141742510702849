import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getAdditionalUserInfo, getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { emailSigninStorageKey, emailSigninCorporateIDKey } from "../utility/constants";
import useHandleFirebaseUserLogin from "./useHandleFirebaseUserLogin";
import { showErrorNotification } from "../utility/Helpers";
import { dashboardRoute } from "../routes";

const useCompleteEmailLinkSignin = () => {
  const history = useHistory();
  const handleFirebaseUserLogin = useHandleFirebaseUserLogin();
  const [loading, setLoading] = useState(false);
  const [isEmailSigninLink, setIsEmailSigninLink] = useState(true);
  useEffect(() => {
    const fireAuth = getAuth();
    if (!isSignInWithEmailLink(fireAuth, window.location.href)) {
      setIsEmailSigninLink(false);
      setLoading(false);
      // history.replace(dashboardRoute.path);
      return;
    }
    const params = new URL(document.location).searchParams;
    // console.log(
    //   "email link sign in param",
    //   params.get(emailSigninStorageKey),
    //   params
    // );
    const email = localStorage.getItem(emailSigninStorageKey) ?? params.get(emailSigninStorageKey);
    if (!email) {
      setIsEmailSigninLink(false);
      setLoading(false);
      // history.replace(dashboardRoute.path);
      return;
    }
    (async () => {
      setLoading(true);
      try {
        const corporateID = localStorage.getItem(emailSigninCorporateIDKey) ?? params.get(emailSigninCorporateIDKey);
        const authResult = await signInWithEmailLink(fireAuth, email);
        console.log("authresultis",authResult)
        localStorage.removeItem(emailSigninStorageKey);
        localStorage.removeItem(emailSigninCorporateIDKey);
        const authData = await handleFirebaseUserLogin({
          user: authResult.user,
          userCredential: authResult,
          corporateID,
          email,
        });
        const additionalUserInfo = getAdditionalUserInfo(authResult);
        setLoading(false);
        if (!authData) {
          history.replace(dashboardRoute.path);
          return showErrorNotification();
        }
        if (additionalUserInfo?.isNewUser) {
          // debugger;
          history.replace("/signup-username");
          // history.replace(signupUserNameRoute.path);
        } else {
          // debugger;
          history.replace(dashboardRoute.path);
        }
      } catch (e) {
        console.log("--- email sign in link error");
        console.log(e);
        setLoading(false);
        showErrorNotification();
        history.replace(dashboardRoute.path);
      }
    })();
  }, [handleFirebaseUserLogin, history]);

  return [loading, isEmailSigninLink];
};

export default useCompleteEmailLinkSignin;
