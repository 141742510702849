import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useEffect, useState } from "react";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { useDispatch, useSelector } from "react-redux";
import { BsChevronDown } from "react-icons/bs";
import FieldFormat from "./FieldFormat";
import { setCalendarLoading } from "../redux/practitioner/actionCreators";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  setPractitionerCalendarEvents,
  setPractitionerViewType,
} from "../redux/practitioner/actionCreators";
import Loading from "./Loading";
import { getScheduleTypes } from "../services/CustomerNetworkService";
import { setPractitionerSelectedSlotAndViewSlotAction } from "../redux/practitioner/actionCreators";

const localizer = momentLocalizer(moment);

const MyCalendarPractitioner = () => {
  const dispatch = useDispatch();
const [bcView, setBCView] = useState("day");
  const ViewType = useSelector(
    (state) => state.practitioner.calendar.calendarView
  );
  const Events = useSelector((state) => state.practitioner.calendar.events);
  const loading = useSelector((state) => state.practitioner.calendar.loading);
  const now = new Date();

  const handleSelectedEvent = (event) => {
    // dispatch({
    //   type: practitionerActions.SET_PRACTITIONER_VIEW_SLOT,
    //   payload: true,
    // });
    dispatch(
      setPractitionerSelectedSlotAndViewSlotAction({
        viewSlot: true,
        selectedSlot: event,
      })
    );
  };

  const getStartDate = (now) => {
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - now.getDay()
    );
  };

  const getEndDate = (now) => {
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      getStartDate(now).getDate() + 6
    );
  };

  // const setDocEvent = async () => {
  //   dispatch(
  //     setPractitionerCalendarEvents(
  //       userData.id,
  //       moment(getStartDate(now)).format("YYYY-MM-DD"),
  //       moment(getEndDate(now)).format("YYYY-MM-DD")
  //     )
  //   );

  //   dispatch({
  //     type: clinicActions.SET_SELECTED_DOCTOR_INFO,
  //     payload: {},
  //   });
  // };

  // useEffect(() => {
  //   setDocEvent();
  // }, []);

  return (
    <div>
      {loading && <Loading />}
      <Calendar
        localizer={localizer}
        className={`${loading ? "d-none" : ""}`}
        events={Events}
        startAccessor="start"
        endAccessor="end"
        components={{
          toolbar: CustomToolbar,
          event: PractitionerCalendarCustomEvent,
        }}
        // formats={formats}
        defaultDate={moment().toDate()}
        popup={true}
        style={{ minHeight: 500 }}
        view={ViewType}
        onView={setBCView}
        onSelectEvent={(e) => handleSelectedEvent(e)}
        dateFormat="h t"
        timeslots={1}
        step={15}
        min={new Date(now.getFullYear(), now.getMonth(), now.getDate(), 9)}
        max={new Date(now.getFullYear(), now.getMonth(), now.getDate(), 20)}
      />
    </div>
  );
};

const CustomToolbar = (toolbar) => {
  const dispatch = useDispatch();
  // const calendarEvents = useSelector(
  //   (state) => state.practitioner.calendar.events
  // );
  const lastCalendarUpdateDate = useSelector(
    (state) => state.patient.calendar.lastUpdateDate
  );
  const ViewType = useSelector(
    (state) => state.practitioner.calendar.calendarView
  );
  const userData = useSelector((state) => state.auth.userData);
  const loadingCalendar = useSelector(
    (state) => state.practitioner.calendar.loading
  );
  const [ViewTypeOptions, setViewTypeOptions] = useState([
    { name: "Day", value: "day" },
    { name: "Week", value: "week" },
  ]);
  const [selectedSchedule, setSelectedSchedule] = useState(0);
  const [scheduleTypes, setScheduleTypes] = useState([]);
  const [startDate, setstartDate] = useState(toolbar.date);
  const [endDate, setendDate] = useState(toolbar.date);
  const [viewDate, setviewDate] = useState(moment().format("DD MMM YYYY"));
  const now = toolbar.date;

  // useEffect(() => {
  //   if (PractitionerDetails.length === 0) {
  //     getDetails();
  //   } else {
  //     setDocEvent();
  //   }
  // }, [PractitionerDetails]);

  useEffect(() => {
    setDocEvent({});
  }, [lastCalendarUpdateDate]);

  useEffect(() => {
    if (!selectedSchedule || selectedSchedule === 0) return;
    setDocEvent();
  }, [selectedSchedule]);

  useEffect(() => {
    if (ViewType === "day") {
      setviewDate(moment(toolbar.date).format("DD MMM YYYY"));
    } else {
      setviewDate(
        `${moment(getStartDate(now)).format("DD")}-${moment(
          getEndDate(now)
        ).format("DD MMM YYYY")}`
      );
    }
  }, [ViewType]);

  useEffect(() => {
    if (ViewType === "week") {
      setstartDate(getStartDate(now));
      setendDate(getEndDate(now));
    } else {
      setstartDate(now);
      setendDate(now);
    }
  }, [toolbar]);

  // useEffect(() => {
  //   if (needToCall) {
  //     setDocEvent();
  //     dispatch({
  //       type: clinicactions.SET_NEED_TO_CALL_CALENDAR,
  //       payload: false,
  //     });
  //   }
  // }, [needToCall]);

  const getAndSetScheduleTypes = async () => {
    const types = await getScheduleTypes();
    setScheduleTypes(types);
    if (types.length > 0) {
      setSelectedSchedule(types[0].id);
    }
    return types[0];
  };

  const addMonths = (date, months) => {
    const d = date.getDate();
    date.setMonth(date.getMonth() + months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    console.log(date);
    return date;
  };

  const addWeeks = (date, weeks) => {
    date.setDate(date.getDate() + 7 * weeks);
    return date;
  };

  const addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    console.log(date);
    return date;
  };

  const goToBack = () => {
    if (ViewType === "month") {
      toolbar.onNavigate("prev", addMonths(toolbar.date, -1));
    } else if (ViewType === "week") {
      toolbar.onNavigate("prev", addWeeks(toolbar.date, -1));
    } else {
      toolbar.onNavigate("prev", addDays(toolbar.date, -1));
    }
    console.log("--- calendar goToBack");
    setDocEvent();
  };

  const goToNext = () => {
    if (ViewType === "month") {
      toolbar.onNavigate("next", addMonths(toolbar.date, +1));
    } else if (ViewType === "week") {
      toolbar.onNavigate("next", addWeeks(toolbar.date, +1));
    } else {
      toolbar.onNavigate("next", addDays(toolbar.date, +1));
    }
    if (ViewType === "day") {
      setviewDate(moment(toolbar.date).format("DD MMM YYYY"));
    } else {
      setviewDate(
        moment(getStartDate(now)).format("DD") +
          "-" +
          moment(getEndDate(now)).format("DD MMM YYYY")
      );
    }
    console.log("--- calendar goToNext");
    setDocEvent();
  };

  const goToToday = () => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.date.setDate(now.getDate());
    toolbar.onNavigate("current");
    setviewDate(moment(toolbar.date).format("DD MMM YYYY"));
    console.log("--- calendar goToToday");
    setDocEvent();
  };

  const setViewType = (e) => {
    dispatch(setPractitionerViewType(e));
  };

  // useEffect(() => {
  //   dispatch({
  //     type:clinicActions.SET_CLINIC_CALENDAR_SETTINGS,
  //     payload:{
  //       startDate: moment(startDate).format("YYYY-MM-DD"),
  //       endDate:moment(endDate).format("YYYY-MM-DD"),
  //       pracID: selectedDoc.value,
  //       roleId: 9
  //     }
  //   });
  // },[startDate]);

  const setDocEvent = async () => {
    if (loadingCalendar) return;
    // console.log("--- setDocEvent");
    let firstScheduleType;
    if (!scheduleTypes || scheduleTypes.length === 0) {
      dispatch(setCalendarLoading({ loading: true }));
      firstScheduleType = await getAndSetScheduleTypes();
    } else {
      firstScheduleType = {};
    }
    dispatch(
      setPractitionerCalendarEvents(
        userData?.id,
        moment(getStartDate(now)).format("YYYY-MM-DD"),
        moment(getEndDate(now)).format("YYYY-MM-DD"),
        firstScheduleType.id || selectedSchedule
      )
    );
  };

  const handleScheduleChanged = (value) => {
    setSelectedSchedule(value);
  };

  // const getDetails = () => {
  //   dispatch(setPractitionerDetails(userData?.id));
  // };

  return (
    <div
      className="w-100 d-flex custom-toolbar justify-content-center align-items-center flex-wrap visiblility-none pt-0 pt-sm-3 "
      style={{ gap: 20 }}
    >
      <div
        className="d-flex w-100 align-items-center justify-content-center flex-wrap flex-lg-nowrap maxContentWidth mb-3"
        style={{ gap: "20px" }}
      >
        {scheduleTypes && scheduleTypes.length > 0 && (
          <FieldFormat
            element={
              <SelectSearch
                value={selectedSchedule}
                placeholder="Select Schedule Type"
                options={scheduleTypes.map((item) => ({
                  name: item.name,
                  value: item.id,
                }))}
                filterOptions={fuzzySearch}
                search={true}
                onChange={handleScheduleChanged}
              />
            }
            icon={<BsChevronDown color={"#18a0fb"} size={25} />}
          />
        )}
        <FieldFormat
          element={
            <SelectSearch
              value={{
                name: ViewType.toUpperCase(),
                value: ViewType.toLowerCase(),
              }}
              placeholder="select view type"
              options={ViewTypeOptions}
              filterOptions={fuzzySearch}
              search={true}
              onChange={setViewType}
            />
          }
          icon={<BsChevronDown color={"#18a0fb"} size={25} />}
        />
        <div className="d-flex controls col-4">
          <div className="arrow" onClick={goToBack}>
            <i className="fas fa-chevron-left"></i>
          </div>
          <div onClick={goToToday}>Today</div>
          <div>{/*moment(toolbar.date).format("DD MMM YYYY")*/ viewDate}</div>
          <div className="arrow" onClick={goToNext}>
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>
      </div>
      <div
        className="w-100 justify-content-center align-items-center mt-2 status pb-2 d-flex flex-wrap"
        style={{ gap: "20px" }}
      >
        {/* <div className="status-card pending">Pending</div> */}
        <div className="status-card ongoing">Ongoing</div>
        <div className="status-card completed">Completed</div>
        <div className="status-card upcoming">Upcoming</div>
        {/* <div className="status-card inprogress">In Progress</div> */}
        {/* <div className="status-card hold">On Hold</div> */}
        <div className="status-card cancelled">Cancelled</div>
        <div className="status-card failed">Failed</div>
        <div className="status-card skiped">Skipped/Missed</div>
      </div>
    </div>
  );
};

const PractitionerCalendarCustomEvent = (events) => {
  const { event } = events;
  return (
    <div
      className="practitioner-calendar-custom-event calendar-event-border d-flex justify-content-center align-items-center h-100"
      style={{ backgroundColor: event.color }}
    >
      <div className="doc-name">{event.PractitionerName}</div>
    </div>
  );
};

const getStartDate = (now) => {
  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - now.getDay()
  );
};

const getEndDate = (now) => {
  return new Date(
    now.getFullYear(),
    now.getMonth(),
    getStartDate(now).getDate() + 6
  );
};

export default MyCalendarPractitioner;
