import React from "react";
import { IconContext } from "react-icons";
import { VscFileMedia } from "react-icons/vsc";

const FileMsgItem = ({ type }) => {
  return (
    <div className={type==="receiver"? "recieved-msg--block": "sent-msg--block"}>
      <div className="file-msg"><IconContext.Provider value={{ color: "#ee2c34" }}>
          <VscFileMedia size={30} />
        </IconContext.Provider></div>
    </div>
  );
};

export default FileMsgItem;
