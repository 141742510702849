import { useEffect, useReducer, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReceivedMsgItem from "../ReceivedMsgItem";
import SentMsgItem from "../SentMsgItem";
import { Form } from "react-bootstrap";
import { IoMdSend } from "react-icons/io";
import reducer, { initState } from "./reducer";
import { getAgoraRTMToken } from "../../services/AgoraNetworkService";
import { showErrorNotification } from "../../utility/Helpers";
import {
  IoChevronDownCircleSharp,
  IoChevronUpCircleSharp,
} from "react-icons/io5";
import {
  setLoadingAction,
  setRTMChannelAction,
  setRTMClientAction,
} from "./actions";
import { agoraAppId } from "../../utility/constants";
import AgoraRTM from "agora-rtm-sdk";
import Loading from "../Loading";
import {
  fetchChatMessages,
  saveChatMessage,
} from "../../services/ChatNetworkService";
import { updateAttendeesMapAction } from "../../redux/liveSession/actionCreators";

const LiveSessionChatCard = ({
  channelID,
  practitionerDetails,
  expandChatMessagesList,
  toggleExpanChatMessagesList,
}) => {
  const reduxDispatch = useDispatch();
  const language = useSelector((state) => state.language.lang);
  const [state, dispatch] = useReducer(reducer, initState);
  const [messageField, setMessageField] = useState("");
  const [messagesList, setMessagesList] = useState([]);
  const messagesListRef = useRef(null);
  const currentUserData = useSelector((state) => state.auth.userData);
  const sessionAttendeesMap = useSelector(
    (state) => state.liveSession.attendeesMap
  );
  const hostDetails = practitionerDetails || {};

  // console.log({ sessionAttendeesMap });

  // useEffect(() => {
  //   setHostDetails(practitionerDetails);
  // }, [practitionerDetails]);

  useEffect(() => {
    Promise.all([initRTMChannel(), getPreviousChatMessages()]).finally(() => {
      dispatch(setLoadingAction({ loading: false }));
    });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messagesList]);

  useEffect(() => {
    return () => {
      if (state.channel && Object.values(state.channel).length > 0) {
        state.channel.leave();
      }
    };
  }, [state.channel]);

  useEffect(() => {
    return () => {
      if (state.client && Object.values(state.client).length > 0) {
        state.client.logout();
      }
    };
  }, [state.client]);

  const getPreviousChatMessages = async () => {
    const previousMessages = await fetchChatMessages(channelID);
    const attendeesList = previousMessages
      .filter((item) => item.userDetail)
      .map((item) => {
        return {
          user_id: item.user_id,
          id: item.user_id,
          detail: item.userDetail,
        };
      });
    const attendeesMap = {};
    attendeesList.forEach((element) => {
      attendeesMap[element.id] = element;
    });
    reduxDispatch(updateAttendeesMapAction({ attendeesMap }));
    const messageItems = previousMessages.map((item) => {
      const isSent = item.user_id === currentUserData.id;
      // const attendee = getAttendeeWithID({ id: item.user_id });
      return createNewMessageObject({
        isSent,
        senderID: item.user_id,
        text: item.message,
        date_time: item.date_time,
        // imageURL: isSent
        //   ? currentUserData.profile_photo_url
        //   : attendee.profile_photo_url,
        // senderName: isSent
        //   ? `${currentUserData.name} ${currentUserData.surname || ""}`.trim()
        //   : `${(attendee.detail || {}).name || ""} ${
        //       (attendee.detail || {}).surname || ""
        //     }`.trim(),
      });
    });
    setMessagesList((prevState) => {
      return [...messageItems, ...prevState];
    });
  };

  const initRTMChannel = async () => {
    try {
      const token = await getAgoraRTMToken({
        userID: currentUserData.id,
        channelID: channelID,
      });
      if (!token) {
        showErrorNotification();
        dispatch(setLoadingAction({ loading: false }));
        return;
      }
      const rtmClient = AgoraRTM.createInstance(agoraAppId);
      dispatch(setRTMClientAction({ client: rtmClient }));
      // Display connection state changes
      // rtmClient.on("ConnectionStateChanged", function (state, reason) {
      //   console.log("--- RTM ConnectionStateChanged", state, reason);
      // });
      const rtmChannel = rtmClient.createChannel(channelID);
      dispatch(setRTMChannelAction({ channel: rtmChannel }));
      rtmChannel.on("ChannelMessage", onChannelMessage);
      // rtmChannel.on("MemberJoined", function (memberId) {
      //   console.log("--- MemberJoined");
      //   console.log(memberId);
      // });
      // rtmChannel.on("MemberLeft", function (memberId) {
      //   console.log("--- MemberLeft");
      //   console.log(memberId);
      // });
      // rtmChannel.on("MemberCountUpdated", (e) => {
      //   console.log("--- MemberCountUpdated", e);
      // });
      const options = {
        uid: currentUserData.id,
        token: token,
      };
      await rtmClient.login(options);
      await rtmChannel.join();
    } catch (e) {
      console.log("--- rtm error");
      console.log(e);
      showErrorNotification();
    }
  };

  const onChannelMessage = (message, memberId) => {
    console.log("--- onChannelMessage", message, memberId);
    addNewMessage({
      senderID: memberId,
      text: message.text,
    });
  };

  const getAttendeeWithID = ({ id }) => {
    // console.log("--- getAttendeeWithID", id, sessionAttendeesMap);
    let senderAttendee;
    if (sessionAttendeesMap[id] && sessionAttendeesMap[id].detail) {
      senderAttendee = sessionAttendeesMap[id];
    } else if (id === hostDetails.id) {
      senderAttendee = {
        detail: {
          profile_photo_url: hostDetails.profile_photo_url,
          name: hostDetails.name,
          surname: hostDetails.surname,
        },
      };
    } else {
      senderAttendee = {
        detail: { name: "", surname: "", profile_photo_url: "" },
      };
    }
    return senderAttendee;
  };

  // console.log("--- currentUserData", currentUserData);

  const addNewMessage = ({ senderID, text, isSent = false }) => {
    // const senderAttendee = getAttendeeWithID({ id: senderID });
    // let imageURL;
    // let senderName;
    // if (isSent) {
    //   imageURL = currentUserData.profile_photo_url;
    //   senderName = `${currentUserData.name} ${
    //     currentUserData.surname || ""
    //   }`.trim();
    // } else {
    //   const attendeeDetails = senderAttendee.detail || {};
    //   imageURL = attendeeDetails.profile_photo_url;
    //   senderName = `${attendeeDetails.name || ""} ${
    //     attendeeDetails.surname || ""
    //   }`.trim();
    // }
    setMessagesList((prevState) => {
      const newMessage = createNewMessageObject({
        senderID: senderID,
        text: text,
        isSent: isSent,
        date_time: Date.now(),
        // imageURL: imageURL,
        // senderName: senderName,
      });
      //  {
      //   id: `${senderID}-${Date.now().toString()}`,
      //   text: text,
      //   senderID: senderID,
      //   date_time: Date.now(),
      //   isSent: isSent,
      //   imageURL: imageURL,
      //   senderName: senderName,
      // };
      return [...prevState, newMessage];
    });
  };

  const createNewMessageObject = ({
    senderID,
    text,
    isSent,
    imageURL,
    senderName,
    dateTime = Date.now(),
  }) => {
    return {
      id: `${senderID}-${dateTime.toString()}`,
      text: text,
      senderID: senderID,
      date_time: dateTime,
      isSent: isSent,
      imageURL: imageURL,
      senderName: senderName,
    };
  };

  const handleMessageSend = () => {
    if (state.loading) return;
    state.channel.sendMessage({ text: messageField });
    addNewMessage({
      text: messageField,
      senderID: currentUserData.id,
      isSent: true,
    });
    saveChatMessage({
      channelID: channelID,
      messageText: messageField,
      type: "text",
    });
    setMessageField("");
  };

  const scrollToBottom = () => {
    if (messagesListRef.current) {
      messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight;
    }
  };

  // doc-patient-chat overflow-auto
  return (
    <div
      className={`card doc-patient-chat p-3 ${
        expandChatMessagesList ? "expand" : ""
      }`}
    >
      <div className="d-flex justify-content-between align-items-center w-100 mb-auto">
        <p className="text-secondary mb-0">{language.groupMessages}</p>
        <button
          onClick={toggleExpanChatMessagesList}
          className="btn toggle-list-btn"
        >
          {expandChatMessagesList ? (
            <IoChevronUpCircleSharp />
          ) : (
            <IoChevronDownCircleSharp />
          )}
        </button>
      </div>
      {expandChatMessagesList && (
        <>
          {state.loading ? (
            <Loading />
          ) : (
            <div className="messages-list" ref={messagesListRef}>
              {messagesList.map((item, i) => {
                const key = `${item.id}-${i}`;
                if (item.isSent) {
                  return <SentMsgItem key={key} text={item.text} />;
                }
                const attendee = getAttendeeWithID({ id: item.senderID });
                const attendeeDetails = attendee.detail || {};
                return (
                  <ReceivedMsgItem
                    key={key}
                    data={item}
                    text={item.text}
                    name={attendeeDetails.name}
                    userImg={attendeeDetails.profile_photo_url}
                  />
                );
              })}
            </div>
          )}
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleMessageSend();
            }}
            className="sent-msg--input"
          >
            <Form.Group className="form-group">
              <Form.Control
                type="text"
                className="shadow-none"
                value={messageField}
                onChange={(e) => setMessageField(e.target.value)}
              />
              <IoMdSend
                className="post-msg"
                size={20}
                onClick={handleMessageSend}
              />
            </Form.Group>
          </Form>
        </>
      )}
    </div>
  );
};

export default LiveSessionChatCard;
