import { Link } from "react-router-dom";
import { dashboardRoute } from "../routes";

const RouteNotFound = () => {
  return (
    <section className="route-not-found">
      <h3>Path Not Found</h3>
      {/* <SettingsButton /> */}
      <Link to={dashboardRoute.path}>
        <button className="btn btn-primary">Back to Home</button>
      </Link>
    </section>
  );
};

export default RouteNotFound;
