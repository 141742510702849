import Config from "../config/index";
const AppStoreLinkButton = ({ className }) => {
  return (
    <div
      className={`app-store-link-btn ${className ? className : ""}`}
      href="https://apps.apple.com/us/app/izi-health/id1576505842"
    >
      <a
        rel="noreferrer"
        target="_blank"
        href="https://apps.apple.com/us/app/izi-health/id1576505842"
      >
        <img alt="App Store" className="FooterStoreButtons" src={Config.appstoreIcon} />
      </a>
    </div>
  );
};

export default AppStoreLinkButton;
