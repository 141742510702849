import { useState } from "react";
import { useWindowDimension } from "../WindowsSize";
import TopDashboardMenuUserProfile from "./GenericDashboardComponents/TopDashboardMenuUserProfile";
import TopDashboardMenuSettings from "./GenericDashboardComponents/TopDashboardMenuSettings";
import TopDashboardMenuHamburger from "./GenericDashboardComponents/TopDashboardMenuHamburger";
import TopDashboardMenuNotification from "./GenericDashboardComponents/TopDashboardMenuNotification";
import RegionDropdown from "../RegionDropdown";
import LanguagesDropdown from "../LanguagesDropdown";

const TopMenuDashboard = ({
  shouldHideProfileButton,
  shouldHideNotificationButton,
  shouldHideLogoButton,
  shouldHideSettingsButton,
  shouldHideGeneralSettingsButton,
  pageTitle,
  className,
}) => {
  const [width, height] = useWindowDimension();
  const [isMobile, setIsMobile] = useState(width < 2000);
  const [isExpanded, setIsExpanded] = useState(width > 2000);

  return (
    <div
      className={`d-flex flex-nowrap flex-row ${className ? className : ""}`}
    >
      <div
        className={`order-1 mr-auto p-2 justify-content-start ${
          shouldHideLogoButton ? "hidden-top-menuitem" : ""
        }`}
      >
        <TopDashboardMenuHamburger />
      </div>
      {/* <div className="mr-auto order-2 p-2">
        <h4 className="Page-title-custom">{pageTitle}</h4>
      </div> */}
      {/* <div
        className={`order-3 d-flex justify-content-end ${
          shouldHideGeneralSettingsButton ? "hidden-top-menuitem" : ""
        }`}
      >
        <GeneralSettingButton className="ml-auto" />
      </div> */}
      <div className="order-3 d-flex justify-content-end">
        <div className="topMenuRegion">
          <RegionDropdown
            isExpanded={isExpanded}
            isMobile={isMobile}
            showLabel={false}
          />
        </div>
      </div>
      <div className="order-4 d-flex justify-content-end">
        <div className="topMenuLanguage">
          <LanguagesDropdown
            isExpanded={isExpanded}
            isMobile={isMobile}
            showLabel={false}
          />
        </div>
      </div>
      <div
        className={`order-5 d-flex justify-content-end ${
          shouldHideNotificationButton ? "hidden-top-menuitem" : ""
        }`}
      >
        <TopDashboardMenuNotification className="ml-auto" />
      </div>
      <div
        className={`order-6 d-flex justify-content-end ${
          shouldHideGeneralSettingsButton ? "hidden-top-menuitem" : ""
        }`}
      >
        <TopDashboardMenuSettings className="ml-auto" />
      </div>
      <div
        className={`order-7 d-flex justify-content-end ${
          shouldHideProfileButton ? "hidden-top-menuitem" : ""
        }`}
      >
        <TopDashboardMenuUserProfile className="ml-auto" />
      </div>
      {/* <div className="order-8 d-flex justify-content-end" >
        <Whatsapp className="ml-auto"/>
      </div> */}
    </div>
  );
};

export default TopMenuDashboard;
