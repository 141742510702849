import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import { useSelector } from "react-redux";
import WorkDayItemList from "./WorkDayItemList";
import { Select } from "antd";
import WorkDayItems from "./WorkDayItems";
const { Option } = Select;
const DoctornewInfoCard = ({
  slots,
  setSlotID,
  setPracID,
  setSelectedSlot,
  onSelectedSlotChanged,
  pracDetails,
  bookNowPressed,
  viewAll,
  setslotnumber,
  slotnumber
}) => { 
  const [loading, setLoading] = useState(false);
  const [slotMap, setSlotMap] = useState(new Map());
  const language = useSelector((state) => state.language);
  useEffect(() => {
    setLoading(true);
    const map1 = new Map();
    slots.map((el) => {
      if (map1.has(el.date)) {
        let arr = map1.get(el.date);
        arr.push({
          start_time: el.start_time,
          end_time: el.end_time,
          is_available: el.is_available,
          slot_id: el.slot_id,
          practionerId: el.practionerId,
        });
        map1.set(el.date, arr);
      } else {
        let arr = [];
        arr.push({
          start_time: el.start_time,
          end_time: el.end_time,
          is_available: el.is_available,
          slot_id: el.slot_id,
          practionerId: el.practionerId,
        });
        map1.set(el.date, arr);
      }
    });
    // console.log("map1", map1);
    setSlotMap(map1);
    setLoading(false);
  }, [slots]);

  const onSelectedTimeSlotChanged = (selectedSlots) => {
    if (onSelectedSlotChanged) onSelectedSlotChanged(selectedSlots);
  };
if(loading){
  return (<Loading />);
}
  return (
    <div className="doctor-info-card ">
      <div className="work-days-list hide-scrollbar">
        {/* {(slots.length === 0 && !loading)? (
       <div>No Slots Available</div>
        ) : (
         
        )} */}
         <WorkDayItems
            slotMap={slotMap}
            setSlotID={setSlotID}
            setSelectedSlot={setSelectedSlot}
            setPracID={setPracID}
            onSelectedChanged={onSelectedTimeSlotChanged}
            bookNowPressed={bookNowPressed}
            viewAll={viewAll}
            setslotnumber={setslotnumber}
            slotnumber={slotnumber}
            slots={slots}
          />
      </div>
    </div>
  );
};

export default DoctornewInfoCard;
