import { useSelector } from "react-redux";
import TopMenu from "../../CommonControls/TopMenu";

const BookDieticianAppointment = () => {
  const language = useSelector((state) => state.language.lang);

  return (
    <div className="book-dietician-appointment">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={true}
        pageTitle={language["pay-as-you-go"] ?? "pay-as-you-go"}
      />
    </div>
  );
};

export default BookDieticianAppointment;
