import React from "react";
import { useSelector } from "react-redux";
import img from "../assets/images/MealPlan1.png";

const MostPopularCard = ({ videoCard, className, recipe }) => {
  const language = useSelector((state) => state.language);
  return (
    <div className="content-card h-list-item  h-100">
      <img
        className="item-img"
        src={recipe ? recipe.image_url : img}
        alt="Popular Item"
      />
      <div className="d-flex">
        <div className="content w-100">
          <p className="category-name category-name-yel">Arabian</p>
          <h6 className="title">{recipe ? recipe.name : "Breakfast"}</h6>
          <div className="description">
            {recipe
              ? recipe.recipe.substring(0, 70) + "..."
              : "Feel the taste of food. Fully Automated and Custom Meal Plans"}
          </div>
          <div
            className="d-flex justify-content-end w-100"
            style={{ height: "fit-content" }}
          >
            <button type="button" className="btn btn-primary">
              {language.lang.view}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostPopularCard;
