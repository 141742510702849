import TopMenu from "./CommonControls/TopMenu";
import {useSelector } from "react-redux";
const TermsAndConditions = () => {
  const language = useSelector((state) => state.language);
  return (
    <section className="container-fluid mb-4 px-4">
      <div className="row">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.lang.termsAndConditions}
      />
      </div>
      <div className="d-flex justify-content-between px-4 m-0 align-items-center mobile-px-none">
        <div className="d-flex">
          <div className="footer-container mb-4 glossy-style">
            <div className="row">
              <h3>{language.lang.termsAndConditions}</h3>
            </div>
            <div className="row">
              <p>
              {language.lang.tcPara1}
              </p>
            </div>
            <div className="row">
              <h4>{language.lang.tcPara2}</h4>
              <div className="row">
                <h5>
                {language.lang.tcPara3}
                </h5>
                <ul style={{ marginLeft: "3rem",listStyleType: 'square' }}>
                  <li>
                  {language.lang.tcPara4}
                  </li>
                  <li>
                  {language.lang.tcPara5}
                  </li>
                  <li>
                  {language.lang.tcPara6}
                  </li>
                  <li>
                  {language.lang.tcPara7}
                  </li>
                  <li>
                  {language.lang.tcPara8}
                  </li>
                  <ul style={{ marginLeft: "3rem",listStyleType: 'circle' }}>
                  <li>
                  {language.lang.tcPara9}
                  </li>
                  <li>
                  {language.lang.tcPara10}
                  </li>
                  <li>
                  {language.lang.tcPara11}
                  </li>
                  <li>
                  {language.lang.tcPara12}
                  </li>
                  </ul>
                </ul>
              </div>
            </div>
            <div className="row">
              <p>
              {language.lang.tcPara13}
              </p>
              <p>
              {language.lang.tcPara14}
              </p>
              <p>
              {language.lang.tcPara15}
              </p>
              <p>
              {language.lang.tcPara16}
              </p>
              <p>
              {language.lang.tcPara17}
              </p>
            </div>

            <div className="row">
              <h3>{language.lang.tcPara18}</h3>
              <p>
              {language.lang.tcPara19}
              </p>

              <div className="row">
                <h4>{language.lang.tcPara20}</h4>
                <p>
                {language.lang.tcPara21}
                </p>
                <p>
                {language.lang.tcPara22}
                </p>
                <p>
                {language.lang.tcPara23}
                </p>
              </div>
              <div className="row">
                <p>
                {language.lang.tcPara24}
                </p>
              </div>

              <div className="row">
                <h4>{language.lang.tcPara25}</h4>
                <p>
                  {language.lang.tcPara26}
                </p>
                <p>
                {language.lang.tcPara27}
                </p>
                <p>
                {language.lang.tcPara28}
                </p>
                <p>
                {language.lang.tcPara29}
                </p>
              </div>
              <div className="row">
                <h4>{language.lang.tcPara30}</h4>
                <p>
                {language.lang.tcPara32}
                </p>
                <p>
                {language.lang.tcPara33}
                </p>
                <p>
                {language.lang.tcPara34}
                </p>
                <p>
                {language.lang.tcPara35}
                </p>
                <p>
                {language.lang.tcPara36}
                </p>
                <p>
                {language.lang.tcPara37}
                </p>
                <p>
                {language.lang.tcPara37}
                </p>
                <p>
                {language.lang.tcPara37}
                </p>
              </div>
              <div className="row">
                <h4>{language.lang.tcPara38}</h4>
                <p>
                {language.lang.tcPara39}
                </p>
                <p>
                {language.lang.tcPara40}
                </p>
                <h4>{language.lang.tcPara41}</h4>
                <p>
                {language.lang.tcPara42}
                </p>
              </div>
              <div className="row">
                <h4>{language.lang.tcPara43}</h4>
                <p>
                {language.lang.tcPara44}
                </p>
                <p>
                {language.lang.tcPara45}
                </p>
                <p>
                {language.lang.tcPara46}
                </p>
                <p>
                {language.lang.tcPara47}
                </p>
                <p>
                {language.lang.tcPara48}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditions;
