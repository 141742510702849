import actions from "./actions";
import {
  isLoggedInStorageKey,
  tokenStorageKey,
  refreshTokenStorageKey,
  tokenExpiryTimeStorageKey,
  userDataStorageKey,
  paymentAmountsKey,
} from "../../utility/constants";

const { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_ERR, LOGOUT_BEGIN, LOGOUT_SUCCESS, LOGOUT_ERR, SET_SOCKET_ID } = actions;

const initState = {
  login: localStorage.getItem(isLoggedInStorageKey) === "true",
  loading: false,
  error: null,
  socketID: null,
  paymentAmounts:
    localStorage.getItem(paymentAmountsKey) &&
    localStorage.getItem(paymentAmountsKey) !== undefined &&
    localStorage.getItem(paymentAmountsKey) !== "undefined"
      ? JSON.parse(localStorage.getItem(paymentAmountsKey))
      : null,
  accessToken: localStorage.getItem(tokenStorageKey),
  refreshToken: localStorage.getItem(refreshTokenStorageKey),
  tokenExpiryTime: localStorage.getItem(tokenExpiryTimeStorageKey)
    ? parseInt(localStorage.getItem(tokenExpiryTimeStorageKey))
    : null,
  userData:
    localStorage.getItem(userDataStorageKey) &&
    localStorage.getItem(userDataStorageKey) !== undefined &&
    localStorage.getItem(userDataStorageKey) !== "undefined"
      ? JSON.parse(localStorage.getItem(userDataStorageKey))
      : null,
};

const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case actions.SET_USER_DATA:
      return Object.assign({}, state, {
        userData: action.payload,
      });
    case actions.UPDATED_USER_LANGUAGE:
      return Object.assign({}, state, {
        userData: {
          ...state.userData,
          preferred_language: action.payload,
        },
      });
    case actions.UPDATE_USER_REGION:
      return Object.assign({}, state, {
        userData: {
          ...state.userData,
          region_id: action.payload,
        },
      });
    case SET_SOCKET_ID:
      return Object.assign({}, state, {
        socketID: action.payload,
      });
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      let login = data ? true : false;
      return {
        ...state,
        login,
        loading: false,
        ...data,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        login: false,
        accessToken: null,
        refreshToken: null,
        tokenExpiryTime: null,
        userData: null,
      });
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default AuthReducer;
