import { useReducer, useEffect } from "react";
import reducer, { initialState } from "./reducer";
import {
  startRecordingAction,
  stopRecordingAction,
  deleteRecordAction,
} from "./actions";
import { useSelector } from "react-redux";

const VoiceRecordField = ({ onChange, valueRef }) => {
  const language = useSelector((state) => state.language);
  const [state, dispatch] = useReducer(
    reducer,
    Object.assign({}, initialState, {
      onRecordFileChange: onChange,
    })
  );

  useEffect(() => {
    return () => {
      if (state.audioMediaStream) {
        state.audioMediaStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [state.audioMediaStream]);

  const recordPressed = async () => {
    if (state.isRecording) {
      state.audioMediaStream.getTracks().forEach((track) => track.stop());
      state.audioMediaRecorder.stop();
      dispatch(stopRecordingAction());
    } else {
      dispatch(await startRecordingAction(dispatch, state));
    }
  };

  const deletePressed = () => {
    valueRef.current = null;
    dispatch(deleteRecordAction());
  };

  let audio;
  if (state.recordBlob) {
    const audioSrc = window.URL.createObjectURL(state.recordBlob);
    audio = (
      <div className="audio-player">
        <audio src={audioSrc} controls />
        <button onClick={deletePressed} className="btn">
          <i className="far fa-times-square"></i>
        </button>
      </div>
    );
  }

  // console.log(
  //   "---- call on change",
  //   state.recordBlob,
  //   state.needToCallOnChange
  // );
  // if (state.recordBlob && onChange && state.needToCallOnChange) {
  //   console.log("---- call on change");
  //   onChange(state.recordBlob);
  //   dispatch(needToCallOnChangeFalseAction());
  // }

  let iconButton;
  let text;
  if (state.isRecording) {
    iconButton = (
      <button onClick={recordPressed} type="button" className="btn btn-light">
        <i className="far fa-stop-circle"></i>
      </button>
    );
    text = (
      <p className="voice-record-hint label">{language.lang.recording}...</p>
    );
  } else if (!state.recordBlob) {
    iconButton = (
      <button onClick={recordPressed} type="button" className="btn btn-light">
        <i className="far fa-microphone"></i>
      </button>
    );
    text = (
      <p className="voice-record-hint label px-2">
        {language.lang["press-here-to-record"]}
      </p>
    );
  }

  return (
    <div className="voice-record-field">
      {iconButton} {text}
      {audio}
    </div>
  );
};

export default VoiceRecordField;
