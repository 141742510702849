import videoImg from "../assets/images/MealPlan2.png";
const MostPopularReminderCards = ({reminder, start, end, when}) =>{
    return(
        <div>
        <div className="most-upcoming-img position-relative">
        <img src={videoImg} alt="img-thumb" />
        <div className="d-flex flex-column justify-content-center align-items-center position-absolute absolute-center" style={{whiteSpace: 'nowrap',color: '#fff'}}>
            <span>{reminder}</span>
            <span>{start}-{end}</span>
        </div>
        </div>
        <div className="text-center pt-3" style={{color:'#f6bd27',fontSize:'14px',fontWeight:'500'}}>{when}</div>
      </div>
    );
}
export default MostPopularReminderCards;