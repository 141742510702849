import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchOldNotificationsAction, ShowOldNotificationsAction } from "../redux/user/actionsCreators";

const EndSideMenu = ({ children, isOpened, onClosePressed, className, title, bottom }) => {
  const [show, setshow] = useState(false);
  const { userData } = useSelector((state) => state.auth);
  const old_notifications = useSelector((state) => state.user.old_notifications);
  const dispatch = useDispatch();
  const oldNotifications = async (choice) => {
    if (choice && old_notifications && old_notifications.length === 0) dispatch(FetchOldNotificationsAction());
    dispatch(ShowOldNotificationsAction(choice));
    setshow(choice);
  };
  const language = useSelector((state) => state.language);
  return (
    <div className={`${className ? className + " " : " "}side-menu ${isOpened ? "open" : ""} p-3`}>
      <div className={"d-flex justify-content-between" + `${language.direction}`}>
        <span style={{ padding: "20px 30px 0 30px" }}>
          <h4>{title === "All Notifications" ? language.lang.all_notification : title}</h4>
        </span>
        <button type="button" className="close-btn btn" onClick={onClosePressed}>
          <i className="fas fa-times fa-lg"></i>
        </button>
      </div>
      {show && title === "All Notifications" && (
        <div className="history-notifications" onClick={() => oldNotifications(false)}>
          <div>
            <i className="far fa-bell"></i>
          </div>
          <div>{language.lang.show_recent_notifications}</div>
        </div>
      )}
      {!show && title === "All Notifications" && (
        <div className="history-notifications" onClick={() => oldNotifications(true)}>
          <div>
            <i className="fas fa-history"></i>
          </div>
          <div>{language.lang["show-old-notifications"]}</div>
        </div>
      )}
      <div className="content">{children}</div>
      {bottom}
    </div>
  );
};

export default EndSideMenu;
