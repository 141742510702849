import TopMenuNotification from './GenericInnerPageComponents/TopMenuNotification';
import TopMenuHamburger from './GenericInnerPageComponents/TopMenuHamburger';
import TopMenuUserProfile from './GenericInnerPageComponents/TopMenuUserProfile';
import TopMenuBackButton from './GenericInnerPageComponents/TopMenuBackButton';
const TopMenu = ({
shouldHideBackButton,
shouldHideProfileButton,
shouldHideNotificationButton,
shouldHideLogoButton,
pageTitle,
hideCustomclass
}) => {
  return (
    <div className={`d-flex flex-nowrap ${ hideCustomclass ? "" : "cust-left-pos" }`}>
                <div className={`order-1 p-2 ${ shouldHideLogoButton ? "hidden-top-menuitem" : "" }`}>
                  <TopMenuHamburger />
                </div>
                <div className={`order-2 p-2 ${ shouldHideBackButton ? "hidden-top-menuitem" : "" }`}>
                  <TopMenuBackButton />
                </div>
                <div className="mr-auto order-3 p-2">
                  <h4 className="top-title-custom">{pageTitle}</h4>
                  {/* <div className="title-border"></div> */}
                </div>
                <div className={`order-4 d-flex justify-content-end ${ shouldHideNotificationButton ? "hidden-top-menuitem" : "" }`}>
                  <TopMenuNotification className="ml-auto" />
                </div>
                <div className={`order-5 d-flex justify-content-end ${ shouldHideProfileButton ? "hidden-top-menuitem" : "" }`}>
                  <TopMenuUserProfile />
                </div>
    </div>
  );
};

export default TopMenu;
