import React from "react";
import DoctorCard from "../components/DoctorCard";
import imgSrc from "../assets/images/doc-profile-img.jpeg";
import JoinConsultationDoctorInfoCard from "../components/JoinConsultationDoctorInfoCard";
import BackButton from "../components/BackButton";
import PatinetAppointmentDetailsCard from "../components/PatinetAppointmentDetailsCard";
import NotificationsButton from "../components/NotificationsButton";
import ProfileCircleButton from "../components/ProfileCircleButton";
import { useSelector } from "react-redux";

const ClientJoinForConsultation = () => {
  const language = useSelector((state) => state.language);
  return (
    <div className="join-for-consultation px-4">
      <section className="row">
        <div className="col-1">
          <BackButton />
        </div>
        <div className="col-10 d-flex align-items-center">
          <h5 className="page-title">{language.lang.join} {language.lang.for_consultation}</h5>
          <NotificationsButton className="ml-auto" />
          <ProfileCircleButton />
        </div>
      </section>
      <seciton className="row">
        <div className="col-12">
          <DoctorCard
            imgSrc={imgSrc}
            rate="3"
            middle="Therapist"
            bottom="Some Hospital"
            name="Dr. Tanisha Khan"
          />
        </div>
      </seciton>
      <section className="row mt-4">
        <div className="col-12 col-lg-7">
          <JoinConsultationDoctorInfoCard />
        </div>
        <div className="col-12 col-lg-5">
          <PatinetAppointmentDetailsCard appointment={{}} />
          <button className="btn btn-primary join-call-btn mt-4">
            {language.lang.join} Call With Doctor
          </button>
        </div>
      </section>
    </div>
  );
};

export default ClientJoinForConsultation;
