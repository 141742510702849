import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select } from "antd";
import config from "../config";
import SideMenuField from "./SideMenuField";
import { countryFlagsMap } from "./SideMenu";
import { setSelectedRegionAction } from "../redux/language/actionCreators";
import { updateUserSettings } from "../services/CustomerNetworkService";
import { updateUserRegionAction } from "../redux/authenitcation/actionCreator";
import { BsChevronDown } from "react-icons/bs";

const RegionDropdown = ({ isExpanded, showLabel, isMobile }) => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const currentUser = useSelector((state) => state.auth.userData);
  const regionsList = language.general.regions || [];
  const isAuth = currentUser && Object.values(currentUser).length > 0;
  const localStorageRegion = localStorage.getItem("selectedRegionID");

  let selectedRegion;
  if (isAuth) {
    selectedRegion = currentUser.region_id;
  } else if (localStorageRegion) {
    selectedRegion = Number(localStorageRegion);
  } else if (regionsList.length > 0) {
    selectedRegion = regionsList[0].id;
  } else {
    selectedRegion = null;
  }

  useEffect(() => {
    if (!selectedRegion) return;
    // console.log("--- effect selectedRegion", selectedRegion);
    dispatch(setSelectedRegionAction({ regionID: selectedRegion }));
  }, [selectedRegion, currentUser, isAuth, localStorageRegion, dispatch]);

  useEffect(() => {
    dispatch(setSelectedRegionAction({ regionID: selectedRegion }));
  }, [selectedRegion, dispatch]);

  const handleRegionChange = (value) => {
    if (isAuth) {
      handleAuthRegionChange(value);
    } else {
      handleUnauthRegionChange(value);
    }
    dispatch(setSelectedRegionAction({ regionID: value }));
  };

  const handleAuthRegionChange = (regionID) => {
    updateUserSettings({
      regionID,
      language: currentUser.preferred_language,
      theme: currentUser.theme,
    });
    dispatch(updateUserRegionAction({ regionID, currentUser }));
  };

  const handleUnauthRegionChange = (regionID) => {
    localStorage.setItem("selectedRegionID", regionID);
  };

  if (regionsList.length === 0) return <div></div>;

  // console.log("--- region", language.regionID, selectedRegion);
  // console.log({ language });

  return (
    <SideMenuField
      name={showLabel ? language.lang.region : ""}
      isExpanded={isExpanded || isMobile}
      field={
        <Select
          name="region"
          value={language.regionID}
          onChange={handleRegionChange}
          className={`region-dropdown ${isExpanded || isMobile ? "expanded" : ""}`}
          showArrow={isExpanded || isMobile}
          suffixIcon={<BsChevronDown />}
          style={{ borderRadius: "50px" }}
        >
          {regionsList.map((region) => {
            return (
              <Select.Option key={region.id} value={region.id}>
                {/* Varun Mishra, Critical River, updated code for showing flags of all countries */}
                {/* <img className="region-flag-img" alt={region.name} src={countryFlagsMap.get(region.id)} />{" "} */}
                <img className="region-flag-img" alt={region.regionCode} src={`${config.flagIcon}/${region.regionCode.toLowerCase()}.svg`} />{" "}
                {(isExpanded || isMobile) && region.name}
              </Select.Option>
            );
          })}
        </Select>
      }
    />
  );
};

export default RegionDropdown;
