import { Button } from "antd";
import { useSelector } from "react-redux";
import usePriceBasedOnRegion from "../../../customHooks/usePriceBasedOnRegion";
import { bookDieticianAppointmentRoute, stripePaymentCheckoutRoute } from "../../../routes";
import {
  subscriptionPaymentModule,
  subscriptionValidityUnitText,
  subscriptionValidityUnitTwoLetterShort,
} from "../../../utility/constants";

const subscriptionDurationChangedEvery = {
  M: "1-month",
  Y: "12-month",
  W: "1-week",
  D: "1-day",
};

const SubscriptionPriceCard = ({ priceModel, history }) => {
  const language = useSelector((state) => state.language.lang);
  const getPriceBasedOnRegion = usePriceBasedOnRegion();
  const [firstPriceModelCurrency, firstPriceModelPrice] = getPriceBasedOnRegion(priceModel?.multiCurrencyPrices ?? {});

  // console.log({ priceModel });

  const onSelectPressed = () => {
    const viewButtonText = "go-to-meal-plan";
    const viewButtonRedirectLink = bookDieticianAppointmentRoute.path;
    const amount = firstPriceModelPrice;
    const currency = firstPriceModelCurrency;
    const paymentRedirectURL = `${window.location.protocol}//${window.location.host}?amount=${amount}&currency=${currency}&buttonText=${viewButtonText}&viewButtonRedirectLink=${viewButtonRedirectLink}`;
    history.replace(stripePaymentCheckoutRoute.path, {
      moduleID: priceModel.id,
      currency,
      amount,
      moduleType: subscriptionPaymentModule,
      redirectURL: paymentRedirectURL,
    });
  };

  return (
    <div className="subscription-price-card">
      <div className="text-center">
        {language[subscriptionValidityUnitText[priceModel.validity_unit]] ??
          subscriptionValidityUnitText[priceModel.validity_unit]}
      </div>
      <div className="line"></div>
      <div className="d-flex justify-content-center price-validity">
        <div className="price">{`${firstPriceModelCurrency} ${firstPriceModelPrice} `}</div>
        <div className="validity">{` / ${
          language[subscriptionValidityUnitTwoLetterShort[priceModel.validity_unit]] ??
          subscriptionValidityUnitTwoLetterShort[priceModel.validity_unit]
        }`}</div>
      </div>
      {priceModel.discount && (
        <div className="discount text-center text-secondary">{`${priceModel.discount} % ${
          language["discount"] ?? "discount"
        }`}</div>
      )}
      <div className="d-flex justify-content-center mt-auto">
        <Button onClick={onSelectPressed} type="primary">
          {language["select"] ?? "select"}
        </Button>
      </div>
      <div className="line"></div>
      <div className="d-flex justify-content-center">
        <div className="price">{`${firstPriceModelCurrency} ${firstPriceModelPrice} ${
          language["charged-every"] ?? "charged-every"
        } ${
          language[subscriptionDurationChangedEvery[priceModel.validity_unit]] ??
          subscriptionDurationChangedEvery[priceModel.validity_unit]
        }`}</div>
      </div>
    </div>
  );
};

export default SubscriptionPriceCard;
