import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const SideMenuLink = ({
  icon,
  label,
  active,
  toggleCollapse,
  className,
  to,
  onClick,
  exact,
}) => {
  // const history = useHistory();
  // const match = useRouteMatch();
  // const [currentPath, setCurrentPath] = useState(match.url);

  // useEffect(() => {
  //   const unlisten = history.listen((location) => {
  //     if (currentPath !== location.pathname) {
  //       setCurrentPath(location.pathname);
  //     }
  //   });
  //   return unlisten;
  // }, [history, currentPath]);
  const language = useSelector((state) => state.language);
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (toggleCollapse) {
      toggleCollapse();
    }
  };

  let classes = `nav-link`;
  if (className) {
    classes += ` ${className}`;
  }
  if (active) {
    classes += ` active`;
  }
  if (to) {
    return (
      <NavLink exact={exact} to={to || "#"} className={classes+language.direction} style={{gap:'10px'}}>
        <div className="nav-icon-container">{icon}</div>
        <span className="nav-label">{label}</span>
      </NavLink>
    );
  }
  return (
    <button className={`btn ${classes}`} onClick={handleClick}>
      <SideMenuLinkContent icon={icon} label={label} />
    </button>
  );
};

const SideMenuLinkContent = ({ icon, label }) => {
  const language = useSelector((state) => state.language);
  return (
    <>
      <div className="nav-icon-container">{icon}</div>
      <span className="nav-label">{label}</span>
    </>
  );
};

export default SideMenuLink;
