import axios from "axios";
import { createRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { settings } from "../config";
import { dashboardCardRecipeRoute } from "../routes";
import Loading from "./Loading";
import MostPopularCard from "./MostPopularCard";
import { useDispatch, useSelector } from "react-redux";
import { setMealPlans, setOffsetMeals } from "../redux/patient/actionCreators";
import actions from '../redux/patient/actions'
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
const AllRecipe = ({sid}) => {
    const [allrecipe,setallrecipe] = useState([]);
    const [recipe, setrecipe] = useState([]);
    const [ pageLoading, setpageLoading] = useState(false);
    const offset = useSelector((state) => state.patient.mealoffset);
    const [flag, setflag] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const Meals = useSelector((state) => state.patient.meal_plans);
      useEffect(() => {
        Meals.length === 0 && dispatch(setMealPlans(offset,10));
        Meals.length === 0 && dispatch(setOffsetMeals(offset+10));
      },[])
      useEffect(() => {setpageLoading(false)},[Meals])
      const routeRecipe = (recipe) =>{
        history.push(dashboardCardRecipeRoute.path, {
          recipe: recipe
        });
        let find = Meals.findIndex(a => a.id === recipe.id);
        console.log(find)
        if(find>0){
          let updateList = Meals.filter(a => a.id !== recipe.id);
          updateList.push(updateList.shift());
          updateList.unshift(recipe);
          dispatch({
            type: actions.UPDATE_MEAL_PLANS,
            payload: updateList,
          });
        }
      }
      const handleScroll = (event) => {
        setpageLoading(true);
        const target = event.target;
        if(target.scrollWidth- target.scrollLeft === target.clientWidth){
            dispatch(setMealPlans(offset,5));
            dispatch(setOffsetMeals(offset+5));
        }
      }
      const mealRef = createRef();
      const scrollTo = (direction) => {
        const target = mealRef.current;
        if (direction == "left") {
        if(mealRef){
          mealRef.current.scrollLeft -= target.clientWidth;
          console.log("Scroll left");
        }
        } else {
          if(mealRef){
            mealRef.current.scrollLeft += target.clientWidth;
            console.log("Scroll Right");
            setpageLoading(true);
 
            if(target.scrollWidth- target.scrollLeft === target.clientWidth){
                dispatch(setMealPlans(offset,5));
                dispatch(setOffsetMeals(offset+5));
            }
          }
        }
      };
    return(
      <div className="position-relative overflow-hidden w-100">
           {Meals.length > 0 ? <div
                className="d-flex most-popular-scroll_new custom-scroll pb-4 h-100 overflow-hidden"
                style={{ minwidth: "100% !important",gap:'20px', width:'100% !important' }}
                onScroll={handleScroll}
                ref={mealRef}
            >
                {Meals &&
                Meals.filter((a)=>{ return(recipe.id !=a.id)}).map((item, i) => {
                    return (
                    <div
                        className="d-flex justify-content-around"
                        style={{ maxHeight: "200px",cursor: "pointer",minWidth:'25rem'}}
                        key={i}
                        onClick={() => routeRecipe(item)}
                    >
                        <MostPopularCard recipe={item} />
                    </div>
                    );
                })} 
                   {pageLoading &&  <div className="d-flex justify-content-center align-items-center"><Loading  /></div>}
          </div> : <Loading width={100} height={100} />}
              {Meals.length > 0 && <>
            <div className="leftScroll scrollBtn" onClick={() => scrollTo("left")}>
                  <FiChevronLeft />
                </div>
                <div className="rightScroll scrollBtn" onClick={() => scrollTo("right")}>
                  <FiChevronRight />
                </div>
          </>}
          </div>
    )
}
export default AllRecipe;