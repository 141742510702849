import React, { useState } from "react";
import AuthBase from "../../components/AuthBase";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { dashboardRoute, signUpRoute, forgotpasswordRoute } from "../../routes";
import { isValidEmail, isValidPassword } from "../../utility/Helpers";
import { invalidCorporateCredentialsErrorMsg, invalidUserMsg } from "../../utility/constants";
import Loading from "../../components/Loading";
import { login } from "../../redux/authenitcation/actionCreator";

const CorporateSignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    corporateID: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [corporateIDError, setCorporateIDError] = useState("");

  const { error, loading, login: isLoggedIn } = useSelector((state) => state.auth);
  const language = useSelector((state) => state.language);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid()) return;
    // setLoading(true);
    try {
      const { email, password, corporateID } = formData;
      dispatch(login(email, password, corporateID))
        .then(() => {
          if (isLoggedIn) {
            history.push(dashboardRoute.path);
          }
        })
        .catch((e) => {
          console.log("--- login error");
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const isValid = () => {
    const emailErr = isValidEmail(formData.email);
    const passwordErr = isValidPassword(formData.password);
    const corporateIDErr = isValidCorporateID(formData.isValidCorporateID);
    setEmailError(emailErr && invalidUserMsg);
    setPasswordError(passwordErr && invalidUserMsg);
    setCorporateIDError(corporateIDErr);
    const valid = !emailErr && !passwordErr && !corporateIDErr;
    return valid;
  };

  const isValidCorporateID = () => {
    if (!formData.corporateID) {
      return "Please enter your corporate ID";
    }
    return null;
  };

  const createAccountPressed = () => {
    history.push(signUpRoute.path);
  };

  const showPasswordClicked = () => {
    setShowPassword(!showPassword);
  };

  if (isLoggedIn) {
    return <Redirect to={dashboardRoute} />;
  }

  return (
    <AuthBase>
      <h4 className="welcome-text d-flex justify-content-center">{language.lang["welcome-to-izi"]}</h4>
      <div style={{ height: "20px" }} />
      <div style={{ height: "40px" }} />
      <form onSubmit={handleSubmit}>
        <div className="sign-in-form d-flex flex-column ">
          <input
            value={formData.corporateID}
            onChange={handleChange}
            name="corporateID"
            className="neumorphic-input"
            placeholder={language.lang["corporate-id"]}
          />
          {corporateIDError && <li className="mt-3 mb-0 text-danger">{corporateIDError}</li>}
          <div style={{ height: "30px" }} />
          <input value={formData.email} onChange={handleChange} name="email" className="neumorphic-input" placeholder={language.lang.email} />
          {emailError && <li className="mt-3 mb-0 text-danger">{emailError}</li>}
          <div style={{ height: "30px" }} />
          <div className="password-field-container">
            <input
              value={formData.password}
              onChange={handleChange}
              name="password"
              className="neumorphic-input"
              placeholder={language.lang.password}
              type={showPassword ? "text" : "password"}
            />
            <button onClick={showPasswordClicked} className="btn show-password-btn" type="button">
              {showPassword ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>}
            </button>
          </div>
          {passwordError && <li className="mt-3 mb-0 text-danger">{passwordError}</li>}
        </div>
        {error && (
          <div style={{ height: "60px" }} className="d-flex justify-content-center align-items-center text-danger">
            {invalidCorporateCredentialsErrorMsg}
          </div>
        )}
        <div className="d-flex justify-content-center mt-4">
          {loading ? (
            <Loading />
          ) : (
            <button className="btn btn-primary" type="submit" style={{ width: "50%" }}>
              {language.lang["sign-in"]}
            </button>
          )}
        </div>
      </form>
      <div style={{ height: "10px" }} />
      <div className="btns">
        <div className="d-flex justify-content-center align-items-center">
          <span className="m-0 signup-header-text">{language.lang["new-here-?"]}</span>
          <button type="submit" className="btn" onClick={createAccountPressed}>
            <span className="text-logo-color">{language.lang.createAnAccount}</span>
          </button>
        </div>
        <div className="d-flex justify-content-center">
          <button
            type="submit"
            className="btn text-logo-color"
            onClick={() => {
              history.push(forgotpasswordRoute.path);
            }}
          >
            <span className="text-logo-color">{language.lang["forgot-password"]}</span>
          </button>
        </div>
        {/* <div className="d-flex justify-content-center align-items-center">
          <button type="submit" className="btn">
            <span className="text-logo-color">Corporate Login</span>
          </button>
        </div> */}
      </div>
    </AuthBase>
  );
};

export default CorporateSignIn;
