import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { clientSignInRoute, dashboardRoute } from "../routes";

const MustHaveRoleRoute = ({
  component,
  path,
  roles,
  exact,
  redirectState,
  render,
}) => {
  const userData = useSelector((state) => state.auth.userData);
  const isAuth = userData && Object.keys(userData || {}).length > 0;

  if (!isAuth) {
    return (
      <Redirect
        to={{ pathname: clientSignInRoute.path, state: redirectState }}
      />
    );
  } else if (!roles.includes(userData.role)) {
    return <Redirect to={dashboardRoute.path} />;
  }
  return (
    <Route render={render} component={component} path={path} exact={exact} />
  );
};

export default MustHaveRoleRoute;
