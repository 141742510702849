import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import ProfileCircleButton from "../components/ProfileCircleButton";
import NotificationsButton from "../components/NotificationsButton";
import BackButton from "../components/BackButton";
import { Table } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";
import Switch from "react-switch";
import { showErrorNotification } from "../utility/Helpers";
import { useSelector } from "react-redux";

const Corporates = () => {
  let stateObj = {
    date: "",
    workingHours: {
      startTime: "",
      endTime: "",
    },
    breakHours: {
      startTime: "",
      endTime: "",
    },
    available: false,
  };
  let arr = [];
  for (let i = 0; i < 50; i++) {
    let newObj = {
      ...stateObj,
    };
    arr.push(newObj);
  }
  const [array, setArray] = useState(arr);

  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
      width: 90,
    },
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
      // width: 15,
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      // width: 15,
    },
    {
      title: "Approved",
      dataIndex: "Approved",
      key: "Approved",
      width: 200,
    },
    {
      title: "Delete",
      dataIndex: "Delete",
      key: "Delete",
      width: 90,
    },
    {
      title: "Last Seen",
      dataIndex: "LastSeen",
      key: "LastSeen",
      // width: 10,
    },
  ];

  const data = [];
  for (let i = 0; i < 5; i++) {
    data.push({
      key: i,
      ID: <span>1467</span>,
      Category: <span>Doctor</span>,
      Name: <span>Dylan Michael</span>,
      Approved: (
        <div className="d-flex">
          <Switch
            checked={array[i].available}
            onChange={(val) => {
              setArray((prevState) => {
                let newArr = [...prevState];
                newArr[i].available = val;
                return newArr;
              });
            }}
            onColor="#bff5de"
            onHandleColor="#56c596"
            handleDiameter={15}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"
          />
          <div style={{ marginLeft: "5px" }}>Not Approved</div>
        </div>
      ),
      Delete: (
        <div>
          {" "}
          <RiDeleteBin6Line color="red" style={{ cursor: "pointer" }} />{" "}
        </div>
      ),
      LastSeen: <span>03 Days ago</span>,
    });
  }

  const [Input, setInput] = useState({
    clientName: "",
    clientEmail: "",
    emiratesIdExpiryDate: "",
    corporateID: "",
  });

  const onChangeHandler = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
    setInput((prevState) => {
      return {
        ...prevState,
        [nam]: val,
      };
    });
  };

  const genID = () => {
    var result = Input.clientName.slice(0, 2);
    if (result.length < 2) {
      showErrorNotification("Please input client Name");
      return;
    }
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    let length = 5;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setInput((prevState) => {
      return {
        ...prevState,
        corporateID: result,
      };
    });
  };
  const language = useSelector((state) => state.language);
  return (
    <div className="corporates">
      <div className="dashboard-book-consultation">
        <div className="col-12 notifications-and-profile mt-3 mt-md-0 justify-content-between mx-2">
          <div className="book-back-btn">
            <BackButton />
            <span className="consultation-heading">Settings</span>
          </div>
          <div style={{ paddingRight: "15px", display: "flex" }}>
            <NotificationsButton />
            <ProfileCircleButton />
          </div>
        </div>
      </div>

      <h5 className="corporates-heading">Corporate Clients</h5>
      <Row className="onboard-clients">
        <p className="onboard-clients--heading">Onboard Clients</p>
        <Col xs={12} md={5} className="onboard-clients-col1">
          <div
            className="corporate-inputs input-field"
            style={{ marginTop: "0" }}
          >
            <label htmlFor="name">Client Name</label>
            <input
              name="clientName"
              value={Input.clientName}
              onChange={onChangeHandler}
              className="corporate-input-fields"
              placeholder="Emirates"
            />
          </div>

          <div className="corporate-inputs input-field">
            <label htmlFor="name">Client {language.lang.email} Address</label>
            <input
              name="clientEmail"
              value={Input.clientEmail}
              onChange={onChangeHandler}
              className="corporate-input-fields"
              placeholder={language.lang.email + " Address"}
            />
          </div>

          <div className="corporate-inputs input-field">
            <label htmlFor="emiratesIdExpiryDate">Expiry Date</label>
            <input
              name="emiratesIdExpiryDate"
              value={Input.emiratesIdExpiryDate}
              onChange={onChangeHandler}
              className="corporate-input-fields"
              placeholder="DD - MM - YYYY"
              type="date"
            />
          </div>
        </Col>

        <Col xs={12} sm={6} md={3} className="onboard-clients-col2">
          <button className="gen-corporate-btn" onClick={genID}>
            Generate {language.lang["corporate-id"]}
          </button>

          <input
            name="corporateID"
            value={Input.corporateID}
            className="corporate-input-fields random-id"
            placeholder="EMX56892"
          />

          <div className="add-file">
            <p className="add-file-corp">Add Corporate Logo file</p>
            <p className="add-file-drop">Or Drop File here</p>
            <i className="far fa-paperclip paper-pos"></i>
          </div>
        </Col>

        <Col xs={12} sm={6} md={4} className="onboard-clients-col3">
          <div className="">
            <label>Subscription Model</label>
          </div>

          <div>
            <div className="corporate-checkbox">
              <label for="product-integration">Product Integration</label>
              <br />
              <input
                type="checkbox"
                id="product-integration"
                name="product-integration"
                value="Product Integration"
              />
            </div>

            <div className="corporate-checkbox">
              <label for="sub-domain-reg">Sub Domain Registration</label>
              <br />
              <input
                type="checkbox"
                id="sub-domain-reg"
                name="sub-domain-reg"
                value="Sub Domain Registration"
              />
            </div>

            <div className="corporate-checkbox">
              <label for="subscribe-commercial">Subscribe Commercial</label>
              <br />
              <input
                type="checkbox"
                id="subscribe-commercial"
                name="subscribe-commercial"
                value="Subscribe Commercial"
              />
            </div>
          </div>

          <div className="submit-corporate-btn-block">
            <button className="submit-corporate-btn">Submit</button>
          </div>
        </Col>
      </Row>

      <h5 className="all-client-heading">All Clients</h5>

      <Row>
        <Col>
          <Table
            columns={columns}
            dataSource={data}
            scroll={{ x: 900, y: 530 }}
            pagination={true}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Corporates;
