import CirclesBackground from "./CirclesBackground";
import { useHistory } from "react-router-dom";
import { dashboardRoute } from "../routes";
import TopMenuBackButton from "./CommonControls/GenericInnerPageComponents/TopMenuBackButton";

const AuthBase = (props) => {
  const { children, className } = props;
  const history = useHistory();

  const closePressed = () => {
    history.push(dashboardRoute.path);
  };

  return (
    <CirclesBackground>
      <div
        className={`layout-container auth-base ${className ? className : ""}`}
      >
        <button
          onClick={closePressed}
          className="btn"
          style={{ position: "absolute", top: "35px", fontSize: "25px" }}
        >
          <TopMenuBackButton />
        </button>
        <div
          className="d-flex align-items-center justify-content-center pb-3"
          style={{ gap: "10px" }}
        >
          {/* <img
            src={Config.logohealth}
            alt="IZI"
            className="col-3 col-sm-2"
          /> */}
        </div>
        <div className="d-flex justify-content-center pb-5">
          <div className="auth-base-layout-card px-4 col-10 col-sm-10 col-md-6 col-lg-5 col-xl-4">
            <div className="" style={{ position: "relative" }}></div>
            {children}
          </div>
        </div>
      </div>
    </CirclesBackground>
  );
};

export default AuthBase;
