import NotificationsButton from "./NotificationsButton";
import ProfileCircleButton from "./ProfileCircleButton";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import MostPopularCard from "./MostPopularCard";
import { settings } from "../config";
import axios from "axios";
import Loading from "./Loading";
import BackButton from "./BackButton";
import AllRecipe from "./AllRecipe";
import { useSelector } from "react-redux";

const CardRecipe = () => {
    const [recipe, setrecipe] = useState([]);
    const [allrecipe,setallrecipe] = useState([]);
    const [flag, setflag] = useState(false);
  useEffect(() => {
    !flag && FetchRecipes();
  },[])
    const history = useHistory();
    useEffect(() => {
        history.location.state.recipe && setrecipe(history.location.state.recipe);
      //  history.location.state.recipe && console.log("recipe",recipe);
    },[ history.location.state.recipe])
    const FetchRecipes = async () =>{
        await axios.get(`${settings.apiBaseURL}/getMealPlans`)
                .then((response) => {
                  setallrecipe(response.data.response);
              })
              .catch((error) => { console.log(error.response.status) })
              .finally(()=>{setflag(true); })
      }
      const language = useSelector((state) => state.language);
  return (
    <section className="row px-4">
        <div className="d-flex justify-content-between">
        <BackButton />
        <h3 className="Page-title">{language.lang.meal_plans}</h3>
        <div className="d-flex">
        <NotificationsButton className="ml-auto" />
        <ProfileCircleButton />
        </div>
      </div>
      <div className="d-flex pt-2 flex-column recipe">
            <div className="w-100" style={{height:"400px"}}>
                <img className="w-100 h-100" style={{objectFit:'cover',borderRadius:'10px'}} src={recipe && recipe.image_url ? recipe.image_url : "https://upload.wikimedia.org/wikipedia/commons/6/6d/Good_Food_Display_-_NCI_Visuals_Online.jpg"} alt="recipe"/>
            </div>
            <div>
            <div className="card p-2" style={{borderRadius:'0'}}>
                <section className="w-100">
                <div style={{color:'#ffc822',fontSize:'25px',fontweight:'bold'}}><h4>{recipe && recipe.name}</h4></div>
                       <div className="section-head">{language.lang["nutritional-summary"]}</div>
                       <div className="d-flex justify-content-center">
                           <table className="summary-table">
                           <thead>
                                <tr>
                                <td>{language.lang.amounts}</td>
                                <td>{language.lang.kcal}</td>
                                <td>{language.lang.carbohydrates}</td>
                                <td>{language.lang.proteins_gm}</td>
                                <td>{language.lang.fats}</td>
                                <td>{language.lang.salt}</td>
                                <td>{language.lang.dietary}</td>
                                </tr>
                            </thead>
                               <tbody>
                                   <tr>
                                       <td>{recipe && recipe.amounts}</td>
                                       <td>{recipe && recipe.kcal}</td>
                                       <td>{recipe && recipe.carbohydrates}</td>
                                       <td>{recipe && recipe.proteins}</td>
                                       <td>{recipe && recipe.fats}</td>
                                       <td>{recipe && recipe.salt}</td>
                                       <td>{recipe && recipe.dietary_fibre}</td>
                                   </tr>
                               </tbody>
                           </table>
                       </div>
                </section>
                <section className="w-100">
                       <div className="section-head">Ingredient</div>
                       <div className="section-content">{recipe&&recipe.ingredients}</div>
                </section>
                <section className="w-100">
                       <div className="section-head">{language.lang.recipe}</div>
                       <div className="section-content">{recipe&&recipe.recipe}</div>
                </section>
            </div>
            </div>
            <div>
                <p style={{ fontSize: "20px", fontWeight: "600" }}>{language.lang.next}</p>
                <div className="px-4">
                <div className="divider mb-3"></div>
                </div>
                <AllRecipe sid={recipe && recipe.id}/>
            </div>
      </div>
    </section>
  );
};

export default CardRecipe;
