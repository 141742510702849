import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { IoPartlySunnyOutline } from "react-icons/io5";
import { FaRegClock } from "react-icons/fa";
import axios from "../services/Axios";
import { settings } from "../config";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../utility/Helpers";
import { useSelector } from "react-redux";

const SlotDetails = ({ modalData }) => {
  const [slots, setSlots] = useState([]);
  const language = useSelector((state) => state.language);
  // const history = useHistory();

  useEffect(() => {
    getSlotDetails();
  }, []);

  const getSlotDetails = async () => {
    try {
      let obj = JSON.parse(localStorage.getItem("userData"));
      const response = await axios.get(
        `${settings.apiBaseURL}/getSlots?practitionerId=${obj.id}`
      );
      // console.log("The response is ->", response);
      let arr = response.data.response.map((el) => {
        let obj = {};
        obj.id = el.id;
        obj.start_time = el.start_time.substring(11, 16);
        obj.end_time = el.end_time.substring(11, 16);
        return obj;
      });
      setSlots(arr);
    } catch (e) {
      // console.log("error->",{e});
      showErrorNotification(e.response.data.error);
    }
  };

  const onClickMarkLeave = async (slotId) => {
    // console.log("slotId", slotId);
    try {
      const response = await axios.post(`${settings.apiBaseURL}/updateSlot`, {
        slotId,
        isAvailable: "false",
      });
      // console.log("response", response);
      showSuccessNotification("Slot marked as leave");
    } catch (e) {
      showErrorNotification(e.response.data.error);
    }
  };

  return (
    <Col xs={12} className="slot-details">
      <h5 className="slot-details--heading">Slot Details</h5>
      <div className="slot-booking">
        <div className="slot-booking-text">
          <p>{language.lang.your_booking_on}</p>
          <button className="btn-morning">
            <IoPartlySunnyOutline /> {language.lang.morning}
          </button>
        </div>
        <div className="slot-booking-date">
          {/* <p>09 June, Saturday</p> */}
          <p>
            {modalData.date} {modalData.month}, {modalData.day}
          </p>
          <button className="btn-evening">
            <FaRegClock /> {language.lang["evening"]}
          </button>
        </div>
      </div>

      <div className="p-slot-details">
        <p>{language.lang["solt-time-details"]}</p>

        <p className="my-3">
          {language.lang["your-allocated-slot"]}{" "}
          <span className="ml-4">10</span>
        </p>
        {/* <p className="ml-1 my-3">Your allocated time 
                    <button className="allocated-time-btn">11: 30 am</button>
                </p> */}
      </div>

      <div className="big-btn">
        <div className="d-flex">
          <div style={{ width: "100px", marginLeft: "5px" }}>From</div>
          <div style={{ width: "129px" }}>To</div>
        </div>
        {slots.map((el) => {
          return (
            <div className="my-2 d-flex justify-content-between">
              <button className="time-btn">{el.start_time}</button>
              <button className="time-btn">{el.end_time}</button>
              <button
                className="mark-btn"
                onClick={() => onClickMarkLeave(el.id)}
              >
                Mark Leave
              </button>
            </div>
          );
        })}
      </div>

      {/* <div className="big-btn">
                <div>Mark the Slot as Break</div>
            </div> */}
    </Col>
  );
};

export default SlotDetails;
