import { useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import moment from "moment";
import axios from "axios";
import { settings } from "../config";
import { useDispatch, useSelector } from "react-redux";
import actions from "../redux/clinic/actions";

const ClinicAppointmentCard = ({ selectedSlot }) => {
  // console.log({ selectedSlot });
  const language = useSelector((state) => state.language.lang);
  const DocInfo = useSelector((state) => state.clinic.selectedDocInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(DocInfo).length === 0 && selectedSlot.id) {
      getPractitionerDetails(selectedSlot.id);
    }
  }, [selectedSlot]);

  const getPractitionerDetails = async (id) => {
    await axios
      .get(`${settings.apiBaseURL}/getPractitionerDetails?practitionerId=${id}`)
      .then((response) => {
        dispatch({
          type: actions.SET_SELECTED_DOCTOR_INFO,
          payload: response.data.response[0],
        });
      });
  };

  return (
    <div className="d-flex w-100 mt-1">
      <div className="col-2 p-0 d-flex flex-column text-end">
        <span className="start-time">
          {moment(selectedSlot.start).format("hh:mm")}
        </span>
        <span className="end-time">
          {moment(selectedSlot.end).format("hh:mm")}
        </span>
      </div>
      <div className="col-9 p-0 mx-2">
        <div className="d-flex justify-content-center align-items-center w-100">
          <div className="side-show mr-2"></div>
          <div
            className="selected-appoint-card w-100"
            style={{ backgroundColor: selectedSlot.color }}
          >
            <div className="d-flex justify-content-between w-100 align-items-center">
              <span>{language[`${selectedSlot.status}-clinic`]}</span>
              <span>
                <BsThreeDotsVertical color={"white"} />
              </span>
            </div>
            <div className="mt-1">{selectedSlot.PractitionerName}</div>

            <div className="mt-2">
              <HiOutlineLocationMarker size={20} />
              <span className="ml-2">
                UAE{" "}
                {Object.keys(DocInfo).length > 0 &&
                  (!!DocInfo.city_name ? "- " + DocInfo.city_name : "")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicAppointmentCard;
