import { useEffect } from "react";
import { useSelector } from "react-redux";
import { OneSignalAppId } from "../config";

function OSignal() {
  const id = useSelector((state) => {
    if (state.auth.userData) {
      return state.auth.userData.id;
    }
    return null;
  });

  // OneSignal.push(function() {
  //   OneSignal.isPushNotificationsEnabled(function(isEnabled) {
  //     if (isEnabled)
  //       {
  //         console.log("Push notifications are enabled!");
  //         OneSignal.setExternalUserId(externalUserId);
  //       }
  //     else
  //       console.log("Push notifications are not enabled yet.");
  //   });
  // });

  useEffect(() => {
    // oss();
    //sendPush();
    if (window.location.hostname === "localhost") return;
    let externalUserId = id;
    window.OneSignal = window.OneSignal || [];
    const OneSignal = window.OneSignal;

    // console.log(OneSignal);

    OneSignal.push(() => {
      OneSignal.init({
        appId: OneSignalAppId, //STEP 9
        notifyButton: {
          enable: true,
        },
        subdomainName: "IZI Health",
        promptOptions: {
          slidedown: {
            enabled: true,
            autoPrompt: true,
            //pageViews: 3
          },
        },
        welcomeNotification: {
          title: "IZI Health",
          message: "Thanks for subscribing!",
        },
      });
      if (externalUserId) {
        OneSignal.setExternalUserId(externalUserId);
      }
    });
    OneSignal.push([
      "getNotificationPermission",
      function (permission) {
        console.log("Site Notification Permission:", permission);
        // (Output) Site Notification Permission: default
      },
    ]);
    OneSignal.push(function () {
      // Occurs when the user's subscription changes to a new value.
      OneSignal.on("subscriptionChange", function (isSubscribed) {
        console.log("The user's subscription state is now:", isSubscribed);
        if (isSubscribed && externalUserId) {
          OneSignal.setExternalUserId(externalUserId);
        }
      });
    });
  }, [id]);

  // const oss = async () => {

  // };

  // const sendPush = async () => {
  //   // Mobile App
  //   //const app_id = "bf14b1ce-3778-4c68-a6a3-1abd98bf5a52";
  //   //const api_key = "MzQ4ZWY1YWUtNjNlZS00NGNhLTk4ODAtNGRjMzVlNDYxYjQy";

  //   // Web App
  //   const app_id = "917cf835-1a80-4b59-b8f1-a6e17ea7c9b2";
  //   const api_key = "NjM5NTE1NzMtMmE4NS00NjljLTljNGItZDQ2ODYzODg2NDU4";
  //   var data = JSON.stringify({
  //     app_id: app_id,
  //     included_segments: ["Subscribed Users"],
  //     //include_external_user_ids: ["vickyid"],
  //     data: {
  //       foo: "bar",
  //     },
  //     contents: {
  //       en: "English Message",
  //     },
  //   });

  //   var config = {
  //     method: "post",
  //     url: "https://onesignal.com/api/v1/notifications",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "basic " + api_key,
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(JSON.stringify(response.data));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  return <div></div>;
}

export default OSignal;
