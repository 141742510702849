import React from "react";
import { useTimer } from "react-timer-hook";

const Timer = ({ expiryTimestamp, endCall, className }) => {
  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: () => endCall(),
  });
  return (
    <div className={className}>
      <button className="timer-sty" style={{ marginLeft: "0" }}>
        <span>{minutes}</span>:<span>{seconds}</span>
      </button>
    </div>
  );
};

export default Timer;
