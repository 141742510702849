const actions = {
  GET_TOP_PRACTITIONERS: "GET_TOP_PRACTITIONERS",
  SET_REVIEWS:"SET_REVIEWS",
  SET_HOME_BANNER: "SET_HOME_BANNER",
  SET_MEAL_PLANS: "SET_MEAL_PLANS",
  UPDATE_PATIENT_APPOINTMENTS: "UPDATE_PATIENT_APPOINTMENTS",
  UPDATE_MEAL_PLANS: "UPDATE_MEAL_PLANS",
  RESET_TOP_PRACTITIONERS: "RESET_TOP_PRACTITIONERS",
  SET_OFFSET: "SET_OFFSET",
  SET_MEAL_OFFSET: "SET_MEAL_OFFSET",
  SET_SELECTED_PRACTITIONER: "SET_SELECTED_PRACTITIONER",
  SET_ALL_APPOINTMENTS_OF_CUSTOMER: "SET_ALL_APPOINTMENTS_OF_CUSTOMER",
  SET_PATIENT_VIEW_TYPE: "SET_PATIENT_VIEW_TYPE",
  SET_VIEW_SLOT: "SET_VIEW_SLOT",
  SET_SELECTED_SLOT: "SET_SELECTED_SLOT",
  SET_NEED_TO_CALL_CALENDAR: "SET_NEED_TO_CALL_CALENDAR",
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",
  SET_SELECTED_SLOT_AND_VIEW_SLOT: "SET_SELECTED_SLOT_AND_VIEW_SLOT",
  CLEAR_CALENDAR_EVENTS: "CLEAR_CALENDAR_EVENTS",
};

export default actions;
