import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useFormContext } from "react-hook-form";
import actions from "../../redux/fitnessBuddy/actions";
import { GoogleMapAPIKEY } from "../../config";
import { IoCloseCircleSharp } from "react-icons/io5";
import { showErrorNotification } from "../../utility/Helpers";

const GoogleMapView = (props) => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);

  const mapStyles = {
    height: "380px",
    width: "100%",
    position: "absolute",
    overflow: "hidden",
    top: "69px",
    left: "5px",
  };

  const userDetails = useSelector((state) => state.patientDetails.userDetails);
  const [suggestions, setSuggestions] = useState([]);

  const [currentPosition, setCurrentPosition] = useState(
    userDetails.location.length === 0
      ? { lat: 24.4539, lng: 54.3773 }
      : {
          lat: userDetails.location[0],
          lng: userDetails.location[1],
        }
  );
  const [currentLocationName, setCurrentLocationName] = useState("");

  useEffect(() => {
    async function fetchData() {
      const res = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${currentPosition.lat},${currentPosition.lng} &key= ${GoogleMapAPIKEY}`
      );
      setCurrentLocationName(
        res.data.results.length === 0
          ? ""
          : res.data.results[1].formatted_address
      );
    }
    fetchData();
  }, []);

  const getCurrentLocation = async (lat, lng) => {
    const res = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${lat},${lng} &key= ${GoogleMapAPIKEY}`
    );
    setCurrentLocationName(res.data.results[1].formatted_address);
  };
  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setCurrentPosition({ lat, lng });
    getCurrentLocation(lat, lng);
  };
  const { register } = useFormContext();
  const handleApply = (e) => {
    e.preventDefault();
    if( currentLocationName === ""){
      showErrorNotification("Please enter the location");
      return;
    }
    dispatch({
      type: actions.UPDATE_LOCATION,
      payload: {
        location: [currentPosition.lat, currentPosition.lng],
        locationName: currentLocationName,
      },
    });

    props.onHide();
  };

  const showSuggestedPlace = async (e) => {
    setCurrentLocationName(e.target.value);
    const res = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${e.target.value}&key=${GoogleMapAPIKEY}`
    );
    if (res.data.status === "OK") {
      let newList = res.data.results.map((result) => {
        return {
          address: result.formatted_address,
          location: result.geometry.location,
        };
      });
      setSuggestions(newList);
    }
  };
  const suggestionSelected = (selectedPlace) => {
    setCurrentLocationName(selectedPlace.address);
    setCurrentPosition(selectedPlace.location);
    setSuggestions([]);
  };
  const renderSuggestions = () => {
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul className="dropdown-place">
        {suggestions.map((city, key) => (
          <li key={key} onClick={(e) => suggestionSelected(city)}>
            {city.address}
          </li>
        ))}
      </ul>
    );
  };
  const handleRemove = () =>{
    setCurrentLocationName("");
  }
  const handleFocus = (event) => event.target.select();

  return (
    <div className="map-view-container">
      <div className="map-search-box">
        <label className="location-text">{language.lang.location}</label>
        <input
          className="location-name neumorphic-input"
          name="locationName"
          {...register("locationName")}
          type="text"
          value={currentLocationName}
          onChange={showSuggestedPlace}
          autoComplete="off"
          autoFocus
          onFocus={handleFocus}
        />
        {renderSuggestions()}
        <IoCloseCircleSharp
          className="remove-locationName"
          onClick={handleRemove}
        />
      </div>
      <LoadScript googleMapsApiKey={GoogleMapAPIKEY}>
        <GoogleMap
          mapContainerStyle={mapStyles}
          center={currentPosition}
          zoom={13}
          className="map-view"
        >
          {currentPosition.lat && (
            <Marker
              position={currentPosition}
              onDragEnd={(e) => onMarkerDragEnd(e)}
              draggable={true}
            />
          )}
        </GoogleMap>
      </LoadScript>

      <div className="apply-button">
        <button
          type="button"
          className="btn-apply"
          onClick={(e) => {
            handleApply(e);
          }}
        >
          {language.lang.apply}
        </button>
      </div>
    </div>
  );
};
export default GoogleMapView;
