import { useSelector } from "react-redux";
import { servingUnit } from "../../../utility/constants";
import ViewRecipeIngredientListItem from "./ViewRecipeIngredientListItem";

const ViewRecipeIngredients = ({ recipe, selectedUnit, selectedUnitQuantity }) => {
  const language = useSelector((state) => state.language.lang);

  // console.log({ recipe });

  return (
    <div className="view-recipe-ingredients">
      <div>{language["ingredients"] ?? "ingredients"}</div>
      <div className="ingredients-list">
        {recipe.recipeIngredients
          .filter((item) => item?.ingredientDetails?.id)
          .map((item) => {
            let quantity;
            let weightInGrams;
            const unitWeightInGrams = item.plain_unit_quantity;
            const unitQuantity = item.unit_quantity;
            if (selectedUnit === servingUnit) {
              const quantityForOneServe = unitQuantity / recipe.rcp_serving_size;
              quantity = quantityForOneServe * selectedUnitQuantity;
              weightInGrams = quantity * (unitWeightInGrams / unitQuantity);
            } else {
              let recipeTotalWeightInGramsForSelectedServing = 0;
              for (const ing of recipe.recipeIngredients) {
                recipeTotalWeightInGramsForSelectedServing += ing.plain_unit_quantity;
              }
              const ingredientWeightInGrams =
                selectedUnitQuantity * (unitWeightInGrams / recipeTotalWeightInGramsForSelectedServing);
              quantity = ingredientWeightInGrams * (unitQuantity / unitWeightInGrams);
              weightInGrams = quantity * (unitWeightInGrams / unitQuantity);
            }
            return (
              <div key={item.ingredientDetails.id} className="mb-3">
                <ViewRecipeIngredientListItem ingredient={item} quantity={quantity} weightInGrams={weightInGrams} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ViewRecipeIngredients;
