import actions from "./actions";
import axios from "../../services/Axios";
import store from "../store";
import { showSuccessNotification } from "../../utility/Helpers";
import { settings } from "../../config";
import { userRoleDoctor } from "../../utility/constants";
//getPatientDetails?patientId=
//getPractitionerDetails?practitionerId=

export const setPreferredLanguageAction = ({ lang }) => {
  return {
    type: actions.SET_PREFERRED_LANGUAGE,
    payload: lang,
  };
};

export const setSelectedRegionAction = ({ regionID }) => {
  return {
    type: actions.SET_SELECTED_REGION,
    payload: regionID,
  };
};

const fetchlanguage = () => {
  return async (dispatch) => {
    const res = await axios.get("/getTranslation", {
      headers: { PlatformType: "Web" },
    });
    dispatch({
      type: actions.SET_LANGUAGE,
      payload: res ? res.data.response : null,
    });
  };
};

const setpreferredLanguage = (payload) => {
  return {
    type: actions.SET_LANGUAGE_PREFER,
    payload: payload,
  };
};

const fetchregions = () => {
  return async (dispatch) => {
    // let userData = store.getState().auth.userData;
    const res = await axios.get("/getRegions");
    if (!res.data.response) return;
    dispatch({
      type: actions.SET_REGIONS,
      payload: res.data.response,
    });
  };
};

const UpdateGeneralSettings = (formData, callAPI = true) => {
  return async (dispatch) => {
    dispatch({
      type: actions.UPDATE_GENERAL_SETTINGS,
      payload: formData || {},
    });
    const userData = store.getState().auth.userData;
    if (userData && callAPI) {
      await axios
        .post("/updateUserGeneralSettings", {
          ...formData,
          userId: userData.id,
        })
        .then(() => {
          showSuccessNotification("General Settings updated successfully"); //lang.general_settings_updated_successfully
        });
    }
  };
};

const UpdatePatientProfile = (formData) => {
  return async (dispatch) => {
    await axios
      .post("/updatePatientProfile", { ...formData })
      .then((response) => {
        showSuccessNotification("Profile Settings updated successfully"); //language.lang.profile_settings_updated_successfully
      });
    dispatch({
      type: actions.UPDATE_PROFILE_SETTINGS,
      payload: formData || {},
    });
  };
};

const getPatientProfile = () => {
  return async (dispatch) => {
    const state = store.getState();
    const userID = state.auth.userData && state.auth.userData.id;
    if (!userID) return;
    if (state.auth.userData.role === userRoleDoctor) return;
    const res = await axios.get("/getPatientDetails", {
      params: { patientId: userID },
    });
    dispatch({
      type: actions.GET_PROFILE_DETAILS,
      payload: res ? res.data.response[0] : null,
    });
  };
};

export {
  fetchlanguage,
  fetchregions,
  UpdateGeneralSettings,
  UpdatePatientProfile,
  getPatientProfile,
  setpreferredLanguage,
};
