import { useSelector } from "react-redux";
import RecipeTotalsPieChart from "./RecipeTotalsPieChart";

const CreateRecipeTotals = ({ addedIngredients }) => {
  const language = useSelector((state) => state.language.lang);

  let calories = 0;
  let carbs = 0;
  let fats = 0;
  let proteins = 0;
  for (const ing of addedIngredients) {
    const gramsForAddedQuantity = (ing.quantity * (ing.unit?.weight_in_grams ?? 0)) / (ing.unit?.unit_quantity ?? 1);
    const gramsForDefaultServing = ing.ingredient.default_serving * ing.ingredient.grams_per_serving || 1;
    const caloriesForDefaultServing = ing.ingredient.ingredientNutritionInfo?.calories ?? 0;
    const carbsForDefaultServing = ing.ingredient.ingredientNutritionInfo?.carbs ?? 0;
    const fatsForDefaultServing = ing.ingredient.ingredientNutritionInfo?.fats ?? 0;
    const proteinsForDefaultServing = ing.ingredient.ingredientNutritionInfo?.proteins ?? 0;
    calories += (gramsForAddedQuantity * caloriesForDefaultServing) / gramsForDefaultServing;
    carbs += (gramsForAddedQuantity * carbsForDefaultServing) / gramsForDefaultServing;
    fats += (gramsForAddedQuantity * fatsForDefaultServing) / gramsForDefaultServing;
    proteins += (gramsForAddedQuantity * proteinsForDefaultServing) / gramsForDefaultServing;
  }

  const nutritionsSum = carbs + fats + proteins;
  const total = nutritionsSum === 0 ? 1 : nutritionsSum;
  const carbsPercentage = (carbs / total) * 100;
  const fatsPercentage = (fats / total) * 100;
  const proteinsPercentage = (proteins / total) * 100;

  return (
    <div className="recipe-totals">
      <div className="d-flex flex-column totals-numbers">
        <div className="title mb-2">{language["recipe-totals"] ?? "recipe-totals"}</div>
        <div className="nutrition-number">
          <div className="calories">{language["calories"] ?? "calories"}</div>
          <div>{calories.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })}</div>
          <div></div>
        </div>
        <div className="nutrition-number">
          <div className="carbs">{language["carbs"] ?? "carbs"}</div>
          <div>
            {carbs.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })} {language["g"] ?? "g"}
          </div>
          <div className="percentage">{carbsPercentage.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })} %</div>
        </div>
        <div className="nutrition-number">
          <div className="fats">{language["fats"] ?? "fats"}</div>
          <div>
            {fats.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })} {language["g"] ?? "g"}
          </div>
          <div className="percentage">{fatsPercentage.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })} %</div>
        </div>
        <div className="nutrition-number">
          <div className="protein">{language["protein"] ?? "protein"}</div>
          <div>
            {proteins.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })} {language["g"] ?? "g"}
          </div>
          <div className="percentage">
            {proteinsPercentage.toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })} %
          </div>
        </div>
      </div>
      <div className="d-flex recipe-totals-pie-chart">
        {addedIngredients && addedIngredients.length > 0 && (
          <RecipeTotalsPieChart carbs={carbs} fats={fats} proteins={proteins} />
        )}
      </div>
    </div>
  );
};

export default CreateRecipeTotals;
