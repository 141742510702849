import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "antd";
import { BsFilterRight } from "react-icons/bs";
import MyCalendarPractitioner from "./MyCalendarPractitioner";
import practitionerActions from "../redux/practitioner/actions";
import PractitionerCalendarAppointmentCard from "./PractitionerCalendarAppointmentCard";
import PractitionerCalendarSessionCard from "./PractitionerCalendarSessionCard";
import { useEffect } from "react";
import { setPractitionerSelectedSlotAndViewSlotAction } from "../redux/practitioner/actionCreators";
import TopMenu from "./CommonControls/TopMenu";

const PractitionerCalendar = () => {
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch({
      type: practitionerActions.SET_PRACTITIONER_VIEW_SLOT,
      payload: false,
    });
  };
  const calendarState = useSelector((state) => state.practitioner.calendar);
  const viewSlot = calendarState.viewSlot;
  const selectedSlot = calendarState.selectedSlot;
  const isAppointment = selectedSlot.calendarItemType === "appointment";

  useEffect(() => {
    return () => {
      dispatch(
        setPractitionerSelectedSlotAndViewSlotAction({
          viewSlot: false,
          selectedSlot: {},
        })
      );
    };
  }, [dispatch]);

  let drawerCard;
  if (isAppointment) {
    drawerCard = (
      <PractitionerCalendarAppointmentCard selectedSlot={selectedSlot} />
    );
  } else {
    drawerCard = <PractitionerCalendarSessionCard />;
  }

  return (
    <div className="practitioner-calendar px-sm-4 pb-sm-5 doctor-dashboard-home calendar-screen">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.lang.calendar}
      />

      <div className="mt-2 d-flex justify-content-center w-100">
        <div className="mm-card doctor-dashboard-card min-card-width p-0 w-100 py-4">
          <MyCalendarPractitioner />
        </div>
      </div>
      <Drawer
        placement={"right"}
        closable={true}
        onClose={handleCancel}
        visible={viewSlot}
        width={400}
      >
        <div>
          {isAppointment ? language.lang.appointments : language.lang.event}
        </div>
        <div className="d-flex w-100 mt-4 mb-3 selected-appoint">
          <div className="col-2 p-0 headings text-end">Time</div>
          <div className="col-8 p-0 mx-2 ml-3 headings">Details</div>
          <div className="col-2 p-0 headings">
            <BsFilterRight size={25} />
          </div>
        </div>
        {drawerCard}
      </Drawer>
    </div>
  );
};

export default PractitionerCalendar;
