import { useEffect, useState } from "react";
import { getCountriesPhoneCodes } from "../services/CustomerNetworkService";

const useCountriesPhoneCodes = () => {
  const [loading, setLoading] = useState(true);
  const [phoneCountryCodes, setPhoneCountryCodes] = useState(null);

  useEffect(() => {
    (async () => {
      const res = await getCountriesPhoneCodes();
      setLoading(false);
      setPhoneCountryCodes(res);
    })();
  }, []);

  return [loading, phoneCountryCodes];
};

export default useCountriesPhoneCodes;
