import { useSelector } from "react-redux";

const ViewRecipeCookingInstructions = ({ recipe }) => {
  const language = useSelector((state) => state.language.lang);
  const preferredlanguage = useSelector(
    (state) => state.language.general.preferredlanguage
  );
  const handleLinkClick = (item) => {
    window.open(item, "_blank").focus();
  };

  return (
    <div className="view-recipe-cooking-instructions">
      <div>{language["cooking-instructions"] ?? "cooking-instructions"}</div>
      <div className="instructions-list">
        {(recipe?.rcp_cooking_instructions ?? []).length === 0 && (
          <p className="text-center">{language["no-added-instructions"] ?? "no-added-instructions"}</p>
        )}
        {(recipe?.rcp_cooking_instructions ?? []).map((item, index) => {
          const isLink = item.referenceVideoUrl;
          const number = index + 1;
          return (
            <div
              key={`${index}-${isLink ? item.referenceVideoUrl : item.instructionEn }`}
              className={`cooking-instruction-list-item ${isLink ? "link" : ""}`}
            >
              <div className="number">{number}</div>
              <div onClick={isLink ? () => handleLinkClick(item.referenceVideoUrl) : null} className="text">
                {isLink ? item.referenceVideoUrl: ((preferredlanguage=='ar') ? (!!item.instructionAr ? item.instructionAr  : item.instructionEn)  : 
                           (preferredlanguage=='ku') ? (!!item.instructionKu ? item.instructionKu : item.instructionEn) 
                           :item.instructionEn)
                }
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ViewRecipeCookingInstructions;
