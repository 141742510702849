import { useSelector } from "react-redux";
import { getAuth } from "firebase/auth";

const useFireAuth = () => {
  const app = useSelector((state) => state.firebase.app);
  const currentLang = useSelector(
    (state) => state.language.general.preferredlanguage
  );
  if (!app) return null;
  const fireAuth = getAuth(app);
  fireAuth.languageCode = currentLang;
  return fireAuth;
};

export default useFireAuth;
