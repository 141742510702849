import Settings from "../config/settings.prod.json";
//export const serverURL = process.env.REACT_APP_SERVER_URL;
export const serverURL = Settings.Url.ApiServer;
// export const serverURL = "http://localhost:3001";
export const GoogleMapAPIKEY = Settings.Constants.GoogleMapApiKey;
export const OneSignalAppId = Settings.Constants.OneSignalAppId;
export const GoogleAnalyticsMID = Settings.Constants.GoogleAnalyticsId;
export const env = Settings.Environment.Mode;
export const isProductionEnv = env === "DEV";
export const domainName = Settings.Url.DomainName;
export const dynamicLinkDomain = Settings.Constants.DynamicLinksDomain;
export const GoogleTranslateAPIKey=Settings.Constants.GoogleTranslateApiKey;

export const settings = {
  apiBaseURL: `${serverURL}/api/v1`,
  storageLink: Settings.Url.Storage,
  themeImagesPath: "/image/Theme",
  topRightCircleColor: "#1B68DF",
  bottomLeftCircleColor: "#1B68DF",
  purbleColor: "#d323c2",
  logoColor: "#1B68DF",
  flagsImagesPath: "/image/FLAGS",
};

export const firebaseConfig = {
  apiKey: "AIzaSyBSIjFXcxWONrsf7-VfTyPN0pe6ZhXIgx4",
  authDomain: "mindmina-74094.firebaseapp.com",
  projectId: "mindmina-74094",
  storageBucket: "mindmina-74094.appspot.com",
  messagingSenderId: "986263771453",
  appId: "1:986263771453:web:74f515dfc6864b9b1f0d54",
  measurementId: "G-KPLNTYNLPG",
};

const config = {
  logoLink: `${settings.storageLink}${settings.themeImagesPath}/logoblue.png`,
  logohealth: `${settings.storageLink}${settings.themeImagesPath}/logohealthblue.png`,
  logoizi: `${settings.storageLink}${settings.themeImagesPath}/logoiziblue.png`,
  mmImageLink: `${settings.storageLink}${settings.themeImagesPath}/logo.png`,
  visaLink: `${settings.storageLink}${settings.themeImagesPath}/visa.png`,
  mastercardLink: `${settings.storageLink}${settings.themeImagesPath}/mastercard.png`,
  doctorsLogo: `${settings.storageLink}${settings.themeImagesPath}/doctorsgroup.png`,
  logoColor: "#1B68DF",
  clientUserRole: "aemm_client",
  bookDoctorIcon: `${settings.storageLink}${settings.themeImagesPath}/dashicons/telehealth.png`,
  fitnessBuddyIcon: `${settings.storageLink}${settings.themeImagesPath}/dashicons/fitnessconnect.png`,
  mealPlanIcon: `${settings.storageLink}${settings.themeImagesPath}/dashicons/mealplans.png`,
  // Critical River, Varun Mishra, updated common url name for flags
  flagIcon: `${settings.storageLink}${settings.flagsImagesPath}`,

  backbutton: `${settings.storageLink}${settings.themeImagesPath}/topmenuicons/backbutton.png`,
  bellbutton: `${settings.storageLink}${settings.themeImagesPath}/topmenuicons/bellbutton.png`,
  hamburger: `${settings.storageLink}${settings.themeImagesPath}/topmenuicons/hamburger.png`,
  izilogocircle: `${settings.storageLink}${settings.themeImagesPath}/topmenuicons/izilogocircle.png`,
  settingsicon: `${settings.storageLink}${settings.themeImagesPath}/topmenuicons/settingsicon.png`,

  dashbackbutton: `${settings.storageLink}${settings.themeImagesPath}/topmenudashicons/dashbackbutton.png`,
  dashbellbutton: `${settings.storageLink}${settings.themeImagesPath}/topmenudashicons/dashbellbutton.png`,
  dashhamburger: `${settings.storageLink}${settings.themeImagesPath}/topmenudashicons/dashhamburger.png`,
  dashizilogocircle: `${settings.storageLink}${settings.themeImagesPath}/topmenudashicons/dashizilogocircle.png`,
  dashsettingsicon: `${settings.storageLink}${settings.themeImagesPath}/topmenudashicons/dashsettingsicon.png`,

  whatsappIcon: `${settings.storageLink}${settings.themeImagesPath}/dashicons/whatsapp.png`,
  customersupport: `${settings.storageLink}${settings.themeImagesPath}/Footer/support/customersupport.png`,
  practitionersupport: `${settings.storageLink}${settings.themeImagesPath}/Footer/support/practitionersupport.png`,
  corporatesupport: `${settings.storageLink}${settings.themeImagesPath}/Footer/support/corporatesupport.png`,
  appstoreIcon: `${settings.storageLink}${settings.themeImagesPath}/appstore.png`,
  playstoreIcon: `${settings.storageLink}${settings.themeImagesPath}/playstore.png`,

  recipeFallbackURL:`${settings.storageLink}${settings.themeImagesPath}/mealplans/recipies.png`,
  ingredientFallbackURL:`${settings.storageLink}${settings.themeImagesPath}/mealplans/ingredients.png`,
};

export default config;
