import actions from "./actions";

export const initState = {
  attendeesList: [],
  attendeesMap: {},
  hostMuteAll: false,
  loadingHostMuteAll: false,
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.SET_LOADING_HOST_MUTE_ALL:
      return Object.assign({}, state, {
        loadingHostMuteAll: payload,
      });
    case actions.UPDATE_ATTENDEES_MAP:
      return Object.assign({}, state, {
        attendeesMap: { ...state.attendeesMap, ...payload },
      });
    case actions.HOST_UNMUTE_ALL:
      return Object.assign({}, state, {
        hostMuteAll: false,
        loadingHostMuteAll: true,
      });
    case actions.HOST_MUTE_ALL:
      return Object.assign({}, state, {
        hostMuteAll: true,
        loadingHostMuteAll: true,
      });
    case actions.UPDATE_ATTENDEES_LIST:
      const attendeesMap = Object.assign(
        {},
        state.attendeesMap,
        payload.attendeesMap
      );
      return Object.assign({}, state, {
        attendeesList: payload.attendeesList,
        attendeesMap,
      });
    default:
      return state;
  }
};

export default reducer;
