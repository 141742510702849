import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { acceptWalkinRequestAction } from "../redux/practitioner/actionCreators";
import Loading from "./Loading";
// import { useHistory, useLocation } from "react-router-dom";
// import { agoraRoute, doctorJoinConsultationRoute } from "../routes";

const AcceptRejectNotificationContent = (props) => {
  // const location = useLocation();
  // const history = useHistory();
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.practitioner);
  const { notificationData, onButtonClick } = props;
  const clientDetails = notificationData.details;

  const acceptPressed = async () => {
    if (onButtonClick) onButtonClick();
    dispatch(acceptWalkinRequestAction(clientDetails));
    // const appointmentData = await dispatch(
    //   acceptWalkinRequestAction(clientDetails)
    // );
    // console.log("--- appointmentData", appointmentData);
    // if (location.pathname === agoraRoute.path) return;
    // if (appointmentData) {
    //   history.push(doctorJoinConsultationRoute.path);
    // }
  };

  const rejectPressed = () => {
    if (onButtonClick) onButtonClick();
  };

  return (
    <div className="accept-reject-notification-content">
      {/* <div className="info-row">
        <span>Name:</span>
        <span className="value"></span>
      </div> */}
      {/* <div className="info-row">
        <span>Age:</span>
        <span className="value">{clientDetails.age}</span>
      </div> */}
      {/* <div className="info-row">
        <span>Gender:</span>
        <span className="value">
          {clientDetails.gender === 1 ? "Male" : "Female"}
        </span>
      </div> */}
      {/* <div className="info-row">
        <span>Appointment Type:</span>
        <span className="value">{clientDetails.appointmentType}</span>
      </div> */}
      {/* <div className="info-row">
        <span>Visit Reason:</span>
        <span className="value">{clientDetails.reason}</span>{" "}
      </div> */}
      {loading ? (
        <div className="d-flex justify-content-center">
          <Loading />
        </div>
      ) : (
        <div className="accept-reject-notification-btns">
          <button onClick={acceptPressed} className="btn btn-accept">
            {language.lang.accept}
          </button>
          <button onClick={rejectPressed} className="btn btn-reject">
            {language.lang.reject}
          </button>
        </div>
      )}
    </div>
  );
};

export default AcceptRejectNotificationContent;
