// import { Button, Spin } from "antd";
// import { useState, useRef } from "react";
// import { useSelector } from "react-redux";
// import { useLocation, useRouteMatch } from "react-router-dom";
// import useMealPlanOnboarding from "../../../customHooks/useMealPlanOnboarding";
// import { subscriptionPlanDetailsRoute } from "../../../routes";
// import { updateUserProfile } from "../../../services/CustomerNetworkService";
// import { userOnboardForMealPlan } from "../../../services/MealPlanNetworkService";
// import { showErrorNotification, showSuccessNotification } from "../../../utility/Helpers";
// import ProtectedRoute from "../../../utility/ProtectedRoute";
// import TopMenu from "../../CommonControls/TopMenu";
// import Loading from "../../Loading";
// import AllergiesAndExclusions from "./AllergiesAndExclusions";
// import DietGoals from "./DietGoals";
// import DietTypes from "./DietTypes";
// import OnboardingSteps from "./OnboardingSteps";

// const MealPlanOnboarding = ({ history }) => {
//   const language = useSelector((state) => state.language.lang);
//   const selectedAllergiesRef = useRef(null);
//   const selectedMajorExclusionsRef = useRef(null);
//   const selectedSubExclusionsRef = useRef(null);
//   const selectedCustomExclusionsRef = useRef(null);
//   const { path } = useRouteMatch();
//   const { pathname, state } = useLocation();
//   const [loading, setLoading] = useState(false);
//   const [loadingInitData, dietGoals, dietTypes] = useMealPlanOnboarding();

//   // console.log(selectedAllergiesRef.current, selectedSubExclusionsRef.current, selectedMajorExclusionsRef.current);

//   const onContinuePressed = async () => {
//     setLoading(true);
//     const allergies = [...selectedAllergiesRef.current.values()];
//     const majorExclusions = [...selectedMajorExclusionsRef.current.values()];
//     const subExclusions = [...selectedSubExclusionsRef.current.values()];
//     // console.log("--- allergies", allergies);
//     // console.log("--- subExclusions", subExclusions);
//     // console.log("--- majorExclusions", majorExclusions);
//     const id = await userOnboardForMealPlan({
//       dietGoal: state?.dietGoal?.id,
//       dietType: state?.dietType?.id,
//       allergies,
//       majorExclusions,
//       subExclusions,
//       customExclusions: selectedCustomExclusionsRef.current,
//     });
//     if (id) {
//       handleOnboardingSuccess({ didSkipMealPlanOnboarding: false });
//     } else {
//       setLoading(false);
//       showErrorNotification();
//     }
//   };

//   const onSkipPressed = async () => {
//     setLoading(true);
//     const id = await userOnboardForMealPlan({
//       dietGoal: state?.dietGoal?.id,
//       dietType: state?.dietType?.id,
//       allergies: [],
//       majorExclusions: [],
//       subExclusions: [],
//     });
//     if (id) {
//       handleOnboardingSuccess({ didSkipMealPlanOnboarding: true });
//     } else {
//       setLoading(false);
//       showErrorNotification();
//     }
//   };

//   const handleCustomExclusionsChanged = (customExclusions) => {
//     // console.log("--- handleCustomExclusionsChanged", customExclusions);
//     selectedCustomExclusionsRef.current = customExclusions;
//   };

//   const handleOnboardingSuccess = async ({ didSkipMealPlanOnboarding }) => {
//     await updateUserProfile({ didSkipMealPlanOnboarding });
//     showSuccessNotification(language["meal-plan-onboarding-success"] ?? "meal-plan-onboarding-success");
//     history.replace(subscriptionPlanDetailsRoute.path);
//   };

//   const handleSelectedAllergiesChanged = (allergiesMap) => {
//     // console.log("--- handleSelectedAllergiesChanged", [...allergiesMap.values()]);
//     selectedAllergiesRef.current = allergiesMap;
//   };

//   const handleSelectedMajorExclusionsChanged = (majorExclusionsMap) => {
//     // console.log("--- handleSelectedMajorExclusionsChanged", [...majorExclusionsMap.values()]);
//     selectedMajorExclusionsRef.current = majorExclusionsMap;
//   };

//   const handleSelectedSubExclusionsChanged = (subExclusionsMap) => {
//     // console.log("--- handleSelectedSubExclusionsChanged", [...subExclusionsMap.values()]);
//     selectedSubExclusionsRef.current = subExclusionsMap;
//   };

//   let content;
//   if (loadingInitData) {
//     content = (
//       <div className="d-flex justify-content-center mt-5">
//         <Loading />
//       </div>
//     );
//   } else if (!dietGoals || !dietTypes) {
//     content = (
//       <div className="d-flex justify-content-center mt-5">
//         <h4>{language["error"] ?? "error"}</h4>
//       </div>
//     );
//   } else {
//     content = (
//       <>
//         <ProtectedRoute render={(props) => <DietGoals {...props} dietGoals={dietGoals} />} path={path} exact={true} />
//         <ProtectedRoute
//           render={(props) => <DietTypes {...props} dietTypes={dietTypes} />}
//           path={`${path}/diet-types`}
//           exact={true}
//         />
//         <ProtectedRoute
//           render={(props) => (
//             <AllergiesAndExclusions
//               {...props}
//               onSelectedMajorExclusionsChanged={handleSelectedMajorExclusionsChanged}
//               onSelectedAllergiesChanged={handleSelectedAllergiesChanged}
//               onSelectedSubExclusionsChanged={handleSelectedSubExclusionsChanged}
//               onSelectedCustomExclusionsChanged={handleCustomExclusionsChanged}
//             />
//           )}
//           path={`${path}/allergies-exclusions`}
//           exact={true}
//         />
//       </>
//     );
//   }

//   if (loading) {
//     content = (
//       <Spin
//         indicator={
//           <div className="d-flex justify-content-center">
//             <Loading />
//           </div>
//         }
//       >
//         {content}
//       </Spin>
//     );
//   }

//   const skipContinueButtons = (
//     <div>
//       <Button disabled={loading} onClick={onSkipPressed} type="link">
//         {language["skip"] ?? "skip"}
//       </Button>
//       {pathname === "/mealplan/onboarding/allergies-exclusions" && (
//         <Button disabled={loading} onClick={onContinuePressed} type="link">
//           {language["continue"] ?? "continue"}
//         </Button>
//       )}
//     </div>
//   );

//   return (
//     <div className="meal-plan-onboarding">
//       <TopMenu
//         shouldHideBackButton={false}
//         shouldHideProfileButton={false}
//         shouldHideNotificationButton={false}
//         shouldHideLogoButton={false}
//         pageTitle={language["meal-plan-onboarding"] ?? "meal-plan-onboarding"}
//         hideCustomclass={true}
//       />
//       <div className="meal-plan-onboarding-card">
//         <div className="d-flex justify-content-between mb-3">
//           <h5>{language["share-with-us-some-basic-details"] ?? "share-with-us-some-basic-details"}</h5>
//           {skipContinueButtons}
//         </div>
//         <div className="d-flex justify-content-center mb-3">
//           <OnboardingSteps />
//         </div>
//         <div className="mb-3">{content}</div>
//         <div className="d-flex justify-content-end">{skipContinueButtons}</div>
//       </div>
//     </div>
//   );
// };

// export default MealPlanOnboarding;


// Inserted by mahi
import { Button, Spin } from "antd";
import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import useMealPlanOnboarding from "../../../customHooks/useMealPlanOnboarding";
import { subscriptionPlanDetailsRoute } from "../../../routes";
import { updateUserProfile } from "../../../services/CustomerNetworkService";
import { userOnboardForMealPlan } from "../../../services/MealPlanNetworkService";
import { showErrorNotification, showSuccessNotification } from "../../../utility/Helpers";
import ProtectedRoute from "../../../utility/ProtectedRoute";
import TopMenu from "../../CommonControls/TopMenu";
import Loading from "../../Loading";
import AllergiesAndExclusions from "./AllergiesAndExclusions";
import DietGoals from "./DietGoals";
import DietTypes from "./DietTypes";
import OnboardingSteps from "./OnboardingSteps";

const MealPlanOnboarding = ({ history }) => {
  const language = useSelector((state) => state.language.lang);
  const selectedAllergiesRef = useRef(null);
  const selectedMajorExclusionsRef = useRef(null);
  const selectedSubExclusionsRef = useRef(null);
  const selectedCustomExclusionsRef = useRef(null);
  const { path } = useRouteMatch();
  const { pathname, state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingInitData, dietGoals, dietTypes] = useMealPlanOnboarding();

  // console.log(selectedAllergiesRef.current, selectedSubExclusionsRef.current, selectedMajorExclusionsRef.current);

  const onContinuePressed = async () => {
    setLoading(true);
    const allergies = [...selectedAllergiesRef.current.values()];
    const majorExclusions = [...selectedMajorExclusionsRef.current.values()];
    const subExclusions = [...selectedSubExclusionsRef.current.values()];
    // console.log("--- allergies", allergies);
    // console.log("--- subExclusions", subExclusions);
    // console.log("--- majorExclusions", majorExclusions);
    const id = await userOnboardForMealPlan({
      dietGoal: state?.dietGoal?.id,
      dietType: state?.dietType?.id,
      allergies,
      majorExclusions,
      subExclusions,
      customExclusions: selectedCustomExclusionsRef.current,
    });
    if (id) {
      handleOnboardingSuccess({ didSkipMealPlanOnboarding: false });
    } else {
      setLoading(false);
      showErrorNotification();
    }
  };

  const onSkipPressed = async () => {
    setLoading(true);
    const id = await userOnboardForMealPlan({
      dietGoal: state?.dietGoal?.id,
      dietType: state?.dietType?.id,
      allergies: [],
      majorExclusions: [],
      subExclusions: [],
    });
    if (id) {
      handleOnboardingSuccess({ didSkipMealPlanOnboarding: true });
    } else {
      setLoading(false);
      showErrorNotification();
    }
  };

  const handleCustomExclusionsChanged = (customExclusions) => {
    // console.log("--- handleCustomExclusionsChanged", customExclusions);
    selectedCustomExclusionsRef.current = customExclusions;
  };

  const handleOnboardingSuccess = async ({ didSkipMealPlanOnboarding }) => {
    await updateUserProfile({ didSkipMealPlanOnboarding });
    showSuccessNotification(language["meal-plan-onboarding-success"] ?? "meal-plan-onboarding-success");
    history.replace(subscriptionPlanDetailsRoute.path);
  };

  const handleSelectedAllergiesChanged = (allergiesMap) => {
    // console.log("--- handleSelectedAllergiesChanged", [...allergiesMap.values()]);
    selectedAllergiesRef.current = allergiesMap;
  };

  const handleSelectedMajorExclusionsChanged = (majorExclusionsMap) => {
    // console.log("--- handleSelectedMajorExclusionsChanged", [...majorExclusionsMap.values()]);
    selectedMajorExclusionsRef.current = majorExclusionsMap;
  };

  const handleSelectedSubExclusionsChanged = (subExclusionsMap) => {
    // console.log("--- handleSelectedSubExclusionsChanged", [...subExclusionsMap.values()]);
    selectedSubExclusionsRef.current = subExclusionsMap;
  };

  let content;
  if (loadingInitData) {
    content = (
      <div className="d-flex justify-content-center mt-5">
        <Loading />
      </div>
    );
  } else if (!dietGoals || !dietTypes) {
    content = (
      <div className="d-flex justify-content-center mt-5">
        <h4>{language["error"] ?? "error"}</h4>
      </div>
    );
  } else {
    content = (
      <>
        <ProtectedRoute render={(props) => <DietGoals {...props} dietGoals={dietTypes} />} path={path} exact={true} />
        <ProtectedRoute
          render={(props) => <DietTypes {...props} dietTypes={dietTypes} />}
          path={`${path}/diet-types`}
          exact={true}
        />
        <ProtectedRoute
          render={(props) => (
            <AllergiesAndExclusions
              {...props}
              onSelectedMajorExclusionsChanged={handleSelectedMajorExclusionsChanged}
              onSelectedAllergiesChanged={handleSelectedAllergiesChanged}
              onSelectedSubExclusionsChanged={handleSelectedSubExclusionsChanged}
              onSelectedCustomExclusionsChanged={handleCustomExclusionsChanged}
            />
          )}
          path={`${path}/allergies-exclusions`}
          exact={true}
        />
      </>
    );
  }

  if (loading) {
    content = (
      <Spin
        indicator={
          <div className="d-flex justify-content-center">
            <Loading />
          </div>
        }
      >
        {content}
      </Spin>
    );
  }

  const skipContinueButtons = (
    <div>
      <Button disabled={loading} onClick={onSkipPressed} type="link">
        {language["skip"] ?? "skip"}
      </Button>
      {pathname === "/mealplan/onboarding/allergies-exclusions" && (
        <Button disabled={loading} onClick={onContinuePressed} type="link">
          {language["continue"] ?? "continue"}
        </Button>
      )}
    </div>
  );

  return (
    <div className="meal-plan-onboarding">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language["meal-plan-onboarding"] ?? "meal-plan-onboarding"}
        hideCustomclass={true}
      />
      <div className="meal-plan-onboarding-card">
        <div className="d-flex justify-content-center align-items-center mb-2">
          <h5>{language["Ready to give it a shot? Let us know your diet"] ?? "Ready to give it a shot? Let us know your diet"}</h5>
          {/* {skipContinueButtons} */}
        </div>
        <div className="d-flex justify-content-center mb-3">
          {/* <OnboardingSteps /> */}
        </div>
        <div className=" d-flex justify-content-center mb-3">{content}</div>
        {/* <div className="d-flex justify-content-end">{skipContinueButtons}</div> */}
      </div>
    </div>
  );
};

export default MealPlanOnboarding;
