import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClinicProfileCard from "./ClinicProfileCard";
import { ClinicCharts } from "./ClinicCharts";
import ClinicPendingCard from "./ClinicPendingCard";
import ClientScheduled from "./ClientScheduled";
import { settings } from "../config";
import actions from "../redux/clinic/actions";
import axios from "../services/Axios";
import { getCurrencies } from "../redux/clinic/actionCreators";
import TopMenuDashboard from "./CommonControls/TopMenuDashboard";

const Clinic = () => {
  const currencies = useSelector((state) => state.clinic.currencies);
  const dispatch = useDispatch();
  const notifArr = useSelector((state) => state.clinic.pending_appointments);
  const language = useSelector((state) => state.language);
  const { id } = useSelector((state) => state.auth.userData);

  // localStorage.getItem("scheduledClinic", false);
  useEffect(() => {
    if (notifArr && notifArr.length === 0) {
      getClinicData();
    }
    if (currencies && currencies.length === 0) {
      dispatch(getCurrencies());
    }
  }, []);

  const getClinicData = async () => {
    const res = await axios.get("/getClinicdashboardData", {
      params: { clinicId: id },
    });
    dispatch({
      type: actions.SET_PENDING_APPOINTMENTS,
      payload: res.data.response,
    });
  };

  return (
    <div className="row pb-5 doctor-dashboard-home clinic">
      {/* <div
        className={
          "container justify-content-between p-0 m-0 align-items-center" +
          `${language.direction}`
        }
      > */}
      {/* <div className="d-flex align-items-center">
          <BackButton />
          <div className="pl-4 d-flex align-items-center">
            <LogoSideButton />
            <h3 className="Page-title-custom ml-3">
              {language.lang.clinic_dashboard}
            </h3>
          </div>
        </div> */}
      {/* <div className="d-flex">
          <NotificationsButton className="ml-auto" />
          <ProfileCircleButton />
        </div>{" "} */}
      <div className="row">
        <TopMenuDashboard
          shouldHideProfileButton={false}
          shouldHideNotificationButton={false}
          shouldHideLogoButton={false}
          shouldHideSettingsButton={false}
          shouldGeneralSettingsButton={true}
          pageTitle={language.lang.clinic_dashboard}
        />
      </div>
      {/* </div> */}
      <>
        <div className="row mx-0 mt-4 justify-content-between">
          <div className="col-12 col-md-4 col-xl-4">
            <ClinicProfileCard />
          </div>
          <div className="col-12 col-md-8 col-xl-8 d-flex flex-column justify-content-between">
            <ClinicPendingCard />
          </div>
        </div>

        <div className="row mx-0 mt-4 justify-content-between flex-wrap-reverse">
          <div className="col-12 col-md-4 col-xl-4">
            <ClinicCharts />
            <ClinicCharts />
          </div>
          <div className="col-12 col-md-8 col-xl-8 d-flex flex-column justify-content-between">
            <ClientScheduled />
          </div>
        </div>
      </>
    </div>
  );
};

export default Clinic;
