import actions from "./actions";

export const initState = {
  remoteStreamIDs: [],
  agoraClient: {},
  enableVideo: true,
  enableAudio: true,
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.ENABLE_VIDEO:
      return Object.assign({}, state, {
        enableVideo: payload,
      });
    case actions.ENABLE_AUDIO:
      return Object.assign({}, state, {
        enableAudio: payload,
      });
    case actions.REMOVE_REMOTE_STREAM_ID:
      return Object.assign({}, state, {
        remoteStreamIDs: state.remoteStreamIDs.filter((id) => id !== payload),
      });
    case actions.ADD_REMOTE_STREAM_ID:
      return Object.assign({}, state, {
        remoteStreamIDs: [...state.remoteStreamIDs, payload],
      });
    case actions.SET_AGORA_CLIENT:
      return Object.assign({}, state, {
        agoraClient: payload,
      });
    default:
      return state;
  }
};

export default reducer;
