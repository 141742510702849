import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import AppStoreLinkButton from "./AppStoreLinkButton";
import PlayStoreLinkButton from "./PlayStoreLinkButton";

const MobileAppPropmtModal = ({ show, onCloseClicked }) => {
  const language = useSelector((state) => state.language.lang);
  return (
    <Modal
      className="mobile-app-propmt-modal"
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <p className="text-center">{language.goToMobileApp}</p>
        <PlayStoreLinkButton />
        <AppStoreLinkButton />
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onCloseClicked} className="btn btn-primary close-btn">
          {language.close}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MobileAppPropmtModal;
