import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from 'react-bootstrap/Table';
import {
  customerCalendarRoute,
  dashboardRoute,
  dashboardSessionDetailRoute,
  stripePaymentCheckoutRoute,
} from "../../routes";
import LiveSessionDetailPoints from "./LiveSessionDetailPoints";
import moment from "moment";
import { clientSignInRoute } from "../../routes";
import {
  checkIfRegisteredForLiveSession,
  customerRegisterForSession,
} from "../../services/LiveSessionsNetworkService";
import Loading from "../Loading";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../utility/Helpers";
import SessionRegisterSuccessModal from "./SessionRegisterSuccessModal";
import { liveSessionPaymentModuleType } from "../../utility/constants";
import TopMenuDashboard from "../CommonControls/TopMenuDashboard";
import NewBackButton from "../NewBackButton";
import TopMenu from "../../components/CommonControls/TopMenu";

const LiveSessionDetailsCard = () => {
  const history = useHistory();
  const language = useSelector((state) => state.language);
  const [sessionDetails, setSessionDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [alreadyRegisteredForSession, setAlreadyRegisteredForSession] =
    useState(false);
  const [showSessionRegisterSuccessModal, setShowSessionRegisterSuccessModal] =
    useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  // console.log("--- session obj ", sessionDetails);
  const { userData } = useSelector((state) => state.auth);
  const isAuth = userData && Object.values(userData).length > 0;
  const attendeesCount =
    sessionDetails.attendeesCount &&
    sessionDetails.attendeesCount.aggregate &&
    sessionDetails.attendeesCount.aggregate.count;

  useEffect(() => {
    if (history.location.state.session) {
      setSessionDetails(history.location.state.session);
    }
  }, [history.location.state.session]);

  useEffect(() => {
    if (!isAuth) {
      setLoading(false);
      return;
    }
    if (!sessionDetails || Object.values(sessionDetails).length === 0) return;
    checkIfRegisteredForLiveSession({ sessionID: sessionDetails.id })
      .then((didRegister) => setAlreadyRegisteredForSession(didRegister))
      .finally(() => setLoading(false));
  }, [isAuth, sessionDetails]);

  // console.log({ sessionDetails });

  const onRegisterSsessionTap = () => {
    if (!isAuth) {
      showSuccessNotification(language.lang.signInToRegisterForEvent);
      return history.push(clientSignInRoute.path, {
        nextRoute: dashboardSessionDetailRoute.path,
        session: sessionDetails,
      });
    }
    handleSessionRegister();
  };

  const handleSessionRegister = async () => {
    setRegisterLoading(true);
    const userSessionID = await customerRegisterForSession({
      sessionID: sessionDetails.id,
    });
    setRegisterLoading(false);
    if (sessionDetails.is_paid) {
      handleRegisterPaidSession({ userSessionID });
    } else {
      handleRegisterFreeSession({ userSessionID });
    }
  };

  const handleRegisterPaidSession = ({ userSessionID }) => {
    // const paymentRedirectURL = `${window.location.protocol}//${window.location.host}${dashboardLiveSessionRoute.path}?amount=${sessionDetails.amount}&currency=${sessionDetails.amount_currency}&sessionID=${sessionDetails.id}&hostID=${sessionDetails.host_id}&startDateTime=${sessionDetails.start_date_time_local}&endDateTime=${sessionDetails.end_date_time_local}`;
    const paymentRedirectURL = `${window.location.protocol}//${window.location.host}?amount=${sessionDetails.amount}&currency=${sessionDetails.amount_currency}&sessionID=${sessionDetails.id}&hostID=${sessionDetails.host_id}&startDateTime=${sessionDetails.start_date_time_utc}&endDateTime=${sessionDetails.end_date_time_utc}&viewButtonRedirectLink=${customerCalendarRoute.path}&buttonText=viewEvent`;
    history.push(stripePaymentCheckoutRoute.path, {
      moduleID: userSessionID,
      currency: sessionDetails.amount_currency,
      amount: sessionDetails.amount,
      moduleType: liveSessionPaymentModuleType,
      redirectURL: paymentRedirectURL,
    });
  };

  const handleRegisterFreeSession = async ({ userSessionID }) => {
    if (userSessionID == null) {
      return showErrorNotification(language.lang.sessionIsFullyBooked);
    }
    setShowSessionRegisterSuccessModal(true);
  };

  const handleModalClose = () => {
    history.replace(dashboardRoute.path);
  };

  const viewInCalendarPressed = () => {
    history.replace(customerCalendarRoute.path);
  };

  // const language = useSelector((state) => state.language);

  let registerButton;
  if (alreadyRegisteredForSession) {
    registerButton = (
      <button onClick={viewInCalendarPressed} className="btn btn-primary">
        {language.lang.viewInCalendar}
      </button>
    );
  } else if (registerLoading) {
    registerButton = <Loading />;
  } else {
    registerButton = (
      <button
        type="button"
        onClick={onRegisterSsessionTap}
        disabled={registerLoading}
        className="btn btn-primary shadow-none"
      >
        {language.lang.registerEvent}
      </button>
    );
  }

  let paidTextContent;
  if (alreadyRegisteredForSession) {
    paidTextContent = (
      <div className="text-success">{language.lang.enrolled}</div>
    );
  } else if (sessionDetails.is_paid) {
    paidTextContent = (
      <div className="">
        {sessionDetails.amount_currency} {sessionDetails.amount}
      </div>
    );
  } else {
    paidTextContent = (
      <div className="text-success">{language.lang.free}</div>
    );
  }

  return (
    <section className="row">
      <TopMenu
          shouldHideBackButton={false}
          shouldHideProfileButton={false}
          shouldHideNotificationButton={false}
          shouldHideLogoButton={false}
          pageTitle={language.lang.eventdetails}
        />
      {loading ? (
        <Loading />
      ) : (
      <div className="izi-container no-gutter">
      <div className="d-flex flex-sm-row flex-column w-100">
        <div className="order1 p2">
        <div className="mm-card" style={{minWidth:"17.5rem"}}>
        <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <div className="d-flex flex-row w-100 justify-content-start">
                    <div className="order1 p2">
                    <div className="big-online-green-tag"></div>
                    </div>
                    <div className="order2 p2 ml-3">
                    <h4 className="card-text">{sessionDetails.title}</h4>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                <div className="d-flex flex-row p2">
                  <div className="order1 mr-auto p2">
                    <h6 className="card-text">{paidTextContent}</h6>
                  </div>
                  <div className="order2 p2">
                    <h6>{attendeesCount} {language.lang.members}</h6>
                  </div>
                </div>
                </li>
                <li className="list-group-item">
                  <h6 className="card-text">
                    {language.lang.date} :
                    {moment .utc(sessionDetails.start_date_time_utc) .local() .format("DD-MM-YYYY")}</h6>
                  <h6>
                    {language.lang.time} :
                    {moment .utc(sessionDetails.start_date_time_utc).local().format("h:mm a")}{" "} to 
                    {" "}{moment .utc(sessionDetails.end_date_time_utc).local().format("h:mm a")}
                  </h6>
                </li>
            </ul>
            <div className="btn btn-group">{registerButton}</div>
            </div>
            <div className="card mb-2">
              <img className="session-image-details-page" src={sessionDetails.session_image_url} alt={sessionDetails.title}/>
            </div>
        </div>
        <div className="order2 p2">
        
          <div className="ml-2 mb-2" style={{minHeight:"40rem"}}>
          <div className="mt-1 px-4">
            <Table responsive>
              <thead>
                <tr>
                  <th>{language.lang.description}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                <p className="card-text">{sessionDetails.description}</p>
                </tr>
              </tbody>
            </Table>
          </div>
          <LiveSessionDetailPoints className="mb-2" data={sessionDetails.key_points} />
        </div>
        </div>
      </div>
      </div>
      )}
      <SessionRegisterSuccessModal
        sessionDetails={sessionDetails}
        show={showSessionRegisterSuccessModal}
        onHide={handleModalClose}
      />
    </section>
  );
};

export default LiveSessionDetailsCard;
