import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from "./utility/ProtectedRoute";
import store from "./redux/store";
import { ReactNotifications } from "react-notifications-component";
import { initializeApp } from "firebase/app";
import routes, {
  clientSignInRoute,
  corporateSignInRoute,
  forgotpasswordRoute,
  resetPasswordRoute,
  signUpRoute,
  coSignUpRoute,
  telehealthlanding,
  eventslanding,
  mealplanslanding,
  fitnesslanding,
  phoneSigninRoute,
  phoneSigninVerifyOTPRoute,
  signupUserNameRoute,
  emailSigninRoute,
  completeEmailLinkSigninRoute,
} from "./routes";
import SocketIO from "./components/SocketIO";
import "react-notifications-component/dist/theme.css";
import "./assets/styles/styles.scss";
import "./assets/styles/responsivestyles.scss";
import { fetchlanguage, fetchregions } from "./redux/language/actionCreators";
import ScrollToTop from "./components/ScrollToTop";
import NoAuthOnlyRoute from "./utility/NoAuthOnlyRoute";
import RouteNotFound from "./components/RouteNotFound";
import GoogleAnalytics from "./components/GoogleAnalytics";
import { firebaseConfig } from "./config";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFirebaseAppAction } from "./redux/firebase/acitonCreators";
import AuthOnlyRoute from "./utility/AuthOnlyRoute";
import Loading from "./components/Loading";

store.dispatch(fetchlanguage());
store.dispatch(fetchregions());

const App = () => {
  const firebaseApp = useSelector((state) => state.firebase.app);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const fireAuth = getAuth();
  //   fireAuth.onAuthStateChanged((user) => {
  //     console.log("--- onAuthStateChanged", user);
  //     // console.log("--- current user", fireAuth.currentUser);
  //   });
  // }, []);

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    dispatch(setFirebaseAppAction({ app }));
  }, [dispatch]);

  if (!firebaseApp) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <>
      {/* <Splash /> */}
      {/* <CirclesBackground> */}
      <Router>
        <GoogleAnalytics />
        <ReactNotifications />
        <SocketIO />
        <ScrollToTop>
          <Switch>
            <NoAuthOnlyRoute path={completeEmailLinkSigninRoute.path} component={completeEmailLinkSigninRoute.component} />
            <NoAuthOnlyRoute path={emailSigninRoute.path} component={emailSigninRoute.component} />
            <AuthOnlyRoute path={signupUserNameRoute.path} component={signupUserNameRoute.component} />
            <NoAuthOnlyRoute path={phoneSigninVerifyOTPRoute.path} component={phoneSigninVerifyOTPRoute.component} />
            <NoAuthOnlyRoute path={phoneSigninRoute.path} component={phoneSigninRoute.component} />
            <NoAuthOnlyRoute path={clientSignInRoute.path} component={clientSignInRoute.component} />
            <NoAuthOnlyRoute path={resetPasswordRoute.path} component={resetPasswordRoute.component} />
            <NoAuthOnlyRoute path={forgotpasswordRoute.path} component={forgotpasswordRoute.component} />
            <NoAuthOnlyRoute path={signUpRoute.path} component={signUpRoute.component} />
            <NoAuthOnlyRoute path={coSignUpRoute.path} component={coSignUpRoute.component} />
            <NoAuthOnlyRoute path={telehealthlanding.path} component={telehealthlanding.component} />
            <NoAuthOnlyRoute path={eventslanding.path} component={eventslanding.component} />
            <NoAuthOnlyRoute path={mealplanslanding.path} component={mealplanslanding.component} />
            <NoAuthOnlyRoute path={fitnesslanding.path} component={fitnesslanding.component} />
            <NoAuthOnlyRoute path={corporateSignInRoute.path} component={corporateSignInRoute.component} />
            {routes.map((route) => {
              if (route.protected) {
                return <ProtectedRoute key={route.path} path={route.path} exact={route.exact} component={route.component} />;
              }
              return <Route component={route.component} key={route.path} path={route.path} exact={route.exact} />;
            })}
            <Route path="*" component={RouteNotFound} />
          </Switch>
        </ScrollToTop>
      </Router>
      {/* </CirclesBackground> */}
    </>
  );
};

export default App;
