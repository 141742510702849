import React from 'react'
import { FaCog } from 'react-icons/fa'
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { settingsRoute } from '../../../routes';
import Config from '../../../config/index';
const TopDashboardMenuSettings = () => {
    const isLoggedIn = useSelector((state) => state.auth.login);
    const history = useHistory();
    const navigate = () => {
        //settingsRoute
        history.push(settingsRoute.path);
    }
    if(!isLoggedIn){
        return (
            <div className="d-flex justify-content-center align-items-center cursor-pointer" onClick={navigate}>
                <img src={Config.dashsettingsicon} alt="SettingsIcon" className="dash-circle-button"/>
            </div>
        )
    }else{
        return (<></>)
    }
}

export default TopDashboardMenuSettings;
