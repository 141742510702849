import React from "react";
import { useHistory, useLocation } from "react-router-dom";
// import { Prompt } from "react-router";

const BackButton = ({ returnPropmt, onClick, className }) => {
  // const [shouldReturnPropmt, setShouldReturnPropmt] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const handleClick = () => {
    if (onClick) {
      return onClick();
    }
    // if (returnPropmt) {
    //   setShouldReturnPropmt(true);
    //   return;
    // }
    location.pathname !== "/" && history.goBack();
  };

  // if (shouldReturnPropmt) {
  //   return <Prompt when={true} message="Are you sure you want to leave?" />;
  // }

  return (
    <div
      className={
        className
          ? `backbutton-insight ${className}`
          : "backbutton-insight float-right"
      }
      style={{ display: "block", textAlign: "center" }}
    >
      <button onClick={handleClick} className="btn px-2">
        <i
          className="far fa-long-arrow-left fa-2x"
          style={{ color: "#012f82" }}
        ></i>
      </button>
    </div>
  );
};

export default BackButton;
