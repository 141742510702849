import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  dashboardPrescriptionRoute,
  dashboardAppointmentsRoute,
} from "../routes";

const ViewPrescriptionButton = ({ presID }) => {
  const history = useHistory();
  const language = useSelector((state) => state.language);

  const onClick = () => {
    if (presID) {
      history.push(dashboardPrescriptionRoute.path, {
        presId: presID,
      });
    } else {
      history.push(dashboardAppointmentsRoute.path, {
        previous: "Previous",
      });
    }
  };

  return (
    <button
      onClick={onClick}
      className="btn view-prescription-button text-white mt-2"
      style={{
        backgroundColor: "#1B68DF",
        borderRadius: "50px",
        color: "#fff",
      }}
    >
      {language.lang.view_prescription}
    </button>
  );
};

export default ViewPrescriptionButton;
