import React from 'react'
import { Chart } from "react-google-charts";
import { useSelector } from 'react-redux';
export const ClinicCharts = () => {
  const language = useSelector((state) => state.language);
    return (
        <div className="mm-card doctor-dashboard-card overflow-hidden my-3 card-color position-relative min-card-width" style={{height:'min-content'}}>
            <div className="left d-flex flex-column text-center">
                <span>12</span><span>Left</span>
            </div>
            <div className="right">+25%</div>
            <div className="right-bottom d-flex flex-column text-center">
                <span>19</span><span>{language.lang.today}</span>
            </div>
            <Chart
          width={"300px"}
          height={"100px"}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["x", "Patients"],
            [0, 0],
            [1, 10],
            [2, 23],
            [3, 17],
            [4, 18],
            [5, 9],
            [6, 11],
            [7, 27],
            [8, 33],
            [9, 40],
            [10, 32],
            [11, 35]
          ]}
          options={{
            legend: { position: "none" },
            axes: {
              x: {
                0: { side: "bottom", label: "" }
              },
              y: {
                0: { side: "bottom", label: "" }
              }
            },
            hAxis: {
              baselineColor: "transparent",
              gridlineColor: "transparent",
              textPosition: "none",
              title: ""
            },
            vAxis: {
              baselineColor: "transparent",
              gridlineColor: "transparent",
              textPosition: "none",
              title: ""
            },
            backgroundColor: {
                fill:'transparent'     
                },
            series: {
              0: { curveType: "function", color: "#b52e03", lineWidth: 3, lineDashStyle: [4, 4] }
            }
          }}
          rootProps={{ "data-testid": "1" }}
        />
        </div>
    )
}
