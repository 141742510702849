import { useState, useEffect } from "react";
import ProfileCircleButton from "../components/ProfileCircleButton";
import NotificationsButton from "../components/NotificationsButton";
import BackButton from "../components/BackButton";
import sign from "../assets/images/sign.png";
import { Row, Container } from "react-bootstrap";
import PrescriptionSection1 from "./PrescriptionSection1";
import PrescriptionSection3 from "./PrescriptionSection3";
import axios from "../services/Axios";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../utility/Helpers";
import { useSelector, useDispatch } from "react-redux";
import { dashboardRoute } from "../routes";
import { currentAppointmentDetailsKey } from "../utility/constants";
import { updatePatientPrescriptionAction } from "../redux/practitioner/actionCreators";
import { useLocation } from "react-router-dom";
import UploadPrescription from "./UploadPrescription";
import { Modal, Button } from "react-bootstrap";
import TopMenu from "./CommonControls/TopMenu";

const CreatePrescription = ({ history }) => {
  const reduxStore = useSelector((state) => state);
  const newInput = useSelector((state) => state.practitioner.prescriptionForm);
  const [loadingState, setLoadingState] = useState(false);
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const { id, role, name } = reduxStore.auth.userData;
  const [viewType, setviewType] = useState(false);

  const [Input, setInput] = useState({
    fName: newInput.fName || "",
    lName: newInput.lName || "",
    age: newInput.age || "",
    gender: newInput.gender || "",
    visit: newInput.visit || "",
    diagnosis: newInput.diagnosis || "",
    allergies: newInput.allergies || "",
  });
  const [list, setList] = useState(newInput.medication);
  const [medicineList, setMedicineList] = useState(newInput.medicineList);
  const [labTestList, setLabTestList] = useState(newInput.labTestList);
  const location = useLocation();
  const [appointDetails, setappointDetails] = useState(
    JSON.parse(localStorage.getItem(currentAppointmentDetailsKey))
  );
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (location.pathname !== "/create-prescription") {
      getPatientDetails();
    }
  }, []);

  const getPatientDetails = async () => {
    const appointmentDetails = JSON.parse(
      localStorage.getItem(currentAppointmentDetailsKey)
    );
    const res = await axios.get("/getPatientDetails", {
      params: { patientId: appointmentDetails.user_id },
    });

    const patientData = res.data.response[0];
    let currentYear = new Date().getFullYear();
    let defaultYear= parseInt(currentYear) - 18;
    let patientAge = "";
    if(!!patientData.birth_date){
      patientAge = currentYear - parseInt(patientData.birth_date.split("-")[0] ?? defaultYear);
    }
    const formData = {
      fName: patientData.name,
      lName: patientData.surname ? patientData.surname : "-",
      age: patientAge,
      gender: patientData.gender_id === 1 ? "Male" : "Female",
    };

    setInput(formData);

    dispatch(updatePatientPrescriptionAction(formData));
  };

  const handleOnInputChange = (e) => {
    var nam = e.target.name;
    var val = e.target.value;
    if (nam === "visit") {
      if (parseInt(val) < 0) {
        val = Math.abs(parseInt(val));
      }
    }
    setInput((prevState) => {
      return { ...prevState, [nam]: val };
    });
  };

  const createPrescriptionHandler = async () => {
    const appointmentDetails = JSON.parse(
      localStorage.getItem(currentAppointmentDetailsKey)
    );
    if (!appointmentDetails) {
      // TODO: handle this error
      showErrorNotification();
      return;
    }

    if (!validForm()) {
      showErrorNotification("Invalid form");
      return;
    }
    localStorage.removeItem("prescriptionForm");
    const presData = {
      appointmentId: appointmentDetails.id,
      customerId: appointmentDetails.user_id,
      practitionerId: id,
      diagnosis: Input.diagnosis,
      drugAlergies: Input.allergies,
      medication: medicineList,
    };
    // console.log("--- request data", presData);
    // TODO: Check if medicen list is being validated and passed to API
    // return;

    setLoadingState(true);
    try {
      const response = await axios.post(`/createPrescription`, presData);
      if (response.data.response) {
        // localStorage.removeItem(prescriptionAppointmentKey);
        showSuccessNotification("Prescription created successfully");
        dispatch({
          type: "UPDATE_PATIENT_PRESCRIPTION",
          payload: {
            fName: "",
            lName: "",
            age: "",
            gender: "",
            visit: "",
            diagnosis: "",
            allergies: "",
            medicineList: [],
            labTestList: [],
            medication: [],
          },
        });
        history.replace(dashboardRoute.path);
      } else {
        showErrorNotification();
      }
      setLoadingState(false);
    } catch (e) {
      showErrorNotification(e.response.data.error);
      setLoadingState(false);
    }
  };

  const validForm = () => {
    let formIsValid = true;
    const formFields = {
      ...Input,
      medication: medicineList.length > 0 ? medicineList : null,
    };

    Object.values(formFields).forEach((value) => {
      if (!value) formIsValid = false;
    });
    return formIsValid;
  };

  // const endCall = () => {
  //   dispatch(setPractitionerAvailability(1));
  //   if (role === userRoleDoctor) {
  //     localStorage.setItem(
  //       prescriptionAppointmentKey,
  //       JSON.stringify(reduxStore.practitioner.currentAppointment)
  //     );
  //     history.replace(dashboardRoute.path);
  //   }
  // };

  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  today = dd + "-" + mm + "-" + yyyy;

  const handleOnBoardModalCloseOk = () => {
    history.push(dashboardRoute.path);
  };

  const handleOnBoardModalCloseNo = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    setModalOpen(true);
  };

  return (
    <div className="consultation create-prescription">
      <div className="dashboard-book-consultation">
        {/* <div className="col-12 notifications-and-profile mt-3 mt-md-0 justify-content-between mx-2">
          <div className="book-back-btn">
            <BackButton />
            <span className="consultation-heading">
              {language.lang.in_consultation}
            </span>
          </div>
          <div className="d-flex" style={{ paddingRight: "15px" }}>
            <NotificationsButton />
            <ProfileCircleButton />
          </div>
        </div> */}
        
      <TopMenu
          shouldHideBackButton={false}
          shouldHideProfileButton={false}
          shouldHideNotificationButton={false}
          shouldHideLogoButton={false}
          pageTitle={<p>{language.lang["create-prescription"]} {"/"} {language.lang["upload_prescription"]} </p>}
          hideCustomclass={true}
        />
      </div>
      <Container
        fluid
        className="my-3 py-3"
        style={{
          background: "#fff",
        }}
      >
        <div className="row mx-0 align-items-center tab-row">
          <div className="col-6 col-lg-5 pl-0">
            <div
              className="tab"
              onClick={() => {
                setviewType(false);
              }}
            >
              <span className={!viewType ? "selected" : ""}>
                {" "}
                {language.lang["create-prescription"]}
              </span>
              {/* {!viewType && <div className="underline"></div>} */}
            </div>
          </div>
          <div className="col-6 co-lg-4">
            <div
              className="tab"
              onClick={() => {
                setviewType(true);
              }}
            >
              <span className={viewType ? "selected" : ""}>
                {language.lang["upload_prescription"]}
              </span>
              {/* {viewType && <div className="underline"></div>} */}
            </div>
          </div>
        </div>
        {!viewType && (
          <Row className="justify-content-around mx-0">
            <PrescriptionSection3
              Input={Input}
              setInput={setInput}
              handleOnInputChange={handleOnInputChange}
              setMedicineList={setMedicineList}
              medicineList={medicineList}
              setList={setList}
              setLabTestList={setLabTestList}
              className="consultation-section-3"
            />
            <PrescriptionSection1
              centerName="Medical Prescription by Klippa Medical Center"
              centerAddress="Lubeckweg 2, 9723HE Groningen, The Netherlands"
              patientName={Input.fName + " " + (Input.lName || "")}
              age={Input.age}
              date={today}
              medicineList={medicineList}
              doctorName={name}
              doctorSignature={sign}
              headingDoc={language.lang.preview}
              width={6}
              createBtn={true}
              createPrescriptionHandler={createPrescriptionHandler}
              appointDetails={appointDetails}
              loadingState={loadingState}
              setLoadingState={setLoadingState}
              handleCancel={handleCancel}
            />
          </Row>
        )}
        {viewType && (
          <UploadPrescription
            appointDetails={appointDetails}
            handleCancel={handleCancel}
          />
        )}
      </Container>
      <Modal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="close-prescription"
      >
        <Modal.Body>{language.lang.doYouWantSkipPrescription}</Modal.Body>
        <Modal.Footer>
          <Button className="ok-btn" onClick={handleOnBoardModalCloseOk}>
            {language.lang.apply}
          </Button>
          <Button className="ok-btn" onClick={handleOnBoardModalCloseNo}>
            {language.lang.cancel}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreatePrescription;
