import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MMRadioButton from "../MMRadioButton"
const ChoiceField = ({ first, second, value, handleInsuredChange, schedule }) => {
  const [select, setselect] = useState(1);
  const [now, setnow] = useState(true);
  useEffect(() =>{
    if(schedule && now){
      setnow(false)
    }else{
      handleInsuredChange(select === 1 ? true : false);
    }
  },[select]);
  useEffect(() =>{
    console.log("this value", value)
    if(value){
      setselect(1);
    }else{
      setselect(2);
    }
  },[value])
  return (
    <div className="gender-select-field flex-wrap">
      <div className="check-box-container">
        <MMRadioButton
          onClick={() => handleInsuredChange(true)}
          label={first}
          selected={select === 1}
          classes="mr-5"
        />
       {<MMRadioButton
          onClick={() => handleInsuredChange(false)}
          label={second}
          selected={select === 2}
        />}
      </div>
    </div>
  );
};


export default ChoiceField;