import { useState } from "react";
import BackButton from "../components/BackButton";
import ProfileCircleButton from "../components/ProfileCircleButton";
import NotificationsButton from "../components/NotificationsButton";
import { Row, Col, Modal } from "react-bootstrap";
import { Table } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";
import Switch from "react-switch";
import { GiAudioCassette } from "react-icons/gi";
import PractitionerModal from "./PractitionerModal";
import { useSelector } from "react-redux";

const Practitioner = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);
  const language = useSelector((state) => state.language);
  let stateObj = {
    date: "",
    workingHours: {
      startTime: "",
      endTime: "",
    },
    breakHours: {
      startTime: "",
      endTime: "",
    },
    available: false,
  };
  let arr = [];
  for (let i = 0; i < 50; i++) {
    let newObj = {
      ...stateObj,
    };
    arr.push(newObj);
  }
  const [array, setArray] = useState(arr);

  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
      width: 90,
    },
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
      // width: 15,
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      // width: 15,
    },
    {
      title: "Approved",
      dataIndex: "Approved",
      key: "Approved",
      width: 200,
    },
    {
      title: "Delete",
      dataIndex: "Delete",
      key: "Delete",
      width: 90,
    },
    {
      title: "Last Seen",
      dataIndex: "LastSeen",
      key: "LastSeen",
      // width: 10,
    },
  ];

  const data = [];
  for (let i = 0; i < 50; i++) {
    data.push({
      key: i,
      ID: <span>1467</span>,
      Category: <span>Doctor</span>,
      Name: <span>Dylan Michael</span>,
      Approved: (
        <div className="d-flex">
          <Switch
            checked={array[i].available}
            onChange={(val) => {
              setArray((prevState) => {
                let newArr = [...prevState];
                newArr[i].available = val;
                return newArr;
              });
            }}
            onColor="#bff5de"
            onHandleColor="#56c596"
            handleDiameter={15}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"
          />
          <div style={{ marginLeft: "5px" }}>Not Approved</div>
        </div>
      ),
      Delete: (
        <div>
          {" "}
          <RiDeleteBin6Line color="red" style={{ cursor: "pointer" }} />{" "}
        </div>
      ),
      LastSeen: <span>03 Days ago</span>,
    });
  }

  return (
    <div>
      <div className="dashboard-book-consultation">
        <div className="col-12 notifications-and-profile mt-3 mt-md-0 justify-content-between mx-2">
          <div>
            <BackButton />
            <span className="consultation-heading">Settings</span>
          </div>
          <div style={{ paddingRight: "15px", display: "flex" }}>
            <NotificationsButton />
            <ProfileCircleButton />
          </div>
        </div>
      </div>

      <div className="practitioners">
        <Row className="mt-3">
          <Col xs={12} lg={8} className="practitioners--col-1">
            <div className="heading--dropdowns">
              <h5>Practitioners</h5>
              <div className="dropdowns">
                <select className="prac-input" name="" id="">
                  <option value="all" selected>
                    All{" "}
                  </option>
                  <option value="x">
                    Some {language.lang["other"]} Option
                  </option>
                </select>

                <select className="prac-input">
                  <option value="approved">Approved </option>
                  <option value="x">
                    Some {language.lang["other"]} Option
                  </option>
                </select>
              </div>
            </div>

            <Table
              columns={columns}
              dataSource={data}
              scroll={{ x: 900, y: 530 }}
              pagination={true}
            />
          </Col>

          <Col xs={12} lg={4} className="practitioners--col-2">
            <div>
              <button className="onboard-prac--btn" onClick={showModal}>
                Onboard Practitioners
              </button>
            </div>

            <div className="summary-col">
              <h6 className="summary-col--headings">{language.lang.summary}</h6>
              <div className="summary-col--block">
                <div className="rounded-circle--casset">
                  <GiAudioCassette size={25} />
                </div>
                <div>
                  <h6 className="prac-name">Total Practitioners</h6>
                  <p className="prac-num">180</p>
                </div>
              </div>
              <div className="summary-col--block">
                <div className="rounded-circle--casset">
                  <GiAudioCassette size={25} />
                </div>
                <div>
                  <h6 className="prac-name">Active Practitioners</h6>
                  <p className="prac-num">64</p>
                </div>
              </div>
              <h6 className="summary-col--headings">Time Slot Allocation</h6>

              <div className="time-slot">
                <div className="time-slot--row">
                  <div className="time-slot--col1">Category</div>
                  <div className="time-slot--col2">Slot Time</div>
                </div>
                <div className="time-slot--row">
                  <div className="time-slot--col1">Doctors</div>
                  <div className="time-slot--col2">
                    <select className="time-slot--input" name="" id="">
                      <option value="15" selected>
                        15 {language.lang.minutes}{" "}
                      </option>
                      <option value="30">30 {language.lang.minutes}</option>
                    </select>
                  </div>
                </div>

                <div className="time-slot--row">
                  <div className="time-slot--col1">Nutritionists</div>
                  <div className="time-slot--col2">
                    <select className="time-slot--input" name="" id="">
                      <option value="30" selected>
                        30 {language.lang.minutes}
                      </option>
                      <option value="15">15 {language.lang.minutes} </option>
                    </select>
                  </div>
                </div>

                <div className="time-slot--row">
                  <div className="time-slot--col1">Gym Trainers</div>
                  <div className="time-slot--col2">
                    <select className="time-slot--input" name="" id="">
                      <option value="45" selected>
                        45 {language.lang.minutes}{" "}
                      </option>
                      <option value="15">15 {language.lang.minutes} </option>
                      <option value="30">30 {language.lang.minutes}</option>
                    </select>
                  </div>
                </div>

                <div className="time-slot--row">
                  <div className="time-slot--col1">Therapist</div>
                  <div className="time-slot--col2">
                    <select className="time-slot--input" name="" id="">
                      <option value="15" selected>
                        15 {language.lang.minutes}{" "}
                      </option>
                      <option value="30">30 {language.lang.minutes}</option>
                    </select>
                  </div>
                </div>

                <div className="time-slot--row">
                  <div className="time-slot--col1">Counsellors</div>
                  <div className="time-slot--col2">
                    <select className="time-slot--input" name="" id="">
                      <option value="15" selected>
                        15 {language.lang.minutes}{" "}
                      </option>
                      <option value="30">30 {language.lang.minutes}</option>
                    </select>
                  </div>
                </div>

                <div className="time-slot--row">
                  <div className="time-slot--col1">
                    {language.lang.meditaiton} Gurus
                  </div>
                  <div className="time-slot--col2">
                    <select className="time-slot--input" name="" id="">
                      <option value="15" selected>
                        15 {language.lang.minutes}{" "}
                      </option>
                      <option value="30">30 {language.lang.minutes}</option>
                    </select>
                  </div>
                </div>

                <div className="time-slot--row">
                  <div className="time-slot--col1">Dietecian</div>
                  <div className="time-slot--col2">
                    <select className="time-slot--input" name="" id="">
                      <option value="30" selected>
                        30 {language.lang.minutes}
                      </option>
                      <option value="15">15 {language.lang.minutes} </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <button className="submit--btn">Submit</button>
              </div>
            </div>

            <Modal size="lg" show={isModalVisible} onHide={handleCancel}>
              <PractitionerModal />
            </Modal>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Practitioner;
