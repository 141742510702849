import { useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { useSelector } from "react-redux";
import { getIngredients } from "../services/CustomerNetworkService";
import { approvedFoodItemStatusID, unapprovedFoodItemStatusID, customIngredientTypeID } from "../utility/constants";

export const useAddIngredientsDrawer = ({ searchText }) => {
  const [loading, setLoading] = useState(true);
  const [ingredientTypesMap, setIngredientsTypesMap] = useState(new Map());
  const userID = useSelector((state) => state?.auth?.userData?.id);
  const debouncedSearchTerm = useDebounce(searchText, 3000);

  // Critical River, Varun Mishra, added debouncing for search functionality
  useEffect(() => {
    const searchHN = async () => {
      if (!loading) {
        setLoading(true);
      }
      let foodItemsResponse = [];
      const typesMap = new Map();
        const promisesResult = await Promise.all([
          getIngredients({
            isCustomFilter: false,
            statusIDs: [approvedFoodItemStatusID],
            searchText: debouncedSearchTerm,
            limit: 1000,
          }),
          getIngredients({
            isCustomFilter: false,
            statusIDs: [unapprovedFoodItemStatusID],
            searchText: debouncedSearchTerm,
            userID,
            limit: 1000,
          }),
        ]);
        foodItemsResponse = promisesResult[0];
        const customFoodItemsResponse = userID ? promisesResult[1] : null;
        typesMap.set(customIngredientTypeID, customFoodItemsResponse?.response ?? []);
        for (const foodItem of foodItemsResponse?.response ?? []) {
          if (foodItem.foodType?.id) {
            if (typesMap.has(foodItem.foodType?.id)) {
              typesMap.get(foodItem.foodType?.id).push(foodItem);
            } else {
              typesMap.set(foodItem.foodType?.id, [foodItem]);
            }
          }
        }
      setIngredientsTypesMap(typesMap);
      setLoading(false);
    };
    searchHN();
  }, [debouncedSearchTerm, userID]);

  return [loading, ingredientTypesMap];
};

export default useAddIngredientsDrawer;
