import { useEffect, useRef } from "react";
import io from "socket.io-client";
import { serverURL, settings } from "../config/index";
import { useSelector, useDispatch } from "react-redux";
import { ModalNotification, showSuccessNotification } from "../utility/Helpers";
import {
  acceptRejectNotificationType,
  userRoleClient,
  doctorAcceptedCallNotificationType,
  doctorStartedCallNotificationType,
  clientWalkinConfirmationAppointmentKey,
  userRoleClinic,
  sessionStartedNotificationType,
  sessionEndedNotificationType,
} from "../utility/constants";
import AcceptRejectNotificationContent from "../components/AcceptRejectNotificationContent";
import { useHistory } from "react-router-dom";
import { walkinConfirmationRoute } from "../routes";
import {
  setAppointmentDetailsAction,
  setDoctorStartedAppointmentIDAction,
  setDoctorStartedAppointmentDetails,
  setCompletedTransaction,
} from "../redux/client/actionsCreators";
import { UpdateNotificationsAction } from "../redux/user/actionsCreators";
import {
  addPendingWalkinRequestsAciton,
  updatePractitionerDashboardData,
} from "../redux/practitioner/actionCreators";
import { setSocketIDAction } from "../redux/authenitcation/actionCreator";
import ViewBookConsultation from "./ViewBookConsultation";
import axios from "axios";
import clinicactions from "../redux/clinic/actions";
import patientactions from "../redux/patient/actions";
import ToastViewPrescription from "./ToastViewPrescription";
import patientActions from "../redux/patient/actions";
import {
  hostMuteAllAction,
  hostUnmuteAllAction,
  updateSessionAttendeesListAction,
} from "../redux/liveSession/actionCreators";
import practitionerActions from "../redux/practitioner/actions";
import moment from "moment";
import { getScheduleTypes } from "../services/CustomerNetworkService";
import { getStartDate, getEndDate } from "../utility/Helpers";
import { refreshConversationList } from "../redux/fitnessBuddy/actionCreators";
const SocketIO = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const { waitingForAppointmentID } = useSelector((state) => state.client);
  const { id, role } = useSelector((state) => {
    return state.auth.userData || {};
  });
  const isAuth = id != null;
  const socketRef = useRef();

  useEffect(() => {
    if (!isAuth) return;
    socketRef.current = io.connect(serverURL, {
      transports: ["websocket", "polling", "flashsocket"],
      secure: true,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 10,
    });
    // socketRef.current = io.connect(serverURL, {
    //   transports: ["websocket", "polling", "flashsocket"],
    //   secure: true,
    //   reconnection: true,
    //   reconnectionDelay: 500,
    //   reconnectionAttempts: 10,
    // });

    socketRef.current.on("connect", (event) => {
      if (id) {
        socketRef.current.emit("joinUser", { userId: id, userRole: role });
      }
      console.log("--- connect", event);
    });

    socketRef.current.on("connected", ({ socketId }) => {
      console.log("--- connected =====", socketId);
      dispatch(setSocketIDAction(socketId));
    });
    socketRef.current.on("connected", async ({ socketId }) => {
      if (role === userRoleClinic) {
        const res = await axios.get(
          `${settings.apiBaseURL}/getWalkinRequestedAppointments`
        );
        const data = res.data.response;
        if (data === "No New Request") return;
        dispatch(addPendingWalkinRequestsAciton(data));
      }
    });

    // socketRef.current.on("disconnect", (event) => {
    //   console.log("--- disconnect", event, typeof event);
    // });

    // socketRef.current.on("message", ({ name, message }) => {
    //   console.log("--- on message");
    // });

    socketRef.current.on("newNotification", ({ notificationData }) => {
      console.log("--- newNotification", notificationData);
      // if (
      //   !notificationData.notificationDetails[0].notificationType.name ===
      //   "AcceptReject"
      // ) {
      //   showNotification(notificationData);
      // }
      // if (notificationData.notificationDetails) {
      //   showNotification(notificationData);
      // }
      if (
        notificationData.notificationDetails[0].notificationType.name ===
        "DoctorStartedCall"
      ) {
        showSuccessNotification(
          notificationData.notificationDetails[0].details
        );
      }
      if (
        notificationData.notificationDetails[0].notificationType.name ===
        "ScheduledAppointmentReminder"
      ) {
        showSuccessNotification(
          notificationData.notificationDetails[0].details,
          role === userRoleClient ? <ViewBookConsultation /> : ""
        );
      } else if (
        notificationData.notificationDetails &&
        notificationData.notificationDetails[0] &&
        notificationData.notificationDetails[0].notificationType.name ==
          "CallEnded"
      ) {
        localStorage.removeItem(clientWalkinConfirmationAppointmentKey);
        dispatch(setDoctorStartedAppointmentDetails(10));
        showSuccessNotification(
          notificationData.notificationDetails[0].details
        );
      } else if (
        notificationData.notificationDetails[0].notificationType.name ===
        "AcceptReject"
      ) {
        dispatch({
          type: "NEW_PATIENT_REQUEST",
          payload: notificationData,
        });
      } else if (
        notificationData.notificationDetails &&
        notificationData.notificationDetails.length > 0 &&
        notificationData.notificationDetails[0].notificationType &&
        notificationData.notificationDetails[0].notificationType.name ===
          sessionStartedNotificationType
      ) {
        showSuccessNotification(
          notificationData.notificationDetails[0].details
        );
      } else if (
        notificationData.notificationDetails &&
        notificationData.notificationDetails.length > 0 &&
        notificationData.notificationDetails[0].notificationType &&
        notificationData.notificationDetails[0].notificationType.name ===
          sessionEndedNotificationType
      ) {
        showSuccessNotification(
          notificationData.notificationDetails[0].details
        );
      }
      // if (
      //   role === userRoleClient &&
      //   (notificationData.notificationDetails[0].notificationType.name ===
      //     "AppointmentScheduled" ||
      //     notificationData.notificationDetails[0].notificationType.name ===
      //       "AppointmentUpdatedByClinic")
      // ) {
      //   ModalNotification(
      //     notificationData.notificationDetails[0].details,
      //     notificationData.notificationDetails[0].notificationType.name
      //   );
      // }
      dispatch(UpdateNotificationsAction(notificationData));
    });

    socketRef.current.on("acceptWalkinAppointment", (event) => {
      if (
        waitingForAppointmentID ===
        event.acceptWalkinAppointmentData.appointmentId
      ) {
        dispatch(
          setAppointmentDetailsAction(event.acceptWalkinAppointmentData)
        );
        history.replace(walkinConfirmationRoute.path);
        // history.push({
        //   pathname: walkinConfirmationRoute.path,
        //   state: event,
        // });
      }
    });

    socketRef.current.on("updatePatientAllAppointments", async (socketData) => {
      console.log("--- updatePatientAllAppointments", socketData.socketData);
      const customer = JSON.parse(localStorage.getItem("userData"));
      const today = new Date();
      const types = await getScheduleTypes();
      const scheduleTypeId = types[0].id;
      const startDate = moment(getStartDate(today)).format("YYYY-MM-DD");
      const endDate = moment(getEndDate(today)).format("YYYY-MM-DD");
      const res = await axios.get(
        `${settings.apiBaseURL}/getCustomerAllAppointments?startDate=${startDate}&endDate=${endDate}&customerId=${customer.id}&schedulerType=${scheduleTypeId}`
      );
      dispatch({
        type: patientactions.UPDATE_PATIENT_APPOINTMENTS,
        payload: res.data.response,
      });
    });

    socketRef.current.on("updateTopPractitioners", (socketData) => {
      dispatch({
        type: patientactions.RESET_TOP_PRACTITIONERS,
      });
      dispatch({
        type: patientactions.GET_TOP_PRACTITIONERS,
        payload: socketData.socketData,
      });
    });

    socketRef.current.on("updateClinicDashboardData", (socketData) => {
      console.log("--- updateClinicDashboardData", socketData.socketData);
      dispatch({
        type: clinicactions.SET_PENDING_APPOINTMENTS,
        payload: socketData.socketData,
      });
    });
    //updateClinicAllAppointments

    socketRef.current.on("updateClinicAllAppointments", (socketData) => {
      console.log("--- updateClinicAllAppointments", socketData.socketData);
      role === userRoleClinic &&
        dispatch({
          type: clinicactions.SET_NEED_TO_CALL_CALENDAR,
          payload: socketData.socketData.needToCall,
        });
      //role === userRoleDoctor  && dispatch({ type: practitioneractions.SET_NEED__TO_CALL_PRACTITIONER_CALENDAR, payload:socketData.socketData.needToCall});
    });

    socketRef.current.on("updatePractitionerAllAppointments", (event) => {
      console.log("--- updatePractitionerAllAppointments", event);
      dispatch({
        type: practitionerActions.SET_NEED__TO_CALL_PRACTITIONER_CALENDAR,
      });
    });

    socketRef.current.on("updateCustomerAllAppointments", (socketData) => {
      console.log("--- updateCustomerAllAppointments", socketData.socketData);
      dispatch({
        type: patientActions.SET_NEED_TO_CALL_CALENDAR,
        payload: socketData.socketData.needToCall,
      });
    });

    socketRef.current.on("updateAttendeesList", (event) => {
      console.log("--- updateAttendeesList");
      console.log(event);
      dispatch(
        updateSessionAttendeesListAction({
          attendeesList: event.updateAttendeesListData.attendees,
        })
      );
    });

    socketRef.current.on("controlSessionCall", (event) => {
      console.log("--- controlSessionCall");
      console.log(event);
      if (
        event.controlSessionCallData &&
        event.controlSessionCallData.callControlRequest === "muteAll"
      ) {
        dispatch(hostMuteAllAction());
      } else if (
        event.controlSessionCallData &&
        event.controlSessionCallData.callControlRequest === "unMuteAll"
      ) {
        dispatch(hostUnmuteAllAction());
      }
    });

    socketRef.current.on("updateWalkinAppointment", (event) => {
      console.log("--- updateWalkinAppointment", event);
      dispatch(setAppointmentDetailsAction(event.updateWalkinAppointmentData));
    });

    socketRef.current.on("updatePractitionerDashboardData", (event) => {
      console.log("--- updatePractitionerDashboardData", event);
      dispatch(updatePractitionerDashboardData({ data: event.socketData }));
    });

    socketRef.current.on("removeAcceptReject", (event) => {
      console.log("The event", event);
      dispatch({
        type: "REMOVE_PATIENT_FROM_NOTIF_ARR_BY_ID",
        payload: event.removeAcceptRejectData.details.appointmentId,
      });
    });

    socketRef.current.on(
      "updateConsultationStatus",
      ({ updateConsultationStatusData }) => {
        console.log(
          "--- updateConsultationStatus",
          updateConsultationStatusData
        );
        dispatch({
          type: patientActions.SET_NEED_TO_CALL_CALENDAR,
          payload: true,
        });
        dispatch(
          setDoctorStartedAppointmentIDAction(
            updateConsultationStatusData.appointmentId
          )
        );
        if (updateConsultationStatusData.statusId === 14) {
          localStorage.setItem(
            clientWalkinConfirmationAppointmentKey,
            JSON.stringify(updateConsultationStatusData)
          );
        }

        dispatch(
          setDoctorStartedAppointmentDetails(
            updateConsultationStatusData.statusId
          )
        );
      }
    );

    socketRef.current.on("getPaymentDetails", ({ getPaymentDetailsData }) => {
      dispatch(setCompletedTransaction(getPaymentDetailsData[0]));
    });
    socketRef.current.on("getMessage", async (socketData) => {
      dispatch(refreshConversationList(0, 50));
    });

    socketRef.current.on("prescriptionCreated", (event) => {
      console.log("The prescriptionCreated->> ", event);
      dispatch({
        type: patientActions.SET_NEED_TO_CALL_CALENDAR,
        payload: true,
      });
      const notificationText = language.lang.your_prescription_is_ready;
      showSuccessNotification(
        notificationText,
        <ToastViewPrescription
          presID={
            role === userRoleClient &&
            event.prescriptionCreatedData.prescriptionId
          }
        />
      );
      // dispatch(UpdateNotificationsAction({
      //           notificationDetails: [
      //             {
      //               details: notificationText,
      //               type: prescriptionReadyNotificationType,
      //               ...event.prescriptionCreatedData,
      //             },
      //           ],
      //         })
      //       );
    });
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [isAuth, waitingForAppointmentID, id, socketRef.current]);

  // useEffect(() => {
  //   if (role !== userRoleClient) return;
  //   socketRef.current.on(
  //     "prescriptionCreated",
  //     ({ prescriptionCreatedData }) => {
  //       console.log("--- prescriptionCreated", prescriptionCreatedData);
  //       const notificationText = language.lang.your_prescription_is_ready;
  //       showSuccessNotification(notificationText, <ViewPrescriptionButton />);
  //       dispatch(UpdateNotificationsAction({
  //         notificationDetails: [
  //           {
  //             details: notificationText,
  //             type: prescriptionReadyNotificationType,
  //             ...prescriptionCreatedData,
  //           },
  //         ],
  //       })
  //     );
  //   });
  // }, [role]);

  const showNotification = (notificationData) => {
    console.log({
      "from scoker notification details ":
        notificationData.notificationDetails[0],
    });
    if (
      notificationData.notificationDetails &&
      notificationData.notificationDetails[0].notificationType.name ===
        acceptRejectNotificationType
    ) {
      showAcceptRejectPractitionerNotification(notificationData);
    } else if (
      notificationData.notificationDetails &&
      notificationData.notificationDetails[0].notificationType.name ===
        doctorAcceptedCallNotificationType
    ) {
      showSuccessNotification(
        null,
        <p>
          {notificationData.notificationDetails[0].details}. Click on{" "}
          <span className="meet-doctor-notificaiton-text">
            {language.lang.meet_doctor}
          </span>{" "}
          button to start the call
        </p>
      );
    } else if (
      notificationData.notificationDetails &&
      notificationData.notificationDetails[0].notificationType.name ===
        doctorStartedCallNotificationType
    ) {
      showSuccessNotification(
        null,
        <p>
          {notificationData.notificationDetails[0].details}. Click on{" "}
          <span className="meet-doctor-notificaiton-text">Meet Doctor</span>{" "}
          button to start the call ---
        </p>
      );
    } else {
      showSuccessNotification(notificationData.notificationDetails[0].details);
    }
  };

  const showAcceptRejectPractitionerNotification = (notificationData) => {
    showSuccessNotification(
      `${notificationData.notificationDetails[0].details} with ${notificationData.details.name}`,
      <AcceptRejectNotificationContent notificationData={notificationData} />
    );
  };

  return <span className="socket-io"></span>;
};

export default SocketIO;
