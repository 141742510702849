import { Button } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import useIngredientName from "../../../../customHooks/useIngredientName";
import Config from "../../../../config/index";
import MealPlanBrokenImage from "../../Shared/MealPlanBrokenImage";

const IngredientListItem = ({ addedIngredient, onRemovePressed }) => {
  const { ingredient, unit } = addedIngredient;
  const getIngredientName = useIngredientName();
  const language = useSelector((state) => state.language.lang);
  const name = getIngredientName(ingredient);
  const gramsPerOneUnit = (unit?.weight_in_grams ?? 0) / (unit?.unit_quantity ?? 1);

  // console.log("--- added ", gramsPerOneUnit, addedIngredient);

  return (
    <div className="ingredient-list-item">
      <MealPlanBrokenImage url={ingredient.image_url} fallbackUrl={Config.ingredientFallbackURL} name={name} className="rounded-circle chat-img" alt={name}/>
      <div className="ingredient-list-item-content d-flex justify-content-between">
        <div className="d-flex flex-column">
          <div className="name">{name}</div>
          <div className="plain">{language["plain"]}</div>
        </div>
        <div className="d-flex flex-column">
          <div className="quantity">
            {addedIngredient?.quantity ?? ""} {unit?.unit_name ?? ""}
          </div>
          <div className="grams">
            {gramsPerOneUnit * addedIngredient.quantity} {language["grams"] ?? "grams"}
          </div>
        </div>
      </div>
      {onRemovePressed && (
        <div>
          <Button onClick={() => onRemovePressed(addedIngredient)} className="remove-btn" type="link" shape="circle">
            <AiOutlineClose />
          </Button>
        </div>
      )}
    </div>
  );
};

export default IngredientListItem;
