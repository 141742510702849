import { useEffect, useState } from "react";
import { getAllergiesAndExclusions } from "../services/MealPlanNetworkService";

const useAllergiesAndExclusions = ({ dietType }) => {
  const [loading, setLoading] = useState(true);
  const [allergiesAndExclusions, setAllergiesAndExclusions] = useState(null);

  useEffect(() => {
    if (!dietType) {
      setLoading(false);
      return;
    }
    (async () => {
      const response = await getAllergiesAndExclusions({ dietType });
      const allergies = [];
      const majorExclusionsMap = new Map();
      for (const item of response) {
        if (item.is_allergy) {
          //  && item.is_exclusion && item.diet_type_id === dietType
          allergies.push(item);
        } else if (item.exclusionSub) {
          const majorExclusion = item.exclusionMajor;
          const subExclusion = {
            ...item.exclusionSub,
            enabled: false,
            isExcluded: item.is_exclusion,
            majorId: majorExclusion.id,
            majorName: majorExclusion.name,
          };
          if (majorExclusionsMap.has(majorExclusion.id)) {
            const newSubExclusionsList = [...(majorExclusionsMap.get(majorExclusion.id).subExclusions ?? []), subExclusion];
            majorExclusionsMap.set(majorExclusion.id, {
              ...majorExclusion,
              enabled: false,
              isExcluded: item.is_exclusion,
              subExclusions: newSubExclusionsList,
            });
          } else {
            majorExclusionsMap.set(majorExclusion.id, {
              ...majorExclusion,
              enabled: false,
              isExcluded: item.is_exclusion,
              subExclusions: [subExclusion],
            });
          }
        } else if (item.exclusionMajor && !majorExclusionsMap.has(item.exclusionMajor.id)) {
          majorExclusionsMap.set(item.exclusionMajor.id, {
            ...item.exclusionMajor,
            enabled: false,
            isExcluded: item.is_exclusion,
          });
        }
      }
      setAllergiesAndExclusions({
        allergies,
        majorExclusions: [...majorExclusionsMap.values()],
      });
      setLoading(false);
    })();
  }, [dietType]);

  return [loading, allergiesAndExclusions];
};

export default useAllergiesAndExclusions;
