import { useState, useRef } from "react";
import { useHistory } from "react-router";
import axios from "../../services/Axios";
import CoSignUpOTPModal from "./CoSignUpOTPModal";

import config from "../../config";
import AuthBase from "../../components/AuthBase";
import Loading from "../../components/Loading";
import { isValidEmail, isValidPassword } from "../../utility/Helpers";
import {
  clientSignInRoute,
  corporateSignInRoute,
  forgotpasswordRoute,
} from "../../routes";
import {
  emailAddressTakenErrorMsg,
  signupUserId,
} from "../../utility/constants";
import { useSelector } from "react-redux";

const CoSignUp = () => {
  const language = useSelector((state) => state.language);
  const confirmPasswordRef = useRef(null);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [emailError, setEmailError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    username: "",
    phone: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid()) return;
    try {
      const { email, password, username, phone } = formData;
      setLoading(true);
      const res = await axios.post(`/cosignup`, {
        email,
        password,
        userName: username,
        phoneNumber: phone,
        userRoleName: config.clientUserRole,
      });
      if (res.data.error) {
        setErrorText(res.data.error);
      } else if (res.data.response === "User Exist") {
        setErrorText(language.lang.UserAlreadyExistsErrorText);
      } else {
        localStorage.setItem(
          signupUserId,
          res.data.response.data.insert_User.returning[0].id
        );
        setShowModal(true);
      }
    } catch (e) {
      console.log("--- sign up error");
      // console.log(e);
      if (e.response.data.response === "User Exist") {
        setErrorText(language.lang[emailAddressTakenErrorMsg]);
      } else if (e.response.data.response) {
        console.log(e.response.data.response);
        setErrorText(e.response.data.response);
      }
    } finally {
      setLoading(false);
    }
  };

  const isValid = () => {
    const emailErr = isValidEmail(formData.email);
    const passwordErr = isValidPassword(formData.password);
    const usernameErr = isValidUsername(formData.username);
    const confirmPasswordErr = isValidConfirmPassword(
      confirmPasswordRef.current.value,
      formData.password
    );
    setEmailError(language.lang[emailErr]);
    setPasswordError(language.lang[passwordErr]);
    setUsernameError(usernameErr);
    setConfirmPasswordError(confirmPasswordErr);
    setErrorText("");
    return !emailErr && !passwordErr && !usernameErr && !confirmPasswordErr;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const loginPressed = () => {
    history.push(clientSignInRoute.path);
  };

  const isValidUsername = (username) => {
    if (!username) return language.lang.enterYourUsername;
    else if (username.length < 3) return language.lang.userNameMustBeLonger;
    return null;
  };

  const showPasswordClicked = () => {
    setShowPassword(!showPassword);
  };

  const isValidConfirmPassword = (cPassword, password) => {
    // console.log(cPassword, password);
    if (!cPassword) return language.lang.confirmYourPassword;
    else if (cPassword !== password) return language.lang.passwordDidNotMatch;
    return null;
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  // const handleModalOkPressed = () => {
  //   handleModalClose();
  //   history.push(clientSignInRoute.path);
  // };

  const corporateLoginPressed = () => {
    history.push(corporateSignInRoute.path);
  };

  const handleForgetPasswordClick = () => {
    history.push(forgotpasswordRoute.path);
  };

  return (
    <AuthBase>
      <h4 className="welcome-text d-flex justify-content-center">
        {language.lang["get-started"]}
      </h4>
      <div style={{ height: "3rem" }} />
      <form onSubmit={handleSubmit}>
        <div className="sign-in-form d-flex flex-column ">
          <input
            value={formData.email}
            onChange={handleInputChange}
            name="email"
            className="neumorphic-input"
            placeholder={language.lang.email}
          />
          {emailError && (
            <li className="mt-3 mb-0 text-danger">{emailError}</li>
          )}
          <div style={{ height: "2rem" }} />
          <input
            value={formData.username}
            onChange={handleInputChange}
            name="username"
            className="neumorphic-input"
            placeholder={language.lang.name}
          />
          {usernameError && (
            <li className="mt-3 mb-0 text-danger">{usernameError}</li>
          )}
          <div style={{ height: "2rem" }} />
          <input
            value={formData.phone}
            onChange={handleInputChange}
            name="phone"
            className="neumorphic-input"
            placeholder={
              language.lang["phone-number"] + "(" + language.lang.optional + ")"
            }
          />
          <div style={{ height: "2rem" }} />
          <div className="password-field-container">
            <input
              onChange={handleInputChange}
              value={formData.password}
              name="password"
              className="neumorphic-input"
              placeholder={language.lang.password}
              type={showPassword ? "text" : "password"}
            />
            <button
              onClick={showPasswordClicked}
              className="btn show-password-btn"
              type="button"
            >
              {showPassword ? (
                <i className="far fa-eye-slash"></i>
              ) : (
                <i className="far fa-eye"></i>
              )}
            </button>
            {passwordError && (
              <li className="mt-3 mb-0 text-danger">{passwordError}</li>
            )}
          </div>
          <div style={{ height: "2rem" }} />
          <div className="password-field-container">
            <input
              ref={confirmPasswordRef}
              name="confirm-password"
              className="neumorphic-input"
              placeholder={"Confirm " + language.lang.password}
              type={showPassword ? "text" : "password"}
            />{" "}
            <button
              onClick={showPasswordClicked}
              className="btn show-password-btn"
              type="button"
            >
              {showPassword ? (
                <i className="far fa-eye-slash"></i>
              ) : (
                <i className="far fa-eye"></i>
              )}
            </button>
            {confirmPasswordError && (
              <li className="mt-3 mb-0 text-danger">{confirmPasswordError}</li>
            )}
          </div>
        </div>
        <div
          style={{ height: errorText ? "4rem" : "2rem" }}
          className="d-flex justify-content-center align-items-center text-danger"
        >
          {errorText}
        </div>
        <div className="d-flex justify-content-center">
          {loading ? (
            <Loading />
          ) : (
            <button
              className="btn btn-primary"
              type="submit"
              style={{ width: "50%" }}
            >
              {language.lang["sign-up"]}
            </button>
          )}
        </div>
      </form>
      <div style={{ height: "1rem" }} />
      <div className="d-flex justify-content-center align-items-center">
        <p className="m-0 signup-header-text">
          {language.lang["already-have-account-?"]}
        </p>
        <button type="submit" className="btn" onClick={loginPressed}>
          <span className="text-logo-color">{language.lang.login}</span>
        </button>
      </div>
      <div className="d-flex justify-content-center">
        <button
          type="submit"
          className="btn"
          onClick={handleForgetPasswordClick}
        >
          <span className="text-logo-color">
            {language.lang["forgot-password"]}
          </span>
        </button>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center mt-2">
        <p className="m-0 signup-header-text">
          {language.lang["are-you-corporate-?"]}
        </p>
        <button
          onClick={corporateLoginPressed}
          type="submit"
          className="btn px-0"
        >
          <span className="text-logo-color">
            {language.lang["corporate-login"]}
          </span>
        </button>
      </div>
      <CoSignUpOTPModal
        show={showModal}
        handleModalClose={handleModalClose}
        email={formData.email}
        password={formData.password}
        handleModalCancelPressed={handleModalClose}
      />
    </AuthBase>
  );
};

export default CoSignUp;
