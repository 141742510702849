import React, { useState } from "react";
import BackButton from "../../components/BackButton";
import CirclesBackground from "../../components/CirclesBackground";
import Modal from "react-bootstrap/Modal";
import OnboardingForm from "../../components/OnboardingForm";
import { dashboardRoute, paymentInfoRoute } from "../../routes";

const ProvideConsultationInfo = (props) => {
  const { history } = props;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { selectedSlot, practitioner } = history.location.state;

  const onFormSubmited = async (
    data,
    emiratesIDImage,
    medicalDocFile,
    recordFile,
  ) => {
    data.appointmentType = "scheduled";
    history.push(paymentInfoRoute.path, {
      formData: data,
      emiratesIDImage,
      medicalDocFile,
      recordFile,
      selectedSlot,
      practitioner,
    });
    // if (!loading) setLoading(true);
    // setFormData(formData);
    // try {
    //   data.paymentType = "membership";
    //   const response = await axios.post(`/scheduleAppointment`, data);
    //   // console.log("response", response);

    // } catch (e) {
    //   // console.log("error->",{e});
    //   showErrorNotification(e.response && e.response.data.error);
    // } finally {
    //   setLoading(false);
    // }
  };

  return (
    <CirclesBackground>
      {/* <Prompt
        when={!showModal}
        message="Are you sure you want to leave this page ?"
      /> */}
      <div className="page-container d-flex">
        <div className="main container-fluid">
          <div className="provide-consultation-info px-4">
            <section className="row">
              <div className="col-1 d-flex align-items-center">
                <BackButton />
              </div>
              <div className="col-10 d-flex align-items-center">
                <h5>Provide Information</h5>
              </div>
            </section>
            <OnboardingForm
              {...props}
              loading={loading}
              setLoading={setLoading}
              onFormSubmited={onFormSubmited}
            />
          </div>
        </div>
      </div>
    </CirclesBackground>
  );
};

export default ProvideConsultationInfo;
