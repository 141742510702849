import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import clientActions from '../redux/client/actions';
const AppointmentDetailsCard = ({ selectedSlot }) => {
  const language = useSelector((state) => state.language);
  const dayPeriod = selectedSlot && selectedSlot.end_time && moment(selectedSlot.end_time).format("a");
  const callType = useSelector((state) => state.client.callType);
  const dispatch = useDispatch();
  const dispatchChoice = (type) => {
    dispatch({
      type: clientActions.SET_CALL_TYPE,
      payload : type === 'Video Call' ? true : false,
    });
  }
  return (
    <div className="appointment-details-card">
     
     <div className="appointment-date">
        {selectedSlot && selectedSlot.start_time && (
          <h5 className="appointment-date-text slot-time-details">
            {language.lang.date}:{" " + moment(selectedSlot.start_time).format("DD-MM-YYYY")}
          </h5>
        )}
        </div>
      
      <div className="appointment-date m-0">
        {selectedSlot && selectedSlot.start_time && (
          <h5 className="appointment-date-text slot-time-details mt-0 mb-4">
             {language.lang.time}:
            {" "+moment(selectedSlot.start_time).format("hh:mm A")}
          </h5>
        )}
        </div>
        <h5 className="slot-time-details m-0">{language.lang.callDuration}</h5>
      {/*<div className="day-time">
        <DayTimeItem
          selected={dayPeriod === "am"}
          text={language.lang.morning}
          icon={<i className="fas fa-sun"></i>}
        />
        <DayTimeItem
          text={language.lang.evening}
          selected={dayPeriod === "pm"}
          icon={<i className="fal fa-moon"></i>}
        />
      </div>*/}
      {/*<h6 className="slot-time-details">{language.lang["solt-time-details"]}</h6>
      <div className="allocated-slot mt-4">
        <p className="text">{language.lang["your-allocated-slot"]}</p>
        <div className="value">1</div>
      </div>*/}
      <div className="slot-time-details allocated-slot mt-2 justify-content-start" style={{gap:'30px'}}>
        <p className="text">{language.lang.yourAllocatedTime}</p>
        {selectedSlot && selectedSlot.start_time && (
          <small className="value">
            {/* {moment(selectedSlot.start_time).format("hh:mm a")} */}
            15 {language.lang.minutes}
          </small>
        )}
      </div>
      <h5 className="slot-time-details mt-2 mb-3 ">{language.lang.call_mode}</h5>
      <div className="d-flex flex-column" style={{gap:'10px'}}>
      {/* <AppointmentFeatureItem type={"Voice Call"} selected={!callType} dispatchChoice={dispatchChoice}/> */}
      <AppointmentFeatureItem type={"Video Call"} selected={callType} dispatchChoice={dispatchChoice}/>
      </div>
      {/* <div className="mt-3"></div>
      <AppointmentFeatureItem /> */}
    </div>
  );
};

const AppointmentFeatureItem = ({ type, selected, dispatchChoice }) => {
  const language = useSelector((state) => state.language);

  return (
    <div className={"appointment-feature-item mm-card" + (selected ? " selected" : "")} onClick={() => dispatchChoice(type)}>
      <div className="icon-container">{type === "Voice Call" ? <i className="fa fa-phone"></i> : <i className="fas fa-video"></i>}</div>
      <div className="">
        <p className="feature-name">{type === "Voice Call" ? language.lang["voice-call"] : language.lang["video-call"] }</p>
        <p className="feature-desc">{type === "Voice Call" ? language.lang["can-make-voice-call-with-doc"] : language.lang["can-make-video-call-with-doc"]}</p>
      </div>
    </div>
  );
};

const DayTimeItem = ({ selected, icon, text }) => {
  return (
    <div className={`day-time-item ${selected ? "selected" : ""}`}>
      <div className="icon-container">{icon}</div>
      <span className="day-time-text">{text}</span>
    </div>
  );
};

export default AppointmentDetailsCard;
