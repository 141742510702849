import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getRecipes } from "../services/CustomerNetworkService";
import { approvedFoodItemStatusID, unapprovedFoodItemStatusID } from "../utility/constants";

const useRecipeDetails = () => {
  const [loading, setLoading] = useState(true);
  const [recipe, setRecipe] = useState(null);
  const { recipeURLName } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.recipe?.id) {
      setRecipe({ ...location.state.recipe });
      setLoading(false);
      return;
    }
    if (!recipeURLName) {
      setLoading(false);
      return;
    }
    (async () => {
      const res = await getRecipes({ urlName: recipeURLName, statusId: [unapprovedFoodItemStatusID, approvedFoodItemStatusID] });
      setRecipe(res[0]);
      setLoading(false);
    })();
  }, [location, recipeURLName]);

  return [loading, recipe];
};

export default useRecipeDetails;
