import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setPatientDetail,
  updatescheduleAppointment,
  verifyPatientDetails,
} from "../redux/clinic/actionCreators";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../utility/Helpers";
import ChoiceField from "./ChoiceField";
import ShowEmiratesID from "./ShowEmiratesID";
import actions from "../redux/clinic/actions";
import Loading from "./Loading";
import { useHistory, Redirect } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { dashboardRoute } from "../routes";
import ProfileCircleButton from "./ProfileCircleButton";
import NotificationsButton from "./NotificationsButton";
import LogoSideButton from "./LogoSideButton";
import BackButton from "./BackButton";
import TopMenu from "./CommonControls/TopMenu";

const VerifyPatientDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const language = useSelector((state) => state.language);
  const { id } = useSelector((state) => state.auth.userData);
  const PatientDetailToVerify = useSelector(
    (state) => state.clinic.patientDetail
  );
  const [resident, setresident] = useState(
    PatientDetailToVerify &&
      PatientDetailToVerify.resident &&
      PatientDetailToVerify.resident.name !== "Tourist"
  );
  const appointment = useSelector((state) => state.clinic.appointmentDetail);
  const ViewType = useSelector((state) => state.clinic.view_schedule);
  const currencies = useSelector((state) => state.clinic.currencies);
  const [insuranceCovered, setinsuranceCovered] = useState(
    !!(
      appointment.insurance_no &&
      appointment.insurance_no &&
      appointment.insuranceProvider &&
      appointment.insuranceProvider.name
    )
  );
  const [formData, setFormData] = useState({
    clinicId: id,
    appointmentId: appointment.id,
    isEmiratesIdValid: true,
    isInsuranceProviderCovered: true,
    isInsuranceNoValid: true,
    isCoPayment:
      appointment.is_co_payment !== null ? appointment.is_co_payment : true,
    amountToPay: "",
    isPassportNumberValid: false,
    isVisaUidValid: false,
    feedback: "",
    amountToPayCurrencyCode: "AED",
  });
  useEffect(() => {
    if (!!appointment?.clinicVerificationResponse) {
      setFormData((prevState) => {
        return {
          ...prevState,
          isEmiratesIdValid:
            !!appointment?.clinicVerificationResponse &&
            typeof appointment?.clinicVerificationResponse
              .is_emirates_id_valid !== "object"
              ? appointment?.clinicVerificationResponse.is_emirates_id_valid
              : true,
          isInsuranceProviderCovered:
            !!appointment?.clinicVerificationResponse &&
            typeof appointment?.clinicVerificationResponse
              .is_insurance_provider_covered !== "object"
              ? appointment?.clinicVerificationResponse
                  .is_insurance_provider_covered
              : true,
          isInsuranceNoValid:
            !!appointment?.clinicVerificationResponse &&
            typeof appointment?.clinicVerificationResponse
              .is_insurance_no_valid !== "object"
              ? appointment?.clinicVerificationResponse.is_insurance_no_valid
              : true,
          isPassportNumberValid:
            !!appointment?.clinicVerificationResponse &&
            typeof appointment?.clinicVerificationResponse
              .is_passport_number_valid !== "object"
              ? appointment?.clinicVerificationResponse.is_passport_number_valid
              : false,
          isVisaUidValid:
            !!appointment?.clinicVerificationResponse &&
            typeof appointment?.clinicVerificationResponse.is_visa_uid_valid !==
              "object"
              ? appointment?.clinicVerificationResponse.is_visa_uid_valid
              : false,
          feedback:
            !!appointment?.clinicVerificationResponse &&
            typeof appointment?.clinicVerificationResponse.feedback !== "object"
              ? appointment?.clinicVerificationResponse.feedback
              : "",
        };
      });
    }
  }, [appointment]);

  const postVerification = async (data, isDecline = false) => {
    if (data.amountToPay === null) {
      data.amountToPay = 0;
    }
    if (data.isCoPayment && data.amountToPay === 0 && !isDecline) {
      showSuccessNotification("Fill the Amount to Pay");
    } else if (data.amountToPayCurrencyCodeRequired === "" && !isDecline) {
      showSuccessNotification("Please select the currency");
    } else {
      setloading(true);
      dispatch(updatescheduleAppointment(appointment.id));
      dispatch({
        type: actions.UPDATE_PENDING_APPOINTMENTS,
        payload: appointment.id,
      });
      try {
        await verifyPatientDetails(data);
        dispatch(setPatientDetail({}));
      } catch (e) {
        showErrorNotification("Some Error Occured");
        return;
      }
      setloading(false);
    }
  };
  const InputHandler = (e) => {
    if (!ViewType) {
      var name = e.target.name,
        value = e.target.value;
      setFormData((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  };

  const ChoiceHandler = (name, value) => {
    if (!ViewType) {
      setFormData((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
    if(name === "isCoPayment" && value === false){
      setFormData((prevState) => {
        return {
          ...prevState,
          amountToPay: null,
        };
      });
    }
  };

  const OnSelectHandler = (e) => {
    if (!ViewType) {
      setFormData((prevState) => {
        return {
          ...prevState,
          amountToPayCurrencyCode: e.target.value,
        };
      });
    }
  };

  const declineVerification = () => {
    if (!formData.feedback) {
      showSuccessNotification(language.lang.pleaseEnterFeedback);
      return;
    }
    dispatch({
      type: actions.UPDATE_PENDING_APPOINTMENTS,
      payload: appointment.id,
    });
    postVerification({ ...formData, statusId: 22 }, true);
  };

  /*const handleCoPaymentChange = (name, value) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        amountToPay: "0",
      };
    });
    ChoiceHandler(name, value);
  };*/

  const handleModalOkClick = () => {
    history.replace(dashboardRoute.path);
  };
  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        amountToPay: appointment.amount_to_pay,
      };
    });
    console.log("This appointment --> ", appointment);
  }, [appointment]);

  if (Object.keys(PatientDetailToVerify).length === 0) {
    return <Redirect to={dashboardRoute.path} />;
  } else {
    return (
      <div className="clinic">
      <div className="row">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.lang.clinic_dashboard}
      />
        </div>
        <div
          className="next-patient mm-card my-5 mt-md-0 min-card-width pending-card d-flex flex-wrap mx-4"
          style={{ height: "auto" }}
        >
          <div className={"px-2 py-1 w-100" + (resident ? " col-md-8" : "")}>
            <div className="d-flex justify-content-between">
              <p className="title mb-4">
                {language.lang.verify_patient_details}
              </p>
            </div>
            <div className="row ">
              <div className="col">
                <div className="mx-2 w-100 d-flex align-items-center justify-content-start">
                  <div className="field-min-width">
                    {language.lang.requested_by} :{" "}
                  </div>
                  <div>{PatientDetailToVerify.name}</div>
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center flex-wrap">
              <div className="col pt-4 pb-4">
                <div className="mx-2 w-100 d-flex align-items-center justify-content-start">
                  <div className="field-min-width">
                    {language.lang.resident_status}:{" "}
                  </div>
                  <div>
                    {PatientDetailToVerify &&
                      PatientDetailToVerify.resident &&
                      PatientDetailToVerify.resident.name}
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center flex-wrap">
              <div className="col">
                {resident && (
                  <div className="mx-2 w-100 d-flex align-items-center justify-content-start">
                    <div className="field-min-width">
                      {language.lang["emirates-id"]} :{" "}
                    </div>
                    <div>
                      {PatientDetailToVerify.emirates_id &&
                        PatientDetailToVerify.emirates_id}
                    </div>
                  </div>
                )}
                {!resident && (
                  <div className="mx-2 w-100 d-flex align-items-center justify-content-start">
                    <div className="field-min-width">
                      {language.lang.passport_number} :{" "}
                    </div>
                    <div>
                      {PatientDetailToVerify.passport_no &&
                        PatientDetailToVerify.passport_no}
                    </div>
                  </div>
                )}
              </div>
              <div className="col">
                <ChoiceField
                  first={language.lang.valid}
                  second={language.lang.invalid}
                  value={
                    PatientDetailToVerify.emirates_id &&
                    PatientDetailToVerify.emirates_id
                      ? 1
                      : 2
                  }
                  ChoiceHandler={ChoiceHandler}
                  name="isEmiratesIdValid"
                  ViewType={ViewType}
                />
              </div>
            </div>

            {appointment.message !== "" && resident && (
              <div className="row align-items-center justify-content-center flex-wrap pt-3">
                <div className="no-insurance col">{appointment.message}</div>
              </div>
            )}

            <div className="row align-items-center justify-content-center flex-wrap">
              <div className="col">
                {((resident && insuranceCovered) ||
                  !appointment.is_proceed_with_full_payment) && (
                  <div className="mx-2 w-100 d-flex align-items-center justify-content-start py-3">
                    <div className="field-min-width">
                      {language.lang.insurance_provider} :{" "}
                    </div>
                    <div>
                      {(appointment.insuranceProvider &&
                        appointment.insuranceProvider.name) ||
                        "None"}
                    </div>
                  </div>
                )}
                {!resident && (
                  <div className="mx-2 w-100 d-flex align-items-center justify-content-start py-3">
                    <div className="field-min-width">
                      {language.lang.visa_uid_number} :{" "}
                    </div>
                    <div>
                      {PatientDetailToVerify.visa_uid_no &&
                        PatientDetailToVerify.visa_uid_no}
                    </div>
                  </div>
                )}
              </div>

              {((resident &&
                insuranceCovered &&
                appointment?.is_any_insurance_covered_by_practitioner) ||
                !appointment.is_proceed_with_full_payment) && (
                <div className="col">
                  {" "}
                  <ChoiceField
                    first={language.lang.covered}
                    second={language.lang.not_covered}
                    value={
                      (appointment.insuranceProvider &&
                        appointment.insuranceProvider.name) === null
                        ? 2
                        : 1
                    }
                    ChoiceHandler={ChoiceHandler}
                    name="isInsuranceProviderCovered"
                    ViewType={ViewType}
                  />
                </div>
              )}
              {!resident && (
                <div className="col">
                  <ChoiceField
                    first={language.lang.valid}
                    second={language.lang.invalid}
                    ChoiceHandler={ChoiceHandler}
                    name="isVisaUidValid"
                    ViewType={ViewType}
                    value={1}
                  />{" "}
                </div>
              )}
            </div>

            <div className="row align-items-center justify-content-center flex-wrap">
              <div className="col">
                {((resident && insuranceCovered) ||
                  !appointment.is_proceed_with_full_payment) && (
                  <div className="mx-2 w-100 d-flex align-items-center justify-content-start py-3">
                    <div className="field-min-width">
                      {language.lang.insurance_no} :{" "}
                    </div>
                    <div>
                      {(appointment.insurance_no && appointment.insurance_no) ||
                        "None"}
                    </div>
                  </div>
                )}
                {!resident && (
                  <div className="mx-2 w-100 d-flex align-items-center justify-content-start py-3">
                    <div className="field-min-width">
                      {language.lang.passport_expiry} :{" "}
                    </div>
                    <div>
                      {PatientDetailToVerify &&
                        PatientDetailToVerify.passport_expiry_date}
                    </div>
                  </div>
                )}
              </div>
              {resident &&
                insuranceCovered &&
                !appointment.is_proceed_with_full_payment && (
                  <div className="col">
                    <ChoiceField
                      first={language.lang.valid}
                      second={language.lang.invalid}
                      value={
                        (appointment.insurance_no &&
                          appointment.insurance_no) === "null" ||
                        (appointment.insurance_no &&
                          appointment.insurance_no) === null
                          ? 2
                          : 1
                      }
                      ChoiceHandler={ChoiceHandler}
                      name="isInsuranceNoValid"
                      ViewType={ViewType}
                    />
                  </div>
                )}
            </div>
            {
              <div className="row align-items-center justify-content-center flex-wrap py-3">
                <div className="col">
                  <div className="mx-2 w-100 d-flex align-items-center justify-content-start">
                    <div className="field-min-width">Full Payment : </div>
                    <div>
                      {appointment.is_proceed_with_full_payment
                        ? language.lang.yes
                        : language.lang.no}
                    </div>
                  </div>
                </div>
              </div>
            }

            {
              /*formData.isCoPayment && */ <div className="row align-items-center pt-3 pb-4">
                <div className="col">
                  <div className="mx-2 w-100 d-flex align-items-center">
                    <div className="field-min-width">Total Fees:</div>
                    <div className="font-weight-bold highlight-fees">
                      {(appointment.full_amount
                        ? appointment.full_amount
                        : "0") +
                        " " +
                        (appointment.amount_to_pay_currency_code
                          ? appointment.amount_to_pay_currency_code
                          : "AED")}
                    </div>
                  </div>
                </div>
              </div>
            }

            {resident && (
              <div className="row align-items-center pb-3">
                <div className="col">
                  <div
                    className="mx-2 w-100 d-flex align-items-center justify-content-start flex-wrap "
                    style={{ gap: "20px" }}
                  >
                    <div className="field-min-width">
                      {language.lang.is_co_payment} :{" "}
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      {" "}
                      <ChoiceField
                        first="Yes"
                        second="No"
                        value={formData.isCoPayment ? 1 : 2}
                        ChoiceHandler={ChoiceHandler}
                        name="isCoPayment"
                        ViewType={ViewType}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {formData.isCoPayment && (
              <div className="row align-items-center py-3">
                <div className="col">
                  <div className="mx-2 w-100 d-flex align-items-center">
                    <div className="field-min-width">
                      {language.lang.amount_to_pay}
                    </div>
                    <div
                      className="d-flex justify-content-start align-items-center"
                      style={{ gap: "20px", maxWidth: "20%" }}
                    >
                      <input
                        value={formData.amountToPay}
                        onChange={InputHandler}
                        name="amountToPay"
                        className="neumorphic-input"
                        placeholder="AED"
                        disabled={ViewType}
                        style={{ maxWidth: "100px", padding: "7px 10px" }}
                      />
                      <select
                        className="neumorphic-input"
                        value={formData.amountToPayCurrencyCode}
                        onChange={OnSelectHandler}
                        style={{ maxWidth: "100px", padding: "7px 10px" }}
                      >
                        {currencies.map((a, i) => {
                          return (
                            <option key={i} value={a.code}>
                              {a.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row align-items-center py-3">
              <div className="col">
                <div className="mx-2 w-100 d-flex align-items-center justify-content-start">
                  <div className="field-min-width">
                    {language.lang.requesting_for} :{" "}
                  </div>
                  <div>
                    {appointment &&
                      appointment.practitionerInfo &&
                      appointment.practitionerInfo.name}
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center py-3">
              <div className="col">
                <div className="mx-2 w-100 d-flex align-items-center justify-content-start">
                  <div className="field-min-width">Requesting Date : </div>
                  <div>
                    {" "}
                    {(appointment &&
                      moment(appointment.start_date_time).format(
                        "DD/MM/YYYY"
                      )) ||
                      "NONE"}{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center py-3">
              <div className="col">
                <div className="mx-2 w-100 d-flex align-items-center justify-content-start">
                  <div className="field-min-width">
                    {language.lang.requesting_time} :{" "}
                  </div>
                  <div>
                    {" "}
                    {(appointment &&
                      moment(appointment.start_date_time).format("hh:mm a")) ||
                      "NONE"}{" "}
                    to{" "}
                    {(appointment &&
                      moment(appointment.end_date_time).format("hh:mm a")) ||
                      "NONE"}{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center py-3">
              <div className="col">
                <div className="mx-2 d-flex align-items-start justify-content-start flex-column">
                  <div className="mb-2">{language.lang.feedback}</div>
                  <textarea
                    name="feedback"
                    value={formData.feedback}
                    onChange={InputHandler}
                    className="neumorphic-input"
                    style={{ resize: "none", width: 300 }}
                    disabled={ViewType}
                  />
                </div>
              </div>
            </div>

            {!ViewType && (
              <div className="d-flex justify-content-center mt-2">
                {!loading ? (
                  <>
                    <button
                      className="accept-btn "
                      onClick={() => {
                        postVerification({ ...formData, statusId: 21 });
                      }}
                    >
                      {language.lang.approve}
                    </button>
                    <button
                      className="reject-btn "
                      onClick={() => {
                        declineVerification();
                      }}
                    >
                      {language.lang.decline}
                    </button>
                  </>
                ) : (
                  <Loading />
                )}
              </div>
            )}
          </div>

          {resident /*&& formData.isInsuranceProviderCovered*/ && (
            <div
              className={"w-100 col-md-4 mt-5" + (resident ? " col-md-4" : "")}
            >
              <ShowEmiratesID
                url={
                  PatientDetailToVerify.emirates_id_photo_url &&
                  PatientDetailToVerify.emirates_id_photo_url
                }
                type="front"
              />
              <ShowEmiratesID
                url={
                  PatientDetailToVerify.emirates_id_photo_url_for_back &&
                  PatientDetailToVerify.emirates_id_photo_url_for_back
                }
                type="back"
              />
            </div>
          )}

          <Modal
            centered={true}
            aria-labelledby="contained-modal-title-vcenter contained-modal-title-hcenter"
            size="lg"
            backdrop="static"
            show={showSuccessModal}
            className="modal_custom"
          >
            <Modal.Body>
              <h6 style={{ width: "57rem" }} className="text-center">
                Your request wast sent successfully. The apppointment will show
                in your apppointments screen and will get a notification when
                the provider approves.
              </h6>
            </Modal.Body>
            <Modal.Footer>
              <button onClick={handleModalOkClick} className="btn btn-success">
                Ok
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
};

export default VerifyPatientDetails;
