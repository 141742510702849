import React from "react";
import mLogo from "../assets/images/MLogo.png";

const ProfileCircleButton = ({src}) => {
  return (
    <button className="btn p-0 profile-doctor-img">
      <img src={src} alt="Username" />
    </button>
  );
};

export default ProfileCircleButton;
