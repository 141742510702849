import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPatientDetail,
  updatescheduleAppointment,
  verifyPatientDetails,
} from "../redux/clinic/actionCreators";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../utility/Helpers";
import actions from "../redux/clinic/actions";
import Loading from "./Loading";
import { useHistory, Redirect } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { dashboardRoute } from "../routes";
import ProfileCircleButton from "./ProfileCircleButton";
import NotificationsButton from "./NotificationsButton";
import LogoSideButton from "./LogoSideButton";
import BackButton from "./BackButton";

const VerifyPatientDetailsBasedOnRegion = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const language = useSelector((state) => state.language);
  const { id } = useSelector((state) => state.auth.userData);
  const PatientDetailToVerify = useSelector(
    (state) => state.clinic.patientDetail
  );
  const appointment = useSelector((state) => state.clinic.appointmentDetail);
  const ViewType = useSelector((state) => state.clinic.view_schedule);
  const currencies = useSelector((state) => state.clinic.currencies);
  const [formData, setFormData] = useState({
    clinicId: id,
    appointmentId: appointment.id,
    isEmiratesIdValid: "",
    isInsuranceProviderCovered: "",
    isInsuranceNoValid: "",
    isCoPayment: "",
    amountToPay: "",
    isPassportNumberValid: "",
    isVisaUidValid: "",
    feedback: "",
    amountToPayCurrencyCode: "AED",
  });
  useEffect(() => {
    if (!!appointment?.clinicVerificationResponse) {
      setFormData((prevState) => {
        return {
          ...prevState,
          feedback:
            !!appointment?.clinicVerificationResponse &&
            typeof appointment?.clinicVerificationResponse.feedback !== "object"
              ? appointment?.clinicVerificationResponse.feedback
              : "",
        };
      });
    }
  }, [appointment]);
  const postVerification = async (data) => {
    if (data.amountToPay === null) {
      data.amountToPay = 0;
    }
    if (data.amountToPay === 0) {
      showSuccessNotification("Fill the Amount to Pay");
    } else {
      setloading(true);
      dispatch(updatescheduleAppointment(appointment.id));
      dispatch({
        type: actions.UPDATE_PENDING_APPOINTMENTS,
        payload: appointment.id,
      });
      try {
        await verifyPatientDetails(data);
        dispatch(setPatientDetail({}));
      } catch (e) {
        showErrorNotification("Some Error Occured");
        return;
      }
      setloading(false);
    }
  };
  const InputHandler = (e) => {
    if (!ViewType) {
      var name = e.target.name,
        value = e.target.value;
      setFormData((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  };

  const OnSelectHandler = (e) => {
    if (!ViewType) {
      setFormData((prevState) => {
        return {
          ...prevState,
          amountToPayCurrencyCode: e.target.value,
        };
      });
    }
  };

  const declineVerification = () => {
    if (!formData.feedback) {
      showSuccessNotification("Give Feedback");
      return;
    }
    dispatch({
      type: actions.UPDATE_PENDING_APPOINTMENTS,
      payload: appointment.id,
    });
    postVerification({ ...formData, statusId: 22 });
  };

  const handleModalOkClick = () => {
    history.replace(dashboardRoute.path);
  };
  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        amountToPay: appointment.amount_to_pay,
      };
    });
    console.log("This appointment --> ", appointment);
  }, [appointment]);

  if (Object.keys(PatientDetailToVerify).length === 0) {
    return <Redirect to={dashboardRoute.path} />;
  } else {
    return (
      <div className="clinic clinic-by-region">
        <div
          className={
            "d-flex justify-content-between p-0 m-0 align-items-center pb-2" +
            `${language.direction}`
          }
        >
          <div className="d-flex align-items-center">
            <BackButton />
            <div className="pl-4 d-flex align-items-center">
              <LogoSideButton />
              <h3 className="Page-title-custom ml-3">
                {language.lang.clinic_dashboard}
              </h3>
            </div>
          </div>
          <div className="d-flex">
            <NotificationsButton className="ml-auto" />
            <ProfileCircleButton />
          </div>
        </div>
        <div
          className="next-patient mm-card my-5 mt-md-0 min-card-width pending-card d-flex flex-wrap mx-4"
          style={{ height: "auto" }}
        >
          <div className={"px-2 py-1 w-100"}>
            <div className="d-flex justify-content-between">
              <p className="title mx-2 ">
                {language.lang.verify_patient_details}
              </p>
            </div>
            <div className="row ">
              <div className="col">
                <div className="mx-2 w-100 d-flex align-items-center justify-content-start">
                  <div className="field-min-width">
                    {language.lang.requested_by} :{" "}
                  </div>
                  <div className="ml-4">{PatientDetailToVerify.name}</div>
                </div>
              </div>
            </div>

            <div className="row align-items-center py-3">
              <div className="col">
                <div className="mx-2 w-100 d-flex align-items-center">
                  <div className="field-min-width">
                    {language.lang.amount_to_pay}
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ gap: "20px", maxWidth: "20%" }}
                  >
                    <input
                      value={formData.amountToPay}
                      onChange={InputHandler}
                      name="amountToPay"
                      className="neumorphic-input amount-to-pay"
                      placeholder=""
                      disabled={ViewType}
                      style={{ maxWidth: "100px", padding: "7px 10px" }}
                    />
                    <select
                      className="neumorphic-input amount-to-pay-dropdown"
                      value={formData.amountToPayCurrencyCode}
                      onChange={OnSelectHandler}
                      style={{ maxWidth: "100px", padding: "7px 10px" }}
                    >
                      {currencies.map((a, i) => {
                        return (
                          <option key={i} value={a.code}>
                            {a.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center py-3">
              <div className="col">
                <div className="mx-2 w-100 d-flex align-items-center justify-content-start">
                  <div className="field-min-width">
                    {language.lang.requesting_for} :{" "}
                  </div>
                  <div className="ml-4">
                    {appointment &&
                      appointment.practitionerInfo &&
                      appointment.practitionerInfo.name}
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center py-3">
              <div className="col">
                <div className="mx-2 w-100 d-flex align-items-center justify-content-start">
                  <div className="field-min-width">Requesting Date : </div>
                  <div className="ml-4">
                    {" "}
                    {(appointment &&
                      moment(appointment.start_date_time).format(
                        "DD/MM/YYYY"
                      )) ||
                      "NONE"}{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center py-3">
              <div className="col">
                <div className="mx-2 w-100 d-flex align-items-center justify-content-start">
                  <div className="field-min-width">
                    {language.lang.requesting_time} :{" "}
                  </div>
                  <div className="ml-4">
                    {" "}
                    {(appointment &&
                      moment(appointment.start_date_time).format("hh:mm a")) ||
                      "NONE"}{" "}
                    to{" "}
                    {(appointment &&
                      moment(appointment.end_date_time).format("hh:mm a")) ||
                      "NONE"}{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center py-3">
              <div className="col">
                <div className="mx-2 d-flex align-items-start justify-content-start flex-column">
                  <div className="mb-2">{language.lang.feedback}</div>
                  <textarea
                    name="feedback"
                    value={formData.feedback}
                    onChange={InputHandler}
                    className="neumorphic-input"
                    style={{ resize: "none", width: 300 }}
                    disabled={ViewType}
                  />
                </div>
              </div>
            </div>

            {!ViewType && (
              <div className="d-flex justify-content-center mt-2">
                {!loading ? (
                  <>
                    <button
                      className="accept-btn "
                      onClick={() => {
                        postVerification({ ...formData, statusId: 21 });
                      }}
                    >
                      {language.lang.approve}
                    </button>
                    <button
                      className="reject-btn "
                      onClick={() => {
                        declineVerification();
                      }}
                    >
                      {language.lang.decline}
                    </button>
                  </>
                ) : (
                  <Loading />
                )}
              </div>
            )}
          </div>

          <Modal
            centered={true}
            aria-labelledby="contained-modal-title-vcenter contained-modal-title-hcenter"
            size="lg"
            backdrop="static"
            show={showSuccessModal}
            className="modal_custom"
          >
            <Modal.Body>
              <h6 style={{ width: "57rem" }} className="text-center">
                Your request wast sent successfully. The apppointment will show
                in your apppointments screen and will get a notification when
                the provider approves.
              </h6>
            </Modal.Body>
            <Modal.Footer>
              <button onClick={handleModalOkClick} className="btn btn-success">
                Ok
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
};

export default VerifyPatientDetailsBasedOnRegion;
