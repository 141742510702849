import actions from "./actions";

const initState = {
  lang: {},
  value_en: {},
  value_ar: {},
  value_ku: {},
  direction: " direction-ltr",
  profile: {
    name: "",
    email: "",
    phone: "",
    emiratesId: "",
    emiratesIdExpiryDate: "",
    emiratesIdPhotoUrl: "",
    insuranceProvider: "",
    insuranceNo: "",
    gender: 1,
    dob: "",
    profilePhotoUrl: "",
  },
  regionID: 1,
  general: {
    profileLanguages: [],
    regions: [],
    preferredlanguage: "en",
    regionId: 1,
    theme: "light",
  },
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.SET_PREFERRED_LANGUAGE:
      return Object.assign({}, state, {
        general: {
          ...state.general,
          preferredlanguage: payload,
        },
      });
    case actions.SET_PROFILE_LANGUAGES:
      return Object.assign({}, state, {
        general: {
          ...state.general,
          profileLanguages: payload,
        },
      });
    case actions.SET_SELECTED_REGION:
      const newState = Object.assign({}, state, {
        general: {
          ...state.general,
          regionId: payload,
        },
        regionID: payload,
      });
      return newState;
    case actions.SET_LANGUAGE:
      let en = {},
        ar = {},
        ku = {},
        setdirection = " direction-ltr";
      (payload || []).forEach((item) => {
        en[item.key] = item.value_en;
        ar[item.key] = item.value_ar;
        ku[item.key] = item.value_ku;
        // en = { ...en, [item.key]: item.value_en };
        // ar = { ...ar, [item.key]: item.value_ar };
        // ku = { ...ku, [item.key]: item.value_ku };
      });
      let selectedLang;
      if (state.general.preferredlanguage === "en") {
        selectedLang = en;
      } else if (state.general.preferredlanguage === "ar") {
        selectedLang = ar;
      } else if (state.general.preferredlanguage === "ar") {
        selectedLang = ku;
      } else {
        selectedLang = en;
      }
      return Object.assign({}, state, {
        ...state,
        lang: selectedLang,
        direction: setdirection,
        value_en: en,
        value_ar: ar,
        value_ku: ku,
      });
    case actions.SET_LANGUAGE_PREFER:
      let setlang = {},
        dir = " direction-ltr";
      if (payload === "ar") {
        setlang = state.value_ar;
        dir = " direction-rtl";
      } else if (payload === "ku") {
        setlang = state.value_ku;
      } else {
        setlang = state.value_en;
      }
      return Object.assign({}, state, {
        ...state,
        lang: Object.values(setlang).length === 0 ? state.lang : setlang,
        direction: dir,
        general: {
          ...state.general,
          preferredlanguage: payload,
        },
      });
    case actions.SET_REGIONS:
      return Object.assign({}, state, {
        ...state,
        general: {
          ...state.general,
          regions: payload,
        },
      });
    case actions.UPDATE_PROFILE_SETTINGS:
      //console.log("Update Profile settings")
      return Object.assign({}, state, {
        ...state,
        profile: payload,
      });
    case actions.UPDATE_GENERAL_SETTINGS:
      let lang = {};
      let direction = " direction-ltr";
      if (payload.preferredlanguage === "en") {
        lang = state.value_en;
      } else if (payload.preferredlanguage === "ar") {
        lang = state.value_ar;
        direction = " direction-rtl";
      } else if (payload.preferredlanguage === "ku") {
        lang = state.value_ku;
      } else {
        lang = state.value_en;
      }
      return Object.assign({}, state, {
        ...state,
        lang: Object.values(lang).length === 0 ? state.lang : lang,
        direction: direction,
        regionID: payload.regionId ?? state.regionID,
        general: {
          ...state.general,
          regionId: payload.regionId,
          preferredlanguage: payload.preferredlanguage,
          theme: payload.theme,
        },
      });
    case actions.GET_PROFILE_DETAILS:
      if (!payload) return state;
      let Setlang = {},
        Setdirection = " direction-ltr";
      let Prevlang = payload.preferred_language;
      if (Prevlang === "ar") {
        Setlang = state.value_ar;
        Setdirection = " direction-rtl";
      } else if (Prevlang === "ku") {
        Setlang = state.value_ku;
      } else {
        Setlang = state.value_en;
      }
      return Object.assign({}, state, {
        ...state,
        lang: Object.values(Setlang).length === 0 ? state.lang : Setlang,
        direction: Setdirection,
        general: {
          ...state.general,
          preferredlanguage: Prevlang,
        },
        profile: {
          name: payload.name || state.profile.name,
          phone: payload.phone || state.profile.phone,
          email: payload.email || state.profile.email,
          emiratesId: payload.emirates_id || state.profile.emiratesId,
          emiratesIdExpiryDate: payload.emirates_id_expiry_date || state.profile.emiratesIdExpiryDate,
          emiratesIdPhotoUrl: payload.emirates_id_photo_url || state.profile.emiratesIdPhotoUrl,
          insuranceProvider: payload.insurance_provider || state.profile.insuranceProvider,
          insuranceNo: payload.insurance_no || state.profile.insuranceNo,
          gender: payload.gender_id || state.profile.gender,
          dob: payload.birth_date || state.profile.dob,
          profilePhotoUrl: payload.profile_photo_url || state.profile.profilePhotoUrl,
        },
      });
    default:
      return state;
  }
};

export default reducer;
