import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Loading from "../../components/Loading";
import axios from "../../services/Axios";
import { showErrorNotification } from "../../utility/Helpers";
import { useHistory } from "react-router-dom";
import { resetPasswordRoute } from "../../routes";
import { useSelector } from "react-redux";

const VerifyOTPModal = ({
  show,
  onhide,
  email,
  resendLoading,
  onResendPressed,
}) => {
  const history = useHistory();
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleResendPressed = () => {
    onResendPressed();
  };

  const handleSubmitPressed = async () => {
    const validationError = isValidOTP();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }
    setLoading(true);
    setErrorMessage("");
    try {
      const res = await axios.post("/verifyOtp", {
        otp: verificationCode,
      });
      // console.log(res.data);
      if (res.data.response.requestId) {
        history.replace(resetPasswordRoute.path, res.data.response);
      } else {
        showErrorNotification();
      }
    } catch (e) {
      console.log("--- resetPasswordModal handleSubmitPressed error");
      console.log(e);
      showErrorNotification(e.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  const isValidOTP = () => {
    if (!verificationCode) {
      return "Please enter the verification code";
    } else if (verificationCode.length !== 6) {
      return "Verification code must be 6 characters";
    }
    return null;
  };
  const language = useSelector((state) => state.language);
  return (
    <Modal
      size="md"
      backdrop="static"
      show={show}
      onHide={onhide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="reset-password-modal d-flex flex-column align-items-center">
          <Modal.Header className="w-100" closeButton>
            <h6 className="font-weight-bold title mb-1">
              Reset {language.lang.password}
            </h6>
          </Modal.Header>
          <p className="info-text px-3">
            {language.lang["verificaiton-code-sent"]} {email}
            {"."}
          </p>
          <p className="info-text mb-3">
            Please enter the verification code to verify.
          </p>
          <form
            className="w-100"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitPressed();
            }}
          >
            <input
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              name="otp"
              className="neumorphic-input w-100"
              placeholder={language.lang["email-otp"]}
              type="text"
            />
            {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {resendLoading || loading ? (
          <Loading />
        ) : (
          <div className="d-flex justify-content-between align-items-center w-100">
            <button
              onClick={handleResendPressed}
              className="btn btn-primary d-flex justify-content-center align-items-center"
            >
              Resend
            </button>
            <button
              onClick={handleSubmitPressed}
              className="btn btn-primary d-flex justify-content-center align-items-center"
            >
              Submit
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default VerifyOTPModal;
