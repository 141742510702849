import { useRef, useState, useEffect } from "react";
import { Upload } from "antd";

const AntEmiratesIDImageUpload = ({
  onChange,
  fileList,
  onRemove,
  imageAlt,
  initialImageURL,
  emptyPlaceholderChild,
}) => {
  const imgRef = useRef(null);
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    if (!initialImageURL) return;
    imgRef.current.src = initialImageURL;
    setShowImage(true);
  }, [initialImageURL]);

  const beforeUpload = (file) => {
    if (!file) return;
    // console.log("--- upload emirates", file);
    try {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        imgRef.current.src = fileReader.result;
        setShowImage(true);
      };
      fileReader.readAsDataURL(file);
      if (onChange) {
        onChange(file);
      }
    } catch (e) {
      console.log("--- AntEmiratesIDImageUpload error");
      console.log(e);
    }
    return false;
  };

  const handleOnRemove = (file) => {
    setShowImage(false);
    onRemove();
  };

  return (
    <Upload
      accept="image/*"
      className="emirates-id-upload"
      beforeUpload={beforeUpload}
      onRemove={handleOnRemove}
      fileList={fileList}
      showUploadList={false}
    >
      <div className="emirates-id-image-btn box-shadow">
        {!imgRef.current?.src && emptyPlaceholderChild && emptyPlaceholderChild}
        <img
          ref={imgRef}
          alt={imageAlt || "Emirates ID"}
          className={`image-preview ${showImage ? "" : "d-none"}`}
        />
      </div>
    </Upload>
  );
};

export default AntEmiratesIDImageUpload;
