import React, { useState } from "react";
import CirclesBackground from "../../components/CirclesBackground";
import FilterSideMenu from "../../components/FilterSideMenu";
import OnboardingForm from "../../components/OnboardingForm";
import BackButton from "../../components/BackButton";
import { dashboardBookConsultationRoute, paymentInfoRoute } from "../../routes";
import { useSelector } from "react-redux";

const WalkinForm = (props) => {
  const { history } = props;
  const [filterSideMenuOpen, setFilterSideMenuOpen] = useState(false);

  const openFiltersSideMenu = () => {
    setFilterSideMenuOpen(true);
  };

  const closeFiltersSideMenu = () => {
    setFilterSideMenuOpen(false);
  };

  const onFormSubmited = async (
    data,
    emiratesIDImage,
    emiratesIDBackImage,
    medicalDocFile,
    recordFile,
    book_consultation,
    practitioners
  ) => {
    if(book_consultation){
      history.push(dashboardBookConsultationRoute.path, {
        formData: data,
        emiratesIDImage,
        emiratesIDBackImage,
        medicalDocFile,
        recordFile,
        practitioners
      });
    }else{
      history.push(paymentInfoRoute.path, {
        formData: data,
        emiratesIDImage,
        emiratesIDBackImage,
        medicalDocFile,
        recordFile,
      });
    }
  };
  const language = useSelector((state) => state.language);
  return (
    <CirclesBackground>
      {/* <Prompt
        when={
          !currentAppointment || Object.values(currentAppointment).length === 0
        }
        message="Are you sure you want to leave this page ?"
      /> */}
      <FilterSideMenu
        isOpened={filterSideMenuOpen}
        onClosePressed={closeFiltersSideMenu}
      />
      <div className={"page-container d-flex"+`${language.direction}`}>
        <div className="main container-fluid">
          <div className="provide-consultation-info px-4">
            <section className="row">
              <div className="col-1 d-flex align-items-center">
                <BackButton />
              </div>
              <div className="col-10 d-flex align-items-center">
                <h5>{language.lang["provide-info"]}</h5>
              </div>
            </section>
            <OnboardingForm {...props} onFormSubmited={onFormSubmited} type={false}/>
          </div>
        </div>
      </div>
    </CirclesBackground>
  );
};

export default WalkinForm;
