import moment from "moment";
import store from "../redux/store";
import axios from "./Axios";
import { format, addDays } from 'date-fns';
export const createMealPlan = async ({
  name,
  description,
  imageURL,
  caloriesInTake,
  totalMealsAdded,
  planLenghInDays,
  startDateMoment,
  endDateMoment,
  timeZoneDifferenceInMinutes,
  nutritionInfo,
  plannedMeals,
  plannedMealsIngredients,
  isAutoGenerated,
  nutritionCalculatorInfo,
  isAutoCreated,
  groceryShoppingDay,
}) => {
  try {
    const data = {};
    if (name) {
      data["mealPlanName"] = name;
    }
    if (description) {
      data["mealPlanDescription"] = description;
    }
    if (imageURL) {
      data["mealPlanImageUrl"] = imageURL;
    }
    if (caloriesInTake) {
      data["caloriesIntakeTarget"] = caloriesInTake;
    }
    if (totalMealsAdded) {
      data["amountOfMeals"] = totalMealsAdded;
      data["totalMealsAdded"] = totalMealsAdded;
    }
    if (planLenghInDays) {
      data["mealPlanLength"] = planLenghInDays;
    }
    if (startDateMoment) {
      data["mealPlanStartDate"] = startDateMoment.format("YYYY-MM-DD");
    }
    if (endDateMoment) {
      data["mealPlanEndDate"] = endDateMoment.format("YYYY-MM-DD");
    }
    if (timeZoneDifferenceInMinutes !== undefined) {
      const momentDuration = moment.duration(timeZoneDifferenceInMinutes, "minutes");
      let timeZone;
      if (timeZoneDifferenceInMinutes >= 0) {
        timeZone = `+${momentDuration.hours()}:${momentDuration.minutes()}:${momentDuration.seconds()}`;
      } else {
        timeZone = `-${momentDuration.hours()}:${momentDuration.minutes()}:${momentDuration.seconds()}`;
      }
      data["remindMeTimezone"] = timeZone;
    }
    if (nutritionInfo) {
      data["nutritionInfo"] = nutritionInfo;
    }
    if (plannedMeals) {
      data["plannedMeals"] = plannedMeals;
    }
    if (plannedMealsIngredients) {
      data["plannedMealsIngredients"] = plannedMealsIngredients;
    }
    if (isAutoGenerated !== undefined) {
      data["isAutoGenerated"] = isAutoGenerated;
    }
    if (nutritionCalculatorInfo) {
      data["nutritionCalculatorInfo"] = nutritionCalculatorInfo;
    }
    if (groceryShoppingDay) {
      data["groceryShoppingDay"] = groceryShoppingDay;
    }
    if (isAutoCreated !== undefined) {
      data["isAutoCreated"] = isAutoCreated;
    }
    const res = await axios.post("/createMealplan", data);
    return res.data.response.id;
  } catch (e) {
    console.log("--- createMealPlan error");
    console.log(e);
    return null;
  }
};

export const getUserValidMealPlans = async () => {
  const userID = store.getState().auth?.userData?.id;
  if (!userID) return null;
  const mealPlans = await getMealPlans({ userID });
  return mealPlans.filter((item) => {
    const startDate = moment(item.mp_start_date);
    const endDate = moment(item.mp_end_date);
    const now = moment();
    return now.isSameOrAfter(startDate) && now.isBefore(endDate);
  });
};

export const getMealPlans = async ({ planID, userID, planName, planURLName, isForMarkting = false, offset = 0, limit = 20 }) => {
  try {
    const params = { isForMarketing: isForMarkting };
    if (planID) {
      params["mealplanId"] = planID;
    }
    if (userID) {
      params["userId"] = userID;
    }
    if (planName) {
      params["mealPlanName"] = planName;
    }
    if (planURLName) {
      params["mealPlanUrlName"] = planURLName;
    }
    if (offset) {
      params["offset"] = offset;
    }
    if (limit) {
      params["limit"] = limit;
    }
    const res = await axios.get("/getAllMealplans", { params });
    return res.data.response;
  } catch (e) {
    console.log("--- getMealPlans error");
    console.log(e);
    return null;
  }
};

export const userOnboardForMealPlan = async ({
  dietType,
  dietGoal,
  allergies,
  majorExclusions,
  subExclusions,
  customExclusions,
}) => {
  try {
    const exclusions = [];
    for (const allergie of allergies) {
      const allergieID = allergie.exclusionMajor.id ?? allergie.exclusionSub.id ?? allergie.id;
      const isMajor = allergie?.exclusionMajor ? true : false;
      exclusions.push({
        id: allergieID,
        name: (allergie?.exclusionMajor?.name ?? allergie?.exclusionSub?.name)?.toLocaleLowerCase(),
        isMajor,
        isSub: !isMajor,
        isCustom: false,
        isAllergy: true,
        tags: allergie?.exclusionMajor?.tags ?? allergie?.exclusionSub?.tags,
      });
    }
    for (const exclusion of majorExclusions) {
      exclusions.push({
        id: exclusion.id,
        name: exclusion.name?.toLocaleLowerCase() ?? "",
        isMajor: true,
        isSub: false,
        isCustom: false,
        isAllergy: false,
        tags: exclusion.tags,
      });
    }
    for (const exclusion of subExclusions) {
      exclusions.push({
        id: exclusion.id,
        name: exclusion.name?.toLocaleLowerCase() ?? "",
        isMajor: false,
        isSub: true,
        isCustom: false,
        isAllergy: false,
        tags: exclusion.tags,
        majorId: exclusion.majorId,
        majorName: exclusion.majorName,
      });
    }
    for (const exclusion of customExclusions ?? []) {
      exclusions.push({
        id: null,
        name: exclusion?.toLocaleLowerCase() ?? "",
        isMajor: false,
        isSub: false,
        isCustom: true,
        isAllergy: false,
      });
    }
    // debugger;
    const res = await axios.post("/userOnboardForMealplan", {
      dietGoal,
      dietType,
      allergiesAndExclusions: exclusions,
    });
    return res.data.response.id;
  } catch (e) {
    console.log("--- userOnboardForMealPlan error");
    console.log(e);
    return null;
  }
};

export const getAllergiesAndExclusions = async ({ dietType }) => {
  try {
    const res = await axios.get("/getExclusionsAndAllergies", { params: { dietTypeId: dietType } });
    return res.data.response;
  } catch (e) {
    console.log("--- getExclusionsAndAllergies error");
    console.log(e);
    return null;
  }
};

export const getFoodAndRecipeCategories = async () => {
  try {
    const res = await axios.get("/getFoodAndRecipeCategories");
    return res.data.response;
  } catch (e) {
    console.log("--- getFoodAndRecipeCategories error");
    console.log(e);
    return null;
  }
};

export const getDietTypes = async () => {
  try {
    const res = await axios.get("/getDietTypes");
    return res.data.response;
  } catch (e) {
    console.log("--- getDietTypes error");
    console.log(e);
    return null;
  }
};

export const getDietGoals = async () => {
  try {
    const res = await axios.get("/getDietGoals");
    return res.data.response;
  } catch (e) {
    console.log("--- getDietGoals error");
    console.log(e);
    return null;
  }
};

export const getNutritionDescriptions = async () => {
  try {
    const res = await axios.get("/nutritionDescriptions");
    return res.data.response;
  } catch (e) {
    console.log("--- getNutritionDescriptions error");
    console.log(e);
    return null;
  }
};


// Inserted By mahi
export const getactiveMealPlansbyuserId = async (userId,date) => {
  const formattedDate = format(date, 'yyyy-MM-dd');
  try {
    const res = await axios.get("/getactiveMealPlansbyuserId",{
      params: {
        userId: userId,
        date: formattedDate
      }}
      );
    return {
      results: res.data.results,
      mealsplanid: res.data.mealsplanid
    };
  } catch (e) {
    console.log("--- getgetactiveMealPlansbyuserId error");
    console.log(e);
    return null;
  }
};