import React, { useEffect, useState } from "react";
import CardPaymentTab from "./CardPaymentTab";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  submitOboardingForm,
  scheduleAppointment,
} from "../../components/OnboardingForm/actions";
import {
  showErrorNotification,
  showSuccessNotification,
  uploadFile,
} from "../../utility/Helpers";
import {
  setIsNotWatingForDoctorApprovalAction,
  setIsWatingForDoctorApprovalAction,
} from "../../redux/client/actionsCreators";
import {
  categoryMentalHealth,
  fileTypeAudio,
  fileTypeImage,
  membershipPaymentType,
  storageTypeAudio,
  storageTypeDocument,
  storageTypeImage,
  userTypeUser,
  cardPaymentType,
  appointmentTypeScheduled,
} from "../../utility/constants";
import Loading from "../../components/Loading";
import { dashboardRoute, walkinFormRoute } from "../../routes";
import moment from "moment";
import NotificationsButton from "../../components/NotificationsButton";
import BackButton from "../../components/BackButton";

const PaymentInfo = ({ history }) => {
  const dispatch = useDispatch();
  const {
    formData,
    medicalDocFile,
    emiratesIDImage,
    recordFile,
    selectedSlot,
    practitioner,
    emiratesIDBackImage,
    paymentRegister,
  } = history.location.state || {};
  const [selectedTab, setSelectedTab] = useState(2);
  const [showModal, setShowModal] = useState(false);
  const [showScheduledAppointmentModal, setShowScheduledAppointmentModal] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const { userData } = useSelector((state) => state.auth);

  useEffect(() => {
    // if (!formData || Object.keys(formData).length === 0) history.goBack();
    // return () => {
    //   history.location.state = null;
    // };
  }, []);

  const membershipPaymentTabPressed = () => {
    if (selectedTab === 1) return;
    setSelectedTab(1);
  };

  const cardPaymentTabPressed = () => {
    if (selectedTab === 2) return;
    setSelectedTab(2);
  };

  const cardPaymentSuccess = (transactionID) => {
    handlePaymentSuccess(cardPaymentType, transactionID);
  };

  const membershipPayment = () => {
    handlePaymentSuccess(membershipPaymentType);
  };

  const handlePaymentSuccess = async (paymentType, transactionID) => {
    setLoading(true);
    setPaymentSuccess(true);
    try {
      const fileURLs = await Promise.all([
        uploadEmiratesID(emiratesIDImage),
        uploadMedicalRecord(medicalDocFile),
        uploadAudioRecord(recordFile),
        uploadBackEmiratesID(emiratesIDBackImage),
      ]);
      if (fileURLs[0] != null) {
        formData.emiratesIdPhotoUrl = fileURLs[0];
      }
      if (fileURLs[3] != null) {
        formData.emiratesIdPhotoUrlForBack = fileURLs[3];
      }
      if (transactionID) formData.transactionId = transactionID;
      formData.medicalDocumentUrl = fileURLs[1];
      formData.slotId = selectedSlot && selectedSlot.slot_id;
      formData.practitionerId = practitioner && practitioner.id;
      formData.callType = "video";
      formData.voiceNoteUrl = fileURLs[2];
      // formData.profileCompleteStep = `${profileStep}`;
      formData.paymentType = paymentType;
      console.log("data", formData);
      if (formData.appointmentType === appointmentTypeScheduled) {
        await submitScheduledAppointment(formData);
      } else {
        await submitWalkinAppointment(formData);
      }
    } catch (e) {
      console.log("--- handlePaymentSuccess error");
      console.log(e);
      setPaymentSuccess(false);
      showErrorNotification(e.response && e.response.data.error);
    } finally {
      console.log("--- finally");
      setLoading(false);
    }
  };

  const submitScheduledAppointment = async (formData) => {
    const res = await scheduleAppointment(formData);
    const { appointmentId, message } = res;
    if (!appointmentId) return showErrorNotification();
    showSuccessNotification(message);
    setShowScheduledAppointmentModal(true);
  };

  const submitWalkinAppointment = async (formData) => {
    const appointmentID = await submitOboardingForm(formData);
    if (!appointmentID) {
      return showErrorNotification();
    }
    dispatch(setIsWatingForDoctorApprovalAction(appointmentID));
    setShowModal(true);
  };

  const uploadEmiratesID = async (emiratesIDImage) => {
    if (!emiratesIDImage) return null;
    const data = {
      storageType: storageTypeImage,
      category: categoryMentalHealth,
      userType: userTypeUser,
      fileType: fileTypeImage,
      userId: userData.id,
    };
    data["multi-files"] = emiratesIDImage;
    return uploadFile(data);
  };
  const uploadBackEmiratesID = async (emiratesIDBackImage) => {
    if (!emiratesIDBackImage) return null;
    const data = {
      storageType: storageTypeImage,
      category: categoryMentalHealth,
      userType: userTypeUser,
      fileType: fileTypeImage,
      userId: userData.id,
    };
    data["multi-files"] = emiratesIDBackImage;
    return uploadFile(data);
  };
  const uploadMedicalRecord = async (medicalDocFile) => {
    if (!medicalDocFile) return null;
    const data = {
      storageType: storageTypeDocument,
      category: categoryMentalHealth,
      fileType: fileTypeImage,
      userType: userTypeUser,
      userId: userData.id,
    };
    data["multi-files"] = medicalDocFile;
    return uploadFile(data);
  };

  const uploadAudioRecord = async (recordFile) => {
    if (!recordFile) return null;
    const data = {
      storageType: storageTypeAudio,
      category: categoryMentalHealth,
      userType: userTypeUser,
      fileType: fileTypeAudio,
      userId: userData.id,
    };
    data["multi-files"] = recordFile;
    return uploadFile(data);
  };

  const onModalClosePressed = () => {
    dispatch(setIsNotWatingForDoctorApprovalAction());
    setShowModal(false);
    history.replace(walkinFormRoute.path);
  };

  const modalOkPressed = () => {
    history.replace(dashboardRoute.path);
  };

  const language = useSelector((state) => state.language);

  return (
    <div className="payment-info pl-3" style={{ marginBottom: "30rem" }}>
      <div className="row d-flex justify-content-between align-items-center">
        <h2 className="text-nowrap" style={{ maxWidth: "min-content",    display: "flex" }}>
          <BackButton />
          {language.lang["payment-info"]}
        </h2>
        <div style={{ maxWidth: "min-content" }}>
          <NotificationsButton className="ml-auto" />
          {/* <ProfileCircleButton /> */}
        </div>
      </div>
      <div className="fluid-container mx-5 mt-4">
        {/* <div className="row mt-5">
           {userData.user_group == userTypeTest && (
            <div className="col-lg-4 col-6">
              <button className={`payment-tab-btn ${selectedTab === 1 ? "selected" : ""}`} onClick={membershipPaymentTabPressed}>
                {language.lang["pay-membership"]}
              </button>
            </div>
          )}
          <div className="col-lg-4 col-6">
            <button className={`payment-tab-btn ${selectedTab === 2 ? "selected" : ""}`} onClick={cardPaymentTabPressed}>
              {language.lang["pay-card"]}
            </button>
          </div>
        </div>
        <div className="row mt-5">
          {loading ? (
            <Loading />
          ) : (
            !paymentSuccess && (
              <div className="col-12">
                {/* {selectedTab === 1 ? (
                  userData.user_group == userTypeTest && <MembershipPaymentTab onPayPressed={membershipPayment} />
                ) : (
                  (<CardPaymentTab onPaymentSuccess={cardPaymentSuccess} formData={formData} unpaid={paymentRegister}/>)
                  )} 
                    <CardPaymentTab onPaymentSuccess={cardPaymentSuccess} formData={formData} unpaid={paymentRegister}/>
              </div>
            )
          )}
        </div> */}
        <CardPaymentTab
          onPaymentSuccess={cardPaymentSuccess}
          formData={formData}
          unpaid={paymentRegister}
        />
      </div>
      {practitioner && Object.keys(practitioner).length !== 0 && (
        <Modal
          size="sm"
          backdrop="static"
          show={showScheduledAppointmentModal}
          className="modal_custom"
        >
          <Modal.Body>
            <div className="d-flex flex-column align-items-center">
              <span>
                Your request for Dr. {practitioner.name}{" "}
                {practitioner.surname || ""}
              </span>
              <span>
                {moment(selectedSlot.end_time).format("MMMM D YYYY")}{" "}
                {moment(selectedSlot.start_time).format("hh:mm a")} -{" "}
                {moment(selectedSlot.end_time).format("hh:mm a")}
                <span> has been sent</span>
              </span>
              <span className="text-center">
                You will get a notification when the doctor accepts your request
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={modalOkPressed} className="btn btn-success">
              Ok
            </button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal size="sm" backdrop="static" show={showModal}>
        <Modal.Body>
          <div
            className={
              "d-flex flex-column align-items-center" + `${language.direction}`
            }
          >
            <span className="text-success">{language.lang.success}</span>
            <span className="mb-4">
              {language.lang["searching-for-doctor"]}...
            </span>{" "}
            <Loading />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={onModalClosePressed} className="btn btn-secondary">
            {language.lang.cancel}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PaymentInfo;
