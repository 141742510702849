import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Modal } from "react-bootstrap";
import { Rate } from "antd";
// Critical River, Varun Mishra, this package is not compatible with react version 18.
// import ImagePicker from 'react-image-picker';
// import 'react-image-picker/dist/index.css';
import docProfileImg from "../assets/images/doc-profile-img.jpeg";
import dummyImg1 from "../assets/images/dummy-img-1.jpeg";
import dummyImg2 from "../assets/images/dummy-img-2.jpeg";
import { Select } from 'antd';
import axios from "../services/Axios";
import {
  showErrorNotification,
  showSuccessNotification,
  arr
} from "../utility/Helpers";
import { ImPlus } from "react-icons/im";
import ComboFeatureModalHelper from "./ComboFeatureModalHelper";
import { settings } from "../config";
import { useDispatch, useSelector } from 'react-redux';
import { setGalleryMedia } from '../redux/superAdmin/actionCreators';

const ComboFeatureModal = (props) => {

    const GalleryMedia = useSelector((state) => state.superAdmin);
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false);
    const [sectionOneData, setsectionOneData] = useState(['']);
    const [sectionTwoData, setsectionTwoData] = useState(['']);
    const [sectionThreeData, setsectionThreeData] = useState(['']);
    const [image, setImage] = useState(null);
    const [dropData, SetdropData] = useState(['']);
    const [shown, Setshown] = useState(false);
    const [updata, Setupdata] = useState(0);
    const [srchImg, SetsrchImg] = useState("");
    const [srchVid, SetsrchVid] = useState("");
    const [imageList,SetimageList] = useState([]);
    const [videosList,SetvideosList] = useState([]);
    const [documents,SetdocumentsList] = useState([]);
    const [animations,SetanimationsList] = useState([]);
    const [backUpImages, setbackUpImages] = useState([]);
    const [backUpVideos, setbackUpVideos] = useState([]);
    const [preview, setpreview] = useState(false);
    const [Inputnew, SetInputnew] = useState({
        featureMasterId : 0,
        featureSectionName : ""
    });
    const [Input, setInput] = useState({
        featureMasterId: "",
        featureSection: "",
        descriptionMainHeader: "",
        descriptionSubHeader: "",
        descriptionContent: "",
        isMostPopular: false,
        isPaidContent: false,
        isCorporateSpecific : false,
        content: [],
        thumbnail: [],
        rating: "1",
    });
    const [allInput, SetallInput] = useState({
        comboName : "Fitness and Meal Plans",
        comboRating : "4.5",
        comboFeatures: []
    });
    /*useEffect(()=>{
        props.data && props.data.length > 0  && props.data[0] && props.data[0].featureContent &&  separatenewMedia(props.data[0].featureContent);
    },[]);*/

        useEffect(() => {
            GalleryMedia.gallery.length > 0 && setMedia();
            GalleryMedia.gallery.length === 0 && dispatch(setGalleryMedia());
        },[GalleryMedia])

    const setMedia = async () =>{
        SetimageList([...GalleryMedia.images]);
        SetvideosList([...GalleryMedia.videos]);
    }
    /*const separatenewMedia = (data) =>{
        data.map((obj) =>{
            if(obj.galleryItemInfo.type === 'image/png') SetimageList(prevArray =>  [...prevArray, {...obj.galleryItemInfo,selected : true}]);
            if(obj.galleryItemInfo.type=='video/mp4') SetvideosList(prevArray =>  [...prevArray, {...obj.galleryItemInfo,selected : true}]);
        })
    }*/
    const giveData = async (id) =>{
        await axios.get(`/getFeatureSection?featureMasterId=${id}`)
            .then((response) => {
            SetdropData(response.data.response[0].featureSection);
          })
          .catch((error) => { console.log(error.response.status) });
    }
    const postData = async () =>{
        if(Inputnew.featureMasterId != -1){ await axios({
            method: 'post',
            url: '/createFeatureSection',
            data:  Inputnew,
            config: { headers: {'Content-Type': 'application/json' }}
            })
            .then(function (response) {
                //handle success
                Setshown(!shown);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });}
            giveData(updata);
    }
    const onChangenewHandler = (e)=> {
        if( typeof(e) === "number" ){
            Setupdata(e);
            giveData(e);
            SetdropData([]);
            setInput((prevState)=>{
                return {
                    ...prevState,
                    "featureMasterId": e
                }
            });
            SetInputnew((prevState)=>{
                return {
                    ...prevState,
                    "featureMasterId": e
                }
            });
        }
        else{
            let val = e.target.value;
            setInput((prevState)=>{
                return {
                    ...prevState,
                    "featureSection": val
                }
            });
            if(e.target.name == "featureSectionName"){
                SetInputnew((prevState)=>{
                    return {
                        ...prevState,
                        "featureSectionName": val
                    }
                });
            }
            
        }

    }




    const onPick = (image)=> {}
    const addImage = (i,select) => {
        const newImage = [...imageList];
        newImage[i] = {...newImage[i],selected:!select};
        SetimageList(newImage);
        if(select){ 
            removeMedia(newImage[i].id,i);
            return;
         }
        if(!select){
         setInput((prevState)=>{
             return {
                 ...prevState,
                 content: [...prevState.content,  newImage[i].id]
             }
         });
        }
     }
 
     const addVideo = (i,select) => {
        const newVideo = [...videosList];
        newVideo[i] = {...newVideo[i],selected:!select};
        SetvideosList(newVideo);
        if(select){ 
            removeMedia(newVideo[i].id,i);
            return;
         }
        if(!select){
         setInput((prevState)=>{
             return {
                 ...prevState,
                 content: [...prevState.content,  newVideo[i].id]
             }
         });
        }
     }
     const removeMedia = (id) =>{
         const changeInput = Input.content.filter((val) => val !== id)
         setInput((prevState)=>{
             return {
                 ...prevState,
                 content: changeInput
             }
         });
     }
    // 
    const onSelectHandler = (e)=> {
        setInput((prevState)=>{
            return {
                ...prevState,
                "featureSection": e
            }
        });
    }
    const onSelectHandlerCorporate = (e)=> {
        //  console.log(e);
          setInput((prevState)=>{
              return {
                  ...prevState,
                  "corporate": e
              }
          });
      }
    const onChangeHandler = (e)=> {

        if( typeof(e) === "number" ){
            setInput((prevState)=>{
                return {
                    ...prevState,
                    rating: e
                }
            });
        }
        else{
            // console.log(e);
            let nam = e.target.name;
            let val = e.target.value;
            setInput((prevState)=>{
                return {
                    ...prevState,
                    [nam]: val
                }
            });
        }

    }
    const apndData = () => {
        SetallInput((prevState) =>{
            return {
                ...prevState,
                comboFeatures: [...prevState.comboFeatures, Input]
            }
        })
    }
    const submitHandler = async ()=> {
        setloading(true);
        if(!(Input["content"].length > 0 && Input["featureMasterId"] && Input["featureSection"] && Input["descriptionMainHeader"] && Input["descriptionSubHeader"] && Input["descriptionContent"] && Input["rating"] && image))
        {
            showErrorNotification('Please fill the form.');
        }else{
            try {
                const response = await axios.post(`${settings.apiBaseURL}/createComboFeature`, allInput);
                console.log("The response is", response);
                showSuccessNotification('Submitted');
            } catch (e) {
                showErrorNotification(e.response.data.error);
            }
        }
        setloading(false);
    }

    const searchContent = async (type)=> {
        setbackUpImages(imageList);
        setbackUpVideos(videosList);
        type === "image" && SetimageList(false);
        type === "video" && SetvideosList(false);
        var key = type === "image" ? srchImg : srchVid ;
        await axios.get(`${settings.apiBaseURL}/searchGalleryMedia?tagName=${key}`)
            .then((response) => {
                const data = response.data.response;
                data.map((obj) =>{
                    if(obj.galleryMediaInfo.type === 'image/png') SetimageList(prevArray =>  [...prevArray, {...obj.galleryMediaInfo,selected : false}]);
                    if(obj.galleryMediaInfo.type=='video/mp4') SetvideosList(prevArray =>  [...prevArray, {...obj.galleryMediaInfo,selected : false}]);
                })
            })
    }
    useEffect(() =>{
        backUpImages.length > 0 && !srchImg && SetimageList(backUpImages);
    },[srchImg])
    useEffect(() =>{
        backUpVideos.length > 0 && !srchVid && SetvideosList(backUpVideos);
    },[srchVid])
    const [showSection, setShowSection] = useState({
        section1: true,
        section2: false,
        section3: false,
    });
    useEffect(() => {
        setsectionOneData(props && props.data && props.data[0].comboFeatures && props.data[0].comboFeatures[0] && props.data[0].comboFeatures[0].features && props.data[0].comboFeatures[0].features[0] && props.data[0].comboFeatures[0].features[0])
        setsectionTwoData(props && props.data && props.data[0].comboFeatures && props.data[0].comboFeatures[1] && props.data[0].comboFeatures[1].features && props.data[0].comboFeatures[1].features[0] && props.data[0].comboFeatures[1].features[0])
        setsectionThreeData(props && props.data && props.data[0].comboFeatures && props.data[0].comboFeatures[2] && props.data[0].comboFeatures[2].features && props.data[0].comboFeatures[2].features[0] && props.data[0].comboFeatures[2].features[0])
    },[sectionOneData,sectionTwoData,sectionThreeData])
    return (        
            <div className="single-feature-modal">
                <Modal.Header closeButton>
                    <h3 className="single-feature-heading">Map the Content with Combo Feature</h3>
                </Modal.Header>
                
                <div className="plus-icons--block">
                    <div className={showSection.section1 ? 'red-plus': 'green-plus'} onClick={()=>{
                        setShowSection({
                            section1: true,
                            section2: false,
                            section3: false,
                        })
                    }}>
                        <ImPlus size={22} className="mr-1" />
                        <ImPlus size={22} />
                    </div>
                    <div className="plus-line" style={{backgroundColor: showSection.section2 || showSection.section3 ? '#8fa7b8': ''}}></div>
                    <div className={showSection.section2 ? 'red-plus': 'green-plus'} onClick={()=>{
                        setShowSection({
                            section1: false,
                            section2: true,
                            section3: false,
                        });
                        setImage(null);
                        apndData();
                    }}>
                        <ImPlus size={22} className="mr-1" />
                        <ImPlus size={22} />
                    </div>
                    <div className="plus-line" style={{backgroundColor: showSection.section3 ? '#8fa7b8': ''}}></div>
                    <div className={showSection.section3 ? 'red-plus': 'green-plus'} onClick={()=>{
                        setShowSection({
                            section1: false,
                            section2: false,
                            section3: true,
                        });
                        setImage(null);
                        apndData();
                    }}>
                        <ImPlus size={22} className="mr-1" />
                        <ImPlus size={22} />
                    </div>
                </div>

                <div className="features-header">
                    <div style={{paddingLeft: '20px'}}>Map your First Feature</div>
                    <div>Map your Second Feature</div>
                    <div>Map your Third Feature</div>
                </div>
                { showSection.section1 && <ComboFeatureModalHelper Input={sectionOneData} onSelectHandler={onSelectHandler} onSelectHandlerCorporate={onSelectHandlerCorporate} onPick={onPick} submitHandler={submitHandler} setMedia={setMedia} onChangenewHandler={onChangenewHandler} image={image} dropData={dropData} shown={shown} Setshown={Setshown} videosList={videosList} documents={documents} animations={animations} Inputnew={Inputnew} Input={Input} onPick={onPick} searchContent={searchContent} updata={updata} postData={postData} onSelectHandler={onSelectHandler} onChangeHandler={onChangeHandler} submitHandler={submitHandler} Setupdata={Setupdata} SetsrchImg={SetsrchImg} SetsrchVid={SetsrchVid} imageList={imageList} SetimageList={SetimageList} SetvideosList={SetvideosList} SetdocumentsList={SetdocumentsList} SetanimationsList={SetanimationsList} SetInputnew={SetInputnew} setInput={setInput}  data={sectionOneData} addImage={addImage} addVideo={addVideo} removeMedia={removeMedia} preview={preview} setpreview={setpreview} loading={loading} setloading={setloading}/>}
                { showSection.section2 && <ComboFeatureModalHelper Input={sectionTwoData} onSelectHandler={onSelectHandler} onSelectHandlerCorporate={onSelectHandlerCorporate} onPick={onPick} submitHandler={submitHandler} setMedia={setMedia} onChangenewHandler={onChangenewHandler} image={image} dropData={dropData} shown={shown} Setshown={Setshown} videosList={videosList} documents={documents} animations={animations} Inputnew={Inputnew} Input={Input} onPick={onPick} searchContent={searchContent}  updata={updata} postData={postData} onSelectHandler={onSelectHandler} onChangeHandler={onChangeHandler} submitHandler={submitHandler} Setupdata={Setupdata} SetsrchImg={SetsrchImg} SetsrchVid={SetsrchVid} imageList={imageList} SetimageList={SetimageList} SetvideosList={SetvideosList} SetdocumentsList={SetdocumentsList} SetanimationsList={SetanimationsList} SetInputnew={SetInputnew} setInput={setInput} data={sectionTwoData} addImage={addImage} addVideo={addVideo} removeMedia={removeMedia} preview={preview} setpreview={setpreview} loading={loading} setloading={setloading}/> }
                { showSection.section3 && <ComboFeatureModalHelper Input={sectionThreeData} onSelectHandler={onSelectHandler} onSelectHandlerCorporate={onSelectHandlerCorporate} onPick={onPick} submitHandler={submitHandler} setMedia={setMedia} onChangenewHandler={onChangenewHandler} image={image} dropData={dropData} shown={shown} Setshown={Setshown} videosList={videosList} documents={documents} animations={animations} Inputnew={Inputnew} Input={Input} onPick={onPick} searchContent={searchContent}  updata={updata} postData={postData} onSelectHandler={onSelectHandler} onChangeHandler={onChangeHandler} submitHandler={submitHandler} Setupdata={Setupdata} SetsrchImg={SetsrchImg} SetsrchVid={SetsrchVid} imageList={imageList} SetimageList={SetimageList} SetvideosList={SetvideosList} SetdocumentsList={SetdocumentsList} SetanimationsList={SetanimationsList} SetInputnew={SetInputnew} setInput={setInput} data={sectionThreeData} addImage={addImage} addVideo={addVideo} removeMedia={removeMedia} preview={preview} setpreview={setpreview} loading={loading} setloading={setloading}/> }
            </div>
    );
}

export default ComboFeatureModal;
