import { useState, useEffect } from "react";
import ProfileCircleButton from "../components/ProfileCircleButton";
import NotificationsButton from "../components/NotificationsButton";
import BackButton from "../components/BackButton";
import { Row, Col, Modal } from "react-bootstrap";
import VoucherCard from "./VoucherCard";
import VoucherModal from "./VoucherModal";
// import { Modal } from 'antd';
import axios from "../services/Axios";
import { useSelector } from "react-redux";

const Voucher = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  useEffect(() => {
    getVoucherDetails();
  }, []);

  const getVoucherDetails = async () => {
    try {
      const response = await axios.get(`/getAllVouchers`);
      console.log("resp", response);
      const data = response.data.response;
    } catch (e) {
      console.log(e);
    }
  };
  const language = useSelector((state) => state.language);
  return (
    <div className="voucher">
      <div className="dashboard-book-consultation">
        <div className="col-12 notifications-and-profile mt-3 mt-md-0 justify-content-between mx-2">
          <div>
            <BackButton />
            <span className="consultation-heading">Settings</span>
          </div>
          <div style={{ paddingRight: "15px", display: "flex" }}>
            <NotificationsButton />
            <ProfileCircleButton />
          </div>
        </div>
      </div>

      <h5>Voucher</h5>
      <div className="voucher--rows">
        <Row>
          <div className="btn-blocks">
            <h5 className="voucher--rows-all">All Vouchers</h5>
            <div className="mt-2 btn-blocks--inside">
              <button className="sell-btn">
                Sell Vouchers {language.lang.online}
              </button>
              <button className="create--btn" onClick={showModal}>
                Create Voucher
              </button>
            </div>
          </div>
          <Col className="my-3 voucher-cards-block">
            <VoucherCard className="voucher-card purple-card" />
            <VoucherCard className="voucher-card green-card" />
            <VoucherCard className="voucher-card black-card" />
            <VoucherCard className="voucher-card green-card" />
            <VoucherCard className="voucher-card purple-card" />
            <VoucherCard className="voucher-card black-card" />
          </Col>
        </Row>
      </div>

      <Modal show={isModalVisible} onHide={handleCancel} size="xl">
        <VoucherModal show={setIsModalVisible} />
      </Modal>

      {/* <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                centered
                width={990}
            >
              <VoucherModal />
            </Modal> */}
    </div>
  );
};

export default Voucher;
