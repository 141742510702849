import { useState, useEffect, useReducer, useRef } from "react";
import { IoMdSend } from "react-icons/io";
import { CgAttachment } from "react-icons/cg";
import { Form } from "react-bootstrap";
import SentMsgItem from "../SentMsgItem";
import ReceivedMsgItem from "../ReceivedMsgItem";
import AgoraRTM from "agora-rtm-sdk";
import {
  agoraAppId,
  currentAppointmentDetailsKey,
  userRoleClient,
} from "../../utility/constants";
import { useSelector } from "react-redux";
import reducer, { initState } from "./reducer";
import {
  setAgoraChannelAction,
  setAgoraClientAction,
  leaveChannelAction,
  addMessageAction,
  saveMessage,
  setChatMessagesAction,
} from "./actions";
import uuid from "react-uuid";
import axios from "../../services/Axios";
import { showErrorNotification } from "../../utility/Helpers";
import { useDropzone } from "react-dropzone";
import FileMsgItem from "../FileMsgItem";
import { settings } from "../../config";
import { getAgoraRTMToken } from "../../services/AgoraNetworkService";
import { fetchChatMessages } from "../../services/ChatNetworkService";

const VideoCallChatCard = () => {
  const appointmentDetails = JSON.parse(
    localStorage.getItem(currentAppointmentDetailsKey)
  );
  const channelID =
    appointmentDetails &&
    (appointmentDetails.appointmentId || appointmentDetails.id);
  const [state, dispatch] = useReducer(reducer, initState);
  const [messageText, setMessageText] = useState("");
  const messagesListRef = useRef(null);
  const messageFieldRef = useRef(null);
  const userData = useSelector((state) => state.auth.userData);
  const userID = userData.id;

  const postMessage = (type) => {
    if (!messageText) return;
    const message = { text: messageText, isSent: true, type: type };
    state.agoraChannel.sendMessage(message);
    dispatch(addMessageAction(message));
    if (type === "media") {
      saveMessage({
        channelId: channelID,
        userId: userID,
        message: messageText,
        files: acceptedFiles,
        type: type,
      });
    } else {
      saveMessage({
        channelId: channelID,
        userId: userID,
        message: messageText,
        type: type,
      });
    }
    setMessageText("");
    messageFieldRef.current.focus();
  };

  const scrollToBottom = () => {
    messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight;
  };

  useEffect(() => {
    fetchChatMessages(channelID).then((value) => {
      dispatch(setChatMessagesAction(value, userID));
    });
  }, []);

  useEffect(() => {
    if (!channelID) return;
    let channel;
    let client;
    getAgoraRTMToken({ userID, channelID })
      .then((token) => {
        console.log("--- chat token ", token);
        const options = {
          uid: userID,
          token: token,
        };

        const appID = agoraAppId;

        // Initialize client
        // const instance = new RtmClient();
        client = AgoraRTM.createInstance(appID);
        dispatch(setAgoraClientAction(client));

        // Display connection state changes
        client.on("ConnectionStateChanged", function (state, reason) {
          console.log("--- ConnectionStateChanged");
          console.log(state, reason);
        });

        channel = client.createChannel(channelID);
        dispatch(setAgoraChannelAction(channel));

        channel.on("ChannelMessage", onChannelMessage);
        // Display channel member stats
        channel.on("MemberJoined", function (memberId) {
          console.log("--- MemberJoined");
          console.log(memberId);
        });
        // Display channel member stats
        channel.on("MemberLeft", function (memberId) {
          console.log("--- MemberLeft");
          console.log(memberId);
        });

        client.login(options).then(() => {
          channel.join();
        });
      })
      .catch(() => {
        showErrorNotification();
      });

    return () => {
      dispatch(leaveChannelAction());
      if (channel) channel.leave();
      if (client) client.logout();
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [state.messagesList]);

  // const getAgoraRTMToken = async () => {
  //   try {
  //     const res = await axios.post("/rtmToken", {
  //       userId: userID,
  //       channelName: channelID,
  //     });
  //     return res.data.response;
  //   } catch (e) {
  //     console.log("--- getAgoraRTMToken error");
  //     console.log(e);
  //     return null;
  //   }
  // };

  const onChannelMessage = (message, memberId) => {
    message.isSent = false;
    dispatch(addMessageAction(message));
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  // const dropzoneRef = createRef();
  // const openDialog = () => {
  //   // Note that the ref is set async,
  //   // so it might be null at some point
  //   if (dropzoneRef.current) {
  //     dropzoneRef.current.open()
  //   }
  // };
  useEffect(() => {
    acceptedFiles.length > 0 &&
      acceptedFiles.map((item) => {
        postMessage("media");
      });
  }, [acceptedFiles]);
  const [senderDetails, setsenderDetails] = useState({});
  const getSenderDetails = async (pracId, patientId) => {
    if (userData?.role === userRoleClient) {
      await axios
        .get(
          `${settings.apiBaseURL}/getPractitionerDetails?practitionerId=${pracId}`
        )
        .then((response) => {
          setsenderDetails(response.data.response[0]);
        });
    } else {
      const res = await axios.get(
        `${settings.apiBaseURL}/getPatientDetails?patientId=${patientId}`
      );
      setsenderDetails(res.data.response[0]);
    }
  };

  useEffect(() => {
    // console.log("List of messages --> ",state.messagesList)
    if (senderDetails && Object.keys(senderDetails).length === 0) {
      getSenderDetails(
        appointmentDetails?.practitioner_id,
        appointmentDetails?.user_id
      );
    }
    console.log("appointmentDetails", appointmentDetails, senderDetails);
  }, [state.messagesList]);

  useEffect(() => {
    console.log("senderDetails -->", senderDetails);
  }, [senderDetails]);

  return (
    <div className="doc-patient-chat overflow-auto pr-4">
      <div ref={messagesListRef} className="messages-list">
        {state &&
          state.messagesList.map((item) => {
            const key = uuid();
            const messageText = item.text || item.message;
            if (item.isSent) {
              if (item.type === "media")
                return <FileMsgItem key={key} type="sender" />;
              return <SentMsgItem key={key} text={messageText} />;
            }
            if (item.type === "media")
              return <FileMsgItem key={key} type="receiver" />;
            return (
              <ReceivedMsgItem
                key={key}
                data={item}
                text={messageText}
                userImg={senderDetails?.profile_photo_url}
                name={
                  (senderDetails?.name && senderDetails?.name) +
                  " " +
                  (!!senderDetails?.surname ? senderDetails?.surname : "")
                }
              />
            );
          })}
        {/* <div className="recieved-msg--block">
          <div>
            <img
              src={docProfileImg}
              className="rounded-circle"
              alt="doc-profile"
              width="40px"
              height="40px"
            />
          </div>
          <div className="recieved-msg">Typing ......</div>
        </div> */}
      </div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          postMessage("text");
        }}
        className="sent-msg--input"
      >
        <Form.Group className="form-group">
          <div className="d-flex align-items-center p-1">
            <input {...getInputProps()} />
            <CgAttachment
              size={20}
              style={{ color: "#c0d6f9", cursor: "pointer" }}
              onClick={() => {
                open();
                setMessageText("File");
              }}
            />
          </div>
          <Form.Control
            type="text"
            className="shadow-none"
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            ref={messageFieldRef}
          />
          <IoMdSend
            className="post-msg"
            size={20}
            onClick={() => {
              postMessage("text");
            }}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default VideoCallChatCard;
