export const notificationEnum = {
  21: "newMatches",
  22: "messages",
  23: "messageLikes",
  24: "superLikes",
  25: "inAppVibrations",
  26: "inAppSounds",
};

export const LikeDisLikeEnum = {
  like: 34,
  DisLike: 35,
};

export const genderOptions = [
  {value: "", label: "All"},
  {value: "1", label: "Male"},
  {value: "2", label: "Female"}
]
