import axios from "./Axios";
import store from "../redux/store";

export const saveChatMessage = async ({
  channelID,
  messageText,
  type,
  module,
}) => {
  const userData = store.getState().auth.userData;
  const res = await axios.post("/saveMessage", {
    channelId: channelID,
    userId: userData.id,
    message: messageText,
    type,
    module: module || "chat",
  });
  return res.data.response.returning != null;
};

export const fetchChatMessages = async (channelID) => {
  try {
    const res = await axios.get("/getMessages", {
      params: {
        channelId: channelID,
      },
    });
    return res.data.response;
  } catch (e) {
    console.log("--- fetchChatMessages error");
    console.log(e);
    return null;
  }
};
