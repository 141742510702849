import { useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Redirect, Prompt } from "react-router-dom";
import BackButton from "../../components/BackButton";
import CirclesBackground from "../../components/CirclesBackground";
import LiveSessionVideoCard from "../../components/LiveSessions/LiveSessionVideoCard";
import NotificationsButton from "../../components/NotificationsButton";
import ProfileCircleButton from "../../components/ProfileCircleButton";
import axios from "../../services/Axios";
import AgoraRTC from "agora-rtc-sdk";
import {
  getUserMediaPermissions,
  showErrorNotification,
} from "../../utility/Helpers";
import reducer, { initState } from "./reducer";
import {
  agoraAppId,
  CameraAndAudioPermissionNotGranted,
  sessionHostMuteAllControlID,
  sessionHostUnmuteAllControlID,
} from "../../utility/constants";
import {
  addRemoteStreamIDAction,
  enableAudioAction,
  enableVideoAction,
  removeRemoteStreamIDAction,
  setClientAction,
  setLocalStreamAction,
  setSessionTimeEndedAction,
  addStreamAction,
  removeStreamAction,
  setShowChatCard,
  setPractitionerDetails,
  setExpandAttendeesListAction,
  setExpandChatMessagesList,
  seSessionAction,
} from "./actions";
import {
  getCustomerLiveSessionsList,
  getSessionAttendeesList,
  setSessionControls,
  updateSessionStatus,
} from "../../services/LiveSessionsNetworkService";
import {
  hostMuteAllAction,
  hostUnmuteAllAction,
  setLoadingHostMuteAllAction,
  updateSessionAttendeesListAction,
} from "../../redux/liveSession/actionCreators";
import PractitionerNetworkService from "../../services/PractitionerNetworkService";
import { dashboardRoute } from "../../routes";
import moment from "moment";
import LiveSessionChatCard from "../../components/LiveSessionChatCard";
import SessionTimeEndedModal from "./SessionTimeEndedModal";
import LiveSessionAudianceList from "../../components/LiveSessions/LiveSessionAudianceList";

const LiveSession = () => {
  const history = useHistory();
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initState);
  // const [practitionerDetails, setPractitionerDetails] = useState({});
  const language = useSelector((state) => state.language.lang);
  const userData = useSelector((state) => state.auth.userData);
  const hostMuteAll = useSelector((state) => state.liveSession.hostMuteAll);
  const session = state?.session || history.location.state?.session;
  const hostID = session.host_id || session.sessionDetails.host_id;
  const isHost = hostID === userData.id;
  const sessionID = session.sessionDetails
    ? session.sessionDetails.id
    : session.id;
  const userSessionID = isHost ? null : history.location.state.session.id;
  const remainingDuration = moment.duration(moment(session.end).diff(moment()));
  const { expandAttendeesList, expandChatMessagesList } = state;
  // console.log("--- LiveSession streams", sessionID, userSessionID);

  useEffect(() => {
    // return;
    initAgora();
    Promise.all([
      PractitionerNetworkService.getPractitionerDetails({
        practitionerID: hostID,
      }),
      updateAttendeesList(),
    ]).then((results) => {
      dispatch(
        setPractitionerDetails({ practitionerDetails: results[0] || {} })
      );
      dispatch(setShowChatCard({ showChatCard: true }));
    });
    window.addEventListener("beforeunload", pageUnloadListener);
  }, []);

  useEffect(() => {
    // host
    if (!isHost) return;
    console.log(
      "--- host session.is_host_muted_all",
      session.is_host_muted_all
    );
    if (session.is_host_muted_all) {
      handleHostMuteAll();
    } else {
      handleHostUnmuteAll();
    }
  }, [session, isHost]);

  useEffect(() => {
    // attendee
    if (isHost) return;
    reduxDispatch(hostMuteAllAction());
    disableAudio();
    updatedAttendeeMuteAllState();
  }, [isHost, state.localStream, session]);

  useEffect(() => {
    return () => {
      window.removeEventListener("beforeunload", pageUnloadListener);
    };
  }, [state.sessionTimeEnded]);

  useEffect(() => {
    // console.log("--- host mute all ", hostMuteAll);
    if (isHost) return;
    if (!state.agoraClient) return;
    if (hostMuteAll) {
      disableAudio();
    }
    // else {
    //   enableAudio();
    // }
    // disableAudio();
  }, [hostMuteAll, isHost, state.agoraClient]);

  // update session status when component unmounts
  useEffect(() => {
    return () => {
      updateSessionStatus({
        sessionID,
        userSessionID,
        statusID: 8,
      });
    };
  }, [sessionID, userSessionID]);

  // close local stream before component unmounts
  useEffect(() => {
    return () => {
      if (!state.localStream) return;
      state.localStream.close();
    };
  }, [state.localStream]);

  // leave channel before component unmounts
  useEffect(() => {
    return () => {
      if (!state.agoraClient) return;
      state.agoraClient.stopLiveStreaming();
      state.agoraClient.leave();
    };
  }, [state.agoraClient]);

  const updatedAttendeeMuteAllState = () => {
    const sessionData = session.sessionDetails
      ? session.sessionDetails
      : session;
    console.log(
      "--- attendee session.is_host_muted_all",
      sessionData.is_host_muted_all
    );
    if (sessionData.is_host_muted_all) {
      reduxDispatch(hostMuteAllAction());
      disableAudio();
    } else {
      // enableAudio();
      reduxDispatch(hostUnmuteAllAction());
    }
  };

  function pageUnloadListener(e) {
    e.preventDefault();
    e.returnValue = "";
  }

  if (!session) {
    return <Redirect to={dashboardRoute.path} />;
  }

  const initAgora = async () => {
    const sessionPromise = getCustomerLiveSessionsList({
      sessionID: sessionID,
      cacheRead: false,
    });
    const gotPermissionPromise = getUserMediaPermissions({
      video: true,
      audio: true,
    });
    const promisesResult = await Promise.all([
      sessionPromise,
      gotPermissionPromise,
    ]);
    try {
      const newSession = promisesResult[0][0];
      if (newSession) {
        dispatch(seSessionAction({ session: newSession }));
      }
    } catch (_) {}
    const gotPermission = promisesResult[1];
    if (!gotPermission) return;
    const channelID = sessionID;
    const rtcToken = await getAgoraToken(channelID);
    // console.log("--- live session ", rtcToken);
    if (!rtcToken) {
      return showErrorNotification();
    }
    const client = AgoraRTC.createClient({
      mode: "rtc",
      codec: "vp8",
    });
    dispatch(setClientAction(client));
    client.init(
      agoraAppId,
      () => {},
      function (err) {
        console.log("--- client init failed ", err);
      }
    );
    // client.setClientRole(isHost ? "host" : "audience");
    client.join(
      rtcToken,
      channelID,
      userData.id,
      (uid) => handleJoinChannelSuccess({ uid, client }),
      (error) => {
        showErrorNotification();
        console.log("--- join error", error);
      }
    );
    // client.on("client-role-changed", (event) => {
    //   console.log("--- client-role-changed", event);
    // });
    // Subscribe to the remote stream when it is published
    client.on("stream-added", (evt) => {
      console.log("--- agora stream-added");
      client.subscribe(evt.stream, handleError);
      updateAttendeesList();
    });
    // Play the remote stream when it is subscribed
    client.on("stream-subscribed", (evt) => {
      const stream = evt.stream;
      const streamId = String(stream.getId());
      console.log("--- agora stream-subscribed", streamId);
      dispatch(addRemoteStreamIDAction(streamId));
      dispatch(addStreamAction({ stream }));
      stream.play(streamId);
      // debugger;
      // if (stream.hasVideo()) {
      //   dispatch(setRemoteVideoStreamIDAction({ streamID: streamId }));
      // }
    });
    client.on("stream-removed", (evt) => {
      console.log("agora stream-removed", evt);
      const stream = evt.stream;
      const streamId = String(stream.getId());
      stream.close();
      dispatch(removeRemoteStreamIDAction(streamId));
      dispatch(removeStreamAction({ stream }));
      updateAttendeesList();
    });
    // client.on("mute-video", (evt) => {
    //   console.log("--- mute vidoe", evt);
    //   dispatch(removeRemoteStreamIDAction(evt.uid));
    // });
    // client.on("unmute-video", (evt) => {
    //   console.log("--- unmute vidoe", evt);
    //   dispatch(addRemoteStreamIDAction(evt.uid));
    //   const stream = state.streams.find((element) => {
    //     const id = String(element.getId());
    //     return id === evt.uid;
    //   });
    //   if (stream) {
    //     stream.play(evt.uid);
    //   }
    // });
    // Remove the corresponding view when a remote user leaves the channel.
    client.on("peer-leave", (evt) => {
      console.log("agora peer-leave", evt);
      const stream = evt.stream;
      const streamId = String(stream.getId());
      stream.close();
      dispatch(removeRemoteStreamIDAction(streamId));
      updateAttendeesList();
    });
  };

  const updateAttendeesList = async () => {
    const attendeesList = await getSessionAttendeesList({
      sessionID: sessionID,
    });
    if (!attendeesList) {
      return showErrorNotification();
    }
    // console.log("--- attendeesList", attendeesList.length, attendeesList);
    reduxDispatch(updateSessionAttendeesListAction({ attendeesList }));
  };

  const handleJoinChannelSuccess = ({ uid, client }) => {
    // console.log("--- join video success", uid, isHost, hostMuteAll);
    updateAttendeesList();
    // if (!isHost) return;
    // console.log("--- initializing local stream");
    const localStream = AgoraRTC.createStream({
      audio: state.enableAudio,
      video: state.enableVideo,
    });
    localStream.init(() => {
      client.publish(localStream, handleError);
      const streamId = String(localStream.getId());
      dispatch(addRemoteStreamIDAction(streamId));
      localStream.play(streamId); // "remote-videos"
      dispatch(setLocalStreamAction(localStream));
      dispatch(addStreamAction({ stream: localStream }));
      if (!isHost) {
        handeAttendeeLocalStreamInitiatedSuccess();
      }
    }, handleError);
  };

  const handeAttendeeLocalStreamInitiatedSuccess = () => {
    // updatedAttendeeMuteAllState();
    // disableAudio();
  };

  // const initializeAndPublishAttendeeLocalStream = ({ client }) => {
  //   // console.log("--- initializing local stream");
  //   const localStream = AgoraRTC.createStream({
  //     audio: true,
  //     video: false,
  //   });
  //   localStream.init(() => {
  //     client.publish(localStream, handleError);
  //     localStream.play("local-video");
  //     dispatch(setLocalStreamAction(localStream));
  //     enableAudio();
  //   }, handleError);
  // };

  const toggleVideo = () => {
    if (!state.localStream) return;
    if (state.enableVideo) {
      dispatch(enableVideoAction(false));
      state.localStream.disableVideo();
    } else {
      dispatch(enableVideoAction(true));
      state.localStream.enableVideo();
    }
  };

  const toggleExpandAttendeesList = () => {
    dispatch(
      setExpandAttendeesListAction({
        expandAttendeesList: !expandAttendeesList,
      })
    );
  };

  // const toggleAudio = () => {
  //   toggleAudioHost();
  //   if (isHost) {
  //   } else {
  //     toggleAudioAttendee();
  //   }
  // };

  // const toggleAudioAttendee = () => {
  //   if (state.enableAudio) {
  //     disableAudio();
  //   } else {
  //     enableAudioAttendee();
  //   }
  // };

  // const enableAudioAttendee = () => {
  //   if (hostMuteAll) return;
  //   if (state.localStream) {
  //     toggleAudioHost();
  //   } else {
  //     initializeAndPublishAttendeeLocalStream({ client: state.agoraClient });
  //   }
  // };

  const toggleAudio = () => {
    // console.log("--- toggle audio", state);
    if (!state.localStream) return;
    if (state.enableAudio) {
      disableAudio();
    } else {
      enableAudio();
    }
  };

  const disableAudio = () => {
    console.log("--- audio disable");
    dispatch(enableAudioAction(false));
    if (!state.localStream) return;
    console.log("--- audio disable 2");
    state.localStream.muteAudio();
  };

  const enableAudio = () => {
    console.log("--- audio enable");
    if (!state.localStream) return;
    console.log("--- audio enable 2");
    dispatch(enableAudioAction(true));
    state.localStream.unmuteAudio();
  };

  const handleError = (error) => {
    // console.log("--- handleError", error);
    showErrorNotification(CameraAndAudioPermissionNotGranted);
  };

  const endCallPressed = () => {
    history.replace(dashboardRoute.path);
  };

  const getAgoraToken = async (appointmentId) => {
    try {
      const res = await axios.post("/rtcToken", {
        userId: userData.id,
        channelName: appointmentId,
      });
      return res.data.response;
    } catch (e) {
      console.log("--- getAgoraToken error", e);
      return null;
    }
  };

  const practitionerName = `${state.practitionerDetails.name || ""} ${
    state.practitionerDetails.surname || ""
  }`.trim();

  const onFullScreenPressed = () => {
    const videoStreamID = isHost ? userData.id : state.remoteVideoStreamID;
    // const removeVideo = document.getElementById(`video${videoStreamID}`);
    const removeVideo = document.getElementById(
      "live-session-videos-container"
    );
    if (!removeVideo) return;
    if (removeVideo.requestFullscreen) {
      removeVideo.requestFullscreen();
    } else if (removeVideo.webkitRequestFullscreen) {
      removeVideo.webkitRequestFullscreen();
    } else if (removeVideo.msRequestFullscreen) {
      removeVideo.msRequestFullscreen();
    }
  };

  // console.log("--- ", session);

  const onSessionTimeFinished = () => {
    dispatch(setSessionTimeEndedAction({ timeEnded: true }));
  };

  const handleHideSessionEndedModal = () => {
    dispatch(setSessionTimeEndedAction({ timeEnded: false }));
  };

  const toggleExpanChatMessagesList = () => {
    dispatch(
      setExpandChatMessagesList({
        expanChatMessagesList: !expandChatMessagesList,
      })
    );
  };

  const handleHostToggleMuteAllClicked = () => {
    if (hostMuteAll) {
      handleHostUnmuteAll();
    } else {
      handleHostMuteAll();
    }
  };

  const handleHostMuteAll = async () => {
    // hostUnmuteAllAction;
    reduxDispatch(hostMuteAllAction());
    const success = await setSessionControls({
      sessionID,
      controlID: sessionHostMuteAllControlID,
    });
    if (!success) {
      console.log("--- handleHostMuteAll failed");
      // reduxDispatch(hostUnmuteAllAction());
    }
    reduxDispatch(setLoadingHostMuteAllAction({ loading: false }));
  };

  const handleHostUnmuteAll = async () => {
    // sessionHostUnmuteAllControlID
    // hostMuteAllAction
    reduxDispatch(hostUnmuteAllAction());
    const success = await setSessionControls({
      sessionID,
      controlID: sessionHostUnmuteAllControlID,
    });
    if (!success) {
      console.log("--- handleHostUnmuteAll failed");
      // reduxDispatch(hostMuteAllAction());
    }
    reduxDispatch(setLoadingHostMuteAllAction({ loading: false }));
  };

  const duration = session.duration || session.sessionDetails.duration;

  // console.log(
  //   "--- session",
  //   duration,
  //   moment.duration(duration).humanize(),
  //   session
  // );

  return (
    <CirclesBackground>
      <Prompt
        when={!state.sessionTimeEnded}
        message="Are you sure you want to leave this page ?"
      />
      <section className="live-session container-fluid">
        <div className="row">
          <div className="col-12 notifications-and-profile mt-3 mt-md-0 justify-content-between align-items-center">
            <BackButton className="mr-3" onClick={endCallPressed} />
            <h4 className="mb-0 mr-auto">
              {language.liveSessionOn} -{" "}
              {session.title || session.sessionDetails.title}
            </h4>
            <div style={{ display: "flex", paddingRight: "15px" }}>
              <NotificationsButton />
              <ProfileCircleButton />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 live-session-title mt-2 d-flex">
            <h6 className="mr-4">{language.presenter}</h6>
            <h6 className="text-primary mr-5">{practitionerName}</h6>
            <h6 className="mr-4">{language.duration}</h6>
            {/* <h6 className="text-secondary mr-5">1 {language.lang.hour}</h6> */}
            {duration && (
              <h6 className="text-primary mr-5">
                {typeof duration === "number"
                  ? moment.duration(duration, "minutes").humanize()
                  : moment.duration(duration).humanize()}
              </h6>
            )}
          </div>
        </div>
        <div className="row mt-2 h-85">
          <div className="col-md-8 h-100">
            <LiveSessionVideoCard
              isHost={isHost}
              audioEnabled={state.enableAudio}
              videoEnabled={state.enableVideo}
              onToggleVideoPressed={toggleVideo}
              onToggleAudioPressed={toggleAudio}
              onEndCallPressed={endCallPressed}
              remoteStreamIDs={state.remoteStreamIDs}
              practitionerDetails={state.practitionerDetails}
              practitionerName={practitionerName}
              onFullScreenPressed={onFullScreenPressed}
              remainingDuration={remainingDuration}
              onTimerFinished={onSessionTimeFinished}
              agoraStreams={state.streams}
            />
          </div>
          <div className="col-md-4 mt-3 mt-md-0 h-100 d-flex flex-column">
            <LiveSessionAudianceList
              toggleExpandAttendeesList={toggleExpandAttendeesList}
              expandAttendeesList={expandAttendeesList}
              hostMuteAll={hostMuteAll}
              isHost={isHost}
              handleHostToggleMuteAllClicked={handleHostToggleMuteAllClicked}
            />
            {state.showChatCard && (
              <LiveSessionChatCard
                expandChatMessagesList={expandChatMessagesList}
                toggleExpanChatMessagesList={toggleExpanChatMessagesList}
                channelID={sessionID}
                practitionerDetails={state.practitionerDetails}
              />
            )}
          </div>
        </div>
      </section>
      <SessionTimeEndedModal
        onYesClicked={endCallPressed}
        onHide={handleHideSessionEndedModal}
        show={state.sessionTimeEnded}
      />
    </CirclesBackground>
  );
};

export default LiveSession;
