import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Input, InputNumber, Row, Col, AutoComplete, Button, Spin } from "antd";
import { AiOutlineArrowDown, AiOutlineCamera, AiOutlinePlusCircle } from "react-icons/ai";
import TopMenu from "../../../CommonControls/TopMenu";
import MMRadioButton from "../../../MMRadioButton";
import AntEmiratesIDImageUpload from "../../../AntEmiratesIDImageUpload";
import IngredientsList from "./IngredientsList";
import CreateRecipeTotals from "./CreateRecipeTotals";
import CookingInstructionsList from "./CookingInstructionsList";
import useInitCreateRecipeForm from "../../../../customHooks/useInitCreateRecipeForm";
import Loading from "../../../Loading";
import AddIngredientDrawer from "./AddIngredientDrawer";
import AddCookingInstructionModal from "./AddCookingInstructionModal";
import EditCookingInstructionModal from "./EditCookingInstructionModal";
import { showErrorNotification, showSuccessNotification, uploadFile } from "../../../../utility/Helpers";
import { categoryMealPlan, fileTypeImage, gramsUnit, storageTypeImage, userTypeUser } from "../../../../utility/constants";
import { createRecipe, updateRecipe } from "../../../../services/CustomerNetworkService";
import { dashboardMealPlanRoute } from "../../../../routes";
import { FiChevronDown } from "react-icons/fi";
import useRecipeDetails from "../../../../customHooks/useRecipeDetails";

const mealTypeBreakfast = 1;
const mealTypeLunch = 2;
const mealTypeDinner = 3;

const CreateRecipe = () => {
  const history = useHistory();
  const editMode = history.location.pathname.endsWith("/edit");
  // const editRecipe = history.location?.state?.recipe;
  // console.log({ editModel, editRecipe });
  const userData = useSelector((state) => state.auth.userData);
  const language = useSelector((state) => state.language.lang);
  const languagePrefs = useSelector((state) => state.language);
  const [loadingRecipeDetails, recipeDetails] = useRecipeDetails();
  const [loadingInitData, categories, cuisines] = useInitCreateRecipeForm();
  const [createRecipeLoading, setCreateRecipeLoading] = useState(false);
  const [openAddIngredientDrawer, setOpenAddIngredientDrawer] = useState(false);
  const [addedIngredients, setAddedIngredients] = useState([]);
  const [isMainDish, setIsMainDish] = useState(true);
  const [isSingleServe, setIsSingleServe] = useState(false);
  const [mealType, setMealType] = useState(mealTypeBreakfast);
  const [keepsWell, setKeepsWell] = useState(false);
  const [cookingInstructions, setCookingInstructions] = useState([]);
  const [showAddInstructionModal, setShowAddInstructionModal] = useState(false);
  const [editCookingInstructionID, setEditCookingInstructionID] = useState(null);
  // const [selectedCategory, setSelectedCategory] = useState("");
  // const selectedImage = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [form] = Form.useForm();
  const currentLang = languagePrefs.general.preferredlanguage;

  const didEditForm = useCallback(() => {
    let editedForm = false;
    if (addedIngredients && addedIngredients.length > 0) {
      editedForm = true;
    } else if (cookingInstructions && cookingInstructions.length > 0) {
      editedForm = true;
    } else if (selectedImage) {
      editedForm = true;
    }
    return editedForm;
  }, [addedIngredients, cookingInstructions, selectedImage]);

  // const onBackButtonEvent = (e) => {
  //   console.log("--- onBackButtonEvent");
  //   e.preventDefault();
  //   alert("You message");
  // };

  useEffect(() => {
    if (!didEditForm()) return;
    window.addEventListener("beforeunload", beforeWindowUnloadListener);
    return () => {
      window.removeEventListener("beforeunload", beforeWindowUnloadListener);
    };
  }, [didEditForm]);

  useEffect(() => {
    // console.log({ editMode, recipeDetails });
    if (!editMode || !recipeDetails) return;
    // console.log(form.getFieldsValue());
    form.setFieldsValue({
      name: recipeDetails.rcp_title,
      description: recipeDetails.rcp_description,
      prepTimeMinutes: recipeDetails.rcp_preparation_time,
      cookTimeMinutes: recipeDetails.rcp_cooking_time,
      servingSize: recipeDetails.rcp_serving_size,
      category: recipeDetails.recipeCategory?.group_name,
      cuisine: recipeDetails.recipeCuisine?.name,
    });
    setIsMainDish(recipeDetails.recipeType?.id === 2);
    setMealType(recipeDetails.recipeMeal?.id);
    setKeepsWell(recipeDetails.is_keeps_well);
    setIsSingleServe(recipeDetails.is_single_serve);
    // setSelectedCategory(recipeDetails.recipeCategory?.group_name);
    setAddedIngredients(
      recipeDetails.recipeIngredients
        ?.filter((item) => item?.ingredientDetails?.id)
        ?.map((item) => {
          return {
            ingredient: item.ingredientDetails,
            quantity: item.unit_quantity,
            unit: {
              ingredient_id: item.ingredientDetails.id,
              is_base_unit: item.unit_name === gramsUnit,
              unit_name: item.unit_name,
              unit_quantity: item.unit_quantity,
              weight_in_grams: item.plain_unit_quantity,
            },
          };
        }) ?? []
    );
    setCookingInstructions(
      recipeDetails.rcp_cooking_instructions?.map((item) => {
        return {
          value: item.referenceVideoUrl ? item.referenceVideoUrl : item.instructionEn,
          isLink: item.referenceVideoUrl,
          id: new Date().getTime() + Math.floor(Math.random() * 100),
        };
      }) ?? []
    );
  }, [editMode, recipeDetails, form]);

  // useEffect(() => {
  //   // window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener("popstate", onBackButtonEvent);
  //   console.log("---- addEventListener popstate");
  //   return () => {
  //     console.log("---- removeEventListener popstate");
  //     window.removeEventListener("popstate", onBackButtonEvent);
  //   };
  // }, []);

  const beforeWindowUnloadListener = (e) => {
    e.preventDefault();
    return (e.returnValue = "You have unsaved changes");
  };

  const onImageChanged = (file) => {
    // console.log("--- onImageChanged", file);
    setSelectedImage(file);
  };

  const onImageRemoved = () => {
    // selectedImage.current = null;
    setSelectedImage(null);
  };

  const onDiscardPressed = () => {
    history.goBack();
  };
  const onReviewTranslationPressed = () => {
    history.goBack();
  };

  const discardSaveDiv = (
    <div className="d-flex justify-content-end">
      <Button onClick={onDiscardPressed} type="link">
        {language["discard"] ?? "discard"}
      </Button>
      <Button type="link" htmlType="submit">
        {language["save-recipe"] ?? "save-recipe"}
      </Button>
    </div>
  );
  const floatingDiv = (
    <div className="d-flex flex-column justify-content-start">
        {/* <div className="d-flex">
          <p className="p-2">Tap the button to review the translated content</p>
          <Button className="p-2" onClick={onReviewTranslationPressed} type="link">
            Review translated content
          </Button>
        </div>
        <div className="d-flex">
        <p className="p-2">The title, description and instructions will get auto translated and saved if no review done!</p>
        </div> */}
    </div>
  );

  const addIngredientButtonClicked = () => {
    setOpenAddIngredientDrawer(!openAddIngredientDrawer);
  };

  const handleAddedIngredients = (ingredients) => {
    setOpenAddIngredientDrawer(false);
    setAddedIngredients([...(ingredients ?? [])]);
  };

  const removeAddedIngredient = (addedIngredient) => {
    // console.log("--- removeAddedIngredient", addedIngredient);
    setAddedIngredients((prevState) => {
      return prevState.filter((item) => item.ingredient.id !== addedIngredient.ingredient.id);
    });
  };

  const addCookingClicked = () => {
    setShowAddInstructionModal(true);
  };

  const closeCookingInstructionClick = () => {
    setShowAddInstructionModal(false);
  };

  const onCookingInstructionAdded = (text) => {
    setShowAddInstructionModal(false);
    setCookingInstructions((prevState) => {
      return [...prevState, { value: text, isLink: false, id: new Date().getTime() }];
    });
  };

  const onCookingLinkAdded = (link) => {
    setShowAddInstructionModal(false);
    setCookingInstructions((prevState) => {
      return [...prevState, { value: link, isLink: true, id: new Date().getTime() }];
    });
  };

  const handleEditCookingInstruction = (id) => {
    setEditCookingInstructionID(id);
  };

  const handleEditInstructionText = (id, text) => {
    setCookingInstructions((prevState) => {
      return prevState.map((item) => {
        if (item.id === id) {
          return { value: text, isLink: false, id: id };
        }
        return item;
      });
    });
    setEditCookingInstructionID(null);
  };

  const handleEditInstructionLink = (id, link) => {
    setCookingInstructions((prevState) => {
      return prevState.map((item) => {
        if (item.id === id) {
          return { value: link, isLink: true, id: id };
        }
        return item;
      });
    });
    setEditCookingInstructionID(null);
  };

  const uploadRecipeImage = async () => {
    const recipeImageURL = await uploadFile({
      storageType: storageTypeImage,
      category: categoryMealPlan,
      userType: userTypeUser,
      fileType: fileTypeImage,
      userId: userData.id,
      "multi-files": selectedImage,
    });
    return recipeImageURL;
  };

  const getRecipeCuisineIDFromSelectedCuisineName = ({ cuisineName }) => {
    return cuisines.find((item) => {
      let name;
      if (currentLang === "ar") {
        name = item.name_ar;
      } else if (currentLang === "ku") {
        name = item.name_ku;
      } else {
        name = item.name;
      }
      return name === cuisineName;
    }).id;
  };

  const handleEditRecipe = async (values) => {
    setCreateRecipeLoading(true);
    let recipeImageURL;
    if (selectedImage) {
      recipeImageURL = await uploadRecipeImage();
    } else {
      recipeImageURL = recipeDetails.rcp_image_url;
    }
    if (!recipeImageURL) {
      setCreateRecipeLoading(false);
      return showErrorNotification(language["error"] ?? "error");
    }
    const calculatedTotals = getRecipeCalculatedTotals();
    const actualTotals = getRecipeActualTotals({ calculatedTotals, servingSize: values.servingSize });
    const recipeID = await updateRecipe({
      recipeID: recipeDetails.id,
      title: values.name,
      description: values.description,
      recipeCategory: getRecipeCategoryIDFromSelectedName({ categoryName: values.category }),
      cookingTime: values.cookTimeMinutes,
      recipeCuisine: getRecipeCuisineIDFromSelectedCuisineName({ cuisineName: values.cuisine }),
      preparationTime: values.prepTimeMinutes,
      seringSize: values.servingSize,
      recipeImageUrl: recipeImageURL,
      recipeType: isMainDish ? 2 : 1,
      recipeMeal: mealType,
      isKeepsWell: keepsWell,
      isSingleServe: isSingleServe,
      isLeftOver: keepsWell,
      recipeCalculatedTotals: calculatedTotals,
      recipeActualTotals: actualTotals,
      ingredients: mapAddedIngredientsToRecipeIngredients(),
      cookingInstructions: mapAddedInstructionsToRecipeInstructions(),
    });
    if (recipeID) {
      showSuccessNotification(language["recipe-updated-success"] ?? "recipe-updated-success");
      history.replace(`${dashboardMealPlanRoute.path}/recipe/${recipeDetails.rcp_url_name}`);
    } else {
      showErrorNotification();
    }
    setCreateRecipeLoading(false);
  };

  const mapAddedInstructionsToRecipeInstructions = () => {
    return cookingInstructions.map((item) => {
      if (item.isLink) {
        return { referenceVideoUrl: item.value };
      }
      return {
        instructionEn: item.value,
        instructionAr: item.value,
      };
    });
  };

  const getRecipeCalculatedTotals = () => {
    const calculatedTotals = {};
    for (const addedIng of addedIngredients) {
      const weightInGramsForOneUnit = (addedIng.unit?.weight_in_grams ?? 0) / (addedIng?.unit?.unit_quantity ?? 1);
      const weightForSelectedServing = addedIng.ingredient.default_serving * addedIng.ingredient.grams_per_serving;
      const weightInGramsForAddedQuantity = weightInGramsForOneUnit * addedIng.quantity;
      const ingNutritionInfo = addedIng.ingredient.ingredientNutritionInfo ?? {};
      delete ingNutritionInfo.ingredient_id;
      for (const key of Object.keys(ingNutritionInfo)) {
        const prevValue = calculatedTotals[key] ?? 0;
        calculatedTotals[key] = prevValue + weightInGramsForAddedQuantity * (ingNutritionInfo[key] / weightForSelectedServing);
      }
      // debugger;
    }
    return calculatedTotals;
  };

  const getRecipeActualTotals = ({ calculatedTotals, servingSize }) => {
    const actualTotals = {};
    for (const key of Object.keys(calculatedTotals)) {
      const prevValue = actualTotals[key] ?? 0;
      actualTotals[key] = prevValue + calculatedTotals[key] / servingSize;
    }
    return actualTotals;
  };

  const getRecipeCategoryIDFromSelectedName = ({ categoryName }) => {
    return categories.find((item) => {
      let name;
      if (currentLang === "ar") {
        name = item.group_name_ar;
      } else if (currentLang === "ku") {
        name = item.group_name_ku;
      } else {
        name = item.group_name;
      }
      return name === categoryName;
    }).group_id;
  };

  const mapAddedIngredientsToRecipeIngredients = () => {
    return addedIngredients.map((item) => {
      const weightInGramsForOneUnit = (item.unit?.weight_in_grams ?? 0) / (item.unit?.unit_quantity ?? 1) ?? 0;
      return {
        ingredientId: item.ingredient.unique_id,
        unitName: item.unit?.unit_name ?? "",
        unitQuantity: item.quantity,
        plainUnitName: "grams",
        plainUnitQuantity: weightInGramsForOneUnit * item.quantity,
      };
    });
  };

  const handleFormFinishedValidation = async (values) => {
    if (!selectedImage && !editMode) {
      return showErrorNotification(language["please-select-image"] ?? "please-select-image");
    } else if (!addedIngredients || addedIngredients.length === 0) {
      return showErrorNotification(language["please-add-one-ingredient-at-least"] ?? "please-add-one-ingredient-at-least");
    }
    if (editMode && recipeDetails) return handleEditRecipe(values);
    setCreateRecipeLoading(true);
    const recipeImageURL = await uploadRecipeImage();
    if (!recipeImageURL) {
      setCreateRecipeLoading(false);
      return showErrorNotification(language["error"] ?? "error");
    }
    const calculatedTotals = getRecipeCalculatedTotals();
    const actualTotals = getRecipeActualTotals({ calculatedTotals, servingSize: values.servingSize });
    // const calculatedTotals = {};
    // for (const addedIng of addedIngredients) {
    //   const weightInGramsForOneUnit = (addedIng.unit?.weight_in_grams ?? 0) / (addedIng?.unit?.unit_quantity ?? 1);
    //   const weightForSelectedServing = addedIng.ingredient.default_serving * addedIng.ingredient.grams_per_serving;
    //   const weightInGramsForAddedQuantity = weightInGramsForOneUnit * addedIng.quantity;
    //   const ingNutritionInfo = addedIng.ingredient.ingredientNutritionInfo ?? {};
    //   delete ingNutritionInfo.ingredient_id;
    //   for (const key of Object.keys(ingNutritionInfo)) {
    //     const prevValue = calculatedTotals[key] ?? 0;
    //     calculatedTotals[key] = prevValue + weightInGramsForAddedQuantity * (ingNutritionInfo[key] / weightForSelectedServing);
    //   }
    //   // debugger;
    // }
    // const actualTotals = {};
    // for (const key of Object.keys(calculatedTotals)) {
    //   const prevValue = actualTotals[key] ?? 0;
    //   actualTotals[key] = prevValue + calculatedTotals[key] / values.servingSize;
    // }
    // debugger;
    const recipeID = await createRecipe({
      title: values.name,
      description: values.description,
      recipeCategory: getRecipeCategoryIDFromSelectedName({ categoryName: values.category }),
      cookingTime: values.cookTimeMinutes,
      recipeCuisine: getRecipeCuisineIDFromSelectedCuisineName({ cuisineName: values.cuisine }),
      preparationTime: values.prepTimeMinutes,
      seringSize: values.servingSize,
      recipeImageUrl: recipeImageURL,
      recipeType: isMainDish ? 2 : 1,
      recipeMeal: mealType,
      isKeepsWell: keepsWell,
      isSingleServe: isSingleServe,
      isLeftOver: keepsWell,
      recipeCalculatedTotals: calculatedTotals,
      recipeActualTotals: actualTotals,
      ingredients: mapAddedIngredientsToRecipeIngredients(),
      cookingInstructions: mapAddedInstructionsToRecipeInstructions(),
    });
    if (recipeID) {
      showSuccessNotification(language["recipe-created-success"] ?? "recipe-created-success");
      history.replace(dashboardMealPlanRoute.path);
    } else {
      showErrorNotification();
    }
    setCreateRecipeLoading(false);
  };

  const handleRemoveCookingInstruction = (id) => {
    setCookingInstructions((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });
    setEditCookingInstructionID(null);
  };

  // console.log({ recipeDetails });

  let content = (
    <Form
      form={form}
      onFinish={handleFormFinishedValidation}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 26 }}
      labelAlign="left"
      colon={false}
      size="large"
    >
      {floatingDiv}
      {discardSaveDiv}
      <Row gutter={24}>
        <Col span={24} md={12}>
          <Form.Item
            className="required-mark"
            label={language["recipe-title"] ?? "recipe-title"}
            name="name"
            rules={[
              {
                required: true,
                message: language["please-enter-recipe-name"] ?? "please-enter-recipe-name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={language["recipe-description"] ?? "recipe-description"}
            name="description"
            rules={[
              {
                required: true,
                message: language["please-enter-recipe-description"] ?? "please-enter-recipe-description",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className="form-item-suffix">
            <Form.Item
              className="form-item-suffix"
              label={language["prep-time"] ?? "prep-time"}
              name="prepTimeMinutes"
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 25 }}
              rules={[
                {
                  required: true,
                  type: "number",
                  message: language["please-enter-recipe-prep-time"] ?? "please-enter-recipe-prep-time",
                },
                // () => ({
                //   validator(field, value) {
                //     console.log("--- validate prep time", value, field);
                //     if (value) {
                //       return Promise.resolve();
                //     }
                //     return Promise.reject();
                //   },
                // }),
              ]}
            >
              <InputNumber />
            </Form.Item>
            <p className="input-trailing">{language["minutes"] ?? "minutes"}</p>
          </div>
          <div className="form-item-suffix">
            <Form.Item
              className="form-item-suffix"
              label={language["cook-time"] ?? "cook-time"}
              name="cookTimeMinutes"
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 25 }}
              rules={[
                {
                  required: true,
                  message: language["please-enter-recipe-cooking-time"] ?? "please-enter-recipe-cooking-time",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <p className="input-trailing">{language["minutes"] ?? "minutes"}</p>
          </div>
          <div className="form-item-suffix">
            <Form.Item
              className="form-item-suffix"
              label={language["serving-size"] ?? "serving-size"}
              name="servingSize"
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 25 }}
              rules={[
                () => ({
                  validator(field, value) {
                    if (!value) {
                      return Promise.reject(language["please-enter-recipe-serving-size"] ?? "please-enter-recipe-serving-size");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber />
            </Form.Item>
            <p className="input-trailing">{language["serving"] ?? "serving"}</p>
          </div>
          <Form.Item
            label={language["category"] ?? "category"}
            name="category"
            rules={[
              // {
              //   required: true,
              //   message: language["please-select-category"] ?? "please-select-category",
              // },
              () => ({
                validator(field, value) {
                  if (!value?.trim()) return Promise.reject(language["please-select-category"] ?? "please-select-category");
                  if (
                    !categories.some((item) => {
                      let name;
                      if (currentLang === "ar") {
                        name = item.group_name_ar;
                      } else if (currentLang === "ku") {
                        name = item.group_name_ku;
                      } else {
                        name = item.group_name;
                      }
                      return name?.trim() === value;
                    })
                  ) {
                    return Promise.reject(language["please-select-valid-category"] ?? "please-select-valid-category");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <AutoComplete
              suffixIcon={<AiOutlineArrowDown />}
              // value={selectedCategory}
              // onChange={(value) => {
              //   setSelectedCategory(value);
              // }}
              options={(categories ?? []).map((item) => {
                let name;
                if (currentLang === "ar") {
                  name = item.group_name_ar;
                } else if (currentLang === "ku") {
                  name = item.group_name_ku;
                } else {
                  name = item.group_name;
                }
                return {
                  value: name, // item.id,
                  label: name,
                };
              })}
              filterOption={(inputValue, option) => {
                return option.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase());
              }}
            >
              <Input suffix={<FiChevronDown />} />
            </AutoComplete>
          </Form.Item>
          <Form.Item
            label={language["cuisine"] ?? "cuisine"}
            name="cuisine"
            rules={[
              // {
              //   required: true,
              //   message: language["please-select-cuisine"] ?? "please-select-cuisine",
              // },
              () => ({
                validator(field, value) {
                  if (!value?.trim()) return Promise.reject(language["please-select-cuisine"] ?? "please-select-cuisine");
                  if (
                    !cuisines.some((item) => {
                      let name;
                      if (currentLang === "ar") {
                        name = item.name_ar;
                      } else if (currentLang === "ku") {
                        name = item.name_ku;
                      } else {
                        name = item.name;
                      }
                      return name?.trim() === value;
                    })
                  ) {
                    return Promise.reject(language["please-select-valid-cuisine"] ?? "please-select-valid-cuisine");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <AutoComplete
              options={(cuisines ?? []).map((item) => {
                let name;
                if (currentLang === "ar") {
                  name = item.name_ar;
                } else if (currentLang === "ku") {
                  name = item.name_ku;
                } else {
                  name = item.name;
                }
                return {
                  value: name, // item.id,
                  label: name,
                };
              })}
              filterOption={(inputValue, option) => {
                return option.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase());
              }}
            >
              <Input suffix={<FiChevronDown />} />
            </AutoComplete>
          </Form.Item>
          <Form.Item label={language["dish-type"] ?? "dish-type"}>
            <MMRadioButton
              label={language["side-dish"] ?? "side-dish"}
              selected={!isMainDish}
              onClick={() => setIsMainDish(false)}
            />
            <MMRadioButton
              label={language["main-dish"] ?? "main-dish"}
              selected={isMainDish}
              onClick={() => setIsMainDish(true)}
            />
          </Form.Item>
          <Form.Item label={language["meal"] ?? "meal"}>
            <MMRadioButton
              label={language["breakfast"] ?? "breakfast"}
              selected={mealType === mealTypeBreakfast}
              onClick={() => setMealType(mealTypeBreakfast)}
            />
            <MMRadioButton
              label={language["lunch"] ?? "lunch"}
              selected={mealType === mealTypeLunch}
              onClick={() => setMealType(mealTypeLunch)}
            />
            <MMRadioButton
              label={language["dinner"] ?? "dinner"}
              selected={mealType === mealTypeDinner}
              onClick={() => setMealType(mealTypeDinner)}
            />
          </Form.Item>
          <Form.Item label={language["keeps-well"] ?? "keeps-well"}>
            <MMRadioButton
              label={language["does-it-make-good-leftover"] ?? "does-it-make-good-leftover"}
              selected={keepsWell}
              onClick={() => setKeepsWell(!keepsWell)}
            />
          </Form.Item>
          <Form.Item label={language["single-serve"] ?? "single-serve"}>
            <MMRadioButton
              label={language["can-be-one-serve"] ?? "can-be-one-serve"}
              selected={isSingleServe}
              onClick={() => setIsSingleServe(!isSingleServe)}
            />
          </Form.Item>
          <Form.Item
            label={language["add-image"] ?? "add-image"}
            rules={[
              {
                required: true,
                message: language["please-select-image"] ?? "please-select-image",
              },
            ]}
          >
            <AntEmiratesIDImageUpload
              fileList={[]}
              initialImageURL={recipeDetails?.rcp_image_url}
              onChange={onImageChanged}
              onRemove={onImageRemoved}
              emptyPlaceholderChild={
                <div className="recipe-image-placeholder d-flex justify-content-center align-items-center w-100 h-100">
                  <AiOutlineCamera />
                </div>
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Button size="large" block className="block-btn add-ingredient-btn mb-3" onClick={addIngredientButtonClicked}>
            <div className="d-flex justify-content-center align-items-center">
              <div className="icon text-primary">
                <AiOutlinePlusCircle />
              </div>
              <div className="mx-1"></div>
              {language["add-ingredient"] ?? "add-ingredient"}
            </div>
          </Button>
          <div className="mb-3">
            <IngredientsList addedIngredients={addedIngredients} onRemovePressed={removeAddedIngredient} />
          </div>
          <div className="mb-3">
            <CreateRecipeTotals addedIngredients={addedIngredients} />
          </div>
          <Button onClick={addCookingClicked} size="large" block className="block-btn add-cooking-instructions-btn mb-3">
            <div className="d-flex justify-content-center align-items-center">
              <div className="icon text-primary">
                <AiOutlinePlusCircle />
              </div>
              <div className="mx-1"></div>
              {language["add-cooking-instructions"] ?? "add-cooking-instructions"}
            </div>
          </Button>
          <CookingInstructionsList onItemEditClicked={handleEditCookingInstruction} cookingInstructions={cookingInstructions} />
        </Col>
      </Row>
      {discardSaveDiv}
    </Form>
  );

  if (loadingInitData || createRecipeLoading || loadingRecipeDetails) {
    content = (
      <Spin
        indicator={
          <div className="d-flex justify-content-center align-items-center">
            <Loading height={100} width={100} />
          </div>
        }
      >
        {content}
      </Spin>
    );
  }

  // console.log({ addedIngredients });

  return (
    <div className="create-recipe">
      <EditCookingInstructionModal
        id={editCookingInstructionID}
        show={editCookingInstructionID}
        onClose={() => setEditCookingInstructionID(null)}
        onInstructionEdited={handleEditInstructionText}
        onLinkEdited={handleEditInstructionLink}
        cookingInstructions={cookingInstructions}
        onRemoveClicked={handleRemoveCookingInstruction}
      />
      <AddCookingInstructionModal
        onInstructionAdded={onCookingInstructionAdded}
        onLinkAdded={onCookingLinkAdded}
        onClose={closeCookingInstructionClick}
        show={showAddInstructionModal}
      />
      <AddIngredientDrawer
        open={openAddIngredientDrawer}
        initialAddedIngs={addedIngredients}
        onClose={() => {
          setOpenAddIngredientDrawer(false);
        }}
        onAddPressed={handleAddedIngredients}
      />
      <section className="row d-flex justify-content-between w-100 top-menu-pad">
        <TopMenu
          shouldHideBackButton={false}
          shouldHideProfileButton={false}
          shouldHideNotificationButton={false}
          shouldHideLogoButton={false}
          pageTitle={language["createRecipe"]}
          hideCustomclass={true}
        />
      </section>
      <div className="create-recipe-card">{content}</div>
    </div>
  );
};

export default CreateRecipe;
