import {useSelector } from "react-redux";
import TopMenu from "./CommonControls/TopMenu";
const ContactUs = () => {
  const language = useSelector((state) => state.language);
  return (
    <section className="container-fluid px-4 mb-4">
      <div className="row">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.lang.getInTouch}
      />
      </div>
      <div className="row px-3 mobile-px-none">
        <div className="footer-container ml-3">
          <div className="row">
            <div className="col-md-12 wow animated fadeInLeft" data-wow-delay=".2s">
              <h1 className="section-title">{language.lang.getInTouch}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 col-sm-12 col-lg-7 mt-3 wow animated fadeInLeft" data-wow-delay=".2s">
              <p>{language.lang.pleaseDontHesitateToContactUs}</p>
              <div className="find-widget">
                Company: <a href="https://www.doctorsae.com">Doctors Group LLC</a>
              </div>
              <div className="find-widget">
                Phone: <a href="https://www.doctorsae.com">+971 50 306 2536</a>
              </div>
              <div className="find-widget">
                Website: <a href="https://www.doctorsae.com">www.doctorsae.com</a>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="contact-box center-version">
                    <div className="location-bold mt-2"><strong>Abudhabi</strong></div>
                    <address className="m-t-md mt-2">
                      <span>Hub71, 14th floor</span>
                      <br />
                      Al Khatem Tower, Abu Dhabi Global Market,
                      <br />
                      Al Maryah Island, Abu Dhabi,UAE.
                      <br />
                      <abbr title="Phone"><i className="fas fa-phone mr-2"></i></abbr> +971 50 306 2536
                    </address>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-box center-version">
                    <div className="location-bold mt-2"><strong>Dubai</strong></div>
                    <address className="m-t-md mt-2">
                      <span>Office Number-1501</span>
                      <br />
                      The Galleria Investment Trade Center 1,<br />
                      Dubai,UAE.
                      <br />
                      <abbr title="Phone"><i className="fas fa-phone mr-2"></i></abbr> +971 50 306 2536
                    </address>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
              <div className="col-md-6">
                  <div className="contact-box center-version">
                    <div className="location-bold mt-2"><strong>Egypt</strong></div>
                    <address className="m-t-md mt-2">
                      <span>Giza, 6 October City,</span>
                      <br/>Apartment No-1108,Floor 8,
                      <br/>Second District,Fourth Neighbourhood,Egypt.
                      <br/>
                      <abbr title="Phone"><i className="fas fa-phone mr-2"></i></abbr>+20 121 1244891
                      <br/>
                      <span>©2022 Easy Health. All rights reserved. <br/> Trade License: 20651</span>
                    </address>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-sm-12 col-lg-4 wow animated fadeInRight" data-wow-delay=".2s">
              <form className="shake" role="form" method="post" id="contactForm" name="contact-form" data-toggle="validator">
                <div className="form-group label-floating">
                  <label className="control-label" htmlFor="name">
                  {language.lang.name}
                  </label>
                  <input className="form-control neumorphic-input w-100" id="name" type="text" name="name" required data-error="Please enter your name" />
                  <div className="help-block with-errors"></div>
                </div>

                <div className="form-group label-floating">
                  <label className="control-label" htmlFor="email">
                  {language.lang.email}
                  </label>
                  <input className="form-control neumorphic-input w-100" id="email" type="email" name="email" required data-error="Please enter your Email" />
                  <div className="help-block with-errors"></div>
                </div>

                <div className="form-group label-floating">
                  <label className="control-label">{language.lang.subject}</label>
                  <input
                    className="form-control neumorphic-input w-100s"
                    id="msg_subject"
                    type="text"
                    name="subject"
                    required
                    data-error="Please enter your message subject"
                  />
                  <div className="help-block with-errors"></div>
                </div>

                <div className="form-group label-floating">
                  <label htmlFor="message" className="control-label">
                  {language.lang.message}
                  </label>
                  <textarea className="form-control neumorphic-input w-100" rows="3" id="message" name="message" required data-error="Write your message"></textarea>
                  <div className="help-block with-errors"></div>
                </div>

                <div className="form-submit mt-5">
                  <button className="btn btn-primary" id="form-submit" style={{
                    width: "100%",
                    borderRadius: "50px",
                  }}>
                    <i className="material-icons mdi mdi-message-outline"></i> {language.lang.emailUs}
                  </button>
                  <div id="msgSubmit" className="h3 text-center hidden"></div>
                  <div className="clearfix"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
