// import RecipeTotalsPieChart from "../Recipe/CreateRecipe/RecipeTotalsPieChart"

// const Recipeswithmealselection = ({ data }) => {

//     // console.log(data[0].calories)
//     const dinnerCalories=data[0].calories
//     const lunchCalories=data[1].calories
//     const breakfastCalories=data[2].calories
//     // const meal=meals.response
//     return (
//         <div className="mealrecipescards">
//             <div className="mealrecipetittle">
//                 <h5>Today's Meal Plan</h5>
//                 <button>
//                     <strong>Regenerate</strong> <i className="fa fa-calculator" aria-hidden="true"></i>
//                 </button>
//             </div>
//             <div className="mealrecipetittlechart">
//                 <div >
//                     <RecipeTotalsPieChart
//                         showPercentageOnChart={false}
//                         carbs={dinnerCalories}
//                         fats={lunchCalories}
//                         proteins={breakfastCalories}
//                     />
//                 </div>
//                 <label>{`${dinnerCalories+lunchCalories+breakfastCalories}`} Calories</label>
//             </div>
//             {/* <div className="mealrecipecard">
//                 {meals.map((meal, index) => (
//                     <div className="mealrecipecard" key={index}>
//                         <div className="card-body">
//                             <div>
//                                 <h5 className="card-title">{meal.name}</h5>
//                                 <h6 className="card-subtitle mb-2 text-muted">{`${meal.calories.toFixed(2)} Calories`}</h6>
//                             </div>
//                         </div>

//                         <div className="mealrecipeitems">
//                             <img src={meal.recipes[0].rcp_image_url}alt="logo" />
//                             <div>
//                                 {meal.recipes && meal.recipes.length > 0 && (
//                                     <p>{meal.recipes[0].rcp_title}</p>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 ))}
//             </div> */}
//             <div className="mealrecipecard">
//         {data.map((meal, index) => (
//           <div className="mealrecipecard" key={index}>
//             <div className="card-body">
//               <div>
//                 <h5 className="card-title">{meal.name}</h5>
//                 <h6 className="card-subtitle mb-2 text-muted">{`${meal.calories.toFixed(2)} Calories`}</h6>
//               </div>
//             </div>

//             <div className="mealrecipeitems">
//               <img src={meal.recipes[0].rcp_image_url} alt="logo" />
//               <div>
//                 {meal.recipes && meal.recipes.length > 0 && (
//                   <p>{meal.recipes[0].rcp_title}</p>
//                 )}
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//         </div>
//     )
// }

// export default Recipeswithmealselection

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import RecipeTotalsPieChart from "../Recipe/CreateRecipe/RecipeTotalsPieChart";
import { FaSync } from 'react-icons/fa';
import { dashboardMealPlanRoute } from '../../../routes';
import RecipeTotalsprogessbar from '../Recipe/CreateRecipe/RecipeTotalsprogessbar';
import axios from "../../../services/Axios";

// import DatePickerMeal from './DatePickerMeal';
// Datemeal Picker
import { format, addDays, subDays, parseISO } from 'date-fns';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { getactiveMealPlansbyuserId } from '../../../services/MealPlanNetworkService';

const Recipeswithmealselection = ({ recipedata, handleAPIRequest, startDate, endDate,tab,mealplanId,diet_type}) => {
    const history = useHistory();
    const userId=useSelector((state)=>state.auth.userData.id)
    const [selectedRecipes, setSelectedRecipes] = useState({});
    const [selectedRecipeIds, setSelectedRecipeIds] = useState([]);
    const [totalCalories, setTotalCalories] = useState(0);
    const [totalCarbs, setTotalCarbs] = useState(0);
    const [totalfats, setTotalFats] = useState(0);
    const [totalProteins, setTotalProteins] = useState(0);
    const [recipeIndex, setRecipeIndex] = useState(0);
    const [data, setData] = useState(recipedata)
    const [selectedDate,setSelectedDate]=useState()
    const [usermealplanId,setMealPlanId]=useState(mealplanId)

    useEffect(() => {
        // Set initial recipes from the API data
        const initialSelectedRecipes = {};
        let initialTotalCalories = 0;
        let initialTotalCarbs = 0;
        let initialTotalFats = 0;
        let initialTotalProteins = 0;

        data.forEach((meal) => {
            const availableRecipes = meal.recipescombo || [];
            const initialRecipe = availableRecipes.length > 0 ? availableRecipes[0] : null;
            initialSelectedRecipes[meal.name] = initialRecipe;

            // Add the calories of the first recipe to the initialTotalCalories
            if (initialRecipe) {
                initialTotalCalories += Math.round(initialRecipe.calories) || 0;
                initialTotalCarbs += Math.round(initialRecipe.carbs) || 0;
                initialTotalFats += Math.round(initialRecipe.fats) || 0;
                initialTotalProteins += Math.round(initialRecipe.proteins) || 0;
            }
        });

        setSelectedRecipes(initialSelectedRecipes);
        setTotalCalories(initialTotalCalories);
        setTotalCarbs(initialTotalCarbs);
        setTotalFats(initialTotalFats);
        setTotalProteins(initialTotalProteins)
    }, [data]);

    const handleMealPlanUpdate = (mealPlanData,date,mealplanId) => {
        // Update state with the fetched meal plan data
        setData(mealPlanData);
        setSelectedDate(date)
        setMealPlanId (mealplanId)
    };

//     const handleRegenerateMeal = async () => {
//         const regenerateids = data.flatMap((result) =>
//             result.recipescombo.flatMap((recipeCombo) =>
//                 recipeCombo.recipes.map((recipe) =>
//                 recipe.id
//                 )
//             )
//         )
//         console.log(regenerateids)
//         setRegenerativeIds(regenerateids)
//          // Call handleRefresh with the appropriate parameters
// // await handleRefresh()
//         // handleAPIRequest(regenerateids)

//     }

    const handleRefresh = async(mealid) => {
        // console.log("selectedDate is",generativeIds)

        let dateTosend;
    if (tab === 'day') {
        dateTosend = format(new Date(), 'yyyy-MM-dd');
    }else if (tab === 'week'){
        dateTosend = format(selectedDate, 'yyyy-MM-dd');
    } 
    //    New code after login 
    const InputData={
        userId: userId,
        mealplanId: usermealplanId,
        regenerate_meal_type: mealid,
        date:dateTosend,
        diet_type:diet_type,
        restricted_ids: [

        ]
    }
    console.log("InputData is",InputData)
    try {
        const response = await axios.post("/restrictedidsandRegenerateids", InputData);
        if (response.status === 200) {
            // const result = response.data;
          let date=parseISO(dateTosend)
          const activeMealPlans = await getactiveMealPlansbyuserId(userId, date);
          setData(activeMealPlans.results)
        //   const mealplanId=activeMealPlans.mealsplanid
        } else if (response.status !== 200) {
            console.log("something went wrong")
        }
    } catch (error) {
        console.error("Error during API call:", error);
    }

        // const recipeIds = selectedRecipes[mealName].recipes.map(recipe => recipe.id);
        // //    handleAPIRequest(recipeIds);
        // const updatedSelectedRecipeIds = [
        //     ...selectedRecipeIds,
        //     ...recipeIds
        // ];
        // setSelectedRecipeIds(updatedSelectedRecipeIds);
        // handleAPIRequest(updatedSelectedRecipeIds)
    };

    //handling regenerate meal
 


    const handleRecipeClick = (recipeTitle) => {
        history.push(`${dashboardMealPlanRoute.path}/recipe/${recipeTitle}`, { recipeTitle });
    }
   
    return (
        <div className="mealrecipescards">
            <div className="mealrecipetittle">
                <h5>Today's Meal Plan</h5>
                {/* <button
                    onClick={() => handleRegenerateMeal()}
                >
                    <strong>Regenerate</strong> <FaSync />
                </button> */}
            </div>
            
            {tab === 'week' && (
            <DatePickerMeal data={{ startDate, endDate }} handleMealPlanUpdate={handleMealPlanUpdate} />
        )}
            <div
                className="mealrecipetittlechart"
            >
                {/* <div>
                    <RecipeTotalsPieChart
                        showPercentageOnChart={false}
                        carbs={30}
                        fats={40}
                        proteins={30}
                    />
                </div> */}
                <div>

                    <RecipeTotalsprogessbar
                        calories={totalCalories}
                        carbs={totalCarbs}
                        fats={totalfats}
                        proteins={totalProteins}
                    />
                </div>

                {/* <label>{parseInt(`${totalCalories}`)} Calories</label> */}
            </div>

            {/* <div style={{width:"0px",height:"100px"}}>
                    <RecipeTotalsprogessbar
                        calories={100}
                    />
                </div> */}
            <div className="mealrecipecard">
                {data.map((meal, index) => (
                    <div className="mealrecipecard" key={index}>
                        <div className="card-body cardheading">
                            <div >
                                <h5 className="card-title">{meal.name}</h5>
                                <h6 className="card-subtitle mb-2 text-muted">{`${meal.calories.toFixed(2)} Calories`}</h6>
                            </div>
                            <button style={{ border: "none", background: "none" }} onClick={() => handleRefresh(meal.id)}><FaSync /></button>
                        </div>

                        <div className='mealrecipeitems'>
                            {selectedRecipes[meal.name] && (
                                <>
                                    {selectedRecipes[meal.name].recipes.map((recipe, index) => (
                                        <div key={index}>
                                            <img src={recipe.rcp_image_url} alt="logo" />
                                            <div>
                                                <p onClick={() => handleRecipeClick(recipe.rcp_url_name)}>{recipe.rcp_title}</p>
                                                <p>{recipe.rcp_serving_size} Serving</p>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

};

const DatePickerMeal = ({ data, handleMealPlanUpdate }) => {
    const userId = useSelector((state) => state.auth.userData.id);
    const startDate = parseISO(data.startDate);
    const [selectedDate, setSelectedDate] = useState(startDate);

    const handlePrevDay = () => {
        const newDate = subDays(selectedDate, 1);
        setSelectedDate(newDate);
        updateMealPlans(newDate);
    };

    const handleNextDay = () => {
        const newDate = addDays(selectedDate, 1);
        setSelectedDate(newDate);
        updateMealPlans(newDate);
    };

    const updateMealPlans = async (date) => {
        const activeMealPlans = await getactiveMealPlansbyuserId(userId, date);
        const mealplanId=activeMealPlans.mealsplanid
        handleMealPlanUpdate(activeMealPlans.results, date,mealplanId);
    };

    useEffect(() => {
        setSelectedDate(parseISO(data.startDate));
        updateMealPlans(selectedDate);
    }, [data.startDate, userId]); 


    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: 'center' }}>
            <button style={{ border: "none", background: "none" }} onClick={handlePrevDay}><FaAngleLeft /></button>
            <span>{format(selectedDate, 'dd-MM-yyyy')}</span>
            <button style={{ border: "none", background: "none" }} onClick={handleNextDay}><FaAngleRight /></button>
        </div>
    );
};

export default Recipeswithmealselection;
