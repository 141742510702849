import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "antd";
import { BsFilterRight } from "react-icons/bs";
import patientActions from "../redux/patient/actions";
import MyCalendarCustomer from "./MyCalendarCustomer";
import CustomerCalendarAppointmentCard from "./CustomerCalendarAppointmentCard";
import CustomerCalendarSessionCard from "./CustomerCalendarSessionCard";
import { setSelectedSlotAndViewSlotAction } from "../redux/patient/actionCreators";
import TopMenu from "./CommonControls/TopMenu";

const CustomerCalendar = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const calendarState = useSelector((state) => state.patient.calendar);
  const viewSlot = calendarState.viewSlot;
  const selectedSlot = calendarState.selectedSlot;
  const calendarEvents = calendarState.events;
  const isAppointment = selectedSlot.calendarItemType === "appointment";

  useEffect(() => {
    if (!selectedSlot) return;
    // debugger;
    const newEvent = calendarEvents.find((item) => item.id === selectedSlot.id);
    // debugger;
    if (!newEvent) return;
    // debugger;
    dispatch(
      setSelectedSlotAndViewSlotAction({
        viewSlot: viewSlot,
        selectedSlot: newEvent,
      })
    );
  }, [calendarEvents]);

  useEffect(() => {
    return () => {
      dispatch(
        setSelectedSlotAndViewSlotAction({
          viewSlot: false,
          selectedSlot: {},
        })
      );
    };
  }, []);

  const handleCancel = () => {
    dispatch({ type: patientActions.SET_VIEW_SLOT, payload: false });
  };

  let detailsCard;
  if (isAppointment) {
    detailsCard = (
      <CustomerCalendarAppointmentCard selectedSlot={selectedSlot} />
    );
  } else {
    detailsCard = <CustomerCalendarSessionCard />;
  }

  return (
    <div className="customer-calendar px-sm-4 pb-sm-5 doctor-dashboard-home calendar-screen">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.lang.calendar}
      />
      <MyCalendarCustomer />
      <Drawer
        placement={"right"}
        closable={true}
        onClose={handleCancel}
        visible={viewSlot}
        width={400}
      >
        <div className="px-3 mt-3">
          {isAppointment ? language.lang.appointment : language.lang.event}
        </div>
        <div className="d-flex w-100 mt-4 mb-3 selected-appoint">
          <div className="col-2 p-0 headings text-end">
            {language.lang.time}
          </div>
          <div className="col-8 p-0 mx-2 ml-3 headings">
            {language.lang.details}
          </div>
          <div className="col-2 p-0 headings">
            <BsFilterRight size={25} />
          </div>
        </div>
        {detailsCard}
      </Drawer>
    </div>
  );
};

export default CustomerCalendar;
