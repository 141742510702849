import { useDropzone } from "react-dropzone";
import { useState, useEffect } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import {
  showErrorNotification,
  showSuccessNotification,
  uploadFile,
} from "../utility/Helpers";
import {
  categoryMentalHealth,
  fileTypePrescription,
  storageTypeDocument,
  userTypeUser,
} from "../utility/constants";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import { settings } from "../config";
import { useHistory } from "react-router-dom";
import { dashboardRoute } from "../routes";
import Loading from "./Loading";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UploadPrescription = (props) => {
  const [files, setFiles] = useState([]);
  const { userData } = useSelector((state) => state.auth);
  const language = useSelector((state) => state.language);

  const history = useHistory();
  const [edit, setedit] = useState(false);
  const [fileURL, setFileURL] = useState(undefined);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingState, setLoadingState] = useState(false);
  const [notesToPatient, setNotesToPatient] = useState("");

  let setting = {
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  };

  const { getRootProps, getInputProps, open, acceptedFiles } =
    useDropzone(setting);

  const [stateFiles, setStateFiles] = useState(acceptedFiles);

  useEffect(() => {
    if (files.length > 0) {
      setFileURL(files[0].preview);
    }
    if (!edit) {
      setStateFiles(acceptedFiles);
    }
  }, [stateFiles, acceptedFiles]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(null);
    setPageNumber(1);
  };

  const removeFile = () => {
    setFiles([]);
    setFileURL(undefined);
    setNotesToPatient("");
  };

  const uploadThisFile = async (file) => {
    const data = {
      storageType: storageTypeDocument,
      category: categoryMentalHealth,
      fileType: fileTypePrescription,
      userType: userTypeUser,
      userId: userData.id,
    };
    data["multi-files"] = file;
    return await uploadFile(data);
  };

  const readFileData = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };

  const convertPdfToImages = async (file) => {
    const images = [];
    const data = await readFileData(file);
    const pdf = await pdfjs.getDocument(data).promise;
    const canvas = document.createElement("canvas");
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1 });
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport: viewport }).promise;
      images.push(canvas.toDataURL());
    }
    canvas.remove();
    return images;
  };

  const uploadPrescription = async () => {
    let url = "";
    setLoadingState(true);
    if (files && files[0].type === "application/pdf") {
      let result = await convertPdfToImages(stateFiles[0]);
      const pdf = new jsPDF();
      for (let i = 0; i < result.length; i++) {
        pdf.addImage(result[i], "JPEG", 20, 20, 180, 280);
        pdf.addPage();
      }
      pdf.text(20, 20, "Notes to Patient:");
      pdf.text(20, 30, notesToPatient);
      const blobFile = new File([pdf.output("blob")], "Prescription.pdf", {
        type: "application/pdf",
      });
      url = await uploadThisFile(blobFile);
    } else {
      const pdf = new jsPDF();
      pdf.addImage(fileURL, "JPEG", 20, 20, 180, 250);
      pdf.addPage();
      pdf.text(20, 20, "Notes to Patient:");
      pdf.text(20, 30, notesToPatient);
      const blobFile = new File([pdf.output("blob")], "Prescription.pdf", {
        type: "application/pdf",
      });
      url = await uploadThisFile(blobFile);
    }
    const formData = {
      appointmentId: props.appointDetails.id,
      practitionerId: props.appointDetails.practitioner_id,
      customerId: props.appointDetails.user_id,
      prescriptionUrl: url,
    };
    try {
      const res = await axios.post(
        `${settings.apiBaseURL}/uploadCloudPrescription`,
        formData
      );
      if (res.status === 200) {
        showSuccessNotification("Prescription Uploaded");
        setLoadingState(false);
        history.push(dashboardRoute.path);
      }
    } catch (e) {
      setLoadingState(false);
      showErrorNotification("Prescription Upload Failed");
    }
  };

  const handleConfirm = () => {
    uploadPrescription();
  };

  const handleTextChange = (event) => {
    setNotesToPatient(event.target.value);
  };

  console.log("--- files", files);

  return (
    <div className="upload-prescription mt-3">
      <div className="presc-container row mx-0">
        <h5 className="col-12 px-0">{language.lang.uploadYourPrescription}</h5>
        <p className="col-12 px-0">
          {language.lang.pleaseUploadPrescriptionInPdfOrImageFormat}
        </p>
      </div>
      <div hidden={files.length === 0}>
        <div className="remove-button" onClick={removeFile}>
          <label>Remove</label>
          <div className="circle">x</div>
        </div>
      </div>

      <div className="upload-container">
        <h5 hidden={files.length !== 0}>{language.lang.notes}:</h5>
        <div className="cont">
          <div
            hidden={files.length !== 0}
            {...getRootProps({ className: "dropzone" })}
          >
            <input {...getInputProps()} />
            <FaCloudUploadAlt className="upload--icon" />
            <p>{language.lang.dragAndDrop}</p>
            <div className="or-block">
              <div className="or-line"></div>
              <div className="or-text">{language.lang.or}</div>
              <div className="or-line"></div>
            </div>
            <button
              type="button"
              onClick={() => {
                open();
                setedit(false);
              }}
              className="browse-btn"
            >
              {language.lang.browse}
            </button>
          </div>
          {files.length !== 0 && files[0].type.startsWith("image/") && (
            <img
              className="w-100 h-100"
              style={{ objectFit: "contain" }}
              src={fileURL}
              alt="image1"
            />
          )}
          {files.length !== 0 && files[0].type === "application/pdf" && (
            <Document file={fileURL} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} scale={1.2} />
            </Document>
          )}
        </div>
        <h5>{language.lang.notesToPatient}:</h5>
        <div>
          <textarea
            onChange={handleTextChange}
            className="notes-patient"
          ></textarea>
        </div>
      </div>
      <div>
        {loadingState ? (
          <Loading></Loading>
        ) : (
          <div className="btn-groups">
            <button
              className="btn confirm"
              disabled={files.length === 0}
              onClick={handleConfirm}
            >
              {language.lang.confirm_prescription}
            </button>
            <button className="btn cancel" onClick={props.handleCancel}>
              {language.lang.cancel}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default UploadPrescription;
