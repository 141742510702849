import { combineReducers } from "redux";
import authReducer from "./authenitcation/reducers";
import interceptorReducer from "./interceptor/reducer";
import practitionerReducer from "./practitioner/reducer";
import userReducer from "./user/reducer";
import clientReducer from "./client/reducer";
import superAdmin from "./superAdmin/reducer";
import patientReducer from "./patient/reducer";
import languageReducer from "./language/reducer";
import clinicReducer from "./clinic/reducer";
import fitnessBuddyReducer from "./fitnessBuddy/reducer";
import liveSessionReducer from "./liveSession/reducer";
import mealPlanReducer from "./mealPlan/reducer";
import firebaseReducer from "./firebase/reducer";

const appReducers = combineReducers({
  firebase: firebaseReducer,
  auth: authReducer,
  interceptor: interceptorReducer,
  practitioner: practitionerReducer,
  user: userReducer,
  client: clientReducer,
  patient: patientReducer,
  superAdmin: superAdmin,
  language: languageReducer,
  clinic: clinicReducer,
  patientDetails: fitnessBuddyReducer,
  liveSession: liveSessionReducer,
  mealPlan: mealPlanReducer,
});

const rootReducers = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    state.patient.top_practitioner = [];
  }
  return appReducers(state, action);
};

export default rootReducers;
