// Modules import
import { useDispatch, useSelector } from "react-redux";
import AddInterestViewModal from "./AddInterestViewModal";
import { useState } from "react";
import actions from "../../redux/fitnessBuddy/actions";
import { AiOutlinePlus } from "react-icons/ai";
import AvatarImg from "../../assets/images/avatar.png";
import { IoCloseCircleSharp } from "react-icons/io5";
import { BsPencil } from "react-icons/bs";
import { useRef } from "react";
import { showErrorNotification, uploadFile } from "../../utility/Helpers";
import {
  categoryFitness,
  fileTypeImage,
  storageTypeImage,
  userTypeUser,
} from "../../utility/constants";

const FitnessBuddyDetails = () => {
  const language = useSelector((state) => state.language);
  const languageType = useSelector(
    (state) => state.language.general.preferredlanguage
  );
  const [modalShow, setModalShow] = useState(false);
  const userData = useSelector((state) => state.auth.userData);

  const imgRef = useRef(null);
  let img = null;

  const buddyDetails = useSelector(
    (state) => state.patientDetails.buddyDetails || {}
  );
  const userDetails = useSelector((state) => state.patientDetails.userDetails);

  const dispatch = useDispatch();
  const handleRemove = (id) => {
    const newList = buddyDetails.userInterests.filter(
      (item) => item.interest.id !== id
    );
    dispatch({
      type: actions.REMOVE_USER_INTEREST,
      payload: newList ? newList : [],
    });
  };
  const uploadProfilePicture = async (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    img = e.target.files[0];
    if (!img.type.startsWith("image")) {
      showErrorNotification();
      return;
    }
    try {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        imgRef.current.src = fileReader.result;
      };
      fileReader.readAsDataURL(img);
      if (imgRef.current !== null) {
        const profilePhotoUrl = await uploadProfileImage(img);
        console.log(profilePhotoUrl);
        dispatch({
          type: actions.UPDATE_PROFILE_IMAGE,
          payload: { profilePhotoUrl: profilePhotoUrl },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const uploadProfileImage = async (ProfileImage) => {
    if (!ProfileImage) return null;
    const data = {
      storageType: storageTypeImage,
      category: categoryFitness,
      userType: userTypeUser,
      fileType: fileTypeImage,
      userId: userData.id,
    };
    data["multi-files"] = ProfileImage;

    return uploadFile(data);
  };
  const getInterestName = (userInterest) => {
    if (languageType === "ar") {
      return userInterest.interest.name_ar;
    } else if (languageType === "ku") {
      return userInterest.interest.name_ku;
    } else {
      return userInterest.interest.name;
    }
  };
  
  return (
    <div className=" fb-details-container col md-6">
      <div className="buddy-profile row">
        <div className="profile-info col md-6">
          <img
            className="profile-pic"
            alt="profile-pic"
            ref={imgRef}
            src={
              !!buddyDetails?.profile_photo_url &&buddyDetails?.profile_photo_url !== ""
                ? buddyDetails?.profile_photo_url
                : AvatarImg
            }
            name="profilePhotoUrl"
            type="file"
            accept="image/*"
          />
          <div className="upload-img" onChange={uploadProfilePicture}>
            <input
              placeholder=""
              id="profilePhotoUrl"
              className="emiratesID-input"
              name="profilePhotoUrl"
              type="file"
              accept="image/*"
              hidden
            />
            <label htmlFor="profilePhotoUrl">
              <BsPencil />
            </label>
            {/* <BsPencil/> */}
          </div>

          <h5>{buddyDetails.name}</h5>
        </div>
        <div className="about-me col md-6" >
          <div className="left" >
            <div className={
                languageType === "ar"
                  ? ` right ${language.direction}`
                  : ` left ${language.direction}`
              }>{language.lang.aboutMe}</div>
            <textarea
              name="about_info"
              className="neumorphic-input"
              defaultValue={userDetails && userDetails?.aboutInfo}
              onChange={(e) => {
                dispatch({
                  type: actions.UPDATE_ABOUT_INFO,
                  payload: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="all-interest">
        {buddyDetails?.userInterests?.map((userInterest) => {
          return (
            <div
              key={userInterest.interest.id}
              className="display-interest"
              style={{ backgroundColor: userInterest.interest.color_code }}
            >
              <div key={userInterest.interest.id}>
                {getInterestName(userInterest)}
              </div>
              <IoCloseCircleSharp
                className="remove-interest"
                onClick={() => {
                  handleRemove(userInterest.interest.id);
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="add-interest">
        <button
          onClick={(e) => {
            e.preventDefault();
            setModalShow(true);
          }}
        >
          {language.lang.addInterest} <AiOutlinePlus />
        </button>
        <AddInterestViewModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          backdrop="static"
        />
      </div>
    </div>
  );
};
export default FitnessBuddyDetails;
