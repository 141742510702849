import { FiMaximize } from "react-icons/fi";
import CallTimer from "../CallTimer";
import { useSelector } from "react-redux";
// import { useEffect } from "react";

const LiveSessionVideoCard = ({
  onEndCallPressed,
  onToggleVideoPressed,
  onToggleAudioPressed,
  audioEnabled,
  videoEnabled,
  remoteStreamIDs,
  isHost,
  practitionerDetails,
  practitionerName,
  onFullScreenPressed,
  remainingDuration,
  onTimerFinished,
}) => {
  const hostMuteAll = useSelector((state) => state.liveSession.hostMuteAll);

  // useEffect(() => {
  //   console.log("--- effect streams", agoraStreams);
  //   agoraStreams.forEach((stream) => {
  //     const streamId = String(stream.getId());
  //     stream.play(streamId);
  //   });
  // }, [agoraStreams]);

  const videoIDs = remoteStreamIDs || [];
  // const videoIDs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  // console.log("---- videoIDs", videoIDs);

  // let videosView;
  // if (videoIDs.length <= 2) {
  //   videosView = <TowOrLessVideosView remoteStreamIDs={videoIDs} />;
  // } else if (videoIDs.length <= 4) {
  //   videosView = <ThreeOrFourVideosView remoteStreamIDs={videoIDs} />;
  // } else {
  //   videosView = <FiveOrMoreVideosView remoteStreamIDs={videoIDs} />;
  // }

  // const videosView = <FiveOrMoreVideosView remoteStreamIDs={videoIDs} />;
  const videosView = <TowOrLessVideosView remoteStreamIDs={videoIDs} />;

  return (
    <div className="live-session-video-card video-call-card mm-card h-100">
      <div className="container video px-0" id="live-session-videos-container">
        {/* {videoIDs.map((remoteID) => (
          <div key={remoteID} id={remoteID} className="remote-video"></div>
        ))} */}
        {videosView}
      </div>
      <div className="bottom-bar row mx-0">
        <div className="row mx-0 col-lg-6 px-0 d-flex flex-column justify-content-between">
          <div className="col-6 col-lg-12 px-0">
            <p className="mb-0">{practitionerName}</p>
            <p className="title">
              {practitionerDetails.userSpecializationsByUserId &&
                practitionerDetails.userSpecializationsByUserId.length > 0 &&
                practitionerDetails.userSpecializationsByUserId[0]
                  .specializationById.name}
            </p>
            <CallTimer
              duration={remainingDuration}
              onTimerFinished={onTimerFinished}
            />
          </div>
        </div>
        <div className="col-lg-6 px-0 call-controles">
          <button onClick={onFullScreenPressed} className="btn btn-light">
            <FiMaximize />
          </button>
          <button className="btn btn-light" onClick={onToggleVideoPressed}>
            {videoEnabled ? (
              <i className="fas fa-video"></i>
            ) : (
              <i className="fas fa-video-slash"></i>
            )}
          </button>
          <button
            disabled={hostMuteAll && !isHost}
            className="btn btn-light"
            onClick={onToggleAudioPressed}
          >
            {audioEnabled ? (
              <i className="fas fa-microphone"></i>
            ) : (
              <i className="fas fa-microphone-slash"></i>
            )}
          </button>
          <button className="btn btn-danger" onClick={onEndCallPressed}>
            <i className="fas fa-phone text-light"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

const TowOrLessVideosView = ({ remoteStreamIDs }) => {
  const hasMoreThan2Videos = remoteStreamIDs.length > 2;
  const has2To4Videos =
    remoteStreamIDs.length <= 4 && remoteStreamIDs.length > 2;

  return (
    <div
      className={`two-or-less-videos-view d-flex w-100 hide-scrollbar ${
        hasMoreThan2Videos ? "has-more-than-2-videos" : ""
      }`}
    >
      {remoteStreamIDs.map((remoteID) => (
        <div
          key={remoteID}
          id={remoteID}
          className={`remote-video ${has2To4Videos ? "w-50" : ""}`}
        ></div>
      ))}
    </div>
  );
};

const FiveOrMoreVideosView = ({ remoteStreamIDs }) => {
  const classNames = "remote-video";

  const listItems = [];

  for (let i = 0; i < remoteStreamIDs.length; i += 2) {
    const id = remoteStreamIDs[i];
    const secondID = remoteStreamIDs[i + 1];
    const addSecondVideo = i + 1 < remoteStreamIDs.length;
    listItems.push(
      <div key={id} className="h-100 h-list-item">
        <div key={id} id={id} className={classNames}></div>
        {addSecondVideo && (
          <div key={secondID} id={secondID} className={classNames}></div>
        )}
      </div>
    );
  }

  return (
    <div className="five-or-more-videos-view h-list">
      {listItems}
      {/* <div className=></div>
      <div className={`${classNames} h-list-item bg-danger`}></div>
      <div className={`${classNames} h-list-item bg-secondary`}></div>
      <div className={`${classNames} h-list-item bg-success`}></div>
      <div className={`${classNames} h-list-item bg-warning`}></div>
      <div className={`${classNames} h-list-item bg-info`}></div>
      <div className={`${classNames} h-list-item bg-dark`}></div>
      <div className={`${classNames} h-list-item bg-primary`}></div>
      <div className={`${classNames} h-list-item bg-dark`}></div>
      <div className={`${classNames} h-list-item bg-info`}></div>
      <div className={`${classNames} h-list-item bg-primary`}></div> */}
    </div>
  );
};

const ThreeOrFourVideosView = ({ remoteStreamIDs }) => {
  return (
    <div className="four-or-less-videos-view row">
      {remoteStreamIDs.map((remoteID) => (
        <div
          key={remoteID}
          id={remoteID}
          className="col-lg-6 h-50 remote-video"
        ></div>
      ))}
      {/* <div className="col-lg-6 h-50 remote-video bg-danger"></div>
        <div className="col-lg-6 h-50 remote-video bg-primary"></div> */}
    </div>
  );
};

export default LiveSessionVideoCard;
