import moment from "moment";
import { calendarColorTokensMap } from "../../utility/constants";
import actions from "./actions";

const initState = {
  top_practitioner: [],
  docoffset: 0,
  banners: [],
  meal_plans: [],
  reviews:[],
  mealoffset: 0,
  appointments: {},
  selectedPractitioner: {},
  selectedSlot: {},
  slotnumber: "",
  loading: false,
  calendar: {
    lastUpdateDate: moment(),
    calendarView: "day",
    events: [],
    viewSlot: false,
    needToCall: false,
    selectedSlot: {},
  },
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.CLEAR_CALENDAR_EVENTS:
      return Object.assign({}, state, {
        calendar: {
          ...state.calendar,
          events: [],
          viewSlot: false,
          selectedSlot: {},
        },
      });
    case actions.SET_SELECTED_SLOT_AND_VIEW_SLOT:
      return Object.assign({}, state, {
        calendar: {
          ...state.calendar,
          viewSlot: payload.viewSlot,
          selectedSlot: payload.selectedSlot,
        },
      });
    case actions.START_LOADING:
      return Object.assign({}, state, { loading: true });
    case actions.GET_TOP_PRACTITIONERS:
      return Object.assign({}, state, {
        top_practitioner: [...state.top_practitioner, ...payload],
      });
    case actions.SET_OFFSET:
      return Object.assign({}, state, {
        ...state,
        docoffset: payload,
      });
    case actions.SET_MEAL_OFFSET:
      return Object.assign({}, state, {
        ...state,
        mealoffset: payload,
      });
    case actions.RESET_TOP_PRACTITIONERS:
      return Object.assign({}, state, {
        ...state,
        top_practitioner: [],
        docoffset: 0,
      });
    case actions.SET_HOME_BANNER:
      if (!payload) return state;
      return Object.assign({}, state, {
        ...state,
        banners: payload,
      });
    case actions.SET_REVIEWS:
      if (!payload) return state;
      return Object.assign({}, state, {
        ...state,
        reviews: payload,
      });
    case actions.SET_MEAL_PLANS:
      if (!payload) return state;
      return Object.assign({}, state, {
        ...state,
        meal_plans: [...state.meal_plans, ...payload],
      });
    case actions.UPDATE_MEAL_PLANS:
      if (!payload) return state;
      return Object.assign({}, state, {
        ...state,
        meal_plans: payload,
      });
    case actions.UPDATE_PATIENT_APPOINTMENTS:
      return Object.assign({}, state, {
        ...state,
        appointments: payload,
      });
    case actions.SET_SELECTED_PRACTITIONER:
      return Object.assign({}, state, {
        ...state,
        selectedPractitioner: payload.selectedPractitioner,
        selectedSlot: payload.selectedSlot,
        slotnumber: payload.slotnumber,
      });
    case actions.SET_ALL_APPOINTMENTS_OF_CUSTOMER:
      // console.log("--- SET_ALL_APPOINTMENTS_OF_CUSTOMER", payload);
      let docEvent = [];
      if (payload.customerCompletedLiveSessions) {
        payload.customerCompletedLiveSessions.forEach((item) => {
          const statusID = item.status_id || item.sessionDetails.status_id;
          const styleToken = payload?.styles.tokens.find(
            (token) => token.status_id === statusID
          );
          docEvent.push(
            mapSessionToCalendarItem({
              session: item,
              statusText:
                (item.status && item.status.name) ||
                (item.sessionDetails.status && item.sessionDetails.status.name),
              colorToken: styleToken && styleToken.token,
            })
          );
        });
      }
      if (payload.customerOngoingLiveSessions) {
        payload.customerOngoingLiveSessions.forEach((item) => {
          const statusID = item.status_id || item.sessionDetails.status_id;
          const styleToken = payload?.styles.tokens.find(
            (token) => token.status_id === statusID
          );
          docEvent.push(
            mapSessionToCalendarItem({
              session: item,
              statusText:
                (item.status && item.status.name) ||
                (item.sessionDetails.status && item.sessionDetails.status.name),
              colorToken: styleToken && styleToken.token,
            })
          );
        });
      }
      if (payload.customerRejoinLiveSessions) {
        payload.customerRejoinLiveSessions.forEach((item) => {
          const statusID = item.status_id || item.sessionDetails.status_id;
          const styleToken = payload?.styles.tokens.find(
            (token) => token.status_id === statusID
          );
          docEvent.push(
            mapSessionToCalendarItem({
              session: item,
              statusText:
                (item.status && item.status.name) ||
                (item.sessionDetails.status && item.sessionDetails.status.name),
              colorToken: styleToken && styleToken.token,
            })
          );
        });
      }
      if (payload.customerUnattendedLiveSessions) {
        payload.customerUnattendedLiveSessions.forEach((item) => {
          const statusID = item.status_id || item.sessionDetails.status_id;
          const styleToken = payload?.styles.tokens.find(
            (token) => token.status_id === statusID
          );
          docEvent.push(
            mapSessionToCalendarItem({
              session: item,
              statusText:
                (item.status && item.status.name) ||
                (item.sessionDetails.status && item.sessionDetails.status.name),
              colorToken: styleToken && styleToken.token,
            })
          );
        });
      }
      if (payload.customerUpcomingLiveSessions) {
        payload.customerUpcomingLiveSessions.forEach((item) => {
          const statusID = item.status_id || item.sessionDetails.status_id;
          const styleToken = payload?.styles.tokens.find(
            (token) => token.status_id === statusID
          );
          docEvent.push(
            mapSessionToCalendarItem({
              session: item,
              statusText:
                (item.status && item.status.name) ||
                (item.sessionDetails.status && item.sessionDetails.status.name),
              colorToken: styleToken && styleToken.token,
            })
          );
        });
      }
      if (payload?.customerUnattendedAppointmentsByPatient?.length > 0) {
        payload.customerUnattendedAppointmentsByPatient.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText:
                payload.customerUnattendedAppointmentsByPatient[0].status.name,
              color:
                payload?.styles?.customerUnattendedAppointmentsByPatient
                  ?.left_border,
              colorToken:
                payload?.styles?.customerUnattendedAppointmentsByPatient?.token,
            })
          );
        });
      }
      if (payload?.customerUnattendedAppointmentsByDoctor?.length > 0) {
        payload.customerUnattendedAppointmentsByDoctor.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText:
                payload.customerUnattendedAppointmentsByDoctor[0].status.name,
              color:
                payload?.styles?.customerUnattendedAppointmentsByDoctor
                  ?.left_border,
              colorToken:
                payload?.styles?.customerUnattendedAppointmentsByDoctor?.token,
            })
          );
        });
      }
      if (payload?.customerPendingAppointments?.length > 0) {
        payload.customerPendingAppointments.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText: "Pending Appointment",
              color: payload?.styles?.customerPendingAppointments?.left_border,
              colorToken: payload?.styles?.customerPendingAppointments?.token,
            })
          );
        });
      }
      if (payload?.customerInProgressAppointments?.length > 0) {
        payload.customerInProgressAppointments.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText: "Progress Appointment",
              color:
                payload?.styles?.customerInProgressAppointments?.left_border,
              colorToken:
                payload?.styles?.customerInProgressAppointments?.token,
            })
          );
        });
      }
      if (payload?.customerOngoingAppointments?.length > 0) {
        payload.customerOngoingAppointments.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText: "Ongoing Appointment",
              color: payload?.styles?.customerOngoingAppointments?.left_border,
              colorToken: payload?.styles?.customerOngoingAppointments?.token,
            })
          );
        });
      }
      if (payload?.customerUpcomingAppointments?.length > 0) {
        payload.customerUpcomingAppointments.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText: "Upcoming Appointment",
              color: payload?.styles?.customerUpcomingAppointments?.left_border,
              colorToken: payload?.styles?.customerUpcomingAppointments?.token,
            })
          );
        });
      }
      if (payload?.customerCancelledAppointments?.length > 0) {
        payload.customerCancelledAppointments.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText: "Cancelled Appointment",
              color:
                payload?.styles?.customerCancelledAppointments?.left_border,
              colorToken: payload?.styles?.customerCancelledAppointments?.token,
            })
          );
        });
      }
      if (payload?.customerCompletedAppointments?.length > 0) {
        payload.customerCompletedAppointments.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText: "Completed Appointment",
              color:
                payload?.styles?.customerCompletedAppointments?.left_border,
              colorToken: payload?.styles?.customerCompletedAppointments?.token,
            })
          );
        });
      }
      if (payload?.customerFailedAppointments?.length > 0) {
        payload.customerFailedAppointments.forEach((item) => {
          docEvent.push(
            mapAppointmentToCalendarItem({
              appointment: item,
              statusText: "Failed Appointment",
              color: payload?.styles?.customerFailedAppointments?.left_border,
              colorToken: payload?.styles?.customerFailedAppointments?.token,
            })
          );
        });
      }
      // console.log("--- docEvent", docEvent);
      return Object.assign({}, state, {
        ...state,
        loading: false,
        calendar: {
          ...state.calendar,
          events: docEvent,
        },
      });
    case actions.SET_PATIENT_VIEW_TYPE:
      return Object.assign({}, state, {
        ...state,
        calendar: {
          ...state.calendar,
          calendarView: payload,
        },
      });
    case actions.SET_VIEW_SLOT:
      return Object.assign({}, state, {
        ...state,
        calendar: {
          ...state.calendar,
          viewSlot: payload,
        },
      });
    case actions.SET_SELECTED_SLOT:
      return Object.assign({}, state, {
        ...state,
        calendar: {
          ...state.calendar,
          selectedSlot: payload,
        },
      });
    case actions.SET_NEED_TO_CALL_CALENDAR:
      return Object.assign({}, state, {
        ...state,
        calendar: {
          ...state.calendar,
          lastUpdateDate: moment(),
          needToCall: payload,
        },
      });
    default:
      return state;
  }
};

const mapSessionToCalendarItem = ({
  session,
  color,
  colorToken,
  statusText,
}) => {
  return {
    PractitionerName: session.sessionDetails.title,
    PatientName: "",
    start: moment.utc(session.sessionDetails.start_date_time_utc).toDate(), // new Date(session.sessionDetails.start_date_time_local),
    end: moment.utc(session.sessionDetails.end_date_time_utc).toDate(), // new Date(session.sessionDetails.end_date_time_local),
    color: calendarColorTokensMap[colorToken] || color,
    appointmentId: session.id,
    statusText: statusText,
    statusID: session.status_id || session.sessionDetails.status_id,
    calendarItemType: "session",
    ...session,
  };
};

export const mapAppointmentToCalendarItem = ({
  appointment,
  statusText,
  color,
  colorToken,
}) => {
  return {
    PractitionerName: `${appointment?.practitionerInfo?.name || ""} ${
      appointment?.practitionerInfo?.surname || ""
    }`.trim(),
    PatientName: `${appointment?.patientInfo?.name || ""} ${
      appointment?.patientInfo?.surname || ""
    }`.trim(),
    start: new Date(appointment.start_date_time),
    end: new Date(appointment.end_date_time),
    color: calendarColorTokensMap[colorToken], // || color,
    appointmentId: appointment.id,
    statusText: statusText,
    statusID: appointment.status_id,
    calendarItemType: "appointment",
    ...appointment,
  };
};

export default reducer;
