import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"



const RecipeTotalsprogessbar = ({ calories, carbs, fats, proteins }) => {

    const calculatePercentage = (value, total) => {
        return Math.round((value / total) * 100);
    };

    const totalPercentage = calories + carbs + fats + proteins;
    const caloriesPercentage = calculatePercentage(calories, totalPercentage);
    const carbsPercentage = calculatePercentage(carbs, totalPercentage);
    const fatPercentage = calculatePercentage(fats, totalPercentage);
    const proteinsPercentage = calculatePercentage(proteins, totalPercentage);
    // console.log("calories percentage",caloriesPercentage,carbsPercentage,fatPercentage,proteinsPercentage)
    return (
        <div className="recipepiechart" >
            <div style={{ height: "95px", width: "95px" }}>
                <CircularProgressbarWithChildren
                    value={caloriesPercentage}
                    // text={`${calories}<br/> cal`}
                    strokeWidth={4}
                    styles={buildStyles({
                        textColor: "black",
                        pathColor: "blue",
                        trailColor: "#e5c076",
                        textSize: "18px",
                    })}
                >
                    <div style={{ textAlign: 'center' }}>
                        <div style={{fontSize:"20px",marginTop:"15px"}}><strong>{calories}</strong></div>
                        <div>cal</div>
                    </div>

                </CircularProgressbarWithChildren>
            </div>


            <div className="recipepiechart_micros">
                <label>{carbsPercentage}%</label>
                <p style={{ fontSize: "18px" }}><strong>{carbs}g</strong></p>
                <p>Carbs</p>
            </div>
            <div className="recipepiechart_micros">
                <label>{fatPercentage}%</label>
                <p style={{ fontSize: "18px" }}><strong>{fats} g</strong></p>
                <p>Fats</p>
            </div>
            <div className="recipepiechart_micros">
                <label>{proteinsPercentage}%</label>
                <p style={{ fontSize: "18px" }}><strong>{proteins} g</strong></p>
                <p>Protein</p>
            </div>
        </div>
    )
}

export default RecipeTotalsprogessbar