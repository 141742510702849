import { Row, Col, Form, Modal } from "react-bootstrap";
import { Rate } from "antd";
// Critical River, Varun Mishra, this package is not compatible with react version 18.
// import ImagePicker from 'react-image-picker';
// import 'react-image-picker/dist/index.css';
import { Select } from 'antd';
import {
  arr
} from "../utility/Helpers";
import Switch from "react-switch";
import { BsPlus } from "react-icons/bs";
import React, {useEffect, useState} from "react";
import axios from "../services/Axios";
import { settings } from "../config";
import { GrCheckboxSelected } from "react-icons/gr";
import Loading from "./Loading";
import ContentCard from "./ContentCard";
import VideoCard from "./VideoCard";
import SingleFeatureCard from "./SingleFeatureCard";
import { useSelector } from "react-redux";
import ComboFeatureCard from "./ComboFeatureCard";
const { Option } = Select;



const ComboFeatureModalHelper = (props) => {
    const [loading, setLoading] = useState(false);
    const language = useSelector((state) => state.language);
    return (
            <Row>
                    <Col xs={12} lg={6}>
                    <div>
                                <Form.Label>Feature Category  <sup>*</sup> </Form.Label>
                                <Select value={props.data && props.data.feature_master_id ? props.data.feature_master_id : props.Input.featureMasterId } onChange={props.onChangenewHandler} name="featureMasterId" style={{width: '100%'}}>
                                    {arr.map((el)=>{
                                        return <Option value={el.id}>{el.feature_description}</Option>
                                    })}
                                </Select>
                            </div>

                            <div style={{marginBottom: '15px',marginTop:'20px'}}>
                            <div className="d-flex justify-content-between align-items-center">
                                    <Form.Label>Feature Sub Category  <sup>*</sup> </Form.Label><div className="add_new_link" onClick={() =>{props.Setshown(!props.shown)}}>{language.lang["add-new"]} <BsPlus /></div></div>

                                {props.shown? <div><Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="text" className="shadow-none" name="featureSectionName" onChange={props.onChangenewHandler} />
                                </Form.Group>  <div className="relbtns" onClick={props.postData}><div className="save">{language.lang.save}</div>
                                    <div className="discard" onClick={()=> props.Setshown(!props.shown)}>Discard</div></div></div>: ''}
                                {!props.shown ? <Select value={props.data && props.data.name ? props.data.name: props.Input.featureSection} onChange={props.onSelectHandler} name="featureSection" style={{width: '100%'}}>
                                    {props.dropData ? props.dropData.map((el)=>{
                                        return <Option value={el.id}>{el.name}</Option>
                                    }) : ''}
                                </Select>: ''}
                            </div>

                            <div>
                                <Form.Label>Section Content Header  <sup>*</sup> </Form.Label>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="text" className="shadow-none" name="descriptionMainHeader" value={props.data && props.data.description_main_header ? props.data.description_main_header : props.Input.descriptionMainHeader} onChange={props.onChangeHandler} />
                                </Form.Group>
                            </div>

                   
                            <div>
                                <Form.Label>Description <sup>*</sup> </Form.Label>
                                 <textarea
                                    className="single-feature-input content-textarea"
                                    name="descriptionContent"
                                    value={props.Input.descriptionContent} 
                                    onChange={props.onChangeHandler}
                                ></textarea>
                                 </div>
                                 <div className="d-flex justify-content-start flex-column align-items-center" style={{margin:'17px 0'}}>
                                <Form.Label className="w-100">Is {language.lang["most-popular"]}<sup>*</sup></Form.Label>
                                <div className="d-flex w-100 justify-content-start" style={{gap:'20px'}}>
                                <div className="d-flex justify-content-center" style={{gap:'10px'}}>
                                <Form.Label>{language.lang.yes}</Form.Label>
                                <Switch
                                   checked={props.Input.isMostPopular}
                                   onChange={(val)=>{
                                    props.setInput((prevState)=>{
                                        return {
                                            ...prevState,
                                            isMostPopular : val
                                        }
                                    });
                                   }}
                                    onColor="#bff5de"
                                    onHandleColor="#56c596"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    id="material-switch"
                                />
                                </div>
                                <div className="d-flex justify-content-center" style={{gap:'10px'}}>
                                <Form.Label>{language.lang.no}</Form.Label>
                                <Switch
                                    checked={!props.Input.isMostPopular}
                                    onChange={(val)=>{
                                        props.setInput((prevState)=>{
                                            return {
                                                ...prevState,
                                                isMostPopular : !val
                                            }
                                        });
                                    }}
                                    onColor="#bff5de"
                                    onHandleColor="#56c596"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    id="material-switch"
                                />
                                </div>
                                </div>
                                </div>
                            <div className="d-flex justify-content-start flex-column align-items-center" style={{margin:'17px 0'}}>
                                <Form.Label className="w-100">Is Paid Content<sup>*</sup></Form.Label>
                                <div className="d-flex w-100 justify-content-start" style={{gap:'20px'}}>
                                <div className="d-flex justify-content-center" style={{gap:'10px'}}>
                                <Form.Label>{language.lang.yes}</Form.Label>
                                <Switch
                                   checked={props.Input.isPaidContent}
                                   onChange={(val)=>{
                                    props.setInput((prevState)=>{
                                        return {
                                            ...prevState,
                                            isPaidContent : val
                                        }
                                    });
                                   }}
                                    onColor="#bff5de"
                                    onHandleColor="#56c596"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    id="material-switch"
                                />
                                </div>
                                <div className="d-flex justify-content-center" style={{gap:'10px'}}>
                                <Form.Label>{language.lang.no}</Form.Label>
                                <Switch
                                    checked={!props.Input.isPaidContent}
                                    onChange={(val)=>{
                                        props.setInput((prevState)=>{
                                            return {
                                                ...prevState,
                                                isPaidContent : !val
                                            }
                                        });
                                    }}
                                    onColor="#bff5de"
                                    onHandleColor="#56c596"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    id="material-switch"
                                />
                                </div>
                                </div>
                            </div>


                            <div className="d-flex justify-content-start align-items-center flex-column" style={{margin:'17px 0'}}>
                                <Form.Label className="w-100">Is Corporate Specific<sup>*</sup></Form.Label>
                                <div className="d-flex w-100 justify-content-start" style={{gap:'20px'}}>
                                <div className="d-flex justify-content-center" style={{gap:'10px'}}>
                                <Form.Label>{language.lang.yes}</Form.Label>
                                <Switch
                                   checked={props.Input.isCorporateSpecific}
                                   onChange={(val)=>{
                                    props.setInput((prevState)=>{
                                        return {
                                            ...prevState,
                                            isCorporateSpecific : val
                                        }
                                    });
                                   }}
                                    onColor="#bff5de"
                                    onHandleColor="#56c596"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    id="material-switch"
                                />
                                </div>
                                <div className="d-flex justify-content-center" style={{gap:'10px'}}>
                                <Form.Label>{language.lang.no}</Form.Label>
                                <Switch
                                    checked={!props.Input.isCorporateSpecific}
                                    onChange={(val)=>{
                                        props.setInput((prevState)=>{
                                            return {
                                                ...prevState,
                                                isCorporateSpecific : !val
                                            }
                                        });
                                    }}
                                    onColor="#bff5de"
                                    onHandleColor="#56c596"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    id="material-switch"
                                />
                                </div>
                                </div>
                            </div>
                            {props.Input.isCorporateSpecific && (
                                <>
                                <Form.Label className="mb-1">Corporate </Form.Label>
                                <Select
                                    name="corporate"
                                    onChange={props.onSelectHandlerCorporate}
                                    style={{ width: "100%" }}
                                >
                                    <Option value="1">Adnic</Option>
                                    <Option value="2">Masdar</Option>
                                    <Option value="3">Microsoft</Option>
                                </Select>
                                </>
                            )}
                    </Col>

                    <Col xs={12} lg={6} className="feature-col-2">
                    <div className="d-flex justify-content-center select-img align-items-center py-3">
                                <Form.Label>Select Images</Form.Label>
                                <div className="d-flex justify-content-center align-items-center">
                                <Form.Group controlId="formBasicEmail" className="m-0">
                                    <Form.Control type="text" className="shadow-none " name="featureCategory" onChange={(e) => props.SetsrchImg(e.target.value)} />
                                </Form.Group>  
                                <div className="search" onClick={()=> props.searchContent("image")}>{language.lang.search}</div>
                                </div>
                            </div>

                            {/*<div className="image-thumbnails-block">
                                <Form.Label>Select Thumbnails </Form.Label>
                                </div>*/}
                               <div className="image_picker mb-2">
                                    <div className="videolist"> 
                                        {props.imageList.length > 0 ? (props.imageList.map((img,i) => {
                                            return <div style={{position: 'relative', height: 'max-content'}} onClick={()=> props.addImage(i,img.selected)}>
                                            <img key={i} className={`pervideo thumbnail ${img.selected? 'selected': ''} `} src={img.url}/>
                                            {img.selected && <GrCheckboxSelected className="selected-video"/>}
                                        </div>
                                        })): (<div className="videolist d-flex justify-content-center align-items-center none-msg pt-3">No Image Available</div>)}
                                    </div>
                                </div>

                            <div className="d-flex justify-content-center select-img py-1 align-items-center py-3" style={{ marginTop: '15px'}}>
                                <Form.Label>Select Videos</Form.Label>
                                <div className="d-flex justify-content-center align-items-center">
                                <Form.Group controlId="formBasicEmail" className="m-0">
                                    <Form.Control type="text" className="shadow-none " name="featureCategory" onChange={(e) => props.SetsrchVid(e.target.value)} />
                                </Form.Group>  
                                <div className="search" onClick={() => props.searchContent("video")}>{language.lang.search}</div>
                                </div>
                            </div>

                            <div className="image_picker mb-2">
                                    <div className="videolist"> 
                                        {props.videosList.length > 0 ? (props.videosList.map((vid,i) => {
                                            return <div style={{position: 'relative', height: 'max-content'}} onClick={()=> props.addVideo(i,vid.selected)}>
                                            <video key={i} className={`pervideo thumbnail ${vid.selected? 'selected': ''} `} autoPlay="true" >
                                                <source src={vid.url} type="video/mp4"></source>
                                            </video>
                                            {vid.selected && <GrCheckboxSelected className="selected-video"/>}
                                        </div>
                                        })): (<div className="videolist d-flex justify-content-center align-items-center none-msg">No Videos Available</div>)}
                                    </div>
                                </div>
                            
                                <div className="d-flex justify-content-center select-img align-items-center py-3">
                                <Form.Label>Select Documents</Form.Label>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Form.Group controlId="formBasicEmail" className="m-0">
                                            <Form.Control type="text" className="shadow-none " name="featureCategory" onChange={(e) => {}}  />
                                        </Form.Group>  
                                        <div className="search" onClick={()=>{}}>Search</div>
                                    </div>
                                </div>
{/* Critical River, Varun Mishra, this package is not compatible with react version 18. */}
                            {/* {props.documents.length > 0 ? <ImagePicker
                                images={props.documents.map((image,i) => ({src: image.url, value: i}))}
                                onPick={props.onPick}
                                multiple
                                style={{marginLeft:'-10px'}}
                            />: (<div className="videolist d-flex justify-content-center align-items-center none-msg smallvideolist">No Document Available</div>)} */}
                                
                            <div className="d-flex justify-content-center select-img align-items-center py-3">
                                    <Form.Label>Select Animations </Form.Label>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Form.Group controlId="formBasicEmail" className="m-0">
                                            <Form.Control type="text" className="shadow-none " name="featureCategory" onChange={(e) =>{}}  />
                                        </Form.Group>  
                                        <div className="search" onClick={()=>{}}>Search</div>
                                    </div>
                            </div>

                            {/* {props.animations.length > 0 ? <ImagePicker
                                images={props.animations.map((image,i) => ({src: image.url, value: i}))}
                                onPick={props.onPick}
                                multiple
                            />: (<div className="videolist d-flex justify-content-center align-items-center none-msg">{language.lang.no} Animation Available</div>)} */}

                    </Col>

                    <div className="my-3 text-right d-flex justify-content-between">
                        <Modal className="modal_bkg" show={props.preview} onHide={props.setpreview} size="lg">
                         <Modal.Header closeButton>
                        </Modal.Header>
                          <div className="d-flex justify-content-center align-items-center flex-column">
                          <h3 className="d-flex justify-content-center align-items-center py-2" style={{fontWeight: 'bold'}}>Preview</h3>
                            <ComboFeatureCard url={(props.imageList && props.imageList.length > 0 &&props.imageList.filter(a => a.selected).length > 0 && props.imageList.filter(a => a.selected)[0].url)} Heading={props.Input.descriptionMainHeader} subHeading={props.Input.descriptionMainHeader} subContent={props.Input.descriptionSubHeader}/>
                         </div>
                         </Modal>
                        <button className="shadow-none btn btn-primary create-btn" onClick={() => props.setpreview(true)} >
                            Preview
                        </button>
                        <div className="d-flex align-items-center">
                                    <Form.Label>Rating <sup>*</sup> </Form.Label>
                                    <Rate name="rating" onChange={props.onChangeHandler} value={props && props.data && props.data.rating && props.data.rating.aggregate && props.data.rating.aggregate.avg && props.data.rating.aggregate.avg.rating && props.data.rating.aggregate.avg.rating ? props.data.rating.aggregate.avg.rating : props.Input.rating} />
                            </div>
                        <div className="d-flex justify-content-between " style={{gap:'50px'}}>
                            {props.loading ? <Loading /> :<button className="shadow-none btn btn-primary create-btn" onClick={props.submitHandler}>
                                Create
                            </button>}
                        </div>
                    </div>
        </Row>
    );
}

export default ComboFeatureModalHelper;