import moment from "moment";
import { useEffect, useRef, useState } from "react";

const CallTimer = ({ duration, onTimerFinished }) => {
  const intervalIDRef = useRef(null);
  const [remainingDuration, setRemainingDuration] = useState(duration?.clone());
  const oneSecondDuration = moment.duration(1, "seconds");

  useEffect(() => {
    if (!remainingDuration || remainingDuration.asSeconds() > 0) return;
    if (onTimerFinished) onTimerFinished();
    if (intervalIDRef.current) {
      clearInterval(intervalIDRef.current);
    }
  }, [remainingDuration]);

  useEffect(() => {
    if (!duration) return;
    setRemainingDuration(duration.clone());
    if (duration && Object.keys(duration).length > 0) {
      intervalIDRef.current = setInterval(timerTick, 1000);
    }
    return () => {
      if (intervalIDRef.current) clearInterval(intervalIDRef.current);
    };
  }, []);

  const timerTick = () => {
    // if (!remainingDuration) return;
    setRemainingDuration(
      remainingDuration?.subtract(oneSecondDuration).clone()
    );
  };

  // const { seconds, minutes } = useTimer({
  //   expiryTimestamp,
  //   onExpire: () => endCall(),
  // });

  if (!remainingDuration) return <div></div>;

  const timerFinished = remainingDuration.asSeconds() <= 0;

  return (
    <div>
      <button className="timer-sty" style={{ marginLeft: "0" }}>
        {timerFinished ? (
          <>
            <span>00</span>:<span>00</span>
          </>
        ) : (
          <>
            <span>{remainingDuration.minutes()}</span>:
            <span>{remainingDuration.seconds()}</span>
          </>
        )}
      </button>
    </div>
  );
};

export default CallTimer;
