import videoImg from "../assets/images/MealPlan2.png";
const MostPopulartTrendingCard = () => {
  return (
    <div
      className="d-flex flex-row p-2"
      style={{ gap: "50px", borderRadius: "15px" }}
    >
      <div
        className="most-upcoming-img"
        style={{
          height: "150px",
          width: "100%",
          minWidth: "120px",
          maxWidth: "250px",
        }}
      >
        <img src={videoImg} alt="img-thumb" />
      </div>
      <div className="d-flex justify-content-around w-100">
        <div className="d-flex justify-content-between flex-column align-items-center ">
          <div style={{ fontSize: "22px", fontWeight: "600" }}>
            Smart tattoos for improving health
          </div>
          <div
            className="d-flex"
            style={{ fontSize: "14px", gap: "20px", fontWeight: "600" }}
          >
            <span
              style={{
                backgroundColor: "#fff",
                padding: "5px 8px",
                color: "#00b37c",
                borderRadius: "8px",
              }}
            >
              <i className="fas fa-bookmark"></i>
            </span>
            Read More
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-end"
          style={{ width: "60px", height: "60px", borderRadius: "10px" }}
        >
          <i className="fas fa-ellipsis-v"></i>
        </div>
      </div>
    </div>
  );
};
export default MostPopulartTrendingCard;
