import { Button } from "antd";
import { FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";
import useIngredientName from "../../../../customHooks/useIngredientName";
import Config from "../../../../config/index";
import MealPlanBrokenImage from "../../Shared/MealPlanBrokenImage";
const DrawerAddedIngredientListItem = ({ addedIngredient, onRemovePressed }) => {
  const language = useSelector((state) => state.language.lang);
  const { ingredient, unit } = addedIngredient;
  const getIngredientName = useIngredientName();
  const name = getIngredientName(ingredient);
  const gramsPerOneUnit = (unit?.weight_in_grams ?? 0) / (unit?.unit_quantity ?? 1);

  return (
    <div className="drawer-ingredient-list-item added-ing">
      <MealPlanBrokenImage url={addedIngredient.ingredient.image_url} fallbackUrl={Config.ingredientFallbackURL} name={name} className="rounded-circle chat-img" alt={name}/>
      <div className="ing-name">
        <div>{name}</div>
        <div>{language["plain"] ?? "plain"}</div>
      </div>
      <div className="quantity">
        <div>
          {addedIngredient?.quantity ?? ""} {addedIngredient?.unit?.unit_name ?? ""}
        </div>
        <div>
          {(gramsPerOneUnit * addedIngredient.quantity).toLocaleString(Intl.LocalesArgument, { maximumFractionDigits: 2 })}{" "}
          {language["grams"] ?? "grams"}
        </div>
      </div>
      <Button onClick={() => onRemovePressed(addedIngredient)} htmlType="submit" className="add-ing-btn" shape="circle">
        <FaTimes />
      </Button>
    </div>
  );
};

export default DrawerAddedIngredientListItem;
