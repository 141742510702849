import Switch from "../../ReusableComponents/Switch/Switch";
import DiscoverySettingsModal from "./DiscoverySettingsModal";

// Modules import
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import AgeSlider from "./UtilityComponents/AgeSlider";
import actions from "../../redux/fitnessBuddy/actions";
import { AiOutlinePlus } from "react-icons/ai";
import { GoogleMapAPIKEY } from "../../config";
import axios from "axios";
import Loading from "../../components/Loading";
import { Language } from "@amcharts/amcharts4/.internal/core/utils/Language";

const FitnessBuddySettings = () => {
  const language = useSelector((state) => state.language);

  const userDetails = useSelector((state) => state.patientDetails.userDetails);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [distance, setDistance] = useState(userDetails.distance);

  const [modalShow, setModalShow] = useState(false);
  const [isAdult, setIsAdult] = useState(userDetails.isAdult);

  const [isMatchMale, setIsMatchMale] = useState(
    userDetails.matchMeWith === "1" ? true : false
  );
  const [isMatchFemale, setIsMatchFemale] = useState(
    userDetails.matchMeWith === "2" ? true : false
  );
  const [isMatchAll, setIsMatchAll] = useState(
    userDetails.matchMeWith === "3" ? true : false
  );
  const handleMatchMaleChange = (e) => {
    setIsMatchMale(e.target.checked);
    if (e.target.checked) {
      setIsMatchFemale(!e.target.checked);
      setIsMatchAll(!e.target.checked);
    }

    dispatch({
      type: actions.UPDATE_MATCHMEWITH,
      payload: { matchMeWith: "1" },
    });
  };
  const handleMatchFemaleChange = (e) => {
    setIsMatchFemale(e.target.checked);
    if (e.target.checked) {
      setIsMatchMale(!e.target.checked);
      setIsMatchAll(!e.target.checked);
    }

    dispatch({
      type: actions.UPDATE_MATCHMEWITH,
      payload: { matchMeWith: "2" },
    });
  };
  const handleMatchAllChange = (e) => {
    setIsMatchAll(e.target.checked);
    if (e.target.checked) {
      setIsMatchMale(!e.target.checked);
      setIsMatchFemale(!e.target.checked);
    }

    dispatch({
      type: actions.UPDATE_MATCHMEWITH,
      payload: { matchMeWith: "3" },
    });
  };
  const handleDistanceChange = (e) => {
    setDistance(Number(e.target.value));
    dispatch({
      type: actions.UPDATE_DISTANCE,
      payload: { distance: Number(e.target.value) },
    });
  };
  const updateAdultStatus = (e) => {
    setIsAdult(e.target.checked);
    dispatch({
      type: actions.UPDATE_ADULT_STATUS,
      payload: { isAdult: e.target.checked },
    });
  };
  const success = async (position) => {
    const res = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${position.coords.latitude},${position.coords.longitude} &key= ${GoogleMapAPIKEY}`
    );
    if (res.status === 200) setLoading(false);
    dispatch({
      type: actions.UPDATE_LOCATION,
      payload: {
        location: [position.coords.latitude, position.coords.longitude],
        locationName: res.data.results[1].formatted_address,
      },
    });
  };
  const getCurrentLocation = () => {
    // get current location of the user
    setLoading(true);
    navigator.geolocation.getCurrentPosition(success);
  };

  return (
    <div className=" fb-settings-container col md-6">
      <div className="setting">
        <div> {language.lang.settings}</div>
        <div className="is-adult">
          <label className="text">{language.lang.iM18YearsOrOlder}</label>
          <Switch
            isOn={isAdult === null ? false : isAdult}
            name={"isAdult"}
            id={"react-switch-isAdult"}
            handleToggle={updateAdultStatus}
          />
        </div>
      </div>
      <div className="discover-settings">
        <label className="d-setting">{language.lang.discoverySettings}</label>
        <div className="info">
          <div className="location">
            <label className="loc-text">{language.lang.location}</label>
            <div className="loc-details">
              <label className="current-location" onClick={getCurrentLocation}>
                {language.lang.myCurrentLocation}
              </label>
              <button
                type="button"
                className="button-map"
                onClick={() => setModalShow(true)}
              >
                <AiOutlinePlus />
              </button>
            </div>
          </div>
          <div className="selected-location">
            {loading ? <Loading /> : userDetails.locationName}
          </div>
          <DiscoverySettingsModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            backdrop="static"
          />
        </div>
      </div>

      <div className="distance">
        <div className="details">
          <label for="customRange1" className="form-label">
            {language.lang.distance}
          </label>
          <label>{distance}km</label>
        </div>

        <input
          type="range"
          className="distance-slider range-style"
          min="0"
          max="200"
          value={Number(distance)}
          onChange={handleDistanceChange}
          id="customRange1"
        />
      </div>
      <div className="g-text">
        <label>{language.lang.matchMeWith}</label>
      </div>
      <div className="gender">
        <div className="grid">
          <div className="g-col-6 g-col-md-4 male">
            <label style={{ "margin-right": "1rem" }}>
              {language.lang.male}
            </label>
            <Switch
              isOn={isMatchMale}
              name={"isMatchMale"}
              id={"react-switch-isMatchMale"}
              handleToggle={handleMatchMaleChange}
            />
          </div>
          <div className=" g-col-6 g-col-md-4 female">
            <label style={{ "margin-right": "1rem" }}>
              {language.lang.female}
            </label>
            <Switch
              isOn={isMatchFemale}
              name={"isMatchFemale"}
              id={"react-switch-isMatchFemale"}
              handleToggle={handleMatchFemaleChange}
            />
          </div>
          <div className="g-col-6 g-col-md-4 all">
            <label style={{ "margin-right": "1rem" }}>
              {language.lang.all}
            </label>
            <Switch
              isOn={isMatchAll}
              name={"isMatchAll"}
              id={"react-switch-isMatchAll"}
              handleToggle={handleMatchAllChange}
            />
          </div>
        </div>
      </div>
      <div></div>
      <div className="age-slider">
        <AgeSlider min={0} max={100} />
      </div>
      
    </div>
  );
};
export default FitnessBuddySettings;
