import { useFormContext } from "react-hook-form";

const Switch = ({ id, name, isOn, handleToggle }) => {
  const { register } = useFormContext();
  return (
    <>
      <input
        className="react-switch-checkbox"
        id={id}
        type="checkbox"
        onClick={handleToggle}
        {...register(name)}
        name={name}
        checked={isOn}
      />
      <label
        className="react-switch-label"
        htmlFor={id}
        style={{ background: isOn && "#1B68DF" }}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default Switch;
