import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const RecipeTotalsPieChart = ({ carbs, fats, proteins, showPercentageOnChart = true }) => {
  const language = useSelector((state) => state.language);

  // console.log({ showPercentageOnChart });

  useEffect(() => {
    const total = carbs + fats + proteins;
    const ctx = document.getElementById("recipe-totals-pie-chart");
    if (showPercentageOnChart) {
      Chart.register(ChartDataLabels);
    }
    Chart.defaults.scale.grid.display = false;
    Chart.defaults.scale.display = false;
    const dataWithLabels = [
      { value: fats, name: "fats" },
      { value: carbs, name: "carbs" },
      { value: proteins, name: "proteins" },
    ];
    const chart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: [language["fats"] ?? "fats", language["carbs"] ?? "carbs", language["proteins"] ?? "proteins"],
        datasets: [
          {
            // label: "# of Votes",
            data: dataWithLabels.map((item) => item.value),
            backgroundColor: ["#00f49c", "#ffe400", "#ffb400"],
            borderWidth: 0,
            datalabels: showPercentageOnChart
              ? {
                  formatter: function (value, context) {
                    return `${Math.round((value / total) * 100)} %\n${
                      language[dataWithLabels[context.dataIndex].name] ?? dataWithLabels[context.dataIndex].name
                    }`;
                  },
                }
              : null,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: { display: false },
          datalabels: showPercentageOnChart
            ? null
            : {
                labels: {
                  title: "",
                },
              },
          tooltip: {
            enabled: true,
          },
        },
      },
    });
    return () => {
      chart.destroy();
    };
  }, [fats, carbs, proteins, language, showPercentageOnChart]);

  return <canvas width="50" height="50" id="recipe-totals-pie-chart"></canvas>;
};

export default RecipeTotalsPieChart;
