import actions from "./actions";

export const initState = {
  enableAudio: true,
  enableVideo: true,
  remoteStreamIDs: [],
  streams: [],
  agoraClient: null,
  localStream: null,
  remoteVideoStreamID: null,
  sessionTimeEnded: false,
  showChatCard: false,
  practitionerDetails: {},
  expandAttendeesList: true,
  expandChatMessagesList: true,
  session: null,
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.SET_SESSION:
      return Object.assign({}, state, { session: payload });
    case actions.SET_EXPAND_CHAT_MESSAGES_LIST:
      return Object.assign({}, state, { expandChatMessagesList: payload });
    case actions.SET_EXPAND_ATTENDEES_LIST:
      return Object.assign({}, state, { expandAttendeesList: payload });
    case actions.SET_PRACTITIONER_DETAILS:
      return Object.assign({}, state, { practitionerDetails: payload });
    case actions.SET_SHOW_CHAT_CARD:
      return Object.assign({}, state, { showChatCard: payload });
    case actions.REMOVE_STREAM:
      return Object.assign({}, state, {
        streams: state.streams.filter((stream) => {
          const id = String(stream.getId());
          const removeID = String(payload.getId());
          return id !== removeID;
        }),
      });
    case actions.ADD_STREAM:
      return Object.assign({}, state, {
        streams: [...state.streams, payload],
      });
    case actions.SET_SESSION_TIME_ENDED:
      return Object.assign({}, state, {
        sessionTimeEnded: payload,
      });
    case actions.SET_REMOTE_VIDEO_STREAM_ID:
      return Object.assign({}, state, {
        remoteVideoStreamID: payload,
      });
    case actions.REMOVE_REMOTE_STREAM_ID:
      return Object.assign({}, state, {
        remoteStreamIDs: state.remoteStreamIDs.filter(
          (item) => item !== payload
        ),
      });
    case actions.ADD_REMOTE_STREAM_ID:
      return Object.assign({}, state, {
        remoteStreamIDs: [...state.remoteStreamIDs, payload],
      });
    case actions.ENABLE_VIDEO:
      return Object.assign({}, state, {
        enableVideo: payload,
      });
    case actions.ENABLE_AUDIO:
      return Object.assign({}, state, {
        enableAudio: payload,
      });
    case actions.SET_LOCAL_STREAM:
      // console.log("==== SET_LOCAL_STREAM", payload);
      return Object.assign({}, state, {
        localStream: payload,
      });
    case actions.SET_CLIENT:
      return Object.assign({}, state, {
        agoraClient: payload,
      });
    default:
      return state;
  }
};

export default reducer;
