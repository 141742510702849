import Table from 'react-bootstrap/Table';
import { useSelector } from "react-redux";

const LiveSessionDetailPoints = ({ data, className }) => {
  const language = useSelector((state) => state.language);
  return (
    <section className="mt-1 px-4">
      <Table responsive>
        <thead>
          <tr>
            <th>{language.lang.details}</th>
          </tr>
        </thead>
        <tbody>
          {data &&
          data.map((item, i) => {
            return (
              <tr className="list-group-item">
                <div className="d-flex flex-sm-row flex-column w-100 justify-content-start" style={{ cursor: "pointer", height: "min-content", }} key={i}>
                <td>
                  <div className="order1 p1 mt-2 ml-2">
                      <div className="points-green-tag float-left" style={{ textAlign: "center" }}> 
                        {i + 1} 
                      </div>
                  </div>
                </td>
                <td>
                  <div className="order2 p2 mt-2 ml-2">
                      <div className="bd-highlight">{item.keyPointEn}</div>
                  </div>
                </td>
              </div>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </section>
  );
};

export default LiveSessionDetailPoints;
