import { useHistory } from "react-router-dom";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
} from "firebase/auth";
import useHandleFirebaseUserLogin from "../customHooks/useHandleFirebaseUserLogin";
import { showErrorNotification } from "../utility/Helpers";

const GoogleSigninButton = (props) => {
// Critical River, Varun Mishra, Routing Issue, user is not redirected to previous page before login pop-up
  const { nextRoute, practitioner = {}, selectedSlot = {}  } = props;
  const history = useHistory();
  const handleFirebaseUserLogin = useHandleFirebaseUserLogin();

  const signInWithGoogle = async () => {
    const googleProvider = new GoogleAuthProvider();
    const fireAuth = getAuth();
    fireAuth.useDeviceLanguage();
    const scopes = [
      "email",
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ];
    for (const scope of scopes) {
      googleProvider.addScope(scope);
    }
    try {
      const result = await signInWithPopup(fireAuth, googleProvider);
      const additionalUserInfo = getAdditionalUserInfo(result);
      const user = result.user;
      handleFirebaseUserLogin({
        user,
        googleProvider: user.email,
        userCredential: result,
        email: additionalUserInfo?.isNewUser ? user.email : null,
      }).then(() => history.push(nextRoute, {  // Critical River, Varun Mishra, Routing Issue, user is not redirected to previous page before login pop-up
        selectedSlot,
        practitioner,
      }));
    } catch (e) {
      console.log("--- GoogleSigninButton error");
      console.log(e);
      if (e.code !== "auth/popup-closed-by-user") {
        showErrorNotification();
      }
    }
  };

  return (
    <button type="button" className="auth-button" onClick={signInWithGoogle}>
      <i className="fab fa-google" />
    </button>
  );
};

export default GoogleSigninButton;
