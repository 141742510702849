import axios from "./Axios";
import store from "../redux/store";
import { userRoleClient } from "../utility/constants";
import { showErrorNotification } from "../utility/Helpers";

export const getSubscriptionPlan = async ({ categryID = 1, moduleID = 3 }) => {
  try {
    const res = await axios.get("/getSubscriptions", {
      params: { subscriptionCategoryId: categryID, subscriptionModuleId: moduleID },
    });
    return res.data.response[0];
  } catch (e) {
    console.log("--- getSubscriptionPlans error");
    console.log(e);
    return null;
  }
};

export const getRecipes = async ({ id, urlName, userID, comboInput, statusId }) => {
  try {
    const params = {};
    if (id) {
      params["recipeId"] = id;
    }
    if (urlName) {
      params["recipeUrlName"] = urlName;
    }
    if (userID) {
      params["userId"] = userID;
    }
    if (comboInput) {
      params["comboInput"] = comboInput;
    }
    if (statusId) {
      params["statusId"] = statusId;
    }
    const res = await axios.get("/getRecipes", { params });
    return res.data.response;
  } catch (e) {
    console.log("--- getRecipes error");
    console.log(e);
    return null;
  }
};

export const updateRecipe = async ({
  recipeID,
  title,
  description,
  preparationTime,
  cookingTime,
  seringSize,
  recipeCategory,
  recipeCuisine,
  recipeType,
  recipeMeal,
  isKeepsWell,
  isSingleServe,
  isLeftOver,
  recipeImageUrl,
  ingredients,
  recipeActualTotals,
  recipeCalculatedTotals,
  cookingInstructions,
}) => {
  try {
    // debugger;
    const res = await axios.put("/updateRecipe", {
      recipeId: recipeID,
      title,
      description,
      preparationTime,
      cookingTime,
      seringSize,
      recipeCategory,
      recipeCuisine,
      recipeType,
      recipeMeal,
      isKeepsWell,
      isSingleServe,
      isLeftOver,
      recipeImageUrl,
      ingredients,
      recipeActualTotals,
      recipeCalculatedTotals,
      cookingInstructions,
    });
    return res.data.response.id;
  } catch (e) {
    console.log("--- editRecipe error");
    console.log(e);
    return null;
  }
};

export const createRecipe = async ({
  title,
  description,
  preparationTime,
  cookingTime,
  seringSize,
  recipeCategory,
  recipeCuisine,
  recipeType,
  recipeMeal,
  isKeepsWell,
  isSingleServe,
  isLeftOver,
  recipeImageUrl,
  ingredients,
  recipeActualTotals,
  recipeCalculatedTotals,
  cookingInstructions,
  saveAction = "save",
}) => {
  try {
    // debugger;
    const res = await axios.post("/createRecipe", {
      title,
      description,
      preparationTime,
      cookingTime,
      seringSize,
      recipeCategory,
      recipeCuisine,
      recipeType,
      recipeMeal,
      isKeepsWell,
      isSingleServe,
      isLeftOver,
      recipeImageUrl,
      ingredients,
      recipeActualTotals,
      recipeCalculatedTotals,
      cookingInstructions,
      saveAction,
    });
    return res.data.response.id;
  } catch (e) {
    console.log("--- createRecipe error");
    console.log(e);
    return null;
  }
};

export const getIngredients = async ({ searchText, isCustomFilter, statusIDs, userID, limit }) => {
  try {
    const params = { isCustomFilter };
    if (searchText) {
      params["comboInput"] = searchText;
    }
    if (statusIDs) {
      params["statusId"] = statusIDs;
    }
    if (userID) {
      params["userId"] = userID;
    }
    if (limit) {
      params["limit"] = limit;
    }
    const res = await axios.get("/getIngredients", { params });
    return res.data;
  } catch (e) {
    console.log("--- getIngredients error");
    console.log(e);
    return null;
  }
};

export const getFoodAndRecipeCategories = async () => {
  try {
    const res = await axios.get("/getFoodAndRecipeCategories");
    return res.data.response;
  } catch (e) {
    console.log("--- getCuisines error");
    console.log(e);
    return null;
  }
};

export const getCuisines = async () => {
  try {
    const res = await axios.get("/getCuisines");
    return res.data.response;
  } catch (e) {
    console.log("--- getCuisines error");
    console.log(e);
    return null;
  }
};

export const updateUserProfile = async ({
  didSkipMealPlanOnboarding,
  didSkipMealPlanAppointmentBooking,
  didSkipMealPlanTermsAndConditions,
  gender,
  name,
  phone,
  email,
  emiratesID,
  emiratesIDExpiryDate,
  emiratesIDPhotoURL,
  insuranceProvider,
  insuranceNumber,
  dateOfBirth,
  profilePhotoURL,
  phoneCountryCode,
  googleProvider,
  facebookProvider,
  linkedinProvider,
  referralCode,
}) => {
  try {
    const currentUser = store.getState().auth.userData;
    const data = { userId: currentUser.id };
    if (referralCode) {
      data["referralCode"] = referralCode;
    }
    if (gender) {
      data["gender"] = gender;
    }
    if (name) {
      data["name"] = name;
    }
    if (phone && phoneCountryCode) {
      data["phone"] = `${phoneCountryCode}${phone}`;
    }
    if (email) {
      data["email"] = email;
    }
    if (emiratesID) {
      data["emiratesId"] = emiratesID;
    }
    if (emiratesIDExpiryDate) {
      data["emiratesIdExpiryDate"] = emiratesIDExpiryDate;
    }
    if (emiratesIDPhotoURL) {
      data["emiratesIdPhotoUrl"] = emiratesIDPhotoURL;
    }
    if (insuranceProvider) {
      data["insuranceProvider"] = insuranceProvider;
    }
    if (insuranceNumber) {
      data["insuranceNo"] = insuranceNumber;
    }
    if (dateOfBirth) {
      data["dob"] = dateOfBirth;
    }
    if (profilePhotoURL) {
      data["profilePhotoUrl"] = profilePhotoURL;
    }
    if (googleProvider) {
      data["googleProvider"] = googleProvider;
    }
    if (facebookProvider) {
      data["facebookProvider"] = facebookProvider;
    }
    if (linkedinProvider) {
      data["linkedinProvider"] = linkedinProvider;
    }
    if (didSkipMealPlanOnboarding !== undefined) {
      data["isSkippedMealplanDietPreferences"] = didSkipMealPlanOnboarding;
    }
    if (didSkipMealPlanAppointmentBooking !== undefined) {
      data["isSkippedMealplanAppointment"] = didSkipMealPlanAppointmentBooking;
    }
    if (didSkipMealPlanTermsAndConditions !== undefined) {
      data["isSkippedMealplanTermsConditions"] = didSkipMealPlanTermsAndConditions;
    }
    const res = await axios.post("/updatePatientProfile", data);
    return res.data.response.userid !== null && res.data.response.userid !== undefined;
  } catch (e) {
    console.log("--- updateProfile error");
    console.log(e);
    debugger;
    return false;
  }
};

export const firebaseLoginSignup = async ({
  email,
  phoneNumber,
  phoneCountryCode,
  phoneWithoutCountryCode,
  googleProvider,
  facebookProvider,
  linkedinProvider,
  appleProvider,
  userName,
  referralCode,
  corporateId,
  accessToken,
  userRole = userRoleClient,
  isNewUser,
}) => {
  try {
    // console.log("--- accessToken", accessToken);
    const res = await axios.post(
      "/loginSignup",
      {
        email,
        userName,
        phoneNumber: phoneNumber ?? "",
        phoneCode: phoneCountryCode ?? "",
        phoneWithoutCode: phoneWithoutCountryCode ?? "",
        referralCode,
        googleProvider,
        facebookProvider,
        linkedinProvider,
        appleProvider,
        corporateId: corporateId ?? "",
        userRoleName: userRole,
        isNewUser,
      },
      { headers: { Authorization: accessToken ? `Bearer ${accessToken}` : "" } }
    );
    if (res.data.error instanceof String) {
      showErrorNotification(res.data.error);
    }
    if (!res.data.response) return null;
    const authData = res.data.response;
    // const userDetail = res.data.response.user_detail;
    // userDetail.name = userDetail.name ?? userName;
    // userDetail.email = email ?? signinProviderEmail;
    return authData;
  } catch (e) {
    console.log("--- firebaseLoginSignup error");
    console.log(e);
    if (e.response?.data?.error) {
      showErrorNotification(e.response?.data.error);
    }
    return null;
  }
};

export const getCountriesPhoneCodes = async () => {
  try {
    const response = await axios.get("/getCountryPhoneCodes");
    return response.data.response;
  } catch (e) {
    console.log("--- getCountriesPhoneCodes error");
    console.log(e);
    return null;
  }
};

export const getInsuranceProviders = async () => {
  try {
    const response = await axios.get("/getInsuranceProviders");
    return response.data.response;
  } catch (e) {
    console.log("--- getInsuranceProviders error");
    console.log(e);
    return null;
  }
};

export const getInsurancesByClinicID = async ({ clinicID }) => {
  try {
    const response = await axios.get("/getInsuranceProvidersByClinicId", {
      params: { clinicId: clinicID },
    });
    if (response.data.response) {
      return response.data.response
        .filter((item) => {
          return item.insuranceProviders;
        })
        .map((item) => item.insuranceProviders);
    }
    return null;
  } catch (e) {
    console.log("--- getInsurancesByClinicID error");
    console.log(e);
    return null;
  }
};

export const getResidenceStatus = async () => {
  try {
    const response = await axios.get("/getResidents");
    return response.data.response;
  } catch (e) {
    console.log("--- getPatientDetails error");
    console.log(e);
    return null;
  }
};

export const getPatientDetails = async ({ id }) => {
  try {
    const currentUser = store.getState().auth.userData;
    const response = await axios.get("/getPatientDetails", {
      params: { patientId: id || currentUser.id },
    });
    return response.data.response[0];
  } catch (e) {
    console.log("--- getPatientDetails error");
    console.log(e);
    return null;
  }
};

export const updateUserSettings = async ({ regionID, language, theme }) => {
  try {
    const currentUser = store.getState().auth.userData;
    const response = await axios.post("/updateUserGeneralSettings", {
      userId: currentUser.id,
      preferredlanguage: language,
      regionId: regionID,
      theme: theme,
    });
    return !response.data.error;
  } catch (e) {
    console.log("--- updateUserSettings error");
    console.log(e);
    return null;
  }
};

export const getScheduleTypes = async () => {
  try {
    const response = await axios.get("/getSchedulerTypes");
    return response.data.response;
  } catch (e) {
    console.log("--- getScheduleTypes error");
    console.log(e);
    return null;
  }
};
