import { showErrorNotification } from "../../utility/Helpers";
import { mediaNotSupportedErrorMsg } from "../../utility/constants";

const actions = {
  START_RECORDING: "START_RECORDING",
  STOP_RECORDING: "STOP_RECORDING",
  ADD_RECORD_CHUNKS: "ADD_RECORD_CHUNKS",
  DELETE_RECORD: "DELETE_RECORD",
  NEED_TO_CALL_ON_CHANGE_FALSE: "NEED_TO_CALL_ON_CHANGE_FALSE",
  NEED_TO_CALL_ON_CHANGE_TRUE: "NEED_TO_CALL_ON_CHANGE_TRUE",
};

export const needToCallOnChangeFalseAction = () => {
  return {
    type: actions.NEED_TO_CALL_ON_CHANGE_FALSE,
  };
};

export const needToCallOnChangeTrueAction = () => {
  return {
    type: actions.NEED_TO_CALL_ON_CHANGE_TRUE,
  };
};

export const deleteRecordAction = () => {
  return {
    type: actions.DELETE_RECORD,
  };
};

export const addRecordChunksAction = (chunk, state) => {
  return {
    type: actions.ADD_RECORD_CHUNKS,
    payload: chunk,
  };
};

export const startRecordingAction = async (dispatch, state) => {
  const audioMediaStream = await getAudioMediaStream();
  const audioMediaRecorder = new MediaRecorder(audioMediaStream);
  audioMediaRecorder.ondataavailable = (e) => {
    dispatch(addRecordChunksAction(e.data, state));
  };
  audioMediaRecorder.start();
  return {
    type: actions.START_RECORDING,
    payload: { audioMediaStream, audioMediaRecorder },
  };
};

export const stopRecordingAction = () => {
  return {
    type: actions.STOP_RECORDING,
  };
};

const getAudioMediaStream = async () => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    showErrorNotification(mediaNotSupportedErrorMsg);
    return null;
  }
  try {
    const audioMediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    return audioMediaStream;
  } catch (e) {
    console.log(e);
    showErrorNotification();
    return null;
  }
};

export default actions;
