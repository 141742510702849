import { useEffect } from "react";
import { useDispatch } from "react-redux";
import LandingBase from "../../../components/LandingComponents/LandingBase";
import ReviewCarousel from "../../../components/LandingComponents/ReviewCarousal";
import { setHomeBanner } from "../../../redux/patient/actionCreators";
// import * as Routes from "../../../routes";
// import { showWarningNotification } from "../../../utility/Helpers";
import PlayStoreLinkButton from "../../../components/PlayStoreLinkButton";
import AppStoreLinkButton from "../../../components/AppStoreLinkButton";

const MealPlanLanding = () => {
  // const history = useHistory();
  //const dispatch = useDispatch();
  // const login = useSelector((state) => state.auth.login);
  // const language = useSelector((state) => state.language);

  // const SelectTab = (type) => {
  //   switch (type) {
  //     case "mostpopular":
  //       return Routes.dashboardMostPopularNewRoute.path;
  //     case "fitness":
  //       return Routes.dashboardFitnessRoute.path;
  //     case "mealplan":
  //       return Routes.dashboardMealPlanRoute.path;
  //     case "view-matches":
  //       return Routes.fitnessBuddyRoute.path;
  //     case "customer-live-sessions-list":
  //       return Routes.dashboardLiveSessionRoute.path;
  //     default:
  //       return Routes.dashboardBookConsultationRoute.path;
  //   }
  // };

  // // useEffect(() => {
  // //   dispatch(setHomeBanner());
  // // }, [dispatch]);

  // const handleViewMatchesClicked = () => {
  //   if (!login) {
  //     showWarningNotification(language.lang.pleaseLoginToUseFitConnect);
  //     history.push(Routes.clientSignInRoute.path, {
  //       nextRoute: Routes.viewMatchesRoute.path,
  //     });
  //   } else {
  //     history.push(Routes.viewMatchesRoute.path);
  //   }
  // };

  // const pushTab = (type) => {
  //   const pageRoute = SelectTab(type);
  //   if (pageRoute === Routes.fitnessBuddyRoute.path) {
  //     return handleViewMatchesClicked();
  //   }
  //   history.push(type);
  // };

  return (
    <LandingBase>
      <div className="row meal-plan-landing mx-0">
        <div className="col-sm-12 col-md-12 col-lg-5 video-container px-0">
          <div className="d-flex flex-row  p-2 welcome-header">
            <div className="d-flex justify-content-start">
              Welcome to IZI Health
            </div>
          </div>
          <div className="tolldiv">
            <iframe
              title="meal-plan-landing-iframe"
              className="tollframe-mp"
              src="https://devmindminastorage.blob.core.windows.net/video/LandingPage/Mealplans.mp4"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              style={{backgroundColor:'#FFF'}}
              allowFullScreen

            ></iframe>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-7 px-0">
          <div className="row mx-0">
            <div className="setone">
              <p className="setone-header mt-2 mb-3">
                Hurray ! Make meal plans in easy steps
              </p>
              <div className="row mx-0">
                <ul className="ml-0 ml-md-3">
                  <li className="mt-3 mb-3 set-list-design">
                    <span>
                      <i className="fas fa-check-circle"></i>
                    </span>
                    <span className="ml-3">Make Easy Mealplans</span>
                  </li>
                  <li className="mt-3 mb-3 set-list-design">
                    <span>
                      <i className="fas fa-check-circle"></i>
                    </span>
                    <span className="ml-3">
                      Add Ingredients to Shopping Cart
                    </span>
                  </li>
                  <li className="mt-3 mb-3 set-list-design">
                    <span>
                      <i className="fas fa-check-circle"></i>
                    </span>
                    <span className="ml-3">For Health Professionals</span>
                  </li>
                </ul>
              </div>
              <div className="d-flex justify-content-start">
                <p className="para-text">
                  IZI Health creates personalized meal plans based on your food
                  preferences, budget, and schedule. Reach your diet and
                  nutritional goals with our calorie calculator, weekly meal
                  plans, grocery lists and more. Install our app from App Store
                  or Play Store to create your own recipies and Ingredients.
                </p>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-sm-12 col-md-12 col-lg-10 no-gutters px-0">
              <div className="d-flex justify-content-center">
                <PlayStoreLinkButton />
                <AppStoreLinkButton />
              </div>
              <p className="setone-header">What our users say</p>
              <div className="carousel-align">
                <ReviewCarousel />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingBase>
  );
};

export default MealPlanLanding;

// Inserted by Mahi
// import { useEffect } from "react";
// import { useDispatch } from "react-redux";
// import LandingBase from "../../../components/LandingComponents/LandingBase";
// import ReviewCarousel from "../../../components/LandingComponents/ReviewCarousal";
// import { setHomeBanner } from "../../../redux/patient/actionCreators";
// // import * as Routes from "../../../routes";
// // import { showWarningNotification } from "../../../utility/Helpers";
// import { useState } from "react";
// import { useSelector } from "react-redux";
// import { mealPlanOnboardingRoute } from "../../../routes";
// // import BMRcalories from "./BMRcalories";
// import { getDietTypes } from "../../../services/MealPlanNetworkService";
// import axios from "../../../services/Axios";

// const MealPlanLanding = () => {

//   const language = useSelector((state) => state.language.lang);
//   const [selectedGoal, setSelectedGoal] = useState();
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const dietTypes = await getDietTypes();
//         setSelectedGoal(dietTypes)
//         // Handle dietTypes data here
//       } catch (error) {
//         // Handle errors
//         console.error('Error fetching diet types:', error);
//       }
//     };

//     fetchData();
//   }, []);
//   const handleGoalClicked = (goal) => {
//     // console.log(goal)
//     setSelectedGoal(goal)
//     // history.push(`${mealPlanOnboardingRoute.path}/diet-types`, { dietGoal: goal });
//   };

//   return (
//     <LandingBase>
//       <section className="meal-paln-calories">
//         <div className="diet-goals">
//           {selectedGoal?.map((item) => {
//             return <DietGoalItem key={item.id} onClick={() => handleGoalClicked(item)} goal={item} isSelected={selectedGoal === item} />;
//           })}
//         </div>
//         <div>
//           {/* <BMRcalories selecteditem={selectedGoal?.id}/> */}
//         </div>
//       </section>
//     </LandingBase>
//   );
// };
// const DietGoalItem = ({ goal, onClick, isSelected }) => {
//   const languagePrefs = useSelector((state) => state.language);
//   const currentLang = languagePrefs.general.preferredlanguage;

//   let name;
//   let image = goal.image_url;
//   if (currentLang === "ar") {
//     name = goal?.name_ar ?? goal?.name;
//   } else if (currentLang === "ku") {
//     name = goal?.name_ku ?? goal.name;
//   } else {
//     name = goal?.name;
//   }
//   return (
//     <>
//       <div onClick={onClick} className={`diet-goal-item ${isSelected ? 'selected-item' : ''}`}>
//         <div className="description"> {image && <img src={image} alt={name} />} </div>
//         <div className="name">{name}</div>
//       </div>

//     </>
//   );
// };

// export default MealPlanLanding;
