import axios from "axios";
import { settings } from "../../config";
import actions from "./actions";

export const setGalleryMedia = () => {
    return async (dispatch) => {
        const res = await axios.get(`${settings.apiBaseURL}/getGalleryMedia`);
        dispatch({
          type: actions.SET_GALLERY_MEDIA,
          payload: res ? res.data.response : null,
        });
      };
  };
  
export const setSingleFeature = (offset) => {
  return async (dispatch) => {
    const res = await axios.get(`${settings.apiBaseURL}/getSingleFeatures?offset=${offset}`);
    dispatch({
      type: actions.SET_SINGLE_FEATURES,
      payload: res ? res.data.response : null,
    });
  };
}