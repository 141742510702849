import axios from "../../services/Axios";
import { userDataStorageKey } from "../../utility/constants";

const actions = {
  LOADING: "LOADING",
  STOP_LOADING: "STOP_LOADING",
  UPDATE_CONSULTATION_STATUS: "UPDATE_CONSULTATION_STATUS",
};

export const stopLoadingAction = () => {
  return {
    type: actions.STOP_LOADING,
  };
};

export const startLoadingAction = () => {
  return {
    type: actions.LOADING,
  };
};

export const updateConsultationStatusAction = async (
  appointmentID,
  statusId = "14",
  isCallEndedBeforeTime = false
) => {
  if (!appointmentID) return null;
  try {
    const userData = JSON.parse(localStorage.getItem(userDataStorageKey));
    const res = await axios.post("/updateConsultationStatus", {
      appointmentId: appointmentID,
      statusId: statusId,
      isCallEndedBeforeTime,
      userId: userData.id,
      userRole: userData.role,
    });
    return res.data.response;
  } catch (e) {
    debugger;
    console.log("--- update appointment error");
    console.log(e);
    return null;
  }
};

export default actions;
