import axios from "axios";
import { createRef, useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { settings } from "../config";
import { MostPopularCardDashRoute } from "../routes";
import { showSuccessNotification } from "../utility/Helpers";
import Loading from "./Loading";
import VideoCard from "./VideoCard";
import moment from "moment";

const FitnessCardList = ({ id, type, live }) => {
  const [allrecipe, setallrecipe] = useState([]);
  const [flag, setflag] = useState(false);
  const [pageLoading, setpageLoading] = useState(false);
  const [offset, setoffset] = useState(0);
  const [limit, setlimit] = useState(15);
  const history = useHistory();
  useEffect(() => {
    !flag && !live && FetchRecipes(offset);
  }, [id]);
  const doneLoading = flag;

  useEffect(() => {}, [flag]);

  useEffect(() => {
    if (live) {
      FetchLive();
    }
  }, [live]);

  const FetchRecipes = async (off) => {
    await axios
      .get(
        `${settings.apiBaseURL}/getSingleFeatureByCategory?categoryIds=${id}&offset=${off}&limit=${limit}`
      )
      .then((response) => {
        setallrecipe((prevState) => [...prevState, ...response.data.response]);
        if (response.data.response.length === 0) {
          // Todo- No need of show this messages
          //showSuccessNotification(`End of ${!!type? type :""} Features`)
        }
      })
      .catch((error) => {
        console.log(error.response?.status);
      })
      .finally(() => {
        setflag(true);
      });
    setpageLoading(false);
  };

  const FetchLive = async () => {
    const today = moment().format("YYYY-MM-DD");
    const nextMonth = moment(today, "YYYY-MM-DD")
      .add(30, "days")
      .format("YYYY-MM-DD");
    await axios
      .get(
        // `${settings.apiBaseURL}/getSingleFeatureByCategory?categoryIds=${id}&offset=${off}&limit=${limit}`
        `${settings.apiBaseURL}/getLiveSessions?startDate=${today}&endDate=${nextMonth}`
      )
      .then((response) => {
        setallrecipe((prevState) => [
          ...prevState,
          ...response.data.response.liveSessions,
        ]);
        // debugger;
        if (response.data.response.liveSessions.length === 0) {
          // Todo- No need of show this messages
          //showSuccessNotification(`End of ${!!type? type :""} Features`)
        }
      })
      .catch((error) => {
        console.log(error.response?.status);
      })
      .finally(() => {
        setflag(true);
      });
    setpageLoading(false);
  };

  const routeRecipe = (recipe) => {
    history.push(MostPopularCardDashRoute.path, {
      list: recipe,
      listid: id,
      pagetitle: type,
    });
  };

  const handleScroll = (event) => {
    setpageLoading(true);
    const target = event.target;
    if (target.scrollWidth - target.scrollLeft === target.clientWidth) {
      FetchRecipes(offset + limit);
      setoffset(offset + limit);
      if (limit === 15) {
        setlimit(5);
      }
    }
  };
  const featRef = createRef();
  const scrollTo = (direction) => {
    if (direction == "left") {
      if (featRef) {
        featRef.current.scrollLeft -= featRef.current.clientWidth;
      }
    } else {
      if (featRef) {
        featRef.current.scrollLeft += featRef.current.clientWidth;
        setpageLoading(true);
        if (
          featRef.current.scrollWidth - featRef.current.scrollLeft ===
          featRef.current.clientWidth
        ) {
          FetchRecipes(offset + limit);
          setoffset(offset + limit);
          if (limit === 15) {
            setlimit(5);
          }
        }
      }
    }
  };

  let content;
  if (!doneLoading) {
    content = <Loading />;
  } else if (!allrecipe) {
    content = <h5>Something went wrong</h5>;
  } else if (allrecipe.length === 0) {
    content = <h5>No sessions found</h5>;
  } else {
    content = (
      <div
        className="py-4 pt-0s d-flex most-popular-scroll custom-scroll overflow-hidden"
        style={{ gap: "30px", minWidth: "100%" }}
        onScroll={handleScroll}
        ref={featRef}
      >
        {allrecipe.map((item) => {
          return (
            <div
              className="customer-dashboard-item-card d-flex justify-content-around w-100 flex-grow-1"
              style={{
                cursor: "pointer",
                maxWidth: "min-content",
              }}
              key={item.id}
              onClick={() => routeRecipe(item)}
            >
              <VideoCard data={item} liveCard={live} />
            </div>
          );
        })}
        {pageLoading && (
          <div className="d-flex justify-content-center align-items-center">
            <Loading />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="position-relative overflow-hidden w-100">
      {/* {console.log("all recipe", allrecipe)} */}
      {content}
      {/* {allrecipe.length > 0 ? (
        
      ) : (
        <Loading width={100} height={100} />
      )} */}
      {allrecipe.length > 0 && (
        <>
          <div
            className="leftScroll scrollBtn"
            onClick={() => scrollTo("left")}
          >
            <FiChevronLeft />
          </div>
          <div
            className="rightScroll scrollBtn"
            onClick={() => scrollTo("right")}
          >
            <FiChevronRight />
          </div>
        </>
      )}
    </div>
  );
};
export default FitnessCardList;
