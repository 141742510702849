import React from "react";
import Modal from "react-bootstrap/Modal";

const SessionTimeEndedModal = ({ show, onHide, onYesClicked }) => {
  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header>
        <Modal.Title className="d-flex justify-content-between">
          Session time has ended.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Would you like to leave this page ?</p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-around">
        <button onClick={onHide} className="btn btn-primary">
          No
        </button>
        <button onClick={onYesClicked} className="btn btn-primary">
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SessionTimeEndedModal;
