import { useHistory } from "react-router-dom";
import {
  getAuth,
  signInWithPopup,
  FacebookAuthProvider,
  getAdditionalUserInfo,
} from "firebase/auth";
import useHandleFirebaseUserLogin from "../customHooks/useHandleFirebaseUserLogin";
import { showErrorNotification } from "../utility/Helpers";

const FacebookSigninButton = (props) => {
  // Critical River, Varun Mishra, Routing Issue, user is not redirected to previous page before login pop-up
  const { nextRoute, practitioner = {}, selectedSlot = {}  } = props;
  const history = useHistory();
  const handleFirebaseUserLogin = useHandleFirebaseUserLogin();

  const signInWithFacebook = async () => {
    const facebookProvider = new FacebookAuthProvider();
    const fireAuth = getAuth();
    fireAuth.useDeviceLanguage();
    try {
      const result = await signInWithPopup(fireAuth, facebookProvider);
      const additionalUserInfo = getAdditionalUserInfo(result);
      const user = result.user;
      handleFirebaseUserLogin({
        user,
        facebookProvider: user.email,
        userCredential: result,
        email: additionalUserInfo?.isNewUser ? user.email : null,
      }).then(() => history.push(nextRoute, {     // Critical River, Varun Mishra, Routing Issue, user is not redirected to previous page before login pop-up
        selectedSlot,
        practitioner,
      }));
    } catch (e) {
      console.log("--- FacebookSigninButton error");
      console.log(e);
      //   debugger;
      if (e.code !== "auth/popup-closed-by-user") {
        showErrorNotification();
      }
    }
  };

  return (
    <button type="button" className="auth-button" onClick={signInWithFacebook}>
      <i className="fab fa-facebook-f" />
    </button>
  );
};

export default FacebookSigninButton;
