const actions = {
  ADD_REMOTE_STREAM_ID: "ADD_REMOTE_STREAM_ID",
  REMOVE_REMOTE_STREAM_ID: "REMOVE_REMOTE_STREAM_ID",
  ENABLE_AUDIO: "ENABLE_AUDIO",
  ENABLE_VIDEO: "ENABLE_VIDEO",
  DISABLE_AUDIO: "DISABLE_AUDIO",
  DISABLE_VIDEO: "DISABLE_VIDEO",
  SET_CLIENT: "SET_CLIENT",
  SET_LOCAL_STREAM: "SET_LOCAL_STREAM",
  SET_REMOTE_VIDEO_STREAM_ID: "SET_REMOTE_VIDEO_STREAM_ID",
  SET_SESSION_TIME_ENDED: "SET_SESSION_TIME_ENDED",
  ADD_STREAM: "ADD_STREAM",
  REMOVE_STREAM: "REMOVE_STREAM",
  SET_SHOW_CHAT_CARD: "SET_SHOW_CHAT_CARD",
  SET_PRACTITIONER_DETAILS: "SET_PRACTITIONER_DETAILS",
  SET_EXPAND_ATTENDEES_LIST: "SET_EXPAND_ATTENDEES_LIST",
  SET_EXPAND_CHAT_MESSAGES_LIST: "SET_EXPAND_CHAT_MESSAGES_LIST",
  SET_SESSION: "SET_SESSION",
};

export const seSessionAction = ({ session }) => {
  return {
    type: actions.SET_SESSION,
    payload: session,
  };
};

export const setExpandChatMessagesList = ({ expanChatMessagesList }) => {
  return {
    type: actions.SET_EXPAND_CHAT_MESSAGES_LIST,
    payload: expanChatMessagesList,
  };
};

export const setExpandAttendeesListAction = ({ expandAttendeesList }) => {
  return {
    type: actions.SET_EXPAND_ATTENDEES_LIST,
    payload: expandAttendeesList,
  };
};

export const setPractitionerDetails = ({ practitionerDetails }) => {
  return {
    type: actions.SET_PRACTITIONER_DETAILS,
    payload: practitionerDetails,
  };
};

export const setShowChatCard = ({ showChatCard }) => {
  return {
    type: actions.SET_SHOW_CHAT_CARD,
    payload: showChatCard,
  };
};

export const addStreamAction = ({ stream }) => {
  return {
    type: actions.ADD_STREAM,
    payload: stream,
  };
};

export const removeStreamAction = ({ stream }) => {
  return {
    type: actions.REMOVE_STREAM,
    payload: stream,
  };
};

export const setSessionTimeEndedAction = ({ timeEnded }) => {
  return {
    type: actions.SET_SESSION_TIME_ENDED,
    payload: timeEnded,
  };
};

const setRemoteVideoStreamIDAction = ({ streamID }) => {
  return {
    type: actions.SET_REMOTE_VIDEO_STREAM_ID,
    payload: streamID,
  };
};

const setClientAction = (client) => {
  return {
    type: actions.SET_CLIENT,
    payload: client,
  };
};

const setLocalStreamAction = (stream) => {
  return {
    type: actions.SET_LOCAL_STREAM,
    payload: stream,
  };
};

const enableVideoAction = (enabled) => {
  return {
    type: actions.ENABLE_VIDEO,
    payload: enabled,
  };
};

const enableAudioAction = (enabled) => {
  return {
    type: actions.ENABLE_AUDIO,
    payload: enabled,
  };
};

const removeRemoteStreamIDAction = (id) => {
  return {
    payload: id,
    type: actions.REMOVE_REMOTE_STREAM_ID,
  };
};

const addRemoteStreamIDAction = (id) => {
  return {
    payload: id,
    type: actions.ADD_REMOTE_STREAM_ID,
  };
};

export {
  addRemoteStreamIDAction,
  removeRemoteStreamIDAction,
  enableAudioAction,
  enableVideoAction,
  setClientAction,
  setLocalStreamAction,
  setRemoteVideoStreamIDAction,
};
export default actions;
