import { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetTopPractitionersAction,
  setTopPractitionersAction,
} from "../redux/patient/actionCreators";
import TopDoctorCards from "./TopDoctorCards";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import axios from "../services/Axios";
import { showErrorNotification } from "../utility/Helpers";
import { Instagram } from "react-content-loader";

const TopOnlineDoctors = () => {
  const TopPractitioner = useSelector(
    (state) => state.patient.top_practitioner
  );
  const docRef = useRef();
  const loadingContainerRef = useRef();
  const currentUser = useSelector((state) => state.auth.userData);
  const language = useSelector((state) => state.language);
  // const offset = useSelector((state) => state.patient.docoffset);
  const [showLoading, setShowLoading] = useState(true);
  // const [loadingNewPage, setLoadingNewPage] = useState(false);
  // const [intersectionObserver, setIntersectionObserver] = useState(null);
  // const limit = 10;
  const dispatch = useDispatch();
  const regionID = language.regionID;
  // console.log("--- offset", offset);
  // const firstUpdate = useRef(true);

  const fetchTopDoctors = useCallback(async () => {
    // if (!enteries[0].isIntersecting) return;
    // console.log("--- fetchTopDoctors", enteries);
    // if (loadingNewPage) return;
    // setLoadingNewPage(true);
    dispatch(resetTopPractitionersAction());
    setShowLoading(true);
    try {
      // debugger;
      const response = await axios.get("/getTopPractitioners", {
        params: {
          // Varun Mishra, Critical River, show all doctors for newly added regions other than UAE, IRAQ and EGYPT
          // regionId: regionID,
          regionId: (regionID < 4) ? regionID : null,
          userGroup: currentUser ? currentUser.user_group : "production",
          offset: 0,
          limit: 50,
        },
      });
      dispatch(
        setTopPractitionersAction({ practitioners: response.data.response })
      );
      // dispatch(setOffsetTopDoctors(offset + limit));
    } catch (e) {
      console.log("--- getTopPractitioners error");
      console.log(e);
      showErrorNotification();
    } finally {
      setShowLoading(false);
      // setLoadingNewPage(false);
    }
  }, [dispatch, currentUser, regionID]);

  // useEffect(() => {

  // }, [regionID, currentUser, dispatch]);

  useEffect(() => {
    // const options = {
    //   root: docRef.current,
    //   rootMargin: "0px",
    //   threshold: 0,
    // };
    // const observer = new IntersectionObserver(fetchTopDoctors, options);
    // setIntersectionObserver(observer);
    // return () => {
    //   observer.disconnect();
    // };
    // debugger;
    fetchTopDoctors();
  }, [fetchTopDoctors]);

  // useEffect(() => {
  //   if (!intersectionObserver) return;
  //   intersectionObserver.observe(loadingContainerRef.current);
  // }, [intersectionObserver]);

  // useEffect(() => {
  //   getNew(offset);
  // }, []);

  // const getNew = useCallback(async () => {
  //   await dispatch(getTopPractitioners(offset + limit, limit));
  //   // setShowLoad(values.length !== 0);
  //   setShowLoading(false);
  //   setLoadingNewPage(false);
  // }, [dispatch, offset]);

  // useEffect(() => {
  //   dispatch(setOffsetTopDoctors(offset + limit));
  // }, [dispatch, TopPractitioner, offset]);

  // const handleScroll = (event) => {
  //   setLoading(true);
  //   const target = event.target;
  //   if (target.scrollWidth - target.scrollLeft === target.clientWidth) {
  //     dispatch(setOffsetTopDoctors(offset + limit));
  //     getNew(offset + limit);
  //   }
  // };

  const scrollTo = (direction) => {
    if (!docRef) return;
    const target = docRef.current;
    if (direction === "left") {
      docRef.current.scrollLeft -= target.clientWidth;
    } else {
      docRef.current.scrollLeft += target.clientWidth;
      // if (showLoad) setLoading(true);
      // if (
      //   target.scrollWidth - Math.round(target.scrollLeft) ===
      //   target.clientWidth
      // ) {
      //   dispatch(setOffsetTopDoctors(offset + limit));
      //   getNew(offset + limit);
      // }
    }
  };

  return (
    <div className="home-top-online-doctors position-relative w-100">
      <div
        className={`${
          showLoading && TopPractitioner.length === 0 ? "d-none" : ""
        } list-container d-flex items-list h-list custom-scroll pb-lg-2 scroll-snap-parent-x`}
        // onScroll={handleScroll}
        ref={docRef}
      >
        <div className="px-3 d-none d-md-block"></div>

        {(TopPractitioner || []).map((item, i) => {
          return (
            <div
              key={i}
              className="d-flex items-list pb-lg-2 px-1 w-100 scroll-snap card-list-gap"
            >
              <TopDoctorCards
                currentSelectedRegion={regionID}
                className="most-popular-item"
                info={item}
              />
            </div>
          );
        })}

        <div
          ref={loadingContainerRef}
          className="loading-container d-flex justify-content-center align-items-center w-100"
        >
          {showLoading && <Instagram className="insta-skeleton" />}
        </div>
        <div className="px-3 d-none d-md-block"></div>
      </div>
      {TopPractitioner.length > 0 && (
        <>
          <div
            className="leftScroll scrollBtn"
            onClick={() => scrollTo("left")}
          >
            <FiChevronLeft />
          </div>
          <div
            className="rightScroll scrollBtn"
            onClick={() => scrollTo("right")}
          >
            <FiChevronRight />
          </div>
        </>
      )}
    </div>
  );
};

export default TopOnlineDoctors;
