import { useState, useEffect } from "react";

import {
  validEmailErrorMsg,
  passwordLengthErrorMsg,
  defaultErrorMsg,
  isLoggedInStorageKey,
  tokenStorageKey,
  userDataStorageKey,
  refreshTokenStorageKey,
  tokenExpiryTimeStorageKey,
  passwordContainSpecialCharacterErrorMsg,
  passwordContainLowerCaseLetterErrorMsg,
  passwordContainNumberErrorMsg,
  passwordContainUpperCaseLetterErrorMsg,
  paymentAmountsKey,
  regionCurrencyMap,
} from "./constants";
import { Store } from "react-notifications-component";
import axios from "../services/Axios";
import NotificationItem from "../components/NotificationItem";
import { settings } from "../config";
import ModalNotificationItem from "../components/ModalNotificationItem";
import store from "../redux/store";

export const isMobileOrTabletBrowser = () => {
  const windowWidth = window.innerWidth;
  return windowWidth < 1024;
  // let check = false;
  // (function (a) {
  //   if (
  //     /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
  //       a
  //     ) ||
  //     /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
  //       a.substr(0, 4)
  //     )
  //   )
  //     check = true;
  // })(navigator.userAgent || navigator.vendor || window.opera);
  // return check;
};

export const getUserMediaPermissions = async ({
  video = true,
  audio = true,
}) => {
  const language = store.getState().language.lang;
  try {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      video: video,
      audio: audio,
    });
    // if (!mediaStream.active) {
    //   showErrorNotification(language.couldNotGetAudioAndVideoPermissions);
    //   return false;
    // }
    if (video && mediaStream.getVideoTracks().length === 0) {
      showErrorNotification(
        language.couldNotGetVideoPermission || "Could not get camera permission"
      );
      return false;
    }
    if (audio && mediaStream.getAudioTracks().length === 0) {
      showErrorNotification(
        language.couldNotGetAudioPermission ||
          "Could not get microphone permission"
      );
      return false;
    }
    return true;
  } catch (e) {
    console.log("--- getUserMedia error");
    console.log(e);
    showErrorNotification(language.couldNotGetAudioAndVideoPermissions);
    return false;
  }
};

export const GetPractitionerCurrencyCodeAndFees = ({
  selectedRegionID,
  feesWithMultiCurrency,
}) => {
  const [regionData, setRegionData] = useState({});
  const getRegions = async () => {
    let obj = {};
    const regionsResponse = await axios.get("/getRegions");
    const data = regionsResponse.data.response;
    data.forEach((region) => {
        let key = region.id;
      obj[key] = region.defaultCurrency.code;
    });
    if (Object.keys(obj).length) {
      setRegionData(obj);
    }
  };
  useEffect(() => {
    getRegions();
  }, []);
  const currencyCode = regionData[selectedRegionID];

  let practitionerFees = (feesWithMultiCurrency || {})[currencyCode] || 0;
  if(selectedRegionID === 2 || currencyCode === 'IQD') {
    practitionerFees = 10000;
  }
  return { currencyCode, practitionerFees };
};

export const getAppointmentDetails = async (id) => {
  try {
    const res = await axios.get(
      `${settings.apiBaseURL}/getAppointmentDetails`,
      {
        params: { appointmentId: id },
      }
    );
    return res.data.response[0];
  } catch (e) {
    console.log("--- /getAppointmentDetails error", e);
    return null;
  }
  // setAppointmentDetails(res.data.response[0]);
};
export const getWalkinRequestedAppointments = async () => {
  let data = "";
  try {
    const res = await axios.get(
      `${settings.apiBaseURL}/getWalkinRequestedAppointments`
    );
    data = res.data.response;
  } catch (e) {
    console.log("--- Some error while fetching walkin req", e);
    return null;
  } finally {
    if (data === "No New Request") return;
    return data;
  }
  // setAppointmentDetails(res.data.response[0]);
};
export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return "Now";
};

export const getAuthDataFromResponse = (response) => {
  const accessToken = response.access_token;
  const refreshToken = response.refresh_token;
  const tokenExpiryTime = response.expires_in;
  const userData = response.user_detail[0] ?? response.user_detail;
  const paymentAmounts =
    response.appointment_amounts ||
    (localStorage.getItem(paymentAmountsKey)
      ? JSON.parse(localStorage.getItem(paymentAmountsKey))
      : null);
  const authData = {
    accessToken,
    refreshToken,
    tokenExpiryTime,
    userData,
    paymentAmounts,
  };
  return authData;
};

export const storeAuthDataInLocalStorage = (response) => {
  const { refreshToken, tokenExpiryTime, userData, paymentAmounts } = response;
  const accessToken = response?.accessToken ?? response?.access_token;
  localStorage.setItem(isLoggedInStorageKey, true);
  localStorage.setItem(tokenStorageKey, accessToken);
  localStorage.setItem(userDataStorageKey, JSON.stringify(userData));
  localStorage.setItem(refreshTokenStorageKey, refreshToken);
  localStorage.setItem(tokenExpiryTimeStorageKey, tokenExpiryTime);
  if (paymentAmounts) {
    localStorage.setItem(paymentAmountsKey, JSON.stringify(paymentAmounts));
  }
  const authData = {
    accessToken,
    refreshToken,
    tokenExpiryTime,
    userData,
    paymentAmounts,
  };
  return authData;
};

export const uploadFile = async (
  // storageType,
  // category,
  // userType,
  // files,
  // userID,
  // fileType
  data
) => {
  try {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    const response = await axios.post(`/uploadFile`, formData);
    return response.data.response[0];
  } catch (e) {
    console.log("--- uploadFile error");
    console.log(e);
    return null;
  }
};

const notificationsSettings = {
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 7000,
    pauseOnHover: true,
    click: true,
  },
};

const ModalNotificationSettings = {
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 0,
    click: false,
    touch: false,
    showIcon: false,
  },
};

export const showWarningNotification = (title) => {
  Store.addNotification({
    ...notificationsSettings,
    // title: title,
    // message: message,
    content: <NotificationItem type="warning" text={title} />,
  });
};

export const showSuccessNotification = (title, component) => {
  Store.addNotification({
    ...notificationsSettings,
    content: (
      <NotificationItem type="success" text={title} component={component} />
    ),
  });
};

export const showErrorNotification = (title = defaultErrorMsg) => {
  Store.addNotification({
    ...notificationsSettings,
    // title: title,
    // message: message || defaultErrorMsg,
    content: <NotificationItem type="danger" text={title} />,
  });
};

export const ModalNotification = (title = defaultErrorMsg, type) => {
  Store.addNotification({
    ...ModalNotificationSettings,
    content: <ModalNotificationItem text={title} type={type} />,
  });
};

const ellipsis = (text, size) => {
  return `${text.split(" ").slice(0, size).join(" ")}...`;
};

const isValidEmail = (email) => {
  if (email.length < 4) return validEmailErrorMsg;
  if (email.indexOf("@") === -1 || email.indexOf(".") === -1) {
    return validEmailErrorMsg;
  }
  return null;
};

const isValidPassword = (password) => {
  if (password.length < 8) {
    return passwordLengthErrorMsg;
  }
  const lowerCaseLetters = /[a-z]/g;
  if (!password.match(lowerCaseLetters)) {
    return passwordContainLowerCaseLetterErrorMsg;
  }
  const upperCaseLetters = /[A-Z]/g;
  if (!password.match(upperCaseLetters)) {
    return passwordContainUpperCaseLetterErrorMsg;
  }
  const numbers = /[0-9]/g;
  if (!password.match(numbers)) {
    return passwordContainNumberErrorMsg;
  }
  const specialChars = /[!@#$%^&*]/g;
  if (!password.match(specialChars)) {
    return passwordContainSpecialCharacterErrorMsg;
  }
  return null;
};

export { ellipsis, isValidEmail, isValidPassword };

export const arr = [
  {
    id: 1,
    feature_description: "Most Popular",
  },
  {
    id: 3,
    feature_description: "Fitness",
  },
  {
    id: 4,
    feature_description: "Meal Plans",
  },
  {
    id: 5,
    feature_description: "Sleep",
  },
  {
    id: 6,
    feature_description: "Meditation",
  },
  {
    id: 7,
    feature_description: "Mental Health",
  },
  {
    id: 8,
    feature_description: "Travel",
  },
  {
    id: 9,
    feature_description: "Combo Plans",
  },
];
export const getStartDate = (now) => {
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 15);
};

export const getEndDate = (now) => {
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 15);
};


// Inserted by mahi
export const getmealplanforweek = async (userid,date) => {
  try {
    const res = await axios.get(
      `${settings.apiBaseURL}/getactiveMealPlansbyuserId`,
      {
        params: { userid: userid,date:date },
      }
    );
    return res.data.results;
  } catch (e) {
    console.log("--- /getactiveMealPlansbyuserId error", e);
    return null;
  }
};
export const getPaymentDetails = async (transactionId) => {
  try {
    const res = await axios.get(
      `${settings.apiBaseURL}/getPaymentDetails`,
      {
        params: { transactionId: transactionId },
      }
    );
    return res.data.response;
  } catch (e) {
    console.log("--- /getPaymentDetails error", e);
    return null;
  }
};