import React from "react";
import CirclesBackground from "../../components/CirclesBackground";
import { defaultErrorMsg } from "../../utility/constants";

const PaymentError = () => {
  return (
    <CirclesBackground>
      <div className="page-container">
        <h1 className="my-5 text-center">{defaultErrorMsg}</h1>
      </div>
    </CirclesBackground>
  );
};

export default PaymentError;
