import { useSelector } from "react-redux";
import moment from "moment";
import "../assets/styles/_session_details.scss";

const SessionDetails = ({
  list,
  title,
  duration,
  paid,
  max_attendees,
  start_date,
  onViewClicked,
}) => {
  const language = useSelector((state) => state.language.lang);
  // console.log("--- duration ", language.free, language.paid);
  // console.log("date check", moment(start_date).isSame(moment.utc(moment())));
  return (
    <div
      style={{ backgroundColor: "#f2f2f2f2" }}
      className="p-2 p-lg-3 rounded mt-3"
    >
      <div className="row mx-0 align-items-center">
        <div className="col-2 col-lg-1 px-0">
          <i
            className="fas fa-clock fa-2x"
            style={{ color: "#2b607d", margin: "0px", marginRight: "10px" }}
          ></i>
        </div>
        <div className="col-10 col-lg-5 px-0">
          <p className="mb-0">{title}</p>
        </div>
        <div className="col-12 col-lg-6 px-0 py-2 py-lg-0 d-flex justify-content-between align-items-center">
          {list ? null : <p className="text-primary mb-0">{duration}</p>}
          <p className="text-primary mb-0">
            {max_attendees} {language.members}
          </p>
          <p className="text-primary mb-0">
            {paid ? language.paid : language.free}
          </p>
        </div>
      </div>
      <div className="row mx-0 align-items-center mt-2">
        <div className="col-8 col-lg-10 px-0 d-flex align-items-center">
          <p className="text-primary mb-0">
            {moment.utc(start_date).local().format("DD-MM-YYYY, h:mm a")}
          </p>
          <p className="text-primary mb-0">
            {moment(start_date).isSame(moment.utc(moment()))
              ? language.today
              : null}
          </p>
        </div>
        <div className="col-4 col-lg-2 px-0 d-flex justify-content-end">
          <button onClick={onViewClicked} className="view">
            {language.view}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SessionDetails;
