import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { dashboardAppointmentsRoute } from "../routes";

const ViewBookConsultation = () => {
  const history = useHistory();

  const onClick = () => {
    history.push(dashboardAppointmentsRoute.path, {
      pending: "Upcoming",
    });
  };

  const language = useSelector((state) => state.language);
  return (
    <div className="d-flex justify-content-end align-items-center w-100">
      <button
        onClick={onClick}
        className="btn"
        style={{
          backgroundColor: "#1B68DF",
          borderRadius: "50px",
          color: "#fff",
          padding: "3px 15px",
        }}
      >
        {language.lang.view}
      </button>
    </div>
  );
};
export default ViewBookConsultation;
