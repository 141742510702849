import actions from "./actions";

export const initState = {
  messagesList: [],
  agoraChannel: null,
  agoraClient: null,
  isMessageSaved: false,
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.SET_CHAT_MESSAGES:
      return Object.assign({}, state, {
        messagesList: payload,
      });
    case actions.ADD_MESSAGE:
      return Object.assign({}, state, {
        messagesList: [...state.messagesList, payload],
      });
    case actions.SET_AGORA_CHANNEL:
      return Object.assign({}, state, {
        agoraChannel: payload,
      });
    case actions.SET_AGORA_CLIENT:
      return Object.assign({}, state, {
        agoraClient: payload,
      });
    case actions.SET_MESSAGE_SAVED:
      return {
        ...state,
        isMessageSaved: payload.isMessageSaved,
      };
    default:
      return state;
  }
};

export default reducer;
