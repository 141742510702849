import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, updateProfile } from "firebase/auth";
import AuthBase from "../../components/AuthBase";
import { dashboardRoute } from "../../routes";
import { updateUserProfile } from "../../services/CustomerNetworkService";
import { showErrorNotification } from "../../utility/Helpers";
import authActions from "../../redux/authenitcation/actions";

const SignupUserName = ({ history }) => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.lang);
  const authData = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    // console.log("--- values ", values);
    setLoading(true);
    // const userDetails = await getPatientDetails({});
    // if (!userDetails) {
    //   setLoading(false);
    //   return showErrorNotification();
    // }
    try {
      const fireAuth = getAuth();
      // console.log({ currentUser: fireAuth.currentUser });
      const updateSuccess = await Promise.all([
        updateUserProfile({
          name: values.name,
          referralCode: values.referalCode,
        }),
        updateProfile(fireAuth.currentUser, { displayName: values.name }),
      ]);
      if (updateSuccess[0]) {
        dispatch(
          authActions.loginSuccess({
            ...authData,
            userData: {
              ...authData.userData,
              name: values.name,
            },
          })
        );
        onSkipClicked(values.name);
      } else {
        setLoading(false);
        showErrorNotification();
      }
    } catch (e) {
      console.log("--- SignupUserName error ");
      console.log(e);
      showErrorNotification();
      setLoading(false);
    }
  };

  const onSkipClicked = () => {
    history.replace(dashboardRoute.path);
  };

  return (
    <AuthBase className="signup-user-name">
      <p className="text-center text-secondary">
        {language["we-collect-this-info-to-address-in-different-areas"] ??
          "We collect this info to address you in different areas"}
      </p>
      <Form size="large" onFinish={handleFormSubmit} layout="vertical">
        <Form.Item
          label={
            language["enter-your-name-to-proceed"] ??
            "enter-your-name-to-proceed"
          }
          rules={[
            {
              type: "string",
              required: true,
              min: 3,
              message:
                language["please-enter-your-name"] ?? "please-enter-your-name",
            },
          ]}
          name="name"
          className="name-number-form-item"
        >
          <Input
            className="border-circle-input"
            placeholder={language["your-name"] ?? "your-name"}
          />
        </Form.Item>
        <Form.Item
          label={
            language["enter-referal-code-if-you-have"] ??
            "enter-referal-code-if-you-have"
          }
          name="referalCode"
          className="referal-code-form-item"
        >
          <Input
            className="border-circle-input"
            placeholder={language["referal-code"] ?? "referal-code"}
          />
        </Form.Item>
        <div className="btns-row d-flex justify-content-between">
          {/* <Button loading={loading} onClick={onSkipClicked} type="primary">
            {language["skip"]}
          </Button> */}
          <div></div>
          <Button loading={loading} type="primary" htmlType="submit">
            {language["continue"]}
          </Button>
        </div>
      </Form>
    </AuthBase>
  );
};

export default SignupUserName;
