import React, { useEffect, useState } from "react";
import MMRadioButton from "./MMRadioButton";

const ChoiceField = ({ first, second, ChoiceHandler, name, ViewType, value }) => {
  const [select, setselect] = useState(value);

  useEffect(() => {
    ChoiceHandler(name, select === 1 ? true : false);
  }, [select]);

  return (
    <div className="gender-select-field flex-wrap">
      <div className="check-box-container align-items-center d-flex">
        <MMRadioButton onClick={() => !ViewType && setselect(1)} label={first} selected={select === 1} classes="mr-5" />
        <MMRadioButton onClick={() => !ViewType && setselect(2)} label={second} selected={select === 2} />
      </div>
    </div>
  );
};

export default ChoiceField;
