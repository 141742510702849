import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUserNotificationsAction,
  ResetNotificationsAction,
} from "../redux/user/actionsCreators";
import NotificationsSideMenu from "./NotificationsSideMenu";

const NotificationsButton = ({ className }) => {
  const dispatch = useDispatch();
  const [openSideMenu, setOpenSideMenue] = useState(false);
  const userNotifications = useSelector((state) => state.user.notifications);
  const language = useSelector((state) => state.language);
  const selectedLanguage = language.general.preferredlanguage;
  const userNotificationCount = useSelector(
    (state) => state.user.notification_count
  );

  useEffect(() => {
    // console.log("--- FetchNotifications", selectedLanguage);
    FetchNotifications();
  }, [selectedLanguage]);

  const FetchNotifications = () => {
    dispatch(fetchUserNotificationsAction());
  };

  const closeSideMenu = () => {
    if (!openSideMenu) return;
    setOpenSideMenue(false);
  };

  const handleBtnPressed = () => {
    setOpenSideMenue(true);
    FetchNotifications();
    dispatch(ResetNotificationsAction());
  };

  // const notificationsList =
  //   (userNotifications && userNotifications.notificationDetails) || []; // .slice(0, 100);

  // notificationsList = notificationsList.filter((item) => item.details !== "");
  // const notificationsCount =
  //   (notificationsList &&
  //     notificationsList.filter((notification) => !notification.is_viewed)
  //       .length) ||
  //   0;

  // console.log("--- count ", notificationsCount, notificationsList);
  return (
    <>
      <NotificationsSideMenu
        isOpened={openSideMenu}
        onClosePressed={closeSideMenu}
        userNotifications={userNotifications}
      />
      <button
        onClick={handleBtnPressed}
        className={`btn notifications-btn ${className}`}
      >
        <i className="far fa-bell"></i>
        {userNotificationCount && userNotificationCount > 0 ? (
          <div
            className="notifications-count"
            style={
              language.direction === " direction-rtl"
                ? {
                    right: "-10px",
                  }
                : {}
            }
          >
            {userNotificationCount}
          </div>
        ) : null}
      </button>
    </>
  );
};

export default NotificationsButton;
