import { useSelector } from "react-redux";
import VerifyBrokenImage from "./VerifyBrokenImage";

const ProfileCircleButton = () => {
  const { userData } = useSelector((state) => state.auth);
  // const [validurl, setvalidurl] = useState(true);
  const handleClick = () => {};

  if (!userData) {
    return <span></span>;
  }
  // const validateurl = (str) => {
  //   var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  //   '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  //   '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  //   '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  //   setValidUrl(!!pattern.test(str));
  // }
  // console.log(validurl)
  // if(!validurl){
  //   validateurl(userData?.profile_photo_url);
  // }
  // const handleImgError = (e) => {
  //   setvalidurl(false);
  //   console.log(e.target.src, "ev.target.src");
  //   e.target.src = Avatar;
  // };

  return (
    <div className="dropdown">
      <button
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={handleClick}
        id="prifle-dropdown"
        className="btn p-0 profile-btn d-flex justify-content-center align-items-center"
      >
        {/* <img src={userData?.profile_photo_url || Avatar} onError={(event) => event.target.style.display = 'none'}/> */}
        <VerifyBrokenImage
          url={userData?.profile_photo_url}
          // name={
          //   (userData?.name && userData?.name) +
          //   " " +
          //   (!!userData?.surname ? userData?.surname : "")
          // }
        />
      </button>
      <ul className="dropdown-menu" aria-labelledby="settings-dropdown">
        <li>
          <div className="dropdown-item">
            <i className="fas fa-user mr-3"></i>
            {userData.name} {userData.surname}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ProfileCircleButton;
