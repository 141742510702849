import actions from "./actions";
import authActions from "../authenitcation/actions";
import { clientWalkinConfirmationAppointmentKey } from "../../utility/constants";

const initState = {
  waitingForAppointmentID: "",
  appointmentDetails: JSON.parse(localStorage.getItem(clientWalkinConfirmationAppointmentKey)) || {},
  doctorStartedAppointmentID: "",
  status: "",
  initiatedTransactionID: "",
  completedTransaciton: {},
  callType: true, // video = true and audio = false
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.SET_INITIATED_AND_COMPLETED_TRANSACTION:
      return Object.assign({}, state, {
        completedTransaciton: payload.completedTransaciton,
        initiatedTransactionID: payload.initiatedTransactionID,
      });
    case actions.SET_COMPLETED_TRASNACTION:
      return Object.assign({}, state, {
        completedTransaciton: payload,
      });
    case actions.SET_INITIATED_TRANSACTION_ID:
      return Object.assign({}, state, {
        initiatedTransactionID: payload,
      });
    case actions.SET_DOCTOR_STARTED_APPOINTMENT_ID:
      return Object.assign({}, state, {
        doctorStartedAppointmentID: payload,
        waitingForAppointmentID: "",
      });
    case actions.SET_APPOINTMENT_DETAILS:
      console.log("Set Appointment Details",payload)
      localStorage.setItem(clientWalkinConfirmationAppointmentKey,JSON.stringify(payload));
      return Object.assign({}, state, {
        appointmentDetails: payload,
      });
    case authActions.LOGOUT_SUCCESS:
      return initState;
    case actions.SET_IS_NOT_WAITING_DOCTOR_APPROVAL:
      return Object.assign({}, state, {
        waitingForAppointmentID: "",
      });
    case actions.SET_IS_WAITING_DOCTOR_APPROVAL:
      return Object.assign({}, state, {
        waitingForAppointmentID: payload,
      });
    case actions.SET_DOCTOR_STARTED_APPOINTMENT_DETAILS:
      console.log("DoctorAppointment Status",payload)
      return Object.assign({}, state, {
        status: payload,
      });
      case actions.SET_CALL_TYPE:
      console.log("Call type --> ", payload);
      return Object.assign({}, state, {
        callType: payload,
      });
    default:
      return state;
  }
};

export default reducer;
