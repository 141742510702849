import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  dashboardBookConsultationRoute,
  dashboardFitnessRoute,
  dashboardLiveSessionRoute,
  dashboardMealPlanRoute,
  dashboardMeditationYogaRoute,
  dashboardMostPopularNewRoute,
} from "../routes";

const SectionHeader = ({
  title,
  btnText,
  titlePadding = true,
  className,
  showTrailingButton = true,
  onViewAllPressed,
}) => {
  let headerClasses = `section-header d-flex justify-content-between ml-1`;
  let tilteClasses = `title`;
  if (titlePadding) {
    tilteClasses += ` `;
  }
  if (className) {
    headerClasses += ` ${className}`;
  }
  const history = useHistory();
  const language = useSelector((state) => state.language);

  const changeRoute = () => {
    if (onViewAllPressed) {
      return onViewAllPressed();
    }
    let pageRoute;
    if (title === "Top Doctors") {
      pageRoute = dashboardBookConsultationRoute.path;
    } else if (title === language.lang["most-popular"]) {
      pageRoute = dashboardMostPopularNewRoute.path;
    } else if (title === language.lang.fitness) {
      pageRoute = dashboardFitnessRoute.path;
    } else if (title === language.lang.you_are_what_you_eat) {
      pageRoute = dashboardMealPlanRoute.path;
    } else if (title === language.lang.meditaiton) {
      pageRoute = dashboardMeditationYogaRoute.path;
    } else if (title === language.lang.yoga) {
      pageRoute = dashboardMeditationYogaRoute.path;
    } else if (title === "events") {
      pageRoute = dashboardLiveSessionRoute.path;
    }
    history.push(pageRoute);
  };

  return (
    <div className={headerClasses + language.direction}>
      <h5 className={tilteClasses}>{title}</h5>
      {showTrailingButton && (
        <button className="btn text-logo-color m-0 view-all" onClick={changeRoute}>
          {language.lang["view-all"]}
        </button>
      )}
    </div>
  );
};

export default SectionHeader;
