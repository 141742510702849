import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/fitnessBuddy/actions";

var thumbsize = 14;

const AgeSlider = ({ min, max }) => {
  const language = useSelector((state) => state.language);
  const userDetails = useSelector((state) => state.patientDetails.userDetails);
  const dispatch = useDispatch();
  const [minVal, setMinVal] = useState(
    Number(userDetails.ageRangeForMatch.min)
  );
  const [maxVal, setMaxVal] = useState(
    Number(userDetails.ageRangeForMatch.max)
  );
  const [avg, setAvg] = useState(parseInt((min + max) / 2));

  const width = 300;
  const minWidth =
    thumbsize + ((avg - min) / (max - min)) * (width - 1 * thumbsize);
  const minPercent = ((minVal - min) / (avg - min)) * 100;
  const maxPercent = ((maxVal - avg) / (max - avg)) * 100;
  const styles = {
    min: {
      width: minWidth,
      left: 0,
      "--minRangePercent": `${minPercent}%`,
    },
    max: {
      width: thumbsize + ((max - avg) / (max - min)) * (width - 1 * thumbsize),
      left: minWidth,
      "--maxRangePercent": `${maxPercent}%`,
    },
  };

  useLayoutEffect(() => {
    setAvg(parseInt((maxVal + minVal) / 2));
  }, [minVal, maxVal]);

  const handleMaxValueChange = (value) => {
    console.log(parseInt(Number(value)));
    if (Number(value) <= 18) {
      setMaxVal(19);
      dispatch({
        type: actions.UPDATE_MAX_AGE_RANGE,
        payload: { max: Number(value) },
      });
      return;
    }

    setMaxVal(Number(value));
    dispatch({
      type: actions.UPDATE_MAX_AGE_RANGE,
      payload: { max: Number(value) },
    });
  };
  const handleMinValueChange = (value) => {
    if (Number(value) < 18) {
      setMinVal(18);
      dispatch({
        type: actions.UPDATE_MAX_AGE_RANGE,
        payload: { max: Math.round(Number(value)) },
      });
      return;
    }
    setMinVal(Number(value));
    dispatch({
      type: actions.UPDATE_MIN_AGE_RANGE,
      payload: { min: Number(value) },
    });
  };
  return (
    <div>
      <div className="age-details">
        <label>{language.lang.age}</label>
        <div>
          {Number(minVal)}-{Math.round(Number(maxVal))}
        </div>
      </div>
      <div
        className="min-max-slider"
        data-legendnum="2"
        data-rangemin={min}
        data-rangemax={max}
        data-thumbsize={thumbsize}
        data-rangewidth={width}
      >
        <label htmlFor="min">Minimum</label>
        <input
          id="min"
          className="min"
          style={styles.min}
          name="min"
          type="range"
          step="1"
          min={min}
          max={avg}
          value={minVal}
          onChange={({ target }) => {
            handleMinValueChange(target.value);
          }}
        />
        <label htmlFor="max">Maximum</label>
        <input
          id="max"
          className="max"
          style={styles.max}
          name="max"
          type="range"
          step="1"
          min={avg}
          max={max}
          value={maxVal}
          onChange={({ target }) => {
            handleMaxValueChange(target.value);
          }}
        />
      </div>
    </div>
  );
};

export default AgeSlider;
