import actions from "./actions";
import authActions from "../authenitcation/actions";

const initState = {
  notifications: {
    notificationDetails: [],
  },
  reset: true,
  show_old_notifications: false,
  old_notifications: [],
  notification_count: 0,
  side_bar: false,
  show_modal: false,
  loadingNotifications: false,
  modal_data: "",
  previousPage: "",
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.SET_LOADING_NOTIFICATIONS:
      return Object.assign({}, state, {
        loadingNotifications: payload,
      });
    case authActions.LOGOUT_SUCCESS:
      return {
        notifications: {
          notificationDetails: [],
        },
      };
    case actions.FETCH_USER_NOTIFICATIONS:
      return Object.assign({}, state, {
        notifications: {
          notificationDetails: [...payload.notificationDetails],
        },
        loadingNotifications: false,
        notification_count: /*payload.notificationDetails.length*/ 0,
      });
    case actions.UPDATE_USER_NOTIFICATIONS:
      let showModal = false,
        modalData = "";
      if (
        payload?.notificationDetails[0]?.notificationType?.name ===
          "AppointmentScheduled" ||
        payload?.notificationDetails[0]?.notificationType?.name ===
          "AppointmentUpdatedByClinic"
      ) {
        modalData = payload?.notificationDetails[0]?.details;
        showModal = true;
      }
      return Object.assign({}, state, {
        ...state,
        notifications: {
          notificationDetails: [
            ...payload.notificationDetails,
            ...state.notifications.notificationDetails,
          ],
        },
        notification_count:
          state.notification_count + payload.notificationDetails.length,
        show_modal: showModal,
        modal_data: modalData,
      });
    case actions.RESET_MODAL_NOTIFICATIONS:
      return Object.assign({}, state, {
        ...state,
        show_modal: false,
        modal_data: "",
      });
    case actions.RESET_NOTIFICATIONS:
      return Object.assign({}, state, {
        ...state,
        notification_count: 0,
      });
    case actions.SHOW_OLD_NOTIFICATIONS:
      return Object.assign({}, state, {
        ...state,
        show_old_notifications: payload,
      });
    case actions.FETCH_OLD_NOTIFICATIONS:
      if (!payload) return state;
      return Object.assign({}, state, {
        ...state,
        old_notifications: payload,
      });
    case actions.SET_SIDEBAR:
      return Object.assign({}, state, {
        ...state,
        side_bar: payload,
      });
    case actions.SET_PREVIOUS_PAGE_PATH:
      if (payload === "/login") return state;
      return Object.assign({}, state, {
        ...state,
        previousPage: payload,
      });
    default:
      return state;
  }
};

export default reducer;
