import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { settings } from "../config";
import {
  setPatientDetail,
} from "../redux/clinic/actionCreators";
import actions from "../redux/clinic/actions";
import { useHistory } from "react-router-dom";
import {
  ViewScheduledPatientRoute,
  ViewScheduledPatientBasedOnRegionRoute,
} from "../routes";

const ClinicPatientScheduled = ({ el, i }) => {
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const history = useHistory();
  const [appointment, setappointment] = useState({});
  //const appointment = useSelector((state) => state.clinic.appointmentDetail);
  const onView = async () => {
    /*setLoading(true);
        await dispatch(acceptWalkinRequestAction(el, i));
        setLoading(false);*/
    console.log(appointment);
    if (appointment.appointment_source === "scheduleAppointmentByRegion") {
      history.push(ViewScheduledPatientBasedOnRegionRoute.path);
    } else {
      history.push(ViewScheduledPatientRoute.path);
    }
    dispatch(setPatientDetail(appointment.patientInfo));
    dispatch({
      type: actions.SET_APPOINTMENT_DETAIL,
      payload: appointment,
    });
    dispatch({
      type: actions.SET_VIEW_SCHEDULED_APPOINTMENTS,
      payload: true,
    });
  };
  useEffect(() => {
    getappointmentDetails();
  }, [el]);
  const getappointmentDetails = async () => {
    //el && el.id &&  dispatch(getAppointmentDetailsForClinic(el.id));
    if (el.id) {
      const res = await axios.get(
        `${settings.apiBaseURL}/getAppointmentDetailsForClinic?appointmentId=${el.id}`
      );
      setappointment(res.data.response[0]);
    }
  };

  return (
    <div className="ind-patient">
      <div className="patient-col1">
        <i className="fas fa-clock pending-clock mr-1"></i>
        <h6 className="mr-3 heading">
          {appointment &&
            appointment.patientInfo &&
            appointment.patientInfo.name}
        </h6>
      </div>
      <div className="patient-col1 light-heading">
        {appointment &&
          moment(appointment && appointment.start_date_time).format("hh:mm a")}
      </div>
      <div className="patient-col1">
        <h6 className="mr-3 heading">
          {appointment &&
            appointment.practitionerInfo &&
            appointment.practitionerInfo.name}
        </h6>
      </div>
      <div className="patient-col1">
        <h6
          className={
            "mr-3 " +
            (appointment && appointment.status_id === 23
              ? "pending"
              : "confirmed")
          }
        >
          {appointment && appointment.status_id === 23
            ? language.lang.pending
            : language.lang.confirmed}
        </h6>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <h6 className="mr-3 heading date">
          {appointment &&
            (parseInt(moment(appointment.start_date_time).format("DD")) ===
            parseInt(moment().format("DD"))
              ? language.lang.today
              : moment(appointment.start_date_time).format("DD-MM-YYYY"))}
        </h6>
      </div>
      <div className="d-flex ml-2 w-100 justify-content-center">
        <button className="accept-btn " onClick={onView}>
          {language.lang.view}
        </button>
      </div>
    </div>
  );
};

export default ClinicPatientScheduled;
