import axios from "../../services/Axios";
import { settings } from "../../config";
import store from "../store";
import actions from "./actions";

export const resetTopPractitionersAction = () => {
  return {
    type: actions.RESET_TOP_PRACTITIONERS,
  };
};

export const clearCalendarEventsAction = () => {
  return { type: actions.CLEAR_CALENDAR_EVENTS };
};

export const setSelectedSlotAndViewSlotAction = ({
  viewSlot,
  selectedSlot,
}) => {
  return {
    type: actions.SET_SELECTED_SLOT_AND_VIEW_SLOT,
    payload: { viewSlot, selectedSlot },
  };
};

export const setTopPractitionersAction = ({ practitioners }) => {
  return {
    type: actions.GET_TOP_PRACTITIONERS,
    payload: practitioners,
  };
};

export const getTopPractitioners = (offset, limit) => {
  return async (dispatch) => {
    const state = store.getState();
    const userID = state.auth.userData && state.auth.userData.id;
    const regionID = state.language.regionID;
    const userGroup = userID ? state.auth.userData.user_group : "production";
    const res = await axios.get("/getTopPractitioners", {
      params: {
        regionId: regionID,
        userGroup,
        offset,
        limit,
      },
    });
    dispatch({
      type: actions.GET_TOP_PRACTITIONERS,
      payload: res ? res.data.response : null,
    });
    return res ? res.data.response : null;
  };
};

export const updatePatientAllAppointments = (payload) => {
  return async (dispatch) => {
    //const response = await axios.get(`${settings.apiBaseURL}/getBanners`);
    dispatch({
      type: actions.UPDATE_PATIENT_APPOINTMENTS,
      payload: payload,
    });
  };
};

export const setHomeBanner = () => {
  return async (dispatch) => {
    const response = await axios.get(`${settings.apiBaseURL}/getBanners`);
    dispatch({
      type: actions.SET_HOME_BANNER,
      payload:
        response && response.data && response.data.response
          ? response.data.response
          : [],
    });
  };
};
export const setReviews = () => {
  return async (dispatch) => {
    const response = await axios.get(`${settings.apiBaseURL}/getCustomerReviews`);
    dispatch({
      type: actions.SET_REVIEWS,
      payload:
        response && response.data && response.data.response
          ? response.data.response
          : [],
    });
  };
};

export const setMealPlans = (offset, limit) => {
  return async (dispatch) => {
    const response = await axios.get(
      `${settings.apiBaseURL}/getMealPlans?offset=${offset}&limit=${limit}`
    );
    dispatch({
      type: actions.SET_MEAL_PLANS,
      payload:
        response && response.data && response.data.response
          ? response.data.response
          : [],
    });
  };
};

export const setOffsetTopDoctors = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: actions.SET_OFFSET,
      payload: payload,
    });
  };
};
export const setOffsetMeals = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: actions.SET_MEAL_OFFSET,
      payload: payload,
    });
  };
};

export const customerCalendarStartLoading = () => {
  return { type: actions.START_LOADING };
};

export const customerCalendarStopLoading = () => {
  return { type: actions.STOP_LOADING };
};

export const setPatientCalendarEvents = (
  startDate,
  endDate,
  patientID,
  showLoading,
  scheduleTypeID
) => {
  return async (dispatch) => {
    if (showLoading) {
      dispatch(customerCalendarStartLoading());
    }
    const res = await axios.get(
      `${settings.apiBaseURL}/getCustomerAllAppointments?startDate=${startDate}&endDate=${endDate}&customerId=${patientID}&schedulerType=${scheduleTypeID}`
    );
    const payload = res ? res.data?.response : null;
    if (!payload) dispatch(customerCalendarStopLoading());
    // const calendarState = store.getState().patient.calendar;
    dispatch({
      type: actions.SET_ALL_APPOINTMENTS_OF_CUSTOMER,
      payload,
    });
  };
};

export const setPatientViewType = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_PATIENT_VIEW_TYPE,
      payload: payload,
    });
  };
};
