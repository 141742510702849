import ExclusionListItem from "./ExclusionListItem";

const MajorExclusionListItem = ({
  majorExclusion,
  selectedMajorExclusions,
  selectedSubExclusions,
  onMajorExclusionClicked,
  onSubExclusionClicked,
}) => {
  const handleMajorExclusionClicked = () => {
    onMajorExclusionClicked(majorExclusion);
  };

  const handleSubExclusionClicked = (exclusion) => {
    onSubExclusionClicked(majorExclusion, exclusion);
  };

  return (
    <div className="mojor-exclusion-list-item mb-3">
      <div className="d-flex mb-2">
        <ExclusionListItem
          name={majorExclusion.name}
          selected={selectedMajorExclusions.has(majorExclusion.id)}
          enabled={!majorExclusion.isExcluded}
          onClick={handleMajorExclusionClicked}
        />
      </div>
      <div className="sub-exclusions px-3">
        {majorExclusion?.subExclusions?.map((item) => {
          return (
            <ExclusionListItem
              key={item.id}
              name={item.name}
              selected={selectedSubExclusions.has(item.id)}
              enabled={!item.isExcluded && !majorExclusion.isExcluded}
              onClick={() => handleSubExclusionClicked(item)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MajorExclusionListItem;
