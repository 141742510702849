const actions = {
    SET_CLINIC_APPOINTMENT : "SET_CLINIC_APPOINTMENT",
    POST_VERIFY_PATIENT_DETAILS : "POST_VERIFY_PATIENT_DETAILS",
    SET_PATIENT_DETAIL : "SET_PATIENT_DETAIL",
    SET_APPOINTMENT_DETAIL : "SET_APPOINTMENT_DETAIL",
    SET_PENDING_APPOINTMENTS : "SET_PENDING_APPOINTMENTS",
    UPDATE_PENDING_APPOINTMENTS : "UPDATE_PENDING_APPOINTMENTS",
    UPDATE_SCHEDULED_APPOINTMENTS : "UPDATE_SCHEDULED_APPOINTMENTS",
    SET_VIEW_SCHEDULED_APPOINTMENTS : "SET_VIEW_SCHEDULED_APPOINTMENTS",
    SET_CURRENCIES : "SET_CURRENCIES",
    GET_TOP_PRACTITIONERS : "GET_TOP_PRACTITIONERS",
    SET_CALENDAR_VIEW_TYPE: "SET_CALENDAR_VIEW_TYPE",
    SET_PRACTITIONER_EVENTS: "SET_PRACTITIONER_EVENTS",
    SET_VIEW_SLOT: "SET_VIEW_SLOT",
    SET_SELECTED_SLOT : "SET_SELECTED_SLOT",
    SET_SELECTED_DOCTOR_INFO : "SET_SELECTED_DOCTOR_INFO",
    SET_ALL_APPOINTMENTS_OF_PRACTITIONER : "SET_ALL_APPOINTMENTS_OF_PRACTITIONER",
    SET_CLINIC_CALENDAR_SETTINGS: "SET_CLINIC_CALENDAR_SETTINGS",
    SET_NEED_TO_CALL_CALENDAR: "SET_NEED_TO_CALL_CALENDAR"
  };
  
  export default actions;
  