import { useEffect, useState, useRef } from "react";
import DoctorInfoCard from "../../components/DoctorInfoCard";
import AppointmentDetailsCard from "../../components/AppointmentDetailsCard";
import { useHistory, useParams } from "react-router-dom";
import { dashboardBookConsultationRoute, clientSignInRoute } from "../../routes";
import axios from "../../services/Axios";
import {
  ModalNotification,
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
  uploadFile,
} from "../../utility/Helpers";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { scheduleAppointment } from "../../components/OnboardingForm/actions";
import { scheduleAppointmentByRegion } from "../../components/OnboardingFormForRole/actions";

import DoctorTile from "../../components/DoctorTile";
import {
  categoryMentalHealth,
  fileTypeAudio,
  fileTypeImage,
  fileTypeMedicalReport,
  storageTypeAudio,
  storageTypeDocument,
  storageTypeImage,
  userTypeUser,
} from "../../utility/constants";
import OnboardingForm from "../../components/OnboardingForm";
import PatientActions from "../../redux/patient/actions";
import Loading from "../../components/Loading";
import OnboardingFormForRole from "../../components/OnboardingFormForRole/OnboardingFormForRole";
import { GetPractitionerCurrencyCodeAndFees } from "../../utility/Helpers";
import TopMenu from "../../components/CommonControls/TopMenu";

const CustomerConfirmConsultation = () => {
  const history = useHistory();
  const practitioner = history?.location?.state?.practitioner || {};
  const routeSelectedSlot = history.location.state?.selectedSlot || {};
  const { urlName } = useParams();
  // console.log({ practitionerID, urlName });
  const language = useSelector((state) => state.language);
  const { userData } = useSelector((state) => state.auth);
  const Patient = useSelector((state) => state.patient);
  const [slots, setSlots] = useState([]);
  const [slotnumber, setslotnumber] = useState(Patient?.slotnumber);
  const [slotID, setSlotID] = useState("");
  const [selectedSlot, setSelectedSlot] = useState(routeSelectedSlot);
  const [pracID, setPracID] = useState("");
  const [pracDetails, setpracDetails] = useState({ ...practitioner });
  const [viewType, setviewType] = useState(false);
  const selectedTimeSlotsRef = useRef(null);
  const [btnloading, setbtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingSlots, setLoadingSlots] = useState(true);
  const [insuranceCount, setinsuranceCount] = useState(0);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.login);

  // useEffect(() => {
  //   if (!isLoggedIn && redirectTo) {
  //     showWarningNotification(loginToBookMsg);
  //   }
  // }, [redirectTo]);

  const addSelectedPractitioner = () => {
    dispatch({
      type: PatientActions.SET_SELECTED_PRACTITIONER,
      payload: {
        selectedPractitioner: Patient?.selectedPractitioner,
        selectedSlot: selectedSlot,
        slotnumber: slotnumber,
      },
    });
  };

  const confirmPressed = async () => {
    addSelectedPractitioner();
    if (!isLoggedIn) {
      showWarningNotification(language.lang.pleaseLoginToBookAppointment);
      history.push(clientSignInRoute.path, {
        nextRoute: `${dashboardBookConsultationRoute.path}/${pracDetails.url_name}`,
        selectedSlot,
      });
      return;
    }
    if (Object.keys(selectedSlot).length > 0) {
      setviewType(true);
    } else {
      setviewType(false);
      showSuccessNotification(language.lang["select-slot"]);
    }
  };

  const submitScheduledAppointment = async (formData) => {
    const res = await scheduleAppointment(formData);
    const { appointmentId, message } = res;
    if (!appointmentId) return showErrorNotification();
    // showSuccessNotification(message);
    ModalNotification(message, "AppointmentScheduled");
  };

  const submitScheduledAppointmentByRegiom = async (formData) => {
    const res = await scheduleAppointmentByRegion(formData);
    const { appointmentId, message } = res;
    if (!appointmentId) return showErrorNotification();
    // showSuccessNotification(message);
    ModalNotification(message, "AppointmentScheduled");
  };

  const uploadEmiratesID = async (emiratesIDImage) => {
    if (!emiratesIDImage) return null;
    const data = {
      storageType: storageTypeImage,
      category: categoryMentalHealth,
      userType: userTypeUser,
      fileType: fileTypeImage,
      userId: userData.id,
    };
    data["multi-files"] = emiratesIDImage;
    return uploadFile(data);
  };

  const uploadBackEmiratesID = async (emiratesIDBackImage) => {
    if (!emiratesIDBackImage) return null;
    const data = {
      storageType: storageTypeImage,
      category: categoryMentalHealth,
      userType: userTypeUser,
      fileType: fileTypeImage,
      userId: userData.id,
    };
    data["multi-files"] = emiratesIDBackImage;
    return uploadFile(data);
  };
  const uploadMedicalRecord = async (medicalDocFile) => {
    if (!medicalDocFile) return null;
    const data = {
      storageType: storageTypeDocument,
      category: categoryMentalHealth,
      fileType: fileTypeMedicalReport,
      userType: userTypeUser,
      userId: userData.id,
    };
    data["multi-files"] = medicalDocFile;
    return uploadFile(data);
  };

  const uploadAudioRecord = async (recordFile) => {
    if (!recordFile) return null;
    const data = {
      storageType: storageTypeAudio,
      category: categoryMentalHealth,
      userType: userTypeUser,
      fileType: fileTypeAudio,
      userId: userData.id,
    };
    data["multi-files"] = recordFile;
    return uploadFile(data);
  };

  useEffect(() => {
    getPractitionerDetails();
    // if (history.location.state) {
    //   history.location.state = null;
    //   console.log("--- history", history);
    // }
  }, []);

  useEffect(() => {
    // getDoctorSlotDetails();
    history.location &&
      history.location.state &&
      history.location.state.selectedSlot &&
      setSelectedSlot(history.location.state.selectedSlot);
    //  history.location && history.location.state && history.location.state.selectedSlot && setSlots([history.location.state.selectedSlot])
  }, [history.location.state, history.location]);

  const getDoctorSlotDetails = async ({ id }) => {
    try {
      // let practionerId = practitioner?.id || practitionerID; // history.location.state.practitioner.id;
      const response = await axios.get("/getSlots", {
        params: { practitionerId: id },
      });
      let arr = response.data.response
        .filter((slot) => {
          const now = moment();
          return moment(slot.start_time).isAfter(now);
        })
        .map((el) => {
          console.log(moment(el.start_time).format("DD-MM-YY"));
          let obj = {};
          obj.key = el.id;
          obj.slot_id = el.id;
          obj.practionerId = el.practitioner_id;
          obj.date = el.start_time.substring(0, 10);
          obj.start_time = el.start_time; // .substring(11, 16);
          obj.end_time = el.end_time; // .substring(11, 16);
          obj.is_available = el.is_available;
          return obj;
        });
      setSlots(arr);
    } catch (e) {
      // console.log("error->",{e});
      showErrorNotification(e.response && e.response.data.error);
    } finally {
      setLoadingSlots(false);
    }
  };

  const onSelectedSlotChanged = (selectedSlots) => {
    selectedTimeSlotsRef.current = selectedSlots;
  };

  const getPractitionerDetails = () => {
    // setLoading(true);
    const params = {};
    if (urlName) {
      params.urlName = urlName;
    } else if (practitioner && practitioner.id) {
      params.practitionerId = practitioner.id;
    }
    axios
      .get("/getPractitionerDetails", {
        params,
      })
      .then((response) => {
        const data = response.data.response[0];
        if (!data) {
          setLoadingSlots(false);
          showErrorNotification();
        }
        setpracDetails(data || {});
        getDoctorSlotDetails({ id: data.id });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFormSubmited = async (data, emiratesIDImage, emiratesIDBackImage, medicalDocFile, recordFile) => {
    setLoading(true);
    try {
      const insurancesCountArrayy = pracDetails.insurances || [];
      let insurancesCount;
      if (insurancesCountArrayy.length === 0) {
        insurancesCount = 0;
      } else {
        insurancesCount = insurancesCountArrayy[0].coveredInsurances.aggregate.count;
      }
      const clinicID = pracDetails.clinic?.clinic_id;
      if (!clinicID) {
        return showErrorNotification(language.clinicNotAssociatedWithClinic);
      }
      data.coveredInsurancesCount = insurancesCount;
      data.clinicId = clinicID;
      const fileURLs = await Promise.all([
        uploadEmiratesID(emiratesIDImage),
        uploadMedicalRecord(medicalDocFile),
        uploadAudioRecord(recordFile),
        uploadBackEmiratesID(emiratesIDBackImage),
      ]);
      if (fileURLs[0] != null) {
        data.emiratesIdPhotoUrl = fileURLs[0];
      }
      if (fileURLs[3] != null) {
        data.emiratesIdPhotoUrlForBack = fileURLs[3];
      }
      //if (transactionID) data.transactionId = transactionID;
      data.medicalDocumentUrl = fileURLs[1];
      data.slotId = selectedSlot.slot_id;
      data.practitionerId = pracDetails && pracDetails.id;
      data.callType = "video";
      data.voiceNoteUrl = fileURLs[2];
      data.appointmentType = "scheduled";
      data.fullAmount = pracDetails.fees;
      await submitScheduledAppointment(data);
    } catch (e) {
      console.log("--- handlePaymentSuccess error");
      console.log(e);
      showErrorNotification(e.response && e.response.data.error);
    } finally {
      console.log("--- finally");
      setLoading(false);
    }
  };

  const onRegionFormSubmited = async (data, medicalDocFile, recordFile) => {
    setLoading(true);
    try {
      const clinicID = pracDetails.clinic.clinic_id;
      data.clinicId = clinicID;
      const fileURLs = await Promise.all([uploadMedicalRecord(medicalDocFile), uploadAudioRecord(recordFile)]);
      data.medicalDocumentUrl = fileURLs[0];
      data.slotId = selectedSlot.slot_id;
      data.practitionerId = pracDetails && pracDetails.id;
      data.callType = "video";
      data.voiceNoteUrl = fileURLs[1];
      const { currencyCode, practitionerFees } = GetPractitionerCurrencyCodeAndFees({
        selectedRegionID: language.regionID,
        feesWithMultiCurrency: pracDetails.feesWithMultiCurrency,
      });
      data.fullAmount = practitionerFees;
      data.amountCurrency = currencyCode;
      await submitScheduledAppointmentByRegiom(data);
      console.log(data, "Final data");
    } catch (e) {
      console.log("--- handlePaymentSuccess error");
      console.log(e);
      showErrorNotification(e.response && e.response.data.error);
    } finally {
      // console.log("--- finally");
      setLoading(false);
    }
  };

  const DisplayForm = () => {
    if (pracDetails.region_id === 1 && pracDetails.role_type === 7) {
      return (
        <OnboardingFormForRole
          onFormSubmited={onRegionFormSubmited}
          type={true}
          clinicid={pracDetails && pracDetails.clinic && pracDetails.clinic.clinic_id}
          fees={pracDetails?.fees}
          pracDetails={pracDetails?.fees}
          practitioner={pracDetails}
          Loading={loading}
        />
      );
    } else if (pracDetails.region_id !== 1) {
      return (
        <OnboardingFormForRole
          onFormSubmited={onRegionFormSubmited}
          type={true}
          clinicid={pracDetails && pracDetails.clinic && pracDetails.clinic.clinic_id}
          fees={pracDetails?.fees}
          pracDetails={pracDetails?.fees}
          practitioner={pracDetails}
          Loading={loading}
        />
      );
    } else {
      return (
        <OnboardingForm
          onFormSubmited={onFormSubmited}
          getinsured={history.location.state.getinsured}
          type={true}
          clinicid={pracDetails && pracDetails.clinic && pracDetails.clinic.clinic_id}
          fees={pracDetails?.fees}
          pracDetails={pracDetails?.fees}
          practitioner={pracDetails}
          Loading={loading}
          insuranceProvidersCount={insuranceCount}
          searchData={history?.location?.state?.searchData}
        />
      );
    }
  };

  // if (redirectTo) {
  //   return <Redirect to={clientSignInRoute.path} />;
  // } else {
  // }
  return (
    <div className="customer-confirm-consultation px-4 mb-5">
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={true}
        pageTitle={language.lang.confirm_consultation}
      />
      <section className="doctor-tiles row mt-4 mm-card p-2">
        <div className="col-12">
          {!loading ? (
            <DoctorTile
              practitioner={pracDetails} /*viewType={viewType}*/
              setSlotID={setSlotID}
              setPracID={setPracID}
              onSelectedSlotChanged={onSelectedSlotChanged}
              pracDetails={pracDetails}
              slotnumber={slotnumber}
              setinsuranceCount={setinsuranceCount}
            />
          ) : (
            <Loading />
          )}
        </div>
      </section>
      <section className="doctor-tiles row mt-4 mm-card p-2 provide-consultation-info">
        <div className="d-flex justify-content-start align-items-center tab-row mb-3" style={{ gap: "50px" }}>
          <div
            className="tab"
            onClick={() => {
              setviewType(false);
            }}
          >
            <span>{language.lang.slotDetails}</span>
            {!viewType && <div className="underline"></div>}
          </div>
          <div className="tab" onClick={confirmPressed}>
            <span>{language.lang.bookingInformation}</span>
            {viewType && <div className="underline"></div>}
          </div>
        </div>
        {!viewType && (
          <>
            <div className="col-12 col-md-7">
              {loadingSlots ? (
                <Loading />
              ) : (
                <DoctorInfoCard
                  slots={slots}
                  setSlotID={setSlotID}
                  setSelectedSlot={setSelectedSlot}
                  setPracID={setPracID}
                  onSelectedSlotChanged={onSelectedSlotChanged}
                  pracDetails={pracDetails}
                  selectedSlot={selectedSlot}
                  slotnumber={slotnumber}
                  setslotnumber={setslotnumber}
                />
              )}
            </div>
            <div className="col-12 col-md-5 mt-4 mt-md-0">
              <AppointmentDetailsCard selectedSlot={selectedSlot} />
              <div className="mt-3 d-flex justify-content-end">
                {slots.length === 0 ? (
                  <span></span>
                ) : btnloading ? (
                  <Loading />
                ) : (
                  <div className="d-flex justify-content-around w-100" style={{ gap: "10px" }}>
                    <button
                      //onClick={confirmPressed}
                      className="btn btn-secondary flex-grow-1 w-100 slot-info-btn"
                      type="button"
                      onClick={() => {
                        history.push(dashboardBookConsultationRoute.path);
                      }}
                    >
                      {language.lang.cancel}
                    </button>
                    <button onClick={confirmPressed} className="btn btn-primary flex-grow-1 w-100 slot-info-btn" type="button">
                      {language.lang.continue}
                      {/*language.lang["book-appointment"]*/}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {viewType && <DisplayForm />}
      </section>
      {/* <Modal size="sm" backdrop="static" show={ShowModal} onHide={CloseModal} className="modal_custom">
    <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center" style={{maxWidth:500}}>
            {ModalData}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={modalOkPressed} className="btn btn-success">
            Ok
          </button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
};

export default CustomerConfirmConsultation;
