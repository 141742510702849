import { useRef } from "react";
import EndSideMenu from "./EndSideMenu";
import { timeSince } from "../utility/Helpers";
import AcceptRejectNotificationContent from "./AcceptRejectNotificationContent";
import { v4 as uuidv4 } from "uuid";
import ViewPrescriptionButton from "./ViewPrescriptionButton";
import ViewBookConsultation from "./ViewBookConsultation";
import { useSelector } from "react-redux";
import { userRoleClient, userRoleDoctor } from "../utility/constants";
import Loading from "./Loading";
import ViewAppointment from "./ViewAppointment";
import { dashboardAppointmentsRoute } from "../routes";
import { useHistory } from "react-router-dom";

const NotificationsSideMenu = ({
  isOpened,
  onClosePressed,
  userNotifications,
}) => {
  const language = useSelector((state) => state.language);
  const show_old_notifications = useSelector(
    (state) => state.user.show_old_notifications
  );
  const old_notifications = useSelector(
    (state) => state.user.old_notifications
  );
  const loading = useSelector((state) => state.user.loadingNotifications);
  const notifyData =
    (show_old_notifications && old_notifications) ||
    (!show_old_notifications && userNotifications.notificationDetails);

  let content;
  if (loading) {
    content = <Loading />;
  } else if (notifyData?.length === 0) {
    content = (
      <div className="notification-side-menu-item">
        {language.lang["no-notifications"]}
      </div>
    );
  } else {
    content = (
      <>
        {notifyData?.map((item, index) => {
          return (
            <NotificationSideMenuItem
              key={uuidv4()}
              data={item}
              acceptRejectDetails={
                index === 0 &&
                userNotifications.details &&
                userNotifications.details
              }
            />
          );
        })}
      </>
    );
  }
  return (
    <EndSideMenu
      className="notifications-side-menu p-0"
      isOpened={isOpened}
      onClosePressed={onClosePressed}
      title={"All Notifications"}
    >
      {content}
    </EndSideMenu>
  );
};

const NotificationSideMenuItem = ({ data, acceptRejectDetails }) => {
  const history = useHistory();
  const language = useSelector((state) => state.language);
  const date = new Date(data.created_date);
  const acceptRejectDetailsRef = useRef(acceptRejectDetails);
  // console.log("", { data: data });
  const role = useSelector((state) => {

    if (state.auth.userData) {
      return state.auth.userData.role != userRoleDoctor ? true : false;
    }
    return true;
  });

  const patient = useSelector((state) => {
    if (state.auth.userData) {
      return state.auth.userData.role === userRoleClient ? true : false;
    }
    return true;
  });

  const handleButtonClick = () => {
    acceptRejectDetailsRef.current = null;
  };

  const isPractitionerStatedAppointmentNotification =
    data.notificationType && data.notificationType.name === "DoctorStartedCall";

  const viewAppointmentStartedNotificationClicked = () => {
    history.push(dashboardAppointmentsRoute.path, {
      pending: "Upcoming",
    });
  };

  const goToPendingAppointments = () => {
    history.push(dashboardAppointmentsRoute.path, {
      pending: "Pending",
    });
  };

  const translationName = data.translation && data.translation.name;

  return (
    <div className="notification-side-menu-item">
      <div className="d-flex justify-content-between w-100">
        <p className="details">{data.details}</p>
        <div className="trailing">
          <div className="date">{`${timeSince(date)}`}</div>
        </div>
      </div>
      {acceptRejectDetailsRef.current && (
        <AcceptRejectNotificationContent
          onButtonClick={handleButtonClick}
          notificationData={{ details: acceptRejectDetailsRef.current }}
        />
      )}
      {data.notificationType?.name === "PrescriptionReady" &&
        data.data &&
        data.data.prescriptionId && (
          <ViewPrescriptionButton
            presID={
              typeof data.data.prescriptionId === "string"
                ? data.data.prescriptionId
                : data.data.prescriptionId && data.data.prescriptionId.id
            }
          />
        )}
      {patient && 
        data.notificationType?.name === "ScheduledAppointmentReminder" && (
          <ViewBookConsultation/>
        )}
      {patient &&
        data.notificationType?.name === "AppointmentUpdatedByClinic" && (
          <ViewAppointment data={data} />
        )}

      {patient && translationName === "appointmentUnderVerification" && (
        <div className="d-flex justify-content-end align-items-center w-100">
          <button
            onClick={goToPendingAppointments}
            className="btn"
            style={{
              backgroundColor: "#1B68DF",
              borderRadius: "50px",
              color: "#fff",
              padding: "3px 15px",
            }}
          >
            {language.lang.view}
          </button>
        </div>
      )}

      {patient &&
        data.notificationType?.name === "AppointmentScheduled" &&
        translationName &&
        translationName !== "appointmentUnderVerification" && (
          <div className="d-flex justify-content-end align-items-center w-100">
            <button
              onClick={viewAppointmentStartedNotificationClicked}
              className="btn"
              style={{
                backgroundColor: "#1B68DF",
                borderRadius: "50px",
                color: "#fff",
                padding: "3px 15px",
              }}
            >
              {language.lang.view}
            </button>
          </div>
        )}

      {isPractitionerStatedAppointmentNotification && (
        <div className="d-flex justify-content-end align-items-center w-100">
          <button
            onClick={viewAppointmentStartedNotificationClicked}
            className="btn"
            style={{
              backgroundColor: "#1B68DF",
              borderRadius: "50px",
              color: "#fff",
              padding: "3px 15px",
            }}
          >
            {language.lang.view}
          </button>
        </div>
      )}
    </div>
  );
};

export default NotificationsSideMenu;
