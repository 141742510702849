import actions from "./actions";
import authActions from "../authenitcation/actions";

const initState = {
  request: null,
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case authActions.LOGOUT_SUCCESS:
      return initState;
    case actions.REQUEST_CREATED:
      return Object.assign({}, state, { request: payload });
    default:
      return state;
  }
};

export default reducer;
