import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form, Modal } from "react-bootstrap";
import Switch from "react-switch";
import { Select } from "antd";
// import axios from "../services/Axios";
import {
  showErrorNotification,
  showSuccessNotification,
  arr,
  uploadFile,
} from "../utility/Helpers";

import {
  storageTypeImage,
  categoryMentalHealth,
  userTypeUser,
  fileTypeImage,
  fileTypeVideo,
  storageTypeVideo,
} from "../utility/constants";
import UploadComponentHelper from "./UploadComponentHelper";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "../services/Axios";
import { settings } from "../config";

const { Option } = Select;

const UploadComponent = ({ type, show, onHide }) => {
  const [avail, setAvail] = useState(false);
  const [ispaid, setIsPaid] = useState(false);
  const { userData } = useSelector((state) => state.auth);
  const [Input, setInput] = useState({
    videoName: "",
    videoDescription: "",
  });
  const language = useSelector((state) => state.language);
  const [tag, settag] = useState([]);
  const onChangeHandler = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
    setInput((prevState) => {
      return {
        ...prevState,
        [nam]: val,
      };
    });
  };
  const handleKeyDown = (e) => {
    if (e.code === "Enter") {
      settag((oldArray) => [...oldArray, Input.videoName]);
      setInput((prevState) => {
        return {
          ...prevState,
          videoName: "",
        };
      });
    }
  };

 
  const onSelectHandler = (e) => {
    setInput((prevState) => {
      return {
        ...prevState,
        corporateId: e,
      };
    });
  };


  const uploadEmiratesID = async () => {
    if (
      Input["videoDescription"] &&
      ((avail == true && Input["corporateId"] != null) ||
        (avail == false && Input["corporateId"] == null)) &&
      tag.length > 0
    ) {
      const data = {
        storageType: storageTypeImage,
        fileType: fileTypeImage,
        userType: userTypeUser,
        userId: userData.id,
        description: Input["videoDescription"],
        category: "MentalHealth",
        isGalleryItem: true,
        isCorporate: avail,
        corporateId: Input["corporateId"],
        isPaidContent: ispaid,
        tags: tag,
      };
      if (type == "Video") {
        data["storageType"] = storageTypeVideo;
        data["fileType"] = fileTypeVideo;
      }
      //if (!emiratesIDImageRef.current) return null;
      for (let i = 0; i < stateFiles.length; i++) {
        data["fileType"] = stateFiles[i].type;
        console.log(data["fileType"]);
        data["multi-files"] = stateFiles[i];
        if (uploadFile(data)) {
          onHide(!show);
          showSuccessNotification("File Uploaded");
        }
      }
    } else {
      showErrorNotification("Please fill the form fields");
    }
  };

  let setting = {
    noClick: true,
    noKeyboard: true,
  };

  if (type === "Image") {
    setting["accept"] = "image/jpeg, image/png";
  }
  if (type === "Video") {
    setting["accept"] = "video/mp4";
    setting["maxSize"] = 104857600;
  }
  const { getRootProps, getInputProps, open, acceptedFiles } =
    useDropzone(setting);

  const [stateFiles, setStateFiles] = useState(acceptedFiles);

  useEffect(() => {
    console.log("use fe");
    setStateFiles(acceptedFiles);
  }, [stateFiles, acceptedFiles]);

  const getDropDownDetails = async () => {
    const response = await axios.get(`${settings.apiBaseURL}/getTenants`);
    console.log("resp -> ", response);
  };

  useEffect(() => {
    console.log("upload comp");
    getDropDownDetails();
  }, []);

  const removeFile = (index) => {
    console.log("index", index);
    //   acceptedFiles.splice(index,1);
    setStateFiles((prevState) => {
      return prevState.splice(index, 1);
    });
  };

  return (
    <div className="single-feature-modal">
      <Modal.Header closeButton>
        <h3 className="single-feature-heading">Upload the {type}</h3>
      </Modal.Header>
      <Row>
        <Col xs={12} lg={6}>
          <div className="tagarea">
            <Form.Label className="mb-2">
              {type} Tags <sup>*</sup>{" "}
            </Form.Label>
            <Form.Group controlId="formBasicEmail">
              <div className="tags d-flex">
                {tag &&
                  tag.map((item, i) => <span className="tag">#{item}</span>)}
              </div>
              <Form.Control
                type="text"
                className="shadow-none"
                name="videoName"
                value={Input.videoName}
                onChange={onChangeHandler}
                onKeyDown={handleKeyDown}
                required
              />
            </Form.Group>
          </div>

          <div>
            <Form.Label>
              {type} Description <sup>*</sup>{" "}
            </Form.Label>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                className="shadow-none"
                name="videoDescription"
                value={Input.videoDescription}
                onChange={onChangeHandler}
                required
              />
            </Form.Group>
          </div>

          <div>
            <Form.Label className="mb-4">
              Is Corporate Specific <sup>*</sup>{" "}
            </Form.Label>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div className="d-flex">
                <div className="d-flex">
                  <Switch
                    checked={avail}
                    onChange={(val) => {
                      setAvail(!avail);
                    }}
                    onColor="#bff5de"
                    onHandleColor="#56c596"
                    handleDiameter={15}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id="material-switch"
                  />
                  <p
                    style={{
                      marginLeft: "5px",
                      marginTop: "-2px",
                      fontWeight: "700",
                      marginRight: "10px",
                    }}
                  >
                    {language.lang.yes}
                  </p>
                </div>

                <div className="d-flex">
                  <Switch
                    checked={!avail}
                    onChange={(val) => {
                      setAvail(!avail);
                    }}
                    onColor="#bff5de"
                    onHandleColor="#56c596"
                    handleDiameter={15}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id="material-switch"
                  />
                  <p
                    style={{
                      marginLeft: "5px",
                      marginTop: "-2px",
                      fontWeight: "700",
                    }}
                  >
                    {language.lang.no}
                  </p>
                </div>
              </div>

              {avail && (
                <>
                  <Form.Label className="mb-1">Corporate </Form.Label>
                  <Select
                    name="corporate"
                    onChange={onSelectHandler}
                    style={{ width: "100%" }}
                  >
                    <Option value="1">Adnic</Option>
                    <Option value="2">Masdar</Option>
                    <Option value="3">Microsoft</Option>
                  </Select>
                </>
              )}
            </Form.Group>
          </div>

          <div>
            <Form.Label className="mb-4">
              Is Paid Content <sup>*</sup>{" "}
            </Form.Label>
            <div className="d-flex">
              <div className="d-flex">
                <Switch
                  checked={ispaid}
                  onChange={(val) => {
                    setIsPaid(!ispaid);
                  }}
                  onColor="#bff5de"
                  onHandleColor="#56c596"
                  handleDiameter={15}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="material-switch"
                />
                <p
                  style={{
                    marginLeft: "5px",
                    marginTop: "-2px",
                    fontWeight: "700",
                    marginRight: "10px",
                  }}
                >
                  {language.lang.yes}
                </p>
              </div>

              <div className="d-flex">
                <Switch
                  checked={!ispaid}
                  onChange={(val) => {
                    setIsPaid(!ispaid);
                  }}
                  onColor="#bff5de"
                  onHandleColor="#56c596"
                  handleDiameter={15}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="material-switch"
                />
                <p
                  style={{
                    marginLeft: "5px",
                    marginTop: "-2px",
                    fontWeight: "700",
                  }}
                >
                  {language.lang.no}
                </p>
              </div>
            </div>
          </div>

          {/* <div className="mb-3">
                                <Form.Label>Feature Section <sup>*</sup> </Form.Label>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="text" className="shadow-none" name="featureSection"/>
                                </Form.Group>
                            </div> */}
        </Col>

        <Col xs={12} lg={6} className="feature-col-2">
          <div>
            <Form.Label>Preview </Form.Label>
            {/* <UploadComponentHelper onChange={handleEmiratesIDImgChange} /> */}
          </div>

          <div className="cont">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <FaCloudUploadAlt className="upload--icon" />
              <p>Drag and Drop</p>
              <div className="or-block">
                <div className="or-line"></div>
                <div className="or-text">or</div>
                <div className="or-line"></div>
              </div>
              <button type="button" onClick={open} className="browse-btn">
                Browse
              </button>
            </div>
            <div className="dropbox-files">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">FileName</th>
                  </tr>
                </thead>
                <tbody>
                  {acceptedFiles.map((file, i) => {
                    console.log("hi", i);
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td
                          className="d-flex justify-content-evenly align-items-center py-1"
                          style={{ width: "375px" }}
                        >
                          <span>{file.path}</span>
                          <RiDeleteBin6Line
                            className="delete--icon"
                            onClick={(i) => removeFile(i)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
        <div className="my-3 text-right">
          <button
            className="shadow-none btn btn-primary create-btn"
            onClick={uploadEmiratesID}
          >
            Upload
          </button>
        </div>
      </Row>
    </div>
  );
};

export default UploadComponent;
