import { useState, useEffect } from "react";
import TimeSlotItem from "./TimeSlotItem";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useSelector } from "react-redux";
import WorkDaytabs from "./WorkDaytabs";

const WorkDayItems = ({
  slots,
  slotMap,
  setSlotID,
  setPracID,
  onSelectedChanged,
  bookNowPressed,
  viewAll,
  setslotnumber,
  slotnumber,
}) => {
  const [selected, setSelected] = useState([]);
  // const [selecteds, setSelecteds] = useState({});
  const [slotArr, setSlotArr] = useState([]);
  const [morningSlots, setmorningSlots] = useState([]);
  const [eveSlots, seteveSlots] = useState([]);
  const userData = useSelector((state) => state.auth.userData);
  const isLoggedIn = useSelector((state) => state.auth.login);
  const [increment, setIncrement] = useState(0);
  useEffect(() => {
    if (isLoggedIn) {
      setIncrement(userData?.user_group === "test" ? 0 : 0);
    } else {
      setIncrement(0);
    }
  }, [isLoggedIn]);
  const [slotsSelected, setSlotsSelected] = useState([]);
  const language = useSelector((state) => state.language);
  // useEffect(() => {
  //   if (!isLoggedIn && redirectTo) {
  //     showWarningNotification(loginToBookMsg);
  //   }
  // }, [redirectTo]);

  const onSelectSlot = (slotId, length, index, pracID, slot, type) => {
    //check auth here
    //  if(!isLoggedIn){
    //   setredirectTo(true);
    //   return;
    // }
    setSlotID(slotId);
    setPracID(pracID);
    // setSelectedSlot(slot);
    // setSelecteds(slot);
    let slots_arr = [];
    for (let i = 0; i < length; i++) {
      slots_arr.push(false);
    }
    slots_arr[index] = true;
    setSlotsSelected(slots_arr);
    bookNowPressed(slot);
    if (onSelectedChanged) onSelectedChanged(slots_arr);
  };

  const onClickWorkDayItem = (key) => {
    setslotnumber && setslotnumber(key);
    let arr = [];
    for (let i = 0; i < slotMap.size; i++) {
      arr.push(false);
    }
    arr[key] = true;
    setslotnumber(key);
    setSelected(arr);
    setSlotsSelected([]);
    let morningslots =
      slotArr &&
      slotArr.length > 0 &&
      slotArr[key].value
        .filter(function (element) {
          return moment(element.start_time) > moment().add(increment, "hours");
        })
        .filter((a) => moment(a.start_time).hours() < 13);
    let eveslots =
      slotArr &&
      slotArr.length > 0 &&
      slotArr[key].value
        .filter(function (element) {
          return moment(element.start_time) > moment().add(increment, "hours");
        })
        .filter((a) => moment(a.start_time).hours() >= 13);
    setmorningSlots(morningslots);
    seteveSlots(eveslots);
  };

  useEffect(() => {
    let selected_arr = [];
    for (let i = 0; i < slotMap.size; i++) {
      selected_arr.push(false);
    }
    // selected_arr[0]=true;
    setSelected(selected_arr);

    let count = 0;
    let slot_arr = [];
    slotMap.forEach((value, key) => {
      slot_arr.push({
        index: count,
        date: key,
        value: value,
      });
      count++;
    });
    setSlotArr(slot_arr);
    onClickWorkDayItem(0);
  }, [slotMap]);
  useEffect(() => {
    let morningslots =
      slotArr &&
      slotArr.length > 0 &&
      slotArr[slotnumber].value.filter(
        (element) =>
          moment(element.start_time) > moment().add(increment, "hours") &&
          moment(element.start_time).format("HH") < 13 &&
          element.is_available
      );
    let eveslots =
      slotArr &&
      slotArr.length > 0 &&
      slotArr[slotnumber].value.filter(
        (element) =>
          moment(element.start_time) > moment().add(increment, "hours") &&
          moment(element.start_time).format("HH") >= 13 &&
          element.is_available
      );
    setmorningSlots(morningslots);
    seteveSlots(eveslots);
  }, [slotArr]);
  //  if(redirectTo){
  //   return <Redirect to={clientSignInRoute.path} />;
  // }else
  const [valid, setValid] = useState(0);
  useEffect(() => {
    if (
      slotArr &&
      slotArr.length > 0 &&
      slotArr[slotnumber].value.filter(
        (element) =>
          moment(element.start_time) > moment().add(increment, "hours") &&
          moment(element.start_time).format("HH") < 13 &&
          element.is_available
      ).length === 0 &&
      slotArr &&
      slotArr.length > 0 &&
      slotArr[slotnumber].value.filter(
        (element) =>
          moment(element.start_time) > moment().add(increment, "hours") &&
          moment(element.start_time).format("HH") >= 13 &&
          element.is_available
      ).length === 0
    ) {
      setValid(valid + 1);
      onClickWorkDayItem(slotnumber + 1);
    }
  }, [slotnumber]);
  return (
    <>
      <div className="container work-day-item d-flex justify-content-start align-items-start flex-column">
        <div className="card-link res-card-link">{language.lang.pickADateAndTime}</div>
        <div className="d-flex w-100 mt-1 flex-wrap res-card-items" style={{ gap: "10px" }}>
          {slots.length > 0 ? (
            slotArr
              //.filter((a) =>moment(a.date) <= moment().add(1,'days'))
              .sort((a, b) => moment(a.date) - moment(b.date))
              .map((el, i) => {
                if (i >= valid && Math.abs(valid - i) < 3) {
                  return (
                    <WorkDaytabs
                      key={uuidv4()}
                      date={el.date}
                      onClickWorkDayItem={onClickWorkDayItem}
                      selected={selected[i]}
                      count={i}
                    />
                  );
                }
              })
          ) : (
            <div className="no-slots-available">{language.lang.noSlotsAvailable}</div>
          )}
          <div
            className="card-link ml-2 flex-grow-1 text-end view-all mb-2"
            style={{ cursor: "pointer" }}
            onClick={viewAll}
            // hidden = {slots.length === 0 ? true : false}
          >
            {slots.length > 0 ? language.lang["view-all"] :" "}
          </div>
        </div>
      </div>
      {morningSlots.length > 0 && (
        <>
          {/* <div className="card-link">{language.lang.morning}</div> */}
          <div
            className="d-flex justify-content-start w-100 mt-1 overflow-auto py-2 customize-filter-doctor"
            style={{ gap: "10px" }}
          >
            {selected.map((el, index) => {
              if (el) {
                return (
                  slotArr &&
                  slotArr.length > 0 &&
                  slotArr[index].value
                    .filter(function (element) {
                      return (
                        moment(element.start_time) >
                        moment().add(increment, "hours")
                      );
                    }) /*.filter(function (element) {return moment(element.start_time) > moment().add(increment, 'hours')}).filter((a) => moment(a.start_time).hours() < 13).filter(function (a) { if(a.is_available) return a})*/
                    .map((element, i) => {
                      if (
                        moment(element.start_time).hours() < 13 &&
                        element.is_available
                      ) {
                        return (
                          <TimeSlotItem
                            key={uuidv4()}
                            start_time={moment(element.start_time).format(
                              "hh:mm a"
                            )}
                            end_time={moment(element.end_time).format(
                              "hh:mm a"
                            )}
                            is_available={element.is_available}
                            slotId={element.slot_id}
                            onSelectSlot={onSelectSlot}
                            length={slotArr[index].value.length}
                            index={i}
                            selected={slotsSelected[i]}
                            pracID={element.practionerId}
                            slot={element}
                            type={true}
                          />
                        );
                      }
                    })
                );
              }
            })}
          </div>
        </>
      )}
      {eveSlots.length > 0 && (
        <>
          {/*<div className="card-link">{language.lang.evening}</div>*/}
          <div
            className="d-flex justify-content-start w-100 mt-1 overflow-auto py-2 customize-filter-doctor"
            style={{ gap: "10px" }}
          >
            {selected.map((el, index) => {
              if (el) {
                return (
                  slotArr &&
                  slotArr.length > 0 &&
                  slotArr[index].value
                    .filter(function (element) {
                      return (
                        moment(element.start_time) >
                        moment().add(increment, "hours")
                      );
                    }) /*.filter(function (a) { if(a.is_available) return a})*/
                    .map((element, i) => {
                      if (
                        moment(element.start_time).hours() >= 13 &&
                        element.is_available
                      ) {
                        return (
                          <TimeSlotItem
                            key={uuidv4()}
                            start_time={moment(element.start_time).format(
                              "hh:mm a"
                            )}
                            end_time={moment(element.end_time).format(
                              "hh:mm a"
                            )}
                            is_available={element.is_available}
                            slotId={element.slot_id}
                            onSelectSlot={onSelectSlot}
                            length={slotArr[index].value.length}
                            index={i}
                            selected={slotsSelected[i]}
                            pracID={element.practionerId}
                            slot={element}
                            type={false}
                          />
                        );
                      }
                    })
                );
              }
            })}
          </div>
        </>
      )}
      {/*slotsSelected.length > 0 && <div className="confirm d-flex justify-content-end mt-2" style={{width: 'min-content'}} onClick={bookNowPressed}>Confirm Booking</div>*/}
    </>
  );
};

export default WorkDayItems;
