import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config";
import { useHistory } from "react-router";
import {
  dashboardRoute,
  settingsRoute,
  cliniccalendarRoute,
  practiitionercalendarRoute,
  customerCalendarRoute,
  practitionerLiveSessionRoute,
  dashboardLiveSessionRoute,
  viewMatchesRoute,
  dashboardBookConsultationRoute,
  dashboardAppointmentsRoute,
  dashboardMealPlanRoute,
} from "../routes";
import { useSelector, useDispatch } from "react-redux";
import { clientSignInRoute } from "../routes";
import { logOutAction } from "../redux/authenitcation/actionCreator";
import SideMenuLink from "./SideMenuLink";
import { userRoleClient, userRoleClinic, userRoleDoctor } from "../utility/constants";
import { clearCalendarEventsAction } from "../redux/patient/actionCreators";
import { resetnewNotificationsAction, resetOldNotificationsAction, setsidebar } from "../redux/user/actionsCreators";
import { useWindowDimension } from "./WindowsSize";
import actions from "../redux/patient/actions";
import { FaCalendarAlt, FaSignInAlt } from "react-icons/fa";
import { BiCog, BiLogOut, BiCheckShield } from "react-icons/bi";
import axios from "../services/Axios";
import { fetchregions, UpdateGeneralSettings } from "../redux/language/actionCreators";
import { Drawer } from "antd";
import Config from "../config/index";
import RegionDropdown from "./RegionDropdown";
import { ImClock2 } from "react-icons/im";
import LanguagesDropdown from "./LanguagesDropdown";

export const countryFlagsMap = new Map();
countryFlagsMap.set(1, Config.UAEFlagIcon);
countryFlagsMap.set(2, Config.IRAQFlagIcon);
countryFlagsMap.set(3, Config.EGYPTFlagIcon);

const SideMenu = () => {
  const [width, height] = useWindowDimension();
  const [isExpanded, setIsExpanded] = useState(width > 2000);
  const [isMobile, setIsMobile] = useState(width < 2000);
  const history = useHistory();
  const language = useSelector((state) => state.language);
  const isAuth = useSelector((state) => {
    return state.auth.login;
  });
  const role = useSelector((state) => {
    return isAuth ? state.auth?.userData?.role !== userRoleDoctor && state.auth?.userData?.role !== userRoleClinic : true;
  });
  const userData = useSelector((state) => state.auth.userData);
  const isClinic = useSelector((state) => {
    return isAuth ? state.auth?.userData?.role === userRoleClinic : false;
  });
  const isPractitioner = useSelector((state) => {
    return isAuth ? state.auth?.userData?.role === userRoleDoctor : false;
  });
  const isPatient = useSelector((state) => {
    return isAuth ? state.auth?.userData?.role === userRoleClient : false;
  });
  const Side_Bar = useSelector((state) => state.user.side_bar);
  const dispatch = useDispatch();

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  const logoutPressed = () => {
    dispatch(clearCalendarEventsAction());
    dispatch(logOutAction());
    // if (role) {
    //   debugger;
    //   dispatch({ type: actions.RESET_TOP_PRACTITIONERS });
    //   dispatch(getTopPractitioners(0, 10));
    // }
    history.push(dashboardRoute.path);
  };

  const handleCancel = () => {
    setIsExpanded(false);
    dispatch(setsidebar(!Side_Bar));
  };

  useEffect(() => {
    setIsExpanded(width > 2000);
    setIsMobile(width < 2000);
  }, [width]);

  const [languages, setlangauges] = useState([]);

  const getLanguage = async () => {
    const res = await axios.get("/getProfileLanguages");
    setlangauges(res.data.response);
  };

  useEffect(() => {
    if (language.general.regions.length === 0) {
      dispatch(fetchregions());
    }
    if (languages.length === 0) {
      getLanguage();
    }
  }, []);

  const [Input, setInput] = useState({
    preferredlanguage: language.general.preferredlanguage,
    regionId: language.regionID,
    theme: language.general.theme,
  });

  const [edited, setedited] = useState(false);

  // const onSelectHandler = (value) => {
  //   var name = "";
  //   if (value === "light" || value === "dark") {
  //     name = "theme";
  //   } else {
  //     if (!isNaN(value)) {
  //       name = "regionId";
  //     } else {
  //       name = "preferredlanguage";
  //     }
  //   }
  //   setInput((prevState) => {
  //     return {
  //       ...prevState,
  //       [name]: value,
  //     };
  //   });
  //   setedited(true);
  // };

  const submitGneralProfile = () => {
    dispatch(UpdateGeneralSettings(Input));
    if (userData) {
      dispatch(resetOldNotificationsAction(userData?.id, Input.preferredlanguage));
      dispatch(resetnewNotificationsAction(userData?.id, Input.preferredlanguage));
    }
    setedited(false);
  };

  useEffect(() => {
    if (edited) {
      submitGneralProfile();
    }
  }, [Input]);

  const sideMenuContent = (
    <>
      <Link to={dashboardRoute.path} className="logo d-flex justify-content-center">
        <img className="m" src={config.logoLink} alt="IZI" />
      </Link>
      <SideMenuLink icon={<i className="far fa-home"></i>} label={language.lang.home} to={dashboardRoute.path} exact={true} />
      {isClinic && <SideMenuLink icon={<FaCalendarAlt />} label={language.lang.calendar} to={cliniccalendarRoute.path} />}
      {isPractitioner && (
        <SideMenuLink icon={<FaCalendarAlt />} label={language.lang.calendar} to={practiitionercalendarRoute.path} />
      )}
      {isPatient && <SideMenuLink icon={<FaCalendarAlt />} label={language.lang.calendar} to={customerCalendarRoute.path} />}
      {role && (
        <SideMenuLink
          icon={<i className="fas fa-phone-alt"></i>}
          label={language.lang.teleHealth}
          to={dashboardBookConsultationRoute.path}
          exact={true}
        />
      )}
      {isPractitioner && (
        <SideMenuLink
          icon={<i className="fas fa-broadcast-tower"></i>}
          label={language.lang.events} /*{language.lang["live-sessions"]}*/
          to={practitionerLiveSessionRoute.path}
          // to={practitionerLiveSessionTabRoute.path}
          exact={true}
        />
      )}
      {isAuth && role && (
        <SideMenuLink icon={<ImClock2 />} label={language.lang.appointments} to={dashboardAppointmentsRoute.path} />
      )}
      {/* {role && (
            <SideMenuLink
              icon={<i className="fas fa-heart"></i>}
              label={language.lang["most-popular"]}
              to={dashboardMostPopularNewRoute.path}
            />
          )} */}
      {/* {role && (
            <SideMenuLink
              icon={<FaCanadianMapleLeaf />}
              label={language.lang["meal-plan"]}
              to={dashboardMealPlanRoute.path}
            />
          )} */}
      {/* {role && (
            <SideMenuLink
              icon={<FaRunning />}
              label={language.lang.fitness}
              to={dashboardFitnessRoute.path}
            />
          )} */}
      {/* {role && (
            <SideMenuLink
              icon={<FaBullseye />}
              label={language.lang["zen-zone"]}
              to={dashboardMeditationYogaRoute.path}
            />
          )} */}
      {role && (
        <SideMenuLink
          icon={<i className="far fa-broadcast-tower"></i>}
          label={language.lang.events} /*{language.lang["live-sessions"]}*/
          to={dashboardLiveSessionRoute.path}
          exact={true}
        />
      )}
      {role && (
        <SideMenuLink icon={<BiCheckShield />} label={language.lang.mealPlan} to={dashboardMealPlanRoute.path} exact={true} />
      )}
      {role && (
        <SideMenuLink
          icon={<i className="fas fa-infinity"></i>}
          label={language.lang.fitnessConnect}
          to={isAuth ? viewMatchesRoute.path : clientSignInRoute.path}
          exact={true}
        />
      )}
      {/* role && <SideMenuLink
            icon={<i className="fas fa-infinity"></i>}
            label="Clinic"
            to={clinicRoute.path}
          /> */}
      {/*<SideMenuLink
            icon={<i className="far fa-heart"></i>}
            label="Wellness"
            to={dashboardWellnessRoute.path}
          />*/}
      <div className="nav-divider my-3"></div>
      <RegionDropdown isExpanded={isExpanded} isMobile={isMobile} showLabel={true} />
      <div className="my-2"></div>
      <LanguagesDropdown isExpanded={isExpanded} isMobile={isMobile} showLabel={true} />
      {/* {languages.length > 0 && (
        <SideMenuField
          name={language.lang.language}
          field={
            <Select
              onChange={onSelectHandler}
              value={general?.preferredlanguage || "en"}
              name="preferredlanguage"
              style={{
                maxWidth: isExpanded || isMobile ? "100px" : "30px",
                minWidth: isExpanded || isMobile ? "80px" : "50px",
              }}
              showArrow={isExpanded || isMobile}
            >
              {languages.length > 0 &&
                languages.map((lang) => {
                  return (
                    <Option
                      key={lang.id}
                      value={
                        lang.id === 93 ? "ku" : lang.id === 43 ? "en" : "ar"
                      }
                    >
                      {isExpanded || isMobile
                        ? lang.name
                        : lang.name.substring(0, 2).toUpperCase()}
                    </Option>
                  );
                })}
            </Select>
          }
          isExpanded={isExpanded || isMobile}
        />
      )} */}
      <div className="nav-divider my-3"></div>
      {!isAuth && <SideMenuLink icon={<FaSignInAlt />} label={language.lang.login} to={clientSignInRoute.path} />}
      {isAuth && <SideMenuLink icon={<BiCog />} label={language.lang.settings} to={settingsRoute.path} />}
      {isAuth && <SideMenuLink onClick={logoutPressed} icon={<BiLogOut />} label={language.lang.logout} />}
      {!isMobile && (
        <SideMenuLink
          className="mt-auto"
          icon={<i className="fal fa-bars"></i>}
          label={language.lang["toggle_menu"]}
          toggleCollapse={toggleCollapse}
        />
      )}
      <div className="nav-bottom-left-circle"></div>
    </>
  );

  return (
    <>
      {isMobile ? (
        <Drawer placement={"left"} closable={true} onClose={handleCancel} visible={Side_Bar} width={250}>
          <div className="page-container w-100">
            <nav className={`mm-nav position-relative overflow-hidden w-100`}>{sideMenuContent}</nav>
          </div>
        </Drawer>
      ) : (
        <nav className={`mm-nav ${!isExpanded && "nav-collapsed"} position-relative`}>{sideMenuContent}</nav>
      )}
    </>
  );
};

export default SideMenu;
