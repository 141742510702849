import axios from "axios";
import { platformType, refreshTokenStorageKey, tokenStorageKey } from "../utility/constants";
import { settings } from "../config";
import { storeAuthDataInLocalStorage } from "../utility/Helpers";
import store from "../redux/store";
import actions from "../redux/authenitcation/actions";
import { logOutAction } from "../redux/authenitcation/actionCreator";
import { dashboardRoute } from "../routes";
import { getAuth } from "firebase/auth";

const instance = axios.create({
  baseURL: settings.apiBaseURL,
  headers: {
    Accept: "application/json",
  },
});

instance.interceptors.request.use(
  (request) => {
    if (request.data && request.data.length !== 0) {
      request.data.PlatformType = platformType;
    } else {
      request.data = { PlatformType: platformType };
    }
    const accessToken = localStorage.getItem(tokenStorageKey);
    request.headers.PlatformType = platformType;
    if (accessToken && !request.headers.Authorization) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
    // store.dispatch(requestCreatedAction(request));
    return request;
  },
  (error) => {
    console.log("--- request error", error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response && err.response.data === "Forbidden" && err.response.status === 403) {
      console.log("--- interceptor invalid token", err);
      return handleInvalidTokenError(err);
    } else {
      return Promise.reject(err);
    }
    // return Promise.reject(err);
  }
);

const handleInvalidTokenError = async (error) => {
  const success = await refreshToken();
  if (success) {
    resolveOriginalRequest();
  } else {
    debugger;
    await store.dispatch(logOutAction());
    window.location.href = `${dashboardRoute.path}?sessionExpired=true`;
  }
};

const resolveOriginalRequest = () => {
  // TODO: Implement this function
  console.log("--- resolveOriginalRequest");
};

const refreshToken = async () => {
  try {
    const fireAuth = getAuth();
    const newFirebaseToken = await fireAuth.currentUser.getIdToken();
    const accessToken = localStorage.getItem(tokenStorageKey);
    const res = await instance.post("/refreshToken", {
      oldAccessToken: accessToken,
      newFirebaseToken,
    });
    debugger;
    if (!res.data.response) {
      console.log("--- refreshToken error");
      console.log(res.data);
      debugger;
      return false;
    }
    // console.log(res);
    const authData = storeAuthDataInLocalStorage(res.data.response);
    store.dispatch(actions.loginSuccess(authData));
    console.log("--- refreshToken success");
    debugger;
    return true;
  } catch (e) {
    console.log("--- refreshToken error", e);
    debugger;
    return false;
  }
};

export default instance;
// "Cannot read properties of null (reading 'getIdToken')"
// "Cannot read properties of undefined (reading 'role')
// The error may be correlated with this previous error:
// TypeError: Cannot read properties of undefined (reading 'role')
//     at Object.current (http://localhost:3000/static/js/bundle.js:51410:41)
//     at Object.checkForUpdates [as onStateChange] (http://localhost:3000/static/js/bundle.js:846747:48)
//     at Object.handleChangeWrapper [as callback] (http://localhost:3000/static/js/bundle.js:847043:20)
//     at http://localhost:3000/static/js/bundle.js:846972:20
//     at batchedUpdates$1 (http://localhost:3000/static/js/bundle.js:401087:16)
//     at Object.notify (http://localhost:3000/static/js/bundle.js:846968:7)
//     at Object.notifyNestedSubs (http://localhost:3000/static/js/bundle.js:847038:15)
//     at handleChangeWrapper (http://localhost:3000/static/js/bundle.js:847043:20)
//     at dispatch (http://localhost:3000/static/js/bundle.js:865134:7)
//     at Object.dispatch (http://localhost:3000/static/js/bundle.js:864803:16)
// "
