import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { fileTypeImage, storageTypeImage, userTypeUser } from "../utility/constants";
import { showErrorNotification, showSuccessNotification, uploadFile } from "../utility/Helpers";
import MMRadioButton from "./MMRadioButton";

const ProfileImageField = ({ onChange, url }) => {
  const [selectedImg, setSelectedImg] = useState(false);
  const [imgData, setimgData] = useState({});
  const language = useSelector((state) => state.language);
  const imgRef = useRef(null);
  let img = null;
  const handleChange = async (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    img = e.target.files[0];
    if (!img.type.startsWith("image")) {
      showErrorNotification();
      return;
    }
    try {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setSelectedImg(true);
        imgRef.current.src = fileReader.result;
      };
      fileReader.readAsDataURL(img);
      setimgData(img);
      /*const data = {
        storageType: storageTypeImage,
        fileType: fileTypeImage,
        userType: userTypeUser,
        userId: userData.id,
        description: "Profile",
        category: "None",
        isGalleryItem: false,
        isCorporate: false,
        corporateId: "None",
        isPaidContent: false,
        tags: [],
      };
       data["fileType"] = "image/png";
       data["multi-files"] = img;
       var url = await uploadFile(data);
       console.log("url",url)*/
      onChange(img);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setSelectedImg(url && true);
    if (selectedImg && url) {
      imgRef.current.src = url;
    }
  }, [url]);
  const applyFile = () => {
    if (imgData) {
      //onChange(imgData);
      //showSuccessNotification("Profile image has been applied");
    } else {
      showErrorNotification();
    }
  };
  const removeFile = () => {
    imgRef.current.src = null;
    setSelectedImg(false);
  };
  return (
    <div className="emirates-id-image-field mt-3 w-100 h-100">
      <div className="img-container"></div>
      <label htmlFor="profilePhotoUrl" className="d-flex justify-content-center align-items-center">
        {selectedImg && imgRef ? (
          <img ref={imgRef} src={url} alt={language.lang.profile} style={{"width":"100%"}}/>
        ) : (
          <i className="fas fa-cloud-upload-alt" style={{ fontSize: "200px" }}></i>
        )}
      </label>
      <div className="row">
      <div className="col">
      <div className="d-flex p2 justify-content-end">
      {selectedImg && (
        <div className="gender-select-field w-100">
        <MMRadioButton
          onClick={() => applyFile()}
          label={language.lang.apply}
          selected={true}
          classes="mr-5"
        />
          <MMRadioButton
          onClick={() => removeFile()}
          label={language.lang.cancel}
          // selected={selectedGender === 2}
        /></div>
       )
      }
      </div>
      </div>
      </div>
       <input onChange={handleChange} id="profilePhotoUrl" className="emiratesID-input" name="profilePhotoUrl" type="file" accept="image/*" />
      {/*{selectedImg && (
        <div className="w-100 d-flex justify-content-between pt-3">
          <button className="btn shadow-none setting-btn" onClick={applyFile}>
            {language.lang.apply}
          </button>
          <button className="btn shadow-none setting-btn btn-gray" onClick={removeFile}>
            {language.lang.cancel}
          </button>
        </div>
      )} */}
    </div>
  );
};

export default ProfileImageField;
