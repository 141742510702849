import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import ProfileCircleButton from "../components/ProfileCircleButton";
import NotificationsButton from "../components/NotificationsButton";
import BackButton from "../components/BackButton";
import VideoCallUserInfoCard from "../components/VideoCallUserInfoCard";
import VideoCallChatCard from "../components/VideoCallChatCard";
import { FaPlus } from "react-icons/fa";
import { Drawer } from "antd";
import { useSelector, useDispatch } from "react-redux";
import PrescriptionSection3 from "../components/PrescriptionSection3";
import VideoCallCard from "../components/VideoCallCard";
import { userRoleDoctor } from "../utility/constants";
// import { MdTimer } from "react-icons/md";
import axios from "../services/Axios";
import moment from "moment";
import TopMenu from "../components/CommonControls/TopMenu";

const VideoCallScreen = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [flag, Setflag] = useState(false);
  const [patientData, SetpatientData] = useState([""]);
  const { role } = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();

  var local = JSON.parse(localStorage.getItem("currentAppointmentDetails"));
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [medicineList, setMedicineList] = useState([]);
  const [labTestList, setLabTestList] = useState([]);
  const [list, setList] = useState([]);

  const [Input, setInput] = useState({
    fName: "",
    lName: "",
    age: "",
    gender: "",
    visit: "",
    diagnosis: "",
    allergies: "",
  });

  const handleOnInputChange = (e) => {
    //console.log({Input})
    //console.log({reduxStore})

    const nam = e.target.name;
    const val = e.target.value;
    setInput((prevState) => {
      return { ...prevState, [nam]: val };
    });
    dispatch({
      type: "UPDATE_PATIENT_PRESCRIPTION",
      payload: {
        ...Input,
        medicineList,
        labTestList,
        medication: list,
      },
    });
  };
  const dispatchInput = () => {
    let prescriptionData = {
      ...Input,
      medicineList,
      labTestList,
      medication: list,
    };
    localStorage.setItem("prescriptionForm", JSON.stringify(prescriptionData));
    dispatch({
      type: "UPDATE_PATIENT_PRESCRIPTION",
      payload: prescriptionData,
    });
  };
  const handleOk = () => {
    console.log({ Input, list });
    console.log("okay");
    console.log("Input, ", Input);
    console.log("lists - > med List, lab List, List", medicineList, labTestList, list);
    if (Input.visit && Input.diagnosis) {
      dispatchInput();
      setIsModalVisible(false);
    }
  };

  // const endCall = () => {
  //   dispatch(setPractitionerAvailability(1));
  //   if (role === userRoleDoctor) {
  //     localStorage.setItem(
  //       prescriptionAppointmentKey,
  //       JSON.stringify(reduxStore.practitioner.currentAppointment)
  //     );
  //     history.replace(dashboardRoute.path);
  //   }
  // };

  useEffect(() => {
    if (flag) {
      SetInputNow();
    } else {
      FetchPatientDetails();
    }
  }, [flag]);

  const SetInputNow = () => {
    setInput((prevState) => ({
      ...prevState,
      fName: patientData.name ? patientData.name : "-",
      lName: patientData.surname ? patientData.surname : "-",
      age: moment().diff(patientData.birth_date, "years"),
      gender: patientData.gender_id === 1 ? "Male" : "Female",
    }));
  };

  const FetchPatientDetails = async () => {
    await axios
      .get("/getPatientDetails", {
        params: { patientId: local.user_id },
      })
      .then((res) => {
        // console.log("here res", res);
        SetpatientData(res.data.response[0]);
        Setflag(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const language = useSelector((state) => state.language);

  return (
    <section className={"video-call--screen mb-3" + `${language.direction}`}>
      {/* <Prompt
        when={true}
        message={language.lang["r-u-sure-u-want-end-call"] || ""}
      /> */}
      <div className="dashboard-book-consultation">
        <TopMenu
          shouldHideBackButton={false}
          shouldHideProfileButton={false}
          shouldHideNotificationButton={false}
          shouldHideLogoButton={true}
          pageTitle={language.lang.in_consultation}
          hideCustomclass={true}
        />
      </div>
      <Row className="mt-2 mx-0">
        <Col xs={12} lg={7} className="d-flex flex-column">
          <VideoCallCard loading={flag} onOk={dispatchInput} />
        </Col>
        <Col xs={12} lg={5} className="mt-3 mt-lg-0 video-call-info-and-chat d-flex flex-column">
          {role === userRoleDoctor && (
            <>
              <div className="create-pres-modal" onClick={showModal}>
                <h3>{language.lang.uploadOrCreatePrescription}</h3>
                <div className="create-pres-plus--icon">
                  <FaPlus className="react-plus--icon" />
                </div>
              </div>
              {/* <div className="mt-2 d-flex btn-screens">
                <button className="skip-pres" onClick={endCall} disabled={!flag}>
                  <IoBanSharp style={{ margin: "-3px 6px 0 0" }} />
                  {language.lang.skip_prescription} 
                </button>
               <div>
                    <MyTimer expiryTimestamp={time} />
                </div>
              </div> */}
            </>
          )}
          <Drawer
            placement={"right"}
            closable={true}
            onClose={handleCancel}
            visible={isModalVisible}
            // width={700}
            className="appointment-prescription-drawer"
          >
            {Input && (
              <div className="p-3">
                <PrescriptionSection3
                  Input={Input}
                  handleOnInputChange={handleOnInputChange}
                  className="consultation-section-3 full-width"
                  setMedicineList={setMedicineList}
                  setLabTestList={setLabTestList}
                  setList={setList}
                  apply={true}
                  onOk={handleOk}
                />
              </div>
            )}
          </Drawer>
          <VideoCallUserInfoCard />
          <VideoCallChatCard />
        </Col>
      </Row>
    </section>
  );
};

export default VideoCallScreen;
