/* 
//Usage 
//For showing the hamburger menu inside inner pages of IZI
//This will show the Mobile Mode.
*/
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setsidebar } from "../../../redux/user/actionsCreators";
import { useWindowDimension } from "../../WindowsSize";
import ShareHamburgerButton from "../Shared/ShareHamburgerButton";
import Config from '../../../config/index';

const TopMenuHamburger = () => {
  const dispatch = useDispatch();
  const [width, height] = useWindowDimension();
  const Side_Bar = useSelector((state) => state.user.side_bar);
  const toggleCollapse = () => {
    dispatch(setsidebar(!Side_Bar));
  };
  useEffect(() => {
    setIsCollapsed(width < 2000);
  }, [width]);
  const [isCollapsed, setIsCollapsed] = useState(width < 2000);
  return (
    <>
      {isCollapsed && (
        <div
          className="d-flex justify-content-start flex-column hamburgerMenuToggle"
          onClick={toggleCollapse}
        >
        <img src={Config.hamburger} alt="Hamburger" className="circle-button"/>
        </div>
      )}
    </>
  );
};

export default TopMenuHamburger;