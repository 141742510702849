import React , {useState} from 'react';
import BackButton from "../components/BackButton";
import { Row, Col, ProgressBar, Form, Modal, Table } from "react-bootstrap";

import { MdFormatListBulleted } from "react-icons/md";
import { FaPlay, FaRegImages, FaVideo, FaFilePdf, FaPhotoVideo } from "react-icons/fa";
import { ImUpload3 } from "react-icons/im";
import { RiAdvertisementFill } from "react-icons/ri";
import { BsImages, BsFillChatSquareDotsFill, BsCardImage } from "react-icons/bs";
import { Rate } from "antd";

import SingleFeatureModal from "./SingleFeatureModal";
import ComboFeatureModal from "./ComboFeatureModal";
import UploadComponent from "./UploadComponent";
import { useEffect } from 'react';
import axios from "../services/Axios";
import { settings } from "../config";
import UploadImage from './UploadImage';
import UploadBanner from './UploadBanner';
import UploadVideo from './UploadVideo';
import { useDispatch, useSelector } from 'react-redux';
import { setSingleFeature } from '../redux/superAdmin/actionCreators';
import Loading from './Loading';
const arr = ["Upload Video", "Upload Image", "Upload Documents", "Upload Animations", "Upload Banners"];



const ContentManager = () => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => setIsModalVisible(true);
    const handleCancel = () => setIsModalVisible(false);
    const [type, setType] = useState('image');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [uploadModal, setUploadModal] = useState(false);
    const openUploadModal = () => setUploadModal(true);
    const closeUploadModal = () => setUploadModal(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalComboShow, setModalComboShow] = useState(false);
    const language = useSelector((state) => state.language);
    const icons = [
        <FaVideo color="#fdac54" size={25} style={{cursor: 'pointer'}} onClick={()=>{
            setType('Video');
            openUploadModal();
            } 
        }/>,
        <BsCardImage color="#fdac54" size={25} style={{cursor: 'pointer'}} onClick={()=>{
            setType('Image');
            openUploadModal();
            } 
        }/>,
        <FaFilePdf color="#fdac54" size={25} style={{cursor: 'pointer'}} onClick={()=>{
            setType('Document');
            openUploadModal();
            }} />,
        <FaPhotoVideo color="#fdac54" size={25} style={{cursor: 'pointer'}} onClick={()=>{
            setType('Animation');
            openUploadModal();
            }}/>,
        <RiAdvertisementFill color="#fdac54" size={25} style={{cursor: 'pointer'}} onClick={()=>{
            setType('Banner');
            openUploadModal();
            }}/>,
    ];
    return (
        <div>
            <div className={"col-12 notifications-and-profile mt-3 mt-md-0 justify-content-between mx-2"+`${language.direction}`}>
                <div>
                    <BackButton />
                    <span className="consultation-heading">{language.lang.settings}</span>
                </div>
            </div>

            <div className="content-manager-heading">
                <h4>Content Manager</h4>
            </div>
            <Row className="content-manager-card">
                <Col xs={12} md={9}>
                    <h4 className="card-heading">At a Glance</h4>
                    
                    <div className="storage-card">
                        <h3 className="storage-card-heading">Storage Space</h3>

                        <div className="progress-block">
                            <ProgressBar now={68} />
                            <div className="storage-details">
                                <div>Storage 68%</div>
                                <div>6.80 GB of 10GB</div>
                            </div>
                        </div>

                        <div className="documents-block">
                                <div className="document-block--img">
                                    <div className="rounded-circle">
                                            <MdFormatListBulleted />
                                    </div>
                                    <div className="document-detail">
                                            302 documents
                                    </div>
                                </div>

                                <div className="document-block--img">
                                    <div className="rounded-circle">
                                            <FaPlay />
                                    </div>
                                    <div className="document-detail">
                                            140 videos
                                    </div>
                                </div>

                                <div className="document-block--img">
                                    <div className="rounded-circle">
                                            <FaRegImages />
                                    </div>
                                    <div className="document-detail">
                                            485 images
                                    </div>
                                </div>
                        </div>

                    </div>
                    
                    <div className="card-btn-block">
                        <button className="card-btn" onClick={showModal}>Create a Combo Feature</button>
                        <button className="card-btn" onClick={() => setModalComboShow(true)}>Open a Combo Features</button>
                        <button className="card-btn" onClick={handleShow}>Create Single Feature</button>
                        <button className="card-btn" onClick={() => setModalShow(true)}>Open Single Features</button>

                    </div>
                </Col>
                
                <Col xs={12} md={3} className="file-uploads-block">
                    <div>
                    {
                        arr.map((el, i)=>{
                            return <>
                                <div className="file-uploads-icon">
                                    {/* <ImUpload3 color="#fdac54" size={25} /> */}
                                    {icons[i]}
                                </div>
                                <h3 className="file-uploads-heading">
                                    {el}
                                </h3>
                            </>
                        })
                    }
                    </div>
                </Col>

            </Row>

            {/* <Modal visible={isModalVisible} onCancel={handleCancel}> */}
                {/* <SingleFeatureModal /> */}
            {/* </Modal> */}

                <Modal show={show} onHide={handleClose} size="lg">
                    <SingleFeatureModal data={[]} hide={setShow}/>
                </Modal>

                 <Modal show={isModalVisible} onHide={handleCancel} size="lg">
                    <ComboFeatureModal />
                </Modal>

                 <Modal show={uploadModal} onHide={closeUploadModal} size="lg">
                    {type === "Image" ? (<UploadImage
                        type={type}
                        show={uploadModal}
                        onHide={closeUploadModal}
                    />) :type === "Banner" ? (<UploadBanner
                        type={type}
                        show={uploadModal}
                        onHide={closeUploadModal}
                    />):type === "Video" ? (<UploadVideo
                        type={type}
                        show={uploadModal}
                        onHide={closeUploadModal}
                    />) :(<UploadComponent
                        type={type}
                        show={uploadModal}
                        onHide={closeUploadModal}
                    />)}
                </Modal>

                <SingleFeatureModals  show={modalShow} onHide={() => setModalShow(false)} />
                <SingleComboModals show={modalComboShow} onHide={() => setModalComboShow(false)}  />
        </div>
    );
}
function SingleFeatureModals(props) {
    const [data, Setdata] = useState(['']);
    const [modalShows, setModalShows] = useState(false);
    const [firstval,setfirstval] = useState('');
    const [perData, SetperData] = useState([]);
    const language = useSelector((state) => state.language);
    const SingleFeatures = useSelector((state) => state.superAdmin.single_features);
    const [ pageLoading, setpageLoading] = useState(false);
    const [offset, setoffset] = useState(5);
    const dispatch = useDispatch();
    useEffect(() => {
        SingleFeatures && SingleFeatures.length === 0 && dispatch(setSingleFeature(offset));
    },[SingleFeatures])
    const getperData = async () =>{
        await axios.get(`/getSingleFeatureById?featureId=${firstval}`) .then(function (response) {
            SetperData(response.data.response);
        })
        .catch(function (response) {
            console.log(response);
        })
    }
    useEffect(() => {
       getperData();
    },[modalShows]);
    const handleScroll = (event) => {
        setpageLoading(true);
        const target = event.target;
        if(target.scrollHeight - target.scrollTop === target.clientHeight){
         console.log("fetch single features --")
            setoffset(offset+5);
           dispatch(setSingleFeature(offset+5))
        }
      }
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Single Feature Items 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow:'auto', maxHeight: '600px'}} onScroll={handleScroll}>
        <Table striped bordered hover>
            <thead>
            <tr>
            <th>id</th>
            <th>{language.lang.name}</th>
            <th>Main</th>
            <th>Sub</th>
            <th>Content</th>
            <th>Description</th>
            <th>Rating</th>
            <th>Popular</th>
            <th>Tag</th>
            </tr>
        </thead>
        <tbody>
        {SingleFeatures .length > 0? SingleFeatures.map((feat,i)=>{
              return(
            <tr key={i}  onClick={() => {
                setfirstval(feat.id)
                setModalShows(true);
                }}>
                <td>{i}</td>
                <td>{feat.name}</td>
                <td>{feat.featureSection.name}</td>
                <td>{feat.description_sub_header}</td>
                <td>{feat.description_content}</td>
                <td>{feat ? feat.featureInfo ? feat.featureInfo.feature_description : '' : '' }</td>
                <td>{feat ? feat.rating ? feat.rating.aggregate ? feat.rating.aggregate.avg ?feat.rating.aggregate.avg.rating : '' :'' : '' : '' /* !loading ? feat.rating.aggregate.avg :'' */}</td>
                <td>{feat.is_most_popular? 'Yes' : 'No'}</td>
                <td>{feat.tag}</td>
            </tr>)
        }) : ''}
        </tbody>                                                              
        </Table>
        {pageLoading &&  <div className="d-flex justify-content-center align-items-center w-100"><Loading  /></div>}
        </Modal.Body>
        <Modal show={modalShows} onHide={setModalShows} size="lg">
        {perData &&  <SingleFeatureModal data={perData} />}
        </Modal>
        
      </Modal>
    );
  }
  
  function SingleComboModals(props) {
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [firstval,setfirstval] = useState('');
    const [perData, SetperData] = useState(['']);
    const [demoData, SetdemoData] = useState(['']);
    const language = useSelector((state) => state.language);
    const fillTable = async () =>{
        await axios.get(`${settings.apiBaseURL}/getComboFeatures`) .then(function (response) {
            SetdemoData(response.data.response);
        })
        .catch(function (response) {
            console.log(response);
        })
        .finally(() => {
            setLoading(true);
          });
    }
    const getperData = async () =>{
        await axios.get(`${settings.apiBaseURL}/getComboFeatureById?featureId=${firstval}`) .then(function (response) {
            SetperData(response.data.response);
        })
        .catch(function (response) {
            console.log(response);
        });
    }
    useEffect(() => {
        fillTable();
    },[loading])
    useEffect(() => {
        getperData();
    },[isModalVisible]);
    const Subprint = (sub,j,i) => {
        if(!(sub.length>0)) return;
        return (
            <tr key={j} onClick={() => {
                    setfirstval(demoData[i].id);
                    setIsModalVisible(true);
                }}>
                <td>Feature {j+1}</td>
                <td>{sub && sub.features && sub.features[0] && sub.features[0].name}</td>
                <td>{sub && sub.features && sub.features[0] && sub.features[0].description_main_header}</td>
                <td>{sub && sub.features && sub.features[0] && sub.features[0].description_sub_header}</td>
                <td>{sub && sub.features && sub.features[0] && sub.features[0].description_content}</td>
                <td>{sub && sub.features && sub.features[0] && sub.features[0].featureInfo.feature_description }</td>
                <td>{sub && sub.features && sub.features[0] && sub.features[0].rating.aggregate.avg.rating ? sub.features[0].rating.aggregate.avg.rating : 0}</td>
                <td>{sub && sub.features && sub.features[0] && sub.features[0].is_most_popular ? 'Yes' : 'No'}</td>
                <td>{sub && sub.features && sub.features[0] && sub.features[0].tag}</td>
            </tr>
        )
    }
    let itemsToRender;
    if(demoData) {
    itemsToRender=demoData.map((feat,i)=>{
        return(
            <>
            <tr style={{whiteSpace: 'nowrap'}}>Combo Features {i+1}</tr>
            {feat.comboFeatures && feat.comboFeatures.map((sub,j) =>Subprint(sub,j,i))}
             </> 
             )
    })
  } 
  else{
      itemsToRender = "Loading...";
  }
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
         Combo Feature
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow:'auto'}}>
        <Table striped bordered hover>
            <thead>
            <tr>
            <th>id</th>
            <th>{language.lang.name}</th>
            <th>Main</th>
            <th>Sub</th>
            <th>Content</th>
            <th>Description</th>
            <th>Rating</th>
            <th>Popular</th>
            <th>Tag</th>
            </tr>
        </thead>
        <tbody>
        {itemsToRender}
        </tbody>                                                              
        </Table>
        </Modal.Body>
       {perData &&  <Modal show={isModalVisible} onHide={setIsModalVisible} size="lg">
         <ComboFeatureModal data ={perData} />
        </Modal> }
      </Modal>
    );
  }
export default ContentManager;