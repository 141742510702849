import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer } from "antd";
import Loading from "./Loading";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { BsChevronDown } from "react-icons/bs";
import InputFieldFormat from "./InputFieldFormat";
import { RiCloseCircleLine } from "react-icons/ri";
import { countryFlagsMap } from "./SideMenu";
import RegionDropdown from "./RegionDropdown";
import { FaLanguage } from "react-icons/fa";

const FilterSideMenu = ({
  isOpened,
  onClosePressed,
  formData,
  setFormData,
  getLanguage,
  getRegions,
  setRegionSelected,
  minFeeHandler,
  maxFeeHandler,
  languageIdHandler,
  genderIdHandler,
  locationHandler,
  consultantTypeHandler,
  regions,
  RegionSelected,
  genderIdoption,
  setDefaultValue,
  consultantTypeoption,
  specialities,
  speciality,
  specialityHandler,
  InsuranceProviderSelected,
  getInsuranceProvider,
  insuranceHandler,
  setInsuranceProviderSelected,
  setspeciality,
  setconsultantTypeoption,
  setgenderIdoption,
  setfilterCounter,
  setcounter,
  setCombo,
  closeInsuranceProviderSelected,
  closeSpecialitySelected,
  langSelected,
  setlangSelected,
  closeLanguageProviderSelected
}) => {
  const language = useSelector((state) => state.language);
  const [loading, setloading] = useState(true);
  let defaultData = {
    specialityId: "",
    consultantType: "",
    genderId: "",
    feesMin: "",
    feesMax: "",
    regionId: "",
    userGroup: "",
    offset: 0,
    insuranceProviderId: "",
    comboInput: "",
  };
  const [consultantTypeoptions, setconsultantTypeoptions] = useState([
    { name: language.lang.video, value: language.lang.video },
  ]);
  const [genderIdoptions, setgenderIdoptions] = useState([
    { name: language.lang.male, value: 1 },
    { name: language.lang.female, value: 2 },
    { name: language.lang.any, value: 0 },
  ]);
  useEffect(() => {
    setgenderIdoptions([
      { name: language.lang.male, value: 1 },
      { name: language.lang.female, value: 2 },
      { name: language.lang.any, value: 0 },
    ]);
    setconsultantTypeoptions([
      { name: language.lang.video, value: language.lang.video },
    ]);
  }, [language]);

  const getPractitionersByFilters = () => {
    /*console.log( newformData)
    setFormData((prevState) => {
      return{
          ...prevState,
           newformData
      }
  });*/
    handleClose();
  };

  const handleClose = (e) => {
    onClosePressed();
  };
  // const languageIdHandler = (value, e) => {
  //   setlangSelected({ name: e.name, value: value });
  //   setFormData((prevState) => {
  //     return {
  //       ...prevState,
  //       languageId: value,
  //       offset: 0,
  //     };
  //   });
  // };
  const regionsDropdownOptions = language.general.regions.map((item, i) => {
    return {
      value: item.id,
      name: (
        <div className="d-flex justify-content-start align-items-center">
          <img src={countryFlagsMap.get(i + 1)} width="30px" className="mr-2" />{" "}
          {item.name}{" "}
        </div>
      ),
    };
  });
  // let selectedRegion = language.general.regions.find(
  //   (item) => item.id === language.general.regionId
  // );
  // selectedRegion = {
  //   name: selectedRegion?.name,
  //   value: selectedRegion?.id,
  // };

  // console.log("--- regions", regionsDropdownOptions, selectedRegion);

  // const removeItems = () => {
  //   setDefaultValue();
  //   setFormData((prevState) => {
  //     return {
  //       ...prevState,
  //       ...defaultData,
  //     };
  //   });
  // };

  return (
    <Drawer
      placement={"right"}
      closable={true}
      onClose={handleClose}
      visible={isOpened}
      width={350}
      className="filter-side-menu"
    >
      <div className="filters-side-menu slim-scroll mt-2 ml-2 mr-2">
        <div className="mt-2">
          <h4 className="title py-2">{language.lang.speciality}</h4>
          <InputFieldFormat
            show={true}
            element={
              <SelectSearch
                value={speciality}
                placeholder={language.lang.selectSpecialty}
                filterOptions={fuzzySearch}
                search={true}
                options={specialities}
                onChange={specialityHandler}
              />
            }
            icon={
              <button
                disabled={Object.keys(speciality).length === 0}
                className="btn position-absolute"
                style={{
                  right: "20px",
                  zIndex: 2,
                  color: "#1B68DF",
                  width: "min-content",
                }}
                onClick={closeSpecialitySelected}
              >
                {Object.keys(speciality).length === 0 ? (
                  <BsChevronDown size={25} />
                ) : (
                  <RiCloseCircleLine size={25} />
                )}
              </button>
            }
          />
        </div>
        <div className="position-relative mt-2">
          <h4 className="title py-2">{language.lang.insurance}</h4>
          <InputFieldFormat
            show={true}
            element={
              <SelectSearch
                placeholder={language.lang.selectInsurance}
                value={InsuranceProviderSelected}
                filterOptions={fuzzySearch}
                search={true}
                options={getInsuranceProvider}
                onChange={insuranceHandler}
              />
            }
            icon={
              <button
                disabled={Object.keys(InsuranceProviderSelected).length === 0}
                className="btn position-absolute"
                style={{
                  right: "20px",
                  zIndex: 2,
                  color: "#1B68DF",
                  width: "min-content",
                }}
                onClick={closeInsuranceProviderSelected}
              >
                {Object.keys(InsuranceProviderSelected).length === 0 ? (
                  <BsChevronDown size={25} />
                ) : (
                  <RiCloseCircleLine size={25} />
                )}
              </button>
            }
          />
        </div>
        <div className="position-relative mt-2">
          <h4 className="title py-2">{language.lang.searchByLanguage}</h4>
          <InputFieldFormat
              show={Object.keys(langSelected).length > 0}
              element={<SelectSearch 
                value={langSelected} 
                placeholder={language.lang.searchByLanguage} 
                filterOptions={fuzzySearch} 
                search={true} 
                options={getLanguage} 
                onChange={languageIdHandler} />}
              // preIcon={<FaLanguage size={30} />}
              icon={
                <button
                  disabled={Object.keys(langSelected).length === 0}
                  className="btn position-absolute"
                  style={{
                    right: "20px",
                    zIndex: 2,
                    color: "#1B68DF",
                    width: "min-content",
                  }}
                  onClick={closeLanguageProviderSelected}
                >
                  {Object.keys(langSelected).length === 0 ? (
                    <BsChevronDown size={25} />
                  ) : (
                    <RiCloseCircleLine size={25} />
                  )}
                </button>
              }
            />
        </div>
        <div className="mt-2">
          <h4 className="title py-2">{language.lang["consult-type"]}</h4>
          <InputFieldFormat
            show={true}
            element={
              <SelectSearch
                value={consultantTypeoption}
                placeholder={language.lang.consultationType}
                filterOptions={fuzzySearch}
                search={false}
                options={consultantTypeoptions}
                // onChange={consultantTypeHandler}
              />
            }
            icon={
              <button
                disabled={Object.keys(consultantTypeoption).length === 0}
                className="btn position-absolute"
                style={{
                  right: "20px",
                  zIndex: 2,
                  color: "#1B68DF",
                  width: "min-content",
                }}
                // onClick={() => {
                //   setconsultantTypeoption({});
                //   setFormData((prevState) => {
                //     return {
                //       ...prevState,
                //       consultantType: "",
                //       offset: 0,
                //     };
                //   });
                //   setcounter((prevState) => {
                //     return {
                //       ...prevState,
                //       consultantType: false,
                //     };
                //   });
                //   setfilterCounter((prevState) => prevState - 1);
                // }}
              >
                {/* {Object.keys(consultantTypeoption).length === 0 ? (
                  <BsChevronDown size={25} />
                ) : (
                  <RiCloseCircleLine size={25} />
                )} */}
              </button>
            }
          />
        </div>
        <div className="position-relative mt-2">
          <h4 className="title py-2">{language.lang.location}</h4>
          {/* <InputFieldFormat
            show={true}
            element={
              <SelectSearch
                value={selectedRegion}
                placeholder="Select Location"
                filterOptions={fuzzySearch}
                search={true}
                options={regionsDropdownOptions}
                onChange={locationHandler}
              />
            }
            icon={
              <button
                className="btn position-absolute"
                style={{
                  right: "20px",
                  zIndex: 2,
                  color: "#1B68DF",
                  width: "min-content",
                }}
                onClick={() => {
                  setRegionSelected({});
                  setFormData((prevState) => {
                    return {
                      ...prevState,
                      regionId: "",
                      offset: 0,
                    };
                  });
                  setcounter((prevState) => {
                    return {
                      ...prevState,
                      regionId: false,
                    };
                  });
                  setfilterCounter((prevState) => prevState - 1);
                }}
              >
                {Object.keys(RegionSelected).length === 0 ? (
                  <BsChevronDown size={25} />
                ) : (
                  <RiCloseCircleLine size={25} />
                )}
              </button>
            }
          /> */}
          <RegionDropdown isExpanded={true} showLabel={false} />
        </div>
        {/*<div className="position-absolute use-location w-100 d-flex justify-content-end align-items-center mb-1">Use my current location</div> */}
        {/*<div className="mt-2">
        <h4 className="title">{language.lang.languages}</h4>
         <SelectSearch filterOptions={fuzzySearch} search={true} options={getLanguage} onChange={languageIdHandler}/>
      </div>*/}
        {/* <div className="mt-2">
        <h4 className="title">Location Area</h4>
                <Select className="w-100 selectfield">
                     <Option value="orthopedic">Orthopedic</Option>
                </Select>
      </div>*/}

        <div className="mt-2">
          <h4 className="title py-2">{language.lang.practitionerGender}</h4>
          <InputFieldFormat
            show={true}
            element={
              <SelectSearch
                value={genderIdoption}
                placeholder={language.lang.selectGender}
                filterOptions={fuzzySearch}
                search={false}
                options={genderIdoptions}
                onChange={genderIdHandler}
              />
            }
            icon={
              <button
                disabled={Object.keys(genderIdoption).length === 0}
                className="btn position-absolute"
                style={{
                  right: "20px",
                  zIndex: 2,
                  color: "#1B68DF",
                  width: "min-content",
                }}
                onClick={() => {
                  setgenderIdoption({});
                  setFormData((prevState) => {
                    return {
                      ...prevState,
                      genderId: "",
                      offset: 0,
                    };
                  });
                  setcounter((prevState) => {
                    return {
                      ...prevState,
                      genderId: false,
                    };
                  });
                  setfilterCounter((prevState) => prevState - 1);
                }}
              >
                {Object.keys(genderIdoption).length === 0 ? (
                  <BsChevronDown size={25} />
                ) : (
                  <RiCloseCircleLine size={25} />
                )}
              </button>
            }
          />
        </div>

        <div className="mt-2">
          <h4 className="title py-2">{language.lang.consultationFees}</h4>
          <div
            className="d-flex justify-content-around"
            style={{ gap: "20px" }}
          >
            <input
              name="feesMin"
              type="number"
              placeholder={language.lang.minimum}
              value={formData && formData.feesMin}
              onChange={minFeeHandler}
              className="neumorphic-input addSearchStyles"
              style={{ maxWidth: "150px" }}
              min={0}
              max={1000}
              step={10}
            />
            <input
              name="feesMax"
              type="number"
              placeholder={language.lang.maximum}
              value={formData && formData.feesMax}
              onChange={maxFeeHandler}
              className="neumorphic-input addSearchStyles"
              style={{ maxWidth: "150px" }}
              min={0}
              max={1000}
              step={10}
            />
            {/*<Select className="w-100 selectfield" value={newformData.feesMin} onChange={minFeeHandler}>
                     <Option value="orthopedic">Orthopedic</Option>
                </Select>
                <Select className="w-100 selectfield" value={newformData.feesMax}  onChange={maxFeeHandler}>
                     <Option value="orthopedic">Orthopedic</Option>
        </Select>*/}
          </div>
        </div>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center mt-3"
            style={{ gap: 20 }}
          >
            <button
              type="button"
              className="filter-apply-btn"
              onClick={getPractitionersByFilters}
            >
              {language.lang.apply}
            </button>
            <button
              type="button"
              className="filter-cancel-btn"
              onClick={/*removeItems*/ handleClose}
            >
              {language.lang.cancel}
            </button>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </Drawer>
  );
};

const FilterCheckBox = ({ label, selected }) => {
  return (
    <div className={`filter-checkbox ${selected ? "selected" : ""}`}>
      <span className="label">{label}</span>
      <div className="checkbox">
        {selected && <i className="far fa-check"></i>}
      </div>
    </div>
  );
};

export default FilterSideMenu;
