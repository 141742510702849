import { useSelector } from "react-redux";
import config from "../config";
const ClinicProfileCard = () => {
  const userData = useSelector((state) => state.auth.userData);
  return (
    <div className="mm-card doctor-dashboard-card min-card-width">
      <img className="doctor-img" src={userData.profile_photo_url || config.logoLink} alt={"Cleaveland"} style={{objectFit:'contain'}}/>
      <h4 className="doctor-name mt-3">{userData.name}</h4>
    </div>
  );
};

export default ClinicProfileCard;
