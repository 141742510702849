import { Redirect } from "react-router-dom";
import Loading from "../components/Loading";
import useCompleteEmailLinkSignin from "../customHooks/useCompleteEmailLinkSignin";
import { dashboardRoute } from "../routes";

const CompleteEmailLinkSignin = () => {
  const [_, isEmailSigninLink] = useCompleteEmailLinkSignin();
  if (!isEmailSigninLink) {
    return <Redirect to={dashboardRoute.path} />;
  }

  return (
    <div className="mt-5 d-flex justify-content-center w-100">
      <Loading />
    </div>
  );
};

export default CompleteEmailLinkSignin;
