import { settings } from "../../config";
import store from "../store";
import actions from "./actions";
import axios from "../../services/Axios";

export const refreshConversationList = (offset, limit) => {
  let endURL = `/getUserConversations?offset=${offset}&limit=${limit}`;
  return async (dispatch) => {
    try {
      const res = await axios.get(`${settings.apiBaseURL}/${endURL}`);
      const data = res.data.response.conversations.filter(
        (result) =>
          result.users[0].userDetail !== null &&
          result.users[1].userDetail !== null
      );

      data.sort(function (a, b) {
        if (a.lastMessage.length !== 0 && b.lastMessage.length !== 0) {
          return (
            myParseDate(b.lastMessage[0].date_time) -
            myParseDate(a.lastMessage[0].date_time)
          );
        } else if (a.lastMessage.length > 0) return -1;
        else if (b.lastMessage.length > 0) return 1;
        else return 0;
      });
      if (res.status === 200) {
        if (data.length !== 0) {
          dispatch({
            type: actions.GET_USER_CONVERSATION,
            payload: res ? { userConversations: data } : null,
          });
          dispatch({
            type: actions.UPDATE_IS_NEXT_CONVERSATION_REFRESHED,
            payload: { isConversationRefreshed: true },
          });
        } else {
          dispatch({
            type: actions.UPDATE_IS_NEXT_CONVERSATION_REFRESHED,
            payload: { isConversationRefreshed: false },
          });
        }
      }
    } catch (e) {
      console.log("Error getting the user conversation", e);
    }
  };
};
const myParseDate = (date_string) => {
  return new Date(date_string);
};

export const getUserConversations = (offset, limit) => {
  let endURL = `/getUserConversations?offset=${offset}&limit=${limit}`;
  return async (dispatch) => {
    try {
      dispatch({
        type: actions.UPDATE_LIVE_CHAT_PAGE_LOADING,
        payload: { isLiveChatPageLoading: true },
      });
      const res = await axios.get(`${settings.apiBaseURL}/${endURL}`);
      const data = res.data.response.conversations.filter(
        (result) =>
          result.users[0].userDetail !== null &&
          result.users[1].userDetail !== null
      );
      data.sort(function (a, b) {
        if (a.lastMessage.length !== 0 && b.lastMessage.length !== 0) {
          return (
            myParseDate(b.lastMessage[0].date_time) -
            myParseDate(a.lastMessage[0].date_time)
          );
        } else if (a.lastMessage.length > 0) return -1;
        else if (b.lastMessage.length > 0) return 1;
        else return 0;
      });
      let defaultChatUser = data.length === 0 ? {} : data[0];
      if (res.status === 200) {
        dispatch({
          type: actions.SET_DEFAULT_CHAT_USER,
          payload: { defaultChatUser: defaultChatUser },
        });
        dispatch({
          type: actions.GET_USER_CONVERSATION,
          payload: res ? { userConversations: data } : null,
        });
        dispatch({
          type: actions.UPDATE_LIVE_CHAT_PAGE_LOADING,
          payload: { isLiveChatPageLoading: false },
        });
      }
    } catch (e) {
      dispatch({
        type: actions.UPDATE_LIVE_CHAT_PAGE_LOADING,
        payload: { isLiveChatPageLoading: false },
      });
      console.log("Error getting the user conversation", e);
    }
  };
};

export const getMatchedUsers = (offset, limit) => {
  const statusId = 34;
  let endURL = `/getMatchUsers?statusId=${statusId}`;
  return async (dispatch) => {
    try {
      const res = await axios.get(`${settings.apiBaseURL}/${endURL}`);
      const data = res.data.response.filter(
        (matchedResults) => matchedResults.matchUser !== null
      );
      if (res.status === 200) {
        dispatch({
          type: actions.GET_MATCHED_USERS,
          payload: res ? { matchedUsers: data } : null,
        });
        dispatch({
          type: actions.UPDATE_LIVE_CHAT_PAGE_LOADING,
          payload: { isLiveChatPageLoading: false },
        });
      }
    } catch (e) {
      console.log("Error getting the matches", e);
    }
  };
};

export const deleteBuddyDetailsInMatches = (currentBuddyDetails) => {
  const state = store.getState();
  let allMatches = state.patientDetails.allMatches;
  var index = allMatches
    .map((x) => {
      return x.id;
    })
    .indexOf(currentBuddyDetails.id);
  allMatches.splice(index, 1);
  return async (dispatch) => {
    dispatch({
      type: actions.UPDATE_REJECTED_USER_DETAILS,
      payload: currentBuddyDetails,
    });
    dispatch({
      type: actions.GET_ALL_THE_MATCHES,
      payload: { allMatches: allMatches },
    });
    dispatch({
      type: actions.UPDATE_VIEW_MATCHES_LOADING,
      payload: { isViewMatchesLoading: false },
    });
  };
};
export const updateCurrentBuddyDetails = (allMatches) => {
  return async (dispatch) => {
    let currentBuddyDetails = {};
    const data = allMatches[Math.floor(Math.random() * allMatches.length)];
    currentBuddyDetails = {
      id: data.id,
      name: data.name,
      age: data.age,
      profilePhotoUrl: data.profile_photo_url,
      locationName: data.location_name,
      matchMeWith: data.match_me_with,
      isSwipeWithFriends: data.is_swipe_with_friends,
      aboutInfo: data.about_info,
      interestsIdArray: data.userInterests.map((value) => {
        return value.interest.id;
      }),
      userInterests: data.userInterests,
      distance: data.distance,
      isVaccinated: data.is_vaccinated,
    };
    dispatch({
      type: actions.VIEW_BUDDY_DETAILS,
      payload: {
        currentBuddyDetails: currentBuddyDetails,
        isViewMatchesLoading: false,
      },
    });
  };
};
export const findFitnessBuddyMatches = (offset, limit) => {
  let endURL = `/findFitnessBuddyMatches`;

  return async (dispatch) => {
    let currentBuddyDetails = {};
    try {
      dispatch({
        type: actions.UPDATE_VIEW_MATCHES_LOADING,
        payload: { isViewMatchesLoading: true },
      });
      const res = await axios.get(`${settings.apiBaseURL}/${endURL}`);
      if (res.status === 200 && res.data.response.length !== 0) {
        const data =
          res.data.response[
            Math.floor(Math.random() * res.data.response.length)
          ];
        currentBuddyDetails = {
          id: data.id,
          name: data.name,
          age: data.age,
          profilePhotoUrl: data.profile_photo_url,
          locationName: data.location_name,
          matchMeWith: data.match_me_with,
          isSwipeWithFriends: data.is_swipe_with_friends,
          aboutInfo: data.about_info,
          interestsIdArray: data.userInterests.map((value) => {
            return value.interest.id;
          }),
          userInterests: data.userInterests,
          distance: data.distance,
          isVaccinated: data.is_vaccinated,
        };
      }
      dispatch({
        type: actions.GET_ALL_THE_MATCHES,
        payload: { allMatches: res.data.response },
      });

      dispatch({
        type: actions.VIEW_BUDDY_DETAILS,
        payload: {
          currentBuddyDetails: currentBuddyDetails,
          isViewMatchesLoading: false,
        },
      });
    } catch (err) {
      dispatch({
        type: actions.UPDATE_VIEW_MATCHES_LOADING,
        payload: { isViewMatchesLoading: false },
      });
      return err.response.data.error;
    }
  };
};
export const getPatientDetails = (offset, limit) => {
  const state = store.getState();
  const userID = state.auth.userData && state.auth.userData.id;
  let endURL = `/getPatientDetails?patientId=${userID}`;
  return async (dispatch) => {
    dispatch({
      type: actions.UPDATE_SETTINGS_LOADING,
      payload: { isSettingsLoading: true },
    });
    dispatch({
      type: actions.UPDATE_FETCHED_USER_DETAILS,
      payload: { isFetchedUserDetails: false },
    });
    const res = await axios.get(`${endURL}`);
    const data = res.data.response.errors ? null : res.data.response[0];
    // console.log("--- getPatientDetails", data, data?.match_me_with);
    const userDetails = {
      isAdult: true,
      name: data?.name,
      surName: data?.surname === null ? "" : data?.surname,
      profilePhotoUrl:
        data?.profile_photo_url === null ? "" : data?.profile_photo_url,
      location: data?.location === null ? [] : data?.location?.coordinates,
      locationName: data?.location_name === null ? "" : data?.location_name,
      matchMeWith:
        data?.match_me_with === null ? "1" : data?.match_me_with.toString(),
      ageRangeForMatch:
        data?.age_range_for_match === null
          ? { min: 18, max: 30 }
          : {
              min: data?.age_range_for_match.min || 18,
              max: data?.age_range_for_match.max || 30,
            },
      isSwipeWithFriends: data?.is_swipe_with_friends,
      aboutInfo: data?.about_info === null ? "" : data?.about_info,
      interests: data?.userInterests.map((value) => {
        return value.interest.id;
      }),
      allowedNotifications: data?.userAllowedNotifications.map((value) => {
        return value.notification.id;
      }),
      distance: data?.distance == null ? 50 : Number(data?.distance) / 1000,
      showOnlySameInterest: data?.show_only_same_interest,
      isVaccinated: data?.is_vaccinated === null ? false : data?.is_vaccinated,
    };
    dispatch({
      type: actions.GET_PATIENT_DETAILS,
      payload: res
        ? {
            buddyDetails: data,
            userDetails: userDetails,
            isFetchedUserDetails: true,
          }
        : null,
    });
    dispatch({
      type: actions.UPDATE_SETTINGS_LOADING,
      payload: { isSettingsLoading: false },
    });
  };
};
export const getInterestDetails = (offset, limit) => {
  let endURL = `/getInterests`;
  return async (dispatch) => {
    const res = await axios.get(`${settings.apiBaseURL}/${endURL}`);
    dispatch({
      type: actions.GET_INTEREST_DETAILS,
      payload: res ? res.data.response : null,
    });
  };
};
