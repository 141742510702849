import moment from "moment";
import actions from "./actions";
const initState = {
    basic_foods: [],
    branded_foods:[],
    recipes: [],
}
const reducer = (state = initState, { type, payload }) => {
    switch (type) {
        case actions.GET_BASIC_FOODS:
            return Object.assign({}, state, {
                basic_foods: [...state.basic_foods, ...payload],
            });
        case actions.RESET_BASIC_FOODS:
        return Object.assign({}, state, {
            ...state,
            basic_foods: [],
            docoffset: 0,
        });
        case actions.GET_BRANDED_FOODS:
            return Object.assign({}, state, {
                branded_foods: [...state.branded_foods, ...payload],
            });
        case actions.RESET_BRANDED_FOODS:
        return Object.assign({}, state, {
            ...state,
            branded_foods: [],
            docoffset: 0,
        });
        case actions.GET_RECIPES:
            return Object.assign({}, state, {
                recipes: [...state.recipes, ...payload],
            });
        case actions.RESET_RECIPES:
        return Object.assign({}, state, {
            ...state,
            recipes: [],
            docoffset: 0,
        });
        default:
            return state;
    }
};

export default reducer;