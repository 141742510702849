import { useEffect, useState } from "react";
import AuthBase from "../../components/AuthBase";
import { clientSignInRoute, signUpRoute } from "../../routes";
import { useHistory } from "react-router-dom";
import {
  isValidPassword,
  showErrorNotification,
  showSuccessNotification,
} from "../../utility/Helpers";
import axios from "../../services/Axios";
import Loading from "../../components/Loading";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

const ResetPassword = () => {
  const history = useHistory();
  const { requestId, userId } = history.location.state;
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!requestId || !userId) {
      history.replace(clientSignInRoute.path);
    }
  }, []);

  const createAccountPressed = () => {
    history.replace(signUpRoute.path);
  };

  const loginPressed = () => {
    history.replace(clientSignInRoute.path);
  };

  const showPasswordClicked = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async () => {
    if (!validForm()) return;
    setLoading(true);
    await axios
      .post("/resetPasswordByOtp", {
        userId,
        requestId,
        password,
      })
      .then((res) => {
        if (res.status === 200) {
          setShowModal(true);
        } else {
          showErrorNotification();
        }
        showSuccessNotification(res?.data?.response);
      })
      .catch((res) => {
        let newRes = { ...res };
        if (newRes?.response?.data?.response?.error_description) {
          setConfirmPasswordError(
            newRes?.response?.data?.response?.error_description
          );
          showErrorNotification(
            newRes?.response?.data?.response?.error_description
          );
        } else {
          showErrorNotification();
        }
      })
      .catch((e) => {
        console.log(e.response);
      })
      .finally(() => {
        setLoading(false);
      });
    // try {

    // } catch (e) {
    //   //console.log("--- reset password handleSubmit error");
    //   // showErrorNotification(res.data.response.error_description);

    // }
  };

  const validForm = () => {
    let isValid = true;
    const error = isValidPassword(password);
    if (error) {
      isValid = false;
      setPasswordError(error);
    } else {
      setPasswordError("");
    }
    if (confirmPassword !== password) {
      isValid = false;
      setConfirmPasswordError("Confirm password does not match password");
    } else {
      setConfirmPasswordError("");
    }
    return isValid;
  };

  const modalOkPressed = () => {
    history.replace(clientSignInRoute.path);
  };

  const language = useSelector((state) => state.language);

  return (
    <AuthBase>
      <h4 className="welcome-text d-flex justify-content-center mb-5">
       {language.lang.welcome}
      </h4>
      <p className="text-center text-secondary font-weight-bold mb-5">
        Reset your password
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="password-field-container">
          <input
            name="password"
            className="neumorphic-input"
            placeholder={language.lang.password}
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            onClick={showPasswordClicked}
            className="btn show-password-btn"
            type="button"
          >
            {showPassword ? (
              <i className="far fa-eye-slash"></i>
            ) : (
              <i className="far fa-eye"></i>
            )}
          </button>
        </div>
        {passwordError && <p className="text-danger mt-3">{passwordError}</p>}
        <div className="mt-3"></div>
        <div className="password-field-container">
          <input
            name="confirmPassword"
            className="neumorphic-input"
            placeholder={"Confirm " + language.lang.password}
            type={showPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button
            onClick={showPasswordClicked}
            className="btn show-password-btn"
            type="button"
          >
            {showPassword ? (
              <i className="far fa-eye-slash"></i>
            ) : (
              <i className="far fa-eye"></i>
            )}
          </button>
        </div>
        {confirmPasswordError && (
          <p className="text-danger mt-3">{confirmPasswordError}</p>
        )}
        <div className="d-flex justify-content-center mt-5">
          {loading ? (
            <Loading />
          ) : (
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn btn-primary btn-submit"
            >
              Submit
            </button>
          )}
        </div>
      </form>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <span className="m-0">{language.lang["new-here-?"]}</span>
        <button type="submit" className="btn" onClick={createAccountPressed}>
          <span className="text-logo-color">{language.lang.createAnAccount}</span>
        </button>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <span className="m-0">{language.lang.alreadyAMember} </span>
        <button type="submit" className="btn" onClick={loginPressed}>
          <span className="text-logo-color">{language.lang.login}</span>
        </button>
      </div>
      <Modal
        size="md"
        backdrop="static"
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="reset-password-modal d-flex flex-column align-items-center">
            <h6 className="font-weight-bold w-100 title mb-3">
              Reset {language.lang.password}
            </h6>
            <p className="info-text px-3">
              We have successfully reset your password. Please use your new
              password to login.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={modalOkPressed}
            className="btn btn-primary d-flex justify-content-center align-items-center"
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </AuthBase>
  );
};

export default ResetPassword;
