import { useHistory, useLocation } from "react-router-dom";
import { dashboardRoute } from "../../../routes";
import BackButton from "../../BackButton";
import ShareBackButton from "../Shared/ShareBackButton";
import Config from '../../../config/index';

// import { Prompt } from "react-router";

const TopMenuBackButton = ({ returnPropmt, onClick, className }) => {
  // const [shouldReturnPropmt, setShouldReturnPropmt] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const handleClick = () => {
    if (onClick) {
      return onClick();
    } else if (location.key && location.pathname !== "/") {
      history.goBack();
    } else {
      history.push(dashboardRoute.path);
    }
    // if (returnPropmt) {
    //   setShouldReturnPropmt(true);
    //   return;
    // }
  };

  // if (shouldReturnPropmt) {
  //   return <Prompt when={true} message="Are you sure you want to leave?" />;
  // }

  return (
    <div
      onClick={handleClick}
      >
      <img src={Config.backbutton} alt="BackButtonSrc" className="circle-button"/>
      {/* <div className="" style={{"width":"3rem","height":"3rem"}}><ShareBackButton></ShareBackButton></div> */}
    </div>
    
  );
};

export default TopMenuBackButton;
