//modules import
import {
  fitnessBuddyRoute,
  matchedProfileRoute,
  buddysLiveChatRoute,
  viewMatchesRoute,
} from "../../../routes";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { settings } from "../../../config";
import axios from "../../../services/Axios";

//redux import
import {
  findFitnessBuddyMatches,
  updateCurrentBuddyDetails,
  deleteBuddyDetailsInMatches,
  getPatientDetails,
} from "../../../redux/fitnessBuddy/actionCreators";
//icon import
import { IoSettingsSharp } from "react-icons/io5";
import { HiChatAlt2 } from "react-icons/hi";
import { FaHeart } from "react-icons/fa";
import { VscDebugRestart } from "react-icons/vsc";
import { BsFillStarFill } from "react-icons/bs";

import { IoCloseCircleSharp } from "react-icons/io5";
import actions from "../../../redux/fitnessBuddy/actions";
import Loading from "../../../components/Loading";
import { LikeDisLikeEnum } from "../constant";
import { tokenStorageKey } from "../../../utility/constants";
import TopMenu from "../../../components/CommonControls/TopMenu";

const ViewMatches = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const language = useSelector((state) => state.language);
  console.log(language);
  const languageType = useSelector(
    (state) => state.language.general.preferredlanguage
  );
  const allMatches = useSelector((state) => state.patientDetails.allMatches);
  const currentBuddyDetails = useSelector(
    (state) => state.patientDetails.currentBuddyDetails
  );
  const rejectedUserDetails = useSelector(
    (state) => state.patientDetails.rejectedUserDetails
  );
  const isViewMatchesLoading = useSelector(
    (state) => state.patientDetails.isViewMatchesLoading
  );
  const userInterests = useSelector(
    (state) => state.patientDetails.userDetails.interests
  );
  const buddyDetails = useSelector(
    (state) => state.patientDetails.buddyDetails || {}
  );
  const isFetchedUserDetails = useSelector(
    (state) => state.patientDetails.isFetchedUserDetails
  );
  const isSettingsLoading = useSelector(
    (state) => state.patientDetails.isSettingsLoading
  );
  const { userData } = useSelector((state) => state.auth);
  console.log(userData);
  useEffect(() => {
    dispatch(getPatientDetails());
    dispatch(findFitnessBuddyMatches());
  }, []);
  useEffect(() => {
    if (isViewMatchesLoading && isFetchedUserDetails && !isSettingsLoading) {
      if (buddyDetails?.is_enabled_for_fitness) {
        return;
      } else {
        history.push(fitnessBuddyRoute.path);
      }
    }
  }, [history, isFetchedUserDetails, isViewMatchesLoading, isSettingsLoading]);
  const goToSettings = () => {
    history.push(fitnessBuddyRoute.path);
  };
  const goToLiveChat = () => {
    dispatch({
      type: actions.UPDATE_MATCHED_USER,
      payload: { selectedUserID: {}, selectedUserStatus: false },
    });
    history.push(buddysLiveChatRoute.path);
  };
  const undoProfile = () => {
    if (rejectedUserDetails.length === 0) {
      return;
    }
    dispatch({
      type: actions.VIEW_BUDDY_DETAILS,
      payload: {
        currentBuddyDetails: rejectedUserDetails[0],
        isViewMatchesLoading: false,
      },
    });
    dispatch({
      type: actions.REMOVE_REJECTED_USER_DETAILS,
    });
  };
  const rejectedProfile = async () => {
    dispatch({
      type: actions.UPDATE_VIEW_MATCHES_LOADING,
      payload: { isViewMatchesLoading: true },
    });
    try {
      dispatch(deleteBuddyDetailsInMatches(currentBuddyDetails));
      dispatch(updateCurrentBuddyDetails(allMatches));
      const URL = `${settings.apiBaseURL}/deleteMatchUser`;
      const res = await axios.delete(URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(tokenStorageKey)}`,
          "Content-Type": "application/json",
        },
        data: {
          matchUserId: currentBuddyDetails.id,
        },
      });
      dispatch({
        type: actions.UPDATE_VIEW_MATCHES_LOADING,
        payload: { isViewMatchesLoading: false },
      });
    } catch (err) {
      dispatch(deleteBuddyDetailsInMatches(currentBuddyDetails));
      dispatch(updateCurrentBuddyDetails(allMatches));

      dispatch({
        type: actions.UPDATE_VIEW_MATCHES_LOADING,
        payload: { isViewMatchesLoading: false },
      });
    }
  };

  const matchSelectedProfile = async () => {
    let isCommomInterest = false;
    const currentUserInterests = currentBuddyDetails.userInterests.map(
      (value) => value.interest.id
    );
    const found = userInterests.some(
      (r) => currentUserInterests.indexOf(r) >= 0
    );
    isCommomInterest = found;
    if (isCommomInterest) {
      dispatch({
        type: actions.UPDATE_VIEW_MATCHES_LOADING,
        payload: { isViewMatchesLoading: true },
      });
      const res = await axios.post(`${settings.apiBaseURL}/addMatchUser`, {
        matchUserId: currentBuddyDetails.id,
        statusId: LikeDisLikeEnum.like,
      });
      if (res.status === 200 && Object.keys(res.data.response).length !== 0) {
        dispatch({
          type: actions.UPDATE_VIEW_MATCHES_LOADING,
          payload: { isViewMatchesLoading: false },
        });
        history.push(matchedProfileRoute.path);
      }
    } else {
      dispatch(deleteBuddyDetailsInMatches(currentBuddyDetails));
      dispatch(updateCurrentBuddyDetails(allMatches));
    }
  };
  const getInterestName = (userInterest) => {
    if (languageType === "ar") {
      return userInterest.interest.name_ar;
    } else if (languageType === "ku") {
      return userInterest.interest.name_ku;
    } else {
      return userInterest.interest.name;
    }
  };
  console.log(userInterests, language.lang);
  return (
    <div>
      <TopMenu
        shouldHideBackButton={false}
        shouldHideProfileButton={false}
        shouldHideNotificationButton={false}
        shouldHideLogoButton={false}
        pageTitle={language.lang.fitnessConnect}
      />

      <div className="view-matches-container">
        {isViewMatchesLoading ? (
          <div className="d-flex justify-content-center">
            <Loading />
          </div>
        ) : (
          <section className="view-match-layout">
            <div className="headings">
              <div onClick={goToSettings} className="settings">
                <IoSettingsSharp />
                {language.lang.fitnessSettings}
              </div>
              <div className="live-chat" onClick={goToLiveChat}>
                <HiChatAlt2 />
                {language.lang.liveChat}
              </div>
            </div>
            {allMatches.length === 0 ? (
              <div>{language.lang["no-matches-found"]}</div>
            ) : (
              <div>
                {/* <div className="btn-container">
                  <button className="btn-matches">View Matches</button>
                </div> */}
                <div className="pic-with-button">
                  <img
                    src={currentBuddyDetails.profilePhotoUrl}
                    className="display-pic"
                    alt="display-pic"
                  />
                  <div className="icons-group">
                    <div
                      className={
                        rejectedUserDetails.length === 0
                          ? "icon-back-undo-disabled"
                          : "icon-back-undo"
                      }
                      disabled={rejectedUserDetails.length === 0 ? true : false}
                    >
                      <VscDebugRestart onClick={undoProfile} className="undo" />
                    </div>
                    <div className="icon-back-reject">
                      <IoCloseCircleSharp
                        onClick={rejectedProfile}
                        className="reject"
                      />
                    </div>
                    <div className="icon-back-like">
                      <FaHeart
                        onClick={matchSelectedProfile}
                        className="like"
                      />
                    </div>
                    <div className="icon-back-star">
                      <BsFillStarFill className="star" />
                    </div>
                  </div>
                </div>
                <div className="person-details">
                  {currentBuddyDetails.name}
                  {currentBuddyDetails.age !== null
                    ? `,${currentBuddyDetails.age}`
                    : ""}
                </div>
                <div className="activity">
                  {`${currentBuddyDetails.locationName}`}
                </div>
                <div className="about-info">
                  <label>{language.lang.aboutMe}</label>
                  <div>{currentBuddyDetails.aboutInfo}</div>
                </div>
                <label className="interest-text">
                  {language.lang.Interests}
                </label>
                <div className="interest-details">
                  {currentBuddyDetails?.userInterests?.map((userInterest) => {
                    return (
                      <div
                        key={userInterest.interest.id}
                        className="display-interest"
                        style={{
                          backgroundColor: userInterest.interest.color_code,
                        }}
                      >
                        <div> {getInterestName(userInterest)}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </section>
        )}
      </div>
    </div>
  );
};

export default ViewMatches;
