import moment from "moment";
import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { liveSessionRoute } from "../routes";
import { updateSessionStatus } from "../services/LiveSessionsNetworkService";
import { sessionStartedByHostStatusID } from "../utility/constants";
import {
  isMobileOrTabletBrowser,
  showErrorNotification,
} from "../utility/Helpers";
import Loading from "./Loading";
import MobileAppPropmtModal from "./MobileAppPropmtModal";

const PractitionerCalendarSessionCard = () => {
  const language = useSelector((state) => state.language);
  const history = useHistory();
  const selectedCalendarItem = useSelector(
    (state) => state.practitioner.calendar.selectedSlot
  );
  const [loading, setLoading] = useState(false);
  const [showMobilePromptModal, setShowMobilePromptModal] = useState(false);
  const startDateTime = moment(selectedCalendarItem.start);
  const endDateTime = moment(selectedCalendarItem.end);
  const now = moment();
  const isCalendarItemTimeNow =
    now.isSameOrAfter(startDateTime) && now.isBefore(endDateTime);
  const hostStartedSession = selectedCalendarItem.is_host_started_call;
  const hostEndedSession = selectedCalendarItem.is_host_ended_call;
  const showStartSessionButton = isCalendarItemTimeNow && !hostStartedSession;
  const showRejoinSessionButton =
    isCalendarItemTimeNow && hostStartedSession && !hostEndedSession;
  const isSessionApproved = selectedCalendarItem.status_id !== 27;
  console.log({ selectedCalendarItem });

  const joinSessionClicked = async () => {
    setLoading(true);
    const sessionID = await updateSessionStatus({
      sessionID: selectedCalendarItem.id,
      statusID: sessionStartedByHostStatusID,
    });
    setLoading(false);
    if (sessionID) {
      joinSessionCall();
    } else {
      showErrorNotification();
    }
  };

  const joinSessionCall = () => {
    if (isMobileOrTabletBrowser()) {
      setShowMobilePromptModal(true);
    } else {
      history.push(liveSessionRoute.path, { session: selectedCalendarItem });
    }
  };

  // console.log("--- isSessionApproved", {
  //   isSessionApproved,
  //   showStartSessionButton,
  //   showRejoinSessionButton,
  // });

  const onMobileModalPromptClose = () => {
    setShowMobilePromptModal(false);
    history.replace(liveSessionRoute.path, { session: selectedCalendarItem });
  };

  const buttonClasses =
    "w-100 d-flex justify-content-center align-items-center";
  let button;
  if (loading) {
    button = <Loading className="mt-3" />;
  } else if (showStartSessionButton) {
    button = (
      <button
        disabled={!isSessionApproved}
        onClick={joinSessionClicked}
        className={
          isSessionApproved ? `pay-btn ${buttonClasses}` : `btn btn-secondary`
        }
      >
        {language.lang.startSession}
      </button>
    );
  } else if (showRejoinSessionButton) {
    button = (
      <button
        onClick={joinSessionClicked}
        className={`pay-btn  ${buttonClasses}`}
      >
        {language.lang.rejoinSession}
      </button>
    );
  }

  // console.log({ selectedCalendarItem });

  return (
    <div className="d-flex w-100 flex-column">
      <MobileAppPropmtModal
        show={showMobilePromptModal}
        onCloseClicked={onMobileModalPromptClose}
      />
      <div className="d-flex w-100 mt-1">
        <div className="col-2 p-0 d-flex flex-column text-end">
          <span className="start-time">{startDateTime.format("hh:mm")}</span>
          <span className="end-time">{endDateTime.format("hh:mm")}</span>
        </div>
        <div className="col-9 p-0 mx-2">
          <div className="d-flex justify-content-center align-items-center w-100">
            <div className="side-show mr-2"></div>
            <div
              className="selected-appoint-card w-100"
              style={{ backgroundColor: selectedCalendarItem.color }}
            >
              <div className="d-flex justify-content-between w-100 align-items-center">
                <span>
                  {language.lang[`${selectedCalendarItem.statusText}-session`]}
                </span>
                <span>
                  <BsThreeDotsVertical color={"white"} />
                </span>
              </div>
              <div className="mt-1">
                {selectedCalendarItem.PractitionerName}
              </div>
              <div className="d-flex justify-content-end w-100 align-items-center mt-auto">
                {button}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PractitionerCalendarSessionCard;
